import * as types from 'constants/actionTypes'
import { Notification } from 'declarations/models'
import { AnyAction } from 'redux'

import _ from 'lodash'

export interface NotificationsState {
  created: Notification | null | undefined
  creating: boolean
  deleted: Notification | null | undefined
  deleting: boolean
  get: Notification | null | undefined
  getting: boolean
  list: Array<Notification> | null | undefined
  listing: boolean
  saving: boolean
  saved: Notification | null | undefined
}

export const initialNotificationsState: NotificationsState = {
  created: undefined,
  creating: false,
  deleted: undefined,
  deleting: false,
  get: undefined,
  getting: false,
  list: undefined,
  listing: false,
  saved: undefined,
  saving: false
}

const notificationsReducer = (
  /* istanbul ignore next */
  state: NotificationsState = initialNotificationsState,
  action: AnyAction
): NotificationsState => {
  switch (action.type) {
    case types.NOTIFICATIONS_DELETE_REQUEST:
      return {
        ...state,
        deleted: undefined,
        deleting: true
      }

    case types.NOTIFICATIONS_DELETE_SUCCESS: {
      const deletedNotification = action.originalPayload
      return {
        ...state,
        deleted: deletedNotification,
        deleting: false,
        list: _.reject(state.list, { id: deletedNotification.id })
      }
    }

    case types.NOTIFICATIONS_DELETE_FAILURE:
      return {
        ...state,
        deleted: null,
        deleting: false
      }

    case types.NOTIFICATIONS_EDIT_REQUEST:
      return {
        ...state,
        saved: undefined,
        saving: true
      }

    case types.NOTIFICATIONS_EDIT_SUCCESS: {
      const editedNotification = action.payload
      return {
        ...state,
        saved: editedNotification,
        saving: false,
        list: _.map(state.list, (Notification) =>
          /* istanbul ignore next */
          editedNotification.id === Notification.id ? editedNotification : Notification
        )
      }
    }

    case types.NOTIFICATIONS_EDIT_FAILURE:
      return {
        ...state,
        saved: null,
        saving: false
      }

    case types.NOTIFICATIONS_GET_REQUEST:
      return {
        ...state,
        getting: true,
        get: undefined
      }

    case types.NOTIFICATIONS_GET_SUCCESS:
      return {
        ...state,
        getting: false,
        get: action.payload
      }

    case types.NOTIFICATIONS_GET_FAILURE:
      return {
        ...state,
        getting: false,
        get: null
      }

    case types.NOTIFICATIONS_LIST_REQUEST:
      return {
        ...state,
        list: undefined,
        listing: true
      }

    case types.NOTIFICATIONS_LIST_SUCCESS: {
      // with 204, I get a {} instead of a []
      /* istanbul ignore next */
      const payload = action.status === 204 && _.isEmpty(action.payload) ? [] : action.payload
      return {
        ...state,
        list: payload,
        listing: false
      }
    }

    case types.NOTIFICATIONS_LIST_FAILURE:
      return {
        ...state,
        list: null,
        listing: false
      }

    case types.NOTIFICATIONS_NEW_REQUEST:
      return {
        ...state,
        creating: true,
        created: undefined
      }

    case types.NOTIFICATIONS_NEW_SUCCESS: {
      const newNotification = action.payload
      return {
        ...state,
        created: newNotification,
        creating: false,
        list: [.../* istanbul ignore next */ (state.list ?? [])].concat(newNotification)
      }
    }

    case types.NOTIFICATIONS_NEW_FAILURE:
      return {
        ...state,
        created: null,
        creating: false
      }

    default:
      return state
  }
}

export default notificationsReducer
