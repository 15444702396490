import { Dispatch, SetStateAction, useEffect, useState } from 'react'

export type AttachmentType = 'Document' | 'EngagementCard' | 'ConsultantCard' | 'ClientCard'

export interface Attachment {
  type: AttachmentType
  label?: string
  link?: string
  title: string
  data: Object
}

export interface Message {
  message: string
  isUser: boolean
  timestamp: Date
  attachments?: Attachment[]
}

interface AiChatHistory {
  [version: string]: Message[]
}

export const useAiMessages = (
  version: string
): [Message[], Dispatch<SetStateAction<Message[]>>, () => void] => {
  let fullHistory: AiChatHistory | undefined = JSON.parse(localStorage.getItem('ai-messages')!)
  const defaultHistory = {
    [version]: [
      {
        isUser: false,
        message: 'Hello, I am the AI assistant. How can I help you?',
        timestamp: new Date()
      }
    ]
  }

  if (!fullHistory) {
    // If user has no history, set default history
    localStorage.setItem('ai-messages', JSON.stringify(defaultHistory))
    fullHistory = defaultHistory
  }
  if (!fullHistory[version]) {
    // If user does not have any history in current version, set default history
    fullHistory[version] = defaultHistory[version]
    localStorage.setItem('ai-messages', JSON.stringify(fullHistory))
  }

  const [history, setHistory] = useState(fullHistory[version] ?? [])

  useEffect(() => {
    if (fullHistory) {
      fullHistory[version] = history
      localStorage.setItem('ai-messages', JSON.stringify(fullHistory))
    }
  }, [history])

  const deleteHistory = () => {
    localStorage.setItem('ai-messages', JSON.stringify(defaultHistory))
    fullHistory = defaultHistory
    setHistory(fullHistory[version] ?? [])
  }

  return [history, setHistory, deleteHistory]
}
