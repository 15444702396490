import { Button, ButtonSize } from '@cegal/ds-components'
import { StarOutline } from '@cegal/ds-icons/dist/StarOutline'
import { ObjectWithId } from 'declarations/models'

import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'

interface FollowButtonProps<T> {
  item?: T
  followedItems: Array<T> | null | undefined
  size?: ButtonSize
  text?: boolean
  onFollow?: (item: T) => void
  onUnfollow: (item: T) => void
  loading?: boolean
  disabled?: boolean
}

const FollowButton = <T extends ObjectWithId>({
  item,
  followedItems,
  onFollow,
  onUnfollow,
  size = 'small',
  text = true,
  loading = false,
  disabled = false
}: FollowButtonProps<T>) => {
  const { t } = useTranslation()
  const [hover, setHover] = useState<boolean>(false)

  const isFollowed = useMemo(() => {
    return _.find(followedItems!, (_item: T) => _item?.id === item?.id) !== undefined
  }, [item, followedItems])

  const buttonTitle = isFollowed
    ? loading
      ? t('buttons:unfollowing')
      : hover
        ? t('buttons:unfollow')
        : t('buttons:followed')
    : loading
      ? t('buttons:following')
      : t('buttons:follow')

  return (
    <Button
      spacing
      key={item?.id}
      disabled={disabled}
      loading={loading}
      title={buttonTitle}
      size={size}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      variant={isFollowed ? 'primary' : 'secondary'}
      icon={<StarOutline size='1.3rem' />}
      onClick={() => (isFollowed ? onUnfollow(item!) : onFollow?.(item!))}
    >
      {text && buttonTitle}
    </Button>
  )
}

export default FollowButton
