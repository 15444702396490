import { FlexDiv, Heading, HorizontalSpace, VerticalSpace } from '@cegal/ds-components'
import { WorkOutline } from '@cegal/ds-icons/dist/WorkOutline'
import { deleteMatch, editMatch, listMatches, resetMatch } from 'actions'
import { MATCHES_LIST_FAILURE } from 'constants/actionTypes'
import { EngagementVacancyConsultant } from 'declarations/models'
import { standardLogger } from 'metrics/loggers'
import { useAppDispatch, useAppSelector } from 'store'

import AlertPanel from 'components/AlertPanel/AlertPanel'
import EngagementVacancyConsultantCard from 'components/Engagements/Engagement/Vacancy/Consultant/EngagementVacancyConsultantCard'
import MyMatchesModal from 'components/Home/MyMatches/MyMatchesModal'
import Widget from 'components/Home/Widget/Widget'
import { StretchLoader } from 'components/Loader/CustomLoader'
import { CardContainer } from 'components/styled'

import { HomePanelProps } from 'pages/Home/Home'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import _ from 'lodash'

const MyMatches = ({ mode }: HomePanelProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [workingCopyMatch, setWorkingCopyMatch] = useState<EngagementVacancyConsultant | undefined>(undefined)

  const apiReady = useAppSelector((state) => state.app.apiReady)
  const matches = useAppSelector((state) => state.matches.list)

  const loadPageData = () => dispatch(listMatches())

  const onCloseMatchModal = () => setWorkingCopyMatch(undefined)

  const onViewMatch = (match: EngagementVacancyConsultant) => {
    standardLogger('match.card.view')
    navigate(generatePath('/consultants/:id', { id: match.consultant!.id!.toString() }))
  }

  const onEditMatch = (match: EngagementVacancyConsultant) => {
    dispatch(resetMatch())
    setWorkingCopyMatch(match)
  }

  const onSaveMatch = (editedMatch: EngagementVacancyConsultant) => {
    standardLogger('match.card.saved')
    dispatch(editMatch(editedMatch, editedMatch.consultant!.id as number, editedMatch.engagement_vacancy!.id))
  }

  const onDeleteMatch = (match: EngagementVacancyConsultant) => {
    standardLogger('match.card.deleted')
    dispatch(deleteMatch(match))
  }

  const renderTitle = () => (
    <FlexDiv>
      <WorkOutline size='2rem' />
      <HorizontalSpace />
      <Heading>{t('home:my-matches')}</Heading>
    </FlexDiv>
  )

  const renderBody = () => (
    <>
      <MyMatchesModal
        open={workingCopyMatch !== undefined}
        onClose={onCloseMatchModal}
        onSaveMatch={onSaveMatch}
        match={workingCopyMatch}
      />
      {_.isUndefined(matches) && <StretchLoader />}
      {_.isNull(matches) && (
        <AlertPanel
          doNotRenderIfHeadIsActiveWithError
          watchFor={[MATCHES_LIST_FAILURE]}
          buttonText={t('buttons:click-to-reload-my-matches')!}
          reloadPage={loadPageData}
        />
      )}
      {!_.isNil(matches) &&
        (!_.isEmpty(matches)
          ? matches!.map((match: EngagementVacancyConsultant) => (
              <EngagementVacancyConsultantCard
                fullWidth
                key={match?.engagement_vacancy?.id + '-' + match.consultant?.id}
                spacing
                size='small'
                item={match}
                onView={() => onViewMatch(match)}
                onEdit={() => onEditMatch(match)}
                onDelete={() => onDeleteMatch(match)}
              />
            ))
          : t('messages:no-matches'))}
    </>
  )

  useEffect(() => {
    if (matches === undefined && apiReady) {
      loadPageData()
    }
  }, [apiReady])

  if (mode === 'widget') {
    return <Widget header={renderTitle()}>{renderBody()}</Widget>
  }

  return (
    <>
      {renderTitle()}
      <VerticalSpace />
      <CardContainer>{renderBody()}</CardContainer>
      <VerticalSpace size='2' />
    </>
  )
}

export default MyMatches
