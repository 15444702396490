import { FlexCenterDiv, FlexDiv, Heading, HorizontalSpace } from '@cegal/ds-components'
import { AccessTime } from '@cegal/ds-icons/dist/AccessTime'
import { DateRange } from '@cegal/ds-icons/dist/DateRange'
import { DateTime } from 'luxon'

import { useEffect, useState } from 'react'

const Time = () => {
  const [time, setTime] = useState('')

  useEffect(() => {
    const interval = setInterval(() => {
      const time = DateTime.now().toLocaleString(DateTime.TIME_24_SIMPLE)
      setTime(time)
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <Heading as='span' size='medium'>
      <FlexCenterDiv>
        <DateRange size='1.7rem' />
        <HorizontalSpace size='0.5' />
        <FlexDiv>{DateTime.now().toLocaleString(DateTime.DATE_MED)}</FlexDiv>
        <HorizontalSpace size='2' />
        <AccessTime size='1.7rem' />
        <HorizontalSpace size='0.5' />
        <FlexDiv>{time}</FlexDiv>
      </FlexCenterDiv>
    </Heading>
  )
}

export default Time
