import { FlexDiv, Link, Table } from '@cegal/ds-components'
import { Certificate, Engagement, EngagementsQuery, Skill } from 'declarations/models'
import { eventLogger } from 'metrics/loggers'

import Row, { RowProps } from 'components/Row/Row'
import CertificatesGroup from 'components/TagGroup/CertificatesGroup'
import SkillsGroup from 'components/TagGroup/SkillsGroup'
import YesOrNoTag from 'components/Tags/YesOrNoTag'

import Highlighter from 'react-highlight-words'
import { NavLink, generatePath } from 'react-router-dom'

import useQuery from 'hooks/useQuery'

import { toLocalDateFormat } from 'utils/date'
import { getCertificatesFromEngagement, getUniqueSkillsFromEngagement } from 'utils/engagements'
import { tokenize } from 'utils/tokenizer'

export type EngagementRowProps = RowProps<Engagement, EngagementsQuery> & {
  order: Array<string>
  onView?: (engagement: Engagement) => void
}

const EngagementRow = ({ item, order, onView, ...rest }: EngagementRowProps) => {
  const rates: Array<number> = item.vacancies?.flatMap((vacancy) => {
    return vacancy.hourly_rate
  })
  const sumOfRate = rates?.reduce((previousValue, currentValue) => previousValue + currentValue, 0)
  const averageRate = Math.round(sumOfRate / rates?.length)
  const skills: Array<Skill> = getUniqueSkillsFromEngagement(item)
  const certificates: Array<Certificate> = getCertificatesFromEngagement(item)
  const { query, setQuery } = useQuery<Partial<EngagementsQuery>>({})
  const searchWords = tokenize(query.search)

  return (
    <Row data-testid='EngagementRow' item={item} {...rest}>
      {order.map((key) => {
        switch (key) {
          case 'title':
            return (
              <Table.DataCell key={key}>
                <Link
                  style={{ cursor: 'pointer' }}
                  onClick={(e: any) => {
                    eventLogger(e)
                    onView?.(item)
                  }}
                  data-amplitude='engagement.row.view'
                  data-testid='EngagementRow-Name'
                >
                  <Highlighter
                    caseSensitive={false}
                    searchWords={searchWords}
                    textToHighlight={item?.title ?? ''}
                  />
                </Link>
              </Table.DataCell>
            )
          case 'client':
            return (
              <Table.DataCell key={key}>
                {item.clients?.map((c) => (
                  <Link
                    style={{ marginRight: '0.6rem' }}
                    key={c.id}
                    as={NavLink}
                    to={generatePath('/clients/:id', {
                      id: c.id.toString()
                    })}
                  >
                    <Highlighter
                      key={c.name}
                      caseSensitive={false}
                      searchWords={searchWords}
                      textToHighlight={c?.name ?? ''}
                    />
                  </Link>
                ))}
              </Table.DataCell>
            )
          case 'source':
            return <Table.DataCell key={key}>{item.source?.name}</Table.DataCell>
          case 'location':
            return (
              <Table.DataCell key={key}>
                <Highlighter
                  caseSensitive={false}
                  searchWords={searchWords}
                  textToHighlight={item?.location ?? ''}
                />
              </Table.DataCell>
            )
          case 'creator':
            return <Table.DataCell key={key}>{item.creator?.name}</Table.DataCell>
          case 'last_modifier_id':
            return <Table.DataCell key={key}>{item.last_modifier?.name}</Table.DataCell>
          case 'deadline':
            return <Table.DataCell key={key}>{toLocalDateFormat(item.deadline)}</Table.DataCell>
          case 'start_date':
            return <Table.DataCell key={key}>{toLocalDateFormat(item.start_date)}</Table.DataCell>
          case 'posted_date':
            return <Table.DataCell key={key}>{toLocalDateFormat(item.posted_date)}</Table.DataCell>
          case 'end_date':
            return <Table.DataCell key={key}>{toLocalDateFormat(item.end_date)}</Table.DataCell>
          case 'last_modified_date':
            return <Table.DataCell key={key}>{toLocalDateFormat(item.last_modified_date)}</Table.DataCell>
          case 'optional_extension_period':
            return <Table.DataCell key={key}>{item.optional_extension_period}</Table.DataCell>
          case 'status':
            return <Table.DataCell key={key}>{item.status}</Table.DataCell>
          case 'average_rate':
            return <Table.DataCell key={key}>{averageRate || ''}</Table.DataCell>
          case 'skills':
            return (
              <Table.DataCell key={key}>
                <SkillsGroup<Partial<EngagementsQuery>>
                  mode='popover'
                  size='small'
                  visibleRows={2}
                  query={query}
                  setQuery={setQuery}
                  interactive
                  skills={skills}
                />
              </Table.DataCell>
            )

          case 'certificates':
            return (
              <Table.DataCell key={key}>
                <FlexDiv style={{ minWidth: '10px' }}>
                  <CertificatesGroup<Partial<EngagementsQuery>>
                    visibleRows={1}
                    query={query}
                    setQuery={setQuery}
                    interactive
                    certificates={certificates}
                  />
                </FlexDiv>
              </Table.DataCell>
            )
          case 'qualified':
            return (
              <Table.DataCell key={key}>
                <YesOrNoTag engagement={item} />
              </Table.DataCell>
            )
        }
        return null
      })}
    </Row>
  )
}

export default EngagementRow
