import { Chip, Popover } from '@cegal/ds-components'
import { KeyboardArrowDown } from '@cegal/ds-icons/dist/KeyboardArrowDown'
import { KeyboardArrowUp } from '@cegal/ds-icons/dist/KeyboardArrowUp'
import { MoreHoriz } from '@cegal/ds-icons/dist/MoreHoriz'
import { SimpleEntry } from 'declarations/models'
import styled from 'styled-components'

import { ReactElement, useLayoutEffect, useMemo, useRef, useState } from 'react'
import AnimateHeight, { Height } from 'react-animate-height'
import Highlighter from 'react-highlight-words'
import { useTranslation } from 'react-i18next'

import { match, tokenize } from 'utils/tokenizer'

import _ from 'lodash'

export type TagGroupMode = 'none' | 'readmore' | 'popover'

export interface RenderedElementProps<T> {
  tag: T
  size: 'medium' | 'small'
  interactive: boolean
  matched: boolean
  onClick: (() => void) | undefined
  highlightName: (name: string) => ReactElement
}

export interface TagGroupProps<T extends SimpleEntry> {
  // ids for tags that are in the query, so they need to show differently
  queryTagIds?: Array<T['id']>
  // raw free text search for text highlight
  searchWords?: string
  // list of tags to render
  tags: Array<T> | null | undefined
  // show the overflow tag as none (display block), readmode (display hidden) or popover
  mode?: TagGroupMode
  // let user click on tags or not
  interactive?: boolean
  // callback on tag clicks if interactive
  setTags?: (tags: Array<T['id']>) => void
  // tag label, to use in the button on readmore mode
  tagLabel?: string
  // compare tags function for sorting
  defaultComparator?: (a: T, b: T) => number
  // custom tag element render function

  renderElement?: (props: RenderedElementProps<T>) => JSX.Element
  // how many rows are visible at all times
  visibleRows?: number
  // size
  size?: 'medium' | 'small'
}

const ChipContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex-wrap: wrap;
`

const TagGroup = <T extends SimpleEntry>({
  tags,
  queryTagIds,
  searchWords,
  mode = 'readmore',
  visibleRows = 1,
  interactive = false,
  setTags,
  tagLabel,
  defaultComparator,
  renderElement,
  size = 'medium'
}: TagGroupProps<T>) => {
  const [open, setOpen] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)

  const divRef: any = useRef(null)
  const buttonRef: any = useRef(null)
  const { t } = useTranslation()

  const ROW_HEIGHT: number = 30
  // no need to expand if all tags are visible
  useLayoutEffect(() => {
    if (divRef?.current && divRef.current?.scrollHeight <= ROW_HEIGHT * visibleRows + 2) {
      setDisabled(true)
    }
  }, [divRef])

  // decide on height depending on mode and open state
  const height: Height = useMemo(() => {
    let _height: Height = ROW_HEIGHT * visibleRows
    if (open && mode === 'readmore') {
      _height = 'auto'
    }
    return _height
  }, [mode, open])

  // filtered T[] that match the query
  const matchedTags: T[] | undefined = useMemo(() => {
    if (_.isNil(queryTagIds) || _.isNil(tags)) {
      return []
    }
    return tags.filter((t: T) => queryTagIds.includes(t?.id))
  }, [tags, queryTagIds])

  const onTagAdded = (tag: T) => {
    const _tags = Array.isArray(queryTagIds) ? queryTagIds : []
    _tags.push(tag.id)
    setTags?.(_tags)
  }

  const onTagRemoved = (tag: T) => {
    let _tags = Array.isArray(queryTagIds) ? queryTagIds : []
    _tags = _tags.filter((id) => id !== tag.id)
    setTags?.(_tags)
  }

  const _tags = useMemo(() => {
    return (
      _.cloneDeep(tags)?.sort((a: T, b: T) => {
        if (!_.isEmpty(matchedTags)) {
          if (matchedTags?.map((t) => t.id).includes(a.id)) {
            return -1
          }
          if (matchedTags?.map((t) => t.id).includes(b.id)) {
            return 1
          }
        }
        if (!_.isEmpty(searchWords)) {
          if (match(a.name, searchWords)) {
            return -1
          }
          if (match(b.name, searchWords)) {
            return 1
          }
        }
        return _.isFunction(defaultComparator) ? defaultComparator(a, b) : 0
      }) ?? []
    )
  }, [tags, searchWords, matchedTags])

  const highlightName = (name: string) => {
    // highlight from tags in query (tagWords) and free text search (searchWords)
    const tagWords = matchedTags?.map((t) => t.name) ?? []
    return (
      <Highlighter
        caseSensitive={false}
        searchWords={tokenize(searchWords).concat(tagWords)}
        textToHighlight={name ?? ''}
      />
    )
  }

  if (_.isEmpty(tags)) {
    return null
  }

  if (mode === 'none') {
    return _tags.map((tag) => {
      const matched = matchedTags.map((t) => t.id).includes(tag.id)
      if (_.isFunction(renderElement)) {
        return renderElement({ tag, size, interactive, matched, onClick: undefined, highlightName })
      }
      return (
        <Chip size={size} selected={matched} key={`tag-${tag.id}`}>
          {highlightName(tag.name)}
        </Chip>
      )
    })
  }

  return (
    <AnimateHeight duration={250} height={height} data-testid='TagGroup'>
      <div ref={divRef}>
        {mode === 'popover' && buttonRef.current && (
          <Popover
            variant='secondary'
            data-testid='TagGroup-Popover'
            placement='left'
            open={open}
            onClose={() => setOpen(false)}
            anchorEl={buttonRef.current}
          >
            <Popover.Content style={{ maxWidth: '50vw' }}>
              {_tags.map((tag) => (
                <Chip size={size} key={`tag-${tag.id}`}>
                  {highlightName(tag.name)}
                </Chip>
              ))}
            </Popover.Content>
          </Popover>
        )}
        <ChipContainer ref={buttonRef}>
          <Chip
            button
            // @ts-ignore
            disabled={disabled}
            border={false}
            size={size}
            onClick={() => setOpen(!open)}
            rightElement={
              disabled ? null : mode === 'readmore' ? (
                !open ? (
                  <KeyboardArrowDown size='1rem' />
                ) : (
                  <KeyboardArrowUp size='1rem' />
                )
              ) : (
                <MoreHoriz size='1rem' />
              )
            }
          >
            {_tags.length} {tagLabel ?? ''}
          </Chip>

          {_tags.map((tag) => {
            const matched = matchedTags.map((t) => t.id).includes(tag?.id)
            const onClick = () => (matched ? onTagRemoved?.(tag) : onTagAdded?.(tag))
            return renderElement ? (
              renderElement({ tag, size, interactive, matched, onClick, highlightName })
            ) : (
              <Chip
                key={`tag-${tag?.id}`}
                button={interactive}
                size={size}
                selected={matched}
                title={
                  interactive
                    ? matched
                      ? t('messages:info-remove-filter', { x: tag?.name })
                      : t('messages:info-add-filter', { x: tag?.name })
                    : ''
                }
                onClick={interactive ? onClick : undefined}
              >
                {highlightName(tag?.name)}
              </Chip>
            )
          })}
        </ChipContainer>
      </div>
    </AnimateHeight>
  )
}

export default TagGroup
