import { Table } from '@cegal/ds-components'
import { deleteFollowedEngagement, deleteMatch, listMatches, newFollowedEngagement, newMatch } from 'actions'
import { ViewMode } from 'declarations/app'
import {
  Consultant,
  Engagement,
  EngagementVacancy,
  EngagementVacancyConsultant,
  EngagementsQuery
} from 'declarations/models'
import { standardLogger } from 'metrics/loggers'
import { useAppDispatch, useAppSelector } from 'store'

import EngagementVacancyCard from 'components/Engagements/Engagement/Vacancy/EngagementVacancyCard'
import EngagementVacancyRow from 'components/Engagements/Engagement/Vacancy/EngagementVacancyRow'
import EngagementVacancyRowHeader from 'components/Engagements/Engagement/Vacancy/EngagementVacancyRowHeader'
import FollowButton from 'components/Forms/Buttons/FollowButton'
import MatchButton from 'components/Forms/Buttons/MatchButton'
import { CardContainer } from 'components/styled'

import { useEffect } from 'react'

import _ from 'lodash'

export interface EngagementVacanciesViewProps {
  buttons?: Array<string>
  // these are engagements with only 1 vacancy, to be rendered as vacancies
  engagements?: Array<Engagement>
  viewMode: ViewMode
  onSortChange?: (sortKey?: string | undefined) => void
  query?: Partial<EngagementsQuery>

  // these are for match context
  previewInModal?: boolean
  consultant: Consultant
}

const EngagementVacanciesView = ({
  buttons,
  engagements,
  consultant,
  viewMode,
  query,
  onSortChange
}: EngagementVacanciesViewProps) => {
  const dispatch = useAppDispatch()

  const matches = useAppSelector((state) => state.matches.list)
  const apiReady = useAppSelector((state) => state.app.apiReady)
  const followedEngagements = useAppSelector((state) => state.followedEngagements.list)
  const creatingFollow = useAppSelector((state) => state.followedEngagements.creating)
  const deletingFollow = useAppSelector((state) => state.followedEngagements.deleting)

  const handleEngagementVacancyMatched = (evc: Partial<EngagementVacancyConsultant>) => {
    standardLogger('engagement.' + viewMode + '.match')
    dispatch(newMatch(evc))
  }

  const handleEngagementUnmatched = (evc: Partial<EngagementVacancyConsultant>) => {
    standardLogger('engagement.' + viewMode + '.unmatch')
    dispatch(deleteMatch(evc))
  }

  const handleEngagementFollow = (engagement: Engagement) => {
    standardLogger('engagement.' + viewMode + '.follow')
    dispatch(newFollowedEngagement(engagement))
  }

  const handleEngagementUnfollow = (engagement: Engagement) => {
    standardLogger('engagement.' + viewMode + '.unfollow')
    dispatch(deleteFollowedEngagement(engagement))
  }

  const getButtons = (engagement: Engagement, ev: EngagementVacancy): Array<React.ReactNode> | undefined => {
    return buttons?.map((button) => {
      switch (button) {
        case 'match':
          return (
            <MatchButton
              key={'evc-' + consultant.id + '-' + (ev?.id ?? '') + 'match-button'}
              item={
                {
                  consultant,
                  engagement_vacancy: ev,
                  start_date: engagement?.start_date,
                  end_date: engagement?.end_date,
                  notes: '',
                  status: 'matched',
                  hourly_rate: ev?.hourly_rate
                } as EngagementVacancyConsultant
              }
              context={ev}
              contextType='engagementVacancy'
              alreadyMatched={false}
              onMatch={handleEngagementVacancyMatched}
              onUnmatch={handleEngagementUnmatched}
              loading={false}
            />
          )

        case 'follow': {
          const _creating = _.find(creatingFollow, { id: engagement.id }) !== undefined
          const _deleting = _.find(deletingFollow, { id: engagement.id }) !== undefined
          return (
            <FollowButton<Engagement>
              key={'engagement-' + engagement.id + '-follow-button'}
              item={engagement}
              followedItems={followedEngagements}
              onFollow={() => handleEngagementFollow(engagement)}
              onUnfollow={() => handleEngagementUnfollow(engagement)}
              loading={_creating || _deleting}
              disabled={_creating || _deleting}
            />
          )
        }
        default:
          return null
      }
    })
  }
  useEffect(() => {
    if (apiReady && _.isUndefined(matches)) {
      dispatch(listMatches())
    }
  }, [apiReady, matches])

  return (
    <>
      {viewMode === 'row' && (
        <>
          <Table
            data-cy='engagementTable'
            style={{ width: '100%' }}
            sort={query?.sort}
            onSortChange={onSortChange}
          >
            <EngagementVacancyRowHeader />
            <Table.Body>
              {engagements?.map((engagement) => {
                const ev: EngagementVacancy = {
                  ...engagement.vacancies[0],
                  engagement: {
                    id: engagement.id,
                    title: engagement.title
                  }
                }
                return (
                  <EngagementVacancyRow
                    key={`${ev.id}-engagement-vacancy-table-row`}
                    item={ev}
                    buttons={getButtons(engagement, ev)}
                  />
                )
              })}
            </Table.Body>
          </Table>
        </>
      )}
      {viewMode === 'card' && (
        <CardContainer>
          {engagements?.map((engagement: Engagement) => {
            const ev: EngagementVacancy = {
              ...engagement.vacancies[0],
              engagement: {
                id: engagement.id,
                title: engagement.title
              }
            }

            return (
              <EngagementVacancyCard
                key={ev.id}
                item={ev}
                context={{
                  consultant,
                  engagement: ev.engagement,
                  engagementVacancy: ev
                }}
                buttons={getButtons(engagement, ev)}
                spacing
              />
            )
          })}
        </CardContainer>
      )}
    </>
  )
}

export default EngagementVacanciesView
