import * as types from 'constants/actionTypes'
import * as urls from 'constants/urls'
import api from 'api'
import { RoleRequest, RoleResponse } from 'declarations/api'
import { sprintf } from 'sprintf-js'

import _ from 'lodash'

export const deleteRole = (role: RoleResponse) => {
  return api.call({
    url: sprintf(urls.ROLES_DELETE_URL, { id: role.id }),
    method: 'DELETE',
    body: role,
    cascadeFailureError: true,
    type: {
      request: types.ROLES_DELETE_REQUEST,
      success: types.ROLES_DELETE_SUCCESS,
      failure: types.ROLES_DELETE_FAILURE
    }
  })
}

export const editRole = (role: RoleResponse) => {
  const payload: RoleRequest = {
    ..._.omit(role, ['id']),
    name: role.name
  }
  return api.call({
    url: sprintf(urls.ROLES_EDIT_URL, { id: role.id }),
    method: 'PUT',
    body: payload,
    cascadeFailureError: true,
    type: {
      request: types.ROLES_EDIT_REQUEST,
      success: types.ROLES_EDIT_SUCCESS,
      failure: types.ROLES_EDIT_FAILURE
    }
  })
}

export const getRole = (id: number) => {
  return api.call({
    url: sprintf(urls.ROLES_GET_URL, { id }),
    cascadeFailureError: true,
    type: {
      request: types.ROLES_GET_REQUEST,
      success: types.ROLES_GET_SUCCESS,
      failure: types.ROLES_GET_FAILURE
    }
  })
}

export const listRoles = () => {
  return api.call({
    url: urls.ROLES_LIST_URL,
    cascadeFailureError: true,
    skipFake: true,
    type: {
      request: types.ROLES_LIST_REQUEST,
      success: types.ROLES_LIST_SUCCESS,
      failure: types.ROLES_LIST_FAILURE
    }
  })
}

export const newRole = (role: RoleRequest) => {
  return api.call({
    url: urls.ROLES_NEW_URL,
    method: 'POST',
    body: role,
    cascadeFailureError: true,
    type: {
      request: types.ROLES_NEW_REQUEST,
      success: types.ROLES_NEW_SUCCESS,
      failure: types.ROLES_NEW_FAILURE
    }
  })
}
