import { Avatar, Detail, Heading, VerticalSpace } from '@cegal/ds-components'
import { Client } from 'declarations/models'

import { DataAlign, DescriptionList, DescriptionListItem } from 'components/DescriptionList/DescriptionList'
import Section from 'components/Forms/Section'
import { HomeElementPanel } from 'components/styled'

import { useTranslation } from 'react-i18next'

import useClientAvatar from 'hooks/useClientAvatar'

export interface ClientDetailProps {
  client: Client
}

const ClientDetail = ({ client }: ClientDetailProps) => {
  const { t } = useTranslation()
  const { avatar, avatarLoading, ref } = useClientAvatar({ id: client.id })

  return (
    <>
      <HomeElementPanel border background>
        <Avatar
          data-testid='ClientCard-Avatar'
          variant='square-round'
          size='large'
          src={avatar}
          loading={avatarLoading}
          ref={ref}
        />
        <VerticalSpace />

        <Heading size='xsmall' as='span'>
          {client?.name}
        </Heading>
        <VerticalSpace />

        <Detail style={{ wordBreak: 'break-all' }}>{client?.description}</Detail>
        <VerticalSpace />

        <Section heading={t('label:info')!}>
          <DescriptionList $alignment={DataAlign.Column}>
            <DescriptionListItem title={t('label:location')} details={client?.location} />
          </DescriptionList>
        </Section>
      </HomeElementPanel>
    </>
  )
}

export default ClientDetail
