import {
  AlignEndColumn,
  Button,
  FlexBaseDiv,
  FlexDiv,
  Heading,
  HorizontalSpace,
  Modal,
  Row,
  Switch,
  TextField,
  VerticalSpace
} from '@cegal/ds-components'
import { Source } from 'declarations/models'
import { useAppSelector } from 'store'

import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'

export interface SourceModalProps {
  askForAddSource?: boolean
  source: Partial<Source> | undefined
  open: boolean
  onClose: () => void
  onNewSource?: (source: Source, addSource: boolean) => void
  onSaveSource?: (source: Source) => void
  anchorEl?: Element | null
}

const SourceModal = ({
  askForAddSource = false,
  source,
  open,
  onClose,
  onNewSource,
  onSaveSource,
  anchorEl = null
}: SourceModalProps) => {
  const { t } = useTranslation()
  const [editMode, setEditMode] = useState<boolean | undefined>(undefined)
  const [addSource, setAddSource] = useState<boolean>(askForAddSource)

  useEffect(() => {
    reset(source)
    setEditMode(_.isNumber(source?.id))
  }, [source])

  const {
    handleSubmit,
    register,
    reset,
    formState: { isDirty, errors }
  } = useForm<Source>()

  const creating = useAppSelector((state) => state.sources.creating)
  const created = useAppSelector((state) => state.sources.created)
  const saving = useAppSelector((state) => state.sources.saving)
  const saved = useAppSelector((state) => state.sources.saved)

  const onSubmit = (source: Source) => {
    if (editMode && onSaveSource) {
      onSaveSource(source)
    }
    if (!editMode && onNewSource) {
      onNewSource(source, addSource)
    }
  }

  useEffect(() => {
    if (editMode && saved !== undefined) {
      onClose()
    }
    if (!editMode && created !== undefined) {
      onClose()
    }
  }, [saved, created, editMode])

  const modalContainerRef = useRef(null)

  return (
    <FlexBaseDiv ref={modalContainerRef}>
      {modalContainerRef?.current && (
        <Modal
          // @ts-ignore
          parentSelector={() => anchorEl ?? modalContainerRef.current!}
          open={open}
          aria-label={t('home:edit-source')}
          closeButton={false}
          onClose={onClose}
          shouldCloseOnOverlayClick={false}
        >
          <Modal.Content style={{ minWidth: '400px' }}>
            <Modal.Content.Header>
              <Heading spacing level='2' size='medium'>
                {t(!editMode ? 'buttons:add-new-x' : 'buttons:edit-x', {
                  x: t('label:source').toLowerCase()
                })}
              </Heading>
            </Modal.Content.Header>
            <Modal.Content.Body>
              <TextField
                label={t('label:name-title')}
                {...register('name', {
                  required: t('validation:source-no-name').toString()
                })}
                error={errors?.name?.message?.toString()}
              />
              <VerticalSpace size='2' />
              {askForAddSource && (
                <>
                  <Switch checked={addSource} onChange={() => setAddSource(!addSource)}>
                    {' '}
                    {t('label:add-new-source-after-create')}
                  </Switch>
                </>
              )}
            </Modal.Content.Body>
            <Modal.Content.Footer>
              <Row>
                <AlignEndColumn style={{ alignItems: 'flex-end' }}>
                  <FlexDiv>
                    <Button
                      variant='secondary'
                      onClick={() => {
                        if (!isDirty) {
                          onClose()
                        } else {
                          if (window.confirm(t('messages:confirm-close-note')!)) {
                            onClose()
                          }
                        }
                      }}
                    >
                      {t('buttons:close')}
                    </Button>
                    <HorizontalSpace />
                    <Button loading={saving || creating} onClick={handleSubmit(onSubmit)}>
                      {editMode
                        ? saving
                          ? t('buttons:saving')
                          : t('buttons:save')
                        : creating
                          ? t('buttons:adding')
                          : t('buttons:add')}
                    </Button>
                  </FlexDiv>
                </AlignEndColumn>
              </Row>
            </Modal.Content.Footer>
          </Modal.Content>
        </Modal>
      )}
    </FlexBaseDiv>
  )
}

export default SourceModal
