import * as types from 'constants/actionTypes'
import { Role } from 'declarations/models'
import { AnyAction } from 'redux'

import _ from 'lodash'

export interface RolesState {
  created: Role | null | undefined
  creating: boolean
  deleted: Role | null | undefined
  deleting: boolean
  get: Role | null | undefined
  getting: boolean
  list: Array<Role> | null | undefined
  listing: boolean
  saving: boolean
  saved: Role | null | undefined
}

export const initialRolesState: RolesState = {
  created: undefined,
  creating: false,
  deleted: undefined,
  deleting: false,
  get: undefined,
  getting: false,
  list: undefined,
  listing: false,
  saved: undefined,
  saving: false
}

const rolesReducer = (
  /* istanbul ignore next */
  state: RolesState = initialRolesState,
  action: AnyAction
): RolesState => {
  switch (action.type) {
    case types.ROLES_DELETE_REQUEST:
      return {
        ...state,
        deleted: undefined,
        deleting: true
      }

    case types.ROLES_DELETE_SUCCESS: {
      const deletedRole = action.originalPayload
      return {
        ...state,
        deleted: deletedRole,
        deleting: false,
        list: _.reject(state.list, { id: deletedRole.id })
      }
    }

    case types.ROLES_DELETE_FAILURE:
      return {
        ...state,
        deleted: null,
        deleting: false
      }

    case types.ROLES_EDIT_REQUEST:
      return {
        ...state,
        saved: undefined,
        saving: true
      }

    case types.ROLES_EDIT_SUCCESS: {
      const editedRole = action.payload
      return {
        ...state,
        saved: editedRole,
        saving: false,
        list: _.map(state.list, (Role) =>
          /* istanbul ignore next */
          editedRole.id === Role.id ? editedRole : Role
        )
      }
    }

    case types.ROLES_EDIT_FAILURE:
      return {
        ...state,
        saved: null,
        saving: false
      }

    case types.ROLES_GET_REQUEST:
      return {
        ...state,
        getting: true,
        get: undefined
      }

    case types.ROLES_GET_SUCCESS:
      return {
        ...state,
        getting: false,
        get: action.payload
      }

    case types.ROLES_GET_FAILURE:
      return {
        ...state,
        getting: false,
        get: null
      }

    case types.ROLES_LIST_REQUEST:
      return {
        ...state,
        list: undefined,
        listing: true
      }

    case types.ROLES_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload,
        listing: false
      }

    case types.ROLES_LIST_FAILURE:
      return {
        ...state,
        list: null,
        listing: false
      }

    case types.ROLES_NEW_REQUEST:
      return {
        ...state,
        creating: true,
        created: undefined
      }

    case types.ROLES_NEW_SUCCESS: {
      const newRole = action.payload
      return {
        ...state,
        created: newRole,
        creating: false,
        list: [.../* istanbul ignore next */ (state.list ?? [])].concat(newRole)
      }
    }

    case types.ROLES_NEW_FAILURE:
      return {
        ...state,
        created: null,
        creating: false
      }

    default:
      return state
  }
}

export default rolesReducer
