import * as types from 'constants/actionTypes'
import { ActionWithPayload } from '@cegal/ds-utils'
import { Action, ActionCreator } from 'redux'

export const clearAlertHead: ActionCreator<Action> = (): Action => ({
  type: types.ALERTS_HEAD_CLEAR
})

export const clearAlertBody: ActionCreator<ActionWithPayload<Array<string>>> = (
  errorTypes: Array<string>
): ActionWithPayload => ({
  type: types.ALERTS_BODY_CLEAR,
  payload: errorTypes
})

export const clearAlertBodyAll: ActionCreator<Action> = (): Action => ({
  type: types.ALERTS_BODY_CLEARALL
})
