import { amplitudeLogger } from 'metrics/amplitude'

export const linkLogger = (event: any, values?: object) => {
  const target = event.target.tagName !== 'A' ? event.target.parentNode : event.target
  if (target.dataset.amplitude) {
    const url = target?.href || 'undefined'
    const data = values || {}
    amplitudeLogger(target.dataset.amplitude, {
      ...data,
      url
    })
  }
}

export const eventLogger = (event: any, values?: object) => {
  if (event.target.dataset.amplitude) {
    const data = values || {}
    amplitudeLogger(event.target.dataset.amplitude, data)
  }
}

export const standardLogger = (name: string, values?: object) => {
  const data = values || {}
  if (name) {
    amplitudeLogger(name, data)
  }
}
