var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import cl from 'clsx';
import React, { useState, forwardRef, useEffect } from 'react';
const Avatar = forwardRef((_a, ref) => {
    var { alt = 'avatar', border = false, className, defaultSrc, loading = false, size = 'medium', spacing = false, src, variant = 'circle' } = _a, rest = __rest(_a, ["alt", "border", "className", "defaultSrc", "loading", "size", "spacing", "src", "variant"]);
    const [_portrait, setPortrait] = useState(false);
    const [failedImage, setFailedImage] = useState(!(src !== null && src !== void 0 ? src : defaultSrc));
    useEffect(() => {
        setFailedImage(!(src !== null && src !== void 0 ? src : defaultSrc));
    }, [src, defaultSrc]);
    return (React.createElement("div", Object.assign({}, rest, { ref: ref, className: cl('cds-avatar', `cds-avatar--${size}`, `cds-avatar--${variant}`, {
            'cds-avatar--border': border,
            'cds-avatar--loading': loading,
            'cds-avatar--portrait': _portrait,
            'cds-avatar--spacing': spacing
        }, className) }), failedImage ? (variant !== 'circle' ? (React.createElement("svg", { width: '95', height: '95', viewBox: '0 0 95 95', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
        React.createElement("rect", { x: '1.5', y: '1.5', width: '92', height: '92', rx: '3.5', stroke: 'currentColor', strokeWidth: '3' }),
        React.createElement("g", { clipPath: 'url(%23clip0_423_1165)' },
            React.createElement("path", { d: 'M47.4997 47.5C56.2476 47.5 63.333 40.4146 63.333 31.6667C63.333 22.9188 56.2476 15.8333 47.4997 15.8333C38.7518 15.8333 31.6663 22.9188 31.6663 31.6667C31.6663 40.4146 38.7518 47.5 47.4997 47.5ZM47.4997 55.4167C36.9309 55.4167 15.833 60.7208 15.833 71.25V79.1667H79.1663V71.25C79.1663 60.7208 58.0684 55.4167 47.4997 55.4167Z', fill: 'currentColor' })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: 'clip0_423_1165' },
                React.createElement("rect", { width: '95', height: '95', fill: 'white' }))))) : (React.createElement("svg", { width: '40', height: '40', viewBox: '0 0 40 40', fill: 'none', xmlns: 'http://www.w3.org/2000/svg' },
        React.createElement("path", { fillRule: 'evenodd', clipRule: 'evenodd', d: 'M20 24C24.42 24 28 20.42 28 16C28 11.58 24.42 8 20 8C15.58 8 12 11.58 12 16C12 20.42 15.58 24 20 24ZM20 28C14.66 28 4 30.68 4 36V36.0219C4 34.9799 4.40894 34.0392 5.10676 33.2C8.40447 37.1381 13.4057 39.7379 19.0553 40H20.9447C26.5943 39.7379 31.5955 37.1381 34.8932 33.2C34.9296 33.2438 34.9652 33.2878 35 33.3321V33.3102C32.2252 29.7773 24.3486 28 20 28Z', fill: 'currentColor' }),
        React.createElement("circle", { cx: '20', cy: '20', r: '19.5', stroke: 'currentColor' })))) : (React.createElement("img", Object.assign({}, rest, { alt: alt, ref: ref, src: src !== null && src !== void 0 ? src : defaultSrc, onLoad: ({ currentTarget }) => {
            if (currentTarget.width < currentTarget.height) {
                setPortrait(true);
            }
        }, onError: ({ currentTarget }) => {
            currentTarget.onerror = null;
            setFailedImage(true);
        } })))));
});
Avatar.displayName = 'Avatar';
export default Avatar;
