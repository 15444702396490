import {
  BodyShort,
  Button,
  FlexCenterDiv,
  FlexDiv,
  Heading,
  HorizontalSpace,
  Loader,
  Pagination,
  Table,
  TextField,
  VerticalSpace
} from '@cegal/ds-components'
import { deleteDepartment, editDepartment, listDepartments, newDepartment } from 'actions'
import { Department } from 'declarations/models'
import { useAppDispatch, useAppSelector } from 'store'

import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useQuery from 'hooks/useQuery'

import BasicRowView from '../../components/Admin/BasicRowView'

const AdminDepartments = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [isNewMode, setIsNewMode] = useState<boolean>(false)

  const departments = useAppSelector((state) => state.departments.list)
  const loadingDepartments = useAppSelector((state) => state.departments.listing)
  const itemsPerPage = useAppSelector((state) => state.app.settings.itemsPerPage)

  const { query, setQuery } = useQuery<{
    page: string
    search: string
  }>({ page: '1', search: '' })
  const page = query?.page ? +query?.page : 1

  useEffect(() => {
    if (!loadingDepartments && departments === undefined) {
      dispatch(listDepartments())
    }
  }, [])

  const refreshDepartments = () => {
    dispatch(listDepartments())
  }

  const handlePageChange = (page: number) => {
    window.scrollTo(0, 0)
    setQuery({ page: page?.toString() })
  }

  const filteredDepartments = useMemo(
    () =>
      query.search
        ? departments?.filter((departments: Department) =>
            departments.name?.toLowerCase().includes(query.search!!.toLowerCase())
          ) ?? null
        : departments ?? null,
    [departments, query.search]
  )

  const departmentsPage = useMemo(() => {
    const firstPageIndex = (page - 1) * itemsPerPage
    const lastPageIndex = firstPageIndex + itemsPerPage
    return filteredDepartments?.slice(firstPageIndex, lastPageIndex) ?? null
  }, [page, filteredDepartments])

  const paginationRange = useMemo(() => {
    const numberOfPages = Math.ceil((filteredDepartments?.length ?? 0) / itemsPerPage)
    return numberOfPages
  }, [itemsPerPage, filteredDepartments])

  const headers: { label: string }[] = [{ label: t('label:id') }, { label: t('label:name') }, { label: '' }]

  const handleFilter = (value: { search: string }) => {
    setQuery(value)
  }

  return (
    <>
      <Heading level='2' size='medium' spacing>
        {t('label:departments-title')}
      </Heading>
      <FlexDiv style={{ justifyContent: 'space-between' }}>
        <FlexDiv>
          {loadingDepartments ? (
            <FlexCenterDiv>
              <Loader />
              <HorizontalSpace />
              <BodyShort>{t('messages:loading-departments')}...</BodyShort>
            </FlexCenterDiv>
          ) : (
            <BodyShort>
              {t('messages:departments-list-result', {
                x: filteredDepartments?.length
              })}
            </BodyShort>
          )}
        </FlexDiv>
        <TextField
          hideLabel
          description={t('label:search-description')}
          label={t('label:filter')}
          placeholder={t('label:search-title')}
          value={query?.search || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleFilter({ search: e.target.value || '' })
          }
        />
        <FlexDiv>
          <Button onClick={() => setIsNewMode(true)}>
            {t('buttons:add-new-x', { x: t('label:department-title') })}
          </Button>
          <HorizontalSpace />
          <Button onClick={refreshDepartments}>
            {t('buttons:refresh-x', { x: t('label:departments-title') })}
          </Button>
        </FlexDiv>
      </FlexDiv>
      <VerticalSpace />
      <Table style={{ tableLayout: 'fixed', width: '100%' }}>
        <Table.Header>
          <Table.Row>
            {headers.map((row) => (
              <Table.ColumnHeader key={`row-label-${row.label}`}>{row.label}</Table.ColumnHeader>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {isNewMode && (
            <BasicRowView<Department>
              onCancel={() => setIsNewMode(false)}
              isNewMode
              onNew={(item) => dispatch(newDepartment(item))}
            />
          )}
          {departmentsPage?.map((department: Department) => (
            <BasicRowView
              key={department.id}
              item={department}
              onEdit={(item) => dispatch(editDepartment(item))}
              onDelete={(item) => dispatch(deleteDepartment(item))}
            />
          ))}
        </Table.Body>
      </Table>

      {paginationRange > 1 && (
        <>
          <VerticalSpace size='2' />
          <Pagination
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
            size='medium'
            count={paginationRange}
            page={page}
            onPageChange={handlePageChange}
          />
        </>
      )}
    </>
  )
}

export default AdminDepartments
