import * as types from 'constants/actionTypes'
import * as urls from 'constants/urls'
import { AnyAction } from '@reduxjs/toolkit'
import api from 'api'
import {
  AiTab,
  AllowedLanguages,
  AllowedThemes,
  DisplaySize,
  FormMode,
  HomeMode,
  ViewMode,
  WidgetTable
} from 'declarations/app'
import mockUserProfile from 'mocks/userProfile'
import { Action, ActionCreator } from 'redux'
import { sprintf } from 'sprintf-js'

import i18n from 'i18n'

export const setApiReady: ActionCreator<Action> = (): Action => ({
  type: types.APP_API_READY
})

export const getAppStats = (): AnyAction => {
  return api.call({
    url: urls.APP_STATS_URL,
    skipFake: true,
    type: {
      request: types.APP_STATS_REQUEST,
      success: types.APP_STATS_SUCCESS,
      failure: types.APP_STATS_FAILURE
    }
  })
}

export const getUserProfile = (): AnyAction => {
  return api.call({
    url: urls.APP_PROFILE_URL,
    expectedPayload: mockUserProfile,
    type: {
      request: types.APP_PROFILE_REQUEST,
      success: types.APP_PROFILE_SUCCESS,
      failure: types.APP_PROFILE_FAILURE
    }
  })
}

export const getUserId = (email: string): AnyAction => {
  return api.call({
    url: sprintf(urls.APP_USERID_URL, { email: encodeURIComponent(email) }),
    expectedPayload: { user_id: 1 },
    type: {
      request: types.APP_USERID_REQUEST,
      success: types.APP_USERID_SUCCESS,
      failure: types.APP_USERID_FAILURE
    }
  })
}

export const logout = () => ({
  type: types.APP_LOGOUT
})

export const setWidgetTable = (widgetTable: WidgetTable): AnyAction => ({
  type: types.APP_WIDGETTABLE_SET,
  payload: widgetTable
})

export const setHomeMode = (homeMode: HomeMode): AnyAction => ({
  type: types.APP_SETTINGS_SET,
  payload: {
    key: 'homeMode',
    value: homeMode
  }
})

export const setFormMode = (formMode: FormMode): AnyAction => ({
  type: types.APP_SETTINGS_SET,
  payload: {
    key: 'formMode',
    value: formMode
  }
})

export const setItemsPerPage = (itemsPerPage: number): AnyAction => ({
  type: types.APP_SETTINGS_SET,
  payload: {
    key: 'itemsPerPage',
    value: itemsPerPage
  }
})

export const setLanguage = (language: AllowedLanguages): AnyAction => {
  i18n.changeLanguage(language) // ?.then((r) => console.log('language changed'))
  document.documentElement.lang = language
  return {
    type: types.APP_SETTINGS_SET,
    payload: {
      key: 'language',
      value: language
    }
  }
}

export const setTableHeaders = (table: string, headers: Array<string>): AnyAction => ({
  type: types.APP_TABLES_SET,
  payload: {
    table,
    headers
  }
})

export const setTheme = (theme: AllowedThemes): AnyAction => ({
  type: types.APP_SETTINGS_SET,
  payload: {
    key: 'theme',
    value: theme
  }
})

export const setViewMode = (viewMode: ViewMode): AnyAction => ({
  type: types.APP_SETTINGS_SET,
  payload: {
    key: 'viewMode',
    value: viewMode
  }
})

export const setAiDrawerOpen = (aiDrawerOpen: boolean): AnyAction => ({
  type: types.APP_SETTINGS_SET,
  payload: {
    key: 'aiDrawerOpen',
    value: aiDrawerOpen
  }
})

export const setAiTab = (aiTab: AiTab): AnyAction => ({
  type: types.APP_SETTINGS_SET,
  payload: {
    key: 'aiTab',
    value: aiTab
  }
})

export const setDisplaySize = (displaySize: DisplaySize): AnyAction => ({
  type: types.APP_DISPLAYSIZE_SET,
  payload: displaySize
})

export const signUp = (email: string) => {
  return api.call({
    url: sprintf(urls.APP_SIGNUP_NEW_URL, { email: encodeURIComponent(email) }),
    expectedPayload: email,
    skipFake: true,
    cascadeFailureError: true,
    type: {
      request: types.APP_SIGNUP_NEW_REQUEST,
      success: types.APP_SIGNUP_NEW_SUCCESS,
      failure: types.APP_SIGNUP_NEW_FAILURE
    }
  })
}
