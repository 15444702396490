import { ThunkDispatch } from '@reduxjs/toolkit'
import { listConsultants, listFollowedConsultants, searchConsultantsByAllocations } from 'actions'
import { UseFilterObject } from 'declarations/app'
import { Consultant, ConsultantsQuery } from 'declarations/models'
import { AnyAction } from 'redux'
import { RootState, useAppDispatch, useAppSelector } from 'store'

import { Dispatch, useEffect, useState } from 'react'

import { filterConsultants, getSearchableConsultants, sortConsultants } from 'hooks/useConsultantFilterUtils'
import useOrgChart from 'hooks/useOrgChart'
import useQuery from 'hooks/useQuery'

import _ from 'lodash'

export type SearchableConsultant = {
  textSearch: Record<string, Array<string>>
  consultant: Consultant
  score?: number
}
type DispatchedConsultantState = Array<Consultant> | null | undefined

const useConsultantFilter = (
  initialQuery: ConsultantsQuery,
  handlePageChange: (page: number) => void
): UseFilterObject<Consultant> => {
  const dispatch: ThunkDispatch<any, undefined, AnyAction> & Dispatch<AnyAction> = useAppDispatch()
  const { loaded: orgChartLoaded, loading: orgchartLoading, getSubTreeIds } = useOrgChart()

  const consultants: DispatchedConsultantState = useAppSelector((state: RootState) => state.consultants.list)
  const consultantsLoading: boolean = useAppSelector((state: RootState) => state.consultants.listing)
  const itemsPerPage: number = useAppSelector((state: RootState) => state.app.settings.itemsPerPage)
  const apiReady: boolean = useAppSelector((state: RootState) => state.app.apiReady)
  const followedConsultants: DispatchedConsultantState = useAppSelector(
    (state: RootState) => state.followedConsultants.list
  )
  const allocations: Array<any> | null = useAppSelector((state) => state.cache.consultantAllocationsSearch)
  const allocationsLoading: boolean = useAppSelector((state) => state.cache.consultantAllocationsSearching)
  const allocationsKey: string = useAppSelector((state) => state.cache.consultantAllocationsSearchKey)

  const [searchableConsultants, setSearchableConsultants] = useState<SearchableConsultant[]>([])
  const [numberOfFilteredConsultants, setNumberOfFilteredConsultants] = useState<number>(0)
  const [paginatedConsultants, setPaginatedConsultants] = useState<Consultant[]>([])
  const [numberOfPages, setNumberOfPages] = useState<number>(0)

  const { query } = useQuery(initialQuery)

  const setUrlSearchParams = (init: Record<string, string | number | boolean | object | undefined>): string =>
    new URLSearchParams(init as Record<string, string>).toString()

  const generateSearchKey = (
    start_date: string | undefined,
    end_date: string | undefined,
    allocation: Array<number> | undefined
  ) => {
    if (!_.isEmpty(start_date) && !_.isEmpty(end_date) && !_.isEmpty(allocation)) {
      return setUrlSearchParams({
        start_date,
        end_date,
        from: allocation?.[0],
        to: allocation?.[1]
      })
    }
    return null
  }

  const loadPageData = (): void => {
    dispatch(listConsultants())
    if (_.isUndefined(followedConsultants)) {
      dispatch(listFollowedConsultants())
    }
  }

  useEffect((): void => {
    if (consultants === undefined && apiReady && !consultantsLoading) {
      loadPageData()
    }
  }, [apiReady, query, consultantsLoading])

  useEffect((): void => {
    if (apiReady) {
      const key = generateSearchKey(query.allocationFrom, query.allocationTo, query.allocation ?? [0, 100])
      if (key && allocationsKey !== key) {
        dispatch(searchConsultantsByAllocations(key, consultants!!))
        loadPageData()
      }
    }
  }, [apiReady, query.allocationFrom, query.allocationTo, query.allocation])

  useEffect((): void => {
    if (consultants?.length) {
      const searchableConsultantsList: Array<SearchableConsultant> = getSearchableConsultants(consultants)
      setSearchableConsultants(searchableConsultantsList)
    }
  }, [consultants])

  useEffect(() => {
    const _filteredConsultants: Array<SearchableConsultant> | undefined = filterConsultants({
      searchableConsultants,
      query,
      followedConsultants,
      getSubTreeIds,
      allocations
    }).sort(sortConsultants(query))

    setNumberOfFilteredConsultants(_filteredConsultants?.length ?? 0)

    const page = query?.page ? +query?.page : 1
    const firstPageIndex = (page - 1) * itemsPerPage

    const lastPageIndex = firstPageIndex + itemsPerPage

    const _paginatedConsultants = _filteredConsultants?.slice(firstPageIndex, lastPageIndex)

    if (searchableConsultants.length > 0 && _paginatedConsultants.length === 0) {
      handlePageChange(1)
    }
    setPaginatedConsultants(_paginatedConsultants.map((c: SearchableConsultant) => c.consultant))
    const _numberOfPages = Math.ceil(_filteredConsultants?.length / itemsPerPage)

    setNumberOfPages(_numberOfPages)
  }, [query, itemsPerPage, searchableConsultants])

  return {
    loadPageData,
    loading: orgchartLoading || consultantsLoading || allocationsLoading,
    loaded: orgChartLoaded && !!consultants && !!allocations,
    total: numberOfFilteredConsultants,
    items: paginatedConsultants,
    pages: numberOfPages
  }
}

export default useConsultantFilter
