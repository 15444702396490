import { useEffect, useLayoutEffect } from 'react';
export { default as AnimateHeight } from './AnimateHeight';
export * from './debounce';
export * from './mergeRefs';
export { default as TextareaAutosize } from './TextareaAutoSize';
export * from './useId';
const canUseDOM = () => {
    return (typeof window !== 'undefined' &&
        typeof window.document !== 'undefined' &&
        typeof window.document.createElement !== 'undefined');
};
export const useClientLayoutEffect = canUseDOM() ? useLayoutEffect : () => { };
export const omit = (obj, props) => Object.entries(obj)
    .filter(([key]) => !props.includes(key))
    .reduce((obj, [key, value]) => (Object.assign(Object.assign({}, obj), { [key]: value })), {});
export const useEventListener = (name, handler, target = typeof window !== 'undefined' ? window : null) => {
    useEffect(() => {
        if (!target) {
            return;
        }
        target === null || target === void 0 ? void 0 : target.addEventListener(name, handler);
        return () => {
            target === null || target === void 0 ? void 0 : target.removeEventListener(name, handler);
        };
    }, [name, handler, target]);
};
export const composeEventHandlers = (originalEventHandler, ourEventHandler) => {
    return function handleEvent(event) {
        originalEventHandler === null || originalEventHandler === void 0 ? void 0 : originalEventHandler(event);
        if (!event.defaultPrevented) {
            return ourEventHandler === null || ourEventHandler === void 0 ? void 0 : ourEventHandler(event);
        }
    };
};
