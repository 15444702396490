import { Button, FlexDiv, Heading, HorizontalSpace, Tooltip, VerticalSpace } from '@cegal/ds-components'
import { Add } from '@cegal/ds-icons'
import { StickyNote2 } from '@cegal/ds-icons/dist/StickyNote2'
import { deleteNote, editNote, listNotes, newNote, resetNote } from 'actions'
import { NOTES_LIST_FAILURE } from 'constants/actionTypes'
import { Note } from 'declarations/models'
import { standardLogger } from 'metrics/loggers'
import { useAppDispatch, useAppSelector } from 'store'

import AlertPanel from 'components/AlertPanel/AlertPanel'
import Widget from 'components/Home/Widget/Widget'
import { StretchLoader } from 'components/Loader/CustomLoader'
import { CardContainer } from 'components/styled'

import { HomePanelProps } from 'pages/Home/Home'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import NoteCard from './NoteCard'
import NoteModal from './NoteModal'

import _ from 'lodash'

const MyNotes = ({ mode }: HomePanelProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [workingCopyNote, setWorkingCopyNote] = useState<Partial<Note> | undefined>(undefined)

  const apiReady = useAppSelector((state) => state.app.apiReady)
  const notes = useAppSelector((state) => state.notes.list)

  const initialNote: Partial<Note> = {}

  const loadPageData = () => {
    dispatch(listNotes())
  }

  const onCloseNoteModal = () => {
    setWorkingCopyNote(undefined)
  }

  const onAddNote = () => {
    dispatch(resetNote())
    setWorkingCopyNote(initialNote)
  }

  const onEditNote = (note: Note) => {
    dispatch(resetNote())
    setWorkingCopyNote(note)
  }

  const onNewNote = (note: Note) => {
    standardLogger('note.new')
    dispatch(newNote(note))
  }

  const onSaveNote = (note: Note) => {
    standardLogger('note.save')
    dispatch(editNote(note!))
  }

  const onDeleteNote = (note: Note) => {
    standardLogger('note.delete')
    dispatch(deleteNote(note!))
  }

  const renderTitle = () => (
    <FlexDiv>
      <StickyNote2 size='2rem' style={{ transform: 'scaleY(-1)' }} />
      <HorizontalSpace />
      <Heading>{t('home:notes-title')}</Heading>
    </FlexDiv>
  )

  const renderBody = () => (
    <>
      <NoteModal
        open={workingCopyNote !== undefined}
        note={workingCopyNote}
        onClose={onCloseNoteModal}
        onNewNote={onNewNote}
        onSaveNote={onSaveNote}
      />
      {_.isUndefined(notes) && <StretchLoader />}
      {_.isNull(notes) && (
        <AlertPanel
          doNotRenderIfHeadIsActiveWithError
          watchFor={[NOTES_LIST_FAILURE]}
          buttonText={t('buttons:click-to-reload-my-notes')!}
          reloadPage={loadPageData}
        />
      )}
      {!_.isNil(notes) &&
        (!_.isEmpty(notes)
          ? notes!.map((note) => (
              <NoteCard onEdit={onEditNote} onDelete={onDeleteNote} key={note.id} item={note} />
            ))
          : t('messages:no-notes'))}
    </>
  )

  useEffect(() => {
    if (notes === undefined && apiReady) {
      loadPageData()
    }
  }, [apiReady])

  if (mode === 'widget') {
    return (
      <Widget
        header={renderTitle()}
        buttons={[
          <Tooltip key='add-button' content='Add a new note' variant='primary'>
            <Button
              aria-label='New note'
              variant='secondary'
              size='small'
              data-cy='newNote'
              icon={<Add size='1.2rem' />}
              onClick={onAddNote}
            />
          </Tooltip>
        ]}
      >
        {renderBody()}
      </Widget>
    )
  }

  return (
    <>
      <FlexDiv>
        {renderTitle()}
        <HorizontalSpace />
        <Tooltip key='add-button' content='Add a new note' variant='primary'>
          <Button
            aria-label='New note'
            variant='secondary'
            size='small'
            data-cy='newNote'
            icon={<Add size='1.2rem' />}
            onClick={onAddNote}
          />
        </Tooltip>
      </FlexDiv>
      <VerticalSpace />
      <CardContainer>{renderBody()}</CardContainer>
      <VerticalSpace size='2' />
    </>
  )
}

export default MyNotes
