import * as reducers from 'reducers'
import { Reducer, combineReducers, configureStore } from '@reduxjs/toolkit'

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

const rootReducer = combineReducers(reducers)

export const setupStore = (reducer: Reducer<any>, preloadedState?: any) => {
  return configureStore({
    reducer,
    preloadedState,
    devTools: true
  })
}

const store = setupStore(rootReducer)

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']

export default store
