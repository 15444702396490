var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import cl from 'clsx';
import { Fieldset, FieldsetContext } from 'components/form';
import React, { forwardRef, useContext } from 'react';
import { useId } from 'util/index';
export const RadioGroupContext = React.createContext(null);
export const RadioGroup = forwardRef((_a, ref) => {
    var _b, _c;
    var { children, className, name, defaultValue, value, onChange = () => { }, required } = _a, rest = __rest(_a, ["children", "className", "name", "defaultValue", "value", "onChange", "required"]);
    const fieldset = useContext(FieldsetContext);
    const nameId = useId();
    return (React.createElement(Fieldset, Object.assign({}, rest, { ref: ref, className: cl('cds-radio-group', `cds-radio-group--${(_c = (_b = rest.size) !== null && _b !== void 0 ? _b : fieldset === null || fieldset === void 0 ? void 0 : fieldset.size) !== null && _c !== void 0 ? _c : 'medium'}`, className) }),
        React.createElement(RadioGroupContext.Provider, { value: {
                name: name !== null && name !== void 0 ? name : `radioGroupName-${nameId}`,
                defaultValue,
                value,
                onChange,
                required
            } },
            React.createElement("div", { className: 'cds-radio-buttons' }, children))));
});
RadioGroup.displayName = 'RadioGroup';
export default RadioGroup;
