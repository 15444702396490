import * as types from 'constants/actionTypes'
// import api from 'api'
// import { ORGCHART_GET_URL } from 'constants/urls'
import mockOrgChart from 'mocks/orgChart.json'

export const getOrgChart = () => ({
  type: types.ORGCHART_GET_SUCCESS,
  payload: mockOrgChart
})

/*
  export const getOrgChart = () => {
  return api.call({
    method: 'PUT',
    url: ORGCHART_GET_URL,
    expectedPayload: mockOrgChart,
    type: {
      request: types.ORGCHART_GET_REQUEST,
      success: types.ORGCHART_GET_SUCCESS,
      failure: types.ORGCHART_GET_FAILURE
    }
  })
} */
