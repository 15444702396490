var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Container } from 'layout/containers';
import RCDrawer from 'rc-drawer';
import React, { useState } from 'react';
import motionProps from './motion';
import 'rc-motion';
const Drawer = (_a) => {
    var { animate = true, children, defaultOpen = false, destroyOnClose = true, open = false, overlay = false, overlayCloses = false, placement = 'left' } = _a, rest = __rest(_a, ["animate", "children", "defaultOpen", "destroyOnClose", "open", "overlay", "overlayCloses", "placement"]);
    const [internalOpen, setInternalOpen] = useState(defaultOpen);
    const animateProps = animate ? Object.assign({}, motionProps) : {};
    return (React.createElement(RCDrawer, Object.assign({}, rest, animateProps, { rootClassName: 'cds-drawer', open: open !== null && open !== void 0 ? open : internalOpen, placement: placement, mask: overlay, maskClosable: overlayCloses, afterOpenChange: setInternalOpen }),
        React.createElement(Container, { style: { height: '100%' } }, children)));
};
Drawer.displayName = 'Drawer';
export default Drawer;
