import { Consultant } from 'declarations/models'

export default {
  id: 1,
  name: 'Ola Nordmann',
  email: 'ola@nordmann.no',
  cvpartner_user_id: 'a1b2c3d4',
  cv_id: '12345',
  department: {
    id: 1,
    name: 'IT Department'
  },
  location: {
    id: 1,
    name: 'Lysaker'
  },
  skills: [
    {
      id: 1,
      name: 'Java'
    },
    {
      id: 2,
      name: 'JavaScript'
    },
    {
      id: 3,
      name: 'Kotlin'
    },
    {
      id: 4,
      name: 'Go Lang'
    },
    {
      id: 5,
      name: 'PostgreSQL'
    },
    {
      id: 6,
      name: 'Kafka streams'
    },
    {
      id: 7,
      name: 'REST APIs'
    }
  ],
  engagements: [
    {
      id: 1,
      title: 'CIP project',
      start_date: '2021-01-01',
      end_date: '2023-01-01',
      status: 'in_progress',
      notes: 'Requested by sales rep Olav Nordmann',
      vacancies: {
        id: 1,
        title: 'Senior Java developer',
        allocation: 'full-time',
        hourly_rate: 0
      }
    }
  ],
  roles: [
    {
      id: 1,
      name: 'Tech lead'
    },
    {
      id: 2,
      name: 'Manager'
    },
    {
      id: 3,
      name: 'Developer'
    },
    {
      id: 4,
      name: 'SysAdmin'
    }
  ]
} as Consultant
