export const ALERTS_HEAD_CLEAR = 'ALERTS_HEAD_CLEAR'
export const ALERTS_BODY_CLEAR = 'ALERTS_BODY_CLEAR'
export const ALERTS_BODY_CLEARALL = 'ALERTS_BODY_CLEARALL'

export const AI_GET_REQUEST = 'AI_GET_REQUEST'
export const AI_GET_SUCCESS = 'AI_GET_SUCCESS'
export const AI_GET_FAILURE = 'AI_GET_FAILURE'

export const ADMIN_SYNCING_LIST_REQUEST = 'ADMIN_SYNCING_LIST_REQUEST'
export const ADMIN_SYNCING_LIST_SUCCESS = 'ADMIN_SYNCING_LIST_SUCCESS'
export const ADMIN_SYNCING_LIST_FAILURE = 'ADMIN_SYNCING_LIST_FAILURE'
export const ADMIN_SYNCING_GET_REQUEST = 'ADMIN_SYNCING_GET_REQUEST'
export const ADMIN_SYNCING_GET_SUCCESS = 'ADMIN_SYNCING_GET_SUCCESS'
export const ADMIN_SYNCING_GET_FAILURE = 'ADMIN_SYNCING_GET_FAILURE'
export const ADMIN_SYNCING_POST_REQUEST = 'ADMIN_SYNCING_POST_REQUEST'
export const ADMIN_SYNCING_POST_SUCCESS = 'ADMIN_SYNCING_POST_SUCCESS'
export const ADMIN_SYNCING_POST_FAILURE = 'ADMIN_SYNCING_POST_FAILURE'

export const APP_API_READY = 'APP_API_READY'
export const APP_WIDGETTABLE_SET = 'APP_WIDGETTABLE_SET'
export const APP_DISPLAYSIZE_SET = 'APP_DISPLAYSIZE_SET'

export const APP_LOGOUT = 'APP_LOGOUT'
export const APP_PROFILE_REQUEST = 'APP_PROFILE_REQUEST'
export const APP_PROFILE_SUCCESS = 'APP_PROFILE_SUCCESS'
export const APP_PROFILE_FAILURE = 'APP_PROFILE_FAILURE'
export const APP_SETTINGS_SET = 'APP_SETTINGS_SET'
export const APP_TABLES_SET = 'APP_TABLES_SET'
export const APP_SIGNUP_NEW_REQUEST = 'APP_SIGNUP_NEW_REQUEST'
export const APP_SIGNUP_NEW_SUCCESS = 'APP_SIGNUP_NEW_SUCCESS'
export const APP_SIGNUP_NEW_FAILURE = 'APP_SIGNUP_NEW_FAILURE'
export const APP_STATS_REQUEST = 'APP_STATS_REQUEST'
export const APP_STATS_SUCCESS = 'APP_STATS_SUCCESS'
export const APP_STATS_FAILURE = 'APP_STATS_FAILURE'
export const APP_USERID_REQUEST = 'APP_USERID_REQUEST'
export const APP_USERID_SUCCESS = 'APP_USERID_SUCCESS'
export const APP_USERID_FAILURE = 'APP_USERID_FAILURE'
export const CACHE_SET = 'CACHE_SET'

export const CATEGORIES_DELETE_REQUEST = 'CATEGORIES_DELETE_REQUEST'
export const CATEGORIES_DELETE_SUCCESS = 'CATEGORIES_DELETE_SUCCESS'
export const CATEGORIES_DELETE_FAILURE = 'CATEGORIES_DELETE_FAILURE'
export const CATEGORIES_EDIT_REQUEST = 'CATEGORIES_EDIT_REQUEST'
export const CATEGORIES_EDIT_SUCCESS = 'CATEGORIES_EDIT_SUCCESS'
export const CATEGORIES_EDIT_FAILURE = 'CATEGORIES_EDIT_FAILURE'
export const CATEGORIES_GET_REQUEST = 'CATEGORIES_GET_REQUEST'
export const CATEGORIES_GET_SUCCESS = 'CATEGORIES_GET_SUCCESS'
export const CATEGORIES_GET_FAILURE = 'CATEGORIES_GET_FAILURE'
export const CATEGORIES_LIST_REQUEST = 'CATEGORIES_LIST_REQUEST'
export const CATEGORIES_LIST_SUCCESS = 'CATEGORIES_LIST_SUCCESS'
export const CATEGORIES_LIST_FAILURE = 'CATEGORIES_LIST_FAILURE'
export const CATEGORIES_NEW_REQUEST = 'CATEGORIES_NEW_REQUEST'
export const CATEGORIES_NEW_SUCCESS = 'CATEGORIES_NEW_SUCCESS'
export const CATEGORIES_NEW_FAILURE = 'CATEGORIES_NEW_FAILURE'

export const CLIENTS_AVATAR_REQUEST = 'CLIENTS_AVATAR_REQUEST'
export const CLIENTS_AVATAR_SUCCESS = 'CLIENTS_AVATAR_SUCCESS'
export const CLIENTS_AVATAR_FAILURE = 'CLIENTS_AVATAR_FAILURE'
export const CLIENTS_CLIENT_SET = 'CLIENTS_CLIENT_SET'
export const CLIENTS_DELETE_REQUEST = 'CLIENTS_DELETE_REQUEST'
export const CLIENTS_DELETE_SUCCESS = 'CLIENTS_DELETE_SUCCESS'
export const CLIENTS_DELETE_FAILURE = 'CLIENTS_DELETE_FAILURE'
export const CLIENTS_EDIT_REQUEST = 'CLIENTS_EDIT_REQUEST'
export const CLIENTS_EDIT_SUCCESS = 'CLIENTS_EDIT_SUCCESS'
export const CLIENTS_EDIT_FAILURE = 'CLIENTS_EDIT_FAILURE'
export const CLIENTS_GET_REQUEST = 'CLIENTS_GET_REQUEST'
export const CLIENTS_GET_SUCCESS = 'CLIENTS_GET_SUCCESS'
export const CLIENTS_GET_FAILURE = 'CLIENTS_GET_FAILURE'
export const CLIENTS_LIST_REQUEST = 'CLIENTS_LIST_REQUEST'
export const CLIENTS_LIST_SUCCESS = 'CLIENTS_LIST_SUCCESS'
export const CLIENTS_LIST_FAILURE = 'CLIENTS_LIST_FAILURE'
export const CLIENTS_NEW_REQUEST = 'CLIENTS_NEW_REQUEST'
export const CLIENTS_NEW_SUCCESS = 'CLIENTS_NEW_SUCCESS'
export const CLIENTS_NEW_FAILURE = 'CLIENTS_NEW_FAILURE'
export const CLIENTS_RESET = 'CLIENTS_RESET'

export const CLIENT_COMMENTS_DELETE_REQUEST = 'CLIENT_COMMENTS_DELETE_REQUEST'
export const CLIENT_COMMENTS_DELETE_SUCCESS = 'CLIENT_COMMENTS_DELETE_SUCCESS'
export const CLIENT_COMMENTS_DELETE_FAILURE = 'CLIENT_COMMENTS_DELETE_FAILURE'
export const CLIENT_COMMENTS_EDIT_REQUEST = 'CLIENT_COMMENTS_EDIT_REQUEST'
export const CLIENT_COMMENTS_EDIT_SUCCESS = 'CLIENT_COMMENTS_EDIT_SUCCESS'
export const CLIENT_COMMENTS_EDIT_FAILURE = 'CLIENT_COMMENTS_EDIT_FAILURE'
export const CLIENT_COMMENTS_LIST_REQUEST = 'CLIENT_COMMENTS_LIST_REQUEST'
export const CLIENT_COMMENTS_LIST_SUCCESS = 'CLIENT_COMMENTS_LIST_SUCCESS'
export const CLIENT_COMMENTS_LIST_FAILURE = 'CLIENT_COMMENTS_LIST_FAILURE'
export const CLIENT_COMMENTS_NEW_REQUEST = 'CLIENT_COMMENTS_NEW_REQUEST'
export const CLIENT_COMMENTS_NEW_SUCCESS = 'CLIENT_COMMENTS_NEW_SUCCESS'
export const CLIENT_COMMENTS_NEW_FAILURE = 'CLIENT_COMMENTS_NEW_FAILURE'
export const CLIENT_COMMENTS_RESET = 'CLIENT_COMMENTS_RESET'
export const CONSULTANTS_ALLOCATION_REQUEST = 'CONSULTANTS_ALLOCATION_REQUEST'
export const CONSULTANTS_ALLOCATION_SUCCESS = 'CONSULTANTS_ALLOCATION_SUCCESS'
export const CONSULTANTS_ALLOCATION_FAILURE = 'CONSULTANTS_ALLOCATION_FAILURE'
export const CONSULTANTS_ALLOCATIONS_SEARCH_REQUEST = 'CONSULTANTS_ALLOCATIONS_SEARCH_REQUEST'
export const CONSULTANTS_ALLOCATIONS_SEARCH_SUCCESS = 'CONSULTANTS_ALLOCATIONS_SEARCH_SUCCESS'
export const CONSULTANTS_ALLOCATIONS_SEARCH_FAILURE = 'CONSULTANTS_ALLOCATIONS_SEARCH_FAILURE'
export const CONSULTANTS_AVATAR_REQUEST = 'CONSULTANTS_AVATAR_REQUEST'
export const CONSULTANTS_AVATAR_SUCCESS = 'CONSULTANTS_AVATAR_SUCCESS'
export const CONSULTANTS_AVATAR_FAILURE = 'CONSULTANTS_AVATAR_FAILURE'
export const CONSULTANTS_CONSULTANT_SET = 'CONSULTANTS_CONSULTANT_SET'
export const CONSULTANTS_CERTIFICATES_GET_REQUEST = 'CONSULTANTS_CERTIFICATES_GET_REQUEST'
export const CONSULTANTS_CERTIFICATES_GET_SUCCESS = 'CONSULTANTS_CERTIFICATES_GET_SUCCESS'
export const CONSULTANTS_CERTIFICATES_GET_FAILURE = 'CONSULTANTS_CERTIFICATES_GET_FAILURE'
export const CONSULTANTS_CV_REQUEST = 'CONSULTANTS_CV_REQUEST'
export const CONSULTANTS_CV_SUCCESS = 'CONSULTANTS_CV_SUCCESS'
export const CONSULTANTS_CV_FAILURE = 'CONSULTANTS_CV_FAILURE'
export const CONSULTANTS_GET_REQUEST = 'CONSULTANTS_GET_REQUEST'
export const CONSULTANTS_GET_SUCCESS = 'CONSULTANTS_GET_SUCCESS'
export const CONSULTANTS_GET_FAILURE = 'CONSULTANTS_GET_FAILURE'
export const CONSULTANTS_LIST_REQUEST = 'CONSULTANTS_LIST_REQUEST'
export const CONSULTANTS_LIST_SUCCESS = 'CONSULTANTS_LIST_SUCCESS'
export const CONSULTANTS_LIST_FAILURE = 'CONSULTANTS_LIST_FAILURE'
export const CONSULTANTS_LIST_SET = 'CONSULTANTS_LIST_SET'

export const CERTIFICATES_GET_REQUEST = 'CERTIFICATES_GET_REQUEST'
export const CERTIFICATES_GET_SUCCESS = 'CERTIFICATES_GET_SUCCESS'
export const CERTIFICATES_GET_FAILURE = 'CERTIFICATES_GET_FAILURE'
export const CERTIFICATES_LIST_REQUEST = 'CERTIFICATES_LIST_REQUEST'
export const CERTIFICATES_LIST_SUCCESS = 'CERTIFICATES_LIST_SUCCESS'
export const CERTIFICATES_LIST_FAILURE = 'CERTIFICATES_LIST_FAILURE'

export const DEPARTMENTS_DELETE_REQUEST = 'DEPARTMENTS_DELETE_REQUEST'
export const DEPARTMENTS_DELETE_SUCCESS = 'DEPARTMENTS_DELETE_SUCCESS'
export const DEPARTMENTS_DELETE_FAILURE = 'DEPARTMENTS_DELETE_FAILURE'
export const DEPARTMENTS_EDIT_REQUEST = 'DEPARTMENTS_EDIT_REQUEST'
export const DEPARTMENTS_EDIT_SUCCESS = 'DEPARTMENTS_EDIT_SUCCESS'
export const DEPARTMENTS_EDIT_FAILURE = 'DEPARTMENTS_EDIT_FAILURE'
export const DEPARTMENTS_GET_REQUEST = 'DEPARTMENTS_GET_REQUEST'
export const DEPARTMENTS_GET_SUCCESS = 'DEPARTMENTS_GET_SUCCESS'
export const DEPARTMENTS_GET_FAILURE = 'DEPARTMENTS_GET_FAILURE'
export const DEPARTMENTS_LIST_REQUEST = 'DEPARTMENTS_LIST_REQUEST'
export const DEPARTMENTS_LIST_SUCCESS = 'DEPARTMENTS_LIST_SUCCESS'
export const DEPARTMENTS_LIST_FAILURE = 'DEPARTMENTS_LIST_FAILURE'
export const DEPARTMENTS_NEW_REQUEST = 'DEPARTMENTS_NEW_REQUEST'
export const DEPARTMENTS_NEW_SUCCESS = 'DEPARTMENTS_NEW_SUCCESS'
export const DEPARTMENTS_NEW_FAILURE = 'DEPARTMENTS_NEW_FAILURE'

export const ENGAGEMENTS_RESET = 'ENGAGEMENTS_RESET'
export const ENGAGEMENTS_CREATED_MOVE = 'ENGAGEMENTS_CREATED_MOVE'
export const ENGAGEMENTS_SAVED_MOVE = 'ENGAGEMENTS_SAVED_MOVE'
export const ENGAGEMENTS_DELETE_REQUEST = 'ENGAGEMENTS_DELETE_REQUEST'
export const ENGAGEMENTS_DELETE_SUCCESS = 'ENGAGEMENTS_DELETE_SUCCESS'
export const ENGAGEMENTS_DELETE_FAILURE = 'ENGAGEMENTS_DELETE_FAILURE'
export const ENGAGEMENTS_EDIT_REQUEST = 'ENGAGEMENTS_EDIT_REQUEST'
export const ENGAGEMENTS_EDIT_SUCCESS = 'ENGAGEMENTS_EDIT_SUCCESS'
export const ENGAGEMENTS_EDIT_FAILURE = 'ENGAGEMENTS_EDIT_FAILURE'
export const ENGAGEMENTS_ENGAGEMENT_SET = 'ENGAGEMENTS_ENGAGEMENT_SET'
export const ENGAGEMENTS_GET_REQUEST = 'ENGAGEMENTS_GET_REQUEST'
export const ENGAGEMENTS_GET_SUCCESS = 'ENGAGEMENTS_GET_SUCCESS'
export const ENGAGEMENTS_GET_FAILURE = 'ENGAGEMENTS_GET_FAILURE'
export const ENGAGEMENTS_LIST_REQUEST = 'ENGAGEMENTS_LIST_REQUEST'
export const ENGAGEMENTS_LIST_SUCCESS = 'ENGAGEMENTS_LIST_SUCCESS'
export const ENGAGEMENTS_LIST_FAILURE = 'ENGAGEMENTS_LIST_FAILURE'
export const ENGAGEMENTS_NEW_REQUEST = 'ENGAGEMENTS_NEW_REQUEST'
export const ENGAGEMENTS_NEW_SUCCESS = 'ENGAGEMENTS_NEW_SUCCESS'
export const ENGAGEMENTS_NEW_FAILURE = 'ENGAGEMENTS_NEW_FAILURE'

export const ENGAGEMENTS_VACANCIES_DELETE_REQUEST = 'ENGAGEMENTS_VACANCIES_DELETE_REQUEST'
export const ENGAGEMENTS_VACANCIES_DELETE_SUCCESS = 'ENGAGEMENTS_VACANCIES_DELETE_SUCCESS'
export const ENGAGEMENTS_VACANCIES_DELETE_FAILURE = 'ENGAGEMENTS_VACANCIES_DELETE_FAILURE'
export const ENGAGEMENTS_VACANCIES_EDIT_REQUEST = 'ENGAGEMENTS_VACANCIES_EDIT_REQUEST'
export const ENGAGEMENTS_VACANCIES_EDIT_SUCCESS = 'ENGAGEMENTS_VACANCIES_EDIT_SUCCESS'
export const ENGAGEMENTS_VACANCIES_EDIT_FAILURE = 'ENGAGEMENTS_VACANCIES_EDIT_FAILURE'
export const ENGAGEMENTS_VACANCIES_GET_REQUEST = 'ENGAGEMENTS_VACANCIES_GET_REQUEST'
export const ENGAGEMENTS_VACANCIES_GET_SUCCESS = 'ENGAGEMENTS_VACANCIES_GET_SUCCESS'
export const ENGAGEMENTS_VACANCIES_GET_FAILURE = 'ENGAGEMENTS_VACANCIES_GET_FAILURE'
export const ENGAGEMENTS_VACANCIES_LIST_REQUEST = 'ENGAGEMENTS_VACANCIES_LIST_REQUEST'
export const ENGAGEMENTS_VACANCIES_LIST_SUCCESS = 'ENGAGEMENTS_VACANCIES_LIST_SUCCESS'
export const ENGAGEMENTS_VACANCIES_LIST_FAILURE = 'ENGAGEMENTS_VACANCIES_LIST_FAILURE'
export const ENGAGEMENTS_VACANCIES_NEW_REQUEST = 'ENGAGEMENTS_VACANCIES_NEW_REQUEST'
export const ENGAGEMENTS_VACANCIES_NEW_SUCCESS = 'ENGAGEMENTS_VACANCIES_NEW_SUCCESS'
export const ENGAGEMENTS_VACANCIES_NEW_FAILURE = 'ENGAGEMENTS_VACANCIES_NEW_FAILURE'
export const ENGAGEMENTS_VACANCIES_RESET = 'ENGAGEMENTS_VACANCIES_RESET'

export const ENGAGEMENTS_VACANCIES_CONSULTANTS_DELETE_REQUEST =
  'ENGAGEMENTS_VACANCIES_CONSULTANTS_DELETE_REQUEST'
export const ENGAGEMENTS_VACANCIES_CONSULTANTS_DELETE_SUCCESS =
  'ENGAGEMENTS_VACANCIES_CONSULTANTS_DELETE_SUCCESS'
export const ENGAGEMENTS_VACANCIES_CONSULTANTS_DELETE_FAILURE =
  'ENGAGEMENTS_VACANCIES_CONSULTANTS_DELETE_FAILURE'
export const ENGAGEMENTS_VACANCIES_CONSULTANTS_EDIT_REQUEST = 'ENGAGEMENTS_VACANCIES_CONSULTANTS_EDIT_REQUEST'
export const ENGAGEMENTS_VACANCIES_CONSULTANTS_EDIT_SUCCESS = 'ENGAGEMENTS_VACANCIES_CONSULTANTS_EDIT_SUCCESS'
export const ENGAGEMENTS_VACANCIES_CONSULTANTS_EDIT_FAILURE = 'ENGAGEMENTS_VACANCIES_CONSULTANTS_EDIT_FAILURE'
export const ENGAGEMENTS_VACANCIES_CONSULTANTS_GET_REQUEST = 'ENGAGEMENTS_VACANCIES_CONSULTANTS_GET_REQUEST'
export const ENGAGEMENTS_VACANCIES_CONSULTANTS_GET_SUCCESS = 'ENGAGEMENTS_VACANCIES_CONSULTANTS_GET_SUCCESS'
export const ENGAGEMENTS_VACANCIES_CONSULTANTS_GET_FAILURE = 'ENGAGEMENTS_VACANCIES_CONSULTANTS_GET_FAILURE'
export const ENGAGEMENTS_VACANCIES_CONSULTANTS_LIST_REQUEST = 'ENGAGEMENTS_VACANCIES_CONSULTANTS_LIST_REQUEST'
export const ENGAGEMENTS_VACANCIES_CONSULTANTS_LIST_SUCCESS = 'ENGAGEMENTS_VACANCIES_CONSULTANTS_LIST_SUCCESS'
export const ENGAGEMENTS_VACANCIES_CONSULTANTS_LIST_FAILURE = 'ENGAGEMENTS_VACANCIES_CONSULTANTS_LIST_FAILURE'
export const ENGAGEMENTS_VACANCIES_CONSULTANTS_NEW_REQUEST = 'ENGAGEMENTS_VACANCIES_CONSULTANTS_NEW_REQUEST'
export const ENGAGEMENTS_VACANCIES_CONSULTANTS_NEW_SUCCESS = 'ENGAGEMENTS_VACANCIES_CONSULTANTS_NEW_SUCCESS'
export const ENGAGEMENTS_VACANCIES_CONSULTANTS_NEW_FAILURE = 'ENGAGEMENTS_VACANCIES_CONSULTANTS_NEW_FAILURE'
export const ENGAGEMENTS_VACANCIES_CONSULTANTS_RESET = 'ENGAGEMENTS_VACANCIES_CONSULTANTS_RESET'

export const FOLLOWED_ENGAGEMENTS_DELETE_REQUEST = 'FOLLOWED_ENGAGEMENTS_DELETE_REQUEST'
export const FOLLOWED_ENGAGEMENTS_DELETE_SUCCESS = 'FOLLOWED_ENGAGEMENTS_DELETE_SUCCESS'
export const FOLLOWED_ENGAGEMENTS_DELETE_FAILURE = 'FOLLOWED_ENGAGEMENTS_DELETE_FAILURE'
export const FOLLOWED_ENGAGEMENTS_GET_REQUEST = 'FOLLOWED_ENGAGEMENTS_GET_REQUEST'
export const FOLLOWED_ENGAGEMENTS_GET_SUCCESS = 'FOLLOWED_ENGAGEMENTS_GET_SUCCESS'
export const FOLLOWED_ENGAGEMENTS_GET_FAILURE = 'FOLLOWED_ENGAGEMENTS_GET_FAILURE'
export const FOLLOWED_ENGAGEMENTS_LIST_REQUEST = 'FOLLOWED_ENGAGEMENTS_LIST_REQUEST'
export const FOLLOWED_ENGAGEMENTS_LIST_SUCCESS = 'FOLLOWED_ENGAGEMENTS_LIST_SUCCESS'
export const FOLLOWED_ENGAGEMENTS_LIST_FAILURE = 'FOLLOWED_ENGAGEMENTS_LIST_FAILURE'
export const FOLLOWED_ENGAGEMENTS_NEW_REQUEST = 'FOLLOWED_ENGAGEMENTS_NEW_REQUEST'
export const FOLLOWED_ENGAGEMENTS_NEW_SUCCESS = 'FOLLOWED_ENGAGEMENTS_NEW_SUCCESS'
export const FOLLOWED_ENGAGEMENTS_NEW_FAILURE = 'FOLLOWED_ENGAGEMENTS_NEW_FAILURE'
export const FOLLOWED_ENGAGEMENTS_SET = 'FOLLOWED_ENGAGEMENTS_SET'

export const FOLLOWED_CLIENTS_DELETE_REQUEST = 'FOLLOWED_CLIENTS_DELETE_REQUEST'
export const FOLLOWED_CLIENTS_DELETE_SUCCESS = 'FOLLOWED_CLIENTS_DELETE_SUCCESS'
export const FOLLOWED_CLIENTS_DELETE_FAILURE = 'FOLLOWED_CLIENTS_DELETE_FAILURE'
export const FOLLOWED_CLIENTS_GET_REQUEST = 'FOLLOWED_CLIENTS_GET_REQUEST'
export const FOLLOWED_CLIENTS_GET_SUCCESS = 'FOLLOWED_CLIENTS_GET_SUCCESS'
export const FOLLOWED_CLIENTS_GET_FAILURE = 'FOLLOWED_CLIENTS_GET_FAILURE'
export const FOLLOWED_CLIENTS_LIST_REQUEST = 'FOLLOWED_CLIENTS_LIST_REQUEST'
export const FOLLOWED_CLIENTS_LIST_SUCCESS = 'FOLLOWED_CLIENTS_LIST_SUCCESS'
export const FOLLOWED_CLIENTS_LIST_FAILURE = 'FOLLOWED_CLIENTS_LIST_FAILURE'
export const FOLLOWED_CLIENTS_NEW_REQUEST = 'FOLLOWED_CLIENTS_NEW_REQUEST'
export const FOLLOWED_CLIENTS_NEW_SUCCESS = 'FOLLOWED_CLIENTS_NEW_SUCCESS'
export const FOLLOWED_CLIENTS_NEW_FAILURE = 'FOLLOWED_CLIENTS_NEW_FAILURE'
export const FOLLOWED_CLIENTS_SET = 'FOLLOWED_CLIENTS_SET'

export const FOLLOWED_CONSULTANTS_DELETE_REQUEST = 'FOLLOWED_CONSULTANTS_DELETE_REQUEST'
export const FOLLOWED_CONSULTANTS_DELETE_SUCCESS = 'FOLLOWED_CONSULTANTS_DELETE_SUCCESS'
export const FOLLOWED_CONSULTANTS_DELETE_FAILURE = 'FOLLOWED_CONSULTANTS_DELETE_FAILURE'
export const FOLLOWED_CONSULTANTS_GET_REQUEST = 'FOLLOWED_CONSULTANTS_GET_REQUEST'
export const FOLLOWED_CONSULTANTS_GET_SUCCESS = 'FOLLOWED_CONSULTANTS_GET_SUCCESS'
export const FOLLOWED_CONSULTANTS_GET_FAILURE = 'FOLLOWED_CONSULTANTS_GET_FAILURE'
export const FOLLOWED_CONSULTANTS_LIST_REQUEST = 'FOLLOWED_CONSULTANTS_LIST_REQUEST'
export const FOLLOWED_CONSULTANTS_LIST_SUCCESS = 'FOLLOWED_CONSULTANTS_LIST_SUCCESS'
export const FOLLOWED_CONSULTANTS_LIST_FAILURE = 'FOLLOWED_CONSULTANTS_LIST_FAILURE'
export const FOLLOWED_CONSULTANTS_NEW_REQUEST = 'FOLLOWED_CONSULTANTS_NEW_REQUEST'
export const FOLLOWED_CONSULTANTS_NEW_SUCCESS = 'FOLLOWED_CONSULTANTS_NEW_SUCCESS'
export const FOLLOWED_CONSULTANTS_NEW_FAILURE = 'FOLLOWED_CONSULTANTS_NEW_FAILURE'
export const FOLLOWED_CONSULTANTS_SET = 'FOLLOWED_CONSULTANTS_SET'

export const LOCATIONS_DELETE_REQUEST = 'LOCATIONS_DELETE_REQUEST'
export const LOCATIONS_DELETE_SUCCESS = 'LOCATIONS_DELETE_SUCCESS'
export const LOCATIONS_DELETE_FAILURE = 'LOCATIONS_DELETE_FAILURE'
export const LOCATIONS_EDIT_REQUEST = 'LOCATIONS_EDIT_REQUEST'
export const LOCATIONS_EDIT_SUCCESS = 'LOCATIONS_EDIT_SUCCESS'
export const LOCATIONS_EDIT_FAILURE = 'LOCATIONS_EDIT_FAILURE'
export const LOCATIONS_GET_REQUEST = 'LOCATIONS_GET_REQUEST'
export const LOCATIONS_GET_SUCCESS = 'LOCATIONS_GET_SUCCESS'
export const LOCATIONS_GET_FAILURE = 'LOCATIONS_GET_FAILURE'
export const LOCATIONS_LIST_REQUEST = 'LOCATIONS_LIST_REQUEST'
export const LOCATIONS_LIST_SUCCESS = 'LOCATIONS_LIST_SUCCESS'
export const LOCATIONS_LIST_FAILURE = 'LOCATIONS_LIST_FAILURE'
export const LOCATIONS_LIST_LOADING = 'LOCATIONS_LIST_LOADING'
export const LOCATIONS_LIST_SET = 'LOCATIONS_LIST_SET'
export const LOCATIONS_NEW_REQUEST = 'LOCATIONS_NEW_REQUEST'
export const LOCATIONS_NEW_SUCCESS = 'LOCATIONS_NEW_SUCCESS'
export const LOCATIONS_NEW_FAILURE = 'LOCATIONS_NEW_FAILURE'

export const MATCHES_LIST_REQUEST = 'MATCHES_LIST_REQUEST'
export const MATCHES_LIST_SUCCESS = 'MATCHES_LIST_SUCCESS'
export const MATCHES_LIST_FAILURE = 'MATCHES_LIST_FAILURE'
export const MATCHES_NEW_REQUEST = 'MATCHES_NEW_REQUEST'
export const MATCHES_NEW_SUCCESS = 'MATCHES_NEW_SUCCESS'
export const MATCHES_NEW_FAILURE = 'MATCHES_NEW_FAILURE'
export const MATCHES_DELETE_REQUEST = 'MATCHES_DELETE_REQUEST'
export const MATCHES_DELETE_SUCCESS = 'MATCHES_DELETE_SUCCESS'
export const MATCHES_DELETE_FAILURE = 'MATCHES_DELETE_FAILURE'
export const MATCHES_EDIT_REQUEST = 'MATCHES_EDIT_REQUEST'
export const MATCHES_EDIT_SUCCESS = 'MATCHES_EDIT_SUCCESS'
export const MATCHES_EDIT_FAILURE = 'MATCHES_EDIT_FAILURE'
export const MATCHES_RESET = 'MATCHES_RESET'

export const NOTES_DELETE_REQUEST = 'NOTES_DELETE_REQUEST'
export const NOTES_DELETE_SUCCESS = 'NOTES_DELETE_SUCCESS'
export const NOTES_DELETE_FAILURE = 'NOTES_DELETE_FAILURE'
export const NOTES_EDIT_REQUEST = 'NOTES_EDIT_REQUEST'
export const NOTES_EDIT_SUCCESS = 'NOTES_EDIT_SUCCESS'
export const NOTES_EDIT_FAILURE = 'NOTES_EDIT_FAILURE'
export const NOTES_GET_REQUEST = 'NOTES_GET_REQUEST'
export const NOTES_GET_SUCCESS = 'NOTES_GET_SUCCESS'
export const NOTES_GET_FAILURE = 'NOTES_GET_FAILURE'
export const NOTES_LIST_REQUEST = 'NOTES_LIST_REQUEST'
export const NOTES_LIST_SUCCESS = 'NOTES_LIST_SUCCESS'
export const NOTES_LIST_FAILURE = 'NOTES_LIST_FAILURE'
export const NOTES_NEW_REQUEST = 'NOTES_NEW_REQUEST'
export const NOTES_NEW_SUCCESS = 'NOTES_NEW_SUCCESS'
export const NOTES_NEW_FAILURE = 'NOTES_NEW_FAILURE'
export const NOTES_RESET = 'NOTES_RESET'

export const NOTIFICATIONS_DELETE_REQUEST = 'NOTIFICATIONS_DELETE_REQUEST'
export const NOTIFICATIONS_DELETE_SUCCESS = 'NOTIFICATIONS_DELETE_SUCCESS'
export const NOTIFICATIONS_DELETE_FAILURE = 'NOTIFICATIONS_DELETE_FAILURE'
export const NOTIFICATIONS_EDIT_REQUEST = 'NOTIFICATIONS_EDIT_REQUEST'
export const NOTIFICATIONS_EDIT_SUCCESS = 'NOTIFICATIONS_EDIT_SUCCESS'
export const NOTIFICATIONS_EDIT_FAILURE = 'NOTIFICATIONS_EDIT_FAILURE'
export const NOTIFICATIONS_GET_REQUEST = 'NOTIFICATIONS_GET_REQUEST'
export const NOTIFICATIONS_GET_SUCCESS = 'NOTIFICATIONS_GET_SUCCESS'
export const NOTIFICATIONS_GET_FAILURE = 'NOTIFICATIONS_GET_FAILURE'
export const NOTIFICATIONS_LIST_REQUEST = 'NOTIFICATIONS_LIST_REQUEST'
export const NOTIFICATIONS_LIST_SUCCESS = 'NOTIFICATIONS_LIST_SUCCESS'
export const NOTIFICATIONS_LIST_FAILURE = 'NOTIFICATIONS_LIST_FAILURE'
export const NOTIFICATIONS_NEW_REQUEST = 'NOTIFICATIONS_NEW_REQUEST'
export const NOTIFICATIONS_NEW_SUCCESS = 'NOTIFICATIONS_NEW_SUCCESS'
export const NOTIFICATIONS_NEW_FAILURE = 'NOTIFICATIONS_NEW_FAILURE'

export const NEWS_DELETE_REQUEST = 'NEWS_DELETE_REQUEST'
export const NEWS_DELETE_SUCCESS = 'NEWS_DELETE_SUCCESS'
export const NEWS_DELETE_FAILURE = 'NEWS_DELETE_FAILURE'
export const NEWS_EDIT_REQUEST = 'NEWS_EDIT_REQUEST'
export const NEWS_EDIT_SUCCESS = 'NEWS_EDIT_SUCCESS'
export const NEWS_EDIT_FAILURE = 'NEWS_EDIT_FAILURE'
export const NEWS_GET_REQUEST = 'NEWS_GET_REQUEST'
export const NEWS_GET_SUCCESS = 'NEWS_GET_SUCCESS'
export const NEWS_GET_FAILURE = 'NEWS_GET_FAILURE'
export const NEWS_LIST_REQUEST = 'NEWS_LIST_REQUEST'
export const NEWS_LIST_SUCCESS = 'NEWS_LIST_SUCCESS'
export const NEWS_LIST_FAILURE = 'NEWS_LIST_FAILURE'
export const NEWS_NEW_REQUEST = 'NEWS_NEW_REQUEST'
export const NEWS_NEW_SUCCESS = 'NEWS_NEW_SUCCESS'
export const NEWS_NEW_FAILURE = 'NEWS_NEW_FAILURE'

export const ORGCHART_GET_REQUEST = 'ORGCHART_GET_REQUEST'
export const ORGCHART_GET_SUCCESS = 'ORGCHART_GET_SUCCESS'
export const ORGCHART_GET_FAILURE = 'ORGCHART_GET_FAILURE'

export const ROLES_DELETE_REQUEST = 'ROLES_DELETE_REQUEST'
export const ROLES_DELETE_SUCCESS = 'ROLES_DELETE_SUCCESS'
export const ROLES_DELETE_FAILURE = 'ROLES_DELETE_FAILURE'
export const ROLES_EDIT_REQUEST = 'ROLES_EDIT_REQUEST'
export const ROLES_EDIT_SUCCESS = 'ROLES_EDIT_SUCCESS'
export const ROLES_EDIT_FAILURE = 'ROLES_EDIT_FAILURE'
export const ROLES_GET_REQUEST = 'ROLES_GET_REQUEST'
export const ROLES_GET_SUCCESS = 'ROLES_GET_SUCCESS'
export const ROLES_GET_FAILURE = 'ROLES_GET_FAILURE'
export const ROLES_LIST_REQUEST = 'ROLES_LIST_REQUEST'
export const ROLES_LIST_SUCCESS = 'ROLES_LIST_SUCCESS'
export const ROLES_LIST_FAILURE = 'ROLES_LIST_FAILURE'
export const ROLES_NEW_REQUEST = 'ROLES_NEW_REQUEST'
export const ROLES_NEW_SUCCESS = 'ROLES_NEW_SUCCESS'
export const ROLES_NEW_FAILURE = 'ROLES_NEW_FAILURE'

export const SERVER_INTERNAL_ERROR = 'SERVER/INTERNAL/ERROR'
export const SERVER_UNAUTHORIZED_ERROR = 'SERVER/UNAUTHORIZED/ERROR'

export const SKILLS_DELETE_REQUEST = 'SKILLS_DELETE_REQUEST'
export const SKILLS_DELETE_SUCCESS = 'SKILLS_DELETE_SUCCESS'
export const SKILLS_DELETE_FAILURE = 'SKILLS_DELETE_FAILURE'
export const SKILLS_EDIT_REQUEST = 'SKILLS_EDIT_REQUEST'
export const SKILLS_EDIT_SUCCESS = 'SKILLS_EDIT_SUCCESS'
export const SKILLS_EDIT_FAILURE = 'SKILLS_EDIT_FAILURE'
export const SKILLS_GET_REQUEST = 'SKILLS_GET_REQUEST'
export const SKILLS_GET_SUCCESS = 'SKILLS_GET_SUCCESS'
export const SKILLS_GET_FAILURE = 'SKILLS_GET_FAILURE'
export const SKILLS_LIST_REQUEST = 'SKILLS_LIST_REQUEST'
export const SKILLS_LIST_SUCCESS = 'SKILLS_LIST_SUCCESS'
export const SKILLS_LIST_FAILURE = 'SKILLS_LIST_FAILURE'
export const SKILLS_NEW_REQUEST = 'SKILLS_NEW_REQUEST'
export const SKILLS_NEW_SUCCESS = 'SKILLS_NEW_SUCCESS'
export const SKILLS_NEW_FAILURE = 'SKILLS_NEW_FAILURE'

export const SOURCES_DELETE_REQUEST = 'SOURCES_DELETE_REQUEST'
export const SOURCES_DELETE_SUCCESS = 'SOURCES_DELETE_SUCCESS'
export const SOURCES_DELETE_FAILURE = 'SOURCES_DELETE_FAILURE'
export const SOURCES_EDIT_REQUEST = 'SOURCES_EDIT_REQUEST'
export const SOURCES_EDIT_SUCCESS = 'SOURCES_EDIT_SUCCESS'
export const SOURCES_EDIT_FAILURE = 'SOURCES_EDIT_FAILURE'
export const SOURCES_GET_REQUEST = 'SOURCES_GET_REQUEST'
export const SOURCES_GET_SUCCESS = 'SOURCES_GET_SUCCESS'
export const SOURCES_GET_FAILURE = 'SOURCES_GET_FAILURE'
export const SOURCES_LIST_REQUEST = 'SOURCES_LIST_REQUEST'
export const SOURCES_LIST_SUCCESS = 'SOURCES_LIST_SUCCESS'
export const SOURCES_LIST_FAILURE = 'SOURCES_LIST_FAILURE'
export const SOURCES_NEW_REQUEST = 'SOURCES_NEW_REQUEST'
export const SOURCES_NEW_SUCCESS = 'SOURCES_NEW_SUCCESS'
export const SOURCES_NEW_FAILURE = 'SOURCES_NEW_FAILURE'
export const SOURCES_RESET = 'SOURCES_RESET'

export const SUBSCRIPTIONS_DELETE_REQUEST = 'SUBSCRIPTIONS_DELETE_REQUEST'
export const SUBSCRIPTIONS_DELETE_SUCCESS = 'SUBSCRIPTIONS_DELETE_SUCCESS'
export const SUBSCRIPTIONS_DELETE_FAILURE = 'SUBSCRIPTIONS_DELETE_FAILURE'
export const SUBSCRIPTIONS_EDIT_REQUEST = 'SUBSCRIPTIONS_EDIT_REQUEST'
export const SUBSCRIPTIONS_EDIT_SUCCESS = 'SUBSCRIPTIONS_EDIT_SUCCESS'
export const SUBSCRIPTIONS_EDIT_FAILURE = 'SUBSCRIPTIONS_EDIT_FAILURE'
export const SUBSCRIPTIONS_GET_REQUEST = 'SUBSCRIPTIONS_GET_REQUEST'
export const SUBSCRIPTIONS_GET_SUCCESS = 'SUBSCRIPTIONS_GET_SUCCESS'
export const SUBSCRIPTIONS_GET_FAILURE = 'SUBSCRIPTIONS_GET_FAILURE'
export const SUBSCRIPTIONS_LIST_REQUEST = 'SUBSCRIPTIONS_LIST_REQUEST'
export const SUBSCRIPTIONS_LIST_SUCCESS = 'SUBSCRIPTIONS_LIST_SUCCESS'
export const SUBSCRIPTIONS_LIST_FAILURE = 'SUBSCRIPTIONS_LIST_FAILURE'
export const SUBSCRIPTIONS_NEW_REQUEST = 'SUBSCRIPTIONS_NEW_REQUEST'
export const SUBSCRIPTIONS_NEW_SUCCESS = 'SUBSCRIPTIONS_NEW_SUCCESS'
export const SUBSCRIPTIONS_NEW_FAILURE = 'SUBSCRIPTIONS_NEW_FAILURE'
