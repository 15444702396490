var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import cl from 'clsx';
import { Loader } from 'components/loader';
import { Label } from 'components/typography';
import React, { forwardRef, useMemo, useRef } from 'react';
import { mergeRefs } from 'util/index';
export const Button = forwardRef((_a, ref) => {
    var { as: Component = 'button', className, children, disabled, icon, iconPosition = 'left', loading = false, size = 'medium', spacing = false, variant = 'primary' } = _a, rest = __rest(_a, ["as", "className", "children", "disabled", "icon", "iconPosition", "loading", "size", "spacing", "variant"]);
    const buttonRef = useRef(null);
    const mergedRef = useMemo(() => mergeRefs([buttonRef, ref]), [ref]);
    return (React.createElement(Component, Object.assign({}, rest, { ref: mergedRef, className: cl('cds-button', `cds-button--${variant}`, `cds-button--${size}`, {
            'cds-button--disabled': disabled || loading,
            'cds-button--loading': loading,
            'cds-button--icon-only': !!icon && !children,
            'cds-button--spacing': spacing
        }, className), disabled: disabled || loading }),
        loading && React.createElement(Loader, { size: size }),
        React.createElement(React.Fragment, null,
            icon && iconPosition === 'left' && React.createElement("span", { className: 'cds-button__icon' }, icon),
            children && (React.createElement(Label, { as: 'span', size: size === 'medium' ? 'medium' : 'small', "aria-live": 'polite' }, children)),
            icon && iconPosition === 'right' && React.createElement("span", { className: 'cds-button__icon' }, icon))));
});
Button.displayName = 'Button';
export default Button;
