var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import cl from 'clsx';
import React, { forwardRef, useContext } from 'react';
import { NavBarContext } from './NavBar';
export const Link = forwardRef((_a, ref) => {
    var { className, children, unsafeIndex = 0, icon } = _a, rest = __rest(_a, ["className", "children", "unsafeIndex", "icon"]);
    const context = useContext(NavBarContext);
    if (context === null) {
        console.error('<NavBar.Link> has to be used within <NavBar>');
        return null;
    }
    const { value, size, variant, onValueChange } = context;
    return (React.createElement("a", Object.assign({}, rest, { ref: ref, className: cl('cds-navbar-link', `cds-navbar-link-${size}`, `cds-navbar-link-${variant}`, {
            'cds-navbar-link--selected': value === unsafeIndex
        }, className), "aria-current": value === unsafeIndex, onClick: (e) => {
            var _a;
            onValueChange(unsafeIndex);
            (_a = rest === null || rest === void 0 ? void 0 : rest.onClick) === null || _a === void 0 ? void 0 : _a.call(rest, e);
        } }),
        React.createElement("span", { className: 'cds-navbar-link__content' },
            icon && React.createElement("span", { className: 'cds-navbar-link__content-icon' }, icon),
            React.createElement("span", { className: 'cds-navbar-link__content-text' }, children))));
});
Link.displayName = 'NavBar.Link';
export default Link;
