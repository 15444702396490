var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import cl from 'clsx';
import React, { forwardRef } from 'react';
import { Button } from 'components/button';
const ChipButton = forwardRef((_a, ref) => {
    var { className, children, disabled, icon } = _a, rest = __rest(_a, ["className", "children", "disabled", "icon"]);
    return (React.createElement(Button, Object.assign({}, rest, { ref: ref, className: cl('cds-chip--inside-button', className), size: 'small', variant: 'tertiary', icon: icon })));
});
ChipButton.displayName = 'Chip.Button';
export default ChipButton;
