import * as types from 'constants/actionTypes'
import { Client } from 'declarations/models'
import { AnyAction } from 'redux'

import _ from 'lodash'

export interface FollowedClientsState {
  creating: Array<Client>
  deleting: Array<Client>
  get: Client | null | undefined
  getting: boolean
  list: Array<Client> | null | undefined
  listing: boolean
}

export const initialFollowedClientsState: FollowedClientsState = {
  creating: [],
  deleting: [],
  get: undefined,
  getting: false,
  list: undefined,
  listing: false
}

const followedClientsReducer = (
  /* istanbul ignore next */
  state: FollowedClientsState = initialFollowedClientsState,
  action: AnyAction
): FollowedClientsState => {
  switch (action.type) {
    case types.FOLLOWED_CLIENTS_DELETE_REQUEST:
      return {
        ...state,
        deleting: _.cloneDeep(state.deleting).concat(action.context)
      }

    case types.FOLLOWED_CLIENTS_DELETE_SUCCESS: {
      const deletedClient = action.context
      return {
        ...state,
        deleting: _.reject(state.deleting, { id: deletedClient.id }),
        list: _.reject(state.list, { id: deletedClient.id })
      }
    }

    case types.FOLLOWED_CLIENTS_DELETE_FAILURE:
      return {
        ...state,
        deleting: _.reject(state.deleting, { id: action.context.id })
      }

    case types.FOLLOWED_CLIENTS_GET_REQUEST:
      return {
        ...state,
        getting: true,
        get: undefined
      }

    case types.FOLLOWED_CLIENTS_GET_SUCCESS:
      return {
        ...state,
        getting: false,
        get: action.payload
      }

    case types.FOLLOWED_CLIENTS_GET_FAILURE:
      return {
        ...state,
        getting: false,
        get: null
      }

    case types.FOLLOWED_CLIENTS_LIST_REQUEST:
      return {
        ...state,
        list: undefined,
        listing: true
      }

    case types.FOLLOWED_CLIENTS_LIST_SUCCESS: {
      const payload = _.isEmpty(action.payload) ? [] : action.payload
      return {
        ...state,
        list: payload,
        listing: false
      }
    }

    case types.FOLLOWED_CLIENTS_LIST_FAILURE:
      return {
        ...state,
        list: null,
        listing: false
      }

    case types.FOLLOWED_CLIENTS_NEW_REQUEST:
      return {
        ...state,
        creating: _.cloneDeep(state.creating).concat(action.context)
      }

    case types.FOLLOWED_CLIENTS_NEW_SUCCESS: {
      const newClient = action.context
      return {
        ...state,
        creating: _.reject(state.creating, { id: newClient.id }),
        list: [.../* istanbul ignore next */ (state.list ?? [])].concat(newClient)
      }
    }

    case types.FOLLOWED_CLIENTS_NEW_FAILURE:
      return {
        ...state,
        creating: _.reject(state.creating, { id: action.context.id })
      }

    case types.FOLLOWED_CLIENTS_SET:
      return {
        ...state,
        get: action.payload
      }

    default:
      return state
  }
}

export default followedClientsReducer
