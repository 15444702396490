import { Alert, Button, Content, PileCenterDiv, VerticalSpace } from '@cegal/ds-components'
import errorImage from 'assets/images/catError1.png'
import { AlertElement } from 'declarations/app'
import { AlertsState } from 'reducers/alerts'
import { useAppSelector } from 'store'

import _ from 'lodash'

export interface AlertPanelProps {
  doNotRenderIfHeadIsActiveWithError?: boolean
  watchFor: Array<string>
  buttonText: string | undefined
  reloadPage: () => void
}

const AlertPanel = ({
  doNotRenderIfHeadIsActiveWithError = false,
  watchFor,
  buttonText,
  reloadPage
}: AlertPanelProps) => {
  const head = useAppSelector((state) => (state.alerts as AlertsState).head)
  const body = useAppSelector((state) => (state.alerts as AlertsState).body)

  if (_.isEmpty(body) || _.isEmpty(watchFor)) {
    return null
  }

  if (head?.type && doNotRenderIfHeadIsActiveWithError) {
    return null
  }

  const error = body.find((b: AlertElement) => watchFor.includes(b.type!))

  if (!error) {
    return null
  }

  return (
    <Content>
      <PileCenterDiv>
        <Alert variant={error.variant}>
          {error.status ? error.status + ' - ' : ''}
          {error.message}
        </Alert>
        <VerticalSpace />
        <img src={errorImage} style={{ maxWidth: '140px' }} alt='Error - Page not found' />
        <VerticalSpace />
        <Button onClick={reloadPage}>{buttonText}</Button>
      </PileCenterDiv>
    </Content>
  )
}

export default AlertPanel
