import { Button, FlexBaseSpacedDiv, Label, Search, VerticalSpace } from '@cegal/ds-components/'
import { KeyboardArrowDown, KeyboardArrowUp, SearchOff } from '@cegal/ds-icons'
import { Search as SearchIcon } from '@cegal/ds-icons/dist/Search'
import styled from 'styled-components'

import FacetedFilterDropdown, {
  FacetedFilterDropdownProps
} from 'components/Forms/FacetedFilterSection/FacetedFilterDropdown'

import { PropsWithChildren, useEffect, useState } from 'react'

import { t } from 'i18next'

import _ from 'lodash'

export interface FacetedFilterSectionWrapperProps extends PropsWithChildren {
  onClear?: () => void
  onSearch?: (text: string) => void

  collapsed?: boolean
  label: React.ReactNode
  search?: string

  showClearButton?: boolean
  showSearchButton?: boolean
  showSettingsButton?: boolean

  settingsProps?: FacetedFilterDropdownProps
}

const ClearButton = styled(Button)`
  margin-right: auto;
  margin-left: 4px;
`

const StyledSearch = styled(Search)`
  padding: 0.4rem 0;
  width: 100%;
  .cds-search__wrapper input {
    padding-left: 2rem;
  }
  .cds-search__button-clear {
    width: 1.2rem;
    height: 1.2rem;
  }
`

export const LabelButton = styled(Button)`
  padding: 5px 5px 5px 0;
  .cds-label {
    display: flex;
  }
`

export const StyledFlexBaseSpacedDiv = styled(FlexBaseSpacedDiv)`
  align-items: center;
`

const FacetedFilterSectionWrapper = ({
  onClear,
  onSearch,

  children,

  label,
  search,
  collapsed = false,

  showClearButton = false,
  showSearchButton = false,
  showSettingsButton = false,

  settingsProps
}: FacetedFilterSectionWrapperProps) => {
  const [showContent, setShowContent] = useState(true)
  const [showSearch, setShowSearch] = useState(false)

  const handleShowContent = () => {
    setShowContent(!showContent)
    setShowSearch(false)
  }

  const handleShowSearch = () => {
    if (showSearch) {
      onSearch?.('')
    }
    setShowSearch(!showSearch)
    setShowContent(true)
  }

  useEffect(() => {
    if (collapsed) {
      setShowContent(false)
      setShowSearch(false)
    } else {
      setShowContent(true)
    }
  }, [collapsed])

  return (
    <>
      <StyledFlexBaseSpacedDiv>
        <LabelButton size='small' variant='tertiary' onClick={handleShowContent}>
          {!showContent ? <KeyboardArrowDown size='1.5rem' /> : <KeyboardArrowUp size='1.5rem' />}
          <Label>{label}</Label>
        </LabelButton>
        {showClearButton && (
          <ClearButton size='small' variant='secondary' onClick={onClear}>
            {t('buttons:clear')}
          </ClearButton>
        )}
        {showSearchButton && (
          <Button
            size='small'
            variant='tertiary'
            style={{ marginLeft: 'auto', padding: '3px' }}
            onClick={handleShowSearch}
          >
            {!showSearch ? <SearchIcon size='1.5rem' /> : <SearchOff size='1.5rem' />}
          </Button>
        )}
        {showSettingsButton && !_.isNil(settingsProps) && <FacetedFilterDropdown {...settingsProps} />}
      </StyledFlexBaseSpacedDiv>
      {showSearch && (
        <StyledSearch size='small' variant='simple' label={null} value={search} onChange={onSearch} />
      )}

      <VerticalSpace size='0.5' />
      {showContent && children}
      <hr style={{ width: '100%' }} />
    </>
  )
}

export default FacetedFilterSectionWrapper
