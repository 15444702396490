import * as types from 'constants/actionTypes'
import api from 'api'
import {
  CONSULTANTS_ALLOCATIONS_SEARCH_URL,
  CONSULTANTS_ALLOCATION_LIST_URL,
  CONSULTANTS_CV_GET_URL,
  CONSULTANTS_GET_URL,
  CONSULTANTS_LIST_URL
} from 'constants/urls'
import { Consultant } from 'declarations/models'
import { sprintf } from 'sprintf-js'

export const getConsultant = (id: number) => {
  return api.call({
    url: sprintf(CONSULTANTS_GET_URL, { id }),
    cascadeFailureError: true,
    skipFake: true,
    type: {
      request: types.CONSULTANTS_GET_REQUEST,
      success: types.CONSULTANTS_GET_SUCCESS,
      failure: types.CONSULTANTS_GET_FAILURE
    }
  })
}

export const getConsultantAllocation = (consultant: Consultant) => {
  return api.call({
    url: sprintf(CONSULTANTS_ALLOCATION_LIST_URL, { id: consultant.id }),
    cascadeFailureError: true,
    skipFake: true,
    context: {
      key: consultant.id.toString()
    },
    type: {
      request: types.CONSULTANTS_ALLOCATION_REQUEST,
      success: types.CONSULTANTS_ALLOCATION_SUCCESS,
      failure: types.CONSULTANTS_ALLOCATION_FAILURE
    }
  })
}

export const getConsultantCV = (id: number) => {
  return api.call({
    url: sprintf(CONSULTANTS_CV_GET_URL, { id }),
    skipFake: true,
    cascadeFailureError: true,
    type: {
      request: types.CONSULTANTS_CV_REQUEST,
      success: types.CONSULTANTS_CV_SUCCESS,
      failure: types.CONSULTANTS_CV_FAILURE
    }
  })
}

export const listConsultants = () => {
  return api.call({
    url: CONSULTANTS_LIST_URL,
    skipFake: true,
    cascadeFailureError: true,
    type: {
      request: types.CONSULTANTS_LIST_REQUEST,
      success: types.CONSULTANTS_LIST_SUCCESS,
      failure: types.CONSULTANTS_LIST_FAILURE
    }
  })
}

export const searchConsultantsByAllocations = (searchKey: string, consultants: Array<Consultant>) => {
  return api.call({
    url: CONSULTANTS_ALLOCATIONS_SEARCH_URL.concat('?' + searchKey),
    cascadeFailureError: true,
    skipFake: true,
    context: {
      key: searchKey
    },
    type: {
      request: types.CONSULTANTS_ALLOCATIONS_SEARCH_REQUEST,
      success: types.CONSULTANTS_ALLOCATIONS_SEARCH_SUCCESS,
      failure: types.CONSULTANTS_ALLOCATIONS_SEARCH_FAILURE
    }
  })
}

export const setConsultant = (consultant: Consultant | undefined) => ({
  type: types.CONSULTANTS_CONSULTANT_SET,
  payload: consultant
})

export const setConsultants = (consultants: Array<Consultant> | undefined) => ({
  type: types.CONSULTANTS_LIST_SET,
  payload: consultants
})
