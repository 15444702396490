import * as types from 'constants/actionTypes'
import { EngagementVacancyConsultant } from 'declarations/models'
import { AnyAction } from 'redux'

import _ from 'lodash'

export interface MatchesState {
  created: EngagementVacancyConsultant | null | undefined
  creating: boolean
  /** vacancy_id and consultant_id being edited or deleted */
  currentMatch: Partial<EngagementVacancyConsultant> | undefined
  deleted: Partial<EngagementVacancyConsultant> | null | undefined
  deleting: boolean
  get: EngagementVacancyConsultant | null | undefined
  getting: boolean
  list: Array<EngagementVacancyConsultant> | null | undefined
  listing: boolean
  saving: boolean
  saved: EngagementVacancyConsultant | null | undefined
}

export const initialMatchesState: MatchesState = {
  created: undefined,
  creating: false,
  currentMatch: undefined,
  deleted: undefined,
  deleting: false,
  get: undefined,
  getting: false,
  list: undefined,
  listing: false,
  saved: undefined,
  saving: false
}

const matchesReducer = (
  /* istanbul ignore next */
  state: MatchesState = initialMatchesState,
  action: AnyAction
): MatchesState => {
  switch (action.type) {
    case types.MATCHES_DELETE_REQUEST:
      return {
        ...state,
        currentMatch: action.context,
        deleted: undefined,
        deleting: true
      }

    case types.MATCHES_DELETE_SUCCESS: {
      return {
        ...state,
        currentMatch: undefined,
        deleted: action.context as Partial<EngagementVacancyConsultant>,
        deleting: false,
        list: _.reject(
          state.list,
          (m: EngagementVacancyConsultant) =>
            m.engagement_vacancy!.id === action.context.engagement_vacancy.id &&
            m.consultant?.id === action.context.consultant?.id
        )
      }
    }

    case types.MATCHES_DELETE_FAILURE:
      return {
        ...state,
        currentMatch: undefined,
        deleted: null,
        deleting: false
      }

    case types.MATCHES_EDIT_REQUEST:
      return {
        ...state,
        currentMatch: action.context,
        saved: undefined,
        saving: true
      }

    case types.MATCHES_EDIT_SUCCESS: {
      const editedMatch: EngagementVacancyConsultant = action.payload
      return {
        ...state,
        saved: editedMatch,
        saving: false,
        currentMatch: undefined,
        list: _.map(state.list, (match: EngagementVacancyConsultant) =>
          /* istanbul ignore next */
          match.engagement_vacancy?.id === action.context.engagement_vacancy.id &&
          match.consultant?.id === action.context.consultant.id
            ? editedMatch
            : match
        )
      }
    }

    case types.MATCHES_EDIT_FAILURE:
      return {
        ...state,
        currentMatch: undefined,
        saved: null,
        saving: false
      }

    case types.MATCHES_LIST_REQUEST:
      return {
        ...state,
        list: undefined,
        listing: true
      }

    case types.MATCHES_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload,
        listing: false
      }

    case types.MATCHES_LIST_FAILURE:
      return {
        ...state,
        list: null,
        listing: false
      }

    case types.MATCHES_NEW_REQUEST:
      return {
        ...state,
        currentMatch: action.context,
        creating: true,
        created: undefined
      }

    case types.MATCHES_NEW_SUCCESS: {
      const newMatch: EngagementVacancyConsultant = {
        ...action.payload,
        engagement_vacancy: action.context.engagement_vacancy
      }
      return {
        ...state,
        created: newMatch,
        creating: false,
        list: [.../* istanbul ignore next */ (state.list ?? [])].concat(newMatch)
      }
    }

    case types.MATCHES_NEW_FAILURE:
      return {
        ...state,
        created: null,
        creating: false
      }

    case types.MATCHES_RESET:
      return {
        ...state,
        created: undefined,
        saved: undefined,
        deleted: undefined,
        get: undefined
      }

    default:
      return state
  }
}

export default matchesReducer
