import { Button, ButtonSize } from '@cegal/ds-components'
import { Delete as DeleteIcon } from '@cegal/ds-icons/dist/Delete'

import { useTranslation } from 'react-i18next'

interface DeleteButtonProps {
  size?: ButtonSize
  text?: string
  title?: string
  showText?: boolean
  loading?: boolean
  disabled?: boolean
  onDelete: () => void
  style?: any
}

const DeleteButton = ({
  size = 'small',
  text,
  showText = true,
  disabled = false,
  title,
  onDelete,
  ...rest
}: DeleteButtonProps) => {
  const { t } = useTranslation()

  return (
    <Button
      spacing
      disabled={disabled}
      variant='secondary'
      size={size}
      title={title ?? t('buttons:delete')}
      icon={<DeleteIcon size='1.25rem' />}
      onClick={onDelete}
      {...rest}
    >
      {showText ? text ?? t('buttons:edit') : null}
    </Button>
  )
}

export default DeleteButton
