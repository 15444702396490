var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import cl from 'clsx';
import React, { createContext, forwardRef, useState } from 'react';
import Link from './Link';
export const NavBarContext = createContext(null);
export const NavBar = forwardRef((_a, ref) => {
    var { size = 'medium', orientation = 'horizontal', variant = 'primary', underscore = false, collapsed = false, className, children, value, defaultValue, style, onChange } = _a, rest = __rest(_a, ["size", "orientation", "variant", "underscore", "collapsed", "className", "children", "value", "defaultValue", "style", "onChange"]);
    const [navValue, setNavValue] = useState(defaultValue);
    const handleValueChange = (v) => {
        setNavValue(v);
        onChange === null || onChange === void 0 ? void 0 : onChange(v);
    };
    let unsafeIndex = 0;
    return (React.createElement("nav", Object.assign({}, rest, { ref: ref, className: cl('cds-navbar', `cds-navbar--${orientation}`, `cds-navbar--${size}`, {
            'cds-navbar--underscore': underscore,
            'cds-navbar--collapsed': collapsed
        }, className), style: style }),
        React.createElement(NavBarContext.Provider, { value: {
                variant,
                value: value !== null && value !== void 0 ? value : navValue,
                onValueChange: handleValueChange,
                orientation,
                underscore,
                collapsed,
                size
            } }, React.Children.map(children, (link) => {
            var _a;
            return React.isValidElement(link) && ((_a = link === null || link === void 0 ? void 0 : link.type) === null || _a === void 0 ? void 0 : _a.displayName) === 'NavBar.Link'
                ? React.cloneElement(link, Object.assign(Object.assign({}, link.props), { unsafeIndex: unsafeIndex++ }))
                : link;
        }))));
});
NavBar.Link = Link;
NavBar.displayName = 'NavBar';
export default NavBar;
