var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { KeyboardArrowDown } from '@cegal/ds-icons/dist/KeyboardArrowDown';
import cl from 'clsx';
import { BodyLong } from 'components/typography';
import React, { forwardRef, useState } from 'react';
import AnimateHeight from 'util/AnimateHeight';
export const ReadMore = forwardRef((_a, ref) => {
    var { className, header, children, open, defaultOpen = false, onClick, size = 'medium' } = _a, rest = __rest(_a, ["className", "header", "children", "open", "defaultOpen", "onClick", "size"]);
    const [internalOpen, setInternalOpen] = useState(defaultOpen);
    const isOpened = open !== null && open !== void 0 ? open : internalOpen;
    return (React.createElement("div", { className: cl('cds-read-more', `cds-read-more--${size}`, {
            'cds-read-more--open': isOpened
        }, className) },
        React.createElement("button", Object.assign({}, rest, { ref: ref, type: 'button', className: cl('cds-read-more__button', 'cds-body-short', {
                'cds-body-short--small': size === 'small'
            }), onClick: (e) => {
                if (open === undefined) {
                    setInternalOpen((isOpen) => !isOpen);
                }
                onClick === null || onClick === void 0 ? void 0 : onClick(e);
            }, "aria-expanded": isOpened }),
            React.createElement(KeyboardArrowDown, { size: '1.5rem', className: 'cds-read-more__expand-icon cds-read-more__expand-icon--filled', "aria-hidden": true }),
            React.createElement("span", null, header)),
        React.createElement(AnimateHeight, { height: isOpened ? 'auto' : 0, duration: 250 },
            React.createElement(BodyLong, { as: 'div', className: 'cds-read-more__content', size: size }, children))));
});
ReadMore.displayName = 'ReadMore';
export default ReadMore;
