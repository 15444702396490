var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Close } from '@cegal/ds-icons/dist/Close';
import cl from 'clsx';
import { Button } from 'components/button';
import React, { forwardRef, useRef } from 'react';
import { mergeRefs } from 'react-merge-refs';
import ReactModal from 'react-modal';
import { Box } from 'components/box';
export const Modal = forwardRef((_a, ref) => {
    var { children, open, onClose, className, shouldCloseOnOverlayClick = true, closeButton = true, 'aria-describedby': ariaDescribedBy, 'aria-labelledby': ariaLabelledBy, 'aria-modal': ariaModal, 'aria-label': contentLabel } = _a, rest = __rest(_a, ["children", "open", "onClose", "className", "shouldCloseOnOverlayClick", "closeButton", 'aria-describedby', 'aria-labelledby', 'aria-modal', 'aria-label']);
    const modalRef = useRef(null);
    const mergedRef = mergeRefs([modalRef, ref]);
    const buttonRef = useRef(null);
    const onModalCloseRequest = (e) => {
        if (shouldCloseOnOverlayClick || e.type === 'keydown') {
            onClose();
        }
        else if (buttonRef.current) {
            buttonRef.current.focus();
        }
    };
    return (React.createElement(ReactModal, Object.assign({}, rest, { ref: mergedRef, className: cl('cds-modal', className), isOpen: open, overlayClassName: 'cds-modal__overlay', shouldCloseOnOverlayClick: shouldCloseOnOverlayClick, onRequestClose: (e) => onModalCloseRequest(e), aria: {
            describedby: ariaDescribedBy,
            labelledby: ariaLabelledBy,
            modal: ariaModal
        }, contentLabel: contentLabel }),
        children,
        closeButton && (React.createElement(Button, { className: cl('cds-modal__button', {
                'cds-modal__button--shake': shouldCloseOnOverlayClick
            }), size: 'small', variant: 'tertiary', ref: buttonRef, onClick: onClose },
            React.createElement(Close, null)))));
});
Modal.setAppElement = (element) => ReactModal.setAppElement(element);
Modal.Content = Box;
Modal.displayName = 'Modal';
export default Modal;
