import {
  BodyShort,
  SelectMultiple,
  SelectMultipleComponents,
  SelectMultipleProps
} from '@cegal/ds-components'
import { Certificate } from 'declarations/models'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OptionProps } from 'react-select'

import _ from 'lodash'

const CertificateOption = (props: OptionProps<Certificate>) => {
  const { data } = props
  return (
    <SelectMultipleComponents.Option {...props}>
      <BodyShort>{data.name}</BodyShort>
    </SelectMultipleComponents.Option>
  )
}

export interface ThisCertificateSelectProps {
  certificates: Array<Certificate>
}

export type CertificateSelectProps = ThisCertificateSelectProps & SelectMultipleProps<Certificate>

const filterOptions = (candidate: { label: string; value: string; data: any }, input: string) => {
  if (input) {
    return candidate.data.name.toLowerCase().match(input.toLowerCase())
  }
  return false
}

const CertificateSelect = (props: CertificateSelectProps) => {
  const [_options, setOptions] = useState<Array<Certificate>>(props.certificates)

  const { t } = useTranslation()

  useEffect(() => {
    setOptions(props.certificates)
  }, [props.certificates])

  const handleInputChange = (inputValue: string) => {
    const filteredOptions = _.filter(props.certificates, (c: Certificate) =>
      c.name.toLowerCase().match(inputValue.toLowerCase())
    ) as Array<Certificate>
    setOptions(filteredOptions)
  }

  return (
    <div>
      <SelectMultiple
        multiple
        fullWidth
        getOptionValue={(certificate: Certificate) => certificate.name}
        getOptionLabel={(c: Certificate) => c.name}
        noOptionsMessage={(data: any) =>
          !_.isEmpty(data.inputValue)
            ? t('messages:warning-no-certificates-found-with-text-x', { x: data.inputValue })
            : t('messages:info-type-to-search-certificates')
        }
        // @ts-ignore
        onInputChange={handleInputChange}
        filterOption={filterOptions}
        components={{
          Option: (optionProps: OptionProps<Certificate>) => <CertificateOption {...optionProps} />
        }}
        // @ts-ignore
        options={_options}
        {...props}
      />
    </div>
  )
}

export default CertificateSelect
