import React, { forwardRef } from 'react';
import { CheckCircle as Success } from '@cegal/ds-icons/dist/CheckCircle';
import { Error } from '@cegal/ds-icons/dist/Error';
import cl from 'clsx';
const ProgressBar = forwardRef(({ now = 0, variant, size, label, helperText, inline, animation, icon, indeterminate, max = 100 }, ref) => {
    return (React.createElement("div", { ref: ref, className: cl('cds-progressbar', `cds-progressbar--${variant}`, `cds-progressbar--${size}`, {
            'cds-progressbar--inline': inline,
            'cds-progressbar--animated': animation,
            'cds-progressbar--indeterminate': indeterminate
        }) },
        React.createElement("div", { className: cl('cds-progressbar__label') },
            React.createElement("span", { className: 'cds-progressbar__label-text' }, label),
            icon === 'success' && React.createElement(Success, { className: 'cds-progressbar__icon--success', title: 'success' }),
            icon === 'error' && React.createElement(Error, { className: 'cds-progressbar__icon--error', title: 'error' })),
        React.createElement("div", { role: 'progressbar', "aria-valuemin": 0, "aria-valuemax": max, "aria-valuenow": now, className: cl('cds-progressbar__track') },
            React.createElement("div", { className: cl('cds-progressbar__bar'), style: { width: indeterminate ? 'null' : `${(now / max) * 100}%` } })),
        !inline && React.createElement("div", { className: 'cds-progressbar__helper' }, helperText)));
});
export default ProgressBar;
