import {
  AlignEndColumn,
  Column,
  FlexCenterDiv,
  Heading,
  HorizontalSpace,
  Link,
  Row,
  VerticalSpace
} from '@cegal/ds-components'
import { Certificate, Engagement, EngagementsQuery, Skill } from 'declarations/models'
import { useAppSelector } from 'store'

import Card, { CardProps } from 'components/Card/Card'
import { ConsultantSignature } from 'components/Consultants/Consultant'
import { DescriptionListItem, DescriptionSpan } from 'components/DescriptionList/DescriptionList'
import CertificatesGroup from 'components/TagGroup/CertificatesGroup'
import SkillsGroup from 'components/TagGroup/SkillsGroup'

import { useDrag } from 'react-dnd'
import Highlighter from 'react-highlight-words'
import { useTranslation } from 'react-i18next'
import { NavLink, generatePath } from 'react-router-dom'

import useQuery from 'hooks/useQuery'

import { dateToPeriodFormat, toLocalDateFormat } from 'utils'
import { getCertificatesFromEngagement, getUniqueSkillsFromEngagement } from 'utils/engagements'
import { tokenize } from 'utils/tokenizer'

import _ from 'lodash'

type EngagementCardPreviewProps = {
  children: React.ReactNode
  onView?: (item: Engagement) => void
  item: Engagement
}

const EngagementCardPreview = ({ children, onView, item }: EngagementCardPreviewProps) => {
  return _.isFunction(onView) ? (
    <div>
      <Link style={{ cursor: 'pointer' }} onClick={() => onView!(item)}>
        {children}
      </Link>
    </div>
  ) : (
    children
  )
}

const EngagementCard = ({
  item,
  size = 'medium',
  spacing = false,
  onView,
  ...rest
}: CardProps<Engagement, EngagementsQuery>) => {
  const { t } = useTranslation()
  const skills: Array<Skill> = getUniqueSkillsFromEngagement(item)
  const certificates: Array<Certificate> = getCertificatesFromEngagement(item)
  const { query, setQuery } = useQuery<Partial<EngagementsQuery>>({})
  const searchWords = tokenize(query.search)
  const aiDrawerOpen = useAppSelector((state) => state.app.settings.aiDrawerOpen)
  const aiTab = useAppSelector((state) => state.app.settings.aiTab)

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'EngagementCard',
    item: {
      type: 'EngagementCard',
      label: t('label:engagement-title'),
      link: `/engagements/${item.id}`,
      title: item.title,
      data: item
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId()
    })
  }))

  const isDrag = aiDrawerOpen && aiTab !== 'files'

  const opacity = isDragging ? 0.4 : 1

  return (
    <div ref={drag} style={{ opacity }}>
      <Card<Engagement>
        item={item}
        data-cy='EngagementCard'
        data-testid='EngagementCard'
        size={size}
        spacing={spacing}
        border
        ref={isDrag ? drag : undefined}
        style={{ opacity, cursor: isDrag ? 'move' : 'default' }}
        draggable={isDrag}
        {...rest}
      >
        <EngagementCardPreview onView={onView} item={item as Engagement}>
          <Heading size='small' data-testid='EngagementCard-Name' data-cy='EngagementCardName'>
            <Highlighter
              caseSensitive={false}
              searchWords={searchWords}
              textToHighlight={item?.title ?? ''}
            />
          </Heading>
        </EngagementCardPreview>
        <VerticalSpace size='0.4' />
        <Row style={{ width: '100%' }}>
          <Column>
            <DescriptionListItem
              title={t('label:clients')}
              details={
                <>
                  {item.clients
                    ?.map((c) => (
                      <Link
                        key={c.id}
                        as={NavLink}
                        to={generatePath('/clients/:id', {
                          id: c.id.toString()
                        })}
                      >
                        <Highlighter
                          caseSensitive={false}
                          searchWords={searchWords}
                          textToHighlight={c?.name ?? ''}
                        />
                      </Link>
                    ))
                    .reduce(
                      (prev: Array<JSX.Element> | null, curr: JSX.Element, index) =>
                        prev === null ? [curr] : [...prev, <span key={'sep-' + index}>{', '}</span>, curr],
                      null
                    )}
                </>
              }
            />
          </Column>
          <Column>
            <DescriptionListItem title={t('label:source-title')} details={item.source?.name ?? '-'} />
          </Column>
        </Row>
        <Row>
          <Column>
            <DescriptionListItem
              title={t('label:location')}
              details={
                <Highlighter
                  caseSensitive={false}
                  searchWords={searchWords}
                  textToHighlight={item?.title ?? ''}
                />
              }
            />
          </Column>
          <Column>
            <DescriptionListItem title={t('label:status-title')} details={item.status} />
          </Column>
        </Row>
        <DescriptionListItem
          title={t('label:dates-title')}
          details={
            <span>
              {dateToPeriodFormat(item.start_date, item.end_date, item.optional_extension_period, t)}
            </span>
          }
        />
        {size === 'medium' && (
          <>
            <DescriptionListItem
              $alignItems='baseline'
              title={t('label:skills')}
              details={
                !_.isEmpty(skills) ? (
                  <SkillsGroup<Partial<EngagementsQuery>>
                    size='small'
                    query={query}
                    skills={skills}
                    setQuery={setQuery}
                    interactive
                  />
                ) : (
                  <DescriptionSpan>{t('messages:no-skills')}</DescriptionSpan>
                )
              }
            />
            <DescriptionListItem
              $alignItems='baseline'
              title={t('label:certificates')}
              details={
                !_.isEmpty(certificates) ? (
                  <CertificatesGroup<Partial<EngagementsQuery>>
                    query={query}
                    certificates={certificates}
                    setQuery={setQuery}
                    interactive
                  />
                ) : (
                  <DescriptionSpan>{t('messages:no-certificates')}</DescriptionSpan>
                )
              }
            />
            {!!item.creator && (
              <DescriptionListItem
                $alignItems='center'
                title={t('label:creator-title')}
                details={
                  <ConsultantSignature
                    consultant={item.creator}
                    popover={['view', 'email']}
                    context={{ engagement: item }}
                  />
                }
              />
            )}
            {!!item.vacancies && (
              <DescriptionListItem
                title={t('label:vacancies')}
                details={
                  !_.isEmpty(item.vacancies) ? (
                    item.vacancies?.map((vacancy, index, vacancies) => (
                      <span key={vacancy.id}>
                        <Highlighter
                          caseSensitive={false}
                          searchWords={searchWords}
                          textToHighlight={vacancy?.title ?? ''}
                        />
                        {vacancies.length - 1 > index ? ', ' : ''}
                      </span>
                    ))
                  ) : (
                    <span>{t('messages:no-vacancies-available')}</span>
                  )
                }
              />
            )}
            <Row>
              <AlignEndColumn>
                <DescriptionListItem
                  justifyContent='flex-end'
                  $alignItems='center'
                  title={t('label:last-modified')}
                  details={
                    <FlexCenterDiv>
                      {toLocalDateFormat(item.last_modified_date)}
                      {!_.isEmpty(item.last_modifier) && (
                        <>
                          <HorizontalSpace size='0.3' />
                          {t('label:by')}
                          <HorizontalSpace size='0.3' />
                          <ConsultantSignature
                            popover={['view', 'email']}
                            consultant={item.last_modifier}
                            context={{ engagement: item }}
                          />
                        </>
                      )}
                    </FlexCenterDiv>
                  }
                />
              </AlignEndColumn>
            </Row>
          </>
        )}
      </Card>
    </div>
  )
}

export default EngagementCard
