import {
  Button,
  Column,
  FlexCenterDiv,
  FlexStartDiv,
  Heading,
  HorizontalSpace,
  PileDiv,
  Row,
  VerticalSpace
} from '@cegal/ds-components'
import { ArrowBack } from '@cegal/ds-icons/dist/ArrowBack'
import { deleteFollowedConsultant, getConsultant, newFollowedConsultant, setConsultant } from 'actions'
import { CONSULTANTS_GET_FAILURE } from 'constants/actionTypes'
import { Consultant } from 'declarations/models'
import { eventLogger, standardLogger } from 'metrics/loggers'
import { useAppDispatch, useAppSelector } from 'store'

import { FullScreenLoader } from 'components'
import AlertPanel from 'components/AlertPanel/AlertPanel'
import ConsultantViewContent from 'components/Consultants/Consultant/ConsultantViewContent'
import FindButton from 'components/Forms/Buttons/FindButton'
import FollowButton from 'components/Forms/Buttons/FollowButton'
import PageContent from 'components/PageContent/PageContent'

import { PropsWithChildren, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { NavigateFunction, Params, generatePath, useNavigate, useParams } from 'react-router-dom'

import _ from 'lodash'

export type ConsultantViewProps = PropsWithChildren & {
  embed?: boolean
}

const ConsultantView = ({ embed = false }: ConsultantViewProps) => {
  const params: Readonly<Params<string>> = useParams()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate: NavigateFunction = useNavigate()

  const consultant = useAppSelector((state) => state.consultants.get)
  const consultants = useAppSelector((state) => state.consultants.list)
  const creatingFollow = useAppSelector((state) => state.followedConsultants.creating)
  const deletingFollow = useAppSelector((state) => state.followedConsultants.deleting)
  const followedConsultants = useAppSelector((state) => state.followedConsultants.list)
  const apiReady = useAppSelector((state) => state.app.apiReady)

  const goToCVPartner = (e: any) => {
    eventLogger(e)
    window.open(
      'https://cegal.cvpartner.com/dashboard#persons/proposal/false/cv/' +
        consultant?.cvpartner_user_id +
        '/' +
        consultant?.cv_id +
        '/read_only/default',
      '_blank'
    )
  }

  const loadPageData = (): void => {
    if (params.id) {
      const parsedId: number = parseInt(params.id!)
      if (_.isNumber(parsedId)) {
        if (!_.isNil(consultants)) {
          const found: Consultant | undefined = consultants?.find(
            (obj: Consultant): boolean => obj.id === parsedId
          )
          if (found) {
            dispatch(setConsultant(found))
            return
          }
        }
        dispatch(getConsultant(parsedId))
      }
    }
  }

  useEffect(() => {
    if (apiReady) {
      loadPageData()
    }
  }, [apiReady])

  const navigateBack = (e: any) => {
    if (e) {
      eventLogger(e)
    }
    dispatch(setConsultant(undefined))
    navigate(-1)
  }

  const navigateToConsultantMatch = (e: any) => {
    if (params.id) {
      standardLogger('consultants.view.find.button')
      navigate(generatePath('/consultants/:id/find', { id: params.id! }))
    }
  }

  const handleConsultantFollow = (consultant: Consultant) => {
    standardLogger('consultants.view.follow')
    dispatch(newFollowedConsultant(consultant))
  }

  const handleConsultantUnfollow = (consultant: Consultant) => {
    standardLogger('consultants.view.unfollow')
    dispatch(deleteFollowedConsultant(consultant))
  }

  const _creating = _.find(creatingFollow, { id: consultant?.id }) !== undefined
  const _deleting = _.find(deletingFollow, { id: consultant?.id }) !== undefined

  return (
    <PageContent maxWidth='1600px'>
      <VerticalSpace />
      <Row>
        <Column>
          <FlexCenterDiv>
            <Button
              spacing
              variant='secondary'
              data-amplitude='engagements.view.goback'
              icon={<ArrowBack size='1.5rem' />}
              onClick={navigateBack}
            >
              {t('buttons:back')}
            </Button>
            <HorizontalSpace />
            <PileDiv>
              <Heading>{consultant?.name}</Heading>
              <VerticalSpace />
            </PileDiv>
          </FlexCenterDiv>
          <VerticalSpace />
        </Column>
        <Column>
          <FlexStartDiv style={{ justifyContent: 'flex-end' }}>
            <Button
              spacing
              variant='secondary'
              data-amplitude='engagements.view.cvpartner'
              onClick={goToCVPartner}
            >
              {t('buttons:open-cv')}
            </Button>
            <div>
              <HorizontalSpace />
              <FindButton
                size='medium'
                forItem='consultant'
                onClick={navigateToConsultantMatch}
                text={t('buttons:find-x', { x: t('label:engagements-title').toLowerCase() })}
              />
            </div>
            <div>
              <HorizontalSpace />
              {consultant && (
                <FollowButton<Consultant>
                  size='medium'
                  item={consultant}
                  followedItems={followedConsultants}
                  onFollow={handleConsultantFollow}
                  onUnfollow={handleConsultantUnfollow}
                  loading={_creating || _deleting}
                  disabled={_creating || _deleting}
                />
              )}
            </div>
          </FlexStartDiv>
        </Column>
      </Row>
      <VerticalSpace />
      {consultant === undefined && <FullScreenLoader />}
      {consultant === null && (
        <AlertPanel
          doNotRenderIfHeadIsActiveWithError
          watchFor={[CONSULTANTS_GET_FAILURE]}
          buttonText={t('buttons:click-to-reload-the-page')!}
          reloadPage={loadPageData}
        />
      )}
      {!_.isNil(consultant) && <ConsultantViewContent consultant={consultant} />}
    </PageContent>
  )
}

export default ConsultantView
