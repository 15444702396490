import { Button, FlexDiv, Panel } from '@cegal/ds-components'
import { ChevronLeft } from '@cegal/ds-icons/dist/ChevronLeft'
import { ChevronRight } from '@cegal/ds-icons/dist/ChevronRight'
import styled from 'styled-components'

import { PropsWithChildren, useEffect, useState } from 'react'

const CarouselDiv = styled.div`
  overflow: hidden;
  position: relative;
`
const CarouselItemDiv = styled.div`
  display: inline-flex;
  position: relative;
`
const Div = styled.div``

const CarouselButtonsDiv = styled.div<{ hover?: boolean }>`
  display: ${(props) => (props.hover ? 'block' : 'none')};
  position: absolute;
  top: 50%;
  bottom: 50%;
  left: 0;
  right: 0;
`

const CarouselContentDiv = styled.div<{ activeIndex: number }>`
  padding: 0;
  transition: transform 0.3s;
  white-space: nowrap;
  transform: translateX(-${(props) => (props.activeIndex - 1) * 100}%);
`

const ButtonsDiv = styled(FlexDiv)`
  justify-content: space-between;
  height: var(--cds-size-12);
  margin-top: calc(-1 * var(--cds-size-6));
`

interface CarouselItemProps {
  children: React.ReactNode
  border?: boolean
}

export const CarouselItem = ({ children, border = true }: CarouselItemProps) => {
  const Component: React.FC<PropsWithChildren & { border: boolean; style: any }> = border ? Panel : Div
  return (
    <CarouselItemDiv style={{ width: '100%', whiteSpace: 'pre-wrap' }}>
      <Component border={border} style={{ width: '100%' }}>
        {children}
      </Component>
    </CarouselItemDiv>
  )
}

interface CarouselProps {
  children: React.ReactNode
  activeIndex?: number
  showButtons?: boolean
}

const Carousel = ({ children, activeIndex = 1, showButtons = false }: CarouselProps) => {
  const [_activeIndex, _setActiveIndex] = useState<number>(activeIndex)
  const [hover, setHover] = useState<boolean>(false)
  const _showButtons = showButtons && Array.isArray(children) && children?.length > 1

  useEffect(() => _setActiveIndex(activeIndex), [activeIndex])

  return (
    <CarouselDiv
      className='carrousel'
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <CarouselContentDiv activeIndex={_activeIndex}>{children}</CarouselContentDiv>
      {_showButtons && (
        <CarouselButtonsDiv hover={hover}>
          <ButtonsDiv>
            <Button
              disabled={_activeIndex === 1}
              icon={<ChevronLeft size='1.5rem' />}
              onClick={() => _setActiveIndex(_activeIndex - 1)}
            />
            <Button
              disabled={Array.isArray(children) && children.length === _activeIndex}
              icon={<ChevronRight size='1.5rem' />}
              onClick={() => _setActiveIndex(_activeIndex + 1)}
            />
          </ButtonsDiv>
        </CarouselButtonsDiv>
      )}
    </CarouselDiv>
  )
}

export default Carousel
