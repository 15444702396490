var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import cl from 'clsx';
import React, { createContext, forwardRef } from 'react';
import AccordionContent from './AccordionContent';
import AccordionHeader from './AccordionHeader';
import AccordionItem from './AccordionItem';
export const AccordionContext = createContext(null);
const Accordion = forwardRef((_a, ref) => {
    var { arrow = true, border = false, className, variant = 'primary', size = 'medium' } = _a, rest = __rest(_a, ["arrow", "border", "className", "variant", "size"]);
    return (React.createElement(AccordionContext.Provider, { value: {
            arrow,
            border,
            size
        } },
        React.createElement("div", Object.assign({}, rest, { ref: ref, className: cl('cds-accordion', `cds-accordion--${variant}`, `cds-accordion--${size}`, {
                'cds-accordion--border': border,
                'cds-accordion--arrow': arrow
            }, className) }))));
});
Accordion.Header = AccordionHeader;
Accordion.Content = AccordionContent;
Accordion.Item = AccordionItem;
Accordion.displayName = 'Accordion';
export default Accordion;
