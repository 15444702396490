import {
  Button,
  FlexDiv,
  FlexEndDiv,
  HorizontalSpace,
  PaddedDiv,
  PileEndDiv,
  RangeSlider,
  Switch,
  VerticalSpace
} from '@cegal/ds-components'
import { Search } from '@cegal/ds-icons/dist/Search'
import {
  Certificate,
  Consultant,
  ConsultantsQuery,
  Engagement,
  Location,
  Role,
  Skill
} from 'declarations/models'
import { DateTime } from 'luxon'
import { eventLogger, standardLogger } from 'metrics/loggers'
import { useAppSelector } from 'store'

import ConsultantsFilterAndSort from 'components/Consultants/ConsultantsFilterAndSort'
import ConsultantSelect from 'components/Forms/ConsultantSelect/ConsultantSelect'
import FacetedFilterSection from 'components/Forms/FacetedFilterSection/FacetedFilterSection'
import FacetedFilterSectionMeta from 'components/Forms/FacetedFilterSection/FacetedFilterSectionMeta'
import FacetedFilterSectionWrapper from 'components/Forms/FacetedFilterSection/FacetedFilterSectionWrapper'
import { DrawerButtonsDiv } from 'components/styled'

import React, { useEffect, useMemo, useState } from 'react'
import { DateRange } from 'react-day-picker'
import 'react-day-picker/dist/style.css'
import { useTranslation } from 'react-i18next'

import useQuery from 'hooks/useQuery'

import { toDateFormatYYYYMMDD } from 'utils'
import { Option, OptionsSortBy, dataToOptionsSortedByOccurrence } from 'utils/fieldUtils'
import { initialConsultantsQuery } from 'utils/query'

import AllocationDayPicker from './AllocationDayPicker/AllocationDayPicker'

import _ from 'lodash'

export type ConsultantFilterContext = 'consultants' | 'matches'

export interface ConsultantsFilterProps {
  context: ConsultantFilterContext
  engagement?: Engagement | null | undefined
  onClose: () => void
}

const ALLOCATION_DISPLAY_RANGE_MIN: number = 0
const ALLOCATION_DISPLAY_RANGE_MAX: number = 100

const ConsultantsFilter = <Q extends ConsultantsQuery>({
  context,
  engagement,
  onClose
}: ConsultantsFilterProps) => {
  const { t } = useTranslation()
  const { query, setQuery } = useQuery<Q>(initialConsultantsQuery as Q)

  const allocationsLoading: boolean = useAppSelector((state) => state.cache.consultantAllocationsSearching)
  const consultants = useAppSelector((state) => state.consultants.list)
  const consultantsLoading = useAppSelector((state) => state.consultants.listing)

  const [skillsOptions, setSkillsOptions] = useState<Array<Option<Skill>> | undefined>(undefined)
  const [locationOptions, setLocationOptions] = useState<Array<Option<Location>> | undefined>(undefined)
  const [rolesOptions, setRolesOptions] = useState<Array<Option<Role>> | undefined>(undefined)
  const [certificatesOptions, setCertificatesOptions] = useState<Array<Option<Certificate>> | undefined>(
    undefined
  )
  const [collapsed, setCollapsed] = useState<boolean>(false)

  const ALLOCATION_START_DATE_DEFAULT = DateTime.now().toJSDate()
  const ALLOCATION_END_DATE_DEFAULT = DateTime.now().plus({ months: 6 }).toJSDate()

  const [allocationValues, setAllocationValues] = useState<Array<number>>()
  const [allocationDates, setAllocationDates] = useState<DateRange>()

  const selectedCertificates = useMemo(
    () =>
      certificatesOptions?.filter((option: Option<Certificate>) =>
        Array.isArray(query?.certificates)
          ? query?.certificates?.includes(option.id)
          : _.isNumber(query?.certificates)
            ? query?.certificates === option.id
            : false
      ) ?? [],
    [certificatesOptions, query?.certificates]
  )

  const selectedSkills: Array<Option<Skill>> = useMemo(
    () =>
      skillsOptions?.filter((s: Skill) =>
        Array.isArray(query?.skills)
          ? query?.skills?.includes(s.id as number)
          : _.isNumber(query?.skills)
            ? query?.skills === s.id
            : false
      ) ?? [],
    [skillsOptions, query?.skills]
  )

  const selectedLocations = useMemo(
    () =>
      locationOptions?.filter((option: Option<Location>) =>
        Array.isArray(query?.locations)
          ? query?.locations?.includes(option.id)
          : _.isNumber(query?.locations)
            ? query?.locations === option.id
            : false
      ) ?? [],
    [locationOptions, query?.locations]
  )

  const selectedRoles: Array<Option<Role>> = useMemo(
    () =>
      rolesOptions?.filter((r: Role) =>
        Array.isArray(query?.roles)
          ? query?.roles?.includes(r.id as number)
          : _.isNumber(query?.roles)
            ? query?.roles === r.id
            : false
      ) ?? [],
    [rolesOptions, query?.roles]
  )

  const selectedManager: Option<Consultant> | null = useMemo(() => {
    const manager = _.find(consultants, (c) => c.id === query?.manager)
    return manager
      ? ({ label: manager.name, value: manager.id, id: manager.id, name: manager.name } as Option<Consultant>)
      : null
  }, [consultants, query?.manager])

  useEffect(() => {
    const _certificatesOptions = dataToOptionsSortedByOccurrence<Certificate, 'id', 'name'>({
      data: consultants?.flatMap((consultant) => consultant.certificates) || [],
      valueSelector: 'id',
      labelSelector: 'name',
      showOccurrence: true,
      sortBy: OptionsSortBy.OCCURRENCE_DESC
    })
    setCertificatesOptions(_certificatesOptions)

    const _skillsOptions = dataToOptionsSortedByOccurrence<Skill, 'id', 'name'>({
      data: consultants?.flatMap((consultant) => consultant.skills) || [],
      valueSelector: 'id',
      labelSelector: 'name',
      showOccurrence: true,
      sortBy: OptionsSortBy.OCCURRENCE_DESC
    })
    setSkillsOptions(_skillsOptions)

    const _locationOptions = dataToOptionsSortedByOccurrence<Location, 'id', 'name'>({
      // locations may not be filled yet, so do not fill with undefined elements
      data: consultants?.map((c) => c.location).filter((l) => !!l) || [],
      valueSelector: 'id',
      labelSelector: 'name',
      showOccurrence: true,
      sortBy: OptionsSortBy.OCCURRENCE_DESC
    })
    setLocationOptions(_locationOptions)

    const _rolesOptions = dataToOptionsSortedByOccurrence<Role, 'id', 'name'>({
      data: consultants?.flatMap((consultant) => consultant.roles) || [],
      valueSelector: 'id',
      labelSelector: 'name',
      showOccurrence: true,
      sortBy: OptionsSortBy.OCCURRENCE_DESC
    })
    setRolesOptions(_rolesOptions)
  }, [consultants])

  useEffect(() => {
    if (
      !_.isEqual(query?.allocation, allocationValues) ||
      !DateTime.fromISO(query.allocationFrom!).equals(DateTime.fromJSDate(allocationDates?.from!)) ||
      !DateTime.fromISO(query.allocationTo!).equals(DateTime.fromJSDate(allocationDates?.to!))
    ) {
      setAllocationValues(
        !_.isEmpty(query?.allocation)
          ? query!!.allocation!!
          : [ALLOCATION_DISPLAY_RANGE_MIN, ALLOCATION_DISPLAY_RANGE_MAX]
      )

      setAllocationDates({
        from: query.allocationFrom ? new Date(query.allocationFrom) : ALLOCATION_START_DATE_DEFAULT,
        to: query.allocationTo ? new Date(query.allocationTo) : ALLOCATION_END_DATE_DEFAULT
      })
    }
  }, [query.allocation, query.allocationFrom, query.allocationTo])

  const handleFollowed = (e: React.ChangeEvent<HTMLInputElement>) => {
    standardLogger(context + '.filters.followed')
    setQuery({ followed: e.target.checked } as Q)
  }

  const handleAllocation = () => {
    standardLogger(context + '.filters.allocation')
    setQuery({
      allocation: allocationValues?.[0] === 0 && allocationValues?.[1] === 100 ? [] : allocationValues,
      allocationFrom: DateTime.fromJSDate(allocationDates!.from!).equals(
        DateTime.fromJSDate(ALLOCATION_START_DATE_DEFAULT)
      )
        ? ''
        : toDateFormatYYYYMMDD(allocationDates!.from!),
      allocationTo: DateTime.fromJSDate(allocationDates!.to!).equals(
        DateTime.fromJSDate(ALLOCATION_END_DATE_DEFAULT)
      )
        ? ''
        : toDateFormatYYYYMMDD(allocationDates!.to!)
    } as Partial<Q>)
  }

  const resetAllocation = () => {
    standardLogger(context + '.filters.allocation')
    setAllocationValues([ALLOCATION_DISPLAY_RANGE_MIN, ALLOCATION_DISPLAY_RANGE_MAX])
    setAllocationDates({
      from: ALLOCATION_START_DATE_DEFAULT,
      to: ALLOCATION_END_DATE_DEFAULT
    })
    setQuery({
      allocation: [] as Array<number>,
      allocationFrom: '',
      allocationTo: ''
    } as Partial<Q>)
  }

  const handleRoles = (_roleOptions: Array<Option<Role>>) => {
    standardLogger(context + '.filters.roles')
    setQuery({ roles: _roleOptions.map((option) => option.id) } as Q)
  }

  const handleAllRoles = (e: React.ChangeEvent<HTMLInputElement>) => {
    standardLogger(context + '.filters.roles.mode')
    setQuery({ allRoles: e.target.checked } as Q)
  }

  const handleManager = (consultant: Consultant) => {
    standardLogger(context + '.filters.manager')
    setQuery({ manager: consultant?.id } as Q)
  }

  const handleLocations = (_locationOptions: Array<Option<Location>>) => {
    standardLogger(context + '.filters.locations')
    setQuery({ locations: _locationOptions.map((option) => option.id) } as Q)
  }

  const handleAllLocations = (e: React.ChangeEvent<HTMLInputElement>) => {
    standardLogger(context + '.filters.locations.mode')
    setQuery({ allLocations: e.target.checked } as Q)
  }

  const handleAllSkills = (e: React.ChangeEvent<HTMLInputElement>) => {
    standardLogger(context + '.filters.skills.mode')
    setQuery({ allSkills: e.target.checked } as Q)
  }

  const handleSkills = (skillOptions: Array<Option<Skill>>) => {
    standardLogger(context + '.filters.skills')
    setQuery({ skills: skillOptions.map((option) => option.id) } as Q)
  }

  const handleCertificates = (_certificatesOptions: Array<Option<Certificate>>) => {
    standardLogger(context + '.filters.skills')
    setQuery({ certificates: _certificatesOptions.map((option) => option.id) } as Q)
  }

  const handleAllCertificates = (e: React.ChangeEvent<HTMLInputElement>) => {
    standardLogger(context + '.filters.certificates.mode')
    setQuery({ allCertificates: e.target.checked } as Q)
  }

  const handleClear = (e: any) => {
    eventLogger(e)
    setQuery({
      ...initialConsultantsQuery,
      allocation: [] as Array<number>,
      allocationFrom: '',
      allocationTo: ''
    } as Partial<Q>)
  }

  const handleClose = (e: any) => {
    eventLogger(e)
    onClose()
  }

  const handleCollapse = () => {
    setCollapsed(!collapsed)
  }

  const disableAllocationSearch =
    allocationDates?.from === undefined || allocationDates?.to === undefined || allocationsLoading

  return (
    <PaddedDiv>
      <FacetedFilterSectionMeta
        header={t('header:filter-options')}
        collapsed={collapsed}
        onCollapse={handleCollapse}
      />
      <VerticalSpace />

      <FacetedFilterSectionWrapper label={t('label:allocation-title')} collapsed={collapsed}>
        <RangeSlider
          label={
            <div style={{ marginBottom: '0.3rem' }}>
              {`${t('label:allocation-title')}: ${allocationValues?.[0]} - ${allocationValues?.[1]}%`}
            </div>
          }
          min={ALLOCATION_DISPLAY_RANGE_MIN}
          max={ALLOCATION_DISPLAY_RANGE_MAX}
          defaultValue={allocationValues}
          value={allocationValues}
          onChange={setAllocationValues}
        />
        <VerticalSpace />
        <AllocationDayPicker
          key={JSON.stringify(allocationDates)}
          value={allocationDates}
          onChange={(c: any) => setAllocationDates(c)}
        />
        <VerticalSpace />
        <PileEndDiv>
          <FlexDiv>
            <Button
              size='small'
              variant='secondary'
              spacing
              disabled={allocationsLoading}
              onClick={resetAllocation}
            >
              {t('buttons:reset')}
            </Button>
            <HorizontalSpace />
            <Button
              size='small'
              spacing
              disabled={disableAllocationSearch}
              loading={allocationsLoading}
              icon={<Search size='0.85rem' />}
              onClick={handleAllocation}
            >
              {allocationsLoading ? t('messages:searching') : t('buttons:search')}
            </Button>
          </FlexDiv>
        </PileEndDiv>
      </FacetedFilterSectionWrapper>

      <FacetedFilterSectionWrapper label={t('buttons:options')} collapsed={collapsed}>
        {context === 'matches' && (
          <ConsultantsFilterAndSort query={query!} setQuery={setQuery} engagement={engagement} />
        )}

        <Switch size='small' position='left' onChange={handleFollowed} checked={!!query?.followed}>
          {t('label:filter-show-only-followed-consultants')}
        </Switch>
        <VerticalSpace />
        <ConsultantSelect
          key={'manager-' + selectedManager?.id}
          menuPosition='fixed'
          label={t('label:manager-title')}
          loading={consultantsLoading}
          defaultValue={selectedManager}
          onChange={handleManager}
          consultants={consultants}
        />
        <VerticalSpace />
      </FacetedFilterSectionWrapper>

      <FacetedFilterSection
        onChange={handleSkills}
        options={skillsOptions}
        label={t('label:skills')}
        value={selectedSkills}
        collapsed={collapsed}
        showSearchButton
        showSettingsButton
        showClearButton
        settingsProps={{
          onChange: handleAllSkills,
          checked: query?.allSkills,
          switchText: query?.allSkills ? t('label:filter-all-skills') : t('label:filter-some-skills')
        }}
      />
      <FacetedFilterSection
        onChange={handleCertificates}
        options={certificatesOptions}
        label={t('label:certificates')}
        value={selectedCertificates}
        collapsed={collapsed}
        showSearchButton
        showSettingsButton
        showClearButton
        settingsProps={{
          onChange: handleAllCertificates,
          checked: query?.allCertificates,
          switchText: query?.allCertificates
            ? t('label:filter-all-certificates')
            : t('label:filter-some-certificates')
        }}
      />
      <FacetedFilterSection
        onChange={handleLocations}
        options={locationOptions}
        label={t('label:locations-title')}
        value={selectedLocations}
        collapsed={collapsed}
        showSearchButton
        showSettingsButton
        showClearButton
        settingsProps={{
          onChange: handleAllLocations,
          checked: query?.allLocations,
          switchText: query?.allLocations ? t('label:filter-all-locations') : t('label:filter-some-locations')
        }}
      />
      <FacetedFilterSection
        onChange={handleRoles}
        options={rolesOptions}
        label={t('label:roles')}
        value={selectedRoles}
        collapsed={collapsed}
        showSearchButton
        showSettingsButton
        showClearButton
        settingsProps={{
          onChange: handleAllRoles,
          checked: query?.allRoles,
          switchText: query?.allRoles ? t('label:filter-all-roles') : t('label:filter-some-roles')
        }}
      />

      <VerticalSpace />
      <DrawerButtonsDiv>
        <FlexEndDiv>
          <Button data-amplitude={context + '.filters.clear'} variant='secondary' onClick={handleClear}>
            {t('buttons:reset')}
          </Button>
          <HorizontalSpace />
          <Button data-amplitude={context + '.filters.close'} onClick={handleClose}>
            {t('buttons:close')}
          </Button>
        </FlexEndDiv>
      </DrawerButtonsDiv>
    </PaddedDiv>
  )
}

export default ConsultantsFilter
