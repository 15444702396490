import cl from 'clsx';
import { useContext } from 'react';
import { useId } from 'util/index';
import { FieldsetContext } from './index';
export const useFormField = (props, prefix) => {
    var _a, _b, _c;
    const { size, error, errorId: propErrorId } = props;
    const fieldset = useContext(FieldsetContext);
    const genId = useId();
    const id = (_a = props.id) !== null && _a !== void 0 ? _a : `${prefix}-${genId}`;
    const errorId = propErrorId !== null && propErrorId !== void 0 ? propErrorId : `${prefix}-error-${genId}`;
    const inputDescriptionId = `${prefix}-description-${genId}`;
    const disabled = (fieldset === null || fieldset === void 0 ? void 0 : fieldset.disabled) || props.disabled;
    const hasError = !disabled && !!(error || (fieldset === null || fieldset === void 0 ? void 0 : fieldset.error));
    const showErrorMsg = !disabled && !!error && typeof error !== 'boolean';
    return {
        showErrorMsg,
        hasError,
        errorId,
        inputDescriptionId,
        size: (_b = size !== null && size !== void 0 ? size : fieldset === null || fieldset === void 0 ? void 0 : fieldset.size) !== null && _b !== void 0 ? _b : 'medium',
        inputProps: {
            id,
            'aria-invalid': hasError,
            'aria-describedby': cl(props['aria-describedby'], {
                [inputDescriptionId]: !!(props === null || props === void 0 ? void 0 : props.description) && typeof (props === null || props === void 0 ? void 0 : props.description) === 'string',
                [errorId]: showErrorMsg,
                [(_c = fieldset === null || fieldset === void 0 ? void 0 : fieldset.errorId) !== null && _c !== void 0 ? _c : '']: hasError && !!(fieldset === null || fieldset === void 0 ? void 0 : fieldset.error)
            }) || undefined,
            disabled
        }
    };
};
