var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import cl from 'clsx';
import { BodyShort, Detail } from 'components/typography';
import { Loader } from 'components/loader';
import React, { forwardRef, useEffect, useState } from 'react';
import { omit } from 'util/index';
import { useFormField } from './useFormField';
import { Check } from '@cegal/ds-icons/dist/Check';
export const Switch = forwardRef((props, ref) => {
    var _a, _b, _c;
    const { inputProps, hasError, size } = useFormField(props, 'switch');
    const { children, className, description, hideLabel = false, loading, checked: checkedProp, defaultChecked, position = 'left' } = props, rest = __rest(props, ["children", "className", "description", "hideLabel", "loading", "checked", "defaultChecked", "position"]);
    const Description = size === 'medium' ? BodyShort : Detail;
    const [checked, setChecked] = useState((_a = defaultChecked !== null && defaultChecked !== void 0 ? defaultChecked : checkedProp) !== null && _a !== void 0 ? _a : false);
    useEffect(() => {
        checkedProp !== undefined && setChecked(checkedProp);
    }, [checkedProp]);
    const handleChange = (e) => {
        setChecked(e.target.checked);
        props.onChange && props.onChange(e);
    };
    return (React.createElement("div", { className: cl('cds-switch', `cds-switch--${size}`, `cds-switch--${position}`, {
            'cds-switch--error': hasError,
            'cds-switch--loading': loading,
            'cds-switch--disabled': (_b = inputProps.disabled) !== null && _b !== void 0 ? _b : loading
        }, props.className) },
        React.createElement("input", Object.assign({}, omit(rest, ['size']), omit(inputProps, ['aria-invalid', 'aria-describedby']), { ref: ref, disabled: (_c = inputProps.disabled) !== null && _c !== void 0 ? _c : loading, checked: checkedProp, defaultChecked: defaultChecked, type: 'checkbox', onChange: (e) => handleChange(e), className: cl(className, 'cds-switch__input') })),
        React.createElement("span", { className: 'cds-switch__track' },
            React.createElement("span", { className: 'cds-switch__thumb' }, loading ? React.createElement(Loader, { size: 'xsmall', "aria-live": 'polite' }) : checked ? React.createElement(Check, { size: '1rem' }) : null)),
        React.createElement("label", { htmlFor: inputProps.id, className: 'cds-switch__label-wrapper' },
            React.createElement("div", { className: cl('cds-switch__content', {
                    'cds-sr-only': hideLabel,
                    'cds-switch--with-description': !!description && !hideLabel
                }) },
                React.createElement(BodyShort, { as: 'div', size: size, className: 'cds-switch__label' }, children),
                description && (React.createElement(Description, { as: 'div', size: 'small', className: 'cds-switch__description' }, description))))));
});
Switch.displayName = 'Switch';
export default Switch;
