import { FlexBaseDiv, HorizontalSpace, Modal, Panel } from '@cegal/ds-components'
import { Engagement } from 'declarations/models'

import EngagementViewContent from 'components/Engagements/Engagement/EngagementViewContent'

import { useRef } from 'react'
import { useTranslation } from 'react-i18next'

export interface OpenEngagementPreviewModalProps {
  open: boolean
  engagement?: Engagement
  onClose: () => void
}

export type EngagementToPreview = Engagement

const EngagementPreviewModal = ({ open, engagement, onClose }: OpenEngagementPreviewModalProps) => {
  const { t } = useTranslation()
  const modalContainerRef = useRef(null)

  return (
    <FlexBaseDiv ref={modalContainerRef}>
      {modalContainerRef?.current && (
        <Modal
          aria-label={t('header:Engagement-preview')}
          parentSelector={() => modalContainerRef.current!}
          open={open}
          onClose={onClose}
        >
          <HorizontalSpace />
          <Modal.Content>
            <Panel style={{ width: '80vw', height: '80vh' }}>
              <EngagementViewContent engagement={engagement!} interactive={false} />
            </Panel>
          </Modal.Content>
        </Modal>
      )}
    </FlexBaseDiv>
  )
}

export default EngagementPreviewModal
