import { listClients, listFollowedClients } from 'actions'
import { UseFilterObject } from 'declarations/app'
import { Client, ClientsQuery } from 'declarations/models'
import { useAppDispatch, useAppSelector } from 'store'

import { useEffect, useState } from 'react'

import { filterClients, getSearchableClients, sortClients } from 'hooks/useClientFilterUtils'
import useQuery from 'hooks/useQuery'

import _ from 'lodash'

export type SearchableClient = {
  textSearch: Record<string, Array<string>>
  client: Client
  score?: number
}

const useClientFilter = (
  initialQuery: ClientsQuery,
  handlePageChange: (page: number) => void
): UseFilterObject<Client> => {
  const dispatch = useAppDispatch()

  const apiReady = useAppSelector((state) => state.app.apiReady)
  const clients = useAppSelector((state) => state.clients.list)
  const clientsLoading = useAppSelector((state) => state.clients.listing)
  const itemsPerPage = useAppSelector((state) => state.app.settings.itemsPerPage)
  const followedClients = useAppSelector((state) => state.followedClients.list)

  const [searchableClients, setSearchableClients] = useState<SearchableClient[]>([])
  const [numberOfFilteredClients, setNumberOfFilteredClients] = useState<number>(0)
  const [paginatedClients, setPaginatedClients] = useState<Client[]>([])
  const [numberOfPages, setNumberOfPages] = useState<number>(0)

  const { query } = useQuery(initialQuery)

  const loadPageData = () => {
    dispatch(listClients())
    if (_.isUndefined(followedClients)) {
      dispatch(listFollowedClients())
    }
  }

  useEffect(() => {
    if (clients === undefined && apiReady && !clientsLoading) {
      loadPageData()
    }
  }, [apiReady])

  useEffect(() => {
    if (clients?.length) {
      const searchableClientsList = getSearchableClients(clients)
      setSearchableClients(searchableClientsList)
    }
  }, [clients])

  useEffect(() => {
    const _filteredClients: Array<SearchableClient> = filterClients({
      searchableClients,
      query,
      followedClients
    }).sort(sortClients(query))
    setNumberOfFilteredClients(_filteredClients?.length ?? 0)

    const page = query?.page ? +query?.page : 1

    const firstPageIndex = (page - 1) * itemsPerPage
    const lastPageIndex = firstPageIndex + itemsPerPage
    const _paginatedClients = _filteredClients?.slice(firstPageIndex, lastPageIndex)

    if (searchableClients.length > 0 && _paginatedClients.length === 0) {
      handlePageChange(1)
    }
    setPaginatedClients(_paginatedClients.map((c: SearchableClient) => c.client))

    const _numberOfPages = Math.ceil((_filteredClients?.length ?? 0) / itemsPerPage)
    setNumberOfPages(_numberOfPages)
  }, [query, itemsPerPage, searchableClients])

  return {
    loadPageData,
    loading: clientsLoading,
    loaded: !!clients,
    total: numberOfFilteredClients,
    items: paginatedClients,
    pages: numberOfPages
  }
}

export default useClientFilter
