import { Container } from '@cegal/ds-components'
import { setDisplaySize } from 'actions'
import { standardLogger } from 'metrics/loggers'
import { useAppDispatch, useAppSelector } from 'store'

import Header from 'components/Header/Header'
import Statistics from 'components/Statistics/Statistics'

import { PropsWithChildren, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import useWindowSize from 'hooks/useWindowSize'

import i18n from 'i18n'

import _ from 'lodash'

const MetaContainer = ({ children }: PropsWithChildren) => {
  const { pathname } = useLocation()
  const dimensions = useWindowSize()
  const dispatch = useAppDispatch()

  const displaySize = useAppSelector((state) => state.app.displaySize)
  const theme = useAppSelector((state) => state.app.settings.theme)
  const language = useAppSelector((state) => state.app.settings.language)

  useEffect(() => {
    i18n.changeLanguage(language)
    document.documentElement.lang = language
    standardLogger('session', { theme, language, displaySize })
  }, [])

  /* with path changes, scroll to top */
  useEffect(() => {
    const canControlScrollRestoration = 'scrollRestoration' in window.history
    /* istanbul ignore next */
    if (canControlScrollRestoration) {
      window.history.scrollRestoration = 'manual'
    }
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    if (_.isNumber(dimensions.width)) {
      if (dimensions.width <= 576 && displaySize !== 'sm') {
        dispatch(setDisplaySize('sm'))
      }
      if (dimensions.width > 576 && dimensions.width <= 768 && displaySize !== 'md') {
        dispatch(setDisplaySize('md'))
      }
      if (dimensions.width > 768 && dimensions.width <= 992 && displaySize !== 'lg') {
        dispatch(setDisplaySize('lg'))
      }
      if (dimensions.width > 992 && dimensions.width <= 1200 && displaySize !== 'xl') {
        dispatch(setDisplaySize('xl'))
      }
      if (dimensions.width > 1200 && displaySize !== 'xxl') {
        dispatch(setDisplaySize('xxl'))
      }
    }
  }, [dimensions])

  return (
    <>
      <Statistics />
      <Container data-theme={theme}>
        <Header />
        {children}
      </Container>
    </>
  )
}

export default MetaContainer
