import {
  Alert,
  Button,
  Column,
  FlexBaseDiv,
  FlexBaseSpacedDiv,
  FlexCenterDiv,
  FlexStartDiv,
  Heading,
  HorizontalSpace,
  Loader,
  Pagination,
  PileDiv,
  PileEndDiv,
  Row,
  TextField,
  VerticalSpace
} from '@cegal/ds-components'
import { Add } from '@cegal/ds-icons/dist/Add'
import { FilterAlt as FilterIcon } from '@cegal/ds-icons/dist/FilterAlt'
import { clearAlertBody } from 'actions'
import { ENGAGEMENTS_DELETE_SUCCESS, ENGAGEMENTS_LIST_FAILURE } from 'constants/actionTypes'
import { DisplaySize, ViewMode } from 'declarations/app'
import { EngagementsQuery } from 'declarations/models'
import { eventLogger, standardLogger } from 'metrics/loggers'
import { AlertsState } from 'reducers/alerts'
import { useAppDispatch, useAppSelector } from 'store'

import AlertPanel from 'components/AlertPanel/AlertPanel'
import { EngagementsFilter } from 'components/Engagements'
import EngagementsDescription from 'components/Engagements/EngagementsDescription'
import EngagementsView from 'components/Engagements/EngagementsView'
import ToggleView from 'components/Forms/ToggleView'
import { FullScreenLoader } from 'components/Loader/CustomLoader'
import PageContent from 'components/PageContent/PageContent'

import { useDeferredValue, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import useEngagementFilter from 'hooks/useEngagementFilter'
import useQuery from 'hooks/useQuery'
import useUnmount from 'hooks/useUnmount'

import { initialEngagementsQuery } from 'utils/query'
import { getNextSort } from 'utils/sort'

import _ from 'lodash'

const EngagementsList = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const viewMode = useAppSelector((state) => state.app.settings.viewMode)
  const itemsPerPage = useAppSelector((state) => state.app.settings.itemsPerPage)
  const displaySize = useAppSelector((state) => state.app.displaySize)
  const alertBody = useAppSelector((state) => (state.alerts as AlertsState).body)

  const [isDrawerOpen, setIsDrawerOpen] = useState(true)
  const [_viewMode, setViewMode] = useState<ViewMode>(viewMode)
  const { query, setQuery } = useQuery<EngagementsQuery>(initialEngagementsQuery)

  const [inputValue, setInputValue] = useState(query?.search ?? '')
  const debouncedValue = useDeferredValue(inputValue)

  const deleteAlert = _.find(alertBody, { type: ENGAGEMENTS_DELETE_SUCCESS })

  const handlePageChange = (page: number) => {
    window.scrollTo(0, 0)
    standardLogger('engagements.list.page', { page })
    setQuery({ page: page === 1 ? undefined : page?.toString() })
  }

  const {
    pages,
    loadPageData,
    items: engagements,
    loaded,
    total
  } = useEngagementFilter(initialEngagementsQuery, handlePageChange)

  const handleSort = (sortKey?: string | undefined) => setQuery({ sort: getNextSort(query?.sort, sortKey) })

  const toggleDrawer = () => {
    standardLogger('engagements.filters.' + (isDrawerOpen ? 'hide' : 'show'))
    setIsDrawerOpen(!isDrawerOpen)
  }

  const setOrientationAndViewMode = (displaySize: DisplaySize) => {
    if (displaySize === 'sm' || displaySize === 'md') {
      setViewMode('card')
    } else {
      setViewMode(viewMode)
    }
  }

  const navigateToNewEngagement = (e: any) => {
    eventLogger(e)
    navigate('/engagements/new')
  }

  useUnmount(() => {
    if (deleteAlert) {
      dispatch(clearAlertBody([ENGAGEMENTS_DELETE_SUCCESS]))
    }
  })

  useEffect(() => setViewMode(viewMode), [viewMode])

  useEffect(() => setOrientationAndViewMode(displaySize), [displaySize])

  useEffect(() => setInputValue(query.search!), [query?.search])

  const updateSearchQuery = (searchPhrase: string) =>
    setQuery((query: Partial<EngagementsQuery> | undefined) => ({ ...query, search: searchPhrase }))

  const debouncedChangeHandler = useMemo(() => _.debounce(updateSearchQuery, 1000), [])

  const UpdateSearch = (searchPhrase: string) => {
    setInputValue(searchPhrase)
    debouncedChangeHandler(searchPhrase)
  }

  return (
    <PageContent
      maxWidth={viewMode === 'row' ? '100%' : '1600px'}
      isDrawerOpen={isDrawerOpen}
      drawer={!_.isNil(engagements) && <EngagementsFilter context='engagements' onClose={toggleDrawer} />}
    >
      <VerticalSpace />
      <FlexBaseSpacedDiv>
        <FlexBaseDiv>
          <PileDiv>
            <Heading level='2' size='large'>
              {t('header:engagements')}
            </Heading>
            <VerticalSpace />
          </PileDiv>
          <HorizontalSpace />
          <PileDiv>
            <FlexCenterDiv style={{ flexWrap: 'nowrap' }}>
              <Button
                data-cy='engagementFilterButton'
                variant='secondary'
                icon={<FilterIcon size='1.5rem' />}
                onClick={toggleDrawer}
              >
                {t(isDrawerOpen ? 'buttons:close-filter-options' : 'buttons:open-filter-options')}
              </Button>
              <HorizontalSpace />
              <TextField
                hideLabel
                description={t('label:search-description')}
                label={t('label:search-title')}
                placeholder={t('label:search-title')}
                value={inputValue}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => UpdateSearch(e.target.value)}
              />
              <HorizontalSpace size='0.5' />
              {debouncedValue !== inputValue && <Loader size='small' />}
            </FlexCenterDiv>
            <VerticalSpace />
          </PileDiv>
        </FlexBaseDiv>
        <PileEndDiv flex='1'>
          <FlexStartDiv style={{ flexWrap: 'nowrap', justifyContent: 'flex-end' }}>
            <Button
              data-amplitude='engagements.list.engagement.new'
              variant='primary'
              icon={<Add size='1.5rem' />}
              onClick={navigateToNewEngagement}
            >
              {t('buttons:create-x', { x: t('label:engagement-title').toLowerCase() })}
            </Button>
            <HorizontalSpace />
            <ToggleView
              context='engagements.list'
              viewItemsPerPage
              viewToggleMode={!(displaySize === 'sm' || displaySize === 'md')}
            />
          </FlexStartDiv>
          <VerticalSpace />
        </PileEndDiv>
      </FlexBaseSpacedDiv>
      <EngagementsDescription total={total} query={query} itemsPerPage={itemsPerPage} setQuery={setQuery} />
      <VerticalSpace size='2' />
      {deleteAlert && (
        <>
          <Row>
            <Column />
            <Column flex='2'>
              <Alert variant='success'>{deleteAlert.message}</Alert>
            </Column>
            <Column />
          </Row>
          <VerticalSpace size='2' />
        </>
      )}
      {!loaded && <FullScreenLoader />}
      {_.isNull(engagements) && (
        <AlertPanel
          doNotRenderIfHeadIsActiveWithError
          watchFor={[ENGAGEMENTS_LIST_FAILURE]}
          buttonText={t('buttons:click-to-reload-the-page')!}
          reloadPage={loadPageData}
        />
      )}
      {!_.isNil(engagements) && (
        <>
          <EngagementsView
            viewMode={_viewMode}
            buttons={['find', 'follow']}
            engagements={engagements}
            onSortChange={handleSort}
          />
          <VerticalSpace size='2' />
          {pages > 1 && (
            <>
              <VerticalSpace size='2' />
              <Pagination
                style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
                size={displaySize === 'sm' ? 'small' : 'medium'}
                count={pages}
                page={query?.page ? +query?.page : 1}
                onPageChange={handlePageChange}
              />
            </>
          )}
        </>
      )}
    </PageContent>
  )
}

export default EngagementsList
