var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as RadixToggleGroup from '@radix-ui/react-toggle-group';
import cl from 'clsx';
import { Label } from 'components/typography';
import React, { createContext, forwardRef, useState } from 'react';
import { useId } from 'util/index';
import ToggleItem from './ToggleItem';
export const ToggleGroupContext = createContext(null);
export const ToggleGroup = forwardRef((_a, ref) => {
    var { className, children, onChange, size = 'medium', label, value, defaultValue, id, 'aria-describedby': desc } = _a, rest = __rest(_a, ["className", "children", "onChange", "size", "label", "value", "defaultValue", "id", 'aria-describedby']);
    const [groupValue, setGroupValue] = useState(defaultValue);
    const labelId = `toggle-group-label-${useId()}`;
    const handleValueChange = (v) => {
        if (v !== '') {
            setGroupValue(v);
            onChange === null || onChange === void 0 ? void 0 : onChange(v);
        }
    };
    if (!value && !defaultValue) {
        console.error('ToggleGroup without value/defaultvalue is not allowed');
    }
    const describeBy = cl({
        [desc !== null && desc !== void 0 ? desc : '']: !!desc,
        [labelId]: !!label
    });
    if (!value && !defaultValue) {
        console.error('ToggleGroup needs either a value or defaultValue');
    }
    return (React.createElement(ToggleGroupContext.Provider, { value: {
            size
        } },
        React.createElement("div", null,
            label && (React.createElement(Label, { size: size, className: 'cds-toggle-group__label', id: labelId }, label)),
            React.createElement(RadixToggleGroup.Root, Object.assign({}, rest, { ref: ref, className: cl('cds-toggle-group', `cds-toggle-group--${size}`, className), onValueChange: handleValueChange, value: value !== null && value !== void 0 ? value : groupValue, defaultValue: defaultValue }, (describeBy && { 'aria-describedby': describeBy }), { role: 'radiogroup', type: 'single' }), children))));
});
ToggleGroup.Item = ToggleItem;
ToggleGroup.displayName = 'ToggleGroup';
export default ToggleGroup;
