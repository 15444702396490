import {
  Button,
  Column,
  FlexEndDiv,
  Heading,
  HorizontalSpace,
  PaddedDiv,
  PileEndDiv,
  Row,
  Switch,
  VerticalSpace
} from '@cegal/ds-components'
import { eventLogger, standardLogger } from 'metrics/loggers'

import { useTranslation } from 'react-i18next'

import useQuery from 'hooks/useQuery'

import { initialClientsQuery } from 'utils/query'

type ClientsFilterProps = {
  onClose: () => void
}

const ClientsFilter = ({ onClose }: ClientsFilterProps) => {
  const { t } = useTranslation()
  const { query, setQuery } = useQuery({})

  const handleEmptyEngagement = (e: React.ChangeEvent<HTMLInputElement>) => {
    standardLogger('clients.filters.hasEngagements')
    setQuery({ hasEngagement: e.target.checked })
  }

  const handleNotEmptyEngagement = (e: React.ChangeEvent<HTMLInputElement>) => {
    standardLogger('clients.filters.hasNoEngagements')
    setQuery({ hasNoEngagement: e.target.checked })
  }

  const handleClear = (e: any) => {
    eventLogger(e)
    setQuery(initialClientsQuery)
  }

  const handleClose = (e: any) => {
    eventLogger(e)
    onClose()
  }

  return (
    <PaddedDiv>
      <Row>
        <Column>
          <Heading>{t('header:filter-options')}</Heading>
        </Column>
      </Row>
      <VerticalSpace size='1' />

      <Switch position='left' onChange={handleEmptyEngagement} checked={!!query?.hasEngagement}>
        {t('label:show-clients-with-engagement')}
      </Switch>

      <Switch position='left' onChange={handleNotEmptyEngagement} checked={!!query?.hasNoEngagement}>
        {t('label:show-clients-with-no-engagement')}
      </Switch>
      <VerticalSpace />
      <PileEndDiv>
        <FlexEndDiv>
          <Button data-amplitude='clients.filters.clear' variant='secondary' onClick={handleClear}>
            {t('buttons:clear')}
          </Button>
          <HorizontalSpace />
          <Button data-amplitude='consultants.filters.close' onClick={handleClose}>
            {t('buttons:close')}
          </Button>
        </FlexEndDiv>
      </PileEndDiv>
    </PaddedDiv>
  )
}

export default ClientsFilter
