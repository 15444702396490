import { Table } from '@cegal/ds-components'

import { useTranslation } from 'react-i18next'

const EngagementVacancyRowHeader = () => {
  const { t } = useTranslation()
  return (
    <Table.Row>
      <Table.HeaderCell>{t('label:title')}</Table.HeaderCell>
      <Table.HeaderCell>{t('label:allocation-title')}</Table.HeaderCell>
      <Table.HeaderCell>{t('label:hourly-rate-title')}</Table.HeaderCell>
      <Table.HeaderCell>{t('label:skills-title')}</Table.HeaderCell>
      <Table.HeaderCell>{t('label:certificates')}</Table.HeaderCell>
      <Table.HeaderCell>{t('label:last-modified')}</Table.HeaderCell>
      <Table.HeaderCell>{t('label:consultants')}</Table.HeaderCell>
      <Table.HeaderCell />
    </Table.Row>
  )
}

export default EngagementVacancyRowHeader
