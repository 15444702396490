import { TFunction } from 'i18next'

import _ from 'lodash'

export enum Locale {
  NO = 'no-NO'
}

export const toDateFormatYYYYMMDD = (date: Date) => {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000).toISOString().split('T')[0]
}

// writes 01.01.2020 into 1. jan 2020
export const toLocalDateFormat = (date: Date | string | undefined): string | undefined => {
  if (_.isNil(date)) return undefined
  return (typeof date === 'string' ? new Date(date) : date).toLocaleDateString(Locale.NO, {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  })
}

export const dateToPeriodFormat = (
  startDate: Date | string | undefined,
  endDate: Date | string | undefined,
  extension: number | undefined,
  t: TFunction<'translation', undefined>
): string => {
  let string = ''
  if (!startDate && !endDate) {
    return '-'
  }
  string += toLocalDateFormat(startDate) ?? t('label:unknown')
  string += ' - '
  string += toLocalDateFormat(endDate) ?? t('label:unknown')

  if (_.isNumber(extension) && extension > 0) {
    string +=
      ' (+' +
      extension +
      (extension === 1 ? t('label:month').toLowerCase() : t('label:months').toLowerCase()) +
      ')'
  }
  return string
}
