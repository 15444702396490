import * as types from 'constants/actionTypes'
import { DisplaySize, Settings, TablesMap, UserProfile, UserRole, WidgetTable } from 'declarations/app'
import { AnyAction } from 'redux'

import { IS_DEVELOPMENT } from 'utils/environment'
import { initialConsultantsTable, initialEngagementsTable } from 'utils/tableColumns'
import { initialWidgetTable } from 'utils/widgets'

export const initialSettings: Settings = {
  formMode: 'wizard',
  viewMode: 'row',
  homeMode: 'widget',
  itemsPerPage: 10,
  language: 'en',
  theme: 'cegal-light',
  aiDrawerOpen: false,
  aiTab: 'chat'
}

const initialTables: TablesMap = {
  consultants: initialConsultantsTable,
  engagements: initialEngagementsTable
}

export interface AppState {
  apiReady: boolean
  userId: number | null | undefined
  userProfile: UserProfile | null | undefined
  userRole: UserRole | null | undefined
  gettingUserProfile: boolean
  gettingUserId: boolean
  stats: Record<string, any> | null | undefined
  statsLoading: boolean
  settings: Settings
  tables: TablesMap
  widgetTable: WidgetTable
  displaySize: DisplaySize | undefined
  signingUp: boolean
  signUp: any
}

export enum LocalStorageKeys {
  SETTINGS = 'settings',
  TABLES = 'tables',
  WIDGETTABLE = 'widgetTable'
}

export const getLocalStorageItemValue = (id: LocalStorageKeys) => {
  const value = localStorage.getItem(id)
  if (!value || value === 'undefined') {
    return null
  }
  return JSON.parse(value)
}

const userAdmins: Array<number> = [1]

const getUserRoleFromId = (id: number): UserRole =>
  /* istanbul ignore next */ userAdmins.indexOf(id) >= 0 ? 'admin' : 'user'

export const initialAppState: AppState = {
  apiReady: false,
  userId: undefined,
  userRole: undefined,
  userProfile: undefined,
  gettingUserId: false,
  gettingUserProfile: false,
  stats: undefined,
  statsLoading: false,
  settings: getLocalStorageItemValue(LocalStorageKeys.SETTINGS) ?? initialSettings,
  tables: getLocalStorageItemValue(LocalStorageKeys.TABLES) ?? initialTables,
  widgetTable: getLocalStorageItemValue(LocalStorageKeys.WIDGETTABLE) ?? initialWidgetTable,
  displaySize: undefined,
  signingUp: false,
  signUp: undefined
}

const appReducer = (
  /* istanbul ignore next */
  state = initialAppState,
  action: AnyAction
) => {
  switch (action.type) {
    case types.APP_API_READY:
      return {
        ...state,
        apiReady: true
      }

    case types.APP_LOGOUT: {
      /* istanbul ignore if  */
      if (IS_DEVELOPMENT) {
        window.location.href = '/login'
      } else {
        window.location.href = '/oauth/logout'
      }
      return state
    }

    case types.APP_STATS_REQUEST:
      return {
        ...state,
        stats: undefined,
        statsLoading: true
      }

    case types.APP_STATS_SUCCESS:
      return {
        ...state,
        statsLoading: false,
        stats: action.payload
      }

    case types.APP_STATS_FAILURE:
      return {
        ...state,
        statsLoading: false,
        stats: null
      }

    case types.APP_PROFILE_REQUEST:
      return {
        ...state,
        gettingUserProfile: true
      }

    case types.APP_PROFILE_SUCCESS: {
      return {
        ...state,
        gettingUserProfile: false,
        userProfile: action.payload
      }
    }

    case types.APP_PROFILE_FAILURE:
      return {
        ...state,
        gettingUserProfile: false,
        userProfile: null
      }

    case types.APP_USERID_REQUEST:
      return {
        ...state,
        gettingUserId: true,
        userRole: undefined
      }

    case types.APP_USERID_SUCCESS: {
      return {
        ...state,
        gettingUserId: false,
        userId: action.payload.user_id,
        userRole: getUserRoleFromId(action.payload.user_id)
      }
    }

    case types.APP_USERID_FAILURE:
      return {
        ...state,
        gettingUserId: false,
        userId: null,
        userRole: null
      }

    case types.APP_SETTINGS_SET: {
      const newSettings = {
        ...state.settings,
        [action.payload.key]: action.payload.value
      }

      localStorage.setItem(LocalStorageKeys.SETTINGS, JSON.stringify(newSettings))
      return {
        ...state,
        settings: newSettings
      }
    }

    case types.APP_TABLES_SET: {
      const newTables = {
        ...state.tables,
        [action.payload.table]: action.payload.headers
      }
      localStorage.setItem(LocalStorageKeys.TABLES, JSON.stringify(newTables))
      return {
        ...state,
        tables: newTables
      }
    }

    case types.APP_WIDGETTABLE_SET: {
      localStorage.setItem(LocalStorageKeys.WIDGETTABLE, JSON.stringify(action.payload))
      return {
        ...state,
        widgetTable: action.payload
      }
    }

    case types.APP_DISPLAYSIZE_SET: {
      return {
        ...state,
        displaySize: action.payload
      }
    }

    case types.APP_SIGNUP_NEW_REQUEST:
      return {
        ...state,
        signingUp: true,
        signUp: undefined
      }

    case types.APP_SIGNUP_NEW_SUCCESS:
      return {
        ...state,
        signingUp: false,
        signUp: action.payload
      }

    case types.APP_SIGNUP_NEW_FAILURE:
      return {
        ...state,
        signingUp: false,
        signUp: null
      }

    default:
      return state
  }
}

export default appReducer
