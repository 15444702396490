import * as amplitude from '@amplitude/analytics-browser'
import { Button } from '@cegal/ds-components'
import { getUserId, getUserProfile, setApiReady } from 'actions'
import api from 'api'
import { useAppDispatch, useAppSelector } from 'store'

import { FullScreenLoader } from 'components/Loader/CustomLoader'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import _ from 'lodash'

const PrivateRoute = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()

  const apiReady = useAppSelector((state) => state.app.apiReady)
  const userId = useAppSelector((state) => state.app.userId)
  const userProfile = useAppSelector((state) => state.app.userProfile)
  const gettingUserId = useAppSelector((state) => state.app.gettingUserId)
  const gettingUserProfile = useAppSelector((state) => state.app.gettingUserProfile)

  useEffect(() => {
    if (_.isUndefined(userProfile) && !gettingUserProfile) {
      dispatch(getUserProfile())
    }
  }, [userProfile, gettingUserProfile])

  useEffect(() => {
    if (!_.isNumber(userId) && !gettingUserId && !_.isNil(userProfile)) {
      amplitude.setUserId(userProfile.name)
      dispatch(getUserId(userProfile.name))
    }
  }, [userId, userProfile, gettingUserId])

  useEffect(() => {
    if (_.isNumber(userId) && !apiReady) {
      api.setHeaders({
        'X-user-id': userId
      })
      dispatch(setApiReady())
    }
  }, [userId, apiReady])

  if (gettingUserId) {
    return <FullScreenLoader label={t('messages:loading-getting-user-id')} />
  }

  if (gettingUserProfile) {
    return <FullScreenLoader label={t('messages:loading-getting-user-profile')} />
  }

  if (!apiReady) {
    return (
      <div>
        <Button onClick={() => navigate('/login')}>Login</Button>
      </div>
    )
  }

  if (location.pathname === '/') {
    navigate('/home')
  }

  return <Outlet />
}

export default PrivateRoute
