import { FlexBaseDiv, Modal } from '@cegal/ds-components'
import { File } from 'declarations/models'

import FileRenderer from 'components/File/FileRenderer'

import { useRef } from 'react'
import { useTranslation } from 'react-i18next'

export interface UploadFileModalProps {
  open: boolean
  onClose: () => void
  fileToPreview?: File
  anchorEl?: Element | null
}

const FilePreviewModal = ({ onClose, open, fileToPreview, anchorEl = null }: UploadFileModalProps) => {
  const modalContainerRef = useRef(null)

  const { t } = useTranslation()

  const handleClose = () => {
    onClose()
  }

  return (
    <FlexBaseDiv ref={modalContainerRef}>
      {modalContainerRef?.current && (
        <Modal
          // @ts-ignore
          parentSelector={() => anchorEl ?? modalContainerRef.current!}
          open={open}
          onClose={handleClose}
          aria-label='Upload File'
          closeButton
          shouldCloseOnOverlayClick
        >
          <Modal.Content>
            <Modal.Content.Header>
              {t('label:file-name')} {fileToPreview?.name}
            </Modal.Content.Header>
            <Modal.Content.Body>
              {!!fileToPreview && <FileRenderer file={fileToPreview} />}
            </Modal.Content.Body>
          </Modal.Content>
        </Modal>
      )}
    </FlexBaseDiv>
  )
}

export default FilePreviewModal
