import {
  BodyShort,
  BoxSize,
  Button,
  Column,
  FlexDiv,
  FlexEndDiv,
  Heading,
  HorizontalSpace,
  PileDiv,
  Tag,
  VerticalSpace
} from '@cegal/ds-components'
import { AspectRatio, CheckCircleOutline, ErrorOutline } from '@cegal/ds-icons'
import { AdminSyncing } from 'declarations/models'

import { CardBody, CardBox, CardFooter } from 'components/Card/Card'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { toLocalDateFormat } from 'utils/date'

import SyncModal from './SyncModal'

export interface SyncCardProps {
  sync: AdminSyncing
  size?: BoxSize
  spacing?: boolean
  width?: string
  height?: string
}

const SyncCard = ({ sync, size = 'medium', width, spacing = true, height }: SyncCardProps) => {
  const { t } = useTranslation()
  const [openModal, setopenModal] = useState<boolean>(false)

  const onPreviewModal = () => {
    setopenModal(true)
  }

  const onCloseSyncModal = () => {
    setopenModal(false)
  }

  return (
    <>
      <SyncModal open={openModal} sync={sync} onClose={onCloseSyncModal} />
      <CardBox data-testid='SyncCard' size={size} spacing={spacing} border>
        <CardBody>
          <FlexDiv style={{ justifyContent: 'space-between' }}>
            <PileDiv style={{ flex: 1 }}>
              <FlexDiv style={{ marginLeft: '-0.5rem', marginRight: '-0.5rem' }}>
                <Column flex='4'>
                  <Heading size='xsmall' as='span'>
                    {sync.source_name}
                  </Heading>
                </Column>
              </FlexDiv>
              <VerticalSpace />
              <FlexDiv>
                {sync.status_code < 300 ? (
                  <Tag variant='success'>
                    <CheckCircleOutline size='1.5rem' />
                    <HorizontalSpace />
                    Success
                  </Tag>
                ) : (
                  <Tag variant='error'>
                    <ErrorOutline size='1.5rem' />
                    <HorizontalSpace />
                    Error
                  </Tag>
                )}
              </FlexDiv>
              <VerticalSpace />
              <FlexDiv>
                <BodyShort key={sync.id} size='small' as='span'>
                  {`Last Synced: ${toLocalDateFormat(sync.synced_date)}`}
                </BodyShort>
              </FlexDiv>
            </PileDiv>
          </FlexDiv>
        </CardBody>

        {size === 'medium' && (
          <CardFooter>
            <FlexEndDiv
              style={{
                justifyContent: 'end'
              }}
            >
              <Button
                size='small'
                variant='secondary'
                icon={<AspectRatio size='1.3rem' />}
                onClick={() => onPreviewModal()}
              >
                {t('buttons:preview')}
              </Button>
            </FlexEndDiv>
          </CardFooter>
        )}
      </CardBox>
    </>
  )
}

export default SyncCard
