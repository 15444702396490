import { BodyShort } from '@cegal/ds-components'
import { Note } from 'declarations/models'

import Card, { CardProps } from 'components/Card/Card'

import { useTranslation } from 'react-i18next'

const NoteCard = ({ item, onDelete, onEdit }: CardProps<Note, any>) => {
  const { t } = useTranslation()

  return (
    <Card<Note>
      item={item}
      fullWidth
      border
      spacing
      size='small'
      editTooltip='edit this note'
      deleteTooltip='delete this note'
      deleteMessage={t('messages:confirm-delete-note').toString()}
      onEdit={onEdit}
      onDelete={onDelete}
    >
      <BodyShort>{item?.title}</BodyShort>
      {item.body}
    </Card>
  )
}

export default NoteCard
