import {
  Alert,
  AlignCenterRow,
  BodyShort,
  Button,
  Column,
  Content,
  HorizontalSpace,
  Link,
  PileDiv,
  VerticalSpace
} from '@cegal/ds-components'
import { clearAlertBody } from 'actions'
import CatSitting from 'assets/images/catSitting.png'
import FullCat from 'assets/images/fullCat.png'
import { APP_SIGNUP_NEW_SUCCESS } from 'constants/actionTypes'
import { eventLogger } from 'metrics/loggers'
import { AlertsState } from 'reducers/alerts'
import { useAppDispatch, useAppSelector } from 'store'
import styled from 'styled-components'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useNavigate } from 'react-router-dom'

import { IS_DEVELOPMENT } from 'utils/environment'

import _ from 'lodash'

const CustomColumn = styled(Column)`
  width: 40vh;
  align-content: center;
  margin-bottom: 1rem;
  padding: 0;
  border: 1px solid var(--cds-text-color);
  border-radius: var(--cds-border-radius-medium);
  background-color: var(--cds-background-color-inverted-light-opaque);
  &:hover {
    background-color: var(--cds-background-color-inverted-medium-opaque);
  }
`

const Login = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const apiReady = useAppSelector((state) => state.app.apiReady)

  const onLogin = (e: any) => {
    eventLogger(e)
    if (IS_DEVELOPMENT) {
      navigate('/home')
      return
    }
    window.location.href = '/oauth/login'
  }

  const onSignUp = (e: any) => {
    eventLogger(e)
    navigate('/signup')
  }

  useEffect(() => {
    if (apiReady) {
      navigate('/home')
    }
  }, [])

  const alertBody = useAppSelector((state) => state.alerts as AlertsState).body

  const successAlert = _.find(alertBody, { type: APP_SIGNUP_NEW_SUCCESS })

  return (
    <Content style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
      <PileDiv>
        {successAlert && (
          <>
            <Alert onClose={() => dispatch(clearAlertBody([APP_SIGNUP_NEW_SUCCESS]))} variant='success'>
              {successAlert.message}
            </Alert>
            <VerticalSpace size='2' />
          </>
        )}
        <AlignCenterRow>
          <CustomColumn>
            <VerticalSpace />
            <div>
              <img alt='logo' style={{ height: '200px' }} src={CatSitting} />
            </div>
            <BodyShort>{t('messages:sign-in')}</BodyShort>
            <VerticalSpace />
            <div>
              <Button data-amplitude='login' onClick={onLogin}>
                {t('buttons:login')}
              </Button>
            </div>
            <VerticalSpace />
          </CustomColumn>
          <HorizontalSpace />
          <CustomColumn>
            <VerticalSpace />
            <div>
              <img alt='logo' style={{ height: '200px' }} src={FullCat} />
            </div>
            <BodyShort>{t('messages:sign-up')}</BodyShort>
            <VerticalSpace />
            <div>
              <Button variant='secondary' data-amplitude='signup' onClick={onSignUp}>
                {t('buttons:sign-up')}
              </Button>
            </div>
            <VerticalSpace />
          </CustomColumn>
        </AlignCenterRow>
        <VerticalSpace />
        <AlignCenterRow style={{ justifyContent: 'center' }}>
          <Link as={NavLink} variant='primary' to='/add-to-cetegra'>
            {t('messages:how-to-add-to-cetegra')}
          </Link>
        </AlignCenterRow>
      </PileDiv>
    </Content>
  )
}

export default Login
