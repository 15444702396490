import styled from 'styled-components';
const HorizontalSpace = styled.div `
  display: inline-block;
  margin-left: ${(props) => props.size || 1}rem;
`;
HorizontalSpace.displayName = 'HorizontalSpace';
const VerticalSpace = styled.div `
  margin-bottom: ${(props) => props.size || 1}rem;
`;
VerticalSpace.displayName = 'VerticalSpace';
export { HorizontalSpace, VerticalSpace };
