var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from 'react';
import cl from 'clsx';
import { BodyShort, Detail, Label, ErrorMessage } from 'components/typography';
import { omit } from 'util/index';
import { useFormField } from './useFormField';
export const TextField = forwardRef((props, ref) => {
    const { inputProps, errorId, showErrorMsg, hasError, size, inputDescriptionId } = useFormField(props, 'textField');
    const { label, className, description, htmlSize, hideLabel = false, type = 'text' } = props, rest = __rest(props, ["label", "className", "description", "htmlSize", "hideLabel", "type"]);
    return (React.createElement("div", { className: cl('cds-form-field', `cds-form-field--${size}`, {
            'cds-text-field--error': hasError,
            'cds-text-field--disabled': !!inputProps.disabled,
            'cds-form-field--disabled': !!inputProps.disabled
        }, className) },
        React.createElement(Label, { htmlFor: inputProps.id, size: size, as: 'label', className: cl('cds-form-field__label', {
                'cds-sr-only': hideLabel
            }) }, label),
        !!description && (React.createElement(React.Fragment, null, size === 'medium' ? (React.createElement(BodyShort, { className: cl('cds-form-field__description', {
                'cds-sr-only': hideLabel
            }), id: inputDescriptionId, size: 'small', as: 'div' }, description)) : (React.createElement(Detail, { className: cl('cds-form-field__description', {
                'cds-sr-only': hideLabel
            }), id: inputDescriptionId, size: 'small', as: 'div' }, description)))),
        React.createElement("input", Object.assign({}, omit(rest, ['error', 'errorId', 'size']), inputProps, { ref: ref, type: type, className: cl('cds-text-field__input', 'cds-body-short', `cds-body-${size !== null && size !== void 0 ? size : 'medium'}`), size: htmlSize })),
        React.createElement("div", { className: 'cds-form-field__error', id: errorId, "aria-relevant": 'additions removals', "aria-live": 'polite' }, showErrorMsg && React.createElement(ErrorMessage, { size: size }, props.error))));
});
TextField.displayName = 'TextField';
export default TextField;
