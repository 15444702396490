import {
  BodyShort,
  Column,
  FlexCenterDiv,
  FlexDiv,
  HorizontalSpace,
  Link,
  Row,
  VerticalSpace
} from '@cegal/ds-components'
import { Engagement, EngagementVacancy, File, SimpleClient } from 'declarations/models'

import { ConsultantSignature } from 'components/Consultants/Consultant'
import { DataAlign, DescriptionList, DescriptionListItem } from 'components/DescriptionList/DescriptionList'
import EngagementVacancyCard from 'components/Engagements/Engagement/Vacancy/EngagementVacancyCard'
import Section from 'components/Forms/Section'
import { DataTag } from 'components/index'
import { HomeElementPanel } from 'components/styled'

import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as NavLink } from 'react-router-dom'

import { toLocalDateFormat } from 'utils'

import _, { isUndefined } from 'lodash'

export interface EngagementDetailProps {
  engagement?: Engagement | null
  showVacancies?: boolean
}

const EngagementDetail = ({ engagement, showVacancies = true }: EngagementDetailProps) => {
  const { t } = useTranslation()
  const cardRef = useRef(null)

  if (!engagement) {
    return null
  }

  const rates: Array<number> = engagement.vacancies
    ?.map((vacancy: EngagementVacancy) => {
      return vacancy.hourly_rate
    })
    ?.flat()

  const sumOfRate = rates?.reduce((previousValue, currentValue) => previousValue + currentValue, 0) ?? 0

  const averageRate = Math.round(sumOfRate / rates?.length)

  const convertFiles = !isUndefined(engagement?.file) && JSON.parse(engagement?.file)

  return (
    <HomeElementPanel data-testid='EngagementDetail' border background ref={cardRef}>
      <Section heading={engagement?.title} headingSize='small'>
        <Row>
          <Column>
            <DescriptionList $alignment={DataAlign.Column}>
              <DescriptionListItem
                data-testid='EngagementDetail-ClientName'
                title={t('label:clients-title')}
                details={engagement?.clients
                  ?.map((c: SimpleClient) => (
                    <Link key={c.name} as={NavLink} to={'/clients/' + c.id}>
                      {c.name}
                    </Link>
                  ))
                  .reduce(
                    (prev, curr) => (_.isEmpty(prev) ? [curr] : [...prev, <span key='span'>, </span>, curr]),
                    [] as Array<React.ReactNode>
                  )}
              />
              <DescriptionListItem title={t('label:status-title')} details={engagement?.status} />
            </DescriptionList>
          </Column>
          <Column>
            <DescriptionList $alignment={DataAlign.Column}>
              <DescriptionListItem
                data-testid='EngagementDetail-SourceName'
                title={t('label:source-title')}
                details={engagement?.source?.name}
              />
              <DescriptionListItem title={t('label:location-title')} details={engagement?.location} />
            </DescriptionList>
          </Column>
        </Row>
      </Section>

      <Section heading={t('label:dates-title')!}>
        <Row>
          <Column>
            <DescriptionList $alignment={DataAlign.Column}>
              <DescriptionListItem
                title={t('label:start-date-title')}
                details={toLocalDateFormat(engagement?.start_date)}
              />
              <DescriptionListItem
                title={t('label:optional-extension-period-title')}
                details={engagement?.optional_extension_period}
              />
            </DescriptionList>
          </Column>

          <Column>
            <DescriptionList $alignment={DataAlign.Column}>
              <DescriptionListItem
                title={t('label:end-date-title')}
                details={toLocalDateFormat(engagement?.end_date)}
              />
              <DescriptionListItem
                title={t('label:deadline-title')}
                details={toLocalDateFormat(engagement?.deadline)}
              />
            </DescriptionList>
          </Column>
        </Row>
      </Section>

      <Section heading={t('label:notes')!}>{engagement?.notes}</Section>

      {!_.isEmpty(convertFiles) ? (
        <Section heading={convertFiles.length === 1 ? t('label:attach-file') : t('label:attach-files')}>
          {convertFiles.map((file: File, index: number) => (
            <FlexDiv key={file.name} className='transition' style={{ maxWidth: '500px' }}>
              <BodyShort size='small' className={file.name.length > 50 ? 'text' : ''}>
                {index + 1}- {file.name}
              </BodyShort>
            </FlexDiv>
          ))}
        </Section>
      ) : (
        <Section heading={t('label:attach-file')}>-</Section>
      )}

      <Section heading={t('label:info')!}>
        <DescriptionList $alignment={DataAlign.Column}>
          <DescriptionListItem
            title={t('label:created')}
            details={
              <FlexCenterDiv>
                <span>{'' + toLocalDateFormat(engagement.posted_date)}</span>
                {!_.isEmpty(engagement.creator) && (
                  <>
                    <HorizontalSpace size='0.5' />
                    {t('label:by')}
                    <HorizontalSpace size='0.5' />
                    <ConsultantSignature
                      popover={['view', 'email']}
                      consultant={engagement.creator}
                      context={{ engagement }}
                    />
                  </>
                )}
              </FlexCenterDiv>
            }
          />
          <DescriptionListItem
            title={t('label:last-modified')}
            details={
              <FlexCenterDiv>
                <span>{'' + toLocalDateFormat(engagement.last_modified_date)}</span>
                {!_.isEmpty(engagement.last_modifier) && (
                  <>
                    <HorizontalSpace size='0.5' />
                    {t('label:by')}
                    <HorizontalSpace size='0.5' />
                    <ConsultantSignature
                      popover={['view', 'email']}
                      consultant={engagement.last_modifier}
                      context={{ engagement }}
                    />
                  </>
                )}
              </FlexCenterDiv>
            }
          />
        </DescriptionList>
      </Section>

      <Section heading={t('label:strategic-areas')!} addTrailingSpace={false}>
        <DataTag label={t('label:energy-domain')} isSuccess={engagement?.energy_domain} />
        <DataTag label={t('label:financially-smart')} isSuccess={engagement?.financially_smart} />
        <DataTag label={t('label:integration')} isSuccess={engagement?.integration} />
        <DataTag label={t('label:mentoring')} isSuccess={engagement?.mentoring} />
        <DataTag label={t('label:relevant-technologies')} isSuccess={engagement?.relevant_technologies} />
        <DataTag label={t('label:reusable-database')} isSuccess={engagement?.reusable_database} />
      </Section>
      <VerticalSpace />
      {showVacancies && (
        <Section heading={`${t('header:vacancies')} (${engagement?.vacancies?.length || '-'})`}>
          {_.isNumber(averageRate) && !_.isNaN(averageRate) && (
            <Row>
              <Column>
                <DescriptionListItem
                  $align={DataAlign.Row}
                  title={t('label:average-rate-title')}
                  details={averageRate + ' kr'}
                />
              </Column>
            </Row>
          )}
          <VerticalSpace />
          {engagement?.vacancies?.length > 0
            ? engagement.vacancies?.map((vacancy: EngagementVacancy, index: number) => (
                <EngagementVacancyCard
                  item={{
                    ...vacancy,
                    title: t('label:vacancy') + ' #' + (index + 1) + ': ' + vacancy.title
                  }}
                  key={vacancy.id}
                  spacing
                />
              ))
            : t('messages:engagement-has-no-vacancies')}
        </Section>
      )}
    </HomeElementPanel>
  )
}

export default EngagementDetail
