import { Button, FlexDiv, Heading, HorizontalSpace, VerticalSpace } from '@cegal/ds-components'
import { ChevronLeft } from '@cegal/ds-icons/dist/ChevronLeft'
import {
  deleteEngagementVacancy,
  editEngagement,
  editEngagementVacancy,
  moveCreatedEngagementToCurrent,
  moveSavedEngagementToCurrent,
  newEngagement,
  newEngagementVacancy,
  setFormMode
} from 'actions'
import { FormMode } from 'declarations/app'
import { Engagement, EngagementVacancy } from 'declarations/models'
import { eventLogger, standardLogger } from 'metrics/loggers'
import { useAppDispatch, useAppSelector } from 'store'

import EngagementExpertForm from 'components/Engagements/Engagement/Form/EngagementExpertForm'
import EngagementWizardForm from 'components/Engagements/Engagement/Form/EngagementWizardForm'
import ConfirmModal from 'components/Modal/ConfirmModal'

import { RefObject, useEffect, useRef, useState } from 'react'
import { FormProvider, UseFormReturn, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'

import _ from 'lodash'

export interface EngagementNewProps {
  engagement?: Partial<Engagement>
  editView?: boolean
}

export interface EngagementFormProps extends Partial<UseFormReturn<any>> {
  // form type
  editView: boolean | undefined

  /* formMode */
  toggleFormMode: (formMode: FormMode) => void
  formMode: FormMode

  /* useForm */
  onSubmit: any
  vacancies: Array<EngagementVacancy>

  /* callbacks */
  onEditVacancy: (ev: EngagementVacancy, index: number) => void
  onNewVacancy: (ev: EngagementVacancy) => void
  onRemoveVacancy: (index: number) => void

  /* sub forms */
  thereAreUnsavedVacations: boolean
  setThereAreUnsavedVacations: (value: boolean) => void
  activateConfirmModal: (callback: () => void) => void

  activeStep: number
  setActiveStep: (n: number) => void
  formRef?: RefObject<HTMLDivElement>
}

export interface EngagementStepFormProps extends EngagementFormProps {
  increaseStep?: (e?: any) => void
}

const initialNewEngagementValue: Partial<Engagement> = {
  vacancies: []
}

const EngagementNew: React.FC<EngagementNewProps> = ({
  engagement = initialNewEngagementValue,
  editView
}: EngagementNewProps) => {
  const [searchParams] = useSearchParams()
  const [thereAreUnsavedVacations, setThereAreUnsavedVacations] = useState<boolean>(false)
  const [activeStep, setActiveStep] = useState<number>(1)
  const [confirmModalVisible, setConfirmModalVisible] = useState<boolean>(false)
  const [confirmModalCallback, setConfirmModalCallback] = useState<(() => void) | undefined>(undefined)

  const createdEngagement = useAppSelector((state) => state.engagements.created)
  const savedEngagement = useAppSelector((state) => state.engagements.saved)
  const formRef = useRef<HTMLDivElement>(null)

  const defaultValues = {
    ...engagement,
    clients: !_.isEmpty(searchParams.get('clientId'))
      ? [
          {
            id: parseInt(searchParams.get('clientId')!),
            name: searchParams.get('clientName')
          }
        ]
      : undefined
  }
  const { t } = useTranslation()
  const navigate = useNavigate()
  const forms: any = useForm({
    defaultValues,
    mode: 'onChange'
  })

  const { fields: vacancies } = useFieldArray({
    control: forms.control,
    name: 'vacancies',
    keyName: 'vacancyFieldArrayId' // prevent useFieldArray from overriding id
  })

  const dispatch = useAppDispatch()

  const formMode = useAppSelector((state) => state.app.settings.formMode)

  /**
   * Called when we are submitting a new/existing engagement to backend
   * @param engagement Engagement we want to create/save
   */
  const onSubmit = (engagement: Engagement) => {
    // remove empty properties
    const sanitizedEngagement = _.pickBy(engagement, (value) => {
      if (_.isString(value)) {
        return value.length > 0
      }
      return value
    }) as Engagement

    // if we are dealing with a new engagement, clean up temp_ids for new engagement vacancies,
    // they only exist in draft engagements, to identify temporary vacancies
    if (!sanitizedEngagement.id) {
      if (!_.isEmpty(sanitizedEngagement.vacancies)) {
        sanitizedEngagement.vacancies = sanitizedEngagement.vacancies?.map((v: EngagementVacancy) =>
          _.omit(v, 'temp_id')
        )
      }
      standardLogger('engagements.new.created')
      dispatch(newEngagement(sanitizedEngagement))
    } else {
      standardLogger('engagements.edit.saved')
      dispatch(editEngagement(sanitizedEngagement))
    }
  }

  /**
   * Called when we are adding a new vacancy to a new/existing engagement
   * If we are adding to a draft engagement, vacancy will be added in memory only with a temp id
   * If we are adding to an existing engagement, then we use the endpoints to store it.
   */
  const onNewVacancy = (newVacancy: EngagementVacancy) => {
    const engagement: Engagement = forms.getValues()

    if (editView) {
      dispatch(newEngagementVacancy(newVacancy, engagement.id))
      return
    }

    const newVacancies = engagement.vacancies.concat(newVacancy)
    forms.setValue('vacancies', newVacancies)
  }

  /**
   * Called when we are saving an existing vacancy to an existing engagement
   */
  const onEditVacancy = (editVacancy: EngagementVacancy, index: number) => {
    const engagement: Engagement = forms.getValues()
    const newVacancies = _.cloneDeep(engagement.vacancies) as Array<EngagementVacancy>
    // removing ID added by useFieldArray
    // @ts-ignore
    delete editVacancy.fieldArrayId
    newVacancies[index] = editVacancy

    if (editView) {
      dispatch(editEngagementVacancy(editVacancy, engagement.id))
      return
    }

    forms.setValue('vacancies', newVacancies)
  }

  /**
   * Called when we are deleting a vacancy from a new/existing engagement
   */
  const onRemoveVacancy = (index: number) => {
    const engagement: Engagement = forms.getValues()
    const newVacancies: Array<EngagementVacancy> = _.cloneDeep(engagement.vacancies)

    if (editView) {
      dispatch(deleteEngagementVacancy(newVacancies[index], engagement.id))
      return
    }

    newVacancies.splice(index, 1)
    forms.setValue('vacancies', newVacancies)
  }

  const toggleFormMode = (e: any) => {
    const newMode = formMode === 'wizard' ? 'expert' : 'wizard'
    standardLogger(`${e.target.dataset.amplitude}.${newMode}`)
    dispatch(setFormMode(newMode))
  }

  const handleGoBack = (e: any) => {
    eventLogger(e)
    navigate(-1)
  }

  const activateConfirmModal = (callback: () => void) => {
    setConfirmModalVisible(true)
    // I have to store the callback function wrapped on an anonymous function so that useState does not
    // execute it
    setConfirmModalCallback(() => callback)
  }

  const confirmModalConfirmed = () => {
    setConfirmModalVisible(false)
    confirmModalCallback?.()
    setConfirmModalCallback(undefined)
  }

  const Component = formMode === 'expert' ? EngagementExpertForm : EngagementWizardForm

  /* if engagement changes in store, because we are using backend to save changes, update form values */
  useEffect(() => {
    forms.reset(engagement)
  }, [engagement])

  // decide what to do when it's created
  useEffect(() => {
    if (createdEngagement) {
      navigate('/engagements/' + createdEngagement.id)
      dispatch(moveCreatedEngagementToCurrent())
    }
  }, [createdEngagement])

  useEffect(() => {
    if (savedEngagement) {
      navigate('/engagements/' + savedEngagement.id)
      dispatch(moveSavedEngagementToCurrent())
    }
  }, [savedEngagement])

  return (
    <>
      <ConfirmModal
        open={confirmModalVisible}
        onConfirm={confirmModalConfirmed}
        onCancel={() => setConfirmModalVisible(false)}
        onClose={() => setConfirmModalVisible(false)}
        title={t('messages:confirm-unsaved-vacancy-title')}
        body={t('messages:confirm-unsaved-vacancy-body')}
      />

      <FlexDiv style={{ justifyContent: 'space-between' }}>
        <FlexDiv>
          <Button
            variant='secondary'
            data-amplitude={`engagements.${editView ? 'edit' : 'new'}.goback`}
            onClick={handleGoBack}
            icon={<ChevronLeft size='1.5rem' />}
          >
            {t('buttons:back')}
          </Button>
          <HorizontalSpace />
          <Heading size='large'>{t(editView ? 'header:edit-engagement' : 'header:new-engagement')}</Heading>
        </FlexDiv>
        <Button
          variant='secondary'
          data-amplitude={`engagements.${editView ? 'edit' : 'new'}.mode`}
          onClick={toggleFormMode}
        >
          {t('buttons:switch-to-x-mode', {
            mode: t('label:' + (formMode === 'wizard' ? 'expert' : 'wizard')).toLowerCase()
          })}
        </Button>
      </FlexDiv>
      <VerticalSpace size='3' />
      <FormProvider {...forms}>
        <div ref={formRef}>
          <Component
            formRef={formRef}
            formMode={formMode}
            toggleFormMode={toggleFormMode}
            editView={editView}
            vacancies={vacancies as unknown as EngagementVacancy[]}
            onEditVacancy={onEditVacancy}
            onNewVacancy={onNewVacancy}
            onRemoveVacancy={onRemoveVacancy}
            onSubmit={forms.handleSubmit(onSubmit)}
            thereAreUnsavedVacations={thereAreUnsavedVacations}
            setThereAreUnsavedVacations={setThereAreUnsavedVacations}
            activateConfirmModal={activateConfirmModal}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
          />
        </div>
      </FormProvider>
      <VerticalSpace size='3' />
    </>
  )
}

export default EngagementNew
