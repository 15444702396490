import { SortState } from '@cegal/ds-components'
import { DateTime } from 'luxon'

import _ from 'lodash'

const toLowerCaseString = (value: string | number | undefined | null): string =>
  value?.toString()?.toLowerCase() ?? ''

const comparator = (a: any, b: any, orderBy: string, otherComparator: (a: any, b: any) => number) => {
  if (_.get(b, orderBy) === undefined) {
    return -1
  }
  if (_.get(a, orderBy) === undefined) {
    return 1
  }
  // dates
  if (['start_date', 'end_date', 'deadline', 'posted_date', 'last_modified_date'].indexOf(orderBy) >= 0) {
    const _a = DateTime.fromFormat(_.get(a, orderBy) ?? '1970-01-01', 'yyyy-MM-dd')
    const _b = DateTime.fromFormat(_.get(b, orderBy) ?? '1970-01-01', 'yyyy-MM-dd')
    if (_a < _b) return -1
    if (_a > _b) return 1
    return otherComparator(a, b)
  }
  // numbers
  if (['allocation', 'optional_extension_period', 'average_rate'].indexOf(orderBy) >= 0) {
    return (_.get(b, orderBy) || 0) - (_.get(a, orderBy) || 0)
  }
  // strings
  const value = toLowerCaseString(_.get(b, orderBy)).localeCompare(toLowerCaseString(_.get(a, orderBy)))

  if (value === 0) {
    return otherComparator(a, b)
  }
  return value
}

export const tableSort = (
  sort: SortState,
  a: any,
  b: any,
  key: string,
  otherComparator: (a: any, b: any) => number
) => {
  return sort.direction === 'ascending'
    ? comparator(_.get(b, key), _.get(a, key), sort.orderBy, otherComparator)
    : comparator(_.get(a, key), _.get(b, key), sort.orderBy, otherComparator)
}

export const getNextSort = (sort: SortState | undefined, sortKey: string | undefined) => {
  const newSort: SortState | undefined =
    sort && sortKey === sort.orderBy && sort.direction === 'descending'
      ? undefined
      : ({
          orderBy: sortKey,
          direction:
            sort && sortKey === sort.orderBy && sort.direction === 'ascending' ? 'descending' : 'ascending'
        } as SortState)
  return newSort
}
