import { Avatar, Link, Table } from '@cegal/ds-components'
import { Client, ClientsQuery, ConsultantsQuery } from 'declarations/models'
import { standardLogger } from 'metrics/loggers'

import { CardSize } from 'components/Card/Card'
import { RowProps } from 'components/Row/Row'
import { ButtonWrapper } from 'components/styled'

import Highlighter from 'react-highlight-words'
import { NavLink, generatePath } from 'react-router-dom'

import useClientAvatar from 'hooks/useClientAvatar'
import useQuery from 'hooks/useQuery'

import { tokenize } from 'utils/tokenizer'

import _ from 'lodash'

export type ClientProps = RowProps<Client, ClientsQuery> & {
  onView?: (client: Client) => void
  size?: CardSize
}

const ClientRow = ({ item, size = 'small', onView, buttons }: ClientProps) => {
  const { avatar, avatarLoading, ref } = useClientAvatar({ id: item.id })
  const { query } = useQuery<Partial<ConsultantsQuery>>({})
  const searchWords = tokenize(query.search)

  return (
    <Table.Row data-testid='ClientRow'>
      <Table.DataCell>
        <Avatar
          data-testid='ClientCard-Avatar'
          variant='square-round'
          size={size === 'small' ? 'medium' : 'small'}
          src={avatar}
          loading={avatarLoading}
          ref={ref}
        />
      </Table.DataCell>
      <Table.DataCell>
        <Link
          onClick={(e: any) => {
            standardLogger('client.row.view')
            onView?.(item)
          }}
          style={{ cursor: 'pointer' }}
        >
          <Highlighter caseSensitive={false} searchWords={searchWords} textToHighlight={item?.name ?? ''} />
        </Link>
      </Table.DataCell>
      <Table.DataCell>
        {_.isEmpty(item.engagements)
          ? 'No engagement'
          : item.engagements?.map((item: any) => (
              <Link
                style={{ marginRight: '0.6rem' }}
                as={NavLink}
                to={generatePath('/engagements/:id', {
                  id: item.id.toString()
                })}
                key={item.id}
              >
                <Highlighter
                  caseSensitive={false}
                  searchWords={searchWords}
                  textToHighlight={item?.title ?? ''}
                />
              </Link>
            ))}
      </Table.DataCell>{' '}
      <Table.DataCell>{item.location}</Table.DataCell>
      <Table.DataCell>
        <ButtonWrapper>{buttons}</ButtonWrapper>
      </Table.DataCell>
    </Table.Row>
  )
}

export default ClientRow
