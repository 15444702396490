import { BodyShort, Tabs } from '@cegal/ds-components'
import { Client, EngagementVacancyConsultant } from 'declarations/models'

import EngagementVacancyConsultantCard from 'components/Engagements/Engagement/Vacancy/Consultant/EngagementVacancyConsultantCard'
import { CardContainer, TabPanel } from 'components/styled'

import { useMemo } from 'react'

import { getConsultantsFromClient } from 'hooks/useClientFilterUtils'

import _ from 'lodash'

interface ClientConsultantsViewProps {
  client: Client
}

const ClientConsultantsView = ({ client }: ClientConsultantsViewProps) => {
  const consultantsList: Array<EngagementVacancyConsultant> = useMemo(
    () => getConsultantsFromClient(client),
    [client]
  )

  const groupedData: { [k in string]: Array<EngagementVacancyConsultant> } = useMemo(
    () => _.groupBy(consultantsList, (c: EngagementVacancyConsultant) => c.status),
    [consultantsList]
  )

  return (
    <Tabs defaultValue='all_consultants'>
      <Tabs.List loop={false}>
        <Tabs.Tab value='all_consultants' label={`All consultants (${consultantsList?.length ?? 0})`} />
        <Tabs.Tab value='matched' label={`Matched (${groupedData.matched?.length ?? 0})`} />
        <Tabs.Tab value='active' label={`Active (${groupedData.active?.length ?? 0})`} />
      </Tabs.List>

      <TabPanel noPadding value='all_consultants'>
        <div style={{ padding: '2rem 0rem' }}>
          {_.isEmpty(consultantsList) ? (
            <BodyShort>There is no consultants</BodyShort>
          ) : (
            <CardContainer>
              {consultantsList?.map((item: any) => (
                <EngagementVacancyConsultantCard spacing key={item.id} item={item} />
              ))}
            </CardContainer>
          )}
        </div>
      </TabPanel>

      <TabPanel noPadding value='ready_to_start'>
        <div style={{ padding: '2rem 0rem' }}>
          {_.isEmpty(groupedData.matched) ? (
            <BodyShort>There is no matched consultants</BodyShort>
          ) : (
            <CardContainer>
              {groupedData.matched?.map((item: any) => (
                <EngagementVacancyConsultantCard spacing key={item.id} item={item} />
              ))}
            </CardContainer>
          )}
        </div>
      </TabPanel>

      <TabPanel noPadding value='ready_to_start'>
        <div style={{ padding: '2rem 0rem' }}>
          {_.isEmpty(groupedData.active) ? (
            <BodyShort>There is no active consultants</BodyShort>
          ) : (
            <CardContainer>
              {groupedData.active?.map((item: any) => (
                <EngagementVacancyConsultantCard spacing key={item.id} item={item} />
              ))}
            </CardContainer>
          )}
        </div>
      </TabPanel>
    </Tabs>
  )
}

export default ClientConsultantsView
