import {
  Button,
  Dropdown,
  FlexCenterDiv,
  FlexCenterSpacedDiv,
  Heading,
  HorizontalSpace,
  Search,
  Tabs,
  VerticalSpace
} from '@cegal/ds-components'
import {
  AutoFixHigh,
  Chat,
  Delete,
  InsertDriveFile,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Launch,
  MoreVert,
  Search as SearchIcon
} from '@cegal/ds-icons'
import { setAiTab } from 'actions'
import { AiTab } from 'declarations/app'
import { useAppDispatch, useAppSelector } from 'store'
import styled, { css } from 'styled-components'

import { PropsWithChildren, useEffect, useRef, useState } from 'react'

import { useAiMessages } from 'hooks/useAiMessages'

import { t } from 'i18next'

import { SHOW_AI_DRAWER } from '../../config'
import AiChat from './AiTabChat/AiChat'
import AiFiles from './AiTabFiles/AiFiles'
import AiSummarize from './AiTabSummarize/AiSummarize'

export type AiDrawerProps = PropsWithChildren<{
  open?: boolean
  width?: number | string
  onClick?: any
  onClose?: any
  showOpenButton?: boolean
}>

const StyledSearch = styled(Search)`
  .cds-search__wrapper input {
    padding-left: 2.4rem;
  }
  .cds-search__button-clear {
    width: 1.2rem;
    height: 1.2rem;
  }
`

const SearchWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const StyledContainer = styled.div`
  position: fixed;
  top: var(--cat-header-height);
  right: 0;
  height: calc(100vh - var(--cat-header-height));
  z-index: 1000;
`
const DrawerWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['open', 'width'].includes(prop as string)
})<{ width?: number | string; open?: boolean }>`
  background-color: var(--cat-drawer-background-color);
  height: 100%;
  overflow: visible;
  position: relative;
  transition: all 0.3s;
  width: ${({ width }) => width};
  ${({ width, open }) =>
    !open &&
    css`
      margin-right: -${width};
    `};
`

const DrawerContent = styled.div`
  height: 100%;
  width: 100%;
  padding: 1rem;
`

const AiDrawer = ({ onClose, open = false, width = '30rem' }: AiDrawerProps) => {
  const dispatch = useAppDispatch()
  const searchRef = useRef<HTMLDivElement>(null)
  const [showSearch, setShowSearch] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [messages, setMessages, deleteHistory] = useAiMessages('2')
  const aiTab = useAppSelector((state) => state.app.settings.aiTab)

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (searchValue === '' && searchRef.current && !searchRef.current.contains(event.target)) {
        setShowSearch(false)
        setSearchValue('')
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [searchRef, searchValue])

  const handleSearch = (e: any) => {
    setSearchValue(e)
  }

  const handleTabChange = (tab: AiTab) => {
    dispatch(setAiTab(tab))
    setSearchValue('')
    setShowSearch(false)
  }

  if (!SHOW_AI_DRAWER) return

  return (
    <div style={{ position: 'relative' }}>
      <StyledContainer>
        <DrawerWrapper open={open} width={width}>
          <DrawerContent>
            <FlexCenterSpacedDiv>
              {showSearch ? (
                <SearchWrapper ref={searchRef}>
                  <StyledSearch
                    autoFocus
                    label='search'
                    hideLabel
                    variant='simple'
                    placeholder={aiTab === 'chat' ? 'Search in conversation' : 'Filter by name'}
                    value={searchValue}
                    onChange={handleSearch}
                    onClear={() => setSearchValue('')}
                  />
                  {aiTab === 'chat' && (
                    <>
                      <KeyboardArrowUp size='2.5rem' />
                      <KeyboardArrowDown size='2.5rem' />
                    </>
                  )}
                  <Button
                    style={{ marginLeft: 'auto' }}
                    variant='secondary'
                    size='medium'
                    onClick={() => {
                      setShowSearch(false)
                      setSearchValue('')
                    }}
                  >
                    {t('buttons:cancel')}
                  </Button>
                </SearchWrapper>
              ) : (
                <>
                  <Heading>{t('header:ai-header')}</Heading>
                  <FlexCenterDiv>
                    {aiTab !== 'summarize' && (
                      <>
                        <Button
                          variant='tertiary'
                          size='medium'
                          onClick={() => setShowSearch(true)}
                          icon={<SearchIcon size='1.5rem' />}
                        />
                        <HorizontalSpace size='0.5' />
                      </>
                    )}

                    <Dropdown closeOnSelect>
                      <Button
                        as={Dropdown.Toggle}
                        variant='tertiary'
                        size='medium'
                        icon={<MoreVert size='1.5rem' />}
                      />
                      <Dropdown.Menu>
                        <Dropdown.Menu.List>
                          <Dropdown.Menu.List.Item onClick={deleteHistory}>
                            <Delete size='1.5rem' />
                            {t('buttons:clear-chat-history')}
                          </Dropdown.Menu.List.Item>
                          <Dropdown.Menu.List.Item>
                            <Launch size='1.5rem' /> Help & FAQ
                          </Dropdown.Menu.List.Item>
                        </Dropdown.Menu.List>
                      </Dropdown.Menu>
                    </Dropdown>

                    <HorizontalSpace size='0.5' />
                    <Button variant='secondary' size='medium' onClick={onClose}>
                      {t('buttons:close')}
                    </Button>
                  </FlexCenterDiv>
                </>
              )}
            </FlexCenterSpacedDiv>
            <Tabs defaultValue={aiTab ?? 'chat'} style={{ height: '100%', padding: '0' }}>
              <Tabs.List>
                <Tabs.Tab
                  value='chat'
                  label={t('label:chat')}
                  iconPosition='left'
                  icon={<Chat size='1.2rem' />}
                  onClick={() => handleTabChange('chat')}
                />
                <Tabs.Tab
                  value='summarize'
                  label='Summarize'
                  iconPosition='left'
                  icon={<AutoFixHigh size='1.2rem' />}
                  onClick={() => handleTabChange('summarize')}
                />
                <Tabs.Tab
                  value='files'
                  label='Files'
                  iconPosition='left'
                  icon={<InsertDriveFile size='1.2rem' />}
                  onClick={() => handleTabChange('files')}
                />
              </Tabs.List>
              <Tabs.Panel value='chat' noPadding style={{ height: 'calc(100% - 7rem)' }}>
                <AiChat searchValue={searchValue} messages={messages} setMessages={setMessages} />
              </Tabs.Panel>
              <Tabs.Panel value='summarize' noPadding style={{ height: 'calc(100% - 7rem)' }}>
                <AiSummarize />
              </Tabs.Panel>
              <Tabs.Panel value='files' noPadding style={{ height: 'calc(100% - 7rem)' }}>
                <AiFiles files={[]} searchValue={searchValue} />
              </Tabs.Panel>
            </Tabs>
            <VerticalSpace size='1' />
          </DrawerContent>
        </DrawerWrapper>
      </StyledContainer>
    </div>
  )
}

export default AiDrawer
