import { BodyLong, Heading, PileCenterDiv, VerticalSpace } from '@cegal/ds-components'
import { listConsultants } from 'actions'
import { CONSULTANTS_LIST_FAILURE } from 'constants/actionTypes'
import { useAppDispatch, useAppSelector } from 'store'

import { FullScreenLoader } from 'components'
import AlertPanel from 'components/AlertPanel/AlertPanel'
import { ConsultantsView } from 'components/Consultants'
import PageContent from 'components/PageContent/PageContent'
import { Hr } from 'components/styled'

import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'

const About = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const consultants = useAppSelector((state) => state.consultants.list)
  const apiReady = useAppSelector((state) => state.app.apiReady)
  const consultantsLoading = useAppSelector((state) => state.consultants.listing)

  const backend: Array<string> = [
    'agnija.bako@cegal.com',
    'ganesh.gurudu@cegal.com',
    'jagyaseni.das@cegal.com',
    'johanne.holst.klaeboe@cegal.com',
    'ioan-vlad.dragomir@cegal.com',
    'magnus.andreas.holmen@cegal.com',
    'martin.thomas.candor@cegal.com',
    'melanie.ostboll@cegal.com',
    'nikola.jovanovic@cegal.com',
    'ole.jorgen.hongset@cegal.com',
    'rohan.kumar@cegal.com',
    'sergii.prodanov@cegal.com',
    'vijay.jadhav@cegal.com'
  ]

  const frontend: Array<string> = [
    'daniel.silva@cegal.com',
    'faisal.ahmed@cegal.com',
    'havard.johannessen@cegal.com',
    'nuno.cardoso@cegal.com',
    'oscar.rainergren@cegal.com',
    'sarah.e.rayfuse@cegal.com',
    'soroush.ghodrat@cegal.com',
    'vitalijus.zudenkovas@cegal.com'
  ]

  const design: Array<string> = ['sarah.e.rayfuse@cegal.com']

  const qa: Array<string> = ['tai.ngo@cegal.com']

  const teamlead: Array<string> = ['nuno.cardoso@cegal.com', 'prakhar.srivastav@cegal.com']

  const product: Array<string> = ['francisco.arturo.viveros@cegal.com', 'lisa.benedikte.greenquist@cegal.com']

  const former: Array<string> = ['Paul Farstad', 'Sindre Fladvad', 'Dalibor Blazevic', 'Christopher Laurente']

  useEffect(() => {
    if (consultants === undefined && apiReady && !consultantsLoading) {
      loadPageData()
    }
  }, [apiReady, consultants, consultantsLoading])

  const loadPageData = () => dispatch(listConsultants())

  const _consultants: any = useMemo(
    () => ({
      backend: consultants?.filter((c) => backend.includes(c.email)),
      frontend: consultants?.filter((c) => frontend.includes(c.email)),
      design: consultants?.filter((c) => design.includes(c.email)),
      qa: consultants?.filter((c) => qa.includes(c.email)),
      teamlead: consultants?.filter((c) => teamlead.includes(c.email)),
      product: consultants?.filter((c) => product.includes(c.email))
    }),
    [consultants]
  )

  return (
    <PageContent maxWidth='1200px'>
      <VerticalSpace />
      <Heading level='2' size='large'>
        {t('header:about-us')}
      </Heading>
      <VerticalSpace />
      <PileCenterDiv>
        <BodyLong>{t('body:about-us')}</BodyLong>
      </PileCenterDiv>
      <VerticalSpace size='2' />
      {consultantsLoading && <FullScreenLoader />}
      {consultants === null && (
        <AlertPanel
          doNotRenderIfHeadIsActiveWithError
          watchFor={[CONSULTANTS_LIST_FAILURE]}
          buttonText={t('buttons:click-to-reload-the-page')!}
          reloadPage={loadPageData}
        />
      )}
      <Heading level='2' size='large'>
        {t('header:staff')}
      </Heading>
      <VerticalSpace />
      {!_.isNil(consultants) && (
        <>
          <Heading level='2' size='large'>
            {t('header:backend-team')}
          </Heading>
          <Hr style={{ width: '70%', marginLeft: '0' }} />
          <VerticalSpace size='2' />
          <ConsultantsView viewMode='card' consultants={_consultants.backend} />
          <VerticalSpace size='2' />
          <Heading level='2' size='large'>
            {t('header:frontend-team')}
          </Heading>
          <Hr style={{ width: '70%', marginLeft: '0' }} />
          <VerticalSpace size='2' />
          <ConsultantsView viewMode='card' consultants={_consultants.frontend} />
          <VerticalSpace size='2' />
          <Heading level='2' size='large'>
            {t('header:design-team')}
          </Heading>
          <Hr style={{ width: '70%', marginLeft: '0' }} />
          <VerticalSpace size='2' />
          <ConsultantsView viewMode='card' consultants={_consultants.design} />
          <VerticalSpace size='2' />
          <Heading level='2' size='large'>
            {t('header:qa-tester')}
          </Heading>
          <Hr style={{ width: '70%', marginLeft: '0' }} />
          <VerticalSpace size='2' />
          <ConsultantsView viewMode='card' consultants={_consultants.qa} />
          <VerticalSpace size='2' />
          <Heading level='2' size='large'>
            {t('header:teamlead')}
          </Heading>
          <Hr style={{ width: '70%', marginLeft: '0' }} />
          <VerticalSpace size='2' />
          <ConsultantsView viewMode='card' consultants={_consultants.teamlead} />
          <VerticalSpace size='2' />
          <Heading level='2' size='large'>
            {t('header:product-owner')}
          </Heading>
          <Hr style={{ width: '70%', marginLeft: '0' }} />
          <VerticalSpace size='2' />
          <ConsultantsView viewMode='card' consultants={_consultants.product} />
          <VerticalSpace size='2' />
          <Heading level='2' size='large'>
            {t('header:former-colleagues')}
          </Heading>
          <VerticalSpace size='2' />
          <ul>
            {former.map((f) => (
              <li key={f}>{f}</li>
            ))}
          </ul>
        </>
      )}
    </PageContent>
  )
}

export default About
