var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { debounce, mergeRefs, useClientLayoutEffect } from './';
const ownerWindow = (node) => {
    const doc = (node && node.ownerDocument) || document;
    return doc.defaultView || window;
};
function getStyleValue(computedStyle, property) {
    return parseInt(computedStyle[property], 10) || 0;
}
const TextareaAutosize = forwardRef((_a, ref) => {
    var { className, onChange, maxRows, minRows = 1, style, value } = _a, other = __rest(_a, ["className", "onChange", "maxRows", "minRows", "style", "value"]);
    const { current: isControlled } = useRef(value != null);
    const inputRef = useRef(null);
    const handleRef = useMemo(() => mergeRefs([inputRef, ref]), [ref]);
    const shadowRef = useRef(null);
    const renders = useRef(0);
    const [state, setState] = useState({});
    const syncHeight = useCallback(() => {
        if (!inputRef.current || !shadowRef.current)
            return;
        const input = inputRef.current;
        const containerWindow = ownerWindow(input);
        const computedStyle = containerWindow.getComputedStyle(input);
        if (computedStyle.width === '0px') {
            return;
        }
        const inputShallow = shadowRef.current;
        inputShallow.style.width = computedStyle.width;
        inputShallow.value = input.value || (other === null || other === void 0 ? void 0 : other.placeholder) || 'x';
        if (inputShallow.value.slice(-1) === '\n') {
            inputShallow.value += ' ';
        }
        const boxSizing = computedStyle['box-sizing'];
        const padding = getStyleValue(computedStyle, 'padding-bottom') + getStyleValue(computedStyle, 'padding-top');
        const border = getStyleValue(computedStyle, 'border-bottom-width') + getStyleValue(computedStyle, 'border-top-width');
        const innerHeight = inputShallow.scrollHeight - padding;
        inputShallow.value = 'x';
        const singleRowHeight = inputShallow.scrollHeight - padding;
        let outerHeight = innerHeight;
        if (minRows) {
            outerHeight = Math.max(Number(minRows) * singleRowHeight, outerHeight);
        }
        if (maxRows) {
            outerHeight = Math.min(Number(maxRows) * singleRowHeight, outerHeight);
        }
        outerHeight = Math.max(outerHeight, singleRowHeight);
        const outerHeightStyle = outerHeight + (boxSizing === 'border-box' ? padding + border : 0);
        const overflow = Math.abs(outerHeight - innerHeight) <= 1;
        setState((prevState) => {
            if (renders.current < 20 &&
                ((outerHeightStyle > 0 && Math.abs((prevState.outerHeightStyle || 0) - outerHeightStyle) > 1) ||
                    prevState.overflow !== overflow)) {
                renders.current += 1;
                return {
                    overflow,
                    outerHeightStyle
                };
            }
            if (process.env.NODE_ENV !== 'production') {
                if (renders.current === 20) {
                    console.error([
                        'MUI: Too many re-renders. The layout is unstable.',
                        'TextareaAutosize limits the number of renders to prevent an infinite loop.'
                    ].join('\n'));
                }
            }
            return prevState;
        });
    }, [maxRows, minRows, other === null || other === void 0 ? void 0 : other.placeholder]);
    useEffect(() => {
        const handleResize = debounce(() => {
            renders.current = 0;
            syncHeight();
        });
        const containerWindow = ownerWindow(inputRef.current);
        containerWindow.addEventListener('resize', handleResize);
        let resizeObserver;
        if (typeof ResizeObserver !== 'undefined') {
            resizeObserver = new ResizeObserver(handleResize);
            resizeObserver.observe(inputRef.current);
        }
        return () => {
            handleResize.clear();
            containerWindow.removeEventListener('resize', handleResize);
            if (resizeObserver) {
                resizeObserver.disconnect();
            }
        };
    }, [syncHeight]);
    useClientLayoutEffect(() => {
        syncHeight();
    });
    useEffect(() => {
        renders.current = 0;
    }, [value]);
    const handleChange = (event) => {
        renders.current = 0;
        if (!isControlled) {
            syncHeight();
        }
        if (onChange) {
            onChange(event);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("textarea", Object.assign({ value: value, onChange: handleChange, ref: handleRef, rows: minRows, style: Object.assign(Object.assign({ height: state.outerHeightStyle }, (state.overflow ? { overflow: 'hidden' } : {})), style) }, other, { className: className })),
        React.createElement("textarea", { "aria-hidden": true, className: className, readOnly: true, ref: shadowRef, tabIndex: -1, style: Object.assign({ visibility: 'hidden', position: 'absolute', overflow: 'hidden', height: 0, top: 0, left: 0, transform: 'translateZ(0)' }, style) })));
});
export default TextareaAutosize;
