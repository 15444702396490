var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import cl from 'clsx';
import { useFormField } from 'components/form/useFormField';
import { Loader } from 'components/loader/Loader';
import { BodyLong, Detail, ErrorMessage, Label } from 'components/typography';
import _ from 'lodash';
import React, { forwardRef, useMemo, useRef } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Option from './Option';
import ValueLabel from './ValueLabel';
import ValueRemove from './ValueRemove';
const SelectMultipleInner = (props, ref) => {
    const { inputProps, errorId, showErrorMsg, hasError, size, inputDescriptionId } = useFormField(props, 'textField');
    const { creatable = false, clearable = true, loading = false, fullWidth = false, label, disabled, className, description, hideLabel = false, searchable = true, multiple = true, menuPortalTarget, onChange, components = {}, style, options, value, selectedToTop = false } = props, rest = __rest(props, ["creatable", "clearable", "loading", "fullWidth", "label", "disabled", "className", "description", "hideLabel", "searchable", "multiple", "menuPortalTarget", "onChange", "components", "style", "options", "value", "selectedToTop"]);
    const customOptions = useMemo(() => {
        if (selectedToTop === false) {
            return options;
        }
        else {
            const selectedValues = Array.isArray(value) ? value.map((v) => v.value) : [value === null || value === void 0 ? void 0 : value.value];
            const selectedOptions = options
                .filter((option) => selectedValues.includes(option.value))
                .sort((a, b) => a.label.localeCompare(b.label));
            const unselectedOptions = options.filter((option) => !selectedValues.includes(option.value));
            return [...selectedOptions, ...unselectedOptions];
        }
    }, [options, value, selectedToTop]);
    const Component = creatable ? CreatableSelect : Select;
    const onSelectChange = (e) => {
        if (_.isFunction(onChange)) {
            onChange(e);
        }
    };
    const divRef = useRef(null);
    return (React.createElement("div", { ref: divRef, className: cl('cds-form-field', `cds-form-field--${size}`, {
            'cds-form-field--disabled': !!inputProps.disabled,
            'cds-form-field--fullWidth': fullWidth,
            'cds-select--error': hasError
        }, className) },
        React.createElement(Label, { as: 'label', htmlFor: inputProps.id, size: size, className: cl('cds-form-field__label', {
                'cds-sr-only': hideLabel
            }) }, label),
        !!description && (React.createElement(React.Fragment, null, size === 'medium' ? (React.createElement(BodyLong, { className: cl('cds-form-field__description', {
                'cds-sr-only': hideLabel
            }), id: inputDescriptionId, size: 'small', as: 'div' }, description)) : (React.createElement(Detail, { className: cl('cds-form-field__description', {
                'cds-sr-only': hideLabel
            }), id: inputDescriptionId, size: 'small', as: 'div' }, description)))),
        React.createElement("div", { className: 'cds-select__container', style: style },
            React.createElement(Component, Object.assign({ ref: ref }, rest, { options: customOptions, className: cl(`cds-body--${size !== null && size !== void 0 ? size : 'medium'}`), isClearable: clearable, isDisabled: disabled, isMulti: multiple, isLoading: loading, isSearchable: searchable, isSelectedToTop: selectedToTop, menuPortalTarget: menuPortalTarget !== null && menuPortalTarget !== void 0 ? menuPortalTarget : divRef === null || divRef === void 0 ? void 0 : divRef.current, components: Object.assign({ IndicatorSeparator: null, LoadingIndicator: () => React.createElement(Loader, { style: { marginRight: '0.3rem' } }), Option: (optionProps) => React.createElement(Option, Object.assign({ size: size }, optionProps)), MultiValueRemove: (optionProps) => React.createElement(ValueRemove, Object.assign({ size: size }, optionProps)), MultiValueLabel: (optionProps) => React.createElement(ValueLabel, Object.assign({ size: size }, optionProps)) }, components), onChange: onSelectChange, styles: {
                    container: (styles) => (Object.assign(Object.assign({}, styles), { minWidth: size === 'medium' ? '12rem' : '9rem', width: fullWidth ? '100%' : 'auto', minHeight: size === 'medium' ? 'var(--cds-size-12)' : 'var(--cds-size-8)' })),
                    clearIndicator: (styles) => (Object.assign(Object.assign({}, styles), { padding: size === 'medium' ? 'var(--cds-size-2)' : 'var(--cds-size-1)', color: 'var(--cds-select-field-text-color)' })),
                    control: (styles, { isFocused, isSelected }) => (Object.assign(Object.assign({}, styles), { minHeight: size === 'medium' ? 'var(--cds-size-12)' : 'var(--cds-size-8)', borderWidth: isSelected ? '1px' : isFocused ? '3px' : '1px', borderStyle: 'solid', '&:hover': {
                            borderColor: 'var(--cds-border-color-muted)'
                        }, borderColor: isFocused
                            ? 'var(--cds-border-color-alternative)'
                            : hasError
                                ? 'var(--cds-select-color-border-error)'
                                : 'var(--cds-select-color-border)', backgroundColor: 'var(--cds-select-field-background-color)', borderRadius: 'var(--cds-border-radius-medium)', color: 'var(--cds-select-field-text-color)' })),
                    dropdownIndicator: (styles) => (Object.assign(Object.assign({}, styles), { padding: size === 'medium' ? 'var(--cds-size-2)' : 'var(--cds-size-1)', color: 'var(--cds-select-field-text-color)' })),
                    menu: (styles) => (Object.assign(Object.assign({}, styles), { zIndex: 500, width: 'max-content', minWidth: '100%' })),
                    multiValue: (styles) => (Object.assign(Object.assign({}, styles), { borderRadius: 'var(--cds-border-radius-medium)', borderWidth: '1px', borderColor: 'var(--cds-select-color-border)', borderStyle: 'solid', padding: '0rem 0.25rem', backgroundColor: 'var(--cds-select-value-background-color)' })),
                    multiValueLabel: (styles) => (Object.assign(Object.assign({}, styles), { color: 'var(--cds-select-value-text-color)', padding: size === 'medium' ? '3px' : '1px' })),
                    multiValueRemove: (styles) => (Object.assign(Object.assign({}, styles), { padding: '2px' })),
                    menuList: (styles) => (Object.assign(Object.assign({}, styles), { borderWidth: '1px', borderColor: 'var(--cds-select-color-border)', borderStyle: 'solid', backgroundColor: 'var(--cds-select-menu-background-color)' })),
                    menuPortal: (base) => (Object.assign(Object.assign({}, base), { zIndex: 9999 })),
                    option: (styles, { isFocused, isSelected }) => (Object.assign(Object.assign({}, styles), { color: isFocused
                            ? 'var(--cds-select-menu-text-color-hover)'
                            : isSelected
                                ? 'var(--cds-select-menu-text-color-selected)'
                                : 'var(--cds-select-menu-text-color)', backgroundColor: isFocused
                            ? 'var(--cds-select-menu-background-color-hover)'
                            : isSelected
                                ? 'var(--cds-select-menu-background-color-selected)'
                                : 'var(--cds-select-menu-background-color)' })),
                    singleValue: (styles) => (Object.assign(Object.assign({}, styles), { color: 'var(--cds-select-value-text-color)' })),
                    valueContainer: (styles) => (Object.assign(Object.assign({}, styles), { padding: size === 'medium' ? '0.125rem 0.5rem' : '0.125rem 0.125rem' }))
                } }))),
        React.createElement("div", { className: 'cds-form-field__error', id: errorId, "aria-relevant": 'additions removals', "aria-live": 'polite' }, showErrorMsg && React.createElement(ErrorMessage, { size: size }, props.error))));
};
const SelectMultiple = forwardRef(SelectMultipleInner);
export { components as SelectMultipleComponents } from 'react-select';
SelectMultiple.displayName = 'SelectMultiple';
export default SelectMultiple;
