var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Button } from 'components/button';
import React, { forwardRef } from 'react';
import cl from 'clsx';
import { BodyShort } from 'components/typography';
import ChipButton from './ChipButton';
export const Chip = forwardRef((_a, ref) => {
    var { className, selected = false, border = true, size = 'medium', button = false, children, leftElement, rightElement } = _a, rest = __rest(_a, ["className", "selected", "border", "size", "button", "children", "leftElement", "rightElement"]);
    const Component = button ? Button : BodyShort;
    return (React.createElement(Component, Object.assign({}, rest, { ref: ref, className: cl('cds-chip', `cds-chip--${size}`, { 'cds-chip--button': button, 'cds-chip--border': border, 'cds-chip--selected': selected }, className), as: button ? 'button' : 'span', size: size }),
        leftElement && React.createElement("span", { className: 'cds-chip--left-element' }, leftElement),
        React.createElement("span", { className: 'cds-chip--content' }, children),
        rightElement && React.createElement("span", { className: 'cds-chip--right-element' }, rightElement)));
});
Chip.Button = ChipButton;
Chip.displayName = 'Chip';
export default Chip;
