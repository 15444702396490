import { PageKeys } from 'declarations/app'
import { standardLogger } from 'metrics/loggers'

import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import _ from 'lodash'

export type PageValues = {
  seconds?: number
  minutes?: number
}

type CurrentPage = {
  date: number // timestamp when current page was loaded
  page?: PageKeys // current page ID
  seconds?: number // amount of time in current page
  visible?: boolean // visible or not, to avoid event double trigger
}

export type Pages = { [page in PageKeys]?: PageValues }

const Statistics = () => {
  const { pathname } = useLocation()
  const currentPage = useRef<CurrentPage | undefined>(undefined)
  const pageStats = useRef<Pages>({})

  const handleVisibilityChange = () => {
    if (document.visibilityState === 'hidden' && currentPage.current?.visible) {
      pageStats.current = getPageStats()

      currentPage.current = {
        ...currentPage.current,
        visible: false
      }
    } else {
      currentPage.current = {
        date: new Date().getTime(),
        page: currentPage.current?.page,
        seconds: undefined,
        visible: true
      }
    }
  }

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange)
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  window.onbeforeunload = () => {
    console.log('Sending amplitude statistics')
    const newPageStats = getPageStats()
    standardLogger('total.time', newPageStats)
  }

  const getPageStats = () => {
    const now = new Date().getTime()
    const newPageStats = _.cloneDeep(pageStats.current)

    // this page is not the first page we see
    if (currentPage?.current?.page) {
      const diff = now - currentPage.current.date
      const diffSeconds = Math.ceil(diff / 1000)

      // if the page we are leaving has been visited before, let's append the extra time spent in it
      if (_.has(newPageStats, currentPage.current?.page)) {
        // @ts-ignore
        newPageStats[currentPage.current?.page as PageKeys].seconds += diffSeconds
      } else {
        // if the page we are leaving hasn't been visited before, let's add time counted
        newPageStats[currentPage.current?.page as PageKeys] = {
          seconds: diffSeconds
        }
      }
    }

    Object.keys(newPageStats).forEach((key) => {
      // @ts-ignore
      newPageStats[key as PageKeys].minutes = Math.floor(newPageStats[key as PageKeys].seconds / 60)
    })

    return newPageStats
  }

  useEffect(() => {
    const page: PageKeys = pathname.replace(/\//g, '') as PageKeys
    pageStats.current = getPageStats()
    currentPage.current = {
      date: new Date().getTime(),
      page,
      seconds: undefined,
      visible: true
    }
    /* console.log(
      'Running on pathname ' + pathname + ', currentPage ',
      currentPage.current,
      ' pageStats ',
      pageStats.current
    ) */
  }, [pathname])

  return <></>
}

export default Statistics
