import { BodyShort, Chip, Loader, VerticalSpace } from '@cegal/ds-components'
import { Delete } from '@cegal/ds-icons/dist/Delete'
import { listCertificates, listClients, listSkills } from 'actions'
import { Client, EngagementsQuery, Location, Skill } from 'declarations/models'
import { useAppDispatch, useAppSelector } from 'store'

import { Hr, MarginChip, MarginChipMarquee } from 'components/styled'

import { useEffect } from 'react'
import Marquee from 'react-fast-marquee'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'

export interface EngagementsDescriptionProps {
  total: number
  query: Partial<EngagementsQuery>
  itemsPerPage: number
  setQuery?: (query: Partial<EngagementsQuery>) => void
}

const EngagementsDescription = ({ total, query, itemsPerPage, setQuery }: EngagementsDescriptionProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const apiReady = useAppSelector((state) => state.app.apiReady)
  const skillsLoading = useAppSelector((state) => state.skills.listing)
  const skillsList = useAppSelector((state) => state.skills.list)
  // const categoriesList = useAppSelector((state) => state.categories.list)
  // const categoriesLoading = useAppSelector((state) => state.categories.listing)
  // const sourcesList = useAppSelector((state) => state.sources.list)
  // const sourcesLoading = useAppSelector((state) => state.sources.listing)
  const clientsList = useAppSelector((state) => state.clients.list)
  const clientsLoading = useAppSelector((state) => state.clients.listing)
  const certificatesList = useAppSelector((state) => state.certificates.list)
  const certificatesLoading = useAppSelector((state) => state.certificates.listing)
  const locationsList = useAppSelector((state) => state.locations.list)
  const locationsLoading = useAppSelector((state) => state.locations.listing)

  useEffect(() => {
    if (apiReady && skillsList === undefined && !skillsLoading) {
      dispatch(listSkills())
    }
  }, [apiReady, skillsList, skillsLoading])

  useEffect(() => {
    if (apiReady && certificatesList === undefined && !certificatesLoading) {
      dispatch(listCertificates())
    }
  }, [apiReady, certificatesList, certificatesLoading])

  useEffect(() => {
    if (apiReady && clientsList === undefined && !clientsLoading) {
      dispatch(listClients())
    }
  }, [apiReady, clientsList, clientsLoading])

  /* useEffect(() => {
     if (apiReady && sourcesList === undefined && !sourcesLoading) {
       dispatch(listSources())
     }
   }, [apiReady, sourcesList, sourcesLoading])
 */

  const {
    search,
    followed,
    mine,
    posteddate,
    startdate,
    enddate,
    deadline,
    hideEndedEngagements,
    clients,
    // sources,
    // categories,
    locations,
    hideUnfilledVacancies,
    hideFilledVacancies,
    allSkills,
    skills,
    page,
    certificates,
    allCertificates
  } = query

  const initialValue = page ? (+page - 1) * itemsPerPage + 1 : 1
  let finalValue = initialValue + itemsPerPage - 1
  if (finalValue > total) {
    finalValue = total
  }

  const sentence: Array<React.ReactNode> = []

  // const sourcesListArray = _.isEmpty(sourcesList) ? [] : sourcesList

  if (total > 0) {
    sentence.push(
      t('messages:description-found-x', {
        number: total,
        item:
          total === 1 ? t('label:engagement-title').toLowerCase() : t('label:engagements-title').toLowerCase()
      })
    )

    if (total > itemsPerPage) {
      sentence.push('. ')
      sentence.push(
        t('messages:description-showing-x', {
          range: '' + initialValue + '-' + finalValue,
          item: t('label:engagements-title').toLowerCase()
        })
      )
    }
  } else {
    sentence.push(t('messages:description-no-x-found', { x: t('label:engagements-title').toLowerCase() }))
  }

  if (!_.isEmpty(followed)) {
    sentence.push(t('messages:description-i-follow'))
  }

  if (!_.isEmpty(mine)) {
    sentence.push(t('messages:description-created-by-me'))
  }

  if (!_.isEmpty(search)) {
    sentence.push(', ')
    sentence.push(t('messages:description-matching-x', { text: search }))
  }

  if (_.isNumber(posteddate)) {
    sentence.push(
      t('messages:description-posted-in-x-item', {
        x: posteddate,
        item: posteddate === 1 ? t('label:day').toLowerCase() : t('label:days').toLowerCase()
      })
    )
  }

  if (!_.isEmpty(startdate)) {
    sentence.push(
      t('messages:description-starting-x', {
        x: startdate
      })
    )
  }

  if (!_.isEmpty(enddate)) {
    sentence.push(
      t('messages:description-ending-x', {
        x: enddate
      })
    )
  }

  if (_.isNumber(deadline)) {
    sentence.push(
      t('messages:description-deadline-in-x-item', {
        x: deadline,
        item: deadline === 1 ? t('label:month').toLowerCase() : t('label:months').toLowerCase()
      })
    )
  }

  if (!_.isEmpty(clients) || _.isNumber(clients)) {
    sentence.push(', ')
    const _clients = (!Array.isArray(clients) ? [clients] : clients) as Array<number>
    sentence.push(
      t('messages:description-in-item', {
        item:
          _clients.length === 1
            ? t('label:client-title').toLowerCase()
            : t('label:clients-title').toLowerCase()
      })
    )
    // this does a "flat push" of an array into current sentences array
    sentence.push.apply(
      sentence,
      clientsList
        ?.filter((c: Client) => _clients.includes(c.id))
        .map((c: Client) => (
          <MarginChip
            key={'client-' + c.id}
            size='small'
            selected
            rightElement={
              <Chip.Button
                icon={<Delete size='1rem' />}
                onClick={() =>
                  setQuery?.({
                    clients: _clients.filter((_c) => _c !== c.id)
                  })
                }
              />
            }
          >
            {c.name}
          </MarginChip>
        )) ?? []
    )
  }

  /* if (sources) {
    const singleSource = !Array.isArray(sources)
    sentence.push(
      t('messages:description-in-item-x', {
        text: !_.isNil(sourcesListArray)
          ? sourcesListArray!
              .filter((s) => (!singleSource ? sources?.includes(s.id as number) : sources === s.id))
              .map((s) => s.name)
              .join(', ')
          : singleSource
          ? sources
          : sources.join(', '),
        item:
          sources.length === 1
            ? t('label:source-title').toLowerCase()
            : t('label:sources-title').toLowerCase()
      })
    )
  } */

  /* if (categories) {
    const singleCategory = !Array.isArray(categories)
    sentence.push(
      t('messages:description-in-item-x', {
        text: !_.isNil(categoriesListArray)
          ? categoriesListArray!
              .filter((c: any) => (!singleCategory ? categories?.includes(c.id) : categories === c.id))
              .map((c: any) => c.name)
              .join(', ')
          : singleCategory
          ? categories
          : categories.join(', '),
        item:
          categories.length === 1
            ? t('label:category-title').toLowerCase()
            : t('label:categories-title').toLowerCase()
      })
    )
  } */

  if (!_.isEmpty(locations) || _.isNumber(locations)) {
    sentence.push(', ')
    const _locations = (!Array.isArray(locations) ? [locations] : locations) as Array<number>
    sentence.push(
      t('messages:description-in-item', {
        item:
          _locations.length === 1
            ? t('label:location-title').toLowerCase()
            : t('label:locations-title').toLowerCase()
      })
    )
    // this does a "flat push" of an array into current sentences array
    sentence.push.apply(
      sentence,
      locationsList
        ?.filter((l: Location) => _locations.includes(l.id))
        .map((l: Location) => (
          <MarginChip
            key={'location-' + l.id}
            size='small'
            selected
            rightElement={
              <Chip.Button
                icon={<Delete size='1rem' />}
                onClick={() =>
                  setQuery?.({
                    locations: _locations.filter((_l) => _l !== l.id)
                  })
                }
              />
            }
          >
            {l.name}
          </MarginChip>
        )) ?? []
    )
  }

  if (hideEndedEngagements) {
    sentence.push(t('messages:description-hiding-ended-engagements'))
  }

  if (hideUnfilledVacancies && !hideFilledVacancies) {
    sentence.push(t('messages:description-filled-vacancies'))
  }

  if (hideFilledVacancies && !hideUnfilledVacancies) {
    sentence.push(t('messages:description-unfilled-vacancies'))
  }

  if (!_.isEmpty(skills) || _.isNumber(skills)) {
    sentence.push(', ')
    const _skills = (!Array.isArray(skills) ? [skills] : skills) as Array<number>

    if (_skills.length > 1) {
      sentence.push(
        allSkills
          ? ' ' + t('messages:description-operator-all')
          : ' ' + t('messages:description-operator-some')
      )
    } else {
      sentence.push(' ' + t('messages:description-operator-none'))
    }
    sentence.push(
      ' ' +
        (_skills.length === 1 ? t('label:skill-title').toLowerCase() : t('label:skills-title').toLowerCase())
    )
    // this does a "flat push" of an array into current sentences array
    sentence.push.apply(
      sentence,
      skillsList
        ?.filter((s: Skill) => _skills.includes(s.id))
        .map((s: Skill) => (
          <MarginChip
            key={'skill-' + s.id}
            size='small'
            selected
            rightElement={
              <Chip.Button
                icon={<Delete size='1rem' />}
                onClick={() =>
                  setQuery?.({
                    skills: _skills.filter((_s) => _s !== s.id)
                  })
                }
              />
            }
          >
            {s.name}
          </MarginChip>
        )) ?? []
    )
  }

  if (!_.isEmpty(certificates) || _.isNumber(certificates)) {
    sentence.push(', ')
    const _certificates = (!Array.isArray(certificates) ? [certificates] : certificates) as Array<number>

    if (_certificates.length > 1) {
      sentence.push(
        allCertificates
          ? ' ' + t('messages:description-operator-all')
          : ' ' + t('messages:description-operator-some')
      )
    } else {
      sentence.push(' ' + t('messages:description-operator-none'))
    }

    sentence.push(
      ' ' +
        (_certificates.length === 1
          ? t('label:certificate').toLowerCase()
          : t('label:certificates').toLowerCase())
    )
    // this does a "flat push" of an array into current sentences array
    sentence.push.apply(
      sentence,
      certificatesList
        ?.filter((c: any) => _certificates.includes(c.id))
        .map((c: any) => (
          <MarginChipMarquee
            key={'certificate-' + c.id}
            size='small'
            selected
            rightElement={
              <Chip.Button
                icon={<Delete size='1rem' />}
                onClick={() =>
                  setQuery?.({
                    certificates: _certificates.filter((_c) => _c !== c.id)
                  })
                }
              />
            }
          >
            {c.name.length > 40 ? <Marquee>{c.name}</Marquee> : <>{c.name}</>}
          </MarginChipMarquee>
        )) ?? []
    )
  }

  return (
    <>
      <BodyShort spacing data-cy='engagementResultsText'>
        {skillsLoading || locationsLoading || clientsLoading || certificatesLoading ? (
          <Loader size='small' />
        ) : (
          sentence
        )}
      </BodyShort>
      <Hr style={{ width: '70%', marginLeft: '0' }} />
      <VerticalSpace />
    </>
  )
}

export default EngagementsDescription
