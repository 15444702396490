import { FlexDiv } from '@cegal/ds-components'
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'
import { File } from 'declarations/models'

import { useEffect, useRef } from 'react'
// necessary to hidwe text content and annotation layers from react-pdf
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'

export interface FileRendererProps {
  file: File
}

const FileRenderer = ({ file }: FileRendererProps) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current && file.name.endsWith('.docx')) {
      fetch(file.content)
        .then((res) => res.blob())
        .then((newBlob) => {
          ;(window as any).docx
            ?.renderAsync(newBlob, ref.current)
            .then((x: Blob) => {
              // console.log('Successfully rendered docx', x)
            })
            .catch((e: React.ChangeEvent<HTMLInputElement>): void => {
              console.log('docx: error', e)
            })
        })
    }
  }, [ref])

  const split = file.name.split('.')
  const extension = split[split.length - 1]

  if (extension !== 'docx') {
    return (
      <DocViewer
        documents={[{ uri: file.content }]}
        pluginRenderers={DocViewerRenderers}
        style={{ maxWidth: '70vh' }}
        config={{
          header: {
            disableFileName: true,
            disableHeader: true
          }
        }}
      />
    )
  }

  /* docx file preview  */
  return <FlexDiv id='doc' ref={ref} />
}

export default FileRenderer
