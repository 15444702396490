import { BodyShort, Chip, Label } from '@cegal/ds-components/dist'
import { AutoFixHigh, DriveFileMove } from '@cegal/ds-icons'
import { styled } from 'styled-components'

import { useState } from 'react'

import AiFile from './AiFile'

type AiFilesProps = {
  files: any[]
  searchValue: string
}

const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 0.5rem;
  position: relative;
`

const TabField = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--cat-homepanel-element-background-color);
  flex-grow: 1;
  overflow-y: auto;
  padding: 1.5rem 1rem;
`

const HeadingContainer = styled.div`
  border-bottom: 1px solid var(--cat-homepanel-element-border-color);
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.6rem;
`

const AiFiles = ({ files, searchValue }: AiFilesProps) => {
  const [chips, setChips] = useState([
    {
      name: 'All',
      selected: true
    },
    {
      name: 'Last 7 days',
      selected: false
    },
    {
      name: 'Last 30 days',
      selected: false
    }
  ])

  const handleChipClick = (clickedIndex: number) => {
    const updatedChips = chips.map((chip, index) => {
      return {
        ...chip,
        selected: index === clickedIndex
      }
    })

    setChips(updatedChips)
  }

  const now = new Date()
  const sevenDaysAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000)
  const thirtyDaysAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000)

  const allFiles = files
  const thisWeekFiles = files.filter((file) => new Date(file.uploaded) >= sevenDaysAgo)
  const thisMonthFiles = files.filter((file) => new Date(file.uploaded) >= thirtyDaysAgo)

  const selectedFiles = chips[0].selected ? allFiles : chips[1].selected ? thisWeekFiles : thisMonthFiles

  selectedFiles.sort((a, b) => {
    const dateA = new Date(a.uploaded)
    const dateB = new Date(b.uploaded)
    return dateB.getTime() - dateA.getTime()
  })

  const filteredFiles = selectedFiles.filter((file) =>
    file.name.toLowerCase().includes(searchValue.toLowerCase())
  )

  return (
    <TabContainer style={{ paddingTop: '0' }}>
      <TabField>
        <HeadingContainer style={{ border: 'none' }}>
          {chips.map((chip, index) => (
            <Chip
              key={index}
              size='medium'
              style={{ margin: '0', cursor: 'pointer' }}
              selected={chip.selected}
              onClick={() => handleChipClick(index)}
            >
              {chip.name}
            </Chip>
          ))}
        </HeadingContainer>
        <HeadingContainer style={{ padding: '0.6rem 0 1rem 0' }}>
          <DriveFileMove size='1.5rem' />
          <Label size='medium'>Sent files</Label>
        </HeadingContainer>
        {filteredFiles.map((file, index) => (
          <AiFile file={file} key={index} />
        ))}
        {filteredFiles.length === 0 && <BodyShort style={{ marginTop: '1rem' }}>No files found</BodyShort>}
        <HeadingContainer style={{ padding: '3rem 0 1rem 0' }}>
          <AutoFixHigh size='1.5rem' />
          <Label>AI Generated files</Label>
        </HeadingContainer>
        <BodyShort style={{ marginTop: '1rem' }}>No files found</BodyShort>
      </TabField>
    </TabContainer>
  )
}

export default AiFiles
