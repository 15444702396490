import { Alert, BodyShort, Button, Table, VerticalSpace } from '@cegal/ds-components'
import { AddCircle } from '@cegal/ds-icons'
import { resetEngagementVacancy } from 'actions'
import { EngagementVacancy } from 'declarations/models'
import { useAppDispatch, useAppSelector } from 'store'
import { v4 as uuidv4 } from 'uuid'

import { EngagementStepFormProps } from 'components/Engagements/Engagement/Form/EngagementForm'
import EngagementVacancyForm from 'components/Engagements/Engagement/Vacancy/EngagementVacancyForm'
import EngagementVacancyRow from 'components/Engagements/Engagement/Vacancy/EngagementVacancyRow'
import EngagementVacancyRowHeader from 'components/Engagements/Engagement/Vacancy/EngagementVacancyRowHeader'

import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'

const Step5 = ({
  onEditVacancy,
  onRemoveVacancy,
  onNewVacancy,
  setThereAreUnsavedVacations,
  vacancies,
  formRef
}: EngagementStepFormProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [vacanciesBeingCreated, setVacanciesBeingCreated] = useState<{ [k in string]: boolean }>({})
  const [vacanciesBeingEdited, setVacanciesBeingEdited] = useState<{ [k in string]: boolean }>({})

  const createdVacancy = useAppSelector((state) => state.engagements.createdVacancy)
  const creatingVacancy = useAppSelector((state) => state.engagements.creatingVacancy)
  const currentVacancyId = useAppSelector((state) => state.engagements.currentVacancyId)
  const deletedVacancy = useAppSelector((state) => state.engagements.deletedVacancy)
  const deletingVacancy = useAppSelector((state) => state.engagements.deletingVacancy)
  const savedVacancy = useAppSelector((state) => state.engagements.savedVacancy)
  const savingVacancy = useAppSelector((state) => state.engagements.savingVacancy)

  const { watch } = useFormContext()

  const engagementTitle = watch('title')

  // tell the main form that we have unsaved vacancies
  useEffect(() => {
    setThereAreUnsavedVacations(
      _.some(Object.values(vacanciesBeingCreated).concat(Object.values(vacanciesBeingEdited)), (x) => x)
    )
  }, [vacanciesBeingCreated, vacanciesBeingEdited])

  return (
    <>
      <VerticalSpace />
      <BodyShort>
        {!_.isEmpty(vacancies)
          ? t(
              vacancies.length > 1
                ? 'messages:engagement-has-x-vacancies'
                : 'messages:engagement-has-x-vacancy',
              { x: vacancies.length }
            )
          : t('messages:engagement-has-no-vacancies')}
      </BodyShort>
      {deletedVacancy !== undefined && (
        <>
          <VerticalSpace />
          {deletedVacancy === null ? (
            <Alert variant='error' onClose={() => dispatch(resetEngagementVacancy())}>
              {t('messages:error-delete-vacancy-from-engagement-x', { x: engagementTitle })}
            </Alert>
          ) : (
            <Alert variant='success' onClose={() => dispatch(resetEngagementVacancy())}>
              {t('messages:success-delete-vacancy-from-engagement-x', { x: engagementTitle })}
            </Alert>
          )}
        </>
      )}
      {savedVacancy !== undefined && (
        <>
          <VerticalSpace />
          {savedVacancy === null ? (
            <Alert variant='error' onClose={() => dispatch(resetEngagementVacancy())}>
              {t('messages:error-edit-vacancy-from-engagement-x', { x: engagementTitle })}
            </Alert>
          ) : (
            <Alert variant='success' onClose={() => dispatch(resetEngagementVacancy())}>
              {t('messages:success-edit-vacancy-from-engagement-x', { x: engagementTitle })}
            </Alert>
          )}
        </>
      )}
      <VerticalSpace />
      <Table>
        {!_.isEmpty(vacancies) && <EngagementVacancyRowHeader />}
        {vacancies?.map((vacancy: EngagementVacancy, index: number) => {
          let editMode = false
          const hasTempId = !_.isNil(vacancy.temp_id)

          if (hasTempId && _.isString(vacancy.temp_id)) {
            editMode = vacanciesBeingEdited[vacancy?.temp_id?.toString()]
          }
          if (_.isNumber(vacancy.id)) {
            editMode = vacanciesBeingEdited[vacancy?.id?.toString()]
          }

          // isDeleting and isEditing are only for existing vacancies, with valid ids

          const isDeleting = deletingVacancy && currentVacancyId === vacancy.id
          const isEditing = savingVacancy && currentVacancyId === vacancy.id
          const added = !!createdVacancy && createdVacancy.id === vacancy.id
          const edited = !!savedVacancy && savedVacancy.id === vacancy.id

          if (editMode) {
            return (
              <Table.Row key={'engagement-vacancy-' + vacancy.id}>
                <Table.DataCell colSpan={999} style={{ padding: '1rem' }}>
                  <EngagementVacancyForm
                    vacancy={vacancy}
                    engagementTitle={engagementTitle}
                    isEditing={isEditing}
                    formRef={formRef}
                    spacing={false}
                    onNewVacancy={(vacancy: EngagementVacancy) => onEditVacancy(vacancy, index)}
                    onClose={() => {
                      hasTempId
                        ? setVacanciesBeingEdited({
                            ...vacanciesBeingEdited,
                            [vacancy?.temp_id?.toString() as string]: false
                          })
                        : setVacanciesBeingEdited({
                            ...vacanciesBeingEdited,
                            [vacancy?.id?.toString() as string]: false
                          })
                    }}
                  />
                </Table.DataCell>
              </Table.Row>
            )
          }

          return (
            <EngagementVacancyRow
              key={hasTempId ? vacancy.temp_id : vacancy.id}
              edited={edited}
              added={added}
              loading={isEditing || isDeleting}
              item={vacancy}
              onEdit={() =>
                hasTempId
                  ? setVacanciesBeingEdited({
                      ...vacanciesBeingEdited,
                      [vacancy?.temp_id?.toString() as string]: true
                    })
                  : setVacanciesBeingEdited({
                      ...vacanciesBeingEdited,
                      [vacancy?.id?.toString() as string]: true
                    })
              }
              onDelete={() => onRemoveVacancy(index)}
            />
          )
        })}
      </Table>
      <VerticalSpace />
      {Object.keys(vacanciesBeingCreated)
        ?.filter((key) => vacanciesBeingCreated[key])
        ?.map((key) => (
          <EngagementVacancyForm
            isAdding={creatingVacancy}
            formRef={formRef}
            tempId={key}
            engagementTitle={engagementTitle}
            key={key}
            onNewVacancy={onNewVacancy}
            onClose={() =>
              setVacanciesBeingCreated({
                ...vacanciesBeingCreated,
                [key]: false
              })
            }
          />
        ))}
      <VerticalSpace />
      <Button
        loading={creatingVacancy}
        variant='secondary'
        icon={<AddCircle size='1.3rem' />}
        onClick={() => {
          const newKey = uuidv4()
          setVacanciesBeingCreated({
            ...vacanciesBeingCreated,
            [newKey]: true
          })
        }}
      >
        {creatingVacancy
          ? t('buttons:adding')
          : t('buttons:add-new-x', { x: t('label:vacancy-title').toLowerCase() })}
      </Button>
      <VerticalSpace />
    </>
  )
}

export default Step5
