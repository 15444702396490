import { Popover, TextField } from '@cegal/ds-components'

import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'
import { DateRange, DayPicker } from 'react-day-picker'
import { useTranslation } from 'react-i18next'

import { toLocalDateFormat } from 'utils'
import BEMHelper, { BEMWrapper } from 'utils/bem'

import './allocationDayPicker.scss'

interface AllocationDayPickerProperties {
  value: DateRange | undefined
  onChange: (values: DateRange | undefined) => void
}

const AllocationDayPicker = ({ value, onChange }: PropsWithChildren<AllocationDayPickerProperties>) => {
  const cls: BEMWrapper = BEMHelper('dayPicker')

  const { t } = useTranslation()
  const buttonRef: React.RefObject<HTMLButtonElement> = useRef<HTMLButtonElement>(null)
  const [dateSelector, setDateSelector] = useState<DateRange | undefined>()
  const [isPickerOpen, setIsPickerOpen] = useState<boolean>(false)

  const _setDateSelector = (d: DateRange | undefined) => {
    setDateSelector(d)
    onChange(d)
  }

  const onClose = (): void => setIsPickerOpen(false)

  useEffect(() => {
    setDateSelector(value)
  }, [value])

  let dateValue = t('label:pick-date-range')
  if (dateSelector?.from) {
    if (!dateSelector.to) {
      dateValue = `${toLocalDateFormat(dateSelector.from)} - ${t('label:pick-end-date')}`
    } else if (dateSelector.to) {
      dateValue = `${toLocalDateFormat(dateSelector.from)} - ${toLocalDateFormat(dateSelector.to)}`
    }
  }

  return (
    <div className={cls.className}>
      <Popover
        placement='auto-start'
        variant='secondary'
        anchorEl={buttonRef?.current}
        onClose={onClose}
        open={isPickerOpen}
      >
        <Popover.Content>
          <DayPicker
            className={cls.element('day-picker')}
            showWeekNumber
            mode='range'
            weekStartsOn={1}
            formatters={{
              formatWeekNumber: (weekNumber: number): string => `W${weekNumber}`
            }}
            defaultMonth={new Date()}
            selected={dateSelector}
            onSelect={_setDateSelector}
          />
        </Popover.Content>
      </Popover>
      <button
        className={cls.element('button')}
        ref={buttonRef}
        type='button'
        aria-label={t('label:allocation-date-range-button-aria-label')}
        onClick={() => setIsPickerOpen(!isPickerOpen)}
      >
        <TextField
          className={cls.element('input-display')}
          type='text'
          size='medium'
          value={dateValue}
          label={t('label:allocation-date-range-text-field-label')}
        />
      </button>
    </div>
  )
}
export default AllocationDayPicker
