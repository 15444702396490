import { Avatar, Heading, Loader, VerticalSpace } from '@cegal/ds-components'
import UnknownAvatar from 'assets/images/unknownAvatar.png'
import { Consultant, ConsultantsQuery, SimpleConsultant } from 'declarations/models'

import { ConsultantSignature } from 'components/Consultants/Consultant'
import ConsultantAllocationChart from 'components/Consultants/Consultant/Allocation/ConsultantAllocationChart'
import { DataAlign, DescriptionList, DescriptionListItem } from 'components/DescriptionList/DescriptionList'
import Section from 'components/Forms/Section'
import RolesGroup from 'components/TagGroup/RolesGroup'
import SkillsGroup from 'components/TagGroup/SkillsGroup'
import { HomeElementPanel } from 'components/styled'

import { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import useConsultantAllocation from 'hooks/useConsultantAllocation'
import useConsultantAvatar from 'hooks/useConsultantAvatar'
import useConsultantSupervisor from 'hooks/useConsultantSupervisor'

type ConsultantDetailProps = {
  consultant?: Consultant | null | undefined
  showEngagements?: boolean
}

const ConsultantDetail = ({ consultant, showEngagements = true }: ConsultantDetailProps) => {
  const { email, department, location, roles, skills } = consultant || {}
  const { avatar, avatarLoading, ref: avatarRef } = useConsultantAvatar({ id: consultant?.id })
  const { allocation, allocationLoading, ref: allocationRef } = useConsultantAllocation({ consultant })
  const { loading: loadingSupervisor, supervisor } = useConsultantSupervisor({ id: consultant?.id })
  const cardRef = useRef(null)
  const { t } = useTranslation()

  if (!consultant) {
    return null
  }

  return (
    <HomeElementPanel border background ref={cardRef}>
      <Avatar
        variant='square-round'
        size='2xlarge'
        src={avatar}
        loading={avatarLoading}
        ref={avatarRef}
        defaultSrc={UnknownAvatar}
      />
      <VerticalSpace />
      <Heading ref={allocationRef}>{consultant.name}</Heading>
      <VerticalSpace size='2' />
      <Section heading={t('label:info')!}>
        <DescriptionList $alignment={DataAlign.Column}>
          <DescriptionListItem title={t('label:email')} details={email} />
          <DescriptionListItem title={t('label:department')} details={department?.name} />
          <DescriptionListItem title={t('label:location')} details={location?.name} />
          <DescriptionListItem
            title={t('label:roles')}
            details={<RolesGroup<Partial<ConsultantsQuery>> mode='none' roles={roles} />}
          />
          <DescriptionListItem
            title={t('label:skills')}
            details={<SkillsGroup mode='readmore' size='small' visibleRows={5} skills={skills} />}
          />
        </DescriptionList>
      </Section>
      <Section heading={t('label:allocation')}>
        {allocationLoading ? (
          <Loader size='small' />
        ) : (
          <div style={{ width: '100%' }}>
            <ConsultantAllocationChart
              chartLabel={t('label:allocation-chart')}
              consultant={consultant}
              allocation={allocation}
              controls
              showOverview
              showYAxisLabel
              showXAxisLabel
            />
          </div>
        )}
      </Section>

      <Section heading={t('label:org-chart')!}>
        <DescriptionListItem
          $alignItems='center'
          title={t('label:supervisor')}
          details={
            loadingSupervisor ? (
              <Loader size='small' />
            ) : loadingSupervisor === null ? (
              '-'
            ) : (
              <ConsultantSignature
                consultant={
                  {
                    id: supervisor?.catId,
                    name: supervisor?.displayName,
                    email: supervisor?.mail
                  } as SimpleConsultant
                }
                popover={['view', 'email']}
                context={{ consultant }}
              />
            )
          }
        />
      </Section>
    </HomeElementPanel>
  )
}

export default ConsultantDetail
