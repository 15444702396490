var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import cl from 'clsx';
import React, { forwardRef } from 'react';
export const ErrorSummaryItem = forwardRef((_a, ref) => {
    var { children, as: Component = 'a', className } = _a, rest = __rest(_a, ["children", "as", "className"]);
    return (React.createElement(Component, Object.assign({}, rest, { ref: ref, className: cl('cds-error-summary__item', 'cds-link', className) }), children));
});
ErrorSummaryItem.displayName = 'ErrorSummary.Item';
export default ErrorSummaryItem;
