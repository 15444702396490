import { Widget, WidgetTable } from 'declarations/app'

export const allWidgets: Array<Widget> = [
  { id: 'news', translation: 'home:news-title' },
  { id: 'myMatches', translation: 'home:my-matches' },
  { id: 'followedConsultants', translation: 'home:followed-consultants' },
  { id: 'followedEngagements', translation: 'home:followed-engagements' },
  { id: 'followedClients', translation: 'home:followed-clients' },
  { id: 'props', translation: 'home:summary-title' },
  // { id: 'myNotifications', translation: 'home:my-notifications' },
  { id: 'myNotes', translation: 'home:notes-title' }
]

export const initialWidgetTable: WidgetTable = {
  left: [...allWidgets].slice(0, 4),
  right: [...allWidgets].slice(4, 7)
}
