var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import cl from 'clsx';
import { Error } from '@cegal/ds-icons/dist/Error';
import { HorizontalSpace } from 'layout/spacing';
import { FlexDiv, PileDiv } from 'layout/grid';
import { BodyShort, Heading } from 'components/typography';
import React, { forwardRef } from 'react';
import { useId } from 'util/useId';
import ErrorSummaryItem from './ErrorSummaryItem';
export const ErrorSummary = forwardRef((_a, ref) => {
    var { children, className, icon = true, size = 'medium', headingTag = 'h2', heading } = _a, rest = __rest(_a, ["children", "className", "icon", "size", "headingTag", "heading"]);
    const headingId = useId();
    return (React.createElement("section", Object.assign({ ref: ref }, rest, { className: cl('cds-error-summary', `cds-error-summary--${size}`, className), tabIndex: -1, "aria-live": 'polite', "aria-relevant": 'all', "aria-labelledby": `error-summary-${headingId}` }),
        React.createElement(PileDiv, null,
            React.createElement(FlexDiv, { className: 'cds-error-summary__header' },
                icon && (React.createElement(React.Fragment, null,
                    React.createElement(Error, { title: 'Error', className: 'cds-error-summary__icon' }),
                    React.createElement(HorizontalSpace, null))),
                React.createElement(PileDiv, null,
                    React.createElement(Heading, { className: 'cds-error-summary__heading', as: headingTag, size: 'small', id: `error-summary-${headingId}`, spacing: true }, heading))),
            React.createElement(PileDiv, null,
                React.createElement(BodyShort, { as: 'ul', size: size, className: 'cds-error-summary__list' }, React.Children.map(children, (child) => {
                    return React.createElement("li", { key: child === null || child === void 0 ? void 0 : child.toString() }, child);
                }))))));
});
ErrorSummary.Item = ErrorSummaryItem;
ErrorSummary.displayName = 'ErrorSummary';
export default ErrorSummary;
