import { Column, HorizontalSpace, Row, Tabs, VerticalSpace } from '@cegal/ds-components/dist'
import { standardLogger } from 'metrics/loggers'

import FollowedClients from 'components/Home/FollowedClients/FollowedClients'
import FollowedConsultants from 'components/Home/FollowedConsultants/FollowedConsultants'
import FollowedEngagements from 'components/Home/FollowedEngagements/FollowedEngagements'
import MyMatches from 'components/Home/MyMatches/MyMatches'
import MyNotes from 'components/Home/MyNotes/MyNotes'
import NewsBox from 'components/Home/NewsBox/NewsBox'
import SummaryBox from 'components/Home/PlatformSummary/SummaryBox'
import { TabPanel } from 'components/styled'

import { HomeProps } from 'pages/Home/Home'

import { useTranslation } from 'react-i18next'

enum HomePageTab {
  FOLLOWS = 'follows',
  MATCHES = 'matches',
  NOTES = 'notes'
}

const HomePage = ({ widgetTable }: HomeProps) => {
  const { t } = useTranslation()

  const handleViewTabChange = (tab: string) => {
    standardLogger('home.view.tab.' + tab)
  }

  return (
    <>
      <VerticalSpace />
      <Row>
        <Column style={{ maxWidth: '100%', minWidth: '400px' }}>
          <SummaryBox mode='tab' />
          <VerticalSpace />
          <NewsBox mode='widget' />
          <VerticalSpace />
        </Column>
        <HorizontalSpace />
        <Column flex='3'>
          <Tabs defaultValue={HomePageTab.FOLLOWS} onChange={handleViewTabChange}>
            <Tabs.List loop>
              <Tabs.Tab value={HomePageTab.FOLLOWS} label={t('label:follows')} />
              <Tabs.Tab value={HomePageTab.MATCHES} label={t('label:matches')} />
              <Tabs.Tab value={HomePageTab.NOTES} label={t('label:notes')} />
            </Tabs.List>
            <TabPanel value={HomePageTab.FOLLOWS}>
              <FollowedConsultants mode='page' />
              <FollowedEngagements mode='page' />
              <FollowedClients mode='page' />
            </TabPanel>
            <TabPanel value={HomePageTab.MATCHES}>
              <MyMatches mode='page' />
            </TabPanel>
            <TabPanel value={HomePageTab.NOTES}>
              <MyNotes mode='page' />
            </TabPanel>
          </Tabs>
        </Column>
      </Row>
    </>
  )
}

export default HomePage
