var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as RadixTabs from '@radix-ui/react-tabs';
import cl from 'clsx';
import React, { createContext, forwardRef } from 'react';
import Tab from './Tab';
import TabList from './TabList';
import TabPanel from './TabPanel';
export const TabsContext = createContext(null);
export const Tabs = forwardRef((_a, ref) => {
    var { className, border = false, children, onChange, size = 'medium', selectionFollowsFocus = false } = _a, rest = __rest(_a, ["className", "border", "children", "onChange", "size", "selectionFollowsFocus"]);
    return (React.createElement(RadixTabs.Root, Object.assign({}, rest, { ref: ref, className: cl('cds-tabs', `cds-tabs--${size}`, { 'cds-tabs--border': border }, className), activationMode: selectionFollowsFocus ? 'automatic' : 'manual', onValueChange: onChange }),
        React.createElement(TabsContext.Provider, { value: {
                size
            } }, children)));
});
Tabs.Tab = Tab;
Tabs.List = TabList;
Tabs.Panel = TabPanel;
Tabs.displayName = 'Tabs';
export default Tabs;
