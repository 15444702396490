var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import cl from 'clsx';
import { AccordionContext } from 'components/accordion/Accordion';
import React, { createContext, forwardRef, useContext, useState } from 'react';
export const AccordionItemContext = createContext(null);
const AccordionItem = forwardRef((_a, ref) => {
    var { children, className, open, defaultOpen = false, onClick, id } = _a, rest = __rest(_a, ["children", "className", "open", "defaultOpen", "onClick", "id"]);
    const [internalOpen, setInternalOpen] = useState(defaultOpen);
    const mainContext = useContext(AccordionContext);
    if (mainContext === null) {
        console.error('<Accordion.Item> has to be used within an <Accordion>');
        return null;
    }
    return (React.createElement("div", Object.assign({}, rest, { ref: ref, className: cl('cds-accordion__item', {
            'cds-accordion__item--open': open !== null && open !== void 0 ? open : internalOpen
        }, className) }),
        React.createElement(AccordionItemContext.Provider, { value: {
                open: open !== null && open !== void 0 ? open : internalOpen,
                toggleOpen: () => {
                    if (open === undefined) {
                        setInternalOpen((iOpen) => !iOpen);
                    }
                }
            } }, children)));
});
AccordionItem.displayName = 'Accordion.Item';
export default AccordionItem;
