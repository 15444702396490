import {
  Avatar,
  FlexDiv,
  Heading,
  HorizontalSpace,
  Link,
  Loader,
  PileDiv,
  VerticalSpace
} from '@cegal/ds-components'
import UnknownAvatar from 'assets/images/unknownAvatar.png'
import {
  Consultant,
  ConsultantCertificate,
  ConsultantEngagement,
  ConsultantsQuery,
  SimpleConsultant
} from 'declarations/models'
import { eventLogger } from 'metrics/loggers'
import { useAppSelector } from 'store'

import Card, { CardProps } from 'components/Card/Card'
import { ConsultantSignature } from 'components/Consultants/Consultant'
import ConsultantAllocationChart, {
  ConsultantAllocationChartType,
  ConsultantAllocationChartUnit
} from 'components/Consultants/Consultant/Allocation/ConsultantAllocationChart'
import { DescriptionListItem, DescriptionSpan } from 'components/DescriptionList/DescriptionList'
import CertificatesGroup from 'components/TagGroup/CertificatesGroup'
import RolesGroup from 'components/TagGroup/RolesGroup'
import SkillsGroup from 'components/TagGroup/SkillsGroup'

import { useDrag } from 'react-dnd'
import Highlighter from 'react-highlight-words'
import { useTranslation } from 'react-i18next'
import { Link as NavLink } from 'react-router-dom'

import useConsultantAllocation from 'hooks/useConsultantAllocation'
import useConsultantAvatar from 'hooks/useConsultantAvatar'
import useConsultantSupervisor from 'hooks/useConsultantSupervisor'
import useQuery from 'hooks/useQuery'

import { tokenize } from 'utils/tokenizer'

import _ from 'lodash'

export type ConsultantCardProps = CardProps<Consultant, ConsultantsQuery>

type ConsultantCardPreviewProps = {
  children: React.ReactNode
  onView?: (item: Consultant) => void
  item: Consultant
}

const ConsultantCardPreview = ({ children, onView, item }: ConsultantCardPreviewProps) => {
  return _.isFunction(onView) ? (
    <div>
      <Link style={{ cursor: 'pointer' }} onClick={() => onView!(item)}>
        {children}
      </Link>
    </div>
  ) : (
    children
  )
}

const ConsultantCard = ({
  item,
  size = 'medium',
  spacing = false,
  onView,
  context,
  ...rest
}: ConsultantCardProps) => {
  const { t } = useTranslation()
  const { avatar, avatarLoading, ref: avatarRef } = useConsultantAvatar({ id: item?.id })
  const {
    allocation,
    allocationLoading,
    ref: allocationRef
  } = useConsultantAllocation({
    consultant: item
  })
  const { loading: loadingSupervisor, supervisor } = useConsultantSupervisor({ id: item?.id })
  const { query, setQuery } = useQuery<Partial<ConsultantsQuery>>({})
  const aiDrawerOpen = useAppSelector((state) => state.app.settings.aiDrawerOpen)
  const aiTab = useAppSelector((state) => state.app.settings.aiTab)

  const searchWords = tokenize(query.search)

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'ConsultantCard',
    item: {
      type: 'ConsultantCard',
      label: t('label:consultant-title'),
      link: `/consultants/${item.id}`,
      title: item.name,
      data: item
    },
    end: (item, monitor) => {
      // eslint-disable-next-line
      const dropResult = monitor.getDropResult()
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId()
    })
  }))
  const isDrag = aiDrawerOpen && aiTab !== 'files'

  const opacity = isDragging ? 0.4 : 1

  return (
    <Card<Consultant>
      data-cy='consultantCard'
      data-testid='ConsultantCard'
      item={item}
      border
      size={size}
      spacing={spacing}
      ref={isDrag ? drag : undefined}
      style={{ opacity, cursor: isDrag ? 'move' : 'default' }}
      draggable={isDrag}
      {...rest}
    >
      <FlexDiv
        ref={allocationRef}
        style={{
          flexDirection: size === 'xsmall' ? 'column' : 'row',
          alignItems: size === 'xsmall' ? 'center' : 'flex-start',
          flexWrap: 'nowrap'
        }}
      >
        <div>
          <ConsultantCardPreview onView={onView} item={item as Consultant}>
            <Avatar
              data-cy='ConsultantCard-Avatar'
              data-testid='ConsultantCard-Avatar'
              variant='square-round'
              size='large'
              src={avatar}
              defaultSrc={UnknownAvatar}
              loading={avatarLoading}
              ref={avatarRef}
            />
          </ConsultantCardPreview>
          {size === 'xsmall' && <VerticalSpace size='0.5' />}
        </div>
        <HorizontalSpace size='0.75' />
        <PileDiv
          style={{
            width: '100%',
            alignItems: size === 'xsmall' ? 'center' : 'flex-start'
          }}
        >
          <ConsultantCardPreview onView={onView} item={item as Consultant}>
            <Heading data-testid='ConsultantCard-Name' size='small' as='span'>
              <Highlighter
                caseSensitive={false}
                searchWords={searchWords}
                textToHighlight={item?.name ?? ''}
              />
            </Heading>
          </ConsultantCardPreview>

          {size !== 'xsmall' && (
            <>
              <VerticalSpace size='0.3' />
              <DescriptionListItem
                title={t('label:department')}
                data-cy='ConsultantCard-Department'
                data-testid='ConsultantCard-Department'
                details={
                  <Highlighter
                    caseSensitive={false}
                    searchWords={searchWords}
                    textToHighlight={(item as Consultant)?.department?.name ?? ''}
                  />
                }
              />
              <DescriptionListItem
                title={t('label:location')}
                details={
                  <Highlighter
                    caseSensitive={false}
                    searchWords={searchWords}
                    textToHighlight={(item as Consultant)?.location?.name ?? ''}
                  />
                }
              />
            </>
          )}
          {size !== 'xsmall' && (
            <DescriptionListItem
              title={t('label:roles')}
              details={
                !_.isEmpty((item as Consultant).roles) ? (
                  <RolesGroup<Partial<ConsultantsQuery>>
                    query={query}
                    roles={(item as Consultant).roles}
                    setQuery={setQuery}
                    interactive={!_.isNil(query)}
                  />
                ) : (
                  <DescriptionSpan>{t('messages:no-roles')}</DescriptionSpan>
                )
              }
            />
          )}
          {size !== 'xsmall' && (
            <DescriptionListItem
              title={t('label:skills')}
              $alignItems='baseline'
              details={
                !_.isEmpty((item as Consultant).skills) ? (
                  <SkillsGroup<Partial<ConsultantsQuery>>
                    query={query}
                    size='small'
                    skills={(item as Consultant).skills}
                    setQuery={setQuery}
                    interactive
                  />
                ) : (
                  <DescriptionSpan>{t('messages:no-skills')}</DescriptionSpan>
                )
              }
            />
          )}

          {size === 'medium' && (
            <>
              <DescriptionListItem
                title={t('label:allocation-title')}
                $alignItems='baseline'
                details={
                  allocationLoading ? (
                    <Loader size='small' />
                  ) : _.isEmpty(allocation) ? (
                    <DescriptionSpan>{t('messages:warning-no-allocation-info')}</DescriptionSpan>
                  ) : (
                    <ConsultantAllocationChart
                      type={ConsultantAllocationChartType.Bar}
                      unit={ConsultantAllocationChartUnit.Week}
                      consultant={item}
                      allocation={allocation}
                      controls
                      chartLabel={t('label:allocation-chart')}
                      showChartLabel={false}
                      showYAxisLabel
                      showXAxisLabel
                      showOverview={false}
                    />
                  )
                }
              />
              <FlexDiv>
                <DescriptionListItem
                  title={t('label:certificates')}
                  details={
                    !_.isEmpty((item as Consultant)?.certificates) ? (
                      <CertificatesGroup<Partial<ConsultantsQuery>>
                        query={query}
                        certificates={item.certificates as Array<ConsultantCertificate>}
                        setQuery={setQuery}
                        interactive={!_.isNil(query)}
                      />
                    ) : (
                      <DescriptionSpan>{t('messages:no-certificates')}</DescriptionSpan>
                    )
                  }
                />
              </FlexDiv>

              {!_.isEmpty((item as Consultant)?.engagements) && (
                <DescriptionListItem
                  title={t('label:engagements')}
                  details={(item as Consultant).engagements?.map((engagement: ConsultantEngagement) => (
                    <div key={engagement.id}>
                      <Link
                        data-amplitude='consultant.card.engagement'
                        as={NavLink}
                        to={'/engagements/' + engagement.id}
                        onClick={(e: any) => eventLogger(e)}
                      >
                        <Highlighter
                          caseSensitive={false}
                          searchWords={searchWords}
                          textToHighlight={engagement.title + ' - ' + engagement.vacancies.title}
                        />
                      </Link>
                    </div>
                  ))}
                />
              )}

              <DescriptionListItem
                $alignItems='center'
                title={t('label:supervisor')}
                details={
                  loadingSupervisor ? (
                    <Loader size='small' />
                  ) : loadingSupervisor === null ? (
                    '-'
                  ) : (
                    <ConsultantSignature
                      consultant={
                        {
                          id: supervisor?.catId,
                          name: supervisor?.displayName,
                          email: supervisor?.mail
                        } as SimpleConsultant
                      }
                      popover={['view', 'email']}
                      context={context}
                    />
                  )
                }
              />
            </>
          )}
        </PileDiv>
      </FlexDiv>
    </Card>
  )
}

export default ConsultantCard
