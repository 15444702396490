const userProfile = {
  amr: ['external'],
  sid: 'EE48FFABA657D04BF56DE2520B3577BE',
  sub: 'VOh2TKO8ISOmJOU6JS7GSibg8wrp4jIwufROR1QyV3s',
  idp: 'AzureIdp',
  name: 'mock.consultant@cegal.com',
  tenantId: 'ab159229-ca80-488c-b946-447a7f497050',
  bluebackApp: 'cat',
  bluebackApplicationClaim: 'Basic'
}

export default userProfile
