var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import cl from 'clsx';
import React, { forwardRef } from 'react';
import { BodyShort, Detail, ErrorMessage, Label } from 'components/typography';
import { omit, useId } from 'util/index';
import { useFormField } from './useFormField';
export const Textarea = forwardRef((props, ref) => {
    var _a;
    const { inputProps, errorId, showErrorMsg, hasError, size, inputDescriptionId } = useFormField(props, 'textarea');
    const { label, className, description, maxLength, hideLabel = false, resize } = props, rest = __rest(props, ["label", "className", "description", "maxLength", "hideLabel", "resize"]);
    const maxLengthId = `TextareaMaxLength-${useId()}`;
    const hasMaxLength = maxLength !== undefined && maxLength > 0;
    return (React.createElement("div", { className: cl('cds-form-field', `cds-form-field--${size}`, {
            'cds-form-field--disabled': !!inputProps.disabled,
            'cds-textarea--error': hasError,
            'cds-textarea--resize': resize
        }, className) },
        React.createElement(Label, { htmlFor: inputProps.id, size: size, as: 'label', className: cl('cds-form-field__label', {
                'cds-sr-only': hideLabel
            }) }, label),
        !!description && (React.createElement(React.Fragment, null, size === 'medium' ? (React.createElement(BodyShort, { className: cl('cds-form-field__description', {
                'cds-sr-only': hideLabel
            }), id: inputDescriptionId, size: 'small', as: 'div' }, description)) : (React.createElement(Detail, { className: cl('cds-form-field__description', {
                'cds-sr-only': hideLabel
            }), id: inputDescriptionId, size: 'small', as: 'div' }, description)))),
        React.createElement("div", { className: 'cds-textarea__wrapper' },
            React.createElement("textarea", Object.assign({}, omit(rest, ['error', 'errorId', 'size']), inputProps, { ref: ref, className: cl('cds-textarea__input', 'cds-body-short', `cds-body-${size !== null && size !== void 0 ? size : 'medium'}`, {
                    'cds-textarea--counter': hasMaxLength
                }), "aria-describedby": cl(inputProps['aria-describedby'], {
                    [maxLengthId]: hasMaxLength
                }) })),
            hasMaxLength && (React.createElement(React.Fragment, null,
                React.createElement("span", { id: maxLengthId, className: 'cds-sr-only' },
                    "Textarea with place for ",
                    maxLength,
                    " characters."),
                React.createElement(Counter, { maxLength: maxLength, currentLength: (_a = props.value) === null || _a === void 0 ? void 0 : _a.length, size: size })))),
        React.createElement("div", { className: 'cds-form-field__error', id: errorId, "aria-relevant": 'additions removals', "aria-live": 'polite' }, showErrorMsg && React.createElement(ErrorMessage, { size: size }, props.error))));
});
export const Counter = ({ maxLength, currentLength, size }) => {
    const difference = maxLength - currentLength;
    return (React.createElement(BodyShort, { className: cl('cds-textarea__counter', {
            'cds-textarea__counter--error': difference < 0
        }), "aria-live": difference < 20 ? 'polite' : 'off', size: size }, difference < 0
        ? `Total characters excess ${Math.abs(difference)}`
        : `Total characters left ${difference}`));
};
Textarea.displayName = 'Textarea';
export default Textarea;
