import { OrgChartSingleNode } from 'declarations/models'

import { useEffect, useState } from 'react'

import useOrgChart from 'hooks/useOrgChart'

import _ from 'lodash'

const useConsultantSupervisor = ({ id }: any) => {
  const { loaded: orgChartLoaded, getParentNode } = useOrgChart()

  const [supervisor, setSupervisor] = useState<OrgChartSingleNode | null | undefined>(undefined)

  useEffect(() => {
    if (_.isNumber(id) && supervisor === undefined && orgChartLoaded) {
      setSupervisor(getParentNode(id.toString()))
    }
  }, [id, orgChartLoaded, supervisor])

  return {
    loading: !orgChartLoaded || supervisor === undefined,
    supervisor
  }
}

export default useConsultantSupervisor
