import { AdminSyncing } from 'declarations/models'

import { CardContainer } from 'components/styled'

import SyncingCard from './SyncingCard'

export interface AdminSyncingCardViewProps {
  syncings: Array<AdminSyncing>
}

const AdminSyncingCardView = ({ syncings }: AdminSyncingCardViewProps) => {
  return (
    <CardContainer>
      {syncings!.map((sync) => {
        return <SyncingCard spacing height='200px' key={sync.id} sync={sync} />
      })}
    </CardContainer>
  )
}

export default AdminSyncingCardView
