import { Avatar, FlexDiv, Heading, HorizontalSpace, Link, PileDiv, VerticalSpace } from '@cegal/ds-components'
import { Client, ClientsQuery, Engagement } from 'declarations/models'
import { eventLogger } from 'metrics/loggers'
import { useAppSelector } from 'store'

import Card, { CardProps } from 'components/Card/Card'
import { DescriptionListItem } from 'components/DescriptionList/DescriptionList'

import { useDrag } from 'react-dnd'
import Highlighter from 'react-highlight-words'
import { useTranslation } from 'react-i18next'
import { NavLink, generatePath } from 'react-router-dom'

import useClientAvatar from 'hooks/useClientAvatar'
import useQuery from 'hooks/useQuery'

import { tokenize } from 'utils/tokenizer'

const ClientCard = ({ item, size = 'medium', spacing = true, ...rest }: CardProps<Client, ClientsQuery>) => {
  const { t } = useTranslation()
  const { avatar, avatarLoading, ref } = useClientAvatar({ id: item.id })
  const { query } = useQuery<Partial<ClientsQuery>>({})
  const searchWords = tokenize(query.search)
  const aiDrawerOpen = useAppSelector((state) => state.app.settings.aiDrawerOpen)
  const aiTab = useAppSelector((state) => state.app.settings.aiTab)

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'ClientCard',
    item: {
      type: 'ClientCard',
      label: t('label:client-title'),
      link: `/clients/${item.id}`,
      title: item.name,
      data: item
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId()
    })
  }))

  const isDrag = aiDrawerOpen && aiTab !== 'files'

  const opacity = isDragging ? 0.4 : 1

  return (
    <Card<Client>
      data-testid='ClientCard'
      item={item}
      size={size}
      spacing={spacing}
      border
      ref={isDrag ? drag : undefined}
      style={{ opacity, cursor: isDrag ? 'move' : 'default' }}
      draggable={isDrag}
      {...rest}
    >
      <FlexDiv>
        <div>
          <Avatar
            data-testid='ClientCard-Avatar'
            variant='square-round'
            size='large'
            loading={avatarLoading}
            src={avatar}
            ref={ref}
          />
          <VerticalSpace />
        </div>
        <HorizontalSpace size='0.75' />
        <PileDiv
          style={{ width: 'calc(100% - 120px)', alignItems: size === 'xsmall' ? 'center' : 'flex-start' }}
        >
          <Heading size='small' as='span'>
            <Link
              as={NavLink}
              data-amplitude='client.card.view'
              onClick={(e: any) => eventLogger(e)}
              to={generatePath('/clients/:id', {
                id: item.id.toString()
              })}
            >
              <Highlighter
                caseSensitive={false}
                searchWords={searchWords}
                textToHighlight={item?.name ?? ''}
              />
            </Link>
          </Heading>
          <VerticalSpace size='0.3' />
          <DescriptionListItem title={t('label:location')} details={item.location} />
          <DescriptionListItem
            title={t('label:engagements')}
            details={item.engagements?.map((engagement: Engagement) => (
              <dt key={engagement.id}>
                <Link
                  data-amplitude='client.card.engagement.view'
                  as={NavLink}
                  onClick={(e: any) => eventLogger(e)}
                  to={generatePath('/engagements/:id', {
                    id: engagement.id.toString()
                  })}
                >
                  <Highlighter
                    caseSensitive={false}
                    searchWords={searchWords}
                    textToHighlight={engagement?.title ?? ''}
                  />
                </Link>
              </dt>
            ))}
          />
        </PileDiv>
      </FlexDiv>
    </Card>
  )
}

export default ClientCard
