var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { NavigateBefore as Back } from '@cegal/ds-icons/dist/NavigateBefore';
import { NavigateNext as Next } from '@cegal/ds-icons/dist/NavigateNext';
import { TabsList } from '@radix-ui/react-tabs';
import cl from 'clsx';
import { debounce } from 'lodash';
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { mergeRefs } from 'react-merge-refs';
export const TabList = forwardRef((_a, ref) => {
    var { className } = _a, rest = __rest(_a, ["className"]);
    const listRef = useRef(null);
    const mergedRef = mergeRefs([listRef, ref]);
    const [displayScroll, setDisplayScroll] = useState({
        start: false,
        end: false
    });
    const updateScrollButtonState = useMemo(() => debounce(() => {
        var _a;
        if (!(listRef === null || listRef === void 0 ? void 0 : listRef.current))
            return;
        const { scrollWidth, clientWidth } = listRef === null || listRef === void 0 ? void 0 : listRef.current;
        const scrollLeft = (_a = listRef === null || listRef === void 0 ? void 0 : listRef.current) === null || _a === void 0 ? void 0 : _a.scrollLeft;
        const showStartScroll = scrollLeft > 1;
        const showEndScroll = scrollLeft < scrollWidth - clientWidth - 1;
        setDisplayScroll((displayScroll) => showStartScroll === displayScroll.start && showEndScroll === displayScroll.end
            ? displayScroll
            : { start: showStartScroll, end: showEndScroll });
    }), []);
    useEffect(() => {
        var _a, _b, _c;
        const handleResize = () => updateScrollButtonState();
        const win = (_c = (_b = (_a = listRef.current) === null || _a === void 0 ? void 0 : _a.ownerDocument) !== null && _b !== void 0 ? _b : document) !== null && _c !== void 0 ? _c : window;
        win.addEventListener('resize', handleResize);
        let resizeObserver;
        if (typeof ResizeObserver !== 'undefined') {
            resizeObserver = new ResizeObserver(handleResize);
            resizeObserver.observe(listRef.current);
        }
        return () => {
            win.removeEventListener('resize', handleResize);
            if (resizeObserver) {
                resizeObserver.disconnect();
            }
        };
    }, [updateScrollButtonState]);
    useEffect(() => {
        updateScrollButtonState();
    });
    const ScrollButton = ({ dir, hidden }) => (React.createElement("div", { className: cl('cds-tabs__scroll-button', {
            'cds-tabs__scroll-button--hidden': hidden
        }), onClick: () => {
            if (!listRef.current)
                return;
            listRef.current.scrollLeft += dir * 100;
        } }, dir === -1 ? React.createElement(Back, null) : React.createElement(Next, null)));
    const showSteppers = displayScroll.end || displayScroll.start;
    return (React.createElement("div", { className: 'cds-tabs__tablist-wrapper' },
        showSteppers && React.createElement(ScrollButton, { dir: -1, hidden: !displayScroll.start }),
        React.createElement(TabsList, Object.assign({}, rest, { ref: mergedRef, className: cl('cds-tabs__tablist', className), onScroll: updateScrollButtonState })),
        showSteppers && React.createElement(ScrollButton, { dir: 1, hidden: !displayScroll.end })));
});
TabList.displayName = 'Tab.List';
export default TabList;
