import {
  AlignEndColumn,
  BodyLong,
  BodyShort,
  FlexDiv,
  Heading,
  HorizontalSpace,
  Row,
  VerticalSpace
} from '@cegal/ds-components'
import { ErrorOutline } from '@cegal/ds-icons'
import { Message } from '@cegal/ds-icons/dist/Message'
import { listNews } from 'actions'
import { NEWS_LIST_FAILURE } from 'constants/actionTypes'
import { News } from 'declarations/models'
import { useAppDispatch, useAppSelector } from 'store'
import styled from 'styled-components'

import AlertPanel from 'components/AlertPanel/AlertPanel'
import Widget from 'components/Home/Widget/Widget'
import { StretchLoader } from 'components/Loader/CustomLoader'
import { CardContainer, HomeElementPanel } from 'components/styled'

import { HomePanelProps } from 'pages/Home/Home'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'

const StyledCardPanel = styled(HomeElementPanel)<{
  isImportant?: boolean
}>`
  border-left-color: ${({ isImportant }) =>
    isImportant ? 'var(--cat-news-important-border-color)' : 'none'};
  border-left-width: ${({ isImportant }) => (isImportant ? '8px' : '1px')};
`

const NewsBox = ({ mode }: HomePanelProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const news = useAppSelector((state) => state.news.list)
  const apiReady = useAppSelector((state) => state.app.apiReady)

  useEffect(() => {
    if (news === undefined && apiReady) {
      loadPageData()
    }
  }, [apiReady])

  const loadPageData = () => dispatch(listNews())

  const renderTitle = () => (
    <FlexDiv>
      <Message size='2rem' />
      <HorizontalSpace />
      <Heading>{t('home:news-title')}</Heading>
    </FlexDiv>
  )

  const renderBody = () => (
    <>
      {_.isUndefined(news) && <StretchLoader />}
      {_.isNull(news) && (
        <AlertPanel
          doNotRenderIfHeadIsActiveWithError
          watchFor={[NEWS_LIST_FAILURE]}
          buttonText={t('buttons:click-to-reload-the-page')!}
          reloadPage={loadPageData}
        />
      )}
      {!_.isNil(news) &&
        (!_.isEmpty(news)
          ? news!.map((_news: News) => (
              <StyledCardPanel border key={_news.id} spacing isImportant={_news.important}>
                {_news.important && (
                  <Row>
                    <BodyShort
                      style={{
                        fontWeight: 'var(--cds-cegal-font-body-weight-bold)',
                        color: 'var(--cat-news-important-icon-text-color)'
                      }}
                    >
                      <ErrorOutline size='1.5rem' /> Important!
                    </BodyShort>
                  </Row>
                )}
                <BodyLong size='small'>{_news.name}</BodyLong>
                <Row>
                  <AlignEndColumn>
                    <BodyShort size='small'>~{_news.creator?.name}</BodyShort>
                  </AlignEndColumn>
                </Row>
              </StyledCardPanel>
            ))
          : t('messages:warning-no-news'))}
    </>
  )

  if (mode === 'widget') {
    return <Widget header={renderTitle()}>{renderBody()}</Widget>
  }

  return (
    <>
      {renderTitle()}
      <VerticalSpace />
      <CardContainer>{renderBody()}</CardContainer>
      <VerticalSpace size='2' />
    </>
  )
}

export default NewsBox
