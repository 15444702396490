const AdminSyncings = [
  {
    id: 1,
    source_name: 'CV Partner API',
    status_code: 200,
    synced_date: '2022-10-15',
    entities: [
      {
        entity_id: 1,
        entity_name: 'Consultants',
        status_code: 200,
        total_entities: 800,
        updated_entities: 20,
        new_entities: 5
      },
      {
        entity_id: 2,
        entity_name: 'Skills',
        status_code: 200,
        total_entities: 500,
        updated_entities: 0,
        new_entities: 10
      }
    ]
  },
  {
    id: 2,
    source_name: 'Resource Guru API',
    status_code: 400,
    synced_date: '2023-1-15',
    entities: [
      {
        entity_id: 1,
        entity_name: 'Engagements',
        status_code: 404,
        total_entities: 0,
        updated_entities: 0,
        new_entities: 0
      }
    ]
  }
]
export default AdminSyncings
