import { ToggleGroup } from '@cegal/ds-components'
import { GridView } from '@cegal/ds-icons/dist/GridView'
import { TableRows } from '@cegal/ds-icons/dist/TableRows'
import { ViewComfy } from '@cegal/ds-icons/dist/ViewComfy'

export interface ToggleViewButtonsProps {
  homeView?: boolean
  value: string
  values?: Array<string>
  homeValues?: Array<string>
  onChange: (e: string) => void
}

const ToggleViewButtons = ({
  homeView = false,
  value,
  values = ['card', 'row'],
  homeValues = ['widget', 'tab', 'page'],
  onChange
}: ToggleViewButtonsProps) => (
  <ToggleGroup onChange={onChange} value={value} size='small'>
    {homeView
      ? homeValues.map((v) => (
          <ToggleGroup.Item key={v} value={v}>
            {v === 'page' && <TableRows size='1.6rem' />}
            {v === 'tab' && <ViewComfy size='1.6rem' />}
            {v === 'widget' && <GridView size='1.6rem' />}
          </ToggleGroup.Item>
        ))
      : values.map((v) => (
          <ToggleGroup.Item key={v} value={v}>
            {v === 'row' ? <TableRows size='1.6rem' /> : <GridView size='1.6rem' />}
          </ToggleGroup.Item>
        ))}
  </ToggleGroup>
)

export default ToggleViewButtons
