import { Tag } from '@cegal/ds-components'
import { Engagement } from 'declarations/models'

import { useTranslation } from 'react-i18next'

interface YesOrNoTagProps {
  engagement: Engagement
}

const YesOrNoTag = ({ engagement }: YesOrNoTagProps) => {
  const { t } = useTranslation()
  // If 4 of 6 boolean values from an engagement are true => Tag properties change (it is qualified)
  const changeTagVariant = () => {
    let counter = 0
    if (engagement.energy_domain) {
      counter++
    }
    if (engagement.financially_smart) {
      counter++
    }
    if (engagement.integration) {
      counter++
    }
    if (engagement.relevant_technologies) {
      counter++
    }
    if (engagement.mentoring) {
      counter++
    }
    if (engagement.reusable_database) {
      counter++
    }
    return counter >= 4
  }

  const tagTrue = changeTagVariant()

  return <Tag variant={tagTrue ? 'success' : 'error'}>{tagTrue ? t('label:yes') : t('label:no')}</Tag>
}

export default YesOrNoTag
