import { FlexCenterDiv, HorizontalSpace, PileDiv, Table } from '@cegal/ds-components'
import { EngagementVacancy, EngagementVacancyConsultant } from 'declarations/models'

import { ConsultantSignature } from 'components/Consultants/Consultant'
import { DescriptionSpan } from 'components/DescriptionList/DescriptionList'
import Row, { RowProps } from 'components/Row/Row'
import CertificatesGroup from 'components/TagGroup/CertificatesGroup'
import SkillsGroup from 'components/TagGroup/SkillsGroup'

import Highlighter from 'react-highlight-words'
import { useTranslation } from 'react-i18next'

import useQuery from 'hooks/useQuery'

import { toLocalDateFormat } from 'utils'
import { tokenize } from 'utils/tokenizer'

import _ from 'lodash'

const EngagementVacancyRow = ({
  item,
  added = false,
  edited = false,
  loading = false,
  ...rest
}: RowProps<EngagementVacancy>) => {
  const { t } = useTranslation()
  const { query } = useQuery()
  const searchWords = tokenize(query.search)

  return (
    <Row<EngagementVacancy>
      item={item}
      data-testid='EngagementVacancyRow'
      edited={edited}
      added={added}
      loading={loading}
      {...rest}
    >
      <Table.DataCell>
        <PileDiv>
          <Highlighter caseSensitive={false} searchWords={searchWords} textToHighlight={item?.title ?? ''} />
          <Highlighter
            caseSensitive={false}
            searchWords={searchWords}
            textToHighlight={item?.engagement?.title ?? ''}
          />
        </PileDiv>
      </Table.DataCell>
      <Table.DataCell>{t('label:' + item.allocation)}</Table.DataCell>
      <Table.DataCell>{item.hourly_rate + ' kr'}</Table.DataCell>
      <Table.DataCell>
        {!_.isEmpty(item.skills) ? (
          <SkillsGroup size='small' skills={item.skills} />
        ) : (
          <DescriptionSpan>{t('messages:no-skills')}</DescriptionSpan>
        )}
      </Table.DataCell>
      <Table.DataCell>
        {!_.isEmpty(item.certificates) ? (
          <CertificatesGroup certificates={item.certificates} />
        ) : (
          <DescriptionSpan>{t('messages:no-certificates')}</DescriptionSpan>
        )}
      </Table.DataCell>
      <Table.DataCell>
        <FlexCenterDiv>
          <span>{'' + toLocalDateFormat(item.last_modified_date)}</span>
          {!_.isEmpty(item.last_modifier) && (
            <>
              <HorizontalSpace size='0.5' />
              {t('label:by')}
              <HorizontalSpace size='0.5' />
              <ConsultantSignature
                consultant={item.last_modifier}
                popover={['view', 'email']}
                context={{ engagementVacancy: item }}
              />
            </>
          )}
        </FlexCenterDiv>
      </Table.DataCell>
      <Table.DataCell>
        <>
          {item.consultants?.map((consultant: EngagementVacancyConsultant) => (
            <ConsultantSignature
              key={'' + consultant.consultant?.id}
              // @ts-ignore
              consultant={consultant.consultant}
            />
          )) ?? t('messages:vacancy-has-no-consultants')}
        </>
      </Table.DataCell>
    </Row>
  )
}

export default EngagementVacancyRow
