import { AlignEndColumn, Button, FlexEndDiv, HorizontalSpace, Row, VerticalSpace } from '@cegal/ds-components'
import { Settings } from '@cegal/ds-icons/dist/Settings'
import { setWidgetTable } from 'actions'
import { DisplaySize, HomeMode, WidgetTable } from 'declarations/app'
import { eventLogger } from 'metrics/loggers'
import { useAppDispatch, useAppSelector } from 'store'

import ToggleView from 'components/Forms/ToggleView'
import WidgetManagerModal from 'components/Home/WidgetManagerModal/WidgetManagerModal'
import PageContent from 'components/PageContent/PageContent'
import { NoWrapSpan } from 'components/styled'

import { useEffect, useState } from 'react'

import { allWidgets, initialWidgetTable } from 'utils/widgets'

import { t } from 'i18next'

import { HomePage, HomeTab, HomeWidget } from '.'

export interface HomeProps {
  widgetTable: WidgetTable
}

export interface HomePanelProps {
  mode: HomeMode
}

const Home = () => {
  const dispatch = useAppDispatch()
  const widgetTable = useAppSelector((state) => state.app.widgetTable)
  const homeMode = useAppSelector((state) => state.app.settings.homeMode)
  const displaySize = useAppSelector((state) => state.app.displaySize)

  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [_homeMode, setHomeMode] = useState<HomeMode>(homeMode)

  const setOrientationAndViewMode = (displaySize: DisplaySize) => {
    if (displaySize === 'sm' || displaySize === 'md') {
      setHomeMode('widget')
    } else {
      setHomeMode(homeMode)
    }
  }

  const writeWidgetTable = (newWidgetTable: WidgetTable) => {
    dispatch(setWidgetTable(newWidgetTable))
  }

  useEffect(() => setHomeMode(homeMode), [homeMode])

  useEffect(() => setOrientationAndViewMode(displaySize), [displaySize])

  return (
    <PageContent maxWidth='1200px'>
      <WidgetManagerModal
        writeWidgetTable={writeWidgetTable}
        modalOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        widgets={allWidgets}
        widgetTable={widgetTable}
        initialWidgetTable={initialWidgetTable}
      />
      <Row>
        <AlignEndColumn>
          <FlexEndDiv
            style={{
              justifyContent: 'flex-end'
            }}
          >
            <Button
              variant='secondary'
              disabled={_homeMode !== 'widget'}
              data-amplitude='home.homepage.edit'
              icon={<Settings size='1.5rem' />}
              onClick={(e: any) => {
                eventLogger(e)
                setModalOpen(true)
              }}
            >
              <NoWrapSpan>{t('buttons:edit-x', { x: t('home:homepage').toLowerCase() })}</NoWrapSpan>
            </Button>
            <HorizontalSpace />
            <ToggleView
              context='home'
              viewToggleModeHome
              viewToggleMode={!(displaySize === 'sm' || displaySize === 'md')}
            />
          </FlexEndDiv>
        </AlignEndColumn>
      </Row>
      <VerticalSpace />
      {_homeMode === 'widget' && <HomeWidget widgetTable={widgetTable} />}
      {_homeMode === 'tab' && <HomeTab widgetTable={widgetTable} />}
      {_homeMode === 'page' && <HomePage widgetTable={widgetTable} />}
    </PageContent>
  )
}

export default Home
