import { Button, Table } from '@cegal/ds-components'
import { PlaylistAddCheck as NewEngagementIcon } from '@cegal/ds-icons/dist/PlaylistAddCheck'
import { deleteFollowedClient, newFollowedClient } from 'actions'
import { ViewMode } from 'declarations/app'
import { Client, ClientsQuery } from 'declarations/models'
import { standardLogger } from 'metrics/loggers'
import { useAppDispatch, useAppSelector } from 'store'

import ClientRow from 'components/Clients/Client/ClientRow'
import { EditButton } from 'components/Forms/Buttons'
import FollowButton from 'components/Forms/Buttons/FollowButton'
import { CardContainer } from 'components/styled'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import ClientCard from './ClientCard'

import _ from 'lodash'

interface ClientTableHeader {
  label: string
  sortable?: boolean
  sortKey?: string
  key: string
}

export interface ClientsCardViewProps {
  clients: Array<Client>
  query?: Partial<ClientsQuery>
  onSortChange?: (sortKey?: string | undefined) => void
  viewMode: ViewMode
  buttons: Array<string>
  onEditClient?: (client: Client) => void
  onDeleteClient?: (client: Client) => void
}

const ClientsView = ({
  clients,
  viewMode,
  query,
  buttons,
  onSortChange,
  onEditClient
}: ClientsCardViewProps) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const creatingFollow = useAppSelector((state) => state.followedClients.creating)
  const deletingFollow = useAppSelector((state) => state.followedClients.deleting)
  const followedClients = useAppSelector((state) => state.followedClients.list)

  const headers: Array<ClientTableHeader> = [
    { label: '', key: 'logo' },
    { label: t('label:client-title'), sortKey: 'name', key: 'client' },
    {
      label: t('label:engagement-title'),
      key: 'engagement'
    },
    { label: t('label:location-title'), key: 'location' },
    { label: '', key: 'action' }
  ]

  const handleClientView = (client: Client) => {
    standardLogger('client.' + viewMode + '.view')
    navigate('/clients/' + client.id)
  }

  const handleClientFollow = (engagement: Client) => {
    standardLogger('client.' + viewMode + '.follow')
    dispatch(newFollowedClient(engagement))
  }

  const handleClientUnfollow = (engagement: Client) => {
    standardLogger('client.' + viewMode + '.unfollow')
    dispatch(deleteFollowedClient(engagement))
  }

  const navigateToNewEngagement = (client: Client) => {
    standardLogger('client.' + viewMode + '.engagement.new')
    navigate('/engagements/new?clientId=' + client.id + '&clientName=' + encodeURIComponent(client.name))
  }

  const getButtons = (client: Client): Array<React.ReactNode> | undefined => {
    return buttons?.map((button) => {
      switch (button) {
        case 'follow': {
          const creating: boolean = _.find(creatingFollow, { id: client.id }) !== undefined
          const deleting: boolean = _.find(deletingFollow, { id: client.id }) !== undefined

          return (
            <FollowButton<Client>
              item={client}
              key={'client-' + client.id + '-follow-button'}
              followedItems={followedClients}
              onFollow={handleClientFollow}
              onUnfollow={handleClientUnfollow}
              loading={creating || deleting}
              disabled={creating || deleting}
            />
          )
        }
        case 'newengagement': {
          return (
            <Button
              size='small'
              variant='secondary'
              style={{ marginBottom: '0.25rem' }}
              key={'client-' + client.id + '-add-button'}
              icon={<NewEngagementIcon size='1.25rem' />}
              onClick={() => navigateToNewEngagement(client)}
            >
              {t('buttons:create-x', {
                x: t('label:engagement-title').toLowerCase()
              })}
            </Button>
          )
        }
        case 'edit':
          return (
            <EditButton
              size='small'
              style={{ marginBottom: '0.25rem' }}
              key={'client-' + client.id + '-edit-button'}
              onEdit={() => onEditClient?.(client)}
            />
          )
        default:
          return null
      }
    })
  }

  return (
    <>
      {viewMode === 'row' && (
        <Table style={{ width: '100%' }} sort={query?.sort} onSortChange={onSortChange}>
          <Table.Header>
            <Table.Row>
              {headers.map((header) => (
                <Table.ColumnHeader
                  key={header.key + '-clients-table-header'}
                  sortable={!!header.sortKey}
                  sortKey={header.sortKey}
                >
                  {t(header.label)}
                </Table.ColumnHeader>
              ))}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {clients?.map((client) => (
              <ClientRow
                key={`${client.id}-client-table-row`}
                item={client}
                onView={handleClientView}
                buttons={getButtons(client)}
              />
            ))}
          </Table.Body>
        </Table>
      )}
      {viewMode === 'card' && (
        <CardContainer>
          {clients?.map((client) => (
            <ClientCard
              spacing
              key={client.id}
              item={client}
              buttons={getButtons(client)}
              onView={handleClientView}
            />
          ))}
        </CardContainer>
      )}
    </>
  )
}

export default ClientsView
