import { Button, ButtonSize } from '@cegal/ds-components'
import { ModeEdit as ModeEditIcon } from '@cegal/ds-icons/dist/ModeEdit'

import { useTranslation } from 'react-i18next'

interface EditButtonProps {
  size?: ButtonSize
  text?: string
  showText?: boolean
  title?: string
  label?: string
  loading?: boolean
  disabled?: boolean
  onEdit: (e: any) => void
  style?: any
}

const EditButton = ({
  size = 'small',
  text = undefined,
  disabled = false,
  showText = true,
  onEdit,
  title,
  ...rest
}: EditButtonProps) => {
  const { t } = useTranslation()

  return (
    <Button
      spacing
      disabled={disabled}
      variant='secondary'
      size={size}
      title={title ?? t('buttons:edit')}
      icon={<ModeEditIcon size='1.25rem' />}
      onClick={onEdit}
      {...rest}
    >
      {showText ? text ?? t('buttons:edit') : null}
    </Button>
  )
}

export default EditButton
