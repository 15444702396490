import { AlignCenterRow, Button, Column } from '@cegal/ds-components/dist'
import { ChevronLeft, ChevronRight } from '@cegal/ds-icons'
import { EngagementVacancy } from 'declarations/models'
import { useAppSelector } from 'store'
import styled from 'styled-components'

import EngagementVacancyCard from 'components/Engagements/Engagement/Vacancy/EngagementVacancyCard'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'

const CarouselBody = styled(Column)`
  overflow-x: auto;
  white-space: nowrap;
  flex-direction: row;
  width: 5rem;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`
const CarouselItem = styled.button.withConfig({
  shouldForwardProp: (prop) => !['isActiveItem'].includes(prop as string)
})<{ isActiveItem: boolean }>`
  background: none;
  min-width: 21rem;
  display: inline-block;
  margin: 0 0.5rem;
  margin-bottom: 1rem;
  padding: 0;
  cursor: pointer;
  border: ${({ isActiveItem }) => (isActiveItem ? '3px solid var(--cds-text-color-interactive)' : '0')};
`

const CarouselButton = styled(Button)`
  aspect-ratio: 1/1;
  padding: var(--cds-size-0-5);
`

interface VacanciesCarouselProps {
  vacancies: EngagementVacancy[]
  size: 'medium' | 'small'
  selectedVacancyId: number | undefined
  onChange: (vacancyId: number) => void
}

const VacanciesCarousel = ({
  vacancies,
  size = 'medium',
  selectedVacancyId,
  onChange
}: VacanciesCarouselProps) => {
  const { t } = useTranslation()

  const displaySize = useAppSelector((state) => state.app.displaySize)

  const [activeItem, setActiveItem] = useState<number | undefined>(
    _.findIndex(vacancies, { id: selectedVacancyId })
  ) // index of selected vacancy

  useEffect(() => {
    setActiveItem(_.findIndex(vacancies, { id: selectedVacancyId }))
  }, [selectedVacancyId])

  const handleChangeActiveItem = (increment?: boolean, index?: number) => {
    const newActive = index !== undefined ? index : increment ? activeItem! + 1 : activeItem! - 1
    onChange(vacancies[newActive].id)
    setActiveItem(newActive)

    const el = document.getElementById(`carousel-item-${vacancies[newActive].id}`)
    if (el) el.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
  }

  return (
    <AlignCenterRow style={{ transition: 'all 0.5s' }}>
      {displaySize !== 'sm' && displaySize !== 'md' && (
        <CarouselButton
          size='xsmall'
          variant='secondary'
          disabled={activeItem === undefined || activeItem <= 0}
          onClick={() => handleChangeActiveItem(false)}
          aria-label={t('label:previous-vacancy')}
        >
          <ChevronLeft size='2rem' />
        </CarouselButton>
      )}
      <CarouselBody>
        {vacancies?.map((vacancy, index) => (
          <CarouselItem
            key={vacancy.id + '-' + vacancy.consultants?.map((c) => c.consultant?.id).join(',')}
            id={`carousel-item-${vacancy.id}`}
            aria-label={`${t('label:match-to-vacancy')}${vacancy.title}`}
            isActiveItem={activeItem !== undefined && index === activeItem}
            onClick={() => handleChangeActiveItem(undefined, index)}
          >
            <EngagementVacancyCard size={size} item={vacancy} />
          </CarouselItem>
        ))}
      </CarouselBody>
      {displaySize !== 'sm' && displaySize !== 'md' && (
        <CarouselButton
          size='xsmall'
          variant='secondary'
          disabled={activeItem === undefined || activeItem >= vacancies.length - 1}
          onClick={() => handleChangeActiveItem(true)}
          aria-label={t('label:next-vacancy')}
        >
          <ChevronRight size='2rem' />
        </CarouselButton>
      )}
    </AlignCenterRow>
  )
}

export default VacanciesCarousel
