import React, { createContext, forwardRef, useState } from 'react';
import { Button } from 'components/button';
import { ChevronLeft } from '@cegal/ds-icons/dist/ChevronLeft';
import { ChevronRight } from '@cegal/ds-icons/dist/ChevronRight';
import Item from 'components/carousel/Item';
import CarouselIndicators from 'components/carousel/CarouselIndicator';
import clsx from 'clsx';
export const CarouselContext = createContext(null);
const CarouselFC = ({ children, border, showIndicators, activeIndex = 0, onChange }) => {
    const [_activeIndex, _setActiveIndex] = useState(activeIndex);
    const handleValueChange = (v) => {
        _setActiveIndex(v);
        onChange === null || onChange === void 0 ? void 0 : onChange(v);
    };
    return (React.createElement("div", { id: 'myCarousel', className: clsx('cds-carousel', { 'cds-carousel--border': border }) },
        React.createElement(CarouselContext.Provider, { value: {
                activeIndex: _activeIndex,
                onValueChange: handleValueChange
            } },
            React.createElement("div", { className: 'cds-carousel_content', style: { transform: `translateX(-${_activeIndex * 100}%)` } }, children),
            showIndicators && React.createElement(CarouselIndicators, null, children)),
        React.createElement("div", { className: 'cds-carousel--buttons' },
            React.createElement(Button, { size: 'small', variant: 'tertiary', disabled: _activeIndex === 0, icon: React.createElement(ChevronLeft, { size: '1rem' }), onClick: () => _setActiveIndex(_activeIndex - 1) }),
            React.createElement(Button, { size: 'small', variant: 'tertiary', disabled: Array.isArray(children) && _activeIndex === children.length - 1, icon: React.createElement(ChevronRight, { size: '1rem' }), onClick: () => _setActiveIndex(_activeIndex + 1) }))));
};
const Carousel = forwardRef(CarouselFC);
Carousel.Item = Item;
export default Carousel;
export { Item };
