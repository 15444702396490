var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import bytes from 'pretty-bytes';
import cl from 'clsx';
import _ from 'lodash';
import React, { forwardRef, useState } from 'react';
import { ErrorCode, useDropzone } from 'react-dropzone';
import defaultLabels from './Dropzone.labels';
const Dropzone = forwardRef((_a, ref) => {
    var _b;
    var { accept, className, labels, maxFiles, maxFileSize, messages = true, multiple = true, onDrop = () => { } } = _a, rest = __rest(_a, ["accept", "className", "labels", "maxFiles", "maxFileSize", "messages", "multiple", "onDrop"]);
    const _labels = Object.assign(Object.assign({}, defaultLabels), labels);
    const [_status, setStatus] = useState({});
    const _onDrop = (acceptedFiles, rejectedFiles) => __awaiter(void 0, void 0, void 0, function* () {
        const filePromises = acceptedFiles.map((file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onabort = (error) => reject(error);
                reader.onerror = (error) => reject(error);
                reader.onload = () => __awaiter(void 0, void 0, void 0, function* () {
                    resolve({
                        size: file.size,
                        name: file.name,
                        mimetype: file.type,
                        lastModified: file.lastModified,
                        content: reader.result
                    });
                });
                reader.readAsDataURL(file);
            });
        });
        const newFiles = yield Promise.all(filePromises);
        let hasError = false;
        const messages = [];
        rejectedFiles.forEach(({ file, errors }) => {
            const m = [file.name];
            errors.forEach((e) => {
                if (e.code === ErrorCode.FileInvalidType) {
                    m.push(renderPlaceholders(_labels.invalidFileType, {
                        filFormat: accept
                            ? Object.keys(accept)
                                .map((type) => {
                                return type.substring(type.indexOf('/') + 1, type.length).toUpperCase();
                            })
                                .join(', ')
                            : _labels.all
                    }));
                }
                if (e.code === ErrorCode.FileTooLarge) {
                    m.push(renderPlaceholders(_labels.fileIsTooBigLimitIs, {
                        maxFileSize: bytes(maxFileSize),
                        size: bytes(file.size)
                    }));
                }
                if (e.code === ErrorCode.TooManyFiles) {
                    m.push(renderPlaceholders(_labels.maxFilesExceeded, {
                        maxFiles,
                        file: maxFiles === 1 ? _labels.file : _labels.files
                    }));
                }
            });
            messages.push(m.join(' - '));
        });
        if (!_.isEmpty(messages)) {
            setStatus({
                messages,
                type: 'error'
            });
            hasError = true;
        }
        if (!hasError) {
            let statusMessage = _labels.accepted + ': ' + acceptedFiles.length + ', ';
            statusMessage += _labels.rejected + ': ' + rejectedFiles.length + ', ';
            statusMessage += _labels.total + ': ' + (acceptedFiles.length + rejectedFiles.length);
            setStatus({
                messages: [statusMessage],
                type: 'ok'
            });
        }
        onDrop === null || onDrop === void 0 ? void 0 : onDrop(newFiles, acceptedFiles, rejectedFiles);
    });
    const renderPlaceholders = (template, values) => {
        template = template.replace(/\{\{([^}]+)\}\}/g, (match) => {
            match = match.slice(2, -2);
            return values[match] || '{{' + match + '}}';
        });
        return template;
    };
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: _onDrop,
        accept,
        maxFiles,
        multiple,
        maxSize: maxFileSize
    });
    const message = [];
    !!accept &&
        message.push(renderPlaceholders(_labels.allowedFileFormat, {
            filFormat: accept
                ? Object.keys(accept)
                    .map((type) => {
                    return type.substring(type.indexOf('/') + 1, type.length).toUpperCase();
                })
                    .join(', ')
                : _labels.all
        }));
    maxFiles &&
        multiple !== false &&
        message.push(renderPlaceholders(_labels.maxFiles, {
            maxFiles,
            file: maxFiles === 1 ? _labels.file : _labels.files
        }));
    multiple === false && message.push(_labels.singleFile);
    multiple === true && message.push(_labels.multipleFiles);
    maxFileSize &&
        message.push(renderPlaceholders(_labels.maxFileSize, {
            maxFileSize: bytes(maxFileSize)
        }));
    return (React.createElement("div", Object.assign({}, rest, { ref: ref, className: cl('cds--dropzone', {
            'cds--dropzone-active': isDragActive
        }, className) }, getRootProps()),
        React.createElement("input", Object.assign({}, getInputProps())),
        React.createElement("div", { className: 'cds--dropzone-placeholder' },
            React.createElement("div", { className: 'cds--dropzone-message' }, _labels.dropFilesHere),
            messages && (React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'cds--dropzone-message' }, message.join(', ')), (_b = _status.messages) === null || _b === void 0 ? void 0 :
                _b.map((message) => (React.createElement("div", { key: message, className: cl('cds--dropzone-message-' + _status.type) }, message))))))));
});
Dropzone.displayName = 'Dropzone';
export default Dropzone;
