import { initReactI18next } from 'react-i18next'

import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false
  }
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      lng: 'en',
      backend: {
        // translation file path
        loadPath: '/locales/{{lng}}/{{ns}}.json'
      },
      fallbackLng: 'en',
      debug: false,
      ns: ['buttons', 'label', 'header', 'home', 'messages', 'validation', 'body'],
      defaultNS: 'label',
      interpolation: {
        escapeValue: false,
        formatSeparator: ','
      },
      returnNull: false,
      react: { useSuspense: true }
    },
    (error) => {
      if (error) {
        return console.log('Loading i18n error', error)
      }
    }
  )
i18n.loadLanguages(['en'], () => {})
i18n.language = 'en'
document.documentElement.lang = 'en'

export default i18n
