import { FlexDiv, HorizontalSpace, Label, PileDiv, VerticalSpace } from '@cegal/ds-components'
import { Widget, WidgetTable } from 'declarations/app'

import DraggableWidget from 'components/Home/WidgetManagerModal/DraggableWidget'

import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'

export interface WidgetTableSorterProps {
  widgetTable: WidgetTable
  widgets: Array<Widget>
  onWidgetTableChange: (newWidgetTable: WidgetTable) => void
}

const move = (currentWidgetTable: WidgetTable, dropResult: DropResult): WidgetTable => {
  const { source, destination } = dropResult
  const isReordering = source.droppableId === destination?.droppableId

  const sourceArray = currentWidgetTable[source.droppableId as keyof WidgetTable] || []
  const destinationArray = currentWidgetTable[destination?.droppableId as keyof WidgetTable] || []

  const sourceClone = _.cloneDeep(sourceArray)
  const destClone = !isReordering ? _.cloneDeep(destinationArray) : []

  // Mutate source array bu removing needed element to consume it later
  const [removed] = sourceClone.splice(source.index, 1)

  // Only do if destination index is number
  typeof destination?.index === 'number' &&
    // If we are reordering just mutate the sourceClone array
    (isReordering ? sourceClone : destClone).splice(destination.index, 0, removed)

  return {
    ...currentWidgetTable,
    [source.droppableId]: sourceClone,
    // Only add to the return if droppableID is available and if we are not reordering
    ...(destination?.droppableId && !isReordering && { [destination?.droppableId]: destClone })
  }
}

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver
    ? 'var(--cds-background-color-alternative-light-opaque)'
    : 'var(--cds-background-color-inverted-light-opaque)',
  padding: '0.75rem',
  flex: 1,
  minHeight: '90%',
  overflow: 'hidden'
})

const WidgetManager = ({ widgetTable, widgets, onWidgetTableChange }: WidgetTableSorterProps) => {
  const { t } = useTranslation()

  const parseWidgetTable = (widgetTable: WidgetTable) => {
    const _left: Array<Widget> = widgetTable.left
    const _right: Array<Widget> = widgetTable.right
    const _invisible: Array<Widget> = _.reject(widgets, (w: Widget) => {
      return _left.some(({ id }) => w.id === id) || _right.some(({ id }) => w.id === id)
    })
    _invisible.sort((a, b) => a.id.localeCompare(b.id))

    return { left: _left, right: _right, available: _invisible }
  }

  const widgetTableData = parseWidgetTable(widgetTable)

  const onDragEnd = (dropResult: DropResult) => {
    if (!dropResult.destination) {
      return
    }
    onWidgetTableChange(move(widgetTableData, dropResult))
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <FlexDiv>
        <PileDiv flex='1'>
          <Label>{t('header:left')}</Label>
          <VerticalSpace />
          <Droppable key='LEFT' droppableId='left'>
            {(provided: any, snapshot: any) => (
              <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                {widgetTableData?.left.map((widget, index) => (
                  <DraggableWidget key={widget.id} widget={widget} index={index} />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </PileDiv>
        <PileDiv flex='1'>
          <Label>{t('header:right')}</Label>
          <VerticalSpace />
          <Droppable key='RIGHT' droppableId='right'>
            {(provided: any, snapshot: any) => (
              <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                {widgetTableData.right.map((widget, index) => (
                  <DraggableWidget key={widget.id} widget={widget} index={index} />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </PileDiv>
        <HorizontalSpace />
        <PileDiv flex='1'>
          <Label>{t('header:available')}</Label>
          <VerticalSpace />
          <Droppable key='AVAILABLE' droppableId='available'>
            {(provided: any, snapshot: any) => (
              <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                {widgetTableData?.available?.map((widget, index) => (
                  <DraggableWidget key={widget.id} widget={widget} index={index} />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </PileDiv>
      </FlexDiv>
    </DragDropContext>
  )
}

export default WidgetManager
