import {
  BodyShort,
  Column,
  Label,
  Row,
  Switch,
  Textarea,
  ToggleGroup,
  VerticalSpace
} from '@cegal/ds-components'

import { EngagementStepFormProps } from 'components/Engagements/Engagement/Form/EngagementForm'

import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const Step4 = (props: EngagementStepFormProps) => {
  const { t } = useTranslation()
  const {
    register,
    control,
    formState: { errors }
  } = useFormContext()
  return (
    <>
      <Row>
        <Column>
          <Label>{t('label:progress-title')}</Label>
          <VerticalSpace size='0.3' />
          <BodyShort size='small'>{t('label:progress-description')}</BodyShort>
          <VerticalSpace size='0.5' />
          <Controller
            control={control}
            name='status'
            render={({ field: { onChange, value, ref } }) => (
              <ToggleGroup
                size='small'
                ref={ref}
                value={value}
                defaultValue='readytostart'
                onChange={onChange}
              >
                <ToggleGroup.Item value='onhold'>{t('label:progress-option-onhold')}</ToggleGroup.Item>
                <ToggleGroup.Item value='readytostart'>
                  {t('label:progress-option-readytostart')}
                </ToggleGroup.Item>
                <ToggleGroup.Item value='inprogress'>
                  {t('label:progress-option-inprogress')}
                </ToggleGroup.Item>
                <ToggleGroup.Item value='waiting'>{t('label:progress-option-waiting')}</ToggleGroup.Item>
                <ToggleGroup.Item value='finished'>{t('label:progress-option-finished')}</ToggleGroup.Item>
              </ToggleGroup>
            )}
          />
          <VerticalSpace />
        </Column>
      </Row>
      <VerticalSpace />
      <Label>{t('label:strategic-areas')}</Label>
      <Row>
        <Column>
          <Switch position='left' {...register('energy_domain')}>
            {t('label:domain-energy')}
          </Switch>
          <Switch position='left' {...register('integration')}>
            {t('label:domain-integration')}
          </Switch>
          <Switch position='left' {...register('reusable_database')}>
            {t('label:domain-reusable-assets')}
          </Switch>
        </Column>

        <Column>
          <Switch position='left' {...register('relevant_technologies')}>
            {t('label:domain-relevant-technologies')}
          </Switch>
          <Switch position='left' {...register('mentoring')}>
            {t('label:domain-mentoring')}
          </Switch>
          <Switch position='left' {...register('financially_smart')}>
            {t('label:domain-financially-smart')}
          </Switch>
        </Column>
        <Column />
      </Row>
      <VerticalSpace />
      <Row>
        <Column flex='3'>
          <Textarea
            resize
            label={t('label:comment-title')}
            description={t('label:comment-description')}
            {...register('notes')}
            error={errors?.notes?.message?.toString()}
          />
          <VerticalSpace />
        </Column>
        <Column />
      </Row>
    </>
  )
}

export default Step4
