import {
  Avatar,
  FlexDiv,
  Heading,
  HorizontalSpace,
  Link,
  Loader,
  PileDiv,
  VerticalSpace
} from '@cegal/ds-components'
import UnknownAvatar from 'assets/images/unknownAvatar.png'
import { Consultant, EngagementVacancyConsultant } from 'declarations/models'
import { eventLogger } from 'metrics/loggers'

import Card, { CardProps } from 'components/Card/Card'
import ConsultantAllocationChart from 'components/Consultants/Consultant/Allocation/ConsultantAllocationChart'
import { DataAlign, DescriptionList, DescriptionListItem } from 'components/DescriptionList/DescriptionList'
import { NoWrapSpan } from 'components/styled'

import { useTranslation } from 'react-i18next'
import { NavLink, generatePath } from 'react-router-dom'

import useConsultantAllocation from 'hooks/useConsultantAllocation'
import useConsultantAvatar from 'hooks/useConsultantAvatar'

import { dateToPeriodFormat } from 'utils'

import _ from 'lodash'

type EngagementVacancyConsultantCardPreviewProps = {
  children: React.ReactNode
  item: EngagementVacancyConsultant
}

const EngagementVacancyConsultantCardPreview = ({
  children,
  item
}: EngagementVacancyConsultantCardPreviewProps) => {
  return _.isNumber(item?.consultant?.id) ? (
    <Link
      as={NavLink}
      to={generatePath('/consultants/:id', {
        id: item!.consultant!.id!.toString()
      })}
    >
      {children}
    </Link>
  ) : (
    children
  )
}

const EngagementVacancyConsultantCard = ({
  item,
  size = 'medium',
  spacing = false,
  added = false,
  border = true,
  edited = false,
  loading = false,
  ...rest
}: CardProps<EngagementVacancyConsultant>) => {
  const { t } = useTranslation()
  const { avatar, avatarLoading, ref } = useConsultantAvatar({ id: item?.consultant?.id })
  const {
    allocation,
    allocationLoading,
    ref: allocationRef
  } = useConsultantAllocation({
    consultant: item?.consultant as Consultant
  })

  return (
    <Card<EngagementVacancyConsultant>
      item={item}
      data-testid='EngagementVacancyConsultantCard'
      size={size}
      spacing={spacing}
      edited={edited}
      added={added}
      loading={loading}
      border={border}
      deleteTooltip='Delete this match'
      deleteMessage={t('messages:confirm-delete-match').toString()}
      editTooltip='Edit this match'
      {...rest}
    >
      <FlexDiv style={{ flexWrap: 'nowrap' }}>
        <div ref={allocationRef}>
          <EngagementVacancyConsultantCardPreview item={item}>
            <Avatar
              defaultSrc={UnknownAvatar}
              variant='square-round'
              size='medium'
              src={avatar}
              loading={avatarLoading}
              ref={ref}
            />
          </EngagementVacancyConsultantCardPreview>
          <VerticalSpace />
        </div>
        <HorizontalSpace size='0.75' />
        <PileDiv>
          <Heading level='3' size='xsmall' spacing>
            <EngagementVacancyConsultantCardPreview item={item}>
              {item?.consultant?.name}
            </EngagementVacancyConsultantCardPreview>
          </Heading>

          {!_.isEmpty((item as EngagementVacancyConsultant).engagement_vacancy) && (
            <DescriptionList $alignment={DataAlign.Column}>
              <DescriptionListItem
                title={t('label:vacancy-title')}
                details={(item as EngagementVacancyConsultant).engagement_vacancy!.title}
              />
            </DescriptionList>
          )}
          {!_.isEmpty((item as EngagementVacancyConsultant).engagement_vacancy?.engagement) && (
            <DescriptionList $alignment={DataAlign.Column}>
              <DescriptionListItem
                title={t('label:engagement')}
                details={
                  <Link
                    data-amplitude='evc.card.engagement'
                    as={NavLink}
                    to={
                      '/engagements/' +
                      (item as EngagementVacancyConsultant).engagement_vacancy!.engagement!.id
                    }
                    onClick={(e: any) => eventLogger(e)}
                  >
                    {(item as EngagementVacancyConsultant).engagement_vacancy!.engagement!.title}
                  </Link>
                }
              />
            </DescriptionList>
          )}
          <FlexDiv style={{ width: '100%' }}>
            <PileDiv flex='1'>
              <DescriptionListItem
                title={t('label:allocation')}
                details={
                  allocationLoading ? (
                    <Loader size='small' />
                  ) : (
                    <ConsultantAllocationChart
                      chartLabel={t('label:allocation-chart')}
                      // @ts-ignore
                      consultant={item.consultant!}
                      allocation={allocation}
                    />
                  )
                }
              />
            </PileDiv>
            <HorizontalSpace size='0.5' />
            <PileDiv flex='1'>
              <DescriptionListItem
                title={<NoWrapSpan>{t('label:hourly-rate')}</NoWrapSpan>}
                details={<NoWrapSpan>{item?.hourly_rate + ' kr'}</NoWrapSpan>}
              />
            </PileDiv>
          </FlexDiv>
          <FlexDiv style={{ width: '100%' }}>
            <PileDiv flex='1'>
              <DescriptionListItem
                data-testid='EngagementVacancyConsultantCard-Status'
                title={t('label:status-title')}
                details={t('label:' + item?.status)}
              />
            </PileDiv>
            <HorizontalSpace size='0.5' />

            <PileDiv flex='1'>
              <DescriptionListItem
                title={t('label:dates-title')}
                details={
                  <NoWrapSpan>{dateToPeriodFormat(item.start_date, item.end_date, undefined, t)}</NoWrapSpan>
                }
              />
            </PileDiv>
          </FlexDiv>
        </PileDiv>
      </FlexDiv>
    </Card>
  )
}

export default EngagementVacancyConsultantCard
