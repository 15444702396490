import {
  AlignEndColumn,
  Button,
  Column,
  FlexDiv,
  Heading,
  HorizontalSpace,
  Modal,
  PileDiv,
  Row,
  VerticalSpace
} from '@cegal/ds-components'
import { Widget, WidgetTable } from 'declarations/app'
import { eventLogger } from 'metrics/loggers'

import WidgetManager from 'components/Home/WidgetManagerModal/WidgetManager'

import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface WidgetManagerModalProps {
  widgetTable: WidgetTable
  writeWidgetTable: (widgetTable: WidgetTable) => void
  modalOpen: boolean
  onClose: () => void
  widgets: Array<Widget>
  initialWidgetTable: WidgetTable
}
const WidgetManagerModal = ({
  widgetTable,
  writeWidgetTable,
  modalOpen,
  onClose,
  widgets,
  initialWidgetTable
}: WidgetManagerModalProps) => {
  const { t } = useTranslation()
  const modalContainerRef = useRef(null)

  const [tempWidgetTable, setTempWidgetTable] = useState<WidgetTable>(widgetTable)

  useEffect(() => setTempWidgetTable(widgetTable), [widgetTable])

  const onWidgetTableChange = (newWidgetTable: WidgetTable) => {
    setTempWidgetTable(newWidgetTable)
  }

  const handleReset = (e: any) => {
    if (window.confirm(t('messages:confirm-reset')!)) {
      eventLogger(e)
      writeWidgetTable(initialWidgetTable)
      onClose()
    }
  }

  const handleCancel = (e: any) => {
    eventLogger(e)
    setTempWidgetTable(widgetTable)
    onClose()
  }

  const handleSave = (e: any) => {
    eventLogger(e)
    onSave()
    onClose()
  }

  const onSave = () => {
    writeWidgetTable(tempWidgetTable)
  }

  return (
    <div ref={modalContainerRef}>
      {modalContainerRef?.current && (
        <Modal
          parentSelector={() => modalContainerRef.current!}
          open={modalOpen}
          onClose={onClose}
          closeButton={false}
          shouldCloseOnOverlayClick={false}
        >
          <Modal.Content>
            <div style={{ minWidth: '50vw', padding: '1rem', maxHeight: '90vh' }}>
              <PileDiv>
                <Heading level='3' size='small' spacing>
                  {t('header:customize-homepage')}
                </Heading>
                <div style={{ overflowY: 'auto', maxHeight: '60vh' }}>
                  <WidgetManager
                    widgetTable={tempWidgetTable}
                    widgets={widgets}
                    onWidgetTableChange={onWidgetTableChange}
                  />
                </div>
              </PileDiv>
              <VerticalSpace />
              <Row>
                <Column style={{ alignItems: 'flex-start' }}>
                  <Button data-amplitude='home.homepage.reset' variant='tertiary' onClick={handleReset}>
                    {t('buttons:reset-x', {
                      x: t('label:homepage').toLowerCase()
                    })}
                  </Button>
                </Column>
                <AlignEndColumn style={{ alignItems: 'flex-end' }}>
                  <FlexDiv>
                    <Button data-amplitude='home.homepage.cancel' variant='secondary' onClick={handleCancel}>
                      {t('buttons:cancel')}
                    </Button>
                    <HorizontalSpace />
                    <Button data-amplitude='home.homepage.save' onClick={handleSave}>
                      {t('buttons:save')}
                    </Button>
                  </FlexDiv>
                </AlignEndColumn>
              </Row>
            </div>
          </Modal.Content>
        </Modal>
      )}
    </div>
  )
}

export default WidgetManagerModal
