export const scoreMatch = (name: string, token: string): number => {
  let score = 0
  const res = name.match(token)
  if (res && res.length > 0) {
    // full match
    if (res[0].length === token.length) {
      score += 2
    } else {
      // not full match but starts with
      if (token.startsWith(res[0])) {
        score += 1.5 * (res[0].length / token.length)
      } else {
        score += res[0].length / token.length
      }
    }
  }
  return score
}
