import React, { forwardRef, useContext } from 'react';
import { CarouselContext } from 'components/carousel/Carousel';
const Item = forwardRef(({ children }, ref) => {
    const mainContext = useContext(CarouselContext);
    if (mainContext === null) {
        console.error('<Carousel.Item> has to be used within an <Carousel>');
        return null;
    }
    return (React.createElement("div", { className: 'cds-carousel_item', ref: ref },
        React.createElement("div", { style: { width: '100%' } }, children)));
});
export default Item;
