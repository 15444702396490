import { VerticalSpace } from '@cegal/ds-components'

import EngagementForm from 'components/Engagements/Engagement/Form/EngagementForm'
import PageContent from 'components/PageContent/PageContent'

const EngagementNew = () => {
  return (
    <PageContent maxWidth='1000px'>
      <VerticalSpace />
      <EngagementForm />
    </PageContent>
  )
}

export default EngagementNew
