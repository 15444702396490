import * as types from 'constants/actionTypes'
import api from 'api'
import { AI_URL } from 'constants/urls'
import mockAiResponse from 'mocks/aiResponse'
import { sprintf } from 'sprintf-js'

export const getAiResponse = (message: any) => {
  return api.call({
    url: sprintf(AI_URL, { message }),
    skipFake: false,
    cascadeFailureError: true,
    expectedPayload: mockAiResponse,
    type: {
      request: types.AI_GET_REQUEST,
      success: types.AI_GET_SUCCESS,
      failure: types.AI_GET_FAILURE
    }
  })
}
