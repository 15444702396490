import { Button, Dropdown, Label, PileDiv, VerticalSpace } from '@cegal/ds-components'
import { ConsultantsFilterOptions } from 'declarations/enum'
import { ConsultantsQuery, Engagement } from 'declarations/models'
import { standardLogger } from 'metrics/loggers'

import { useTranslation } from 'react-i18next'

import { getSortedSkillsFromEngagementVacancy } from 'utils/engagements'
import { initialConsultantsQuery } from 'utils/query'

import _ from 'lodash'

interface ConsultantsFilterProps {
  query: Partial<ConsultantsQuery>
  setQuery: any
  engagement: Engagement | null | undefined
}

interface Option<T> {
  label: string
  value: T
}

const ConsultantsFilterAndSort = ({ query, setQuery, engagement }: ConsultantsFilterProps) => {
  const { t } = useTranslation()

  const filteringOptions: Array<Option<ConsultantsFilterOptions>> = [
    { value: ConsultantsFilterOptions.NONE, label: t('label:match-filter-none') },
    { value: ConsultantsFilterOptions.CUSTOM, label: t('label:match-filter-custom') },
    { value: ConsultantsFilterOptions.SKILL, label: t('label:match-filter-skill') }
  ]

  /* const sortingOptions: Array<Option<ConsultantsSortOptions>> = [
    { value: ConsultantsSortOptions.NONE, label: t('label:match-sort-none') },
    { value: ConsultantsSortOptions.SKILL, label: t('label:match-sort-best-skill') },
    { value: ConsultantsSortOptions.IDLE, label: t('label:match-sort-idle') },
    { value: ConsultantsSortOptions.CLIENT, label: t('label:match-sort-client-first') },
    { value: ConsultantsSortOptions.FOLLOWED, label: t('label:match-sort-followed-first') }
  ] */

  const handleFilterChanged = (option: ConsultantsFilterOptions) => {
    standardLogger('matches.filters.' + option)
    switch (option) {
      case ConsultantsFilterOptions.SKILL:
        setQuery({
          metaFilter: option,
          skills: getSortedSkillsFromEngagementVacancy(engagement!!, query.vacancy!!)
        })
        break
      case ConsultantsFilterOptions.NONE:
        setQuery({
          ...initialConsultantsQuery,
          metaFilter: option
        })
        break
      case ConsultantsFilterOptions.CUSTOM:
        setQuery({
          metaFilter: option
        })
        break
      default:
        break
    }
  }

  /* const handleSortChanged = (option: ConsultantsSortOptions) => {
    standardLogger('matches.sort.' + option)
    setQuery({ metaSort: option })
  } */

  return (
    <PileDiv>
      <Label>{t('label:filter')}</Label>
      <Dropdown>
        <Button as={Dropdown.Toggle}>{_.find(filteringOptions, { value: query.metaFilter })?.label}</Button>
        <Dropdown.Menu placement='bottom-start'>
          <Dropdown.Menu.List>
            {_.reject(filteringOptions, { value: query.metaFilter }).map(
              (option: Option<ConsultantsFilterOptions>) => (
                <Dropdown.Menu.List.Item key={option.value} onClick={() => handleFilterChanged(option.value)}>
                  {option.label}
                </Dropdown.Menu.List.Item>
              )
            )}
          </Dropdown.Menu.List>
        </Dropdown.Menu>
      </Dropdown>

      {/* <Label>{t('label:sort')}</Label>
        <Dropdown>
          <Button as={Dropdown.Toggle}>{_.find(sortingOptions, { value: query.metaSort })?.label}</Button>
          <Dropdown.Menu placement='bottom-start'>
            <Dropdown.Menu.List>
              {_.reject(sortingOptions, { value: query.metaSort }).map(
                (option: Option<ConsultantsSortOptions>) => (
                  <Dropdown.Menu.List.Item key={option.value} onClick={() => handleSortChanged(option.value)}>
                    {option.label}
                  </Dropdown.Menu.List.Item>
                )
              )}
            </Dropdown.Menu.List>
          </Dropdown.Menu>
        </Dropdown>
        */}
      <VerticalSpace />
    </PileDiv>
  )
}

export default ConsultantsFilterAndSort
