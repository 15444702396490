import { Heading, VerticalSpace } from '@cegal/ds-components'
import { getAppStats } from 'actions'
import { APP_STATS_FAILURE } from 'constants/actionTypes'
import { useAppDispatch, useAppSelector } from 'store'

import AlertPanel from 'components/AlertPanel/AlertPanel'
import PlatformSummary from 'components/Home/PlatformSummary/PlatformSummary'
import Time from 'components/Home/PlatformSummary/Time'
import Widget from 'components/Home/Widget/Widget'
import { StretchLoader } from 'components/Loader/CustomLoader'
import { CardContainer } from 'components/styled'

import { HomePanelProps } from 'pages/Home/Home'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'

const SummaryBox = ({ mode }: HomePanelProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const stats = useAppSelector((state) => state.app.stats)
  const statsLoading = useAppSelector((state) => state.app.statsLoading)
  const apiReady = useAppSelector((state) => state.app.apiReady)

  useEffect(() => {
    if (stats === undefined && apiReady && !statsLoading) {
      loadPageData()
    }
  }, [apiReady])

  const loadPageData = () => {
    dispatch(getAppStats())
  }

  const renderBody = () => (
    <>
      {statsLoading && <StretchLoader />}
      {stats === null && (
        <AlertPanel
          doNotRenderIfHeadIsActiveWithError
          watchFor={[APP_STATS_FAILURE]}
          buttonText={t('buttons:click-to-reload-the-page')!}
          reloadPage={loadPageData}
        />
      )}
      {!_.isNil(stats) && <PlatformSummary stats={stats} />}
    </>
  )

  if (mode === 'widget') {
    return <Widget header={<Time />}>{renderBody()}</Widget>
  }

  if (mode === 'tab') {
    return (
      <Widget header={<Time />} spacing={false} minWidth={150}>
        {renderBody()}
      </Widget>
    )
  }

  return (
    <>
      <Heading>{t('home:platform-summary')}</Heading>
      <VerticalSpace />
      <CardContainer>{renderBody()}</CardContainer>
      <VerticalSpace size='2' />
    </>
  )
}

export default SummaryBox
