import { Allocation, Consultant, ConsultantEngagement } from 'declarations/models'

import 'components/Consultants/Consultant/Allocation/consultantOverview.scss'

import React, { FunctionComponent, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import BEMHelper from 'utils/bem'

import _ from 'lodash'

export interface ConsultantOverviewProps {
  consultant: Consultant
  allocation: Array<Allocation> | null | undefined
}

interface CurrentEngagement {
  id: number
  consultantAllocations: Array<Allocation> | undefined
  name: string
}

const ConsultantOverview: FunctionComponent<ConsultantOverviewProps> = ({
  consultant,
  allocation
}: ConsultantOverviewProps) => {
  const cls = BEMHelper('consultantOverview')

  const { t } = useTranslation()

  const assignments: CurrentEngagement[] = useMemo(() => {
    return (
      consultant.engagements?.map((eng: ConsultantEngagement) => ({
        id: eng.id,
        consultantAllocations: _.filter(allocation, (a) => new Date(a?.end_date) >= new Date()).filter(
          (a) => a.engagement_vacancy_id === eng.id
        ),
        name: eng.title || eng.vacancies.title || t('messages:backup-allocation-view')
      })) ?? []
    )
  }, [consultant])

  return (
    <div className={cls.className}>
      {assignments &&
        assignments.length > 0 &&
        assignments?.map((a: CurrentEngagement, index: number) => {
          const last =
            a?.consultantAllocations && a?.consultantAllocations.length > 0
              ? new Date(a?.consultantAllocations[a.consultantAllocations?.length - 1]?.end_date)
              : null

          return (
            <div key={index}>
              {last && (
                <div className={cls.element('info')}>
                  <span className={cls.element('name')}>{a.name}</span>
                  <span className={cls.element('label')}>{t('label:consultant-overview')}</span>
                  <span className={cls.element('end-date')}>
                    {last.toLocaleDateString('no-NO', {
                      day: undefined,
                      month: 'short',
                      year: 'numeric'
                    })}
                  </span>
                </div>
              )}
            </div>
          )
        })}
    </div>
  )
}
export default ConsultantOverview
