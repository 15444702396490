var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import cl from 'clsx';
import { AccordionContext } from 'components/accordion/Accordion';
import React, { forwardRef, useContext } from 'react';
import AnimateHeight from 'util/AnimateHeight';
import { AccordionItemContext } from './AccordionItem';
const AccordionContent = forwardRef((_a, ref) => {
    var { children, className } = _a, rest = __rest(_a, ["children", "className"]);
    const context = useContext(AccordionItemContext);
    const mainContext = useContext(AccordionContext);
    if (context === null) {
        console.error('<Accordion.Content> has to be used within an <Accordion.Item>');
        return null;
    }
    return (React.createElement(AnimateHeight, { height: context.open ? 'auto' : 0, duration: 250 },
        React.createElement("div", Object.assign({}, rest, { ref: ref, className: cl('cds-accordion__content', {
                'cds-accordion__content--border': mainContext === null || mainContext === void 0 ? void 0 : mainContext.border
            }, className) }), children)));
});
AccordionContent.displayName = 'Accordion.Content';
export default AccordionContent;
