import * as types from 'constants/actionTypes'
import { News } from 'declarations/models'
import { AnyAction } from 'redux'

import _ from 'lodash'

export interface NewsState {
  created: News | null | undefined
  creating: boolean
  deleted: News | null | undefined
  deleting: boolean
  get: News | null | undefined
  getting: boolean
  list: Array<News> | null | undefined
  listing: boolean
  saving: boolean
  saved: News | null | undefined
}

export const initialNewsState: NewsState = {
  created: undefined,
  creating: false,
  deleted: undefined,
  deleting: false,
  get: undefined,
  getting: false,
  list: undefined,
  listing: false,
  saved: undefined,
  saving: false
}

const newsReducer = (
  /* istanbul ignore next */
  state: NewsState = initialNewsState,
  action: AnyAction
): NewsState => {
  switch (action.type) {
    case types.NEWS_DELETE_REQUEST:
      return {
        ...state,
        deleted: undefined,
        deleting: true
      }

    case types.NEWS_DELETE_SUCCESS: {
      const deletedNews = action.context
      return {
        ...state,
        deleted: deletedNews,
        deleting: false,
        list: _.reject(state.list, { id: deletedNews.id })
      }
    }

    case types.NEWS_DELETE_FAILURE:
      return {
        ...state,
        deleted: null,
        deleting: false
      }

    case types.NEWS_EDIT_REQUEST:
      return {
        ...state,
        saved: undefined,
        saving: true
      }

    case types.NEWS_EDIT_SUCCESS: {
      const editedNews = action.payload
      return {
        ...state,
        saved: editedNews,
        saving: false,
        list: _.map(state.list, (News) =>
          /* istanbul ignore next */
          editedNews.id === News.id ? editedNews : News
        )
      }
    }

    case types.NEWS_EDIT_FAILURE:
      return {
        ...state,
        saved: null,
        saving: false
      }

    case types.NEWS_GET_REQUEST:
      return {
        ...state,
        getting: true,
        get: undefined
      }

    case types.NEWS_GET_SUCCESS:
      return {
        ...state,
        getting: false,
        get: action.payload
      }

    case types.NEWS_GET_FAILURE:
      return {
        ...state,
        getting: false,
        get: null
      }

    case types.NEWS_LIST_REQUEST:
      return {
        ...state,
        list: undefined,
        listing: true
      }

    case types.NEWS_LIST_SUCCESS: {
      // with 204, I get a {} instead of a []
      /* istanbul ignore next */
      const payload = action.status === 204 && _.isEmpty(action.payload) ? [] : action.payload
      return {
        ...state,
        list: payload,
        listing: false
      }
    }

    case types.NEWS_LIST_FAILURE:
      return {
        ...state,
        list: null,
        listing: false
      }

    case types.NEWS_NEW_REQUEST:
      return {
        ...state,
        creating: true,
        created: undefined
      }

    case types.NEWS_NEW_SUCCESS: {
      const newNews = action.payload
      return {
        ...state,
        created: newNews,
        creating: false,
        list: [.../* istanbul ignore next */ (state.list ?? [])].concat(newNews)
      }
    }

    case types.NEWS_NEW_FAILURE:
      return {
        ...state,
        created: null,
        creating: false
      }

    default:
      return state
  }
}

export default newsReducer
