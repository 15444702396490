import { FlexDiv, HorizontalSpace, Label, PileDiv, VerticalSpace } from '@cegal/ds-components'
import { TableHeader } from 'declarations/app'

import DraggableHeader from 'components/TableColumnManagerModal/DraggableHeader'

import { useEffect, useState } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'

export interface TableHeaderSorterProps {
  headerKeys: Array<string>
  options: Array<TableHeader>
  onHeadersChange: (newHeaders: Array<TableHeader>) => void
}

const move = (
  source: Array<TableHeader>,
  destination: Array<TableHeader>,
  droppableSource: any,
  droppableDestination: any
): { [k in string]: Array<TableHeader> } => {
  const sourceClone = _.cloneDeep(source)
  const destClone = _.cloneDeep(destination)
  const [removed] = sourceClone.splice(droppableSource.index, 1)
  destClone.splice(droppableDestination.index, 0, removed)
  return {
    [droppableSource.droppableId]: sourceClone,
    [droppableDestination.droppableId]: destClone
  }
}

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver
    ? 'var(--cds-background-color-alternative-light-opaque)'
    : 'var(--cds-background-color-inverted-light-opaque)',
  padding: '0.75rem',
  flex: 1,
  minHeight: '90%'
})

const reorder = (list: Array<TableHeader>, startIndex: number, endIndex: number): Array<TableHeader> => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

const TableHeaderSorter = ({ headerKeys, options, onHeadersChange }: TableHeaderSorterProps) => {
  const { t } = useTranslation()
  const [visibleHeaders, setVisibleHeaders] = useState<Array<TableHeader>>([])
  const [invisibleHeaders, setInvisibleHeaders] = useState<Array<TableHeader>>([])
  const [isDropDisabled, setIsDropDisabled] = useState<boolean>(false)

  const parseHeaders = (headerKeys: Array<string>) => {
    let _visible: Array<TableHeader> = []
    const _invisible: Array<TableHeader> = []
    options.forEach((option: TableHeader) =>
      headerKeys.includes(option.value) ? _visible.push(option) : _invisible.push(option)
    )
    _visible = _visible.sort((a, b) => headerKeys.indexOf(a.value) - headerKeys.indexOf(b.value))
    return [_visible, _invisible]
  }

  useEffect(() => {
    const [_visibleHeaders, _invisibleHeaders] = parseHeaders(headerKeys)
    setVisibleHeaders(_visibleHeaders)
    setInvisibleHeaders(_invisibleHeaders)
  }, [headerKeys])

  const updateVisibleHeaders = (newHeaders: Array<TableHeader>) => {
    setVisibleHeaders(newHeaders)
    onHeadersChange(newHeaders)
  }

  const onBeforeDragStart = ({ source }: any) => {
    if (source.droppableId === 'AVAILABLE') {
      setIsDropDisabled(true)
    }
  }

  const onDragEnd = ({ source, destination }: any) => {
    // dropped outside the list
    if (isDropDisabled) {
      setIsDropDisabled(false)
    }
    if (!destination) {
      return
    }
    if (source.droppableId === destination.droppableId) {
      if (source.droppableId === 'VISIBLE') {
        const items = reorder(visibleHeaders, source.index, destination.index)
        updateVisibleHeaders(items)
      } else {
        // do not sort invisible headers
      }
    } else {
      const result = move(
        source.droppableId === 'VISIBLE' ? visibleHeaders : invisibleHeaders,
        destination.droppableId === 'VISIBLE' ? visibleHeaders : invisibleHeaders,
        source,
        destination
      )
      updateVisibleHeaders(result.VISIBLE)
      setInvisibleHeaders(result.AVAILABLE)
    }
  }

  const addElement = (index: number) => {
    let _visibleHeaders = _.cloneDeep(visibleHeaders)
    const _invisibleHeaders = _.cloneDeep(invisibleHeaders)
    _visibleHeaders = _visibleHeaders.concat(_invisibleHeaders.splice(index, 1))
    setInvisibleHeaders(_invisibleHeaders)
    updateVisibleHeaders(_visibleHeaders)
  }

  const removeElement = (index: number) => {
    const _visibleHeaders = _.cloneDeep(visibleHeaders)
    let _invisibleHeaders = _.cloneDeep(invisibleHeaders)
    _invisibleHeaders = _invisibleHeaders.concat(_visibleHeaders.splice(index, 1))
    setInvisibleHeaders(_invisibleHeaders)
    updateVisibleHeaders(_visibleHeaders)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={onBeforeDragStart}>
      <FlexDiv>
        <PileDiv flex='1'>
          <Label>{t('header:visible-columns')}</Label>
          <VerticalSpace />
          <Droppable droppableId='VISIBLE'>
            {(provided: any, snapshot: any) => (
              <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                {visibleHeaders.map((header, index) => (
                  <DraggableHeader
                    key={header.value}
                    header={header}
                    index={index}
                    role='right'
                    onClick={removeElement}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </PileDiv>
        <HorizontalSpace />
        <PileDiv flex='1'>
          <Label>{t('header:available-columns')}</Label>
          <VerticalSpace />
          <Droppable droppableId='AVAILABLE' isDropDisabled={isDropDisabled}>
            {(provided: any, snapshot: any) => (
              <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                {invisibleHeaders.map((header, index) => (
                  <DraggableHeader
                    key={header.value}
                    header={header}
                    index={index}
                    role='left'
                    onClick={addElement}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </PileDiv>
      </FlexDiv>
    </DragDropContext>
  )
}

export default TableHeaderSorter
