import { BodyShort, Button, Label, VerticalSpace } from '@cegal/ds-components'
import { File } from 'declarations/models'
import 'index.css'

import FilePreviewModal from 'components/File/FilePreviewModal'
import FileRow from 'components/File/FileRow'
import FileUploadModal from 'components/File/FileUploadModal'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'

export interface UploadFileProps {
  anchorEl?: HTMLElement | null
  value: string
  onChange: (s: string) => void
}

const FileUpload = ({ anchorEl, value, onChange }: UploadFileProps) => {
  const { t } = useTranslation()

  const [openUploadFileModal, setOpenUploadFileModal] = useState<boolean>(false)
  const [openFileViewerModal, setOpenFileViewerModal] = useState<boolean>(false)
  const [files, setFiles] = useState<Array<File>>([])
  const [previewFile, setPreviewFile] = useState<File | undefined>(undefined)

  useEffect(() => {
    if (!_.isEmpty(value)) {
      setFiles(JSON.parse(value))
    }
  }, [value])

  const onCloseUploadFileModal = () => setOpenUploadFileModal(false)

  const onCloseFileViewerModal = () => setOpenFileViewerModal(false)

  const onOpenUploadFileModal = () => setOpenUploadFileModal(true)

  const onPreviewFile = (file: File) => {
    setPreviewFile(file)
    setOpenFileViewerModal(true)
  }

  const onAddFiles = (_files: Array<File>) => {
    setFiles(_files)
    onChange(JSON.stringify(_files))
  }

  const onDeleteFile = (_file: File) => {
    onAddFiles((files as Array<File>).filter((file: File) => file.name !== _file.name))
  }

  return (
    <>
      <FileUploadModal
        onAddFiles={onAddFiles}
        onPreviewFile={onPreviewFile}
        open={openUploadFileModal}
        onClose={onCloseUploadFileModal}
        newFiles={files}
        anchorEl={anchorEl}
      />
      <FilePreviewModal
        open={!!previewFile && openFileViewerModal}
        onClose={onCloseFileViewerModal}
        fileToPreview={previewFile}
        anchorEl={anchorEl}
      />

      <Label>{t('label:upload-file-title')}</Label>
      <VerticalSpace />

      <Button variant='secondary' onClick={onOpenUploadFileModal}>
        {t('buttons:click-to-add-file')}
      </Button>

      <VerticalSpace />

      {!_.isEmpty(files) && <BodyShort> {t('label:added-files')}: </BodyShort>}
      {(files as Array<File>).map((file: File) => (
        <FileRow
          key={file.name}
          file={file}
          allowDownload
          separator
          onDelete={() => onDeleteFile(file)}
          onPreview={() => onPreviewFile(file)}
        />
      ))}
    </>
  )
}

export default FileUpload
