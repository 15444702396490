var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { ArrowDownward as Down } from '@cegal/ds-icons/dist/ArrowDownward';
import { ArrowUpward as Up } from '@cegal/ds-icons/dist/ArrowUpward';
import { ImportExport as UpDown } from '@cegal/ds-icons/dist/ImportExport';
import React, { forwardRef, useContext } from 'react';
import HeaderCell from './HeaderCell';
import { TableContext } from './Table';
export const ColumnHeader = forwardRef((_a, ref) => {
    var _b, _c, _d, _e;
    var { className, children, sortable = false, sortKey } = _a, rest = __rest(_a, ["className", "children", "sortable", "sortKey"]);
    const context = useContext(TableContext);
    if (sortable && !sortKey) {
        console.warn('ColumnHeader with `sortable=true` must have a sortKey.');
    }
    return (React.createElement(HeaderCell, Object.assign({}, rest, { ref: ref, className: className, scope: 'col', "aria-sort": sortable ? (((_b = context === null || context === void 0 ? void 0 : context.sort) === null || _b === void 0 ? void 0 : _b.orderBy) === sortKey ? (_c = context === null || context === void 0 ? void 0 : context.sort) === null || _c === void 0 ? void 0 : _c.direction : 'none') : undefined }), sortable ? (React.createElement("button", { className: 'cds-table__sort-button', onClick: sortable && sortKey ? () => { var _a; return (_a = context === null || context === void 0 ? void 0 : context.onSortChange) === null || _a === void 0 ? void 0 : _a.call(context, sortKey); } : undefined },
        children,
        ((_d = context === null || context === void 0 ? void 0 : context.sort) === null || _d === void 0 ? void 0 : _d.orderBy) === sortKey ? (((_e = context === null || context === void 0 ? void 0 : context.sort) === null || _e === void 0 ? void 0 : _e.direction) === 'descending' ? (React.createElement(Down, { size: '1.5rem', "aria-hidden": true })) : (React.createElement(Up, { size: '1.5rem', "aria-hidden": true }))) : (React.createElement(UpDown, { size: '1.5rem', "aria-hidden": true })))) : (children)));
});
ColumnHeader.displayName = 'Table.ColumnHeader';
export default ColumnHeader;
