import {
  Accordion,
  BodyShort,
  Button,
  Column,
  HorizontalSpace,
  Row,
  Tabs,
  VerticalSpace
} from '@cegal/ds-components'
import { Add, AttachFile, ContactPage } from '@cegal/ds-icons'
import { Work as VacancyIcon } from '@cegal/ds-icons/dist/Work'
import { deleteEngagementVacancy, editEngagementVacancy, newEngagementVacancy } from 'actions'
import { Engagement, EngagementVacancy, File } from 'declarations/models'
import { standardLogger } from 'metrics/loggers'
import { nextTick } from 'process'
import { useAppDispatch, useAppSelector } from 'store'

import EngagementDetail from 'components/Engagements/Engagement/EngagementDetail'
import EngagementVacancyCard from 'components/Engagements/Engagement/Vacancy/EngagementVacancyCard'
import EngagementVacancyForm from 'components/Engagements/Engagement/Vacancy/EngagementVacancyForm'
import FileRenderer from 'components/File/FileRenderer'
import FileRow from 'components/File/FileRow'
import { TabPanel } from 'components/styled'

import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'

import _ from 'lodash'

export interface EngagementViewContentProps {
  engagement: Engagement
  interactive?: boolean
}

const EngagementViewContent = ({ engagement, interactive = true }: EngagementViewContentProps) => {
  const { t } = useTranslation()
  const formRef = useRef<HTMLDivElement>(null)

  enum TabName {
    VACANCIES = 'Vacancies',
    DESCRIPTION = 'Description',
    FILE = 'File'
  }

  const savingEngagementVacancy = useAppSelector((state) => state.engagements.savingVacancy)

  const dispatch = useAppDispatch()

  const [showVacancyForm, setShowVacancyForm] = useState<boolean>(false)
  const [editingVacancy, setEditingVacancy] = useState<number>()

  const handleShowVacancyForm = () => {
    setShowVacancyForm(true)

    nextTick(() => {
      const el = document.getElementById('newVacancyContainer')
      if (el) el.scrollIntoView({ behavior: 'smooth', block: 'start' })
    })
  }

  const handleEngagementViewTabChange = (tab: string) => {
    standardLogger('engagements.view.tab.' + tab)
  }

  const onNewVacancy = (newVacancy: EngagementVacancy) => {
    if (engagement) {
      if (editingVacancy) dispatch(editEngagementVacancy(newVacancy, engagement.id))
      else dispatch(newEngagementVacancy(newVacancy, engagement.id))
    }
  }

  const onDeleteVacancy = (vacancy: EngagementVacancy) => {
    if (engagement) {
      dispatch(deleteEngagementVacancy(vacancy, engagement.id))
    }
  }

  return (
    <Row ref={formRef}>
      <Column style={{ maxWidth: '100%', minWidth: '400px' }}>
        <EngagementDetail engagement={engagement} showVacancies={false} />
        <VerticalSpace />
      </Column>
      <Column flex='3'>
        <Tabs defaultValue={TabName.VACANCIES} onChange={handleEngagementViewTabChange}>
          <Tabs.List loop>
            <Tabs.Tab
              value={TabName.VACANCIES}
              label={
                <>
                  <VacancyIcon size='1.5rem' />
                  <HorizontalSpace size='0.3' />
                  {t('label:vacancies')}
                </>
              }
            />
            <Tabs.Tab
              value={TabName.DESCRIPTION}
              label={
                <>
                  <ContactPage size='1.5rem' />
                  <HorizontalSpace size='0.3' />
                  {t('label:description')}
                </>
              }
            />
            <Tabs.Tab
              data-cy='consultantFileTab'
              value={TabName.FILE}
              label={
                <>
                  <AttachFile size='1.5rem' />
                  {t('label:files')}
                </>
              }
            />
          </Tabs.List>

          <TabPanel value={TabName.VACANCIES}>
            {engagement.vacancies?.map((vacancy: EngagementVacancy, index: number) => {
              return _.isNumber(vacancy.id) && editingVacancy === vacancy.id ? (
                <EngagementVacancyForm
                  isEditing={savingEngagementVacancy}
                  formRef={formRef}
                  vacancy={vacancy}
                  onNewVacancy={onNewVacancy}
                  onClose={() => setEditingVacancy(undefined)}
                />
              ) : (
                <EngagementVacancyCard
                  item={{
                    ...vacancy,
                    title: t('label:vacancy') + ' #' + (index + 1) + ': ' + vacancy.title
                  }}
                  key={vacancy.id}
                  spacing
                  increaseCutoffPoint
                  onDelete={onDeleteVacancy}
                  onEdit={(vacancy) => setEditingVacancy(vacancy.id)}
                />
              )
            }) ?? t('messages:engagement-has-no-vacancies')}
            <VerticalSpace />
            <div id='newVacancyContainer'>
              {showVacancyForm ? (
                <EngagementVacancyForm
                  isEditing={savingEngagementVacancy}
                  formRef={formRef}
                  engagementTitle={engagement.title}
                  onNewVacancy={onNewVacancy}
                  onClose={() => setShowVacancyForm(false)}
                />
              ) : interactive ? (
                <Button icon={<Add size='1.5rem' />} variant='secondary' onClick={handleShowVacancyForm}>
                  {t('buttons:add-new-x', { x: t('label:vacancy').toLowerCase() })}
                </Button>
              ) : null}
            </div>
          </TabPanel>

          <TabPanel value={TabName.DESCRIPTION}>
            {_.isNull(engagement?.description) && (
              <BodyShort>{t('messages:there-is-no-description')}</BodyShort>
            )}
            <ReactMarkdown>{engagement?.description}</ReactMarkdown>
          </TabPanel>

          <TabPanel value={TabName.FILE}>
            {engagement?.file
              ? JSON.parse(engagement?.file)?.map((file: File) => (
                  <Accordion border key={file.name}>
                    <Accordion.Item>
                      <Accordion.Header>
                        <FileRow file={file} allowDownload />
                      </Accordion.Header>
                      <Accordion.Content>
                        <FileRenderer file={file} />
                      </Accordion.Content>
                    </Accordion.Item>
                  </Accordion>
                ))
              : null}
          </TabPanel>
        </Tabs>
      </Column>
    </Row>
  )
}

export default EngagementViewContent
