import * as types from 'constants/actionTypes'
import * as urls from 'constants/urls'
import api from 'api'
import { sprintf } from 'sprintf-js'

export const getCertificate = (id: number) => {
  return api.call({
    url: sprintf(urls.CERTIFICATES_GET_URL, { id }),
    cascadeFailureError: true,
    skipFake: true,
    type: {
      request: types.CERTIFICATES_GET_REQUEST,
      success: types.CERTIFICATES_GET_SUCCESS,
      failure: types.CERTIFICATES_GET_FAILURE
    }
  })
}

export const listCertificates = () => {
  return api.call({
    url: urls.CERTIFICATES_LIST_URL,
    cascadeFailureError: true,
    skipFake: true,
    type: {
      request: types.CERTIFICATES_LIST_REQUEST,
      success: types.CERTIFICATES_LIST_SUCCESS,
      failure: types.CERTIFICATES_LIST_FAILURE
    }
  })
}
