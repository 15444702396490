import { Attachment } from 'hooks/useAiMessages'

import { MockResponse, MockResponseFunction, responses } from './responses'

interface MockSpecificResponse {
  wordIndex: number
  response: MockResponseFunction
}

// Recursively look for the most specific response based on the context tree defined in responses.ts
// Returns an object with the index of the word found with most specificity as well as the response function
// That way the word after the trigger-word can be used inside the response function
// Ex. "Add a vacancy called Test", where "called" is the deepest context trigger, and "Test" can be used in the response
const findMostSpecificResponse = (
  hit: MockResponse,
  words: string[],
  wordIndex: number
): MockSpecificResponse => {
  // If mocked response has possible contexts, look for a more specific response
  if (hit.contexts) {
    let newHit = { wordIndex, response: hit.response }
    words.some((word, index) => {
      const found = hit.contexts!.find((context) => context.triggers.includes(word.toLowerCase()))
      if (found) {
        newHit = findMostSpecificResponse(found, words, index)
        return true // break some() loop
      } else {
        return false
      }
    })
    return newHit
  } else {
    // Else just return the default response
    return { wordIndex, response: hit.response }
  }
}

export function mockAiResponse(message: string, attachments?: Attachment[]) {
  let result = "Sorry, I'm not sure what you mean. Please try rephrasing your request."

  const words = message.split(/[.,\-_ ]/)
  words.some((word, index) => {
    const found = responses.find((item) => item.triggers.includes(word.toLowerCase()))
    if (found) {
      const specific = findMostSpecificResponse(found, words, index)
      const response = specific.response(words[specific.wordIndex + 1], attachments)
      if (response) result = response
      return true // break some() loop
    }
    return false
  })

  return result
}
