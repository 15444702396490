import {
  Alert,
  BodyShort,
  Button,
  Column,
  ErrorMessage,
  FlexEndDiv,
  FlexStartSpacedDiv,
  HorizontalSpace,
  Label,
  Panel,
  PileDiv,
  Row,
  SelectMultiple,
  VerticalSpace
} from '@cegal/ds-components'
import { Add } from '@cegal/ds-icons'
import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  CreateLink,
  InsertImage,
  ListsToggle,
  MDXEditor,
  Separator,
  UndoRedo,
  frontmatterPlugin,
  headingsPlugin,
  imagePlugin,
  linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  markdownShortcutPlugin,
  quotePlugin,
  tablePlugin,
  thematicBreakPlugin,
  toolbarPlugin
} from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css'
import { clearAlertBody, listClients, listSources, newClient, newSource, resetClient } from 'actions'
import { CLIENTS_NEW_SUCCESS, SOURCES_NEW_SUCCESS } from 'constants/actionTypes'
import { AlertElement } from 'declarations/app'
import { Client, SimpleEntry, Source } from 'declarations/models'
import { standardLogger } from 'metrics/loggers'
import { AlertsState } from 'reducers/alerts'
import { useAppDispatch, useAppSelector } from 'store'

import ClientModal from 'components/Clients/Client/ClientModal'
import { EngagementStepFormProps } from 'components/Engagements/Engagement/Form/EngagementForm'
import SourceModal from 'components/Modal/SourceModal'

import { useEffect, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import useUnmount from 'hooks/useUnmount'

import { Option } from 'utils/fieldUtils'

import _ from 'lodash'

const Step2 = ({ formRef, editView }: EngagementStepFormProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const {
    control,
    setValue,
    watch,
    formState: { errors }
  } = useFormContext()

  const _clients = watch('clients')
  // const _source = watch('source')

  const [open, setOpen] = useState<boolean>(false)
  const [workingCopySource, setWorkingCopySource] = useState<Partial<Source> | undefined>(undefined)
  const [addNewClient, setAddNewClient] = useState<boolean>(false)

  const apiReady = useAppSelector((state) => state.app.apiReady)
  const theme = useAppSelector((state) => state.app.settings.theme)
  const clients = useAppSelector((state) => state.clients.list)
  const createdClient = useAppSelector((state) => state.clients.created)
  const loadingClients = useAppSelector((state) => state.clients.listing)
  const sources = useAppSelector((state) => state.sources.list)
  const loadingSources = useAppSelector((state) => state.sources.listing)
  const alertBody = useAppSelector((state) => (state.alerts as AlertsState).body)

  /* const initialSource: Partial<Source> = {
    name: ''
  }
*/
  const onNewClient = (client: Client, addClient: boolean) => {
    standardLogger(`engagements.${editView ? 'edit' : 'new'}.client.created`)
    dispatch(newClient(client))
    if (addClient) {
      setAddNewClient(true)
    }
  }

  const onCloseNewClient = () => {
    if (addNewClient) {
      setValue('clients', [...(_clients ?? []), createdClient])
    }
    setOpen(false)
  }

  const onNewSource = (source: Source, addSource: boolean) => {
    standardLogger(`engagements.${editView ? 'edit' : 'new'}.source.created`)
    dispatch(newSource(source))
    if (addSource) {
      setValue('source', source)
    }
  }

  const onCloseNewSource = () => setWorkingCopySource(undefined)

  const handleNewClientRequest = () => {
    dispatch(resetClient())
    setOpen(true)
  }

  /* const handleNewSourceRequest = () => {
    dispatch(resetSource())
    setWorkingCopySource(initialSource)
  } */

  const showAlertSources: AlertElement | null = useMemo(
    () => _.find(alertBody, (alert) => [SOURCES_NEW_SUCCESS].indexOf(alert.type) >= 0) ?? null,
    [alertBody]
  )

  const showAlertClients: AlertElement | null = useMemo(
    () => _.find(alertBody, (alert) => [CLIENTS_NEW_SUCCESS].indexOf(alert.type) >= 0) ?? null,
    [alertBody]
  )

  const makeSelectOption = <T extends SimpleEntry>(el: T): Option<T> =>
    ({ ...el, label: el.name, value: el.id }) as Option<T>

  const makeSelectOptions = <T extends SimpleEntry>(array: Array<T> | null | undefined): Array<Option<T>> =>
    _.cloneDeep(array)
      ?.sort((a, b) => a.name.localeCompare(b.name))
      ?.map(makeSelectOption) ?? []

  const clientsOptions: Array<Option<Client>> = useMemo(() => makeSelectOptions<Client>(clients), [clients])

  //  const sourcesOptions: Array<Option<Source>> = useMemo(() => makeSelectOptions<Source>(sources), [sources])

  const selectedClients: Array<Client> = useMemo(() => {
    const ids = _clients?.map((_c: any) => _c?.id) ?? []
    return clientsOptions?.filter((c) => ids?.includes(c.id))
  }, [clientsOptions, _clients])

  /* const selectedSource: Source | undefined = useMemo(() => {
    return _.find(sourcesOptions, (s) => s.id === _source?.id)
  }, [sourcesOptions, _source])
  */

  // when unmounting, clean up any alerts that are displayed
  useUnmount(() => {
    if (showAlertSources) {
      dispatch(clearAlertBody([SOURCES_NEW_SUCCESS]))
    }
    if (showAlertClients) {
      dispatch(clearAlertBody([CLIENTS_NEW_SUCCESS]))
    }
  })

  useEffect(() => {
    if (apiReady) {
      if (!loadingClients && clients === undefined) {
        dispatch(listClients())
      }
      if (!loadingSources && sources === undefined) {
        dispatch(listSources())
      }
    }
  }, [apiReady])

  console.log(clients)
  return (
    <>
      <ClientModal
        anchorEl={formRef?.current}
        open={open}
        client={undefined}
        onClose={onCloseNewClient}
        onNewClient={onNewClient}
        askForAddClient
      />
      <SourceModal
        anchorEl={formRef?.current}
        open={workingCopySource !== undefined}
        source={workingCopySource}
        onClose={onCloseNewSource}
        onNewSource={onNewSource}
        askForAddSource
      />

      <Row>
        <Column>
          <FlexEndDiv style={{ flexWrap: 'nowrap' }}>
            <SelectMultiple
              fullWidth
              key={'clients-' + selectedClients.join(',')}
              menuPosition='fixed'
              menuPortalTarget={formRef?.current}
              label={t('label:client-title')}
              description={t('label:client-description')}
              loading={loadingClients}
              defaultValue={selectedClients}
              getOptionLabel={(c: Client) => c.name}
              onChange={(newSelectedClients: Array<Option<Client>>) => {
                setValue('clients', newSelectedClients)
              }}
              options={clientsOptions}
              error={errors.client?.message?.toString()}
            />

            <HorizontalSpace />
            <Button
              title={t('buttons:add-new-x', {
                x: t('label:client').toLowerCase()
              })}
              variant='secondary'
              icon={<Add size='1.5rem' />}
              onClick={handleNewClientRequest}
            >
              {t('buttons:add')}
            </Button>
          </FlexEndDiv>
          <VerticalSpace />
          {showAlertClients && (
            <>
              <Alert
                onClose={() => dispatch(clearAlertBody([CLIENTS_NEW_SUCCESS]))}
                variant='success'
                size='small'
              >
                {showAlertClients.message}
              </Alert>
              <VerticalSpace />
            </>
          )}
        </Column>
        <Column>
          {/* <FlexEndDiv style={{ flexWrap: 'nowrap' }}>
            <SelectMultiple
              fullWidth
              clearable
              key={'source-' + selectedSource}
              menuPortalTarget={formRef?.current}
              multiple={false}
              label={t('label:source-title')}
              description={t('label:source-description')}
              loading={loadingSources}
              defaultValue={selectedSource}
              onChange={(s: Option<Source>) => setValue('source', s)}
              options={sourcesOptions}
              error={errors.source?.message?.toString()}
            />
            <HorizontalSpace />
            <Button
              title={t('buttons:add-new-x', {
                x: t('label:sources-title').toLowerCase()
              })}
              variant='secondary'
              icon={<Add size='1.5rem' />}
              onClick={handleNewSourceRequest}
            >
              {t('buttons:add')}
            </Button>
          </FlexEndDiv>
          <VerticalSpace />
          {showAlertSources && (
            <>
              <Alert
                onClose={() => dispatch(clearAlertBody([SOURCES_NEW_SUCCESS]))}
                variant='success'
                size='small'
              >
                {showAlertSources.message}
              </Alert>
              <VerticalSpace />
            </>
          )}
          */}
        </Column>
      </Row>
      <VerticalSpace />
      <Row>
        <Column flex='3'>
          <FlexStartSpacedDiv>
            <PileDiv>
              <Label>{t('label:description-title')}</Label>
              <VerticalSpace size='0.5' />
              <BodyShort size='small'>{t('label:description-description')}</BodyShort>
            </PileDiv>
          </FlexStartSpacedDiv>
          <VerticalSpace size='0.5' />
          <Controller
            control={control}
            name='description'
            render={({ field: { onChange, value } }) => (
              <Panel border noPadding style={{ minHeight: '20rem' }}>
                <MDXEditor
                  className={theme.endsWith('light') ? '' : 'dark-theme dark-editor'}
                  markdown={value ?? ''}
                  onChange={onChange}
                  plugins={[
                    toolbarPlugin({
                      toolbarContents: () => (
                        <>
                          <UndoRedo />
                          <BoldItalicUnderlineToggles />
                          <ListsToggle />
                          <Separator />
                          <BlockTypeSelect />
                          <CreateLink />
                          <InsertImage />
                          <Separator />
                        </>
                      )
                    }),
                    listsPlugin(),
                    quotePlugin(),
                    headingsPlugin(),
                    linkPlugin(),
                    linkDialogPlugin(),
                    imagePlugin(),
                    tablePlugin(),
                    thematicBreakPlugin(),
                    frontmatterPlugin(),
                    markdownShortcutPlugin()
                  ]}
                />
                {errors?.description?.message && (
                  <ErrorMessage className='cegal-form-field__error' aria-live='polite'>
                    {errors?.description?.message}
                  </ErrorMessage>
                )}
              </Panel>
            )}
          />
          <VerticalSpace />
        </Column>
        <Column />
      </Row>
    </>
  )
}

export default Step2
