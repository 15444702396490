import {
  BodyShort,
  SelectMultiple,
  SelectMultipleComponents,
  SelectMultipleProps
} from '@cegal/ds-components'
import { Skill } from 'declarations/models'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OptionProps } from 'react-select'

import _ from 'lodash'

const SkillOption = (props: OptionProps<Skill>) => {
  const { data } = props
  return (
    <SelectMultipleComponents.Option {...props}>
      <BodyShort>{data.name}</BodyShort>
    </SelectMultipleComponents.Option>
  )
}

export interface ThisSkillSelectProps {
  skills: Array<Skill>
}

export type SkillSelectProps = ThisSkillSelectProps & SelectMultipleProps<Skill>

const filterOptions = (candidate: { label: string; value: string; data: any }, input: string) => {
  if (input) {
    return candidate.data.name.toLowerCase().match(input.toLowerCase())
  }
  return false
}

const SkillSelect = (props: SkillSelectProps) => {
  const [_options, setOptions] = useState<Array<Skill>>(props.skills)

  const { t } = useTranslation()

  useEffect(() => {
    setOptions(props.skills)
  }, [props.skills])

  const handleInputChange = (inputValue: string) => {
    const filteredOptions = _.filter(props.skills, (c: Skill) =>
      c.name.toLowerCase().match(inputValue.toLowerCase())
    ) as Array<Skill>
    setOptions(filteredOptions)
  }

  return (
    <div>
      <SelectMultiple
        multiple
        fullWidth
        getOptionValue={(skill: Skill) => skill.name}
        getOptionLabel={(s: Skill) => s.name}
        noOptionsMessage={(data: any) =>
          !_.isEmpty(data.inputValue)
            ? t('messages:warning-no-skills-found-with-text-x', { x: data.inputValue })
            : t('messages:info-type-to-search-skills')
        }
        // @ts-ignore
        onInputChange={handleInputChange}
        filterOption={filterOptions}
        components={{
          Option: (optionProps: OptionProps<Skill>) => <SkillOption {...optionProps} />
        }}
        // @ts-ignore
        options={_options}
        {...props}
      />
    </div>
  )
}

export default SkillSelect
