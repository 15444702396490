import { FlexDiv, Heading, HorizontalSpace, VerticalSpace } from '@cegal/ds-components'
import { PersonOutline } from '@cegal/ds-icons/dist/PersonOutline'
import { deleteFollowedConsultant, listFollowedConsultants } from 'actions'
import { FOLLOWED_CONSULTANTS_LIST_FAILURE } from 'constants/actionTypes'
import { Consultant } from 'declarations/models'
import { standardLogger } from 'metrics/loggers'
import { useAppDispatch, useAppSelector } from 'store'

import AlertPanel from 'components/AlertPanel/AlertPanel'
import { ConsultantCard } from 'components/Consultants'
import FollowButton from 'components/Forms/Buttons/FollowButton'
import Widget from 'components/Home/Widget/Widget'
import { StretchLoader } from 'components/Loader/CustomLoader'
import { CardContainer } from 'components/styled'

import { HomePanelProps } from 'pages/Home/Home'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import _ from 'lodash'

const FollowedConsultants = ({ mode }: HomePanelProps) => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const apiReady = useAppSelector((state) => state.app.apiReady)
  const followedConsultants = useAppSelector((state) => state.followedConsultants.list)
  const deletingFollow = useAppSelector((state) => state.followedConsultants.deleting)

  const loadPageData = () => {
    dispatch(listFollowedConsultants())
  }

  const handleConsultantUnfollow = (consultant: Consultant) => {
    standardLogger('followedconsultants.card.unfollow')
    dispatch(deleteFollowedConsultant(consultant))
  }

  const renderTitle = () => (
    <FlexDiv>
      <PersonOutline size='2rem' />
      <HorizontalSpace />
      <Heading>{t('home:followed-consultants')}</Heading>
    </FlexDiv>
  )

  const renderBody = () => (
    <>
      {_.isUndefined(followedConsultants) && <StretchLoader />}
      {_.isNull(followedConsultants) && (
        <AlertPanel
          doNotRenderIfHeadIsActiveWithError
          watchFor={[FOLLOWED_CONSULTANTS_LIST_FAILURE]}
          buttonText={t('buttons:click-to-reload-my-follows')!}
          reloadPage={loadPageData}
        />
      )}
      {!_.isNil(followedConsultants) &&
        (!_.isEmpty(followedConsultants)
          ? followedConsultants!.map((followedConsultant: Consultant) => {
              const _deleting = _.find(deletingFollow, { id: followedConsultant.id }) !== undefined
              return (
                <ConsultantCard
                  spacing
                  fullWidth
                  key={followedConsultant?.id}
                  item={followedConsultant}
                  onView={(followedConsultant) =>
                    navigate(generatePath('/consultants/:id', { id: followedConsultant!.id.toString() }))
                  }
                  buttons={[
                    <FollowButton<Consultant>
                      text={false}
                      key={'followedconsultant-' + followedConsultant.id + '-follow-button'}
                      item={followedConsultant}
                      followedItems={followedConsultants}
                      onUnfollow={handleConsultantUnfollow}
                      loading={_deleting}
                      disabled={_deleting}
                    />
                  ]}
                  size='small'
                />
              )
            })
          : t('messages:no-followed-consultants'))}
    </>
  )

  useEffect(() => {
    if (followedConsultants === undefined && apiReady) {
      loadPageData()
    }
  }, [followedConsultants, apiReady])

  if (mode === 'widget') {
    return <Widget header={renderTitle()}>{renderBody()}</Widget>
  }

  return (
    <>
      {renderTitle()}
      <VerticalSpace />
      <CardContainer>{renderBody()}</CardContainer>
      <VerticalSpace size='2' />
    </>
  )
}

export default FollowedConsultants
