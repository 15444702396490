import {
  Alert,
  Button,
  Column,
  FlexCenterDiv,
  FlexStartDiv,
  Heading,
  HorizontalSpace,
  PileDiv,
  Row,
  Tooltip,
  VerticalSpace
} from '@cegal/ds-components'
import { ArrowBack } from '@cegal/ds-icons/dist/ArrowBack'
import { Delete } from '@cegal/ds-icons/dist/Delete'
import { ModeEdit } from '@cegal/ds-icons/dist/ModeEdit'
import {
  clearAlertBody,
  deleteEngagement,
  deleteFollowedEngagement,
  getEngagement,
  newFollowedEngagement,
  resetEngagement,
  setEngagement
} from 'actions'
import {
  ENGAGEMENTS_DELETE_FAILURE,
  ENGAGEMENTS_EDIT_FAILURE,
  ENGAGEMENTS_EDIT_SUCCESS,
  ENGAGEMENTS_GET_FAILURE,
  ENGAGEMENTS_NEW_SUCCESS
} from 'constants/actionTypes'
import { AlertElement } from 'declarations/app'
import { Engagement } from 'declarations/models'
import { eventLogger, standardLogger } from 'metrics/loggers'
import { AlertsState } from 'reducers/alerts'
import { useAppDispatch, useAppSelector } from 'store'

import { FullScreenLoader } from 'components'
import AlertPanel from 'components/AlertPanel/AlertPanel'
import EngagementViewContent from 'components/Engagements/Engagement/EngagementViewContent'
import FindButton from 'components/Forms/Buttons/FindButton'
import FollowButton from 'components/Forms/Buttons/FollowButton'
import PageContent from 'components/PageContent/PageContent'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import useUnmount from 'hooks/useUnmount'

import { isEditable } from 'utils/engagements'

import _ from 'lodash'

const EngagementView = () => {
  const params = useParams()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const engagement = useAppSelector((state) => state.engagements.get)
  const engagements = useAppSelector((state) => state.engagements.list)
  const alertBody = useAppSelector((state) => (state.alerts as AlertsState).body)
  const apiReady = useAppSelector((state) => state.app.apiReady)
  const userId = useAppSelector((state) => state.app.userId)
  const deleting = useAppSelector((state) => state.engagements.deleting)
  const deleted = useAppSelector((state) => state.engagements.deleted)
  const followedEngagements = useAppSelector((state) => state.followedEngagements.list)
  const deletingFollow = useAppSelector((state) => state.followedEngagements.deleting)
  const creatingFollow = useAppSelector((state) => state.followedEngagements.creating)

  const showAlert: AlertElement | undefined = _.find(
    alertBody,
    (alert) =>
      [
        ENGAGEMENTS_NEW_SUCCESS,
        ENGAGEMENTS_DELETE_FAILURE,
        ENGAGEMENTS_EDIT_FAILURE,
        ENGAGEMENTS_EDIT_SUCCESS
      ].indexOf(alert.type) >= 0
  )

  const navigateBack = (e: any) => {
    eventLogger(e)
    dispatch(setEngagement(undefined))
    navigate(-1)
  }

  const navigateToEngagementMatch = (e: any) => {
    if (params.id) {
      standardLogger('engagements.view.find.button')
      navigate(generatePath('/engagements/:id/find', { id: params.id! }))
    }
  }

  const navigateToEngagementEdit = (e: any) => {
    if (params.id) {
      eventLogger(e)
      navigate(generatePath('/engagements/:id/edit', { id: params.id! }))
    }
  }

  const handleDeleteEngagement = () => {
    if (!!engagement && window.confirm(t('messages:confirm-delete-engagement')!)) {
      standardLogger('engagements.view.delete')
      dispatch(deleteEngagement(engagement))
    }
  }

  const handleEngagementFollow = (engagement: Engagement) => {
    standardLogger('engagements.view.follow')
    dispatch(newFollowedEngagement(engagement))
  }

  const handleEngagementUnfollow = (engagement: Engagement) => {
    standardLogger('engagements.view.unfollow')
    dispatch(deleteFollowedEngagement(engagement))
  }

  const loadPageData = () => {
    if (params.id) {
      const parsedId = parseInt(params.id)
      if (_.isNumber(parsedId)) {
        if (!_.isNil(engagements)) {
          const found = engagements?.find((obj) => obj.id === parsedId)
          if (found) {
            dispatch(setEngagement(found))
            return
          }
        }
        dispatch(getEngagement(parsedId))
      }
    }
  }

  const _creating = _.find(creatingFollow, { id: engagement?.id }) !== undefined
  const _deleting = _.find(deletingFollow, { id: engagement?.id }) !== undefined

  // when unmounting, clean up any alerts that are shown here
  useUnmount(() => {
    if (showAlert) {
      dispatch(
        clearAlertBody([
          ENGAGEMENTS_NEW_SUCCESS,
          ENGAGEMENTS_DELETE_FAILURE,
          ENGAGEMENTS_EDIT_FAILURE,
          ENGAGEMENTS_EDIT_SUCCESS
        ])
      )
    }
  })

  useEffect(() => {
    if (!!deleted && !deleting) {
      dispatch(resetEngagement())
      navigate('/engagements')
    }
  }, [engagement, deleted, deleting])

  useEffect(() => {
    if (apiReady) {
      loadPageData()
    }
  }, [apiReady])

  return (
    <PageContent maxWidth='1600px'>
      <VerticalSpace />
      <Row>
        <Column>
          <FlexCenterDiv>
            <Button
              spacing
              variant='secondary'
              data-amplitude='engagements.view.goback'
              icon={<ArrowBack size='1.5rem' />}
              onClick={navigateBack}
            >
              {t('buttons:back')}
            </Button>
            <HorizontalSpace />
            <PileDiv>
              <Heading>{engagement?.title}</Heading>
              <VerticalSpace />
            </PileDiv>
          </FlexCenterDiv>
        </Column>
        <Column>
          <FlexStartDiv style={{ justifyContent: 'flex-end' }}>
            <Tooltip
              content={
                <>
                  {!_.isNil(engagement?.external_id) && engagement!!.external_id > 0
                    ? t('messages:warning-edit-external-engagement')
                    : engagement?.id !== userId
                      ? t('messages:warning-edit-other-user-engagement')
                      : t('messages:warning-edit-your-engagement')}
                </>
              }
            >
              <div>
                <Button
                  spacing
                  disabled={!isEditable(engagement, userId)}
                  data-amplitude='engagements.view.edit'
                  variant='secondary'
                  icon={<ModeEdit size='1.5rem' />}
                  onClick={navigateToEngagementEdit}
                >
                  {t('buttons:edit')}
                </Button>
              </div>
            </Tooltip>
            <div>
              <HorizontalSpace />
              <FindButton
                size='medium'
                forItem='engagement'
                onClick={navigateToEngagementMatch}
                text={t('buttons:find-x', { x: t('label:consultants-title').toLowerCase() })}
              />
            </div>
            <div>
              <HorizontalSpace />
              <FollowButton<Engagement>
                size='medium'
                item={engagement!}
                followedItems={followedEngagements}
                onFollow={handleEngagementFollow}
                onUnfollow={handleEngagementUnfollow}
                loading={_creating || _deleting}
                disabled={_creating || _deleting}
              />
            </div>
            <div>
              <HorizontalSpace />
              <Button
                spacing
                variant='danger'
                disabled={!_.isEmpty(engagement?.source)}
                icon={<Delete size='1.5rem' />}
                loading={deleting}
                onClick={handleDeleteEngagement}
              >
                {t(deleting ? 'buttons:deleting' : 'buttons:delete')}
              </Button>
            </div>
          </FlexStartDiv>
        </Column>
      </Row>
      <VerticalSpace size='2' />
      {showAlert && (
        <>
          <Alert variant={showAlert.variant} onClose={() => dispatch(clearAlertBody([showAlert.variant]))}>
            {showAlert.message}
          </Alert>
          <VerticalSpace />
        </>
      )}{' '}
      <VerticalSpace />
      {_.isUndefined(engagement) && <FullScreenLoader />}
      {_.isNull(engagement) && (
        <AlertPanel
          doNotRenderIfHeadIsActiveWithError
          watchFor={[ENGAGEMENTS_GET_FAILURE]}
          buttonText={t('buttons:click-to-reload-the-page')!}
          reloadPage={loadPageData}
        />
      )}
      {!_.isNil(engagement) && <EngagementViewContent engagement={engagement} />}
    </PageContent>
  )
}

export default EngagementView
