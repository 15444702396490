import * as types from 'constants/actionTypes'
import { Skill } from 'declarations/models'
import { AnyAction } from 'redux'

import _ from 'lodash'

export interface SkillsState {
  created: Skill | null | undefined
  creating: boolean
  deleted: Skill | null | undefined
  deleting: boolean
  get: Skill | null | undefined
  getting: boolean
  list: Array<Skill> | null | undefined
  listing: boolean
  saving: boolean
  saved: Skill | null | undefined
}

export const initialSkillsState: SkillsState = {
  created: undefined,
  creating: false,
  deleted: undefined,
  deleting: false,
  get: undefined,
  getting: false,
  list: undefined,
  listing: false,
  saved: undefined,
  saving: false
}

const skillsReducer = (
  /* istanbul ignore next */
  state: SkillsState = initialSkillsState,
  action: AnyAction
): SkillsState => {
  switch (action.type) {
    case types.SKILLS_DELETE_REQUEST:
      return {
        ...state,
        deleted: undefined,
        deleting: true
      }

    case types.SKILLS_DELETE_SUCCESS: {
      const deletedSkill = action.originalPayload
      return {
        ...state,
        deleted: deletedSkill,
        deleting: false,
        list: _.reject(state.list, { id: deletedSkill.id })
      }
    }

    case types.SKILLS_DELETE_FAILURE:
      return {
        ...state,
        deleted: null,
        deleting: false
      }

    case types.SKILLS_EDIT_REQUEST:
      return {
        ...state,
        saved: undefined,
        saving: true
      }

    case types.SKILLS_EDIT_SUCCESS: {
      const editedSkill = action.payload
      return {
        ...state,
        saved: editedSkill,
        saving: false,
        list: _.map(state.list, (Skill) =>
          /* istanbul ignore next */
          editedSkill.id === Skill.id ? editedSkill : Skill
        )
      }
    }

    case types.SKILLS_EDIT_FAILURE:
      return {
        ...state,
        saved: null,
        saving: false
      }

    case types.SKILLS_GET_REQUEST:
      return {
        ...state,
        getting: true,
        get: undefined
      }

    case types.SKILLS_GET_SUCCESS:
      return {
        ...state,
        getting: false,
        get: action.payload
      }

    case types.SKILLS_GET_FAILURE:
      return {
        ...state,
        getting: false,
        get: null
      }

    case types.SKILLS_LIST_REQUEST:
      return {
        ...state,
        list: undefined,
        listing: true
      }

    case types.SKILLS_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload,
        listing: false
      }

    case types.SKILLS_LIST_FAILURE:
      return {
        ...state,
        list: null,
        listing: false
      }

    case types.SKILLS_NEW_REQUEST:
      return {
        ...state,
        creating: true,
        created: undefined
      }

    case types.SKILLS_NEW_SUCCESS: {
      const newSkill = action.payload
      return {
        ...state,
        created: newSkill,
        creating: false,
        list: [.../* istanbul ignore next */ (state.list ?? [])].concat(newSkill)
      }
    }

    case types.SKILLS_NEW_FAILURE:
      return {
        ...state,
        created: null,
        creating: false
      }

    default:
      return state
  }
}

export default skillsReducer
