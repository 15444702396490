import { BodyLong, Heading, Link, PileCenterDiv, VerticalSpace } from '@cegal/ds-components'
import Step1Image from 'assets/images/AddToCetegra/Step1.png'
import Step2Image from 'assets/images/AddToCetegra/Step2.png'
import Step3Image from 'assets/images/AddToCetegra/Step3.png'

import PageContent from 'components/PageContent/PageContent'

import { Trans, useTranslation } from 'react-i18next'

const AddToCetegra = () => {
  const { t } = useTranslation()

  return (
    <PageContent maxWidth='1200px'>
      <VerticalSpace />
      <Heading level='2' size='large'>
        {t('header:add-to-cetegra')}
      </Heading>
      <VerticalSpace />
      <PileCenterDiv>
        <BodyLong>{t('body:add-to-cetegra')}</BodyLong>

        <VerticalSpace size='3' />
        <Heading level='3' size='medium'>
          {t('header:add-to-cetegra-step1-title', { x: '<strong>test</strong>' })}
        </Heading>
        <VerticalSpace />
        <img src={Step1Image} style={{ maxWidth: '100%' }} alt='Add shortcut in Cetegra workspace' />
        <VerticalSpace />
        <BodyLong>
          <Trans i18nKey='body:add-to-cetegra-step1-body'>
            Go to{' '}
            <Link href='https://cloud.cegal.com/#/' type='primary'>
              Cetegra
            </Link>{' '}
            and click the three dots next to the "Applications" header. In the menu, click "Add shortcut".
          </Trans>
        </BodyLong>

        <VerticalSpace size='3' />
        <Heading level='3' size='medium'>
          {t('header:add-to-cetegra-step2-title')}
        </Heading>
        <VerticalSpace />
        <img src={Step2Image} style={{ maxWidth: '100%' }} alt='Input URL and name' />
        <VerticalSpace />
        <BodyLong>
          <Trans i18nKey='body:add-to-cetegra-step2-body'>
            Write the following link into the URL input field:{' '}
            <Link href='https://cat.cegal.com' type='primary'>
              https://cat.cegal.com
            </Link>
            <br /> and give the shortcut a name, for example "CAT".
          </Trans>
        </BodyLong>

        <VerticalSpace size='3' />
        <Heading level='3' size='medium'>
          {t('header:add-to-cetegra-step3-title')}
        </Heading>
        <VerticalSpace />
        <img src={Step3Image} style={{ maxWidth: '100%' }} alt='Save your changes' />
        <VerticalSpace />
        <BodyLong>{t('body:add-to-cetegra-step3-body')}</BodyLong>
      </PileCenterDiv>
    </PageContent>
  )
}

export default AddToCetegra
