import { AlignCenterRow, BodyShort, Column, Heading } from '@cegal/ds-components'
import errorImage from 'assets/images/catError1.png'

import PageContent from 'components/PageContent/PageContent'

import { Link, useSearchParams } from 'react-router-dom'

const ErrorPage = () => {
  const [searchParams] = useSearchParams()
  return (
    <PageContent>
      <AlignCenterRow>
        <Column>
          <Heading size='xlarge'>{searchParams.get('message')}</Heading>
          <BodyShort>We search high and low but couldn't find the page you were looking for.</BodyShort>

          <Link to='/consultants'>Go back to consultants</Link>
        </Column>
        <Column>
          <img src={errorImage} alt='Carlie Anglemire' />
        </Column>
      </AlignCenterRow>
    </PageContent>
  )
}

export default ErrorPage
