import React, { createContext, useState } from 'react';
import Toggle from './Toggle';
import Menu from './Menu';
export const DropdownContext = createContext(null);
export const Dropdown = (({ children, onSelect, closeOnSelect = true }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    return (React.createElement(DropdownContext.Provider, { value: {
            isOpen,
            setIsOpen,
            anchorEl,
            setAnchorEl,
            onSelect: (event) => {
                onSelect === null || onSelect === void 0 ? void 0 : onSelect(event);
                closeOnSelect && setIsOpen(false);
            }
        } }, children));
});
Dropdown.Toggle = Toggle;
Dropdown.Menu = Menu;
Dropdown.displayName = 'Dropdown';
export default Dropdown;
