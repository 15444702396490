import { HorizontalSpace, Tag } from '@cegal/ds-components'
import { ThumbDown } from '@cegal/ds-icons/dist/ThumbDown'
import { ThumbUp } from '@cegal/ds-icons/dist/ThumbUp'
import styled from 'styled-components'

const StyledTag = styled(Tag)`
  margin: 0 10px 10px 0;
  border-radius: 15px;
`

const DataTag = ({ isSuccess, label }: { label: string; isSuccess?: boolean }) => {
  const variant = isSuccess ? 'success' : 'error'
  const Icon = (props: { size: number; className: string }) =>
    isSuccess ? <ThumbUp {...props} /> : <ThumbDown {...props} />

  return (
    <StyledTag variant={variant} size='small' className={`cegal-alert--${variant}`}>
      <Icon size={20} className='cegal-ALERTS__icon' />
      <HorizontalSpace />
      {label}
      <HorizontalSpace />
    </StyledTag>
  )
}

export default DataTag
