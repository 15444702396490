var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import cl from 'clsx';
import React, { forwardRef } from 'react';
export const Link = forwardRef((_a, ref) => {
    var { as: Component = 'a', spacing = false, variant = 'primary', className } = _a, rest = __rest(_a, ["as", "spacing", "variant", "className"]);
    return (React.createElement(Component, Object.assign({}, rest, { ref: ref, className: cl('cds-link', `cds-link--variant-${variant}`, {
            'cds-link--spacing': spacing
        }, className) })));
});
Link.displayName = 'Link';
export default Link;
