import {
  AlignCenterRow,
  BodyShort,
  Button,
  Column,
  FlexBaseDiv,
  FlexBaseSpacedDiv,
  FlexCenterDiv,
  FlexDiv,
  FlexStartDiv,
  Heading,
  HorizontalSpace,
  Label,
  Loader,
  Pagination,
  PileCenterDiv,
  PileDiv,
  PileEndDiv,
  TextField,
  VerticalSpace
} from '@cegal/ds-components'
import { Assignment as EngagementIcon } from '@cegal/ds-icons/dist/Assignment'
import { ChevronLeft } from '@cegal/ds-icons/dist/ChevronLeft'
import { FilterAlt as FilterIcon } from '@cegal/ds-icons/dist/FilterAlt'
import { getEngagement, setEngagement } from 'actions'
import { CONSULTANTS_LIST_FAILURE } from 'constants/actionTypes'
import { DisplaySize, ViewMode } from 'declarations/app'
import { ConsultantsFilterOptions } from 'declarations/enum'
import { ConsultantsQuery, EngagementVacancy, Skill } from 'declarations/models'
import { standardLogger } from 'metrics/loggers'
import { useAppDispatch, useAppSelector } from 'store'
import styled from 'styled-components'

import AlertPanel from 'components/AlertPanel/AlertPanel'
import VacanciesCarousel from 'components/Carousel/VacanciesCarousel'
import { ConsultantsFilter, ConsultantsView } from 'components/Consultants'
import ConsultantsDescription from 'components/Consultants/ConsultantsDescription'
import EngagementCard from 'components/Engagements/Engagement/EngagementCard'
import EngagementVacancyCard from 'components/Engagements/Engagement/Vacancy/EngagementVacancyCard'
import ToggleView from 'components/Forms/ToggleView'
import PageContent from 'components/PageContent/PageContent'

import { useDeferredValue, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import useConsultantFilter from 'hooks/useConsultantFilter'
import useQuery from 'hooks/useQuery'

import { initialConsultantFindQuery, initialConsultantsQuery, isEmptyQuery, isSkillQuery } from 'utils/query'
import { getNextSort } from 'utils/sort'

import _ from 'lodash'

const FixedButtons = styled(PileDiv)`
  position: sticky;
  top: var(--cat-header-height);
  background-color: var(--cds-background-color);
  z-index: 3;
`

const EngagementFind = () => {
  const { id: engagementId } = useParams()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const apiReady = useAppSelector((state) => state.app.apiReady)
  const viewMode = useAppSelector((state) => state.app.settings.viewMode)
  const itemsPerPage = useAppSelector((state) => state.app.settings.itemsPerPage)
  const displaySize = useAppSelector((state) => state.app.displaySize)
  const engagement = useAppSelector((state) => state.engagements.get)
  const engagements = useAppSelector((state) => state.engagements.list)

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(true)
  const [_viewMode, setViewMode] = useState<ViewMode>(viewMode)

  const { query, setQuery } = useQuery<ConsultantsQuery>(initialConsultantFindQuery)

  const [inputValue, setInputValue] = useState(query?.search)
  const debouncedValue = useDeferredValue(inputValue)

  const handlePageChange = (page: number) => {
    window.scrollTo(0, 0)
    standardLogger('engagement.match.page', { page })
    setQuery({ page: page === 1 ? undefined : page?.toString() })
  }

  const {
    pages,
    items: consultants,
    loading,
    total
  } = useConsultantFilter(initialConsultantsQuery, handlePageChange)

  const handleSort = (sortKey?: string | undefined) => setQuery({ sort: getNextSort(query?.sort, sortKey) })

  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen)

  const setOrientationAndViewMode = (displaySize: DisplaySize) => {
    if (displaySize === 'sm' || displaySize === 'md') {
      setViewMode('card')
    } else {
      setViewMode(viewMode)
    }
  }

  const handleVacancyChanged = (newVacancyId: number) => {
    if (query.metaFilter === ConsultantsFilterOptions.SKILL) {
      setQuery({
        vacancy: newVacancyId,
        skills:
          _.find(engagement?.vacancies, { id: newVacancyId })
            ?.skills?.map((s: Skill) => s.id)
            ?.sort() ?? []
      })
    } else {
      setQuery({
        vacancy: newVacancyId
      })
    }
  }

  const loadPageData = () => {
    if (engagementId) {
      const parsedId = parseInt(engagementId)
      if (_.isNumber(parsedId)) {
        if (!_.isNil(engagements)) {
          const found = engagements?.find((obj) => obj.id === parsedId)
          if (found) {
            dispatch(setEngagement(found))
            return
          }
        }
        dispatch(getEngagement(parsedId))
      }
    }
  }

  useEffect(() => setViewMode(viewMode), [viewMode])

  useEffect(() => setOrientationAndViewMode(displaySize), [displaySize])

  useEffect(() => {
    if (apiReady) {
      loadPageData()
    }
  }, [apiReady])

  useEffect(() => {
    if (inputValue !== undefined) {
      setQuery((query: Partial<ConsultantsQuery> | undefined) => ({ ...query, search: debouncedValue }))
    }
  }, [debouncedValue])

  useEffect(() => {
    if (engagement && !_.isNumber(query.vacancy)) {
      const firstVacation: EngagementVacancy | undefined = _.first(engagement?.vacancies)
      setQuery({
        vacancy: firstVacation?.id,
        skills: firstVacation?.skills?.map((s) => s.id)?.sort() ?? []
      })
    }
  }, [engagement, query.vacancy])

  useEffect(() => {
    let newFilter: ConsultantsFilterOptions | undefined
    if (!_.isEmpty(query.metaFilter)) {
      if (isEmptyQuery('consultant', query)) {
        if (query.metaFilter !== ConsultantsFilterOptions.NONE) {
          newFilter = ConsultantsFilterOptions.NONE
        }
      } else {
        if (isSkillQuery('consultant', engagement, query)) {
          if (query.metaFilter !== ConsultantsFilterOptions.SKILL) {
            newFilter = ConsultantsFilterOptions.SKILL
          }
        } else {
          if (query.metaFilter !== ConsultantsFilterOptions.CUSTOM) {
            newFilter = ConsultantsFilterOptions.CUSTOM
          }
        }
      }
    }

    if (newFilter) {
      setQuery((query: any) => ({ ...query, metaFilter: newFilter }))
    }
  }, [query])

  const showVacancyCarousel = engagement?.vacancies && engagement.vacancies.length > 1

  return (
    <PageContent
      padding='0rem 1rem'
      maxWidth={viewMode === 'row' ? '100%' : '1600px'}
      isDrawerOpen={isDrawerOpen}
      drawer={
        !_.isNil(consultants) && (
          <ConsultantsFilter context='matches' engagement={engagement} onClose={() => toggleDrawer()} />
        )
      }
    >
      <FixedButtons>
        <VerticalSpace />
        <FlexBaseSpacedDiv>
          <FlexBaseDiv>
            <div>
              <Button variant='secondary' icon={<ChevronLeft size='1.5rem' />} onClick={() => navigate(-1)}>
                {t('buttons:back')}
              </Button>
            </div>
            <HorizontalSpace />
            <PileDiv>
              <FlexCenterDiv style={{ flexWrap: 'nowrap' }}>
                <Button
                  variant='secondary'
                  icon={<FilterIcon size='1.5rem' />}
                  onClick={() => setIsDrawerOpen(!isDrawerOpen)}
                >
                  <span style={{ whiteSpace: 'nowrap' }}>
                    {isDrawerOpen ? t('buttons:close-filter-options') : t('buttons:open-filter-options')}
                  </span>
                </Button>
                <HorizontalSpace />
                <TextField
                  hideLabel
                  description={t('label:search-description')}
                  label={t('label:filter')}
                  placeholder={t('label:search-title')}
                  value={inputValue}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value)}
                />
                <HorizontalSpace size='0.5' />
                {debouncedValue !== inputValue && <Loader size='small' />}
              </FlexCenterDiv>
              <VerticalSpace />
            </PileDiv>
          </FlexBaseDiv>
          <PileEndDiv flex='1'>
            <FlexStartDiv style={{ flexWrap: 'nowrap', justifyContent: 'flex-end' }}>
              <ToggleView
                context='matches.list'
                viewItemsPerPage
                viewToggleMode={!(displaySize === 'sm' || displaySize === 'md')}
              />
            </FlexStartDiv>
            <VerticalSpace />
          </PileEndDiv>
        </FlexBaseSpacedDiv>
      </FixedButtons>

      <Heading level='2' size='large'>
        {t('header:engagement-match-x', { x: engagement?.title })}
      </Heading>

      <VerticalSpace />
      <AlignCenterRow style={{ justifyContent: 'space-beetween' }}>
        <Column>
          {engagement && (
            <EngagementCard
              spacing
              item={engagement}
              style={{
                maxWidth: displaySize === 'sm' ? '1000px' : '500px'
              }}
            />
          )}
        </Column>
        <Column flex='2'>
          <PileDiv>
            <FlexDiv>
              {showVacancyCarousel && <HorizontalSpace size='3' />}
              <Label spacing>{t('label:vacancies-title')}</Label>
            </FlexDiv>
            {engagement?.vacancies ? (
              <PileDiv>
                {showVacancyCarousel ? ( // More than 1 vacancy
                  <VacanciesCarousel
                    size='medium'
                    vacancies={engagement.vacancies}
                    selectedVacancyId={query?.vacancy}
                    onChange={handleVacancyChanged}
                  />
                ) : (
                  <EngagementVacancyCard
                    spacing
                    style={{ maxWidth: displaySize === 'sm' ? '1000px' : '500px' }}
                    item={engagement.vacancies[0]}
                    increaseCutoffPoint
                  />
                )}
              </PileDiv>
            ) : (
              <BodyShort>{t('messages:no-vacancies-in-engagement')}</BodyShort>
            )}
          </PileDiv>
        </Column>
      </AlignCenterRow>
      <VerticalSpace size='0.5' />
      <ConsultantsDescription total={total} query={query} setQuery={setQuery} itemsPerPage={itemsPerPage} />
      {consultants === null && (
        <AlertPanel
          doNotRenderIfHeadIsActiveWithError
          watchFor={[CONSULTANTS_LIST_FAILURE]}
          buttonText={t('buttons:click-to-reload-the-page')!}
          reloadPage={loadPageData}
        />
      )}
      {loading && <Loader />}
      {!_.isNil(consultants) &&
      engagement?.vacancies &&
      ((engagement.vacancies.length > 1 && _.isNumber(query?.vacancy)) || !showVacancyCarousel) ? (
        <>
          <ConsultantsView
            query={query}
            viewMode={_viewMode}
            consultants={consultants}
            engagement={engagement}
            engagementVacancy={_.find(engagement?.vacancies, { id: query.vacancy })}
            previewInModal
            buttons={['match', 'follow']}
            onSortChange={handleSort}
          />
          {pages > 1 && (
            <>
              <VerticalSpace size='2' />
              <Pagination
                style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
                size={displaySize === 'sm' ? 'small' : 'medium'}
                count={pages}
                page={query?.page ? +query?.page : 1}
                onPageChange={handlePageChange}
              />
            </>
          )}
        </>
      ) : (
        <PileCenterDiv>
          <VerticalSpace size='5' />
          <Heading level='4' size='small'>
            {t('header:no-vacancy-selected')}
          </Heading>
          <BodyShort>{t('messages:no-vacancy-selected')}</BodyShort>
          <VerticalSpace />
          <EngagementIcon size='3rem' />
        </PileCenterDiv>
      )}
    </PageContent>
  )
}

export default EngagementFind
