import styled from 'styled-components'

export enum DataAlign {
  Row = 'row',
  Column = 'column'
}

export type DescriptionListSize = 'medium' | 'small'

type DescriptionListItemProps = {
  title?: string | React.ReactNode | JSX.Element
  details?: string | React.ReactNode | JSX.Element
  colon?: boolean
  size?: DescriptionListSize
  $align?: DataAlign
  $alignItems?: string
  justifyContent?: string
}

export const DescriptionTitle = styled.dt<{ size?: DescriptionListSize }>`
  font-size: ${(props) => (props.size === 'medium' ? 'var(--cds-size-4)' : 'var(--cds-size-3)')};
  margin: 0;
  opacity: 0.7;
`

export const DescriptionList = styled.dl<{
  $alignment?: DataAlign
  justifyContent?: string
}>`
  display: flex;
  flex-direction: ${({ $alignment }) => $alignment || 'initial'};
  justify-content: ${({ justifyContent }) => justifyContent || 'initial'};
  flex-wrap: wrap;
  margin: 0;
  width: 100%;
`

export const DescriptionSpan = styled.span<{ size?: DescriptionListSize }>`
  font-size: ${(props) => (props.size === 'medium' ? 'var(--cds-size-4)' : 'var(--cds-size-4)')};
  margin: 0;
`

const DescriptionDetails = styled.dl<{ size?: DescriptionListSize }>`
  font-size: ${(props) => (props.size === 'medium' ? 'var(--cds-size-4)' : 'var(--cds-size-3)')};
  margin: 0;
`

const DescriptionListItemWrapper = styled.div<{
  size: string
  $align?: DataAlign
  justifyContent?: string
  $alignItems?: string
}>`
  display: flex;
  padding-bottom: ${({ size }) => (size !== 'medium' ? '0px' : '2px')};
  flex-direction: ${({ $align }) => $align || 'initial'};
  justify-content: ${({ justifyContent }) => justifyContent || 'initial'};
  align-items: ${({ $alignItems }) => $alignItems || 'baseline'};
`

const ColonWrapper = styled.span`
  margin-right: 5px;
`

export const DescriptionListItem = ({
  title,
  details,
  $align = DataAlign.Row,
  size = 'medium',
  colon,
  $alignItems,
  ...rest
}: DescriptionListItemProps) => (
  <DescriptionListItemWrapper $align={$align} size={size} $alignItems={$alignItems} {...rest}>
    {title && (
      <DescriptionTitle size={size}>
        {title}
        {(colon || $align === DataAlign.Row) && <ColonWrapper>:</ColonWrapper>}
      </DescriptionTitle>
    )}
    <DescriptionDetails size={size}>{details || '-'}</DescriptionDetails>
  </DescriptionListItemWrapper>
)
