import { BodyLong, Heading } from '@cegal/ds-components'

const AdminHome = () => {
  return (
    <>
      <Heading level='2' size='medium' spacing>
        Admin
      </Heading>
      <BodyLong>You are admin</BodyLong>
    </>
  )
}

export default AdminHome
