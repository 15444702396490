import { Button, Checkbox, CheckboxGroup } from '@cegal/ds-components/'
import styled from 'styled-components'

import { FacetedFilterDropdownProps } from 'components/Forms/FacetedFilterSection/FacetedFilterDropdown'
import FacetedFilterSectionWrapper from 'components/Forms/FacetedFilterSection/FacetedFilterSectionWrapper'

import { useEffect, useState } from 'react'

import { Option } from 'utils/fieldUtils'

import { t } from 'i18next'

import _ from 'lodash'

export type FacetedFilterSectionProps<T extends Record<string, any>> = {
  options: Array<Option<T>> | undefined
  value: Array<Option<T>>
  onChange: (value: Array<Option<T>>) => void
  collapsed?: boolean
  label: string

  showClearButton?: boolean
  showSearchButton?: boolean
  showSettingsButton?: boolean

  settingsProps?: FacetedFilterDropdownProps
}

const ShowAllButton = styled(Button)`
  margin: 8px 0;
`

const StyledCheckboxGroup = styled(CheckboxGroup)`
  max-height: 250px;
  overflow-y: auto;
`

const FacetedFilterSection = <T extends Record<string, any>>({
  label,
  options = [],
  value,
  onChange,
  collapsed = false,

  showClearButton = false,
  showSearchButton = false,
  showSettingsButton = false,

  settingsProps
}: FacetedFilterSectionProps<T>) => {
  const [expanded, setExpanded] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState(value || [])
  const [searchValue, setSearchValue] = useState('')
  const [sortedOptions, setSortedOptions] = useState<Array<Option<T>>>(options || [])

  useEffect(() => {
    setSelectedOptions(value || [])
  }, [value])

  useEffect(() => {
    if (!_.isEmpty(options)) {
      const checkedOptions = options.filter((option) => selectedOptions.includes(option))
      const uncheckedOptions = options.filter((option) => !selectedOptions.includes(option))

      if (!_.isEmpty(searchValue)) {
        const filteredOptions = options.filter((option) =>
          option.label.toLowerCase().includes(searchValue.toLowerCase())
        )
        setSortedOptions(filteredOptions)
      } else {
        setSortedOptions([...checkedOptions, ...uncheckedOptions])
      }
    }
  }, [selectedOptions, searchValue, options])

  const handleCheckboxChange = (option: Option<T>) => {
    const updatedOptions = selectedOptions.includes(option)
      ? selectedOptions.filter((item) => item !== option)
      : [...selectedOptions, option]
    setSelectedOptions(updatedOptions)
  }

  const handleClear = () => {
    setSelectedOptions([])
    onChange([])
  }

  const renderOption = (option: Option<T>) => (
    <Checkbox
      value={option}
      key={option.value}
      size='small'
      onChange={() => handleCheckboxChange(option)}
      checked={selectedOptions.includes(option)}
    >
      {option?.label}
    </Checkbox>
  )

  const visibleOptions = expanded ? sortedOptions : sortedOptions.slice(0, 5)

  return (
    <FacetedFilterSectionWrapper
      collapsed={collapsed}
      label={label}
      showClearButton={showClearButton && selectedOptions.length > 0}
      onClear={handleClear}
      showSearchButton={showSearchButton}
      search={searchValue}
      onSearch={setSearchValue}
      showSettingsButton={showSettingsButton}
      settingsProps={settingsProps}
    >
      {!_.isEmpty(visibleOptions) ? (
        <StyledCheckboxGroup legend={null} value={selectedOptions} onChange={onChange}>
          {visibleOptions.map(renderOption)}
        </StyledCheckboxGroup>
      ) : (
        t('messages:no-filter-elements')
      )}
      {sortedOptions.length > 5 && (
        <div>
          <ShowAllButton size='small' variant='secondary' onClick={() => setExpanded(!expanded)}>
            {!expanded ? t('buttons:show-all') : t('buttons:show-less')}
          </ShowAllButton>
        </div>
      )}
    </FacetedFilterSectionWrapper>
  )
}

export default FacetedFilterSection
