var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as RadixTabs from '@radix-ui/react-tabs';
import cl from 'clsx';
import React, { forwardRef, useContext } from 'react';
import { Label } from 'components/typography';
import { TabsContext } from './Tabs';
export const Tab = forwardRef((_a, ref) => {
    var _b;
    var { className, as: Component = 'button', label, icon, iconPosition, value } = _a, rest = __rest(_a, ["className", "as", "label", "icon", "iconPosition", "value"]);
    const context = useContext(TabsContext);
    if (!label && !icon) {
        console.error('<Tabs.Tab/> needs label/icon');
        return null;
    }
    return (React.createElement(RadixTabs.Trigger, { value: value, asChild: true },
        React.createElement(Component, Object.assign({}, rest, { ref: ref, className: cl('cds-tabs__tab', `cds-tabs__tab--${(_b = context === null || context === void 0 ? void 0 : context.size) !== null && _b !== void 0 ? _b : 'medium'}`, `cds-tabs__tab-icon--${iconPosition}`, {
                'cds-tabs__tab--icon-only': icon && !label
            }, className) }),
            React.createElement(Label, { as: 'span', className: 'cds-tabs__tab-inner', size: context === null || context === void 0 ? void 0 : context.size },
                icon,
                label))));
});
Tab.displayName = 'Tabs.Tab';
export default Tab;
