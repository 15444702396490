import { BodyShort, Tabs } from '@cegal/ds-components'
import { Client, Engagement } from 'declarations/models'

import EngagementsView from 'components/Engagements/EngagementsView'
import { TabPanel } from 'components/styled'

import _ from 'lodash'

interface ClientEngagementsViewProps {
  client: Client
}

const ClientEngagementsView = ({ client }: ClientEngagementsViewProps) => {
  const groupedData: { [k in string]: Array<Engagement> } = _.groupBy(
    client.engagements,
    (engagement: Engagement) => engagement.status
  )

  return (
    <Tabs defaultValue='all_engagements'>
      <Tabs.List loop={false}>
        <Tabs.Tab value='all_engagements' label={`All engagements (${client.engagements?.length ?? 0})`} />
        <Tabs.Tab value='potential' label={`Potential (${groupedData.potential?.length ?? 0})`} />
        <Tabs.Tab
          value='ready_to_start'
          label={`Ready to start (${groupedData.readyToStart?.length ?? 0})`}
        />
        <Tabs.Tab value='in_progress' label={`In progress (${groupedData.inProgress?.length ?? 0})`} />
        <Tabs.Tab value='onhold' label={`On hold (${groupedData.onhold?.length ?? 0})`} />
        <Tabs.Tab value='finished' label={`Finished (${groupedData.finished?.length ?? 0})`} />
      </Tabs.List>

      <TabPanel noPadding value='potential'>
        <div style={{ padding: '2rem 0rem' }}>
          {_.isEmpty(groupedData.potential) ? (
            <BodyShort>There is no Potential engagements</BodyShort>
          ) : (
            <EngagementsView viewMode='card' engagements={groupedData.potential} />
          )}
        </div>
      </TabPanel>

      <TabPanel noPadding value='ready_to_start'>
        <div style={{ padding: '2rem 0rem' }}>
          {_.isEmpty(groupedData.readyToStart) ? (
            <BodyShort>There is no ready to start engagements</BodyShort>
          ) : (
            <EngagementsView viewMode='card' engagements={groupedData.readyToStart} />
          )}
        </div>
      </TabPanel>

      <TabPanel noPadding value='in_progress'>
        <div style={{ padding: '2rem 0rem' }}>
          {_.isEmpty(groupedData.inProgress) ? (
            <BodyShort>There is no in progress engagements</BodyShort>
          ) : (
            <EngagementsView viewMode='card' engagements={groupedData.inProgress} />
          )}
        </div>
      </TabPanel>

      <TabPanel noPadding value='onhold'>
        <div style={{ padding: '2rem 0rem' }}>
          {_.isEmpty(groupedData.onhold) ? (
            <BodyShort>There is no in hold engagements</BodyShort>
          ) : (
            <EngagementsView viewMode='card' engagements={groupedData.onhold} />
          )}
        </div>
      </TabPanel>

      <TabPanel noPadding value='finished'>
        <div style={{ padding: '2rem 0rem' }}>
          {_.isEmpty(groupedData.finished) ? (
            <BodyShort>There is no finished engagements</BodyShort>
          ) : (
            <EngagementsView viewMode='card' engagements={groupedData.finished} />
          )}
        </div>
      </TabPanel>

      <TabPanel noPadding value='all_engagements'>
        <div style={{ padding: '2rem 0rem' }}>
          {_.isEmpty(client.engagements) ? (
            <BodyShort>There is no in progress engagements</BodyShort>
          ) : (
            <EngagementsView viewMode='card' engagements={client.engagements} />
          )}
        </div>
      </TabPanel>
    </Tabs>
  )
}

export default ClientEngagementsView
