import {
  AlignEndColumn,
  Box,
  Button,
  Column,
  Datepicker,
  ErrorSummary,
  FlexEndDiv,
  Heading,
  HorizontalSpace,
  Label,
  PileDiv,
  Row,
  Select,
  TextField,
  Textarea,
  VerticalSpace
} from '@cegal/ds-components'
import { listConsultants } from 'actions'
import { EngagementVacancyConsultant, SimpleConsultant } from 'declarations/models'
import { useAppDispatch, useAppSelector } from 'store'

import { ConsultantSignature } from 'components/Consultants'
import ConsultantSelect from 'components/Forms/ConsultantSelect/ConsultantSelect'

import { RefObject, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'

interface EngagementVacancyConsultantFormProps {
  /* show loading animation for adding this evconsultant */
  isAdding?: boolean
  /* show loading animation for deleting this evconsultant */
  isEditing?: boolean
  // if this form is used for a new EVC, here is a temp ID so we can identify it
  tempId?: string
  // Custom title to the form
  title?: string
  // form ref, useful to attach modals and other menus so they behave properly
  formRef?: RefObject<HTMLDivElement>
  /* callback function when form submitted */
  onEvcChanged: (evc: Partial<EngagementVacancyConsultant>) => void
  /* callback function when form canceled */
  onClose: () => void
  // when editing, we should not edit consultant. Control if we should show it or not
  showConsultant?: boolean
  // vacancy title
  vacancyTitle?: string
  evc?: EngagementVacancyConsultant
  spacing?: boolean
}

const initialEvcValue: Partial<EngagementVacancyConsultant> = {
  hourly_rate: 0
}

const EngagementVacancyConsultantForm: React.FC<EngagementVacancyConsultantFormProps> = ({
  isAdding = false,
  isEditing = false,
  tempId,
  title,
  vacancyTitle,
  evc = initialEvcValue,
  onEvcChanged,
  showConsultant = true,
  formRef,
  onClose,
  spacing = true
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const apiReady = useAppSelector((state) => state.app.apiReady)
  const consultantsLoading = useAppSelector((state) => state.consultants.listing)
  const consultants = useAppSelector((state) => state.consultants.list)
  const language = useAppSelector((state) => state.app.settings.language)

  const statusOptions = [
    { label: t('label:matched'), value: 'matched' },
    { label: t('label:pitched'), value: 'pitched' }
  ]

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch
  } = useForm({
    defaultValues: evc
  })

  const editView = tempId === undefined

  const _consultant: Partial<SimpleConsultant> | undefined = watch('consultant')

  const onSubmit = (_evc: Partial<EngagementVacancyConsultant>) => {
    onEvcChanged({
      ..._evc,
      // make sure this remembers the value we passed initially
      consultant: _evc.consultant ?? evc.consultant,
      engagement_vacancy: _evc.engagement_vacancy ?? evc.engagement_vacancy
    })
    reset(initialEvcValue)
    onClose()
  }

  useEffect(() => {
    if (consultants === undefined && apiReady && !consultantsLoading) {
      dispatch(listConsultants())
    }
  }, [apiReady, consultants, consultantsLoading])

  return (
    <>
      <Box border>
        <Box.Header background>
          <Heading size='medium'>
            {!_.isNil(title)
              ? title
              : editView
                ? t('header:vacancy-consultant-edit-x', { x: _consultant?.name })
                : t('header:vacancy-consultant-add-x-to-y', {
                    x: _consultant?.name,
                    y: vacancyTitle
                  })}
          </Heading>
        </Box.Header>
        <Box.Body style={{ padding: '1rem' }}>
          <Row>
            {showConsultant && (
              <Column>
                {editView ? (
                  <PileDiv>
                    <Label>{t('label:consultant-title')}</Label>
                    <VerticalSpace />
                    <ConsultantSignature consultant={_consultant as SimpleConsultant} />
                  </PileDiv>
                ) : (
                  <Controller
                    control={control}
                    name='consultant'
                    rules={{
                      required: t('validation:engagement-no-consultant').toString()
                    }}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <ConsultantSelect
                          key={'manager-' + value?.id}
                          menuPortalTarget={formRef?.current}
                          menuPosition='fixed'
                          label={t('label:consultant-title') + '*'}
                          description={t('label:consultant-description')}
                          loading={consultantsLoading}
                          defaultValue={value}
                          onChange={onChange}
                          error={errors.consultant?.message}
                          consultants={consultants}
                        />
                      )
                    }}
                  />
                )}
                <VerticalSpace />
              </Column>
            )}

            <Column>
              <TextField
                type='number'
                label={t('label:hourly-rate-title')}
                description={t('label:hourly-rate-description')}
                error={errors.hourly_rate?.message}
                {...register('hourly_rate', {
                  valueAsNumber: true
                })}
              />
              <VerticalSpace />
            </Column>

            <Column style={{ position: 'relative' }}>
              <Controller
                control={control}
                name='status'
                render={({ field: { onChange, value } }) => (
                  <Select
                    {...register('status')}
                    label={t('label:status-title')}
                    description={t('label:status-description')}
                    error={errors?.status?.message?.toString()}
                  >
                    {statusOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Select>
                )}
              />
              <VerticalSpace />
            </Column>
          </Row>
          <Row>
            <Column>
              <Controller
                control={control}
                name='start_date'
                render={({ field: { onChange, value } }) => (
                  <Datepicker
                    lang={language}
                    label={t('label:start-date-title')}
                    description={t('label:start-date-description')}
                    value={value}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      onChange(event.target.value)
                    }}
                    error={errors?.start_date?.message}
                  />
                )}
              />
              <VerticalSpace />
            </Column>
            <Column>
              <Controller
                control={control}
                name='end_date'
                render={({ field: { onChange, value } }) => (
                  <Datepicker
                    lang={language}
                    label={t('label:end-date-title')}
                    description={t('label:end-date-description')}
                    value={value}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      onChange(event.target.value)
                    }}
                    error={errors?.end_date?.message}
                  />
                )}
              />
              <VerticalSpace />
            </Column>
          </Row>
          <Row>
            <Column>
              <Textarea
                label={t('label:notes-title')}
                description={t('label:notes-description')}
                error={errors.notes?.message}
                {...register('notes')}
              />
              <VerticalSpace />
            </Column>
          </Row>
        </Box.Body>
        <Box.Footer style={{ padding: '1rem' }}>
          <Row>
            <AlignEndColumn style={{ alignItems: 'flex-end' }}>
              <FlexEndDiv>
                <Button variant='primary' loading={isEditing || isAdding} onClick={handleSubmit(onSubmit)}>
                  {isEditing || isAdding ? t('buttons:saving') : t('buttons:save')}
                </Button>
                <HorizontalSpace />
                <Button variant='secondary' onClick={onClose}>
                  {editView ? t('buttons:discard') : t('buttons:cancel')}
                </Button>
              </FlexEndDiv>
            </AlignEndColumn>
          </Row>
          {!_.isEmpty(errors) && (
            <>
              <VerticalSpace />
              <ErrorSummary
                size='small'
                heading={t('validation:engagement-vacancy-consultant-error-summary-title')}
              >
                {Object.keys(errors).map((errorKey) => (
                  <ErrorSummary.Item key={errorKey} href='#'>
                    {_.get(errors, errorKey + '.message')}
                  </ErrorSummary.Item>
                ))}
              </ErrorSummary>
            </>
          )}
        </Box.Footer>
      </Box>
      {spacing && <VerticalSpace />}
    </>
  )
}

export default EngagementVacancyConsultantForm
