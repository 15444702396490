import * as types from 'constants/actionTypes'
import { AnyAction } from 'redux'

export interface AiState {
  messages: any
  messagesLoading: boolean
}

export const initialAiState: AiState = {
  messages: undefined,
  messagesLoading: false
}

const AiReducer = (state: AiState = initialAiState, action: AnyAction): AiState => {
  switch (action.type) {
    case types.AI_GET_REQUEST:
      return {
        ...state,
        messagesLoading: true,
        messages: undefined
      }
    case types.AI_GET_SUCCESS:
      return {
        ...state,
        messagesLoading: false,
        messages: action.payload
      }
    default:
      return state
  }
}

export default AiReducer
