var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Search } from '@cegal/ds-icons/dist/Search';
import cl from 'clsx';
import React, { forwardRef, useContext } from 'react';
import { Button } from 'components/button';
import { SearchContext } from './Search';
const SearchButton = forwardRef((_a, ref) => {
    var _b;
    var { className, children, disabled } = _a, rest = __rest(_a, ["className", "children", "disabled"]);
    const context = useContext(SearchContext);
    if (context === null) {
        console.warn('<Search.Button> has to be wrapped in <Search />');
        return null;
    }
    const { size, variant, onSubmit } = context;
    return (React.createElement(Button, Object.assign({}, rest, { ref: ref, className: cl('cds-search__button-search', className), type: 'submit', onClick: onSubmit, size: size, variant: variant === 'secondary' ? 'secondary' : 'primary', disabled: (_b = context === null || context === void 0 ? void 0 : context.disabled) !== null && _b !== void 0 ? _b : disabled }),
        React.createElement(Search, { size: '1.3rem', "aria-hidden": true }),
        children || React.createElement("span", { className: 'cds-sr-only' }, "S\u00F8k")));
});
SearchButton.displayName = 'Search.Button';
export default SearchButton;
