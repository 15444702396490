import { getConsultantAllocation } from 'actions'
import { AllocationResponse, RawAllocation } from 'declarations/api'
import { Allocation, Consultant } from 'declarations/models'
import { useAppDispatch, useAppSelector } from 'store'

import { useEffect, useMemo, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import _ from 'lodash'

export interface UseConsultantAllocationProps {
  consultant?: Consultant | null
  initialLoading?: boolean // for test purposes
}

const useConsultantAllocation = ({ consultant, initialLoading = false }: UseConsultantAllocationProps) => {
  const [allocationLoading, setAllocationLoading] = useState<boolean>(initialLoading)
  const { ref, inView } = useInView()

  const rawAllocation: AllocationResponse | null | undefined = useAppSelector(
    /* istanbul ignore next */ (state) =>
      consultant ? state.cache.consultantAllocations[consultant.id] : undefined
  )

  const dispatch = useAppDispatch()

  /* here we can massage raw allocation data to serve our components digested data */
  const allocation: Array<Allocation> | null = useMemo(
    () =>
      rawAllocation
        // allocations like holidays show up with engagement_vacancy_id as 0
        ?.filter((a: RawAllocation) => a.engagement_vacancy_id > 0)
        .map((a: RawAllocation) =>
          _.pick(
            a,
            'consultant_id',
            'start_date',
            'end_date',
            'duration',
            'engagement_vacancy_id',
            'last_modified_date',
            'last_modifier_id',
            'name',
            'note'
          )
        ) ?? null,
    [rawAllocation]
  )

  useEffect(() => {
    if (inView && _.isNumber(consultant?.id)) {
      if (rawAllocation !== undefined) {
        setAllocationLoading(false)
      } else {
        if (!allocationLoading && !!consultant) {
          setAllocationLoading(true)
          dispatch(getConsultantAllocation(consultant))
        }
      }
    }
  }, [rawAllocation, allocationLoading, inView, consultant])

  return { allocation, allocationLoading, ref }
}

export default useConsultantAllocation
