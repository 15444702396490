import { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import _ from 'lodash'

export type QueryValue = Record<string, any>

export const convertNumberOrBooleanOrObject = (value: string) =>
  value.toLowerCase() === 'true' || value.toLowerCase() === 'false' || value.match(/^\d*\.?\d+$/)
    ? JSON.parse(value.toLowerCase())
    : value.startsWith('{') && value.endsWith('}')
      ? JSON.parse(value)
      : value

export const parseSearchParamsToT = <T extends QueryValue = QueryValue>(
  params: URLSearchParams
): Partial<T> => {
  const res: T = {} as T
  params?.forEach((value: string, key: keyof T) => {
    let _value = convertNumberOrBooleanOrObject(value)
    if (typeof _value === 'string' && _value.indexOf(',') >= 0) {
      _value = _value
        .split(',')
        .map(convertNumberOrBooleanOrObject)
        .sort((a, b) => a - b)
    }
    res[key] = _value
  })
  return res
}

export const parseTToSearchParams = <T extends QueryValue = QueryValue>(params: T): URLSearchParams => {
  const _params = new URLSearchParams()
  Object.entries(params)?.forEach(([key, value]) => {
    /* istanbul ignore next */
    if (!_.isEmpty(value?.toString()) && value?.toString() !== 'false') {
      if (Array.isArray(value)) {
        _params.append(key, value.sort((a, b) => a - b).join())
      } else {
        typeof value !== 'string' ? _params.append(key, JSON.stringify(value)) : _params.append(key, value)
      }
    }
  })
  return _params
}

const useQuery = <T extends QueryValue = QueryValue>(
  /* istanbul ignore next */ initialQueryObject: Partial<T> = {}
) => {
  const [searchParams, setSearchParams] = useSearchParams(parseTToSearchParams(initialQueryObject))
  const [query, setQuery] = useState<Partial<T>>(initialQueryObject)
  const debug: boolean = false

  useEffect(() => {
    /* istanbul ignore if */
    if (debug) console.log('useQuery updating', parseSearchParamsToT<T>(searchParams))
    setQuery(parseSearchParamsToT<T>(searchParams))
  }, [searchParams])

  const updateQuery = useCallback(
    (nextInit: Partial<T> | ((prevQueryValue?: Partial<T>) => Partial<T>)) => {
      if (typeof nextInit === 'function') {
        setSearchParams(
          (prev: URLSearchParams) => {
            /* istanbul ignore if */
            if (debug)
              console.log('updateQuery func', {
                ...query,
                ...nextInit(parseSearchParamsToT<T>(prev))
              })

            return parseTToSearchParams({
              ...query,
              ...nextInit(parseSearchParamsToT<T>(prev))
            })
          },
          {
            replace: true
          }
        )
        return
      }
      /* istanbul ignore if */
      if (debug)
        console.log('updateQuery no-func', {
          ...query,
          ...nextInit
        })
      setSearchParams(
        parseTToSearchParams({
          ...query,
          ...nextInit
        }),
        {
          replace: true
        }
      )
    },
    [query]
  )

  return { query, setQuery: updateQuery }
}

export default useQuery
