import {
  AlignEndColumn,
  Button,
  FlexBaseDiv,
  FlexDiv,
  Heading,
  HorizontalSpace,
  Modal,
  Row
} from '@cegal/ds-components'

import { useRef } from 'react'
import { useTranslation } from 'react-i18next'

export interface ConfirmModalProps {
  open: boolean
  title: string
  body: string
  anchorEl?: HTMLElement
  onConfirm: () => void
  onCancel: () => void
  onClose: () => void
}

const ConfirmModal = ({ open, title, body, onConfirm, onCancel, anchorEl, onClose }: ConfirmModalProps) => {
  const { t } = useTranslation()
  const modalContainerRef = useRef(null)
  return (
    <FlexBaseDiv ref={modalContainerRef}>
      {modalContainerRef?.current && (
        <Modal
          // @ts-ignore
          parentSelector={() => anchorEl ?? modalContainerRef.current!}
          open={open}
          aria-label={t('home:edit-source')}
          closeButton={false}
          onClose={onClose}
          shouldCloseOnOverlayClick={false}
        >
          <Modal.Content style={{ minWidth: '400px' }}>
            <Modal.Content.Header>
              <Heading spacing level='2' size='medium'>
                {title}
              </Heading>
            </Modal.Content.Header>
            <Modal.Content.Body>{body}</Modal.Content.Body>
            <Modal.Content.Footer>
              <Row>
                <AlignEndColumn style={{ alignItems: 'flex-end' }}>
                  <FlexDiv>
                    <Button variant='secondary' onClick={onCancel}>
                      {t('buttons:close')}
                    </Button>
                    <HorizontalSpace />
                    <Button onClick={onConfirm}>{t('buttons:confirm')}</Button>
                  </FlexDiv>
                </AlignEndColumn>
              </Row>
            </Modal.Content.Footer>
          </Modal.Content>
        </Modal>
      )}
    </FlexBaseDiv>
  )
}

export default ConfirmModal
