import { Column, HorizontalSpace, Row, Tabs, VerticalSpace } from '@cegal/ds-components/dist'
import { Groups2 } from '@cegal/ds-icons'
import { ContactPage } from '@cegal/ds-icons/dist/ContactPage'
import { WorkspacePremium } from '@cegal/ds-icons/dist/WorkspacePremium'
import { standardLogger } from 'metrics/loggers'

import {
  ConsultantCV,
  ConsultantCertificates,
  ConsultantDetail,
  ConsultantEngagements
} from 'components/Consultants/Consultant/index'
import { TabPanel } from 'components/styled'

import { useTranslation } from 'react-i18next'

const ConsultantViewContent = ({ consultant }: any) => {
  const { t } = useTranslation()

  const handleConsultantViewTabChange = (tab: string) => {
    standardLogger('consultants.view.tab.' + tab)
  }

  enum TabName {
    CV = 'cv',
    ALLOCATION = 'allocation',
    CERTIFICATE = 'certificate',
    ENGAGEMENTS = 'engagements'
  }

  return (
    <Row>
      <Column style={{ maxWidth: '100%', minWidth: '400px' }}>
        <ConsultantDetail consultant={consultant} />
        <VerticalSpace />
      </Column>

      <Column flex='3'>
        <Tabs defaultValue={TabName.CV} onChange={handleConsultantViewTabChange}>
          <Tabs.List loop>
            <Tabs.Tab
              value={TabName.CV}
              label={
                <>
                  <ContactPage size='1.5rem' />
                  <HorizontalSpace size='0.3' />
                  {t('label:CV')}
                </>
              }
            />

            {/* <Tabs.Tab
              data-cy='consultantAllocationTab'
              value={TabName.ALLOCATION}
              label={
                <>
                  <CalendarToday size='1.5rem' />
                  {t('label:allocation')}
                </>
              }
            /> */}

            <Tabs.Tab
              data-cy='consultantCertificateTab'
              value={TabName.CERTIFICATE}
              label={
                <>
                  <WorkspacePremium size='1.5rem' />
                  {t('label:certificates')}
                </>
              }
            />

            <Tabs.Tab
              data-cy='consultantEngagementsTab'
              value={TabName.ENGAGEMENTS}
              label={
                <>
                  <Groups2 size='1.5rem' />
                  {t('label:engagements')}
                </>
              }
            />
          </Tabs.List>

          <TabPanel value={TabName.CV}>
            <ConsultantCV consultant={consultant} />
          </TabPanel>

          {/*
          <TabPanel value={TabName.ALLOCATION}>
            <AvailabilityCalendar consultant={consultant} />
          </TabPanel>
          */}

          <TabPanel value={TabName.CERTIFICATE}>
            <ConsultantCertificates consultant={consultant} />
          </TabPanel>

          <TabPanel value={TabName.ENGAGEMENTS}>
            <ConsultantEngagements consultant={consultant} />
          </TabPanel>
        </Tabs>
      </Column>
    </Row>
  )
}

export default ConsultantViewContent
