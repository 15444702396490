import { Checkbox } from 'components/form/checkbox';
import React from 'react';
import { components } from 'react-select';
const Option = (props) => {
    var _a, _b;
    return (React.createElement(components.Option, Object.assign({}, props),
        React.createElement(Checkbox, { onChange: (e) => {
                e.preventDefault();
                e.stopPropagation();
            }, checked: props.isSelected }, ((_a = props.data) === null || _a === void 0 ? void 0 : _a.label) || ((_b = props.data) === null || _b === void 0 ? void 0 : _b.name))));
};
Option.displayName = 'SelectMultiple.Option';
export default Option;
