import * as types from 'constants/actionTypes'
import * as urls from 'constants/urls'
import api from 'api'
import { SkillRequest, SkillResponse } from 'declarations/api'
import { sprintf } from 'sprintf-js'

import _ from 'lodash'

export const deleteSkill = (skill: SkillResponse) => {
  return api.call({
    method: 'DELETE',
    url: sprintf(urls.SKILLS_DELETE_URL, { id: skill.id }),
    skipFake: true,
    body: skill,
    cascadeFailureError: true,
    type: {
      request: types.SKILLS_DELETE_REQUEST,
      success: types.SKILLS_DELETE_SUCCESS,
      failure: types.SKILLS_DELETE_FAILURE
    }
  })
}

export const editSkill = (skill: SkillResponse) => {
  const payload: SkillRequest = {
    ..._.omit(skill, ['id', 'proficiency', 'years']),
    name: skill.name
  }
  return api.call({
    method: 'PUT',
    url: sprintf(urls.SKILLS_EDIT_URL, { id: skill.id }),
    skipFake: true,
    body: payload,
    cascadeFailureError: true,
    type: {
      request: types.SKILLS_EDIT_REQUEST,
      success: types.SKILLS_EDIT_SUCCESS,
      failure: types.SKILLS_EDIT_FAILURE
    }
  })
}

export const getSkill = (id: number) => {
  return api.call({
    url: sprintf(urls.SKILLS_GET_URL, { id }),
    skipFake: true,
    cascadeFailureError: true,
    type: {
      request: types.SKILLS_GET_REQUEST,
      success: types.SKILLS_GET_SUCCESS,
      failure: types.SKILLS_GET_FAILURE
    }
  })
}

export const listSkills = () => {
  return api.call({
    skipFake: true,
    url: urls.SKILLS_LIST_URL,
    cascadeFailureError: true,
    type: {
      request: types.SKILLS_LIST_REQUEST,
      success: types.SKILLS_LIST_SUCCESS,
      failure: types.SKILLS_LIST_FAILURE
    }
  })
}

export const newSkill = (skill: SkillRequest) => {
  return api.call({
    method: 'POST',
    skipFake: true,
    url: urls.SKILLS_NEW_URL,
    body: skill,
    cascadeFailureError: true,
    type: {
      request: types.SKILLS_NEW_REQUEST,
      success: types.SKILLS_NEW_SUCCESS,
      failure: types.SKILLS_NEW_FAILURE
    }
  })
}
