var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Help } from '@cegal/ds-icons/dist/Help';
import cl from 'clsx';
import { Popover } from 'components/popover';
import React, { forwardRef, useMemo, useRef, useState } from 'react';
import { mergeRefs } from 'util/index';
const HelpText = forwardRef((_a, ref) => {
    var { className, children, placement = 'top', strategy = 'absolute', title = 'hjelp', variant = 'primary', onClick, popoverAnchorEl = null } = _a, rest = __rest(_a, ["className", "children", "placement", "strategy", "title", "variant", "onClick", "popoverAnchorEl"]);
    const buttonRef = useRef(null);
    const mergedRef = useMemo(() => mergeRefs([buttonRef, ref]), [ref]);
    const [open, setOpen] = useState(false);
    return (React.createElement("div", { className: 'cds-help-text' },
        React.createElement("button", Object.assign({}, rest, { ref: mergedRef, className: cl('cds-help-text__button', className), onClick: (e) => {
                setOpen((x) => !x);
                onClick === null || onClick === void 0 ? void 0 : onClick(e);
            }, title: title, type: 'button', "aria-expanded": open }),
            React.createElement(Help, { size: '1.5rem', className: 'cds-help-text__icon' })),
        React.createElement(Popover, { onClose: () => setOpen(false), className: 'cds-help-text__popover', open: open, anchorEl: popoverAnchorEl !== null && popoverAnchorEl !== void 0 ? popoverAnchorEl : buttonRef.current, placement: placement, strategy: strategy, variant: variant },
            React.createElement(Popover.Content, { className: 'cds-body-short' }, children))));
});
HelpText.displayName = 'HelpText';
export default HelpText;
