import { Link } from '@cegal/ds-components'
import styled from 'styled-components'

import { NoWrapSpan } from 'components/styled'

export const ToCDiv = styled.nav`
  flex: 1;
  position: sticky;
  align-self: flex-start;
  height: auto;
  top: 5rem;
  left: 0rem;
`
ToCDiv.displayName = 'ToCDiv'

const ToC = ({ headings, activeId }: any) => {
  return (
    <ul>
      {headings?.map((heading: any) => (
        <li key={heading.id} style={{ marginLeft: `${heading.level - 2}em` }}>
          <Link href={`#${heading.id}`} style={{ fontWeight: activeId === heading.id ? 'bold' : 'normal' }}>
            <NoWrapSpan>{heading.text}</NoWrapSpan>
          </Link>
        </li>
      ))}
    </ul>
  )
}

export default ToC
