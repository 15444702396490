import { Label, Loader, LoaderProps, PileCenterDiv } from '@cegal/ds-components'
import styled from 'styled-components'

import { forwardRef } from 'react'

type FullScreenLoaderProps = { label?: string } & Omit<LoaderProps, 'ref'>

const FullScreenWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100%;
`

const StretchContentWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
`

const FullScreenLoader = forwardRef<SVGSVGElement, FullScreenLoaderProps>(
  ({ size = '2xlarge', label, ...rest }, ref) => (
    <FullScreenWrapper>
      <PileCenterDiv>
        <Loader {...rest} ref={ref} size={size} />
        {label && <Label>{label}</Label>}
      </PileCenterDiv>
    </FullScreenWrapper>
  )
)

const StretchLoader = forwardRef<SVGSVGElement, FullScreenLoaderProps>(
  ({ size = '2xlarge', ...rest }, ref) => (
    <StretchContentWrapper>
      <Loader {...rest} ref={ref} size={size} />
    </StretchContentWrapper>
  )
)

export { FullScreenLoader, StretchLoader }
