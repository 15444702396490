import { useEffect, useRef, useState } from 'react'

/**
 * Utility to spy on scroll position
 * @param ids
 * @param options
 */
const useScrollSpy = (ids: any, options: any) => {
  const [activeId, setActiveId] = useState<any>()
  const observer: any = useRef()

  useEffect(() => {
    const elements = ids.map((id: any) => document.getElementById(id))
    observer.current?.disconnect()
    /* istanbul ignore next */
    observer.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry?.isIntersecting) {
          setActiveId(entry.target.id)
        }
      })
    }, options)
    elements.forEach((el: any) => {
      /* istanbul ignore next */
      if (el) {
        observer.current?.observe(el)
      }
    })
    return () => observer.current?.disconnect()
  }, [ids, options])

  return activeId
}

export default useScrollSpy
