import { Document, Packer, Paragraph, TextRun } from 'docx'
import { saveAs } from 'file-saver'

export function generateDocument(output: string) {
  Packer.toBlob(
    new Document({
      sections: [
        {
          properties: {},
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: output
                })
              ]
            })
          ]
        }
      ]
    })
  ).then((blob) => {
    saveAs(blob, 'summarized.docx')
  })
}
