import {
  BodyShort,
  Button,
  FlexBaseDiv,
  FlexDiv,
  Heading,
  HorizontalSpace,
  Modal,
  Panel,
  PileEndDiv,
  Table,
  Tag,
  VerticalSpace
} from '@cegal/ds-components'
import { CheckCircleOutline, ErrorOutline } from '@cegal/ds-icons'
import { newAdminSync } from 'actions'
import { AdminSyncing } from 'declarations/models'
import { useAppDispatch, useAppSelector } from 'store'

import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { toLocalDateFormat } from 'utils/date'

export interface SyncModalProps {
  sync: AdminSyncing | undefined
  open: boolean
  onClose: () => void
}

const SyncModal = ({ sync, open, onClose }: SyncModalProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const posted = useAppSelector((state) => state.adminSyncing.posted)
  const posting = useAppSelector((state) => state.adminSyncing.posting)

  const onSubmit = () => {
    dispatch(newAdminSync(sync!.id))
  }

  useEffect(() => {
    if (posted !== undefined) {
      onClose()
    }
  }, [posted])

  const modalContainerRef = useRef(null)

  return (
    <FlexBaseDiv ref={modalContainerRef}>
      {modalContainerRef?.current && (
        <Modal
          parentSelector={() => modalContainerRef.current!}
          open={open}
          aria-label={sync?.source_name}
          closeButton={false}
          onClose={onClose}
          shouldCloseOnOverlayClick={false}
        >
          <Modal.Content>
            <Panel style={{ minWidth: '600px' }}>
              <Heading spacing level='2' size='medium'>
                {sync?.source_name}
              </Heading>
              <BodyShort key={sync?.id} size='medium' as='span'>
                {`Last Synced: ${toLocalDateFormat(sync!.synced_date)}`}
              </BodyShort>
              <VerticalSpace size='2' />
              <FlexDiv>
                {sync!.status_code < 300 ? (
                  <Tag variant='success'>
                    <CheckCircleOutline size='1.5rem' />
                    <HorizontalSpace />
                    Success
                  </Tag>
                ) : (
                  <Tag variant='error'>
                    <ErrorOutline size='1.5rem' />
                    <HorizontalSpace />
                    Error
                  </Tag>
                )}
              </FlexDiv>
              <VerticalSpace size='2' />
              <Table.Header>
                <Table.Row>
                  <Table.ColumnHeader>{t('label:name')}</Table.ColumnHeader>
                  <Table.ColumnHeader>{t('label:total')}</Table.ColumnHeader>
                  <Table.ColumnHeader>{t('label:newly-added')}</Table.ColumnHeader>
                  <Table.ColumnHeader>{t('label:updated')}</Table.ColumnHeader>
                  <Table.ColumnHeader>{t('label:status')}</Table.ColumnHeader>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {sync?.entities?.map((entity) => (
                  <Table.Row key={entity.entity_id}>
                    <Table.DataCell>{entity.entity_name}</Table.DataCell>
                    <Table.DataCell>{entity.total_entities}</Table.DataCell>
                    <Table.DataCell>{entity.new_entities}</Table.DataCell>
                    <Table.DataCell>{entity.updated_entities}</Table.DataCell>
                    <Table.DataCell>
                      {sync.status_code < 300 ? (
                        <Tag variant='success'>
                          <CheckCircleOutline size='1.5rem' />
                          <HorizontalSpace />
                          {entity.status_code}
                        </Tag>
                      ) : (
                        <Tag variant='error'>
                          <ErrorOutline size='1.5rem' />
                          <HorizontalSpace />
                          {entity.status_code}
                        </Tag>
                      )}
                    </Table.DataCell>
                  </Table.Row>
                ))}
              </Table.Body>
              <VerticalSpace size='2' />
              <PileEndDiv>
                <FlexDiv>
                  <Button
                    variant='secondary'
                    onClick={() => {
                      onClose()
                    }}
                  >
                    {t('buttons:close')}
                  </Button>
                  <HorizontalSpace />
                  <Button loading={posting} onClick={onSubmit}>
                    {t('buttons:sync')}
                  </Button>
                </FlexDiv>
              </PileEndDiv>
            </Panel>
          </Modal.Content>
        </Modal>
      )}
    </FlexBaseDiv>
  )
}

export default SyncModal
