var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useContext } from 'react';
import { useFormField } from 'components/form/useFormField';
import { CheckboxGroupContext } from './CheckboxGroup';
import { omit } from 'util/index';
const useCheckbox = (_a) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    const checkboxGroup = useContext(CheckboxGroupContext);
    const _b = useFormField(omit(props, ['description']), 'checkbox'), { inputProps } = _b, rest = __rest(_b, ["inputProps"]);
    if (checkboxGroup) {
        if (props.checked) {
            console.warn('`checked` is unsupported on <Checkbox> elements within a <CheckboxGroup>. Please set a `value` or `defaultValue` on <CheckboxGroup> instead.');
        }
        if (props.value === undefined) {
            console.warn('A <Checkbox> element within a <CheckboxGroup> requires a `value` property.');
        }
    }
    return Object.assign(Object.assign({}, rest), { inputProps: Object.assign(Object.assign({}, inputProps), { checked: (checkboxGroup === null || checkboxGroup === void 0 ? void 0 : checkboxGroup.value) ? checkboxGroup.value.includes(props.value) : props.checked, defaultChecked: (checkboxGroup === null || checkboxGroup === void 0 ? void 0 : checkboxGroup.defaultValue)
                ? checkboxGroup.defaultValue.includes(props.value)
                : props.defaultChecked, onChange: (e) => {
                props.onChange && props.onChange(e);
                checkboxGroup && checkboxGroup.toggleValue(props.value);
            } }) });
};
export default useCheckbox;
