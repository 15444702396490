import { Chart } from '@cegal/ds-graph'
import { EngagementVacancyConsultant } from 'declarations/models'
import { DateTime } from 'luxon'

import { useMemo } from 'react'

import { getConsultantsFromClient } from 'hooks/useClientFilterUtils'

import { ClientDetailProps } from './ClientDetail'

const ClientStatisticsView = ({ client }: ClientDetailProps) => {
  const consultantsList: Array<EngagementVacancyConsultant> = useMemo(
    () => getConsultantsFromClient(client),
    [client]
  )

  const timeSpent: { [k in string]: number } = {}

  consultantsList.forEach((c: EngagementVacancyConsultant) => {
    let days = 0
    if (c.start_date) {
      const date1 = DateTime.fromISO(c.start_date)
      const date2 = DateTime.now()
      const diff = date2.diff(date1, ['years', 'months', 'days'])
      days = diff.days
    }
    timeSpent[c.consultant?.name ?? ''] = days
  })

  const options = {
    indexAxis: 'y' as const,
    elements: {
      bar: {
        borderWidth: 2
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        grid: {
          display: false
        }
      }
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'right' as const
      },
      title: {
        display: true,
        text: 'Most used consultants'
      }
    }
  }

  const data = {
    labels: Object.keys(timeSpent).sort((a, b) => (timeSpent[b] >= timeSpent[a] ? 1 : -1)),
    datasets: [
      {
        label: 'Time',
        data: Object.values(timeSpent).sort((a, b) => (b >= a ? 1 : -1))
      }
    ]
  }

  return (
    <div style={{ width: '500px' }}>
      <Chart.Bar data={data} options={options} />
    </div>
  )
}

export default ClientStatisticsView
