import { EngagementVacancyConsultant } from 'declarations/models'
import mockEngagement from 'mocks/engagement'

export default [
  {
    consultant: {
      id: 1,
      name: 'aaa',
      email: 'aaa@cegal.com',
      image_href: 'abcd'
    },
    hourly_rate: 1,
    status: 'Matched',
    start_date: '2022-09-28',
    end_date: '2022-09-28',
    notes: 'note aaa',
    engagement_vacancy: {
      id: 1,
      title: 'engagement vacancy',
      engagement: {
        id: mockEngagement.id,
        title: mockEngagement.title
      }
    }
  },
  {
    consultant: {
      id: 2,
      name: 'bbb',
      email: 'bbb@cegal.com',
      image_href: 'abcd'
    },
    hourly_rate: 1,
    status: 'Matched',
    start_date: '2022-09-28',
    end_date: '2022-09-28',
    notes: 'note bbb',
    engagement_vacancy: {
      id: 1,
      title: 'engagement vacancy',
      engagement: {
        id: mockEngagement.id,
        title: mockEngagement.title
      }
    }
  },
  {
    consultant: {
      id: 3,
      name: 'ccc',
      email: 'ccc@cegal.com',
      image_href: 'abcd'
    },
    hourly_rate: 1,
    status: 'Matched',
    start_date: '2022-09-28',
    end_date: '2022-09-28',
    notes: 'note ccc',
    engagement_vacancy: {
      id: 1,
      title: 'engagement vacancy',
      engagement: {
        id: mockEngagement.id,
        title: mockEngagement.title
      }
    }
  },
  {
    consultant: {
      id: 4,
      name: 'ddd',
      email: 'ddd@cegal.com',
      image_href: 'abcd'
    },
    hourly_rate: 1,
    status: 'Matched',
    start_date: '2022-09-28',
    end_date: '2022-09-28',
    notes: 'note ddd',
    engagement_vacancy: {
      id: 1,
      title: 'engagement vacancy',
      engagement: {
        id: mockEngagement.id,
        title: mockEngagement.title
      }
    }
  }
] as Array<EngagementVacancyConsultant>
