import {
  AlignEndColumn,
  BodyShort,
  Button,
  Column,
  FlexStartDiv,
  Heading,
  HorizontalSpace,
  Row,
  Stepper,
  StepperOrientation,
  VerticalSpace
} from '@cegal/ds-components'
import { ChevronLeft } from '@cegal/ds-icons/dist/ChevronLeft'
import { ChevronRight } from '@cegal/ds-icons/dist/ChevronRight'
import { Help } from '@cegal/ds-icons/dist/Help'
import { DisplaySize } from 'declarations/app'
import { eventLogger } from 'metrics/loggers'
import { useAppSelector } from 'store'

import Carousel, { CarouselItem } from 'components/Carousel/Carousel'
import {
  EngagementFormProps,
  EngagementStepFormProps
} from 'components/Engagements/Engagement/Form/EngagementForm'

import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Step1 from './Steps/Step1'
import Step2 from './Steps/Step2'
import Step3 from './Steps/Step3'
import Step4 from './Steps/Step4'
import Step5 from './Steps/Step5'
import Step6 from './Steps/Step6'

import _ from 'lodash'

export interface Step {
  title: string
  value: string
  interactive: boolean
  component: (props: EngagementStepFormProps) => JSX.Element
  description?: string
  fields?: Array<string>
}

const EngagementWizardForm = (props: EngagementFormProps) => {
  const { t } = useTranslation()
  const {
    formState: { errors }
  } = useFormContext()

  const doDecreaseStep = () => {
    props.setActiveStep(props.activeStep - 1)
  }

  const doSetStep = (i: number) => {
    props.setActiveStep(i)
  }

  const doIncreaseStep = () => {
    props.setActiveStep(props.activeStep + 1)
  }

  const decreaseStep = (e?: any) => {
    if (e) {
      eventLogger(e)
    }
    if (props.thereAreUnsavedVacations) {
      props.activateConfirmModal(doDecreaseStep)
    } else {
      doDecreaseStep()
    }
  }

  const increaseStep = (e?: any) => {
    if (e) {
      eventLogger(e)
    }
    if (props.thereAreUnsavedVacations) {
      props.activateConfirmModal(doIncreaseStep)
    } else {
      doIncreaseStep()
    }
  }

  const [orientation, setOrientation] = useState<StepperOrientation>('horizontal')
  const displaySize = useAppSelector((state) => state.app.displaySize)

  const steps: Array<Step> = [
    {
      title: t('header:engagement-new-wizard-step1-title'),
      description: t('label:engagement-step1'),
      value: 'title',
      interactive: true,
      component: Step1,
      fields: ['title']
    },
    {
      title: t('header:engagement-new-wizard-step2-title'),
      description: t('label:engagement-step2'),
      value: 'description',
      interactive: true,
      component: Step2,
      fields: ['client', 'source', 'location', 'description']
    },
    {
      title: t('header:engagement-new-wizard-step3-title'),
      description: t('label:engagement-step3'),
      value: 'dates',
      interactive: true,
      component: Step3,
      fields: ['start_date', 'end_date', 'optional_extension_period', 'deadline', 'file']
    },
    {
      title: t('header:engagement-new-wizard-step4-title'),
      value: 'strategic',
      interactive: true,
      component: Step4,
      fields: [
        'status',
        'energy_domain',
        'integration',
        'reusable_database',
        'relevant_technologies',
        'mentoring',
        'financially_smart',
        'notes'
      ]
    },
    {
      title: t('header:engagement-new-wizard-step5-title'),
      description: t('label:engagement-step5'),
      value: 'vacancies',
      interactive: true,
      component: Step5,
      fields: ['vacancies']
    },
    {
      title: t('header:engagement-new-wizard-step6-title'),
      description: t('label:engagement-step6'),
      value: 'submit',
      interactive: true,
      component: Step6,
      fields: []
    }
  ]

  const updateOrientation = (displaySize: DisplaySize) => {
    if (displaySize === 'sm' || displaySize === 'md') {
      if (orientation !== 'vertical') {
        setOrientation('vertical')
      }
    } else {
      if (orientation !== 'horizontal') {
        setOrientation('horizontal')
      }
    }
  }

  const handleActiveStepChange = (i: number) => {
    if (props.thereAreUnsavedVacations) {
      props.activateConfirmModal(() => doSetStep(i))
    } else {
      doSetStep(i)
    }
  }

  useEffect(() => {
    updateOrientation(displaySize)
  }, [displaySize])

  return (
    <>
      <VerticalSpace />

      <Stepper activeStep={props.activeStep} orientation={orientation} onStepChange={handleActiveStepChange}>
        {steps.map((s: Step) => (
          <Stepper.Step
            key={s.value}
            interactive={s.interactive}
            error={_.intersection(Object.keys(errors), s.fields ?? []).length > 0}
          >
            {s.title}
          </Stepper.Step>
        ))}
      </Stepper>
      <VerticalSpace size='2' />
      <Carousel activeIndex={props.activeStep}>
        {steps.map((step: Step) => (
          <CarouselItem key={step.value}>
            <>
              <Heading style={{ display: 'flex' }} size='medium' level='2' spacing>
                {step.title}
                <HorizontalSpace />
              </Heading>
              <VerticalSpace size='2' />
              {step.description && (
                <>
                  <FlexStartDiv>
                    <Help size='1.5rem' style={{ minWidth: '24px' }} />
                    <HorizontalSpace size='0.5' />
                    <BodyShort>{step.description}</BodyShort>
                  </FlexStartDiv>
                  <VerticalSpace size='2' />
                </>
              )}
              <step.component {...props} increaseStep={increaseStep} />
              <VerticalSpace size='2' />
              <Row>
                <Column>
                  <div>
                    <Button
                      data-amplitude={`engagements.${props.editView ? 'edit' : 'new'}.wizard.carrousel.prev`}
                      disabled={props.activeStep === 1}
                      variant='secondary'
                      onClick={decreaseStep}
                      icon={<ChevronLeft size='1.5rem' />}
                    >
                      {t('buttons:previous')}
                    </Button>
                  </div>
                </Column>
                <AlignEndColumn>
                  {steps[props.activeStep]?.interactive === true && (
                    <div>
                      <Button
                        data-amplitude={`engagements.${
                          props.editView ? 'edit' : 'new'
                        }.wizard.carrousel.next`}
                        variant='secondary'
                        disabled={steps[props.activeStep].interactive === false}
                        onClick={increaseStep}
                        icon={<ChevronRight size='1.5rem' />}
                      >
                        {t('buttons:next')}
                      </Button>
                    </div>
                  )}
                </AlignEndColumn>
              </Row>
            </>
          </CarouselItem>
        ))}
      </Carousel>
    </>
  )
}

export default EngagementWizardForm
