import { Button, ButtonSize, Popover } from '@cegal/ds-components'
import { PlaylistAddCheck } from '@cegal/ds-icons/dist/PlaylistAddCheck'
import { Consultant, EngagementVacancy, EngagementVacancyConsultant } from 'declarations/models'

import EngagementVacancyConsultantForm from 'components/Engagements/Engagement/Vacancy/Consultant/EngagementVacancyConsultantForm'

import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface MatchButtonProps {
  item?: Partial<EngagementVacancyConsultant>
  alreadyMatched?: boolean
  onMatch: (item: Partial<EngagementVacancyConsultant>) => void
  onUnmatch?: (item: Partial<EngagementVacancyConsultant>) => void
  context?: Consultant | EngagementVacancy
  contextType?: 'consultant' | 'engagementVacancy'
  loading?: boolean
  disabled?: boolean
  size?: ButtonSize
  text?: boolean
}

const MatchButton = ({
  item,
  alreadyMatched,
  onMatch,
  onUnmatch,
  size = 'small',
  context,
  contextType,
  text = true,
  loading = false,
  disabled = false
}: MatchButtonProps) => {
  const { t } = useTranslation()
  const [hover, setHover] = useState<boolean>(false)
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false)
  const buttonRef = useRef(null)

  const buttonTitle = alreadyMatched
    ? loading
      ? t('buttons:unmatching')
      : hover
        ? t('buttons:unmatch')
        : t('buttons:matched')
    : loading
      ? t('buttons:matching')
      : t('buttons:match')

  const handleButtonClicked = () => {
    if (alreadyMatched) {
      onUnmatch?.(item!)
      return
    }
    setPopoverOpen(!popoverOpen)
  }

  const title =
    contextType === 'consultant'
      ? t('messages:matching-consultant-x-to-y', {
          x: (context as Consultant)?.name,
          y: item?.engagement_vacancy?.title
        })
      : t('messages:matching-ev-x-to-y', {
          x: (context as EngagementVacancy)?.title,
          y: item?.consultant?.name
        })

  return (
    <div style={{ position: 'relative' }}>
      <Popover
        placement='top'
        variant='secondary'
        anchorEl={buttonRef?.current}
        onClose={() => setPopoverOpen(false)}
        open={popoverOpen}
      >
        <Popover.Content style={{ padding: '0px', minWidth: '400px' }}>
          <EngagementVacancyConsultantForm
            formRef={buttonRef!.current!}
            title={title}
            spacing={false}
            showConsultant={false}
            evc={item as EngagementVacancyConsultant}
            onEvcChanged={(updateMatch) => onMatch?.(updateMatch!)}
            onClose={() => setPopoverOpen(false)}
          />
        </Popover.Content>
      </Popover>
      <Button
        spacing
        ref={buttonRef}
        title={buttonTitle}
        disabled={disabled}
        loading={loading}
        size={size}
        variant={alreadyMatched ? 'primary' : 'secondary'}
        icon={<PlaylistAddCheck size='1.3rem' />}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={handleButtonClicked}
      >
        {text && buttonTitle}
      </Button>
    </div>
  )
}

export default MatchButton
