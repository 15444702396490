import { mockAiResponse } from 'ai'
import { styled } from 'styled-components'

import { useEffect, useRef, useState } from 'react'
import { useDrop } from 'react-dnd'

import { Attachment } from 'hooks/useAiMessages'

import { t } from 'i18next'

import AiChatBubbles from './AiChatBubbles'
import AiQuickActions from './AiQuickActions'
import AiTextField from './AiTextField'

const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 0.5rem;
  position: relative;
`

const TabField = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--cat-homepanel-element-background-color);
  flex-grow: 1;
  overflow-y: auto;
  padding: 1.5rem 1rem;
`

const Overlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
`

const OverlayStripes = styled.div`
  position: absolute;
  inset: 1.6rem;
  border: 2px dashed rgba(255, 255, 255, 0.5);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
`

const OverlayText = styled.div`
  position: absolute;
  color: white;
  font-size: 24px;
`

export type AiChatProps = {
  searchValue: string
  messages: any[]
  setMessages: (messages: any[]) => void
}

const AiChat = ({ searchValue, messages, setMessages }: AiChatProps) => {
  const chatFieldRef = useRef<HTMLDivElement>(null)
  const [attachments, setAttachments] = useState<Attachment[]>([])
  const [textareaContent, setTextareaContent] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleInput = (e: any) => {
    const textarea = e.target
    textarea.style.height = 'auto'
    textarea.style.height = `${Math.min(textarea.scrollHeight, 8 * 16)}px`

    setTextareaContent(textarea.value)
  }

  useEffect(() => {
    if (messages.length) {
      chatFieldRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end'
      })
    }
  }, [messages.length])

  const handleSubmit = (e: any, msg?: string) => {
    e?.preventDefault()

    const newMessages = [
      ...messages,
      {
        isUser: true,
        message: msg || textareaContent,
        timestamp: new Date(),
        attachments
      }
    ]

    setMessages([...newMessages])

    setTextareaContent('')
    setAttachments([])

    setIsLoading(true)

    setTimeout(() => {
      setIsLoading(false)
      setMessages([
        ...newMessages,
        {
          isUser: false,
          message: mockAiResponse(msg || textareaContent),
          timestamp: new Date()
        }
      ])
    }, 1000)
  }

  const addAttachment = (item: Attachment) => {
    setAttachments((curr) => [...curr, item])
  }
  const removeAttachment = (index: number) => {
    const copy = [...attachments]
    copy.splice(index, 1)
    setAttachments(copy)
  }

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: ['EngagementCard', 'ConsultantCard', 'ClientCard'],
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    }),
    drop: (item, monitor) => {
      addAttachment(item as Attachment)
    }
  }))

  const styles = {
    backgroundColor: isOver ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.5)',
    transition: 'background-color 0.2s ease-in-out'
  }
  const stripeStyles = {
    inset: isOver ? '2rem' : '1.4rem',
    transition: 'inset 0.2s ease-in-out'
  }

  const isActive = canDrop

  return (
    <TabContainer style={{ paddingTop: '0' }}>
      <TabField>
        {isActive && (
          <Overlay ref={drop} style={{ ...styles }}>
            <OverlayStripes style={{ ...stripeStyles }}>
              <OverlayText>{t('label:drop-files-here')}</OverlayText>
            </OverlayStripes>
          </Overlay>
        )}
        <AiChatBubbles messages={messages} isLoading={isLoading} searchValue={searchValue} />
        <div ref={chatFieldRef} />
      </TabField>
      <AiQuickActions disabled={isLoading} onAction={(msg) => handleSubmit(null, msg)} />
      <AiTextField
        value={textareaContent}
        attachments={attachments}
        addAttachment={addAttachment}
        removeAttachment={removeAttachment}
        onInput={handleInput}
        disabled={!textareaContent || isLoading}
        onSubmit={handleSubmit}
      />
    </TabContainer>
  )
}

export default AiChat
