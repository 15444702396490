import * as types from 'constants/actionTypes'
import { Client } from 'declarations/models'
import { AnyAction } from 'redux'

import _ from 'lodash'

export interface ClientsState {
  created: Client | null | undefined
  creating: boolean
  deleted: Client | null | undefined
  deleting: boolean
  get: Client | null | undefined
  getting: boolean
  list: Array<Client> | null | undefined
  listing: boolean
  saving: boolean
  saved: Client | null | undefined
}

export const initialClientsState: ClientsState = {
  created: undefined,
  creating: false,
  deleted: undefined,
  deleting: false,
  get: undefined,
  getting: false,
  list: undefined,
  listing: false,
  saved: undefined,
  saving: false
}

const clientsReducer = (
  /* istanbul ignore next */
  state: ClientsState = initialClientsState,
  action: AnyAction
): ClientsState => {
  switch (action.type) {
    case types.CLIENTS_CLIENT_SET:
      return {
        ...state,
        get: action.payload
      }

    case types.CLIENTS_DELETE_REQUEST:
      return {
        ...state,
        deleted: undefined,
        deleting: true
      }

    case types.CLIENTS_DELETE_SUCCESS: {
      const deletedClient = action.originalPayload
      return {
        ...state,
        deleted: deletedClient,
        deleting: false,
        get: undefined,
        list: _.reject(state.list, { id: deletedClient.id })
      }
    }

    case types.CLIENTS_DELETE_FAILURE:
      return {
        ...state,
        deleted: null,
        deleting: false
      }

    case types.CLIENTS_EDIT_REQUEST:
      return {
        ...state,
        saved: undefined,
        saving: true
      }

    case types.CLIENTS_EDIT_SUCCESS: {
      const editedClient = action.payload
      return {
        ...state,
        saved: editedClient,
        saving: false,
        list: _.map(state.list, (Client) =>
          /* istanbul ignore next */
          editedClient.id === Client.id ? editedClient : Client
        )
      }
    }

    case types.CLIENTS_EDIT_FAILURE:
      return {
        ...state,
        saved: null,
        saving: false
      }

    case types.CLIENTS_GET_REQUEST:
      return {
        ...state,
        getting: true,
        get: undefined
      }

    case types.CLIENTS_GET_SUCCESS:
      return {
        ...state,
        getting: false,
        get: action.payload
      }

    case types.CLIENTS_GET_FAILURE:
      return {
        ...state,
        getting: false,
        get: null
      }

    case types.CLIENTS_LIST_REQUEST:
      return {
        ...state,
        list: undefined,
        listing: true
      }

    case types.CLIENTS_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload,
        listing: false
      }

    case types.CLIENTS_LIST_FAILURE:
      return {
        ...state,
        list: null,
        listing: false
      }

    case types.CLIENTS_NEW_REQUEST:
      return {
        ...state,
        creating: true,
        created: undefined
      }

    case types.CLIENTS_NEW_SUCCESS: {
      const newClient = action.payload
      return {
        ...state,
        created: newClient,
        creating: false,
        list: [.../* istanbul ignore next */ (state.list ?? [])].concat(newClient)
      }
    }

    case types.CLIENTS_NEW_FAILURE:
      return {
        ...state,
        created: null,
        creating: false
      }

    case types.CLIENTS_RESET:
      return {
        ...state,
        created: undefined,
        saved: undefined,
        deleted: undefined,
        get: undefined
      }
    default:
      return state
  }
}

export default clientsReducer
