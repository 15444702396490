import { listEngagements, listFollowedEngagements } from 'actions'
import { UseFilterObject } from 'declarations/app'
import { Engagement, EngagementsQuery, Skill } from 'declarations/models'
import { useAppDispatch, useAppSelector } from 'store'

import { useEffect, useState } from 'react'

import { filterEngagements, getSearchableEngagements, sortEngagements } from 'hooks/useEngagementFilterUtils'
import useQuery from 'hooks/useQuery'

import _ from 'lodash'

export type SearchableEngagement = {
  textSearch: Record<string, Array<string>>
  engagement: Engagement
  score?: number
}

const useEngagementFilter = (
  initialQuery: EngagementsQuery,
  handlePageChange: (page: number) => void,
  options?: Record<string, any>
): UseFilterObject<Engagement> => {
  const dispatch = useAppDispatch()

  const apiReady = useAppSelector((state) => state.app.apiReady)
  const engagements = useAppSelector((state) => state.engagements.list)
  const engagementsLoading = useAppSelector((state) => state.engagements.listing)
  const itemsPerPage = useAppSelector((state) => state.app.settings.itemsPerPage)
  const followedEngagements = useAppSelector((state) => state.followedEngagements.list)
  const userId = useAppSelector((state) => state.app.userId)
  const locationsList = useAppSelector((state) => state.locations.list)

  const [searchableEngagements, setSearchableEngagements] = useState<SearchableEngagement[]>([])
  const [numberOfFilteredEngagements, setNumberOfFilteredEngagements] = useState<number>(0)
  const [paginatedEngagements, setPaginatedEngagements] = useState<Engagement[]>([])
  const [numberOfPages, setNumberOfPages] = useState<number>(0)

  const { query } = useQuery(initialQuery)

  const loadPageData = () => {
    dispatch(listEngagements())
    if (_.isUndefined(followedEngagements)) {
      dispatch(listFollowedEngagements())
    }
  }

  useEffect(() => {
    if (engagements === undefined && apiReady && !engagementsLoading) {
      loadPageData()
    }
  }, [apiReady])

  useEffect(() => {
    if (engagements?.length) {
      const searchableEngagementsList = getSearchableEngagements(engagements)
      setSearchableEngagements(searchableEngagementsList)
    }
  }, [engagements])

  useEffect(() => {
    let _filteredEngagements: Array<Engagement> = filterEngagements({
      searchableEngagements,
      query,
      followedEngagements,
      userId,
      options,
      locationsList
    })
      .sort(sortEngagements(query))
      .map((c: SearchableEngagement) => c.engagement)

    // let's filter the irrelevant vacancies. If there is more than one remaining vacancy, let's replicate the engagement
    // so we can show those vacancies independently. _filteredEngagements will be a list of engagements with only 1 vacancy.
    if (options?.asEngagementVacancy) {
      _filteredEngagements = _.flatMap(_filteredEngagements, (e: Engagement) => {
        const engagementList: Array<Engagement> = []
        _.filter(e.vacancies, (v) => {
          const matchAllSkills: boolean = query.allSkills === true
          const needleSkillIds = Array.isArray(query.skills)
            ? query.skills.sort()
            : _.isNumber(query.skills)
              ? [query.skills]
              : []
          if (_.isEmpty(needleSkillIds)) {
            // nothing to filter upon, so let's return for now
            return true
          }
          const haystackSkillIds = v.skills.map((s: Skill) => s.id).sort()
          const intersection = _.intersection(needleSkillIds, haystackSkillIds)
          return matchAllSkills ? intersection.length === needleSkillIds.length : intersection.length > 0
        })?.forEach((v) => {
          engagementList.push({
            ...e,
            vacancies: [v]
          })
        })
        return engagementList
      })
    }

    setNumberOfFilteredEngagements(_filteredEngagements?.length ?? 0)

    const page = query?.page ? +query?.page : 1

    const firstPageIndex = (page - 1) * itemsPerPage
    const lastPageIndex = firstPageIndex + itemsPerPage
    const _paginatedEngagements = _filteredEngagements?.slice(firstPageIndex, lastPageIndex)

    if (searchableEngagements.length > 0 && _paginatedEngagements.length === 0) {
      handlePageChange(1)
    }
    setPaginatedEngagements(_paginatedEngagements)

    const _numberOfPages = Math.ceil((_filteredEngagements?.length ?? 0) / itemsPerPage)
    setNumberOfPages(_numberOfPages)
  }, [query, itemsPerPage, searchableEngagements])

  return {
    loadPageData,
    loading: engagementsLoading,
    loaded: !!engagements,
    total: numberOfFilteredEngagements,
    items: paginatedEngagements,
    pages: numberOfPages
  }
}

export default useEngagementFilter
