import { Alert, Button, ErrorSummary, FlexDiv, VerticalSpace } from '@cegal/ds-components'
import { ENGAGEMENTS_EDIT_FAILURE, ENGAGEMENTS_NEW_FAILURE } from 'constants/actionTypes'
import { Engagement } from 'declarations/models'
import { AlertsState } from 'reducers/alerts'
import { useAppSelector } from 'store'

import EngagementViewContent from 'components/Engagements/Engagement/EngagementViewContent'
import { EngagementStepFormProps } from 'components/Engagements/Engagement/Form/EngagementForm'

import { FieldError, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'

const Step6 = ({
  formMode,
  onSubmit,
  editView,
  thereAreUnsavedVacations,
  activateConfirmModal
}: EngagementStepFormProps) => {
  const { t } = useTranslation()

  const {
    getValues,
    formState: { errors }
  } = useFormContext()

  const creatingEngagement = useAppSelector((state) => state.engagements.creating)
  const savingEngagement = useAppSelector((state) => state.engagements.saving)
  const alertBody = useAppSelector((state) => (state.alerts as AlertsState).body)

  const error = _.find(alertBody, { type: ENGAGEMENTS_NEW_FAILURE })
  const errorEdit = _.find(alertBody, { type: ENGAGEMENTS_EDIT_FAILURE })

  return (
    <>
      {formMode === 'wizard' && (
        <>
          <VerticalSpace />
          <EngagementViewContent engagement={getValues() as Engagement} interactive={false} />
          <VerticalSpace />
        </>
      )}
      <FlexDiv>
        <Button
          loading={creatingEngagement || savingEngagement}
          disabled={creatingEngagement || savingEngagement}
          onClick={() => {
            if (thereAreUnsavedVacations) {
              activateConfirmModal(() => onSubmit())
            } else {
              onSubmit()
            }
          }}
        >
          {creatingEngagement
            ? t('buttons:creating-x', { x: t('label:engagement-title').toLowerCase() })
            : editView
              ? t('buttons:update-and-save-x', {
                  x: t('label:engagement-title').toLowerCase()
                })
              : t('buttons:save-x', {
                  x: t('label:engagement-title').toLowerCase()
                })}
        </Button>
      </FlexDiv>
      {error && (
        <>
          <Alert variant='error'>{error.message}</Alert>
          <VerticalSpace />
        </>
      )}
      {errorEdit && (
        <>
          <Alert variant='error'>{errorEdit.message}</Alert>
          <VerticalSpace />
        </>
      )}
      <VerticalSpace size='2' />
      {!_.isEmpty(errors) && (
        <ErrorSummary heading={t('validation:engagement-error-summary-title')}>
          {Object.keys(errors).map((errorKey) => (
            <ErrorSummary.Item
              key={errorKey}
              onClick={() => ((_.get(errors, errorKey) as FieldError)?.ref as any)?.focus()}
            >
              {_.get(errors, errorKey + '.message') as unknown as string}
            </ErrorSummary.Item>
          ))}
        </ErrorSummary>
      )}
    </>
  )
}

export default Step6
