import { ConsultantsQuery, Role } from 'declarations/models'

import TagGroup, { TagGroupMode } from 'components/TagGroup/TagGroup'

import _ from 'lodash'

interface RolesGroupProps<Q> {
  query?: Q
  roles: Array<Role> | null | undefined
  setQuery?: (q: Q) => void
  interactive?: boolean
  mode?: TagGroupMode
  visibleRows?: number
}

const RolesGroup = <Q extends Partial<ConsultantsQuery>>({
  query,
  roles,
  mode,
  setQuery,
  interactive,
  visibleRows = 1
}: RolesGroupProps<Q>) => {
  const handleTagsChanged = (roleIds: Array<Role['id']>) => {
    setQuery?.({ roles: roleIds } as Q)
  }

  return (
    <TagGroup
      size='small'
      tags={roles}
      queryTagIds={_.isArray(query?.roles) ? query!.roles : _.isNumber(query?.roles) ? [query!.roles] : []}
      mode={mode}
      interactive={interactive}
      visibleRows={visibleRows}
      setTags={handleTagsChanged}
    />
  )
}

export default RolesGroup
