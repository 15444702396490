import { getOrgChart, listConsultants, setConsultants, setLocations, setLocationsLoading } from 'actions'
import { Location, OrgChartNode, OrgChartSingleNode } from 'declarations/models'
import { useAppDispatch, useAppSelector } from 'store'

import { useEffect, useState } from 'react'

import _ from 'lodash'

const useOrgChart = () => {
  const dispatch = useAppDispatch()

  const apiReady = useAppSelector((state) => state.app.apiReady)
  const orgChart = useAppSelector((state) => state.orgChart.get)
  const loading = useAppSelector((state) => state.orgChart.getting)
  const consultants = useAppSelector((state) => state.consultants.list)
  const consultantsLoading = useAppSelector((state) => state.consultants.listing)
  const locationLoading = useAppSelector((state) => state.locations.listing)

  const [tree, setTree] = useState<OrgChartNode | undefined | null>(undefined)
  const [personIndex, setPersonIndex] = useState<Record<string, Array<number> | undefined>>()
  const [parentIndex, setParentIndex] = useState<Record<string, OrgChartSingleNode>>()
  const [locationIndex, setLocationIndex] = useState<Record<string, string>>()

  const getPath = (key: string): Array<number> | undefined => {
    return personIndex?.[key]
  }

  const getNode = (key: string): OrgChartNode | undefined => {
    const path: Array<number> | undefined = getPath(key)
    if (!path) {
      return undefined
    }
    return _.get(tree, path?.map((i) => 'children[' + i + ']').join('.'))
  }

  const getSubTreeIds = (key: string): Array<number> => {
    const ids: Array<number> = []
    const subTree: OrgChartNode | undefined = getNode(key)
    if (!subTree) {
      return ids
    }

    const walkNodes = (node: OrgChartNode, _ids: Array<number>) => {
      if (node.catId) {
        _ids.push(node.catId)
      }
      node.children?.forEach((x) => walkNodes(x, _ids))
    }

    walkNodes(subTree, ids)
    return ids
  }

  const getPathFromTopToX = (catId: string): Array<number> | undefined => {
    /* const leafNode: OrgChartNode | undefined = getNode(catId)
    if (!leafNode) {
      return undefined
    } */
    return getPath(catId)
  }

  const getParentNode = (catId: string): OrgChartSingleNode | null => {
    const node = getNode(catId)
    if (node) {
      return parentIndex?.[node.id] ?? null
    }
    return null
  }

  useEffect(() => {
    // when I have all the raw data (consultants and orgchart) to make the tree and fill out locations
    if (!tree && !!orgChart && !!consultants) {
      const _personIndex: Record<string, Array<number> | undefined> = {}
      const _parentIndex: Record<string, OrgChartSingleNode> = {}
      const _locationIndex: Record<string, string> = {}
      const transformNodes = (node: OrgChartNode, path: Array<number> | undefined): OrgChartNode => {
        const catId: number | undefined = _.find(consultants, { email: node.mail?.toLowerCase() })?.id
        if (_.isNumber(catId)) {
          _personIndex[catId.toString()] = path
          _locationIndex[catId.toString()] = node.officeLocation
        }
        return {
          ...node,
          catId,
          children: node.children?.map((subnode: OrgChartNode, i: number) => {
            _parentIndex[subnode.id] = {
              ..._.omit(node, 'children'),
              catId
            }
            return transformNodes(subnode, (path ?? []).concat(i))
          })
        }
      }

      const _tree = transformNodes(orgChart, undefined)

      setPersonIndex(_personIndex)
      setParentIndex(_parentIndex)
      setLocationIndex(_locationIndex)
      setTree(_tree)
    }
  }, [tree, orgChart, consultants])

  useEffect(() => {
    if (!!tree && locationLoading && !_.isEmpty(locationIndex) && consultants !== undefined) {
      // ...and a list with all locations
      const allLocations: Array<Location> = _.uniq(Object.values(locationIndex))
        .sort()
        .map((l, i) => ({
          id: i + 1,
          name: l
        }))

      const unknownLocation = { id: allLocations.length + 1, name: 'Unknown' }
      allLocations.push(unknownLocation)

      // create a new consultant list...
      const newConsultants = consultants?.map((c) => ({
        ...c,
        location: _.find(allLocations, { name: locationIndex[c.id.toString()] }) ?? unknownLocation
      }))
      dispatch(setConsultants(newConsultants))
      dispatch(setLocations(allLocations as Array<Location>))
    }
  }, [tree, locationLoading, locationIndex])

  useEffect(() => {
    if (apiReady && orgChart === undefined && !loading) {
      dispatch(getOrgChart())
      dispatch(setLocationsLoading())
    }
  }, [apiReady, orgChart, loading, consultants])

  useEffect(() => {
    if (apiReady && consultants === undefined && !consultantsLoading && locationLoading) {
      dispatch(listConsultants())
    }
  }, [apiReady, orgChart, loading, locationLoading, consultants, consultantsLoading])

  return {
    orgChart: tree,
    loaded: !locationLoading && !!tree,
    loading,
    getParentNode,
    getSubTreeIds,
    getPathFromTopToX
  }
}

export default useOrgChart
