import {
  AlignEndColumn,
  BodyLong,
  BodyShort,
  Button,
  Column,
  FlexDiv,
  FlexEndDiv,
  Heading,
  HorizontalSpace,
  Modal,
  PileDiv,
  Row,
  VerticalSpace
} from '@cegal/ds-components'
import { Delete } from '@cegal/ds-icons'
import { TableHeader } from 'declarations/app'

import TableHeaderSorter from 'components/TableColumnManagerModal/TableHeaderSorter'
import { InsetDiv, OverlayDiv } from 'components/styled'

import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface TableColumnManagerModalProps {
  headerKeys: Array<string>
  setHeaderKeys: (newHeaders: Array<string>) => void
  modalOpen: boolean
  onClose: () => void
  headers: Array<TableHeader>
  initialHeaders: Array<string>
}
const TableColumnManagerModal = ({
  headerKeys,
  setHeaderKeys,
  modalOpen,
  onClose,
  headers,
  initialHeaders
}: TableColumnManagerModalProps) => {
  const { t } = useTranslation()
  const modalContainerRef = useRef(null)

  const [resetMode, setResetMode] = useState<boolean>(false)
  const [tempHeaderKeys, setTempHeaderKeys] = useState<Array<string>>(headerKeys)

  useEffect(() => setTempHeaderKeys(headerKeys), [headerKeys])

  const onHeadersChange = (newHeaders: Array<TableHeader>) => {
    setTempHeaderKeys(newHeaders.map((h) => h.value))
  }

  const handleResetHeaders = () => {
    setHeaderKeys(initialHeaders)
    setTempHeaderKeys(initialHeaders)
    setResetMode(false)
  }

  const onSave = () => {
    setHeaderKeys(tempHeaderKeys)
  }

  return (
    <div ref={modalContainerRef}>
      {modalContainerRef?.current && (
        <Modal
          parentSelector={() => modalContainerRef.current!}
          open={modalOpen}
          onClose={onClose}
          closeButton={false}
          shouldCloseOnOverlayClick={false}
        >
          <Modal.Content>
            {resetMode && (
              <OverlayDiv>
                <InsetDiv>
                  <BodyShort>{t('messages:confirm-reset-table-headers')}</BodyShort>
                  <VerticalSpace size='0.5' />
                  <FlexEndDiv>
                    <Button
                      variant='danger'
                      size='small'
                      icon={<Delete size='1.2rem' />}
                      onClick={handleResetHeaders}
                    >
                      {t('buttons:yes-delete')}
                    </Button>
                    <HorizontalSpace />
                    <Button variant='secondary' size='small' onClick={() => setResetMode(false)}>
                      {t('buttons:no-cancel')}
                    </Button>
                  </FlexEndDiv>
                </InsetDiv>
              </OverlayDiv>
            )}
            <div style={{ minWidth: '50vw', padding: '1rem', maxHeight: '90vh' }}>
              <PileDiv>
                <Heading level='3' size='small' spacing>
                  {t('header:customise-table-columns')}
                </Heading>
                <BodyLong>{t('messages:customise-table-header-text-1')}</BodyLong>
                <BodyLong spacing>{t('messages:customise-table-header-text-2')}</BodyLong>
                <div style={{ overflowY: 'auto', maxHeight: '60vh' }}>
                  <TableHeaderSorter
                    headerKeys={headerKeys}
                    options={headers}
                    onHeadersChange={onHeadersChange}
                  />
                </div>
              </PileDiv>
              <VerticalSpace />
              <Row>
                <Column style={{ alignItems: 'flex-start' }}>
                  <Button variant='tertiary' onClick={() => setResetMode(true)}>
                    {t('buttons:reset-x', { x: t('label:table-columns').toLowerCase() })}
                  </Button>
                </Column>
                <AlignEndColumn style={{ alignItems: 'flex-end' }}>
                  <FlexDiv>
                    <Button variant='secondary' onClick={onClose}>
                      {t('buttons:cancel')}
                    </Button>
                    <HorizontalSpace />
                    <Button
                      onClick={() => {
                        onSave()
                        onClose()
                      }}
                    >
                      {t('buttons:save')}
                    </Button>
                  </FlexDiv>
                </AlignEndColumn>
              </Row>
            </div>
          </Modal.Content>
        </Modal>
      )}
    </div>
  )
}

export default TableColumnManagerModal
