var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { KeyboardArrowDown } from '@cegal/ds-icons/dist/KeyboardArrowDown';
import cl from 'clsx';
import { AccordionContext } from 'components/accordion/Accordion';
import React, { forwardRef, useContext } from 'react';
import { AccordionItemContext } from './AccordionItem';
const AccordionHeader = forwardRef((_a, ref) => {
    var { children, className, onClick } = _a, rest = __rest(_a, ["children", "className", "onClick"]);
    const context = useContext(AccordionItemContext);
    const mainContext = useContext(AccordionContext);
    if (context === null) {
        console.error('<Accordion.Header> has to be used within an <Accordion.Item>');
        return null;
    }
    const handleClick = (e) => {
        context.toggleOpen();
        onClick && onClick(e);
    };
    return (React.createElement("button", Object.assign({}, rest, { ref: ref, className: cl('cds-accordion__header', {
            'cds-accordion__header--border': mainContext === null || mainContext === void 0 ? void 0 : mainContext.border
        }, className), type: 'button', onClick: handleClick, "aria-expanded": context.open }),
        React.createElement("span", { className: 'cds-accordion__header-content' }, children),
        (mainContext === null || mainContext === void 0 ? void 0 : mainContext.arrow) && React.createElement(KeyboardArrowDown, { "aria-hidden": true, className: 'cds-accordion__expand-icon' })));
});
AccordionHeader.displayName = 'Accordion.Header';
export default AccordionHeader;
