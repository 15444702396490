const labels = {
    all: 'alle',
    accepted: 'Accepted',
    size: 'Size',
    dropFilesHere: 'Click here to drop files',
    allowedFileFormat: 'Allowed file format {{filFormat}}',
    file: 'file',
    files: 'files',
    singleFile: 'only single file',
    multipleFiles: 'multiple files allowed',
    maxFiles: 'max {{maxFiles}} {{file}}',
    maxFileSize: 'max size {{maxFileSize}}',
    fileIsTooBigLimitIs: 'File is too big ({{size}}), limit is {{maxFileSize}}',
    maxFilesExceeded: 'Too many files, max {{maxFiles}} {{file}}',
    invalidFileType: 'Not a file of type {{filFormat}}',
    rejected: 'Rejected',
    removed: 'Removed',
    total: 'Total'
};
export default labels;
