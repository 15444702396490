import * as types from 'constants/actionTypes'
import * as urls from 'constants/urls'
import api from 'api'
import { SourceRequest, SourceResponse } from 'declarations/api'
import { sprintf } from 'sprintf-js'

import _ from 'lodash'

export const deleteSource = (source: SourceResponse) => {
  return api.call({
    url: sprintf(urls.SOURCES_DELETE_URL, { id: source.id }),
    method: 'DELETE',
    body: source,
    cascadeFailureError: true,
    type: {
      request: types.SOURCES_DELETE_REQUEST,
      success: types.SOURCES_DELETE_SUCCESS,
      failure: types.SOURCES_DELETE_FAILURE
    }
  })
}

export const editSource = (source: SourceResponse) => {
  const payload: SourceRequest = {
    ..._.omit(source, ['id']),
    name: source.name
  }
  return api.call({
    url: sprintf(urls.SOURCES_EDIT_URL, { id: source.id }),
    method: 'PUT',
    body: payload,
    cascadeFailureError: true,
    type: {
      request: types.SOURCES_EDIT_REQUEST,
      success: types.SOURCES_EDIT_SUCCESS,
      failure: types.SOURCES_EDIT_FAILURE
    }
  })
}

export const getSource = (id: number) => {
  return api.call({
    url: sprintf(urls.SOURCES_GET_URL, { id }),
    cascadeFailureError: true,
    type: {
      request: types.SOURCES_GET_REQUEST,
      success: types.SOURCES_GET_SUCCESS,
      failure: types.SOURCES_GET_FAILURE
    }
  })
}

export const listSources = () => {
  return api.call({
    url: urls.SOURCES_LIST_URL,
    cascadeFailureError: true,
    type: {
      request: types.SOURCES_LIST_REQUEST,
      success: types.SOURCES_LIST_SUCCESS,
      failure: types.SOURCES_LIST_FAILURE
    }
  })
}

export const newSource = (source: SourceRequest) => {
  return api.call({
    url: urls.SOURCES_NEW_URL,
    method: 'POST',
    body: source,
    cascadeFailureError: true,
    type: {
      request: types.SOURCES_NEW_REQUEST,
      success: types.SOURCES_NEW_SUCCESS,
      failure: types.SOURCES_NEW_FAILURE
    }
  })
}

export const resetSource = () => ({
  type: types.SOURCES_RESET
})
