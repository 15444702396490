import * as types from 'constants/actionTypes'
import { AnyAction } from 'redux'

export interface OrgChartState {
  get: any | null | undefined
  getting: boolean
}

export const initialOrgChartState: OrgChartState = {
  get: undefined,
  getting: false
}

const orgChartReducer = (
  /* istanbul ignore next */
  state: OrgChartState = initialOrgChartState,
  action: AnyAction
): OrgChartState => {
  switch (action.type) {
    case types.ORGCHART_GET_REQUEST:
      return {
        ...state,
        getting: true,
        get: undefined
      }

    case types.ORGCHART_GET_SUCCESS:
      return {
        ...state,
        getting: false,
        get: action.payload
      }

    case types.ORGCHART_GET_FAILURE:
      return {
        ...state,
        getting: false,
        get: null
      }

    default:
      return state
  }
}

export default orgChartReducer
