import { MAX_WORKING_HOURS_PER_DAY } from 'config'
import { Allocation, Consultant } from 'declarations/models'
import { DateTime, Interval } from 'luxon'

import { ChartDataset, ConsultantAllocationChartUnit } from './ConsultantAllocationChart'

import _ from 'lodash'

export interface GetAllocationWindowProps {
  allocations: Array<Allocation> | null | undefined
  pastDate: DateTime
  futureDate: DateTime
}

export interface GetAllocationWindowResponse {
  sortedAllocations: Array<Allocation>
  lowestDate: DateTime
  highestDate: DateTime
}

export const getAllocationWindow = ({
  allocations,
  pastDate,
  futureDate
}: GetAllocationWindowProps): GetAllocationWindowResponse => {
  let lowestDate: DateTime | undefined
  let highestDate: DateTime | undefined

  /* lowestDate can be lower than pastDate, because allocations can come in week periods,
  so in the case that pastDate lands on the middle of the week, lowestDate will be the
  startDate of that week. This" "rounding" problem is not a big deal, as this is only for
  graph view purposes
   */

  const sortedAllocations =
    allocations
      ?.filter((a: Allocation) => {
        const valid =
          DateTime.fromISO(a?.start_date) <= futureDate && DateTime.fromISO(a?.end_date) >= pastDate
        if (valid && (!lowestDate || DateTime.fromISO(a.start_date) < lowestDate)) {
          lowestDate = DateTime.fromISO(a.start_date)
        }
        if (valid && (!highestDate || DateTime.fromISO(a.end_date) > highestDate)) {
          highestDate = DateTime.fromISO(a.end_date)
        }
        return valid
      })
      ?.sort(
        (a: Allocation, b: Allocation) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime()
      ) ?? []

  return {
    lowestDate: lowestDate!,
    highestDate: highestDate!,
    sortedAllocations
  }
}

export const getDatesInInterval = (startDate: DateTime, endDate: DateTime): Array<string> => {
  return Interval.fromDateTimes(startDate.startOf('day'), endDate.endOf('day'))
    .splitBy({ day: 1 })
    .map((d) => d.start!.toFormat('yyyy-MM-dd'))
}

export type AllocationDays = Record<string, Array<{ duration: number; engagement_vacancy_id: number }>>

export const getAllocationDays = ({
  lowestDate,
  sortedAllocations,
  highestDate
}: GetAllocationWindowResponse): AllocationDays => {
  const allocationDays: Record<string, any> = {}

  if (!!lowestDate && !!highestDate) {
    getDatesInInterval(lowestDate, highestDate).forEach((date: string): void => {
      allocationDays[date] = []
    })
  }

  sortedAllocations.forEach((allocation: Allocation): void => {
    const allocationDates: Array<string> = getDatesInInterval(
      DateTime.fromISO(allocation.start_date),
      DateTime.fromISO(allocation.end_date)
    )
    const durationSlice: number = allocation.duration

    allocationDates.forEach((date): void => {
      allocationDays[date].push({
        duration: durationSlice,
        engagement_vacancy_id: allocation.engagement_vacancy_id
      })
    })
  })

  return allocationDays
}

type AggregatedAllocation = Record<
  string,
  { workingHours: number; maxWorkingHours: number; interval: string }
>

export const getAggregatedAllocation = (
  allocationDays: AllocationDays,
  unit: ConsultantAllocationChartUnit
): AggregatedAllocation => {
  const aggregatedAllocation: AggregatedAllocation = {}

  Object.keys(allocationDays).forEach((date: string): void => {
    const _date: DateTime = DateTime.fromISO(date)
    let finalKey: string = ''
    let interval: string = ''
    if (unit === 'day') {
      finalKey = date
      interval = _date.toLocaleString()
    }
    if (unit === 'week') {
      finalKey = 'w' + _date.get('weekNumber').toString() + ' ' + _date.get('year')
      interval = _date.startOf('week').toLocaleString() + ' - ' + _date.endOf('week').toLocaleString()
    }
    if (unit === 'month') {
      finalKey = _date.get('monthShort').toString() + ' ' + _date.get('year')
      interval = _date.startOf('month').toLocaleString() + ' - ' + _date.endOf('month').toLocaleString()
    }

    if (!_.has(aggregatedAllocation, finalKey)) {
      aggregatedAllocation[finalKey] = {
        workingHours: 0,
        maxWorkingHours: 0,
        interval
      }
    }
    allocationDays[date].forEach((v: { duration: number; engagement_vacancy_id: number }): void => {
      // duration comes in minutes. We will display values in hours
      aggregatedAllocation[finalKey].workingHours += Math.ceil(v.duration / 60)
      // do not use weekends for calculating maxWorkingHours
      if (![6, 7].includes(_date.get('weekday'))) {
        aggregatedAllocation[finalKey].maxWorkingHours += MAX_WORKING_HOURS_PER_DAY
      }
    })
  })
  return aggregatedAllocation
}

export const getDisplayData = (aggregatedAllocation: AggregatedAllocation): ChartDataset[] => {
  const displayData: ChartDataset[] = []
  Object.keys(aggregatedAllocation).forEach((key: string): void => {
    displayData.push({
      tooltip: [aggregatedAllocation[key].interval, `worked ${aggregatedAllocation[key].workingHours} hours`],
      x: key,
      y:
        aggregatedAllocation[key].maxWorkingHours === 0
          ? 0
          : Math.ceil(
              (aggregatedAllocation[key].workingHours / aggregatedAllocation[key].maxWorkingHours) * 100
            )
    })
  })
  return displayData
}

export const generateDatasetFromAllocation = (
  consultant: Consultant | null | undefined,
  allocations: Array<Allocation> | null | undefined,
  refDate: DateTime = DateTime.now(),
  unit: ConsultantAllocationChartUnit,
  offsetInUnits: number
): ChartDataset[] => {
  if (_.isEmpty(allocations)) {
    return []
  }

  const pastDate: DateTime = refDate.minus({ [unit]: offsetInUnits })
  const futureDate: DateTime = refDate.plus({ [unit]: offsetInUnits })

  const allocationWindow: GetAllocationWindowResponse = getAllocationWindow({
    allocations,
    pastDate,
    futureDate
  })
  const allocationDays: AllocationDays = getAllocationDays(allocationWindow)
  const aggregatedAllocation: AggregatedAllocation = getAggregatedAllocation(allocationDays, unit)
  return getDisplayData(aggregatedAllocation)
}
