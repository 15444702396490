import { Widget } from 'declarations/app'

import FollowedClients from 'components/Home/FollowedClients/FollowedClients'
import FollowedConsultants from 'components/Home/FollowedConsultants/FollowedConsultants'
import FollowedEngagements from 'components/Home/FollowedEngagements/FollowedEngagements'
import MyMatches from 'components/Home/MyMatches/MyMatches'
import MyNotes from 'components/Home/MyNotes/MyNotes'
import NewsBox from 'components/Home/NewsBox/NewsBox'
import SummaryBox from 'components/Home/PlatformSummary/SummaryBox'

import { HomeProps } from 'pages/Home/Home'

const HomePage = ({ widgetTable }: HomeProps) => {
  const dash: Array<Widget> = widgetTable.left.concat(widgetTable.right)

  const renderWidget = (widget: Widget | null): JSX.Element | null => {
    if (widget === null) {
      return null
    }
    switch (widget.id) {
      case 'news':
        return <NewsBox mode='page' />
      case 'myMatches':
        return <MyMatches mode='page' />
      case 'followedConsultants':
        return <FollowedConsultants mode='page' />
      case 'followedEngagements':
        return <FollowedEngagements mode='page' />
      case 'props':
        return <SummaryBox mode='page' />
      case 'myNotifications':
        return null
      case 'myNotes':
        return <MyNotes mode='page' />
      case 'followedClients':
        return <FollowedClients mode='page' />
      default:
        return null
    }
  }

  return <>{dash.map(renderWidget)}</>
}

export default HomePage
