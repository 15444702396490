import * as types from 'constants/actionTypes'
import { Note } from 'declarations/models'
import { AnyAction } from 'redux'

import _ from 'lodash'

export interface NotesState {
  created: Note | null | undefined
  creating: boolean
  deleted: Note | null | undefined
  deleting: boolean
  get: Note | null | undefined
  getting: boolean
  list: Array<Note> | null | undefined
  listing: boolean
  saving: boolean
  saved: Note | null | undefined
}

export const initialNotesState: NotesState = {
  created: undefined,
  creating: false,
  deleted: undefined,
  deleting: false,
  get: undefined,
  getting: false,
  list: undefined,
  listing: false,
  saved: undefined,
  saving: false
}

const notesReducer = (
  /* istanbul ignore next */
  state: NotesState = initialNotesState,
  action: AnyAction
): NotesState => {
  switch (action.type) {
    case types.NOTES_DELETE_REQUEST:
      return {
        ...state,
        deleted: undefined,
        deleting: true
      }

    case types.NOTES_DELETE_SUCCESS: {
      const deletedNote = action.originalPayload
      return {
        ...state,
        deleted: deletedNote,
        deleting: false,
        list: _.reject(state.list, { id: deletedNote.id })
      }
    }

    case types.NOTES_DELETE_FAILURE:
      return {
        ...state,
        deleted: null,
        deleting: false
      }

    case types.NOTES_EDIT_REQUEST:
      return {
        ...state,
        saved: undefined,
        saving: true
      }

    case types.NOTES_EDIT_SUCCESS: {
      const editedNote = action.payload
      return {
        ...state,
        saved: editedNote,
        saving: false,
        list: _.map(state.list, (Note) =>
          /* istanbul ignore next */
          editedNote.id === Note.id ? editedNote : Note
        )
      }
    }

    case types.NOTES_EDIT_FAILURE:
      return {
        ...state,
        saved: null,
        saving: false
      }

    case types.NOTES_LIST_REQUEST:
      return {
        ...state,
        list: undefined,
        listing: true
      }

    case types.NOTES_LIST_SUCCESS: {
      // with 204, I get a {} instead of a []
      /* istanbul ignore next */
      const payload = action.status === 204 && _.isEmpty(action.payload) ? [] : action.payload
      return {
        ...state,
        list: payload,
        listing: false
      }
    }

    case types.NOTES_LIST_FAILURE:
      return {
        ...state,
        list: null,
        listing: false
      }

    case types.NOTES_NEW_REQUEST:
      return {
        ...state,
        creating: true,
        created: undefined
      }

    case types.NOTES_NEW_SUCCESS: {
      const newNote = action.payload
      return {
        ...state,
        created: newNote,
        creating: false,
        list: [.../* istanbul ignore next */ (state.list ?? [])].concat(newNote)
      }
    }

    case types.NOTES_NEW_FAILURE:
      return {
        ...state,
        created: null,
        creating: false
      }

    case types.NOTES_RESET:
      return {
        ...state,
        created: undefined,
        saved: undefined,
        deleted: undefined,
        get: undefined
      }

    default:
      return state
  }
}

export default notesReducer
