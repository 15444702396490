export const BASE_URL = '/api'

export const AI_URL = BASE_URL + '/ai'

export const APP_PROFILE_URL = '/profile'
export const APP_LOGIN_URL = '/logout'

export const APP_LOGOUT_URL = '/logout'
export const APP_STATS_URL = BASE_URL + '/stats'
export const APP_USERID_URL = BASE_URL + '/consultants/user_id/%(email)s'

export const ADMIN_SYNCING_LIST_URL = '/admin/syncing'
export const ADMIN_SYNCING_GET_URL = '/admin/syncing/%(id)s'
export const ADMIN_SYNCING_POST_URL = '/admin/syncing/%(id)s/post'

export const CATEGORIES_DELETE_URL = BASE_URL + '/categories/%(id)s/delete'
export const CATEGORIES_EDIT_URL = BASE_URL + '/categories/%(id)s/edit'
export const CATEGORIES_GET_URL = BASE_URL + '/categories/%(id)s'
export const CATEGORIES_LIST_URL = BASE_URL + '/categories'
export const CATEGORIES_NEW_URL = BASE_URL + '/categories/new'

export const CERTIFICATES_GET_URL = BASE_URL + '/certificates/%(id)s'
export const CERTIFICATES_LIST_URL = BASE_URL + '/certificates?limit=10000'

export const CLIENTS_DELETE_URL = BASE_URL + '/clients/%(id)s/delete'
export const CLIENTS_EDIT_URL = BASE_URL + '/clients/%(id)s/edit'
export const CLIENTS_GET_URL = BASE_URL + '/clients/%(id)s'
export const CLIENTS_LIST_URL = BASE_URL + '/clients?limit=500'
export const CLIENTS_NEW_URL = BASE_URL + '/clients/new'
export const CLIENTS_AVATAR_URL = BASE_URL + '/clients/%(id)s/avatar'

export const CLIENT_COMMENTS_DELETE_URL = BASE_URL + '/clients/%(id)s/comments/%(comment_id)s/delete'
export const CLIENT_COMMENTS_EDIT_URL = BASE_URL + '/clients/%(id)s/comments/%(comment_id)s/edit'
export const CLIENT_COMMENTS_LIST_URL = BASE_URL + '/clients/%(id)s/comments'
export const CLIENT_COMMENTS_NEW_URL = BASE_URL + '/clients/%(id)s/comments/new'

export const CONSULTANTS_CERTIFICATE_GET_URL = BASE_URL + '/consultants/%(id)s/certificate'
export const CONSULTANTS_CV_GET_URL = BASE_URL + '/consultants/%(id)s/cv'
export const CONSULTANTS_GET_URL = BASE_URL + '/consultants/%(id)s'
export const CONSULTANTS_LIST_URL = BASE_URL + '/consultants?limit=1000'
export const CONSULTANTS_ALLOCATION_LIST_URL = BASE_URL + '/consultants/%(id)s/allocation'
export const CONSULTANTS_AVATAR_URL = BASE_URL + '/consultants/%(id)s/avatar'
export const CONSULTANTS_ALLOCATIONS_SEARCH_URL = BASE_URL + '/consultants/allocation/search'

export const DEPARTMENTS_DELETE_URL = BASE_URL + '/departments/%(id)s/delete'
export const DEPARTMENTS_EDIT_URL = BASE_URL + '/departments/%(id)s/edit'
export const DEPARTMENTS_GET_URL = BASE_URL + '/departments/%(id)s'
export const DEPARTMENTS_LIST_URL = BASE_URL + '/departments'
export const DEPARTMENTS_NEW_URL = BASE_URL + '/departments/new'

export const ENGAGEMENTS_DELETE_URL = BASE_URL + '/engagements/%(engagement_id)s/delete'
export const ENGAGEMENTS_EDIT_URL = BASE_URL + '/engagements/%(engagement_id)s/edit'
export const ENGAGEMENTS_GET_URL = BASE_URL + '/engagements/%(engagement_id)s'
export const ENGAGEMENTS_LIST_URL = BASE_URL + '/engagements?limit=1000'
export const ENGAGEMENTS_NEW_URL = BASE_URL + '/engagements/new'

export const ENGAGEMENTS_VACANCIES_DELETE_URL = BASE_URL + '/engagements/vacancies/%(vacancy_id)s/delete'
export const ENGAGEMENTS_VACANCIES_EDIT_URL = BASE_URL + '/engagements/vacancies/%(vacancy_id)s/edit'
export const ENGAGEMENTS_VACANCIES_GET_URL = BASE_URL + '/engagements/vacancies/%(vacancy_id)s'
export const ENGAGEMENTS_VACANCIES_LIST_URL = BASE_URL + '/engagements/%(engagement_id)s/vacancies'
export const ENGAGEMENTS_VACANCIES_NEW_URL = BASE_URL + '/engagements/%(engagement_id)s/vacancies/new'

export const ENGAGEMENTS_VACANCIES_CONSULTANTS_DELETE_URL =
  BASE_URL + '/engagements/vacancies/%(vacancy_id)s/consultants/%(id)s/delete'
export const ENGAGEMENTS_VACANCIES_CONSULTANTS_EDIT_URL =
  BASE_URL + '/engagements/vacancies/%(vacancy_id)s/consultants/%(id)s/edit'
export const ENGAGEMENTS_VACANCIES_CONSULTANTS_GET_URL =
  BASE_URL + '/engagements/vacancies/%(vacancy_id)s/consultants/%(id)s'
export const ENGAGEMENTS_VACANCIES_CONSULTANTS_LIST_URL =
  BASE_URL + '/engagements/vacancies/%(vacancy_id)s/consultants'
export const ENGAGEMENTS_VACANCIES_CONSULTANTS_NEW_URL =
  BASE_URL + '/engagements/vacancies/%(vacancy_id)s/consultants/new'

export const FOLLOWED_ENGAGEMENTS_DELETE_URL = BASE_URL + '/follows/engagements/%(id)s/delete'
export const FOLLOWED_ENGAGEMENTS_LIST_URL = BASE_URL + '/follows/engagements'
export const FOLLOWED_ENGAGEMENTS_NEW_URL = BASE_URL + '/follows/engagements/%(engagement_id)s/new'

export const FOLLOWED_CLIENTS_DELETE_URL = BASE_URL + '/follows/clients/%(id)s/delete'
export const FOLLOWED_CLIENTS_LIST_URL = BASE_URL + '/follows/clients'
export const FOLLOWED_CLIENTS_NEW_URL = BASE_URL + '/follows/clients/%(client_id)s/new'

export const FOLLOWED_CONSULTANTS_DELETE_URL = BASE_URL + '/follows/consultants/%(id)s/delete'
export const FOLLOWED_CONSULTANTS_LIST_URL = BASE_URL + '/follows/consultants'
export const FOLLOWED_CONSULTANTS_NEW_URL = BASE_URL + '/follows/consultants/%(consultant_id)s/new'

export const LOCATIONS_DELETE_URL = BASE_URL + '/locations/%(id)s/delete'
export const LOCATIONS_EDIT_URL = BASE_URL + '/locations/%(id)s/edit'
export const LOCATIONS_GET_URL = BASE_URL + '/locations/%(id)s'
export const LOCATIONS_LIST_URL = BASE_URL + '/locations'
export const LOCATIONS_NEW_URL = BASE_URL + '/locations/new'

export const MATCHES_LIST_URL = BASE_URL + '/matches'
export const MATCHES_NEW_URL = BASE_URL + '/engagements/vacancies/%(vacancy_id)s/consultants/new'
export const MATCHES_DELETE_URL = BASE_URL + '/engagements/vacancies/%(vacancy_id)s/consultants/%(id)s/delete'
export const MATCHES_EDIT_URL = BASE_URL + '/engagements/vacancies/%(vacancy_id)s/consultants/%(id)s/edit'

export const NEWS_DELETE_URL = BASE_URL + '/news/%(id)s/delete'
export const NEWS_EDIT_URL = BASE_URL + '/news/%(id)s/edit'
export const NEWS_LIST_URL = BASE_URL + '/news'
export const NEWS_NEW_URL = BASE_URL + '/news/new'

export const ORGCHART_GET_URL = BASE_URL + '/orgchart'

export const NOTES_DELETE_URL = BASE_URL + '/notes/%(id)s/delete'
export const NOTES_EDIT_URL = BASE_URL + '/notes/%(id)s/edit'
export const NOTES_LIST_URL = BASE_URL + '/notes'
export const NOTES_NEW_URL = BASE_URL + '/notes/new'

export const NOTIFICATIONS_DELETE_URL = BASE_URL + '/notifications/%(id)s/delete'
export const NOTIFICATIONS_EDIT_URL = BASE_URL + '/notifications/%(id)s/edit'
export const NOTIFICATIONS_GET_URL = BASE_URL + '/notifications/%(id)s'
export const NOTIFICATIONS_LIST_URL = BASE_URL + '/notifications'
export const NOTIFICATIONS_NEW_URL = BASE_URL + '/notifications/new'
export const ROLES_DELETE_URL = BASE_URL + '/roles/%(id)s/delete'
export const ROLES_EDIT_URL = BASE_URL + '/roles/%(id)s/edit'
export const ROLES_GET_URL = BASE_URL + '/roles/%(id)s'
export const ROLES_LIST_URL = BASE_URL + '/roles'
export const ROLES_NEW_URL = BASE_URL + '/roles/new'

export const SKILLS_DELETE_URL = BASE_URL + '/skills/%(id)s/delete'
export const SKILLS_EDIT_URL = BASE_URL + '/skills/%(id)s/edit'
export const SKILLS_GET_URL = BASE_URL + '/skills/%(id)s'
export const SKILLS_LIST_URL = BASE_URL + '/skills?limit=10000'
export const SKILLS_NEW_URL = BASE_URL + '/skills/new'

export const APP_SIGNUP_NEW_URL = BASE_URL + '/consultants/invite/%(email)s'

export const SWAGGER_URL = BASE_URL + '/swagger/doc.json'

export const SOURCES_DELETE_URL = BASE_URL + '/sources/%(id)s/delete'
export const SOURCES_EDIT_URL = BASE_URL + '/sources/%(id)s/edit'
export const SOURCES_GET_URL = BASE_URL + '/sources/%(id)s'
export const SOURCES_LIST_URL = BASE_URL + '/sources'
export const SOURCES_NEW_URL = BASE_URL + '/sources/new'

export const SUBSCRIPTIONS_DELETE_URL = BASE_URL + '/subscriptions/%(id)s/delete'
export const SUBSCRIPTIONS_EDIT_URL = BASE_URL + '/subscriptions/%(id)s/edit'
export const SUBSCRIPTIONS_GET_URL = BASE_URL + '/subscriptions/%(id)s'
export const SUBSCRIPTIONS_LIST_URL = BASE_URL + '/subscriptions'
export const SUBSCRIPTIONS_NEW_URL = BASE_URL + '/subscriptions/new'
