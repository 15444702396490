import { Avatar, Chip, Link, PileDiv, Popover, VerticalSpace } from '@cegal/ds-components'
import { Consultant, Engagement, EngagementVacancy, SimpleConsultant } from 'declarations/models'
import styled from 'styled-components'

import { NoWrapSpan } from 'components/styled'

import { useRef, useState } from 'react'
import { NavLink, generatePath } from 'react-router-dom'

import useConsultantAvatar from 'hooks/useConsultantAvatar'

import _ from 'lodash'

export interface ConsultantSignatureProps {
  consultant?: SimpleConsultant
  context?: any
  showName?: boolean
  popover?: Array<string>
}

const SignatureChip = styled(Chip)`
  .cds-label {
    text-wrap: nowrap;
  }
`

const ConsultantSignature = ({ popover, consultant, showName = true, context }: ConsultantSignatureProps) => {
  const { avatar, avatarLoading, ref: avatarRef } = useConsultantAvatar({ id: consultant?.id })
  const [open, setOpen] = useState<boolean>(false)
  const buttonRef = useRef(null)

  let href =
    'mailto:' +
    encodeURIComponent(consultant!.email ?? '') +
    '?subject=' +
    encodeURIComponent('CAT inquiry about ')

  if (context?.consultant) {
    href += encodeURIComponent(`consultant: ${(context.consultant as Consultant)!.name}`)
  } else if (context?.engagement) {
    href += encodeURIComponent(`engagement: ${(context.engagement as Engagement)!.title}`)
  }

  href += '&body='

  if (context?.consultant) {
    href += encodeURIComponent(`Consultant: ${(context.consultant as Consultant)!.name}\n\n`)
  }
  if (context?.engagement) {
    href += encodeURIComponent(`Engagement: ${(context.engagement as Engagement)!.title}\n\n`)
  }
  if (context?.engagementVacancy) {
    href += encodeURIComponent(
      `Engagement vacancy: ${(context.engagementVacancy as EngagementVacancy)!.title}\n\n`
    )
  }

  href += '\n\nSent from CAT - cat.cegal.com'

  const getOptions = (): Array<React.ReactNode> => {
    return (
      popover!
        .map((key) => {
          switch (key) {
            case 'view':
              return (
                <Link
                  key={key}
                  as={NavLink}
                  to={generatePath('/consultants/:id', {
                    id: consultant!.id.toString()
                  })}
                >
                  <NoWrapSpan>See {consultant!.name} details</NoWrapSpan>
                </Link>
              )
            case 'email':
              return (
                <Link key={key} target='_blank' href={href} rel='noreferrer'>
                  <NoWrapSpan>Email {consultant!.name}</NoWrapSpan>
                </Link>
              )
            default:
              return null
          }
        })
        .reduce(
          (prev: Array<React.ReactNode> | null, curr: React.ReactNode | null, index) =>
            prev === null ? [curr] : [...prev, <VerticalSpace key={'sep-' + index} />, curr],
          null
        ) ?? []
    )
  }

  return (
    <div>
      {!_.isEmpty(popover) && (
        <Popover
          placement='top'
          variant='secondary'
          anchorEl={buttonRef?.current}
          onClose={() => setOpen(false)}
          open={open}
        >
          <Popover.Content>{_.isNumber(consultant?.id) && <PileDiv>{getOptions()}</PileDiv>}</Popover.Content>
        </Popover>
      )}
      <SignatureChip
        ref={buttonRef}
        size='small'
        leftElement={<Avatar src={avatar} ref={avatarRef} loading={avatarLoading} size='2xsmall' />}
        button
        onClick={() => setOpen(!open)}
      >
        {showName && consultant?.name}
      </SignatureChip>
    </div>
  )
}

export default ConsultantSignature
