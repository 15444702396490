import { Table } from '@cegal/ds-components'

import { useTranslation } from 'react-i18next'

const EngagementVacancyConsultantRowHeader = () => {
  const { t } = useTranslation()
  return (
    <Table.Row>
      <Table.HeaderCell>{t('label:consultant-title')}</Table.HeaderCell>
      <Table.HeaderCell>{t('label:vacancy-title')}</Table.HeaderCell>
      <Table.HeaderCell>{t('label:hourly-rate-title')}</Table.HeaderCell>
      <Table.HeaderCell>{t('label:status-title')}</Table.HeaderCell>
      <Table.HeaderCell>{t('label:dates-title')}</Table.HeaderCell>
      <Table.HeaderCell>{t('label:last-modified')}</Table.HeaderCell>
      <Table.HeaderCell />
    </Table.Row>
  )
}

export default EngagementVacancyConsultantRowHeader
