import { BodyShort, VerticalSpace } from '@cegal/ds-components'
import { ClientsQuery } from 'declarations/models'

import { Hr } from 'components/styled'

import { useTranslation } from 'react-i18next'

export interface ClientsDescriptionProps {
  total: number
  query: Partial<ClientsQuery>
  itemsPerPage: number
}

const ClientsDescription = ({ total, query, itemsPerPage }: ClientsDescriptionProps) => {
  const { page } = query
  const { t } = useTranslation()

  const initialValue = page ? (+page - 1) * itemsPerPage + 1 : 1
  let finalValue = initialValue + itemsPerPage - 1
  if (finalValue > total) {
    finalValue = total
  }

  const sentence = []

  if (total > 0) {
    sentence.push(
      t('messages:description-found-x', {
        number: total,
        item: total === 1 ? t('label:client-title').toLowerCase() : t('label:clients-title').toLowerCase()
      })
    )

    if (total > itemsPerPage) {
      sentence.push(
        t('messages:description-showing-x', {
          range: '' + initialValue + '-' + finalValue,
          item: t('label:clients-title').toLowerCase()
        })
      )
    }
  } else {
    sentence.push(t('messages:description-no-x-found', { x: t('label:clients-title').toLowerCase() }))
  }

  return (
    <>
      <BodyShort data-cy='clientResultsText' spacing>
        {sentence.join(', ')}
      </BodyShort>
      <Hr style={{ width: '70%', marginLeft: '0' }} />
      <VerticalSpace />
    </>
  )
}

export default ClientsDescription
