import * as types from 'constants/actionTypes'
import { Location } from 'declarations/models'
import { AnyAction } from 'redux'

import _ from 'lodash'

export interface LocationsState {
  created: Location | null | undefined
  creating: boolean
  deleted: Location | null | undefined
  deleting: boolean
  get: Location | null | undefined
  getting: boolean
  list: Array<Location> | null | undefined
  listing: boolean
  saving: boolean
  saved: Location | null | undefined
}

export const initialLocationsState: LocationsState = {
  created: undefined,
  creating: false,
  deleted: undefined,
  deleting: false,
  get: undefined,
  getting: false,
  list: undefined,
  listing: false,
  saved: undefined,
  saving: false
}

const locationsReducer = (
  /* istanbul ignore next */
  state: LocationsState = initialLocationsState,
  action: AnyAction
): LocationsState => {
  switch (action.type) {
    case types.LOCATIONS_DELETE_REQUEST:
      return {
        ...state,
        deleted: undefined,
        deleting: true
      }

    case types.LOCATIONS_DELETE_SUCCESS: {
      const deletedLocation = action.originalPayload
      return {
        ...state,
        deleted: deletedLocation,
        deleting: false,
        list: _.reject(state.list, { id: deletedLocation.id })
      }
    }

    case types.LOCATIONS_DELETE_FAILURE:
      return {
        ...state,
        deleted: null,
        deleting: false
      }

    case types.LOCATIONS_EDIT_REQUEST:
      return {
        ...state,
        saved: undefined,
        saving: true
      }

    case types.LOCATIONS_EDIT_SUCCESS: {
      const editedLocation = action.payload
      return {
        ...state,
        saved: editedLocation,
        saving: false,
        list: _.map(state.list, (Location) =>
          /* istanbul ignore next */
          editedLocation.id === Location.id ? editedLocation : Location
        )
      }
    }

    case types.LOCATIONS_EDIT_FAILURE:
      return {
        ...state,
        saved: null,
        saving: false
      }

    case types.LOCATIONS_GET_REQUEST:
      return {
        ...state,
        getting: true,
        get: undefined
      }

    case types.LOCATIONS_GET_SUCCESS:
      return {
        ...state,
        getting: false,
        get: action.payload
      }

    case types.LOCATIONS_GET_FAILURE:
      return {
        ...state,
        getting: false,
        get: null
      }

    case types.LOCATIONS_LIST_LOADING:
    case types.LOCATIONS_LIST_REQUEST:
      return {
        ...state,
        list: undefined,
        listing: true
      }

    case types.LOCATIONS_LIST_SET:
    case types.LOCATIONS_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload,
        listing: false
      }

    case types.LOCATIONS_LIST_FAILURE:
      return {
        ...state,
        list: null,
        listing: false
      }

    case types.LOCATIONS_NEW_REQUEST:
      return {
        ...state,
        creating: true,
        created: undefined
      }

    case types.LOCATIONS_NEW_SUCCESS: {
      const newLocation = action.payload
      return {
        ...state,
        created: newLocation,
        creating: false,
        list: [.../* istanbul ignore next */ (state.list ?? [])].concat(newLocation)
      }
    }

    case types.LOCATIONS_NEW_FAILURE:
      return {
        ...state,
        created: null,
        creating: false
      }

    default:
      return state
  }
}

export default locationsReducer
