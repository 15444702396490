var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from 'react';
import cl from 'clsx';
import { BodyShort, Detail } from 'components/typography';
export const Tag = forwardRef((_a, ref) => {
    var { className, variant = 'info', size = 'medium' } = _a, rest = __rest(_a, ["className", "variant", "size"]);
    const Component = size === 'medium' ? BodyShort : Detail;
    return (React.createElement(Component, Object.assign({}, rest, { ref: ref, className: cl('cds-tag', `cds-tag--${variant}`, `cds-tag--${size}`, className), as: 'span', size: size })));
});
Tag.displayName = 'Tag';
export default Tag;
