import * as types from 'constants/actionTypes'
import * as urls from 'constants/urls'
import api from 'api'
import { CategoryRequest, CategoryResponse } from 'declarations/api'
import mockCategories from 'mocks/categories'
import { sprintf } from 'sprintf-js'

import _ from 'lodash'

export const deleteCategory = (category: CategoryResponse) => {
  return api.call({
    url: sprintf(urls.CATEGORIES_DELETE_URL, { id: category.id }),
    method: 'DELETE',
    body: category,
    cascadeFailureError: true,
    expectedPayload: category,
    type: {
      request: types.CATEGORIES_DELETE_REQUEST,
      success: types.CATEGORIES_DELETE_SUCCESS,
      failure: types.CATEGORIES_DELETE_FAILURE
    }
  })
}

export const editCategory = (category: CategoryResponse) => {
  const payload: CategoryRequest = {
    ..._.omit(category, ['id']),
    name: category.name
  }
  return api.call({
    url: sprintf(urls.CATEGORIES_EDIT_URL, { id: category.id }),
    method: 'PUT',
    body: payload,
    cascadeFailureError: true,
    expectedPayload: category,
    type: {
      request: types.CATEGORIES_EDIT_REQUEST,
      success: types.CATEGORIES_EDIT_SUCCESS,
      failure: types.CATEGORIES_EDIT_FAILURE
    }
  })
}

export const getCategory = (id: number) => {
  return api.call({
    url: sprintf(urls.CATEGORIES_GET_URL, { id }),
    expectedPayload: mockCategories,
    cascadeFailureError: true,
    type: {
      request: types.CATEGORIES_GET_REQUEST,
      success: types.CATEGORIES_GET_SUCCESS,
      failure: types.CATEGORIES_GET_FAILURE
    }
  })
}

export const listCategories = () => {
  return api.call({
    url: urls.CATEGORIES_LIST_URL,
    expectedPayload: mockCategories,
    cascadeFailureError: true,
    type: {
      request: types.CATEGORIES_LIST_REQUEST,
      success: types.CATEGORIES_LIST_SUCCESS,
      failure: types.CATEGORIES_LIST_FAILURE
    }
  })
}

export const newCategory = (category: CategoryRequest) => {
  return api.call({
    url: urls.CATEGORIES_NEW_URL,
    method: 'POST',
    cascadeFailureError: true,
    body: category,
    expectedPayload: {
      ...category,
      id: Math.floor(Math.random() * 10000 + 10000)
    },
    type: {
      request: types.CATEGORIES_NEW_REQUEST,
      success: types.CATEGORIES_NEW_SUCCESS,
      failure: types.CATEGORIES_NEW_FAILURE
    }
  })
}
