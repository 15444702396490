(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("clsx"), require("react"), require("styled-components"), require("react-dom"), require("lodash"));
	else if(typeof define === 'function' && define.amd)
		define(["clsx", "React", "styled-components", "ReactDOM", "lodash"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("clsx"), require("react"), require("styled-components"), require("react-dom"), require("lodash")) : factory(root["clsx"], root["React"], root[undefined], root["ReactDOM"], root["_"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(this, (__WEBPACK_EXTERNAL_MODULE__9054__, __WEBPACK_EXTERNAL_MODULE__4639__, __WEBPACK_EXTERNAL_MODULE__5466__, __WEBPACK_EXTERNAL_MODULE__1346__, __WEBPACK_EXTERNAL_MODULE__7607__) => {
return 