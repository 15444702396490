var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import cl from 'clsx';
import { BodyShort, Detail, ErrorMessage, Label } from 'components/typography';
import React, { forwardRef, useContext } from 'react';
import { omit } from 'util/index';
import { useFieldset } from './useFieldset';
export const FieldsetContext = React.createContext(null);
export const Fieldset = forwardRef((props, ref) => {
    var _a, _b, _c;
    const { inputProps, errorId, showErrorMsg, hasError, size, inputDescriptionId } = useFieldset(props);
    const fieldset = useContext(FieldsetContext);
    const { children, className, errorPropagation = true, legend, description, hideLegend } = props, rest = __rest(props, ["children", "className", "errorPropagation", "legend", "description", "hideLegend"]);
    return (React.createElement(FieldsetContext.Provider, { value: {
            error: errorPropagation ? (_a = props.error) !== null && _a !== void 0 ? _a : fieldset === null || fieldset === void 0 ? void 0 : fieldset.error : undefined,
            errorId: cl({
                [errorId]: showErrorMsg,
                [(_b = fieldset === null || fieldset === void 0 ? void 0 : fieldset.errorId) !== null && _b !== void 0 ? _b : '']: !!(fieldset === null || fieldset === void 0 ? void 0 : fieldset.error)
            }),
            size,
            disabled: (_c = props.disabled) !== null && _c !== void 0 ? _c : false
        } },
        React.createElement("fieldset", Object.assign({}, omit(rest, ['errorId', 'error', 'size']), inputProps, { ref: ref, className: cl('cds-fieldset', `cds-fieldset--${size}`, {
                'cds-fieldset--error': hasError
            }, className) }),
            React.createElement(Label, { size: size, as: 'legend', className: cl('cds-fieldset__legend', {
                    'cds-sr-only': !!hideLegend
                }) }, legend),
            !!description &&
                (size === 'medium' ? (React.createElement(BodyShort, { className: cl('cds-fieldset__description', {
                        'cds-sr-only': !!hideLegend
                    }), id: inputDescriptionId, size: 'small', as: 'div' }, props.description)) : (React.createElement(Detail, { className: cl('cds-fieldset__description', {
                        'cds-sr-only': !!hideLegend
                    }), id: inputDescriptionId, size: 'small', as: 'div' }, props.description))),
            children,
            React.createElement("div", { id: errorId, "aria-relevant": 'additions removals', "aria-live": 'polite', className: 'cds-fieldset__error' }, showErrorMsg && React.createElement(ErrorMessage, { size: size }, props.error)))));
});
Fieldset.displayName = 'Fieldset';
export default Fieldset;
