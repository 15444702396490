import * as types from 'constants/actionTypes'
import * as urls from 'constants/urls'
import api from 'api'
import mockAdminSyncings from 'mocks/adminSyncings'
import { sprintf } from 'sprintf-js'

export const getAdminSyncing = (id: number) => {
  return api.call({
    url: sprintf(urls.ADMIN_SYNCING_GET_URL, { id }),
    expectedPayload: mockAdminSyncings[0],
    cascadeFailureError: true,
    type: {
      request: types.ADMIN_SYNCING_GET_REQUEST,
      success: types.ADMIN_SYNCING_GET_SUCCESS,
      failure: types.ADMIN_SYNCING_GET_FAILURE
    }
  })
}

export const listAdminSyncings = () => {
  return api.call({
    url: urls.ADMIN_SYNCING_LIST_URL,
    expectedPayload: mockAdminSyncings,
    cascadeFailureError: true,
    type: {
      request: types.ADMIN_SYNCING_LIST_REQUEST,
      success: types.ADMIN_SYNCING_LIST_SUCCESS,
      failure: types.ADMIN_SYNCING_LIST_FAILURE
    }
  })
}

export const newAdminSync = (id: number) => {
  return api.call({
    url: sprintf(urls.ADMIN_SYNCING_POST_URL, { id }),
    method: 'POST',
    expectedResponses: { 201: 1 },
    cascadeFailureError: true,
    type: {
      request: types.ADMIN_SYNCING_POST_REQUEST,
      success: types.ADMIN_SYNCING_POST_SUCCESS,
      failure: types.ADMIN_SYNCING_POST_FAILURE
    }
  })
}
