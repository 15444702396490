import React, { useEffect, useState } from 'react';
let globalId = 0;
function useGlobalId(idOverride) {
    const [defaultId, setDefaultId] = useState(idOverride);
    const id = idOverride || defaultId;
    useEffect(() => {
        if (defaultId == null) {
            globalId += 1;
            setDefaultId(`cds-id-${globalId}`);
        }
    }, [defaultId]);
    return id;
}
const maybeReactUseId = React['useId' + ''];
export function useId(idOverride) {
    if (maybeReactUseId !== undefined) {
        const reactId = maybeReactUseId();
        return idOverride !== null && idOverride !== void 0 ? idOverride : reactId;
    }
    return useGlobalId(idOverride);
}
