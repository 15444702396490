import * as types from 'constants/actionTypes'
import { Source } from 'declarations/models'
import { AnyAction } from 'redux'

import _ from 'lodash'

export interface SourcesState {
  created: Source | null | undefined
  creating: boolean
  deleted: Source | null | undefined
  deleting: boolean
  get: Source | null | undefined
  getting: boolean
  list: Array<Source> | null | undefined
  listing: boolean
  saving: boolean
  saved: Source | null | undefined
}

export const initialSourcesState: SourcesState = {
  created: undefined,
  creating: false,
  deleted: undefined,
  deleting: false,
  get: undefined,
  getting: false,
  list: undefined,
  listing: false,
  saved: undefined,
  saving: false
}

const sourcesReducer = (
  /* istanbul ignore next */
  state: SourcesState = initialSourcesState,
  action: AnyAction
): SourcesState => {
  switch (action.type) {
    case types.SOURCES_DELETE_REQUEST:
      return {
        ...state,
        deleted: undefined,
        deleting: true
      }

    case types.SOURCES_DELETE_SUCCESS: {
      const deletedSource = action.originalPayload
      return {
        ...state,
        deleted: deletedSource,
        deleting: false,
        list: _.reject(state.list, { id: deletedSource.id })
      }
    }

    case types.SOURCES_DELETE_FAILURE:
      return {
        ...state,
        deleted: null,
        deleting: false
      }

    case types.SOURCES_EDIT_REQUEST:
      return {
        ...state,
        saved: undefined,
        saving: true
      }

    case types.SOURCES_EDIT_SUCCESS: {
      const editedSource = action.payload
      return {
        ...state,
        saved: editedSource,
        saving: false,
        list: _.map(state.list, (Source) =>
          /* istanbul ignore next */
          editedSource.id === Source.id ? editedSource : Source
        )
      }
    }

    case types.SOURCES_EDIT_FAILURE:
      return {
        ...state,
        saved: null,
        saving: false
      }

    case types.SOURCES_GET_REQUEST:
      return {
        ...state,
        getting: true,
        get: undefined
      }

    case types.SOURCES_GET_SUCCESS:
      return {
        ...state,
        getting: false,
        get: action.payload
      }

    case types.SOURCES_GET_FAILURE:
      return {
        ...state,
        getting: false,
        get: null
      }

    case types.SOURCES_LIST_REQUEST:
      return {
        ...state,
        list: undefined,
        listing: true
      }

    case types.SOURCES_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload,
        listing: false
      }

    case types.SOURCES_LIST_FAILURE:
      return {
        ...state,
        list: null,
        listing: false
      }

    case types.SOURCES_NEW_REQUEST:
      return {
        ...state,
        creating: true,
        created: undefined
      }

    case types.SOURCES_NEW_SUCCESS: {
      const newSource = action.payload
      return {
        ...state,
        created: newSource,
        creating: false,
        list: [.../* istanbul ignore next */ (state.list ?? [])].concat(newSource)
      }
    }

    case types.SOURCES_NEW_FAILURE:
      return {
        ...state,
        created: null,
        creating: false
      }

    case types.SOURCES_RESET:
      return {
        ...state,
        created: undefined,
        saved: undefined,
        deleted: undefined,
        get: undefined
      }
    default:
      return state
  }
}

export default sourcesReducer
