import { Fieldset, Label, VerticalSpace } from '@cegal/ds-components'

import { EngagementFormProps } from 'components/Engagements/Engagement/Form/EngagementForm'
import { Hr } from 'components/styled'

import { useTranslation } from 'react-i18next'

import Step1 from './Steps/Step1'
import Step2 from './Steps/Step2'
import Step3 from './Steps/Step3'
import Step4 from './Steps/Step4'
import Step5 from './Steps/Step5'
import Step6 from './Steps/Step6'

const EngagementExpertForm = (props: EngagementFormProps) => {
  const { t } = useTranslation()

  return (
    <Fieldset hideLegend legend={t(props.editView ? 'header:edit-engagement' : 'header:new-engagement')}>
      <Step1 {...props} />
      <Step2 {...props} />
      <Step3 {...props} />
      <Step4 {...props} />
      <VerticalSpace />
      <Label>{t('header:vacancies')}</Label>
      <Hr />
      <VerticalSpace />
      <Step5 {...props} />
      <VerticalSpace size='2' />
      <Hr />
      <VerticalSpace size='2' />
      <Step6 {...props} />
    </Fieldset>
  )
}

export default EngagementExpertForm
