import { SortState } from '@cegal/ds-components'
import { Engagement, EngagementsQuery, Location, SimpleClient } from 'declarations/models'
import { DateTime } from 'luxon'

import { SearchableEngagement } from 'hooks/useEngagementFilter'

import { getConsultantsFromEngagement, getUniqueSkillIdsFromEngagement } from 'utils/engagements'
import { scoreMatch } from 'utils/rank'
import { tableSort } from 'utils/sort'
import { tokenize } from 'utils/tokenizer'

import _ from 'lodash'

export const getSearchableEngagements = (engagements?: Engagement[]): SearchableEngagement[] =>
  engagements?.map((engagement) => {
    const textSearch: Record<string, string[]> = {
      title: tokenize(engagement.title),
      clients: _.flatten(engagement.clients?.map((c: SimpleClient) => tokenize(c.name))),
      location: tokenize(engagement.location)
    }
    return { textSearch, engagement }
  }) || []

interface FilterEngagementsProps {
  query: Partial<EngagementsQuery>
  searchableEngagements: Array<SearchableEngagement>
  sort?: SortState
  followedEngagements?: Engagement[] | null | undefined
  userId?: number
  options?: any
  locationsList?: Array<Location> | null
}

const rankEngagement = (c: SearchableEngagement, query: Partial<EngagementsQuery>): number => {
  let score = 0
  if (_.isNil(query?.search) || query?.search.length === 0) {
    return score
  }

  let textScore = 0.0
  let maxTextScore = 0.0

  tokenize(query.search).forEach((token) => {
    if (token.length > 1) {
      maxTextScore += 2 * token.length
      c.textSearch.title?.forEach((title) => {
        textScore += scoreMatch(title, token)
      })
      c.textSearch.clients?.forEach((client) => {
        textScore += scoreMatch(client, token)
      })
      c.textSearch.location?.forEach((location) => {
        textScore += scoreMatch(location, token)
      })
    }
  })

  const normalisedTextScore = textScore / maxTextScore

  // normalised => value between 0 and 1
  let normalisedSkillScore

  if (!_.isEmpty(query.skills)) {
    const needleSkillIds = Array.isArray(query.skills) ? query.skills.sort() : [query.skills]
    const haystackSkillIds = _.uniq(
      _.flatMap(c.engagement?.vacancies, (v) => v.skills?.map((s) => s.id as number))
    ).sort()
    const intersectionSkills = _.intersection(needleSkillIds, haystackSkillIds)
    normalisedSkillScore = intersectionSkills.length / haystackSkillIds.length
  } else {
    normalisedSkillScore = 0
  }

  // final weight: 2 text match to 1 skill match
  score = 2 * normalisedTextScore + normalisedSkillScore
  return score
}

export const filterEngagements = ({
  query,
  searchableEngagements,
  followedEngagements,
  userId,
  options,
  locationsList
}: FilterEngagementsProps): Array<SearchableEngagement> => {
  return searchableEngagements?.filter((engagementItem) => {
    // search text
    if (query.search && query.search.length > 1) {
      // if free text search terms: reject those who score 0
      engagementItem.score = rankEngagement(engagementItem, query)
      if (engagementItem.score === 0) {
        return false
      }
    }

    // show followed engagements
    if (query.followed) {
      const needleIds = Array.isArray(followedEngagements) ? followedEngagements.map((e) => e.id).sort() : []
      const haystackIds = engagementItem.engagement ? [engagementItem.engagement.id] : []
      const intersection = _.intersection(needleIds, haystackIds)
      if (intersection.length === 0) {
        return false
      }
    }

    // show my engagements
    if (query.mine) {
      if (engagementItem.engagement.creator?.id !== userId) {
        return false
      }
    }

    // posteddate
    if (_.isNumber(query.posteddate)) {
      const engagementPostedDate = DateTime.fromJSDate(new Date(engagementItem.engagement.posted_date))
      const intervalStart = DateTime.fromJSDate(new Date()).minus({ days: query.posteddate })
      const intervalEnd = DateTime.fromJSDate(new Date())
      if (intervalStart >= engagementPostedDate || engagementPostedDate >= intervalEnd) {
        return false
      }
    }

    // between start and end date
    if (query.startdate && query.startdate > engagementItem.engagement.start_date) {
      return false
    }
    if (query.enddate && query.enddate < engagementItem.engagement.end_date) {
      return false
    }

    // deadline
    if (_.isNumber(query.deadline)) {
      const engagementDeadline = DateTime.fromJSDate(new Date(engagementItem.engagement.deadline))
      const intervalStart = DateTime.fromJSDate(new Date())
      const intervalEnd = DateTime.fromJSDate(new Date()).plus({ months: query.deadline })
      if (intervalStart >= engagementDeadline || engagementDeadline >= intervalEnd) {
        return false
      }
    }

    // hide ended engagements
    if (query.hideEndedEngagements && engagementItem.engagement.end_date) {
      const engagementEndDate = DateTime.fromJSDate(new Date(engagementItem.engagement.end_date))
      if (DateTime.fromJSDate(new Date()) >= engagementEndDate) {
        return false
      }
    }

    // clients
    if (!_.isEmpty(query.clients?.toString())) {
      const matchAllClients: boolean = query.allClients === true
      const needleIds = Array.isArray(query.clients) ? query.clients.sort() : [query.clients]
      const haystackIds = engagementItem.engagement.clients
        ? engagementItem.engagement.clients?.map((c: SimpleClient) => c.id)
        : []
      const intersection = _.intersection(needleIds, haystackIds)
      const approved = matchAllClients ? intersection.length === needleIds.length : intersection.length > 0
      if (!approved) {
        return false
      }
    }

    // sources
    if (!_.isEmpty(query.sources?.toString())) {
      const needleIds = Array.isArray(query.sources) ? query.sources.sort() : [query.sources]
      const haystackIds = engagementItem.engagement.source ? [engagementItem.engagement.source?.id] : []
      const intersection = _.intersection(needleIds, haystackIds)
      if (intersection.length === 0) {
        return false
      }
    }

    // locations
    if (!_.isEmpty(query.locations?.toString())) {
      const needleIds = Array.isArray(query.locations) ? query.locations.sort() : [query.locations]
      const haystack: Location | undefined = engagementItem.engagement.location
        ? _.find(locationsList, { name: engagementItem.engagement.location })
        : undefined
      const haystackIds = haystack ? [haystack.id] : []
      const intersection = _.intersection(needleIds, haystackIds)
      if (intersection.length === 0) {
        return false
      }
    }

    const consultants = getConsultantsFromEngagement(engagementItem.engagement)
    if (query.hideFilledVacancies && consultants.length !== 0) {
      return false
    }
    if (query.hideUnfilledVacancies && consultants.length === 0) {
      return false
    }

    // skills
    if (!_.isEmpty(query.skills?.toString())) {
      const matchAllSkills: boolean = query.allSkills === true
      const needleSkillIds = Array.isArray(query.skills) ? query.skills.sort() : [query.skills]
      const haystackSkillIds = getUniqueSkillIdsFromEngagement(engagementItem.engagement)
      const intersection = _.intersection(needleSkillIds, haystackSkillIds)
      const approved = matchAllSkills
        ? intersection.length === needleSkillIds.length
        : intersection.length > 0
      if (!approved) {
        return false
      }
    }
    return true
  })
}

export const sortEngagements = (query: Partial<EngagementsQuery>) => {
  return (a: SearchableEngagement, b: SearchableEngagement) => {
    if (query.sort) {
      return tableSort(query.sort, a, b, 'engagement', (a, b) =>
        b.score - a.score > 0 ? 1 : b.score - a.score < 0 ? -1 : 0
      )
    }
    return b.score! - a.score! > 0 ? 1 : b.score! - a.score! < 0 ? -1 : 0
  }
}
