import {
  Button,
  FlexBaseDiv,
  FlexBaseSpacedDiv,
  FlexCenterDiv,
  FlexStartDiv,
  Heading,
  HorizontalSpace,
  Loader,
  Pagination,
  PileDiv,
  PileEndDiv,
  TextField,
  VerticalSpace
} from '@cegal/ds-components'
import { FilterAlt as FilterIcon } from '@cegal/ds-icons/dist/FilterAlt'
import { CONSULTANTS_LIST_FAILURE } from 'constants/actionTypes'
import { DisplaySize, ViewMode } from 'declarations/app'
import { ConsultantsQuery } from 'declarations/models'
import { standardLogger } from 'metrics/loggers'
import { useAppSelector } from 'store'

import AlertPanel from 'components/AlertPanel/AlertPanel'
import { ConsultantsFilter } from 'components/Consultants'
import ConsultantsDescription from 'components/Consultants/ConsultantsDescription'
import ConsultantsView from 'components/Consultants/ConsultantsView'
import ToggleView from 'components/Forms/ToggleView'
import PageContent from 'components/PageContent/PageContent'

import React, { useDeferredValue, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useConsultantFilter from 'hooks/useConsultantFilter'
import useQuery from 'hooks/useQuery'

import { initialConsultantsQuery } from 'utils/query'
import { getNextSort } from 'utils/sort'

import _ from 'lodash'

const ConsultantsList = () => {
  const { t } = useTranslation()

  const viewMode = useAppSelector((state) => state.app.settings.viewMode)
  const itemsPerPage = useAppSelector((state) => state.app.settings.itemsPerPage)
  const displaySize = useAppSelector((state) => state.app.displaySize)

  const [isDrawerOpen, setIsDrawerOpen] = useState(true)
  const [_viewMode, setViewMode] = useState<ViewMode>(viewMode)
  const { query, setQuery } = useQuery<ConsultantsQuery>(initialConsultantsQuery)

  const [inputValue, setInputValue] = useState(query?.search)
  const debouncedValue = useDeferredValue(inputValue)

  const handlePageChange = (page: number) => {
    window.scrollTo(0, 0)
    standardLogger('consultants.list.page', { page })
    setQuery({ page: page === 1 ? undefined : page?.toString() })
  }

  const {
    pages,
    loadPageData,
    items: consultants,
    loading,
    total
  } = useConsultantFilter(initialConsultantsQuery, handlePageChange)

  const handleSort = (sortKey?: string | undefined) => setQuery({ sort: getNextSort(query?.sort, sortKey) })

  const toggleDrawer = () => {
    standardLogger('consultants.filters.' + (isDrawerOpen ? 'hide' : 'show'))
    setIsDrawerOpen(!isDrawerOpen)
  }

  const setOrientationAndViewMode = (displaySize: DisplaySize) => {
    if (displaySize === 'sm' || displaySize === 'md') {
      setViewMode('card')
    } else {
      setViewMode(viewMode)
    }
  }

  useEffect(() => setViewMode(viewMode), [viewMode])

  useEffect(() => setOrientationAndViewMode(displaySize), [displaySize])

  useEffect(() => setInputValue(query?.search), [query?.search])

  const updateSearchQuery = (searchPhrase: string) =>
    setQuery((query: Partial<ConsultantsQuery> | undefined) => ({ ...query, search: searchPhrase }))

  const debouncedChangeHandler = useMemo(() => _.debounce(updateSearchQuery, 1000), [])

  const UpdateSearch = (searchPhrase: string) => {
    setInputValue(searchPhrase)
    debouncedChangeHandler(searchPhrase)
  }

  return (
    <PageContent
      maxWidth={_viewMode === 'row' ? '100%' : '1600px'}
      isDrawerOpen={isDrawerOpen}
      drawer={
        !_.isNil(consultants) && (
          <ConsultantsFilter<ConsultantsQuery> context='consultants' onClose={toggleDrawer} />
        )
      }
    >
      <VerticalSpace />
      <FlexBaseSpacedDiv>
        <FlexBaseDiv>
          <PileDiv>
            <Heading level='2' size='large'>
              {t('header:consultants')}
            </Heading>
            <VerticalSpace />
          </PileDiv>
          <HorizontalSpace />
          <PileDiv>
            <FlexCenterDiv style={{ flexWrap: 'nowrap' }}>
              <Button
                data-cy='consultantFilterButton'
                variant='secondary'
                icon={<FilterIcon size='1.5rem' />}
                onClick={toggleDrawer}
              >
                <span style={{ whiteSpace: 'nowrap' }}>
                  {t(isDrawerOpen ? 'buttons:close-filter-options' : 'buttons:open-filter-options')}
                </span>
              </Button>
              <HorizontalSpace />
              <TextField
                hideLabel
                description={t('label:search-description')}
                label={t('label:filter')}
                placeholder={t('label:search-title')}
                value={inputValue}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => UpdateSearch(e.target.value)}
              />
              <HorizontalSpace size='0.5' />
              {debouncedValue !== inputValue && <Loader size='small' />}
            </FlexCenterDiv>
            <VerticalSpace />
          </PileDiv>
        </FlexBaseDiv>
        <PileEndDiv flex='1'>
          <FlexStartDiv style={{ flexWrap: 'nowrap', justifyContent: 'flex-end' }}>
            <ToggleView
              context='consultants.list'
              viewItemsPerPage
              viewToggleMode={!(displaySize === 'sm' || displaySize === 'md')}
            />
          </FlexStartDiv>
          <VerticalSpace />
        </PileEndDiv>
      </FlexBaseSpacedDiv>
      <ConsultantsDescription total={total} query={query} setQuery={setQuery} itemsPerPage={itemsPerPage} />
      <VerticalSpace size='2' />
      {consultants === null && (
        <AlertPanel
          doNotRenderIfHeadIsActiveWithError
          watchFor={[CONSULTANTS_LIST_FAILURE]}
          buttonText={t('buttons:click-to-reload-the-page')!}
          reloadPage={loadPageData}
        />
      )}
      {loading && <Loader />}
      {!_.isNil(consultants) && (
        <>
          <ConsultantsView
            query={query}
            consultants={consultants}
            onSortChange={handleSort}
            viewMode={_viewMode}
            buttons={['find', 'follow']}
          />
          <VerticalSpace size='2' />
          {pages > 1 && (
            <Pagination
              style={{ display: 'flex', justifyContent: 'center' }}
              size={displaySize === 'sm' ? 'small' : 'medium'}
              count={pages}
              page={query?.page ? +query?.page : 1}
              onPageChange={handlePageChange}
            />
          )}
        </>
      )}
    </PageContent>
  )
}

export default ConsultantsList
