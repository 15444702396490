var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import cl from 'clsx';
import React, { forwardRef, useCallback, useEffect, useRef } from 'react';
import { mergeRefs } from 'react-merge-refs';
import { usePopper } from 'react-popper';
import { useClientLayoutEffect } from 'util/index';
import PopoverContent from './PopoverContent';
const useEventLister = (event, callback) => useEffect(() => {
    document.addEventListener(event, callback);
    return () => {
        document.removeEventListener(event, callback);
    };
}, [event, callback]);
export const Popover = forwardRef((_a, ref) => {
    var { className, children, anchorEl, arrow = true, open, onClose, placement = 'right', offset, strategy = 'absolute', variant = 'primary' } = _a, rest = __rest(_a, ["className", "children", "anchorEl", "arrow", "open", "onClose", "placement", "offset", "strategy", "variant"]);
    const popoverRef = useRef(null);
    const mergedRef = mergeRefs([popoverRef, ref]);
    const close = useCallback(() => open && onClose(), [open, onClose]);
    useEventLister('click', useCallback((e) => {
        if (![anchorEl, popoverRef.current].some((element) => element === null || element === void 0 ? void 0 : element.contains(e.target))) {
            close();
        }
    }, [anchorEl, close]));
    useEventLister('keydown', useCallback((e) => e.key === 'Escape' && close(), [close]));
    useEventLister('focusin', useCallback((e) => {
        if (![anchorEl, popoverRef.current].some((element) => element === null || element === void 0 ? void 0 : element.contains(e.target))) {
            close();
        }
    }, [anchorEl, close]));
    const { styles, attributes, update } = usePopper(anchorEl, popoverRef.current, {
        placement,
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, offset !== null && offset !== void 0 ? offset : (arrow ? 16 : 4)]
                }
            },
            {
                name: 'arrow',
                options: {
                    padding: 8
                }
            }
        ],
        strategy
    });
    useClientLayoutEffect(() => {
        open && update && update();
    }, [open, update]);
    return (React.createElement("div", Object.assign({}, rest, { ref: mergedRef, className: cl('cds-popover', `cds-popover--${variant}`, {
            'cds-popover--hidden': !open || !anchorEl
        }, className), "aria-hidden": !open || !anchorEl, tabIndex: -1 }, attributes.popper, { style: styles.popper }),
        children,
        arrow && React.createElement("div", { "data-popper-arrow": true, style: styles.arrow, className: 'cds-popover__arrow' })));
});
Popover.Content = PopoverContent;
Popover.displayName = 'Popover';
export default Popover;
