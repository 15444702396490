import { BodyShort, Button, Link, PileDiv } from '@cegal/ds-components/dist'
import { Article, Clear, InsertDriveFile, Person } from '@cegal/ds-icons'
import styled from 'styled-components'

import { useNavigate } from 'react-router-dom'

import { Attachment } from 'hooks/useAiMessages'

import _ from 'lodash'

const AttachmentItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  align-items: center;
  gap: 0.5rem;
  background-color: var(--cat-drawer-background-color);
  padding: 0.5rem;
  border-radius: 0.4rem;
`

interface Props {
  item: Attachment
  removeAttachment?: () => void
}

const AiAttachment = ({ item, removeAttachment }: Props) => {
  const navigate = useNavigate()

  const iconMap = [
    { type: 'Document', icon: <InsertDriveFile size='1.5rem' /> },
    { type: 'ConsultantCard', icon: <Person size='1.5rem' /> }
  ]

  return (
    <AttachmentItem>
      {iconMap.find((x) => x.type === item.type)?.icon || <Article size='1.5rem' />}
      <PileDiv>
        {item.label && <BodyShort style={{ opacity: '0.7' }}>{item.label}</BodyShort>}
        <BodyShort>
          {item.link ? (
            <Link
              style={{ cursor: 'pointer', paddingBottom: '.4rem' }}
              onClick={() => navigate(item.link || '')}
            >
              {item.title}
            </Link>
          ) : (
            <span>{item.title}</span>
          )}
        </BodyShort>
      </PileDiv>
      {_.isFunction(removeAttachment) && (
        <Button onClick={removeAttachment} icon={<Clear size='1.5rem' />} variant='tertiary' size='small' />
      )}
    </AttachmentItem>
  )
}

export default AiAttachment
