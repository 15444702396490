import {
  BodyShort,
  Button,
  FlexCenterDiv,
  FlexDiv,
  Heading,
  HorizontalSpace,
  Loader,
  Pagination,
  Table,
  TextField,
  VerticalSpace
} from '@cegal/ds-components'
import { deleteSource, editSource, listSources, newSource } from 'actions'
import { Source } from 'declarations/models'
import { useAppDispatch, useAppSelector } from 'store'

import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useQuery from 'hooks/useQuery'

import BasicRowView from '../../components/Admin/BasicRowView'

const AdminSources = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [isNewMode, setIsNewMode] = useState<boolean>(false)
  const sources = useAppSelector((state) => state.sources.list)
  const loadingSources = useAppSelector((state) => state.sources.listing)
  const itemsPerPage = useAppSelector((state) => state.app.settings.itemsPerPage)

  const { query, setQuery } = useQuery<{
    page: string
    search: string
  }>({ page: '1', search: '' })
  const page = query?.page ? +query?.page : 1

  useEffect(() => {
    if (!loadingSources && sources === undefined) {
      dispatch(listSources())
    }
  }, [])

  const refreshSources = () => {
    dispatch(listSources())
  }

  const handlePageChange = (page: number) => {
    window.scrollTo(0, 0)
    setQuery({ page: page?.toString() })
  }

  const filteredSources = useMemo(
    () =>
      query.search
        ? sources?.filter((source: Source) =>
            source.name?.toLowerCase().includes(query.search!!.toLowerCase())
          ) ?? null
        : sources ?? null,
    [sources, query.search]
  )

  const sourcesPage = useMemo(() => {
    const firstPageIndex = (page - 1) * itemsPerPage
    const lastPageIndex = firstPageIndex + itemsPerPage
    return filteredSources?.slice(firstPageIndex, lastPageIndex) ?? null
  }, [page, filteredSources])

  const paginationRange = useMemo(() => {
    const numberOfPages = Math.ceil((filteredSources?.length ?? 0) / itemsPerPage)
    return numberOfPages
  }, [itemsPerPage, filteredSources])

  const headers: { label: string }[] = [{ label: t('label:id') }, { label: t('label:name') }, { label: '' }]

  const handleFilter = (value: { search: string }) => {
    setQuery(value)
  }

  return (
    <>
      <Heading level='2' size='medium' spacing>
        {t('label:sources-title')}
      </Heading>
      <FlexDiv style={{ justifyContent: 'space-between' }}>
        <FlexDiv>
          {loadingSources ? (
            <FlexCenterDiv>
              <Loader />
              <HorizontalSpace />
              <BodyShort>{t('messages:loading-sources')}...</BodyShort>
            </FlexCenterDiv>
          ) : (
            <BodyShort>
              {t('messages:sources-list-result', {
                x: filteredSources?.length
              })}
            </BodyShort>
          )}
        </FlexDiv>
        <TextField
          hideLabel
          description={t('label:search-description')}
          label={t('label:filter')}
          placeholder={t('label:search-title')}
          value={query?.search || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleFilter({ search: e.target.value || '' })
          }
        />
        <FlexDiv>
          <Button onClick={() => setIsNewMode(true)}>
            {t('buttons:add-new-x', { x: t('label:source-title') })}
          </Button>
          <HorizontalSpace />
          <Button onClick={refreshSources}>{t('buttons:refresh-x', { x: t('label:sources-title') })}</Button>
        </FlexDiv>
      </FlexDiv>
      <VerticalSpace />
      <Table style={{ tableLayout: 'fixed', width: '100%' }}>
        <Table.Header>
          <Table.Row>
            {headers.map((row) => (
              <Table.ColumnHeader key={`row-label-${row.label}`}>{row.label}</Table.ColumnHeader>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {isNewMode && (
            <BasicRowView<Source>
              onCancel={() => setIsNewMode(false)}
              isNewMode
              onNew={(item) => dispatch(newSource(item))}
            />
          )}
          {sourcesPage?.map((source: Source) => (
            <BasicRowView<Source>
              key={source.id}
              item={source}
              onEdit={(item) => dispatch(editSource(item))}
              onDelete={(item) => dispatch(deleteSource(item))}
            />
          ))}
        </Table.Body>
      </Table>

      {paginationRange > 1 && (
        <>
          <VerticalSpace size='2' />
          <Pagination
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
            size='medium'
            count={paginationRange}
            page={page}
            onPageChange={handlePageChange}
          />
        </>
      )}
    </>
  )
}

export default AdminSources
