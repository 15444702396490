import { Column, Row } from '@cegal/ds-components'
import gitinfo from 'gitinfo.json'

import { DescriptionListItem } from 'components/DescriptionList/DescriptionList'

import { useTranslation } from 'react-i18next'

import env from 'utils/environment'

interface PlatformSummaryProps {
  stats: Record<string, any> | null | undefined
}

const PlatformSummary = ({ stats }: PlatformSummaryProps) => {
  const { t } = useTranslation()

  const getDiffValue = () => {
    let diffDate = Math.floor(((parseInt(gitinfo.gitDate) as number) - new Date().valueOf()) / 1000)
    if (Math.abs(diffDate) < 60) {
      return { value: diffDate, unit: 'seconds' }
    }
    diffDate = Math.floor(diffDate / 60)
    if (Math.abs(diffDate) < 60) {
      return { value: diffDate, unit: 'minutes' }
    }
    diffDate = Math.floor(diffDate / 60)
    if (Math.abs(diffDate) < 24) {
      return { value: diffDate, unit: 'hours' }
    }
    diffDate = Math.floor(diffDate / 24)
    if (Math.abs(diffDate) < 7) {
      return { value: diffDate, unit: 'days' }
    }
    diffDate = Math.floor(diffDate / 7)
    if (Math.abs(diffDate) < 4) {
      return { value: diffDate, unit: 'weeks' }
    }
    diffDate = Math.floor(diffDate / 4)
    if (Math.abs(diffDate) < 12) {
      return { value: diffDate, unit: 'months' }
    }
    diffDate = Math.floor(diffDate / 12)
    return { value: diffDate, unit: 'years' }
  }

  const rtf = new Intl.RelativeTimeFormat('en', {
    localeMatcher: 'best fit',
    numeric: 'auto',
    style: 'long'
  })
  const diffValue = getDiffValue()
  const diffValueString = rtf.format(diffValue.value, diffValue.unit as Intl.RelativeTimeFormatUnit)

  return (
    <Row>
      <Column>
        {stats &&
          Object.keys(stats).map((key: string) => (
            <DescriptionListItem key={key} title={t('label:' + key + '-title')} details={stats[key]} />
          ))}
      </Column>
      <Column>
        <DescriptionListItem title={t('home:summary-gitBranch')} details={gitinfo.gitBranch} />
        <DescriptionListItem title={t('home:summary-appVersion')} details={gitinfo.appVersion} />
        <DescriptionListItem
          title={t('home:summary-gitHash')}
          details={gitinfo.gitCommitHash + ', ' + diffValueString}
        />
        <DescriptionListItem title={t('home:summary-env')} details={env} />
      </Column>
    </Row>
  )
}

export default PlatformSummary
