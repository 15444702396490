import {
  BodyShort,
  Button,
  FlexEndDiv,
  HorizontalSpace,
  Loader,
  Table,
  VerticalSpace
} from '@cegal/ds-components'
import { Delete } from '@cegal/ds-icons'
import styled from 'styled-components'

import { CardSize } from 'components/Card/Card'
import DeleteButton from 'components/Forms/Buttons/DeleteButton'
import EditButton from 'components/Forms/Buttons/EditButton'
import { ButtonWrapper, InsetDiv, OverlayDiv } from 'components/styled'

import { PropsWithChildren, useState } from 'react'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'

export interface RowProps<T, Q = any> extends PropsWithChildren {
  item: T
  query?: Q
  size?: CardSize
  ref?: (node?: Element | null | undefined) => void
  edited?: boolean
  // style for newly added card
  added?: boolean
  // animation for loading
  loading?: boolean
  onEdit?: (item: T) => void
  onDelete?: (item: T) => void
  buttons?: Array<React.ReactNode> | null
  editTooltip?: string
  deleteTooltip?: string
  deleteMessage?: string
}

export const Row = styled(Table.Row)<{
  edited?: boolean
  added?: boolean
}>`
  position: relative;
  background-color: ${(props) =>
    props.edited
      ? 'var(--cat-card-background-edited)'
      : props.added
        ? 'var(--cat-card-background-added)'
        : 'var(--cat-card-background)'};
`

const RowFC = <T extends any>({
  item,
  buttons,
  children,
  onDelete,
  editTooltip,
  deleteTooltip,
  size,
  ref,
  loading,
  onEdit,
  deleteMessage,
  ...rest
}: RowProps<T>) => {
  const { t } = useTranslation()
  const [deleteMode, setDeleteMode] = useState<boolean>(false)
  const confirmDelete = () => {
    onDelete?.(item)
    setDeleteMode(false)
  }

  const cancelDelete = () => setDeleteMode(false)

  return (
    <Row ref={ref} {...rest}>
      {children}
      <Table.DataCell key='buttons'>
        {loading ? (
          <Loader />
        ) : (
          <ButtonWrapper>
            {buttons}
            {_.isFunction(onDelete) && (
              <DeleteButton
                title={deleteTooltip}
                text={size === 'medium' ? t('buttons:delete') : undefined}
                showText={size === 'medium'}
                onDelete={() => setDeleteMode(true)}
              />
            )}
            {_.isFunction(onEdit) && (
              <EditButton
                title={editTooltip}
                text={size === 'medium' ? t('buttons:edit') : undefined}
                showText={size === 'medium'}
                onEdit={() => onEdit?.(item)}
              />
            )}
          </ButtonWrapper>
        )}
      </Table.DataCell>
      {deleteMode && (
        <OverlayDiv>
          <InsetDiv>
            <BodyShort>{deleteMessage ?? t('messages:are-you-sure')}</BodyShort>
            <VerticalSpace size='0.5' />
            <FlexEndDiv>
              <Button variant='danger' size='small' icon={<Delete size='1.2rem' />} onClick={confirmDelete}>
                {t('buttons:yes-delete')}
              </Button>
              <HorizontalSpace />
              <Button variant='secondary' size='small' onClick={cancelDelete}>
                {t('buttons:no-cancel')}
              </Button>
            </FlexEndDiv>
          </InsetDiv>
        </OverlayDiv>
      )}
    </Row>
  )
}

export default RowFC
