import { BodyShort, Button, FlexBaseSpacedDiv, FlexDiv, HorizontalSpace, Tooltip } from '@cegal/ds-components'
import { PictureAsPdf, TextSnippet } from '@cegal/ds-icons'
import { Delete as DeleteIcon } from '@cegal/ds-icons/dist/Delete'
import { Download as DownloadIcon } from '@cegal/ds-icons/dist/Download'
import { Preview as PreviewIcon } from '@cegal/ds-icons/dist/Preview'
import { File } from 'declarations/models'
import prettyBytes from 'pretty-bytes'

import { Hr } from 'components/styled'

import { useTranslation } from 'react-i18next'

import _ from 'lodash'

export interface FileRowProps {
  file: File
  separator?: boolean
  onPreview?: () => void
  onDelete?: () => void
  allowDownload?: boolean
}

const FileRow = ({ file, onPreview, onDelete, allowDownload = false, separator = false }: FileRowProps) => {
  const { t } = useTranslation()

  return (
    <>
      <FlexBaseSpacedDiv style={{ flexWrap: 'nowrap' }}>
        <FlexDiv style={{ flexWrap: 'nowrap' }}>
          {file.mimetype === 'application/pdf' ? <PictureAsPdf size='2rem' /> : <TextSnippet size='2rem' />}
          <HorizontalSpace />
          <BodyShort>
            {file.name} ({prettyBytes(file.size)})
          </BodyShort>
        </FlexDiv>

        <FlexDiv style={{ flexWrap: 'nowrap' }}>
          {_.isFunction(onPreview) && (
            <>
              <HorizontalSpace />
              <Tooltip content='Preview'>
                <Button
                  aria-label='preview-file'
                  variant='secondary'
                  size='small'
                  icon={<PreviewIcon size='1.5rem' />}
                  onClick={onPreview}
                />
              </Tooltip>
            </>
          )}
          {_.isFunction(onDelete) && (
            <>
              <HorizontalSpace />
              <Tooltip content={t('buttons:delete')}>
                <Button
                  aria-label='delete-note'
                  variant='secondary'
                  size='small'
                  icon={<DeleteIcon size='1.5rem' />}
                  onClick={onDelete}
                />
              </Tooltip>
            </>
          )}
          {allowDownload && (
            <>
              <HorizontalSpace />
              <Tooltip content={t('buttons:download')}>
                <Button
                  aria-label='download'
                  variant='secondary'
                  size='small'
                  icon={<DownloadIcon size='1.5rem' />}
                  onClick={() => {
                    const link = document.createElement('a')
                    link.href = file.content
                    link.setAttribute('download', file.name)
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                  }}
                />
              </Tooltip>
            </>
          )}
        </FlexDiv>
      </FlexBaseSpacedDiv>
      {separator && <Hr />}
    </>
  )
}

export default FileRow
