var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useRef, useState } from 'react';
function isNumber(n) {
    const number = parseFloat(n);
    return !isNaN(number) && isFinite(number);
}
function isPercentage(height) {
    return (typeof height === 'string' &&
        height[height.length - 1] === '%' &&
        isNumber(height.substring(0, height.length - 1)));
}
function hideContent(element, height) {
    if (height === 0 && (element === null || element === void 0 ? void 0 : element.style)) {
        element.style.display = 'none';
    }
}
function showContent(element, height) {
    if (height === 0 && (element === null || element === void 0 ? void 0 : element.style)) {
        element.style.display = '';
    }
}
const AnimateHeight = (_a) => {
    var { children, className, innerClassName, duration: userDuration = 250, easing = 'ease', height } = _a, props = __rest(_a, ["children", "className", "innerClassName", "duration", "easing", "height"]);
    const prevHeight = useRef(height);
    const contentElement = useRef(null);
    const animationClassesTimeoutID = useRef();
    const timeoutID = useRef();
    const isBrowser = typeof window !== 'undefined';
    const prefersReducedMotion = useRef(isBrowser && window.matchMedia ? window.matchMedia('(prefers-reduced-motion)').matches : false);
    const duration = prefersReducedMotion.current ? 0 : userDuration;
    let initHeight = height;
    let initOverflow = 'visible';
    if (typeof initHeight === 'number') {
        initHeight = height < 0 ? 0 : height;
        initOverflow = 'hidden';
    }
    else if (isPercentage(initHeight)) {
        initHeight = height === '0%' ? 0 : height;
        initOverflow = 'hidden';
    }
    const [currentHeight, setCurrentHeight] = useState(initHeight);
    const [overflow, setOverflow] = useState(initOverflow);
    const [useTransitions, setUseTransitions] = useState(false);
    useEffect(() => {
        hideContent(contentElement.current, currentHeight);
    }, []);
    useEffect(() => {
        if (height !== prevHeight.current && contentElement.current) {
            showContent(contentElement.current, prevHeight.current);
            contentElement.current.style.overflow = 'hidden';
            const contentHeight = contentElement.current.offsetHeight;
            contentElement.current.style.overflow = '';
            const totalDuration = duration;
            let newHeight;
            let timeoutHeight;
            let timeoutOverflow = 'hidden';
            let timeoutUseTransitions;
            const isCurrentHeightAuto = prevHeight.current === 'auto';
            if (typeof height === 'number') {
                newHeight = height < 0 ? 0 : height;
                timeoutHeight = newHeight;
            }
            else if (isPercentage(height)) {
                newHeight = height === '0%' ? 0 : height;
                timeoutHeight = newHeight;
            }
            else {
                newHeight = contentHeight;
                timeoutHeight = 'auto';
                timeoutOverflow = undefined;
            }
            if (isCurrentHeightAuto) {
                timeoutHeight = newHeight;
                newHeight = contentHeight;
            }
            setCurrentHeight(newHeight);
            setOverflow('hidden');
            setUseTransitions(!isCurrentHeightAuto);
            clearTimeout(timeoutID.current);
            clearTimeout(animationClassesTimeoutID.current);
            if (isCurrentHeightAuto) {
                timeoutUseTransitions = true;
                timeoutID.current = setTimeout(() => {
                    setCurrentHeight(timeoutHeight);
                    setOverflow(timeoutOverflow);
                    setUseTransitions(timeoutUseTransitions);
                }, 50);
                animationClassesTimeoutID.current = setTimeout(() => {
                    setUseTransitions(false);
                    hideContent(contentElement.current, timeoutHeight);
                }, totalDuration);
            }
            else {
                timeoutID.current = setTimeout(() => {
                    setCurrentHeight(timeoutHeight);
                    setOverflow(timeoutOverflow);
                    setUseTransitions(false);
                    if (height !== 'auto') {
                        hideContent(contentElement.current, newHeight);
                    }
                }, totalDuration);
            }
        }
        prevHeight.current = height;
        return () => {
            clearTimeout(timeoutID.current);
            clearTimeout(animationClassesTimeoutID.current);
        };
    }, [height]);
    const componentStyle = {
        height: currentHeight,
        overflow
    };
    if (useTransitions) {
        componentStyle.transition = `height ${duration}ms ${easing} 0ms`;
        componentStyle.WebkitTransition = componentStyle.transition;
    }
    const hasAriaHiddenProp = typeof props['aria-hidden'] !== 'undefined';
    const ariaHidden = hasAriaHiddenProp ? props['aria-hidden'] : height === 0;
    return (React.createElement("div", Object.assign({}, props, { "aria-hidden": ariaHidden, className: className, style: componentStyle }),
        React.createElement("div", { className: innerClassName, ref: contentElement }, children)));
};
export default AnimateHeight;
