import { Column, Row } from '@cegal/ds-components'
import { Widget } from 'declarations/app'

import FollowedClients from 'components/Home/FollowedClients/FollowedClients'
import FollowedConsultants from 'components/Home/FollowedConsultants/FollowedConsultants'
import FollowedEngagements from 'components/Home/FollowedEngagements/FollowedEngagements'
import MyMatches from 'components/Home/MyMatches/MyMatches'
import MyNotes from 'components/Home/MyNotes/MyNotes'
import NewsBox from 'components/Home/NewsBox/NewsBox'
import SummaryBox from 'components/Home/PlatformSummary/SummaryBox'

import { HomeProps } from 'pages/Home/Home'

import _ from 'lodash'

const HomeWidget = ({ widgetTable }: HomeProps) => {
  const numberRows: number = Math.max(widgetTable.left.length, widgetTable.right.length)

  const renderWidget = (widget: Widget | null): JSX.Element | null => {
    if (widget === null) {
      return null
    }
    switch (widget.id) {
      case 'news':
        return <NewsBox mode='widget' />
      case 'myMatches':
        return <MyMatches mode='widget' />
      case 'followedConsultants':
        return <FollowedConsultants mode='widget' />
      case 'followedEngagements':
        return <FollowedEngagements mode='widget' />
      case 'props':
        return <SummaryBox mode='widget' />
      case 'myNotifications':
        return null
      case 'myNotes':
        return <MyNotes mode='widget' />
      case 'followedClients':
        return <FollowedClients mode='widget' />
      default:
        return null
    }
  }

  return (
    <>
      {_.range(0, numberRows).map((rowNumber: number) => {
        const left = rowNumber < widgetTable.left.length ? widgetTable.left[rowNumber] : null
        const right = rowNumber < widgetTable.right.length ? widgetTable.right[rowNumber] : null
        const key = 'widgetTable-' + (left?.id ?? 'null') + (right?.id ?? 'null')
        return (
          <Row key={key}>
            {widgetTable.left.length !== 0 && <Column>{renderWidget(left)}</Column>}
            {widgetTable.right.length !== 0 && <Column>{renderWidget(right)}</Column>}
          </Row>
        )
      })}
    </>
  )
}

export default HomeWidget
