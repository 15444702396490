import { Query, Skill } from 'declarations/models'

import SkillChip from 'components/TagGroup/SkillChip'
import TagGroup, { RenderedElementProps, TagGroupMode } from 'components/TagGroup/TagGroup'

import _ from 'lodash'

interface SkillGroupProps<Q> {
  query?: Q
  skills: Array<Skill> | null | undefined
  setQuery?: (q: Q) => void
  interactive?: boolean
  mode?: TagGroupMode
  size?: 'medium' | 'small'
  visibleRows?: number
}

const SkillsGroup = <Q extends Partial<Query>>({
  query,
  skills,
  size,
  mode,
  setQuery,
  interactive,
  visibleRows
}: SkillGroupProps<Q>) => {
  const defaultComparator = (a: Skill, b: Skill) => {
    const profDiff = (b.proficiency ?? 0) - (a.proficiency ?? 0)
    if (profDiff > 0) return 1
    if (profDiff < 0) return -1
    const yearDiff = (b.years ?? 0) - (a.years ?? 0)
    if (yearDiff > 0) return 1
    if (yearDiff < 0) return -1
    return a.name.localeCompare(b.name)
  }

  const handleTagsChanged = (skillIds: Array<Skill['id']>) => {
    setQuery?.({ skills: skillIds } as Q)
  }

  return (
    <TagGroup
      size={size}
      tags={skills}
      defaultComparator={defaultComparator}
      queryTagIds={
        _.isArray(query?.skills) ? query!.skills : _.isNumber(query?.skills) ? [query!.skills] : []
      }
      searchWords={query?.search}
      mode={mode}
      interactive={interactive}
      visibleRows={visibleRows}
      setTags={handleTagsChanged}
      renderElement={(props: RenderedElementProps<Skill>) => <SkillChip key={props.tag.id} {...props} />}
    />
  )
}

export default SkillsGroup
