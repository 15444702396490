import {
  Button,
  FlexBaseDiv,
  FlexDiv,
  Heading,
  HorizontalSpace,
  Modal,
  Panel,
  PileEndDiv,
  Textarea,
  VerticalSpace
} from '@cegal/ds-components'
import { ClientCommentModel } from 'declarations/models'
import { useAppSelector } from 'store'

import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'

export interface EditCommentModalProps {
  comment: ClientCommentModel | undefined
  open: boolean
  onClose: () => void
  onNewComment?: (comment: ClientCommentModel) => void
  onSaveComment?: (comment: ClientCommentModel) => void
}

const ClientCommentModal = ({
  comment,
  open,
  onClose,
  onNewComment,
  onSaveComment
}: EditCommentModalProps) => {
  const { t } = useTranslation()

  const [editMode, setEditMode] = useState<boolean | undefined>(undefined)

  useEffect(() => {
    reset(comment)
    setEditMode(_.isNumber(comment?.id))
  }, [comment])

  const {
    handleSubmit,
    register,
    reset,
    formState: { isDirty, errors }
  } = useForm<ClientCommentModel>()

  const creating = useAppSelector((state) => state.clientComments.creating)
  const created = useAppSelector((state) => state.clientComments.created)
  const saving = useAppSelector((state) => state.clientComments.saving)
  const saved = useAppSelector((state) => state.clientComments.saved)

  const onSubmit = (comment: ClientCommentModel) => {
    if (editMode && onSaveComment) {
      onSaveComment(comment)
    }
    if (!editMode && onNewComment) {
      onNewComment(comment)
    }
  }

  useEffect(() => {
    if (editMode && saved !== undefined) {
      onClose()
    }
    if (!editMode && created !== undefined) {
      onClose()
    }
  }, [saved, created, editMode])

  const modalContainerRef = useRef(null)

  return (
    <FlexBaseDiv ref={modalContainerRef}>
      {modalContainerRef?.current && (
        <Modal
          parentSelector={() => modalContainerRef.current!}
          open={open}
          aria-label='Edit command'
          closeButton={false}
          onClose={onClose}
          shouldCloseOnOverlayClick={false}
        >
          <Modal.Content>
            <Panel style={{ minWidth: '600px' }}>
              <Heading spacing level='2' size='medium'>
                {t(!editMode ? 'buttons:add-new-x' : 'buttons:edit-x', {
                  x: t('label:match-comment').toLowerCase()
                })}
              </Heading>
              <VerticalSpace size='2' />
              <Textarea
                data-cy='commentTextArea'
                resize
                rows={6}
                {...register('body', {
                  required: t('validation:comment-no-text-details').toString()
                })}
                label={t('label:comments-description')}
                hideLabel
                error={errors?.body?.message}
              />
              <VerticalSpace size='2' />
              <PileEndDiv>
                <FlexDiv>
                  <Button
                    variant='secondary'
                    onClick={() => {
                      if (!isDirty) {
                        onClose()
                      } else {
                        if (window.confirm(t('messages:confirm-close-comment')!)) {
                          onClose()
                        }
                      }
                    }}
                  >
                    {t('buttons:close')}
                  </Button>
                  <HorizontalSpace />
                  <Button loading={saving || creating} onClick={handleSubmit(onSubmit)}>
                    {editMode
                      ? saving
                        ? t('buttons:saving')
                        : t('buttons:save')
                      : creating
                        ? t('buttons:adding')
                        : t('buttons:add')}
                  </Button>
                </FlexDiv>
              </PileEndDiv>
            </Panel>
          </Modal.Content>
        </Modal>
      )}
    </FlexBaseDiv>
  )
}

export default ClientCommentModal
