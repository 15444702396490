import { VerticalSpace } from '@cegal/ds-components'
import { getEngagement, setEngagement } from 'actions'
import { ENGAGEMENTS_GET_FAILURE } from 'constants/actionTypes'
import { useAppDispatch, useAppSelector } from 'store'

import { FullScreenLoader } from 'components'
import AlertPanel from 'components/AlertPanel/AlertPanel'
import EngagementForm from 'components/Engagements/Engagement/Form/EngagementForm'
import PageContent from 'components/PageContent/PageContent'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import _ from 'lodash'

const EngagementEdit = () => {
  const { id } = useParams()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const apiReady = useAppSelector((state) => state.app.apiReady)
  const engagement = useAppSelector((state) => state.engagements.get)
  const engagements = useAppSelector((state) => state.engagements.list)

  const loadPageData = () => {
    if (id) {
      const parsedId = parseInt(id)
      if (_.isNumber(parsedId)) {
        if (!_.isNil(engagements)) {
          const found = engagements?.find((obj) => obj.id === parsedId)
          if (found) {
            dispatch(setEngagement(found))
            return
          }
        }
        dispatch(getEngagement(parsedId))
      }
    }
  }

  useEffect(() => {
    if (apiReady) {
      loadPageData()
    }
  }, [apiReady])

  useEffect(() => {
    if (id) {
      const parsedId = parseInt(id)
      if (_.isNumber(parsedId)) {
        if (!_.isNil(engagements)) {
          const found = engagements?.find((obj) => (id !== undefined ? obj.id === parsedId : false))
          if (found) {
            dispatch(setEngagement(found))
            return
          }
        }
        dispatch(getEngagement(parsedId))
      }
    }
  }, [id])

  if (!engagement) {
    return (
      <PageContent maxWidth='1600px'>
        <VerticalSpace />
        {_.isUndefined(engagement) && <FullScreenLoader />}
        {_.isNull(engagement) && (
          <AlertPanel
            doNotRenderIfHeadIsActiveWithError
            watchFor={[ENGAGEMENTS_GET_FAILURE]}
            buttonText={t('buttons:click-to-reload-the-page')}
            reloadPage={loadPageData}
          />
        )}
      </PageContent>
    )
  }

  return (
    <PageContent maxWidth='1000px'>
      <VerticalSpace />
      <EngagementForm engagement={engagement} editView />
    </PageContent>
  )
}

export default EngagementEdit
