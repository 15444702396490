var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as RadixToggleGroup from '@radix-ui/react-toggle-group';
import cl from 'clsx';
import { Label } from 'components/typography';
import React, { forwardRef, useContext } from 'react';
import { ToggleGroupContext } from './ToggleGroup';
const ToggleItem = forwardRef((_a, ref) => {
    var { className, children } = _a, rest = __rest(_a, ["className", "children"]);
    const context = useContext(ToggleGroupContext);
    return (React.createElement(RadixToggleGroup.Item, Object.assign({}, rest, { ref: ref, className: cl('cds-toggle-group__button', className) }),
        React.createElement(Label, { as: 'span', className: 'cds-toggle-group__button-inner', size: context === null || context === void 0 ? void 0 : context.size }, children)));
});
ToggleItem.displayName = 'ToggleGroup.Item';
export default ToggleItem;
