import * as types from 'constants/actionTypes'
import { AnyAction } from 'redux'

export type CacheDomain = 'consultants' | 'clients'
export interface CacheState {
  clientAvatars: Record<string, string>
  consultantAvatars: Record<string, string>
  consultantAllocations: Record<string, any>
  consultantAllocationsSearch: any
  consultantAllocationsSearchKey: string | undefined
  consultantAllocationsSearching: boolean
}

export const initialCacheState: CacheState = {
  clientAvatars: {},
  consultantAvatars: {},
  consultantAllocations: {},
  consultantAllocationsSearch: undefined,
  consultantAllocationsSearchKey: undefined,
  consultantAllocationsSearching: false
}

const cacheReducer = (state = initialCacheState, action: AnyAction) => {
  switch (action.type) {
    case types.CACHE_SET: {
      const cacheDomain: CacheDomain | undefined = action.payload.domain
      const cacheKey: string = action.payload.key
      const cacheValue: string = action.payload.value

      if (cacheDomain === 'clients') {
        return {
          ...state,

          clientAvatars: {
            ...state.clientAvatars,
            [cacheKey]: cacheValue
          }
        }
      }

      return {
        ...state,
        consultantAvatars: {
          ...state.consultantAvatars,
          [cacheKey]: cacheValue
        }
      }
    }

    case types.CONSULTANTS_ALLOCATION_SUCCESS: {
      return {
        ...state,
        consultantAllocations: {
          ...state.consultantAllocations,
          [action.context.key]: /* istanbul ignore next */ action.payload ?? []
        }
      }
    }

    case types.CONSULTANTS_ALLOCATION_FAILURE: {
      return {
        ...state,
        consultantAllocations: {
          ...state.consultantAllocations,
          [action.context.key]: null
        }
      }
    }

    case types.CLIENTS_AVATAR_SUCCESS:
      return {
        ...state,
        clientAvatars: {
          ...state.clientAvatars,
          [action.context.key]: action.payload.image_href
        }
      }

    case types.CLIENTS_AVATAR_FAILURE:
      return {
        ...state,
        clientAvatars: {
          ...state.clientAvatars,
          [action.context.key]: null
        }
      }

    case types.CONSULTANTS_AVATAR_SUCCESS:
      return {
        ...state,
        consultantAvatars: {
          ...state.consultantAvatars,
          [action.context.key]: action.payload.image_href
        }
      }

    case types.CONSULTANTS_AVATAR_FAILURE:
      return {
        ...state,
        consultantAvatars: {
          ...state.consultantAvatars,
          [action.context.key]: null
        }
      }

    case types.CONSULTANTS_ALLOCATIONS_SEARCH_REQUEST:
      return {
        ...state,
        consultantAllocationsSearching: true,
        consultantAllocationsSearchKey: action.context.key,
        consultantAllocationsSearch: undefined
      }

    case types.CONSULTANTS_ALLOCATIONS_SEARCH_SUCCESS:
      return {
        ...state,
        consultantAllocationsSearching: false,
        consultantAllocationsSearch: action.payload ?? []
      }

    case types.CONSULTANTS_ALLOCATIONS_SEARCH_FAILURE:
      return {
        ...state,
        consultantAllocationsSearching: false,
        consultantAllocationsSearch: null
      }

    default:
      return state
  }
}

export default cacheReducer
