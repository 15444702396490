import * as types from 'constants/actionTypes'
import { AlertElement } from 'declarations/app'
import { AnyAction } from 'redux'

import i18n from 'i18n'

import _ from 'lodash'

export interface AlertsState {
  head: AlertElement | undefined
  body: Array<AlertElement>
}
export const initialAlertState: AlertsState = {
  head: undefined,
  body: []
}

const alertsReducer = (
  /* istanbul ignore next */ state: AlertsState = initialAlertState,
  action: AnyAction
) => {
  let headMessage: React.ReactNode | undefined
  let bodyMessage: React.ReactNode | undefined

  if (action.type === types.ALERTS_HEAD_CLEAR) {
    return {
      ...state,
      head: undefined
    }
  }

  if (action.type === types.ALERTS_BODY_CLEAR) {
    /* istanbul ignore next */
    const needle = (action.payload as Array<string>) ?? []
    return {
      ...state,
      body: state.body.filter((a: AlertElement) => !needle.includes(a.type))
    }
  }

  if (action.type === types.ALERTS_BODY_CLEARALL) {
    return {
      ...state,
      body: []
    }
  }

  /**
   * All ERROR MESSAGES go here, to head alert
   **/
  if (_.endsWith(action.type, '/ERROR')) {
    switch (action.type) {
      default:
        headMessage = i18n.t('messages:error-serverInternalError') + ': ' + (action.error?.message ?? '')
        headMessage += /* istanbul ignore next */ action.error?.url ? ' - ' + action.error.url : ''
        break
    }

    return {
      ...state,
      head: {
        type: action.type,
        status: action.status,
        message: headMessage,
        variant: 'error'
      }
    }
  }

  /**
   * All REQUEST actions go here, they will clean up all errors with equal action type prefix
   */
  if (_.endsWith(action.type, '_REQUEST')) {
    const prefix = action.type.substring(0, action.type.lastIndexOf('_REQUEST'))
    return {
      ...state,
      head: state.head?.type?.startsWith(prefix) ? undefined : { ...state.head },
      body: state.body?.filter((a: AlertElement) => !a.type?.startsWith(prefix))
    }
  }

  /**
   * All FAILURE MESSAGES go here, to body alert
   **/
  if (_.endsWith(action.type, '_FAILURE')) {
    switch (action.type) {
      case types.CONSULTANTS_LIST_FAILURE:
        if (action.status === 404) {
          bodyMessage = i18n.t('messages:consultant-list-error-404')?.toString()
        }
        break

      case types.APP_SIGNUP_NEW_FAILURE:
        if (action.status === 404) {
          bodyMessage = i18n.t('messages:sign-up-error-404')?.toString()
        }
        break

      case types.ENGAGEMENTS_LIST_FAILURE:
        if (action.status === 404) {
          bodyMessage = i18n.t('messages:engagement-list-error-404')?.toString()
        }
        break

      case types.CONSULTANTS_CV_FAILURE:
        if (action.status === 404) {
          bodyMessage = i18n.t('messages:consultant-cv-error-404')?.toString()
        }
        break

      case types.MATCHES_LIST_FAILURE:
        if (action.status === 404) {
          bodyMessage = i18n.t('messages:match-list-error-404')?.toString()
        }
        break

      case types.NOTES_LIST_FAILURE:
        if (action.status === 404) {
          bodyMessage = i18n.t('messages:note-list-error-404')?.toString()
        }
        break

      case types.CLIENTS_NEW_FAILURE:
        if (action.status === 404) {
          bodyMessage = i18n.t('messages:clients-new-error-404')?.toString()
        }
        break

      case types.CLIENTS_EDIT_FAILURE:
        if (action.status === 404) {
          bodyMessage = i18n.t('messages:clients-save-error-404')?.toString()
        }
        break

      case types.CLIENTS_DELETE_FAILURE:
        if (action.status === 404) {
          bodyMessage = i18n.t('messages:clients-delete-error-404')?.toString()
        }
        break

      case types.SOURCES_NEW_FAILURE:
        if (action.status === 404) {
          bodyMessage = i18n.t('messages:sources-new-error-404')?.toString()
        }
        break

      default:
        bodyMessage = action.payload?.message || i18n.t('messages:error-genericFailure')
        break
    }

    return {
      ...state,
      body: state.body.concat({
        type: action.type,
        message: bodyMessage,
        variant: 'error',
        status: action.status
      })
    }
  }

  if (_.endsWith(action.type, '_SUCCESS')) {
    switch (action.type) {
      case types.ENGAGEMENTS_NEW_SUCCESS:
        bodyMessage = i18n.t('messages:success-engagement-created')?.toString()
        break
      case types.ENGAGEMENTS_EDIT_SUCCESS:
        bodyMessage = i18n.t('messages:success-engagement-saved')?.toString()
        break
      case types.ENGAGEMENTS_DELETE_SUCCESS:
        bodyMessage = i18n.t('messages:success-engagement-deleted')?.toString()
        break
      case types.APP_SIGNUP_NEW_SUCCESS:
        bodyMessage = i18n.t('messages:success-sign-up')?.toString()
        break
      case types.SOURCES_NEW_SUCCESS:
        bodyMessage = i18n.t('messages:success-source-created')?.toString()
        break
      case types.CLIENTS_NEW_SUCCESS:
        bodyMessage = i18n.t('messages:success-client-created')?.toString()
        break
      case types.CLIENTS_EDIT_SUCCESS:
        bodyMessage = i18n.t('messages:success-client-saved')?.toString()
        break
      case types.CLIENTS_DELETE_SUCCESS:
        bodyMessage = i18n.t('messages:success-client-deleted')?.toString()
        break
      default:
        break
    }

    if (bodyMessage) {
      return {
        ...state,
        body: state.body.concat({
          type: action.type,
          message: bodyMessage,
          variant: 'success',
          status: action.status
        })
      }
    }
  }

  return state
}

export default alertsReducer
