import _ from 'lodash'

const cleanUp = (s: string): string => s.normalize('NFD').toLowerCase()

export const tokenize = (s: string | undefined): string[] => {
  if (_.isNil(s)) {
    return []
  }
  return cleanUp(s)
    .replace(/\p{Diacritic}/gu, '')
    .replace(/\p{Punctuation}/gu, ' ')
    .replace(/\p{Mark}/gu, ' ')
    .replace(/\p{Symbol}/gu, ' ')
    .trim()
    .split(/\s+/)
}

export const match = (needle: string | undefined, haystack: string | undefined): boolean => {
  if (!needle || !haystack) {
    return false
  }
  const _needle = tokenize(needle)
  const _haystack = tokenize(haystack)
  return _.find(_needle, (n) => _.find(_haystack, (_h) => _h.match(n)) !== undefined) !== undefined
}
