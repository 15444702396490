import { Avatar, BodyShort, FlexDiv, HorizontalSpace, Label, Panel, PileStartDiv } from '@cegal/ds-components'
import UnknownAvatar from 'assets/images/unknownAvatar.png'
import { OrgChartNode } from 'declarations/models'
import styled from 'styled-components'

import useConsultantAvatar from 'hooks/useConsultantAvatar'

export interface ConsultantOrgChartNodeProps {
  id: string
  data: OrgChartNode
  highlight?: boolean
}

const CustomPanel = styled(Panel)<{ highlight: boolean }>`
  border: ${(props: any) =>
    props.highlight ? '2px solid var(--cds-border-color-interactive)' : '1px solid var(--cds-border-color)'};
`

const ConsultantOrgChartNode = ({ id, data, highlight = false }: ConsultantOrgChartNodeProps) => {
  const { avatar, avatarLoading, ref: avatarRef } = useConsultantAvatar({ id: data?.catId })

  return (
    <CustomPanel id={id} border highlight={highlight}>
      <FlexDiv>
        <Avatar
          variant='circle'
          size='medium'
          src={avatar}
          defaultSrc={UnknownAvatar}
          loading={avatarLoading}
          ref={avatarRef}
        />
        <HorizontalSpace size='0.5' />
        <PileStartDiv>
          <Label>{data.displayName}</Label>
          <BodyShort>{data.jobTitle}</BodyShort>
          <BodyShort>{data.officeLocation}</BodyShort>
        </PileStartDiv>
      </FlexDiv>
    </CustomPanel>
  )
}

export default ConsultantOrgChartNode
