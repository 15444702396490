import {
  AlignEndColumn,
  Avatar,
  BodyShort,
  Button,
  Dropzone,
  DropzoneFile,
  FlexBaseDiv,
  FlexDiv,
  Heading,
  HorizontalSpace,
  Label,
  Modal,
  PileCenterDiv,
  Row,
  SelectMultiple,
  Switch,
  TextField,
  Textarea,
  VerticalSpace
} from '@cegal/ds-components'
import { Client, Location, SimpleEntry } from 'declarations/models'
import { useAppSelector } from 'store'

import { useEffect, useMemo, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import useOrgChart from 'hooks/useOrgChart'

import { Option } from 'utils/fieldUtils'

import _ from 'lodash'

export interface ClientModalProps {
  askForAddClient?: boolean
  client: Partial<Client> | undefined
  open: boolean
  onClose: () => void
  onNewClient?: (client: Client, addClient: boolean) => void
  onSaveClient?: (client: Client) => void
  anchorEl?: Element | null
}

const ClientModal = ({
  askForAddClient = false,
  client,
  open,
  onClose,
  onNewClient,
  onSaveClient,
  anchorEl = null
}: ClientModalProps) => {
  const { t } = useTranslation()
  const [addClient, setAddClient] = useState<boolean>(askForAddClient)
  const [editMode, setEditMode] = useState<boolean | undefined>(undefined)

  const modalContainerRef = useRef(null)

  const {
    handleSubmit,
    control,
    register,
    reset,
    watch,
    formState: { isDirty, errors }
  } = useForm<Client>()

  const locations = useAppSelector((state) => state.locations.list)
  const creating = useAppSelector((state) => state.clients.creating)
  const created = useAppSelector((state) => state.clients.created)
  const saving = useAppSelector((state) => state.clients.saving)
  const saved = useAppSelector((state) => state.clients.saved)

  const { loading } = useOrgChart()

  const _location: string | undefined = watch('location')
  const onSubmit = (client: Client) => {
    if (editMode && onSaveClient) {
      onSaveClient(client)
    }
    if (!editMode && onNewClient) {
      onNewClient(client, addClient)
    }
  }

  const locationOptions: Array<Option<Location>> = useMemo(() => {
    if (!_.isEmpty(locations)) {
      return (
        locations?.map((l) => ({
          ...l,
          label: l.name,
          value: l.id
        })) ?? []
      )
    }
    return []
  }, [locations])

  const selectedLocation: Option<SimpleEntry> | null = useMemo(
    () => _.find(locationOptions, { id: parseInt(_location) }) ?? null,
    [_location, locationOptions]
  )

  useEffect(() => {
    if (editMode && saved !== undefined) {
      onClose()
    }
    if (!editMode && created !== undefined) {
      onClose()
    }
  }, [saved, created, editMode])

  useEffect(() => {
    reset(client)
    setEditMode(_.isNumber(client?.id))
  }, [client])

  return (
    <FlexBaseDiv ref={modalContainerRef}>
      {modalContainerRef?.current && (
        <Modal
          // @ts-ignore
          parentSelector={() => anchorEl ?? modalContainerRef.current!}
          open={open}
          aria-label={t('home:edit-client')}
          closeButton={false}
          onClose={onClose}
          shouldCloseOnOverlayClick={false}
        >
          <Modal.Content style={{ minWidth: '400px' }}>
            <Modal.Content.Header>
              <Heading spacing level='2' size='medium'>
                {t(!editMode ? 'buttons:add-new-x' : 'buttons:edit-x', {
                  x: t('label:client').toLowerCase()
                })}
              </Heading>
            </Modal.Content.Header>
            <Modal.Content.Body>
              <VerticalSpace />
              <TextField
                label={t('label:name-title') + ' *'}
                {...register('name', {
                  required: t('validation:client-no-name').toString()
                })}
                error={errors?.name?.message}
              />
              <VerticalSpace size='2' />
              <Controller
                control={control}
                name='location'
                render={({ field: { onChange } }) => (
                  <SelectMultiple
                    multiple={false}
                    menuPosition='fixed'
                    loading={loading}
                    label={t('label:location-title')}
                    defaultValue={!_.isEmpty(selectedLocation) ? selectedLocation : null}
                    error={errors?.location?.message}
                    onChange={(location: Option<Location>) => onChange(location?.value?.toString())}
                    options={locationOptions}
                    getOptionValue={(location: Location) => location.name}
                  />
                )}
              />
              <VerticalSpace size='2' />
              <Textarea
                {...register('description')}
                label={t('label:description-title')}
                resize
                error={errors?.description?.message}
              />
              <VerticalSpace size='2' />

              <Label>{t('label:image-title')}</Label>
              <VerticalSpace size='0.3' />
              <BodyShort size='small'>{t('label:image-description')}</BodyShort>
              <VerticalSpace size='0.5' />
              <Controller
                control={control}
                name='image_href'
                render={({ field: { onChange, value } }) => {
                  return (
                    <PileCenterDiv>
                      <Dropzone
                        // @ts-ignore
                        style={{ width: '100%' }}
                        multiple={false}
                        accept={{
                          'image/jpeg': ['.jpeg', '.jpg'],
                          'image/png': ['.png']
                        }}
                        labels={{
                          dropFilesHere: t('messages:drag-and-drop-logo')
                        }}
                        onDrop={(files: Array<DropzoneFile>) => {
                          onChange(files[0].content)
                        }}
                      />
                      <VerticalSpace />
                      {value && <Avatar src={value} size='medium' variant='square-round' />}
                    </PileCenterDiv>
                  )
                }}
              />
              {askForAddClient && (
                <>
                  <Switch checked={addClient} onChange={() => setAddClient(!addClient)}>
                    {' '}
                    {t('label:add-new-client-after-create')}
                  </Switch>
                </>
              )}
            </Modal.Content.Body>
            <Modal.Content.Footer>
              <Row>
                <AlignEndColumn style={{ alignItems: 'flex-end' }}>
                  <FlexDiv>
                    <Button
                      variant='secondary'
                      onClick={() => {
                        if (!isDirty) {
                          onClose()
                        } else {
                          if (window.confirm(t('messages:confirm-close-note')!)) {
                            onClose()
                          }
                        }
                      }}
                    >
                      {t('buttons:close')}
                    </Button>
                    <HorizontalSpace />
                    <Button loading={saving || creating} onClick={handleSubmit(onSubmit)}>
                      {editMode
                        ? saving
                          ? t('buttons:saving')
                          : t('buttons:save')
                        : creating
                          ? t('buttons:adding')
                          : t('buttons:add')}
                    </Button>
                  </FlexDiv>
                </AlignEndColumn>
              </Row>
            </Modal.Content.Footer>
          </Modal.Content>
        </Modal>
      )}
    </FlexBaseDiv>
  )
}

export default ClientModal
