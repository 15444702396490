import { TableHeader } from 'declarations/app'

import _ from 'lodash'

export const initialConsultantsTable: Array<string> = [
  'name',
  'engagements',
  'allocation',
  'department',
  'location',
  'skills',
  'certificates'
]

export const initialEngagementsTable: Array<string> = [
  'title',
  'client',
  'source',
  'location',
  'deadline',
  'start_date',
  'average_rate',
  'skills',
  'qualified',
  'certificates'
]

export const consultantHeaders: Array<TableHeader> = [
  { value: 'name', label: 'label:name-title', sortKey: 'name' },
  { value: 'email', label: 'label:email-title', sortKey: 'email' },
  { value: 'department', label: 'label:department-title', sortKey: 'department.name' },
  { value: 'location', label: 'label:location-title', sortKey: 'location.name' },
  { value: 'roles', label: 'label:roles-title' },
  { value: 'skills', label: 'label:skills-title' },
  { value: 'engagements', label: 'label:engagements-title' },
  { value: 'allocation', label: 'label:allocation-title' },
  // deprecated, but still needs to be here because localStorage in users
  { value: 'office', label: 'deprecated' },
  { value: 'categories', label: 'label:categories-title' },
  { value: 'certificates', label: 'label:certificates' }
]

export const engagementHeaders: Array<TableHeader> = [
  { value: 'title', label: 'label:engagement-title', sortKey: 'title' },
  { value: 'client', label: 'label:client-title' },
  { value: 'source', label: 'label:source-title', sortKey: 'source.name' },
  { value: 'posted_date', label: 'label:posted-date-title', sortKey: 'posted_date' },
  { value: 'creator', label: 'label:creator-title', sortKey: 'creator.name' },
  { value: 'start_date', label: 'label:start-date-title', sortKey: 'start_date' },
  { value: 'end_date', label: 'label:end-date-title', sortKey: 'end_date' },
  { value: 'certificates', label: 'label:certificates' },
  {
    value: 'optional_extension_period',
    label: 'label:optional-extension-period-title',
    sortKey: 'optional_extension_period'
  },
  { value: 'location', label: 'label:location-title', sortKey: 'location' },
  { value: 'deadline', label: 'label:reply-deadline-title', sortKey: 'deadline' },
  { value: 'status', label: 'label:status-title', sortKey: 'status' },
  { value: 'last_modified_date', label: 'label:last-modified-date-title', sortKey: 'last_modified_date' },
  { value: 'last_modifier_id', label: 'label:last-modifier-title', sortKey: 'last_modifier_id.name' },
  { value: 'average_rate', label: 'label:average-rate-title' },
  { value: 'skills', label: 'label:skills-title' },
  { value: 'qualified', label: 'label:qualified-title' },
  // deprecated
  { value: 'categories', label: 'label:categories-title' }
]

export const getConsultantHeader = (value: string): TableHeader | undefined =>
  _.find(consultantHeaders, { value })

export const getEngagementHeader = (value: string): TableHeader | undefined =>
  _.find(engagementHeaders, { value })
