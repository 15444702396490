import { Alert, Button, Content, FlexCenterDiv, HorizontalSpace } from '@cegal/ds-components'
import { Close } from '@cegal/ds-icons/dist/Close'
import { clearAlertHead, setAiDrawerOpen } from 'actions'
import { AlertsState } from 'reducers/alerts'
import { useAppDispatch, useAppSelector } from 'store'
import styled from 'styled-components'

import AiDrawer from 'components/AI/AiDrawer'
import FluidDrawer from 'components/Drawer/FluidDrawer'

import { useEffect } from 'react'

import _ from 'lodash'

const BodyContainer = styled.div<{
  $openDrawer: boolean
  $drawerWidth: any
  $openAiDrawer: boolean
  $aiDrawerWidth: any
}>`
  display: block;
  margin-bottom: 2rem;
  width: 100%;
  transition: all 0.3s;
  margin-left: ${({ $openDrawer, $drawerWidth }) => ($openDrawer ? $drawerWidth : 0)};
  margin-right: ${({ $openAiDrawer, $aiDrawerWidth }) => ($openAiDrawer ? $aiDrawerWidth : 0)};
`

export const Body = styled.div`
  flex: 1 1 auto;
  display: flex;
  margin-top: var(--cat-header-height); /* leave space for fixed header */
  flex-direction: row;
`

export const BodyContent = styled(Content)<{
  $maxWidth?: string | undefined
  padding?: string
}>`
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding: ${({ padding }) => padding ?? '1rem'};
  max-width: ${({ $maxWidth }) => $maxWidth ?? '100%'};
`

export interface PageContentProps extends React.PropsWithChildren {
  drawer?: React.ReactNode
  isDrawerOpen?: boolean
  aiDrawer?: React.ReactNode
  showAiDrawer?: boolean
  drawerWidth?: any
  aiDrawerWidth?: any
  maxWidth?: any
  padding?: any
}

const WideAlert = styled(Alert)`
  top: calc(var(--cat-header-height) + 10px); /* leave space for fixed header */
  position: fixed;
  z-index: 2;
  right: 10px;
`
const PageContent = ({
  children,
  drawer,
  isDrawerOpen = false,
  drawerWidth = '20rem',
  aiDrawer,
  aiDrawerWidth = '30rem',
  maxWidth,
  padding
}: PageContentProps) => {
  const dispatch = useAppDispatch()

  const alert = useAppSelector((state) => (state.alerts as AlertsState).head)
  const aiDrawerOpen = useAppSelector((state) => state.app.settings.aiDrawerOpen)

  useEffect(() => {
    dispatch(setAiDrawerOpen(aiDrawerOpen))
  }, [aiDrawerOpen])

  // const [showAiDrawer, setShowAiDrawer] = useState(false)
  return (
    <>
      {!_.isEmpty(alert) && (
        <WideAlert variant={alert.variant}>
          <FlexCenterDiv>
            <span>{alert.message}</span>
            <HorizontalSpace />
            <Button
              variant='tertiary'
              size='small'
              icon={<Close size='1.5rem' />}
              onClick={() => dispatch(clearAlertHead())}
            />
          </FlexCenterDiv>
        </WideAlert>
      )}
      <Body className='body'>
        {!!drawer && (
          <FluidDrawer open={isDrawerOpen} width={drawerWidth}>
            {drawer}
          </FluidDrawer>
        )}

        <AiDrawer open={aiDrawerOpen} width={aiDrawerWidth} onClose={() => dispatch(setAiDrawerOpen(false))}>
          {aiDrawer}
        </AiDrawer>

        <BodyContainer
          $openAiDrawer={aiDrawerOpen}
          $aiDrawerWidth={aiDrawerWidth}
          $openDrawer={isDrawerOpen}
          $drawerWidth={drawerWidth}
        >
          <BodyContent $maxWidth={maxWidth} padding={padding}>
            {children}
          </BodyContent>
        </BodyContainer>
      </Body>
    </>
  )
}

export default PageContent
