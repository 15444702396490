import * as types from 'constants/actionTypes'
import { Department } from 'declarations/models'
import { AnyAction } from 'redux'

import _ from 'lodash'

export interface DepartmentsState {
  created: Department | null | undefined
  creating: boolean
  deleted: Department | null | undefined
  deleting: boolean
  get: Department | null | undefined
  getting: boolean
  list: Array<Department> | null | undefined
  listing: boolean
  saving: boolean
  saved: Department | null | undefined
}

export const initialDepartmentsState: DepartmentsState = {
  created: undefined,
  creating: false,
  deleted: undefined,
  deleting: false,
  get: undefined,
  getting: false,
  list: undefined,
  listing: false,
  saved: undefined,
  saving: false
}

const departmentsReducer = (
  /* istanbul ignore next */
  state: DepartmentsState = initialDepartmentsState,
  action: AnyAction
): DepartmentsState => {
  switch (action.type) {
    case types.DEPARTMENTS_DELETE_REQUEST:
      return {
        ...state,
        deleted: undefined,
        deleting: true
      }

    case types.DEPARTMENTS_DELETE_SUCCESS: {
      const deletedDepartment = action.originalPayload
      return {
        ...state,
        deleted: deletedDepartment,
        deleting: false,
        list: _.reject(state.list, { id: deletedDepartment.id })
      }
    }

    case types.DEPARTMENTS_DELETE_FAILURE:
      return {
        ...state,
        deleted: null,
        deleting: false
      }

    case types.DEPARTMENTS_EDIT_REQUEST:
      return {
        ...state,
        saved: undefined,
        saving: true
      }

    case types.DEPARTMENTS_EDIT_SUCCESS: {
      const editedDepartment = action.payload
      return {
        ...state,
        saved: editedDepartment,
        saving: false,
        list: _.map(state.list, (Department) =>
          /* istanbul ignore next */
          editedDepartment.id === Department.id ? editedDepartment : Department
        )
      }
    }

    case types.DEPARTMENTS_EDIT_FAILURE:
      return {
        ...state,
        saved: null,
        saving: false
      }

    case types.DEPARTMENTS_GET_REQUEST:
      return {
        ...state,
        getting: true,
        get: undefined
      }

    case types.DEPARTMENTS_GET_SUCCESS:
      return {
        ...state,
        getting: false,
        get: action.payload
      }

    case types.DEPARTMENTS_GET_FAILURE:
      return {
        ...state,
        getting: false,
        get: null
      }

    case types.DEPARTMENTS_LIST_REQUEST:
      return {
        ...state,
        list: undefined,
        listing: true
      }

    case types.DEPARTMENTS_LIST_SUCCESS:
      return {
        ...state,
        list: /* istanbul ignore next */ action.payload ?? [],
        listing: false
      }

    case types.DEPARTMENTS_LIST_FAILURE:
      return {
        ...state,
        list: null,
        listing: false
      }

    case types.DEPARTMENTS_NEW_REQUEST:
      return {
        ...state,
        creating: true,
        created: undefined
      }

    case types.DEPARTMENTS_NEW_SUCCESS: {
      const newDepartment = action.payload
      return {
        ...state,
        created: newDepartment,
        creating: false,
        list: [.../* istanbul ignore next */ (state.list ?? [])].concat(newDepartment)
      }
    }

    case types.DEPARTMENTS_NEW_FAILURE:
      return {
        ...state,
        created: null,
        creating: false
      }

    default:
      return state
  }
}

export default departmentsReducer
