import { AlignEndColumn, Button, Row, VerticalSpace } from '@cegal/ds-components'
import { AddBox as AddIcon } from '@cegal/ds-icons/dist/AddBox'
import {
  deleteClientComment,
  editClientComment,
  listClientComments,
  newClientComment,
  resetClientComment
} from 'actions'
import { CLIENT_COMMENTS_LIST_FAILURE } from 'constants/actionTypes'
import { Client, ClientCommentModel } from 'declarations/models'
import { standardLogger } from 'metrics/loggers'
import { useAppDispatch, useAppSelector } from 'store'

import AlertPanel from 'components/AlertPanel/AlertPanel'
import ClientCommentModal from 'components/Clients/Client/Comments/ClientCommentModal'
import ClientCommentsCard from 'components/Clients/Client/Comments/ClientCommentsCard'
import { StretchLoader } from 'components/Loader/CustomLoader'
import { InnerCardContainer } from 'components/styled'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'

export interface CientCommentCardViewProps {
  client: Client
}

const ClientCommentsView = ({ client }: CientCommentCardViewProps) => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const [workingCopyClientComment, setWorkingCopyClientComment] = useState<ClientCommentModel | undefined>(
    undefined
  )

  const apiReady = useAppSelector((state) => state.app.apiReady)
  const clientComments = useAppSelector((state) => state.clientComments.list)
  const clientCommentsListing = useAppSelector((state) => state.clientComments.listing)
  const userId = useAppSelector((state) => state.app.userId)

  const initialComment: any = {
    body: '',
    client_id: client.id
  }

  const clientCommentsListArray = _.isEmpty(clientComments) ? [] : clientComments

  const loadPageData = () => {
    dispatch(listClientComments(client.id as number))
  }

  useEffect(() => {
    if (clientComments === undefined && apiReady && !clientCommentsListing) {
      loadPageData()
    }
  }, [apiReady])

  const onAddComment = () => {
    dispatch(resetClientComment())
    setWorkingCopyClientComment(initialComment)
  }

  const onEditComment = (comment: ClientCommentModel) => {
    dispatch(resetClientComment())
    setWorkingCopyClientComment(comment)
  }

  const onDeleteComment = (comment: ClientCommentModel) => {
    standardLogger('clients.view.comment.deleted')
    dispatch(deleteClientComment(comment!))
  }
  const onNewComment = (comment: ClientCommentModel) => {
    standardLogger('clients.view.comment.created')
    dispatch(newClientComment(comment))
  }

  const onSaveComment = (comment: ClientCommentModel) => {
    standardLogger('clients.view.comment.saved')
    dispatch(editClientComment(comment!))
  }

  const onCloseCommentModal = () => {
    setWorkingCopyClientComment(undefined)
  }

  return (
    <>
      <ClientCommentModal
        open={workingCopyClientComment !== undefined}
        comment={workingCopyClientComment}
        onClose={onCloseCommentModal}
        onNewComment={onNewComment}
        onSaveComment={onSaveComment}
      />
      <Row>
        <AlignEndColumn style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <Button
            aria-label='New comment'
            data-cy='newComment'
            variant='primary'
            size='small'
            icon={<AddIcon size='1.5rem' />}
            onClick={onAddComment}
          >
            {t('messages:add-a-new-comment')}
          </Button>
        </AlignEndColumn>
      </Row>
      <VerticalSpace />

      {clientComments === undefined && <StretchLoader />}

      {clientComments === null && (
        <AlertPanel
          doNotRenderIfHeadIsActiveWithError
          watchFor={[CLIENT_COMMENTS_LIST_FAILURE]}
          buttonText={t('buttons:click-to-reload-the-comments')!}
          reloadPage={loadPageData}
        />
      )}

      <InnerCardContainer>
        {clientCommentsListArray?.map((clientComment) => (
          <ClientCommentsCard
            size='small'
            fullWidth
            item={clientComment}
            onDelete={userId === clientComment.commenter?.id ? onDeleteComment : undefined}
            onEdit={userId === clientComment.commenter?.id ? onEditComment : undefined}
            key={clientComment.id}
            spacing
          />
        ))}
      </InnerCardContainer>
    </>
  )
}

export default ClientCommentsView
