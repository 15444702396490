import {
  Box,
  Button,
  Content,
  Heading,
  HorizontalSpace,
  PileDiv,
  TextField,
  VerticalSpace
} from '@cegal/ds-components'
import { signUp } from 'actions'
import FullCat from 'assets/images/fullCat.png'
import { APP_SIGNUP_NEW_SUCCESS } from 'constants/actionTypes'
import { eventLogger, standardLogger } from 'metrics/loggers'
import { AlertsState } from 'reducers/alerts'
import { useAppDispatch, useAppSelector } from 'store'

import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { NavLink, generatePath, useNavigate } from 'react-router-dom'

import _ from 'lodash'

const SignUp = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const alertBody = useAppSelector((state) => (state.alerts as AlertsState).body)
  const getting = useAppSelector((state) => state.app.signingUp)

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: undefined
  })

  const successAlert = _.find(alertBody, { type: APP_SIGNUP_NEW_SUCCESS })

  useEffect(() => {
    if (successAlert) {
      navigate('/login')
    }
  }, [successAlert])

  return (
    <Content style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box border background spacing style={{ maxWidth: '40vh' }}>
        <Box.Header>
          <Heading level='2' size='medium' style={{ textAlign: 'center' }}>
            {t('label:signUp-title')}
          </Heading>
        </Box.Header>
        <Box.Body>
          <VerticalSpace />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img alt='logo' style={{ height: '200px' }} src={FullCat} />
          </div>

          <VerticalSpace />
          <PileDiv>
            <TextField
              label={t('label:email-title') + '*'}
              description={t('messages:email-description')}
              {...register('email' as string, {
                required: t('validation:sign-up-no-email').toString(),
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: t('validation:email-not-match').toString()
                }
              })}
              error={errors?.email?.message?.toString()}
            />
          </PileDiv>
        </Box.Body>
        <Box.Footer style={{ textAlign: 'center' }}>
          <Button
            loading={getting}
            disabled={getting}
            onClick={handleSubmit((item) => {
              standardLogger('signup.request')
              dispatch(signUp(item.email))
              reset()
            })}
          >
            {t('buttons:send')}
          </Button>
          <HorizontalSpace />
          <Button
            variant='secondary'
            as={NavLink}
            data-amplitude='signup.cancel'
            to={generatePath('/')}
            onClick={(e: any) => {
              eventLogger(e)
            }}
          >
            {t('buttons:back-to-home')}
          </Button>
        </Box.Footer>
      </Box>
    </Content>
  )
}

export default SignUp
