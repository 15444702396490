var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import cl from 'clsx';
import React, { createContext, forwardRef } from 'react';
import Body from './Body';
import ColumnHeader from './ColumnHeader';
import DataCell from './DataCell';
import ExpandableRow from './ExpandableRow';
import Header from './Header';
import HeaderCell from './HeaderCell';
import Row from './Row';
export const TableContext = createContext(null);
export const Table = forwardRef((_a, ref) => {
    var { className, zebraStripes = false, size = 'medium', onSortChange, sort } = _a, rest = __rest(_a, ["className", "zebraStripes", "size", "onSortChange", "sort"]);
    return (React.createElement(TableContext.Provider, { value: { size, onSortChange, sort } },
        React.createElement("table", Object.assign({}, rest, { ref: ref, className: cl('cds-table', `cds-table--${size}`, {
                'cds-table--zebra-stripes': zebraStripes
            }, className) }))));
});
Table.Header = Header;
Table.Body = Body;
Table.Row = Row;
Table.ColumnHeader = ColumnHeader;
Table.HeaderCell = HeaderCell;
Table.DataCell = DataCell;
Table.ExpandableRow = ExpandableRow;
Table.displayName = 'Table';
export default Table;
