import { useEffect, useState } from 'react'

/**
 * Utility to read all headings with toc class
 * @param ref
 */
const useHeadings = (ref: any) => {
  const [headings, setHeadings] = useState([])

  useEffect(() => {
    if (ref?.current) {
      const elements: any = Array.from(
        ref.current!.querySelectorAll('h1.toc, h2.toc, h3.toc, h4.toc, h5.toc, h6.toc')
      )?.map((element: any) => ({
        id: element.id,
        text: /* istanbul ignore next */ element.textContent ?? '',
        level: Number(element.tagName.substring(1))
      }))
      setHeadings(elements)
    }
  }, [ref])
  return headings
}

export default useHeadings
