var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { NavigateBefore as Back } from '@cegal/ds-icons/dist/NavigateBefore';
import { NavigateNext as Next } from '@cegal/ds-icons/dist/NavigateNext';
import cl from 'clsx';
import { BodyShort } from 'components/typography';
import React, { forwardRef } from 'react';
import PaginationItem from './PaginationItem';
export const getSteps = ({ page, count, boundaryCount = 1, siblingCount = 1 }) => {
    var _a, _b;
    const range = (start, end) => Array.from({ length: end - start + 1 }, (_, i) => start + i);
    if (count <= (boundaryCount + siblingCount) * 2 + 3)
        return range(1, count);
    const startPages = range(1, boundaryCount);
    const endPages = range(count - boundaryCount + 1, count);
    const siblingsStart = Math.max(Math.min(page - siblingCount, count - boundaryCount - siblingCount * 2 - 1), boundaryCount + 2);
    const siblingsEnd = siblingsStart + siblingCount * 2;
    return [
        ...startPages,
        siblingsStart - ((_a = startPages[startPages.length - 1]) !== null && _a !== void 0 ? _a : 0) === 2 ? siblingsStart - 1 : 'ellipsis',
        ...range(siblingsStart, siblingsEnd),
        ((_b = endPages[0]) !== null && _b !== void 0 ? _b : count + 1) - siblingsEnd === 2 ? siblingsEnd + 1 : 'ellipsis',
        ...endPages
    ];
};
export const Pagination = forwardRef((_a, ref) => {
    var { page, onPageChange, count, boundaryCount = 1, siblingCount = 1, className, size = 'medium', prevNextTexts = false, renderItem: Item = (item) => React.createElement(PaginationItem, Object.assign({}, item)) } = _a, rest = __rest(_a, ["page", "onPageChange", "count", "boundaryCount", "siblingCount", "className", "size", "prevNextTexts", "renderItem"]);
    if (page < 1) {
        console.error('page cannot be less than 1');
        return null;
    }
    if (count < 1) {
        console.error('count cannot be less than 1');
        return null;
    }
    if (boundaryCount < 0) {
        console.error('boundaryCount cannot be less than 0');
        return null;
    }
    if (siblingCount < 0) {
        console.error('siblingCount cannot be less than 0');
        return null;
    }
    return (React.createElement("nav", Object.assign({}, rest, { ref: ref, className: cl('cds-pagination', `cds-pagination--${size}`, className) }),
        React.createElement("ul", { className: 'cds-pagination__list' },
            React.createElement("li", null,
                React.createElement(Item, { className: cl('cds-pagination__prev-next', {
                        'cds-pagination--invisible': page === 1
                    }), disabled: page === 1, onClick: () => onPageChange === null || onPageChange === void 0 ? void 0 : onPageChange(page - 1), page: page - 1, size: size, icon: React.createElement(Back, Object.assign({ size: '1.5rem', className: 'cds-pagination__prev-next-icon' }, (prevNextTexts ? { 'aria-hidden': true } : { title: 'Forrige' }))) }, prevNextTexts && (React.createElement(BodyShort, { size: size === 'xsmall' ? 'small' : size, className: 'cds-pagination__prev-text' }, "Forrige")))),
            getSteps({ page, count, siblingCount, boundaryCount }).map((step, i) => {
                const n = Number(step);
                return isNaN(n) ? (React.createElement("li", { className: 'cds-pagination__ellipsis', key: `${step}${i}` },
                    React.createElement(BodyShort, { size: size === 'xsmall' ? 'small' : size }, "..."))) : (React.createElement("li", { key: step },
                    React.createElement(Item, { onClick: () => onPageChange === null || onPageChange === void 0 ? void 0 : onPageChange(n), selected: page === n, page: n, size: size },
                        React.createElement(BodyShort, { size: size === 'xsmall' ? 'small' : size }, n))));
            }),
            React.createElement("li", null,
                React.createElement(Item, { className: cl('cds-pagination__prev-next', {
                        'cds-pagination--invisible': page === count
                    }), disabled: page === count, onClick: () => onPageChange === null || onPageChange === void 0 ? void 0 : onPageChange(page + 1), page: page + 1, size: size, icon: React.createElement(Next, Object.assign({ size: '1.5rem', className: 'cds-pagination__prev-next-icon' }, (prevNextTexts ? { 'aria-hidden': true } : { title: 'Neste' }))), iconPosition: 'right' }, prevNextTexts && (React.createElement(BodyShort, { size: size === 'xsmall' ? 'small' : size, className: 'cds-pagination__next-text' }, "Neste")))))));
});
Pagination.Item = PaginationItem;
Pagination.displayName = 'Pagination';
export default Pagination;
