import * as types from 'constants/actionTypes'
import * as urls from 'constants/urls'
import api from 'api'
import { LocationRequest, LocationResponse } from 'declarations/api'
import { Location } from 'declarations/models'
import { sprintf } from 'sprintf-js'

import _ from 'lodash'

export const deleteLocation = (location: LocationResponse) => {
  return api.call({
    url: sprintf(urls.LOCATIONS_DELETE_URL, { id: location.id }),
    method: 'DELETE',
    body: location,
    cascadeFailureError: true,
    type: {
      request: types.LOCATIONS_DELETE_REQUEST,
      success: types.LOCATIONS_DELETE_SUCCESS,
      failure: types.LOCATIONS_DELETE_FAILURE
    }
  })
}

export const editLocation = (location: LocationResponse) => {
  const payload: LocationRequest = {
    ..._.omit(location, ['id']),
    name: location.name
  }
  return api.call({
    url: sprintf(urls.LOCATIONS_EDIT_URL, { id: location.id }),
    method: 'PUT',
    body: payload,
    cascadeFailureError: true,
    type: {
      request: types.LOCATIONS_EDIT_REQUEST,
      success: types.LOCATIONS_EDIT_SUCCESS,
      failure: types.LOCATIONS_EDIT_FAILURE
    }
  })
}

export const getLocation = (id: number) => {
  return api.call({
    url: sprintf(urls.LOCATIONS_GET_URL, { id }),
    cascadeFailureError: true,
    type: {
      request: types.LOCATIONS_GET_REQUEST,
      success: types.LOCATIONS_GET_SUCCESS,
      failure: types.LOCATIONS_GET_FAILURE
    }
  })
}

export const listLocations = () => {
  return api.call({
    url: urls.LOCATIONS_LIST_URL,
    cascadeFailureError: true,
    type: {
      request: types.LOCATIONS_LIST_REQUEST,
      success: types.LOCATIONS_LIST_SUCCESS,
      failure: types.LOCATIONS_LIST_FAILURE
    }
  })
}

export const newLocation = (location: LocationRequest) => {
  return api.call({
    url: urls.LOCATIONS_NEW_URL,
    method: 'POST',
    body: location,
    cascadeFailureError: true,
    type: {
      request: types.LOCATIONS_NEW_REQUEST,
      success: types.LOCATIONS_NEW_SUCCESS,
      failure: types.LOCATIONS_NEW_FAILURE
    }
  })
}

export const setLocations = (locations: Array<Location> | undefined) => ({
  type: types.LOCATIONS_LIST_SET,
  payload: locations
})

export const setLocationsLoading = () => ({
  type: types.LOCATIONS_LIST_LOADING
})
