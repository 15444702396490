export enum ConsultantsFilterOptions {
  NONE = 'none',
  CUSTOM = 'custom',
  SKILL = 'skill'
}

export enum ConsultantsSortOptions {
  NONE = 'none',
  SKILL = 'skill',
  IDLE = 'idle',
  CLIENT = 'client',
  FOLLOWED = 'followed'
}
