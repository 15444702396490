import * as types from 'constants/actionTypes'
import { Consultant } from 'declarations/models'
import { AnyAction } from 'redux'

import _ from 'lodash'

export interface FollowedConsultantsState {
  creating: Array<Consultant>
  // to identify which consultant is being followed / unfollowed
  deleting: Array<Consultant>
  get: Consultant | null | undefined
  getting: boolean
  list: Array<Consultant> | null | undefined
  listing: boolean
}

export const initialFollowedConsultantsState: FollowedConsultantsState = {
  creating: [],
  deleting: [],
  get: undefined,
  getting: false,
  list: undefined,
  listing: false
}

const followedConsultantsReducer = (
  /* istanbul ignore next */
  state: FollowedConsultantsState = initialFollowedConsultantsState,
  action: AnyAction
): FollowedConsultantsState => {
  switch (action.type) {
    case types.FOLLOWED_CONSULTANTS_DELETE_REQUEST:
      return {
        ...state,
        deleting: _.cloneDeep(state.deleting).concat(action.context)
      }

    case types.FOLLOWED_CONSULTANTS_DELETE_SUCCESS: {
      const deletedClient = action.context
      return {
        ...state,
        deleting: _.reject(state.deleting, { id: deletedClient.id }),
        list: _.reject(state.list, { id: deletedClient.id })
      }
    }

    case types.FOLLOWED_CONSULTANTS_DELETE_FAILURE:
      return {
        ...state,
        deleting: _.reject(state.deleting, { id: action.context.id })
      }

    case types.FOLLOWED_CONSULTANTS_GET_REQUEST:
      return {
        ...state,
        getting: true,
        get: undefined
      }

    case types.FOLLOWED_CONSULTANTS_GET_SUCCESS:
      return {
        ...state,
        getting: false,
        get: action.payload
      }

    case types.FOLLOWED_CONSULTANTS_GET_FAILURE:
      return {
        ...state,
        getting: false,
        get: null
      }

    case types.FOLLOWED_CONSULTANTS_LIST_REQUEST:
      return {
        ...state,
        list: undefined,
        listing: true
      }

    case types.FOLLOWED_CONSULTANTS_LIST_SUCCESS: {
      const payload = _.isEmpty(action.payload) ? [] : action.payload
      return {
        ...state,
        list: payload,
        listing: false
      }
    }

    case types.FOLLOWED_CONSULTANTS_LIST_FAILURE:
      return {
        ...state,
        list: null,
        listing: false
      }

    case types.FOLLOWED_CONSULTANTS_NEW_REQUEST:
      return {
        ...state,
        creating: _.cloneDeep(state.creating).concat(action.context)
      }

    case types.FOLLOWED_CONSULTANTS_NEW_SUCCESS: {
      const newConsultant = action.context
      return {
        ...state,
        creating: _.reject(state.creating, { id: newConsultant.id }),
        list: [.../* istanbul ignore next */ (state.list ?? [])].concat(newConsultant)
      }
    }

    case types.FOLLOWED_CONSULTANTS_NEW_FAILURE:
      return {
        ...state,
        creating: _.reject(state.creating, { id: action.context.id })
      }

    case types.FOLLOWED_CONSULTANTS_SET:
      return {
        ...state,
        get: action.payload
      }

    default:
      return state
  }
}

export default followedConsultantsReducer
