import fetch from 'cross-fetch';
import 'cross-fetch/polyfill';
import * as types from 'fetch/actionTypes';
import env, { IS_CHAOS, IS_DEVELOPMENT, IS_PRODUCTION, IS_TEST, RUNNING_IN_BROWSER } from 'fetch/environment';
import { HOST } from 'fetch/urls';
/* I want to use Error, because it has stacktraces */
class ApiError extends Error {
    constructor(message, url) {
        // 'Error' breaks prototype chain here
        super(message);
        this.url = url;
        const actualProto = new.target.prototype;
        Object.setPrototypeOf(this, actualProto);
    }
}
export class Call {
    constructor(options = {}) {
        var _a;
        this.headers = {};
        this.expectedResponses = { 200: 1 };
        this.headers = (_a = options.headers) !== null && _a !== void 0 ? _a : {};
        if (IS_CHAOS) {
            this.expectedResponses = { 404: 1, 500: 1 };
        }
    }
    setHeaders(headers = {}) {
        this.headers = headers;
    }
    handleResponse(requestType, url) {
        return (response) => {
            if (response) {
                if (response.status >= 400) {
                    const apiError = new ApiError(response.statusText, url);
                    apiError.response = response;
                    // 404s may have body, let's process it before throwing final error
                    return response
                        .json()
                        .then((payload) => {
                        apiError.payload = payload;
                        throw apiError;
                    })
                        .catch(() => {
                        return Promise.reject(apiError);
                    });
                }
                if (response.status >= 300) {
                    return Promise.resolve({
                        status: response.status,
                        headers: response === null || response === void 0 ? void 0 : response.headers
                    });
                }
                const handler = (payload = {}) => {
                    return Promise.resolve({
                        status: response === null || response === void 0 ? void 0 : response.status,
                        headers: response === null || response === void 0 ? void 0 : response.headers,
                        payload: Object.getPrototypeOf(payload).name === 'SyntaxError' ? {} : payload
                    });
                };
                if (requestType === 'json') {
                    return response.json().then(handler).catch(handler); // when body is empty for 200, response.json() fails with a SyntaxError error
                }
                return response.blob().then(handler);
            }
        };
    }
    handleSuccessResponse(dispatch, type, body, context) {
        return (response) => {
            var _a, _b, _c;
            console.debug('handleSuccessResponse', response);
            const headers = response && response.headers && response.headers.entries
                ? Object.fromEntries((_b = (_a = response === null || response === void 0 ? void 0 : response.headers) === null || _a === void 0 ? void 0 : _a.entries) === null || _b === void 0 ? void 0 : _b.call(_a))
                : {};
            const action = {
                context,
                headers,
                originalPayload: body,
                status: response === null || response === void 0 ? void 0 : response.status
            };
            if ((response === null || response === void 0 ? void 0 : response.status) < 300) {
                return dispatch(Object.assign(Object.assign({}, action), { type: type.success, payload: response === null || response === void 0 ? void 0 : response.payload }));
            }
            return dispatch(Object.assign(Object.assign({}, action), { type: (_c = type.redirect) !== null && _c !== void 0 ? _c : types.API_CALL_REDIRECT }));
        };
    }
    handleFailureResponse(dispatch, type, body, context, cascadeFailureError) {
        return (error) => {
            var _a, _b, _c, _d;
            console.debug('handleFailureResponse', error);
            let failureHandled = false;
            const headers = Object.fromEntries((_c = (_b = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.headers) === null || _b === void 0 ? void 0 : _b.entries()) !== null && _c !== void 0 ? _c : []);
            const action = {
                context,
                error: {
                    url: error === null || error === void 0 ? void 0 : error.url,
                    message: error === null || error === void 0 ? void 0 : error.message,
                    stack: error === null || error === void 0 ? void 0 : error.stack
                },
                headers,
                payload: error.payload,
                originalPayload: body,
                status: (_d = error === null || error === void 0 ? void 0 : error.response) === null || _d === void 0 ? void 0 : _d.status
            };
            if (action.status === 401) {
                dispatch(Object.assign(Object.assign({}, action), { type: (type.unauthorized || type.failure) }));
                failureHandled = true;
            }
            if (action.status === 403) {
                dispatch(Object.assign(Object.assign({}, action), { type: (type.forbidden || type.failure) }));
                failureHandled = true;
            }
            if (action.status >= 500) {
                dispatch(Object.assign(Object.assign({}, action), { type: type.serverInternalError || types.SERVER_INTERNAL_ERROR }));
                failureHandled = true;
            }
            if (!failureHandled || (failureHandled && action.status >= 500 && cascadeFailureError)) {
                dispatch(Object.assign(Object.assign({}, action), { type: type.failure }));
            }
        };
    }
    /*
      if I get expectedResponses = {200: 1, 404: 1, 500: 1}, that means I have 33% probability
      of choosing one of each. So, I will sum the values (1+1+1 = 3), then I will generate a random
      number between 0 and 3.
  
      200 will be [0-1[, 404 [1-2[, 500 [2-3[. Those are the targets.
     */
    pickOneHttpCode(expectedResponses) {
        const httpCodeKeys = Object.keys(expectedResponses).sort((a, b) => parseInt(b) - parseInt(a));
        let total = 0;
        const targets = {};
        httpCodeKeys.forEach((key) => {
            const delta = expectedResponses[key];
            targets[key] = { low: total, high: total + delta };
            total += delta;
        });
        const randomValue = Math.random() * total;
        let pickedValue = 0;
        Object.keys(targets).forEach((key) => {
            if (targets[key].low <= randomValue && randomValue < targets[key].high) {
                pickedValue = parseInt(key);
            }
        });
        return pickedValue;
    }
    fakeCall({ body, context, cascadeFailureError = false, expectedPayload, expectedResponses, maxDelayTime = 2000, method = 'GET', payload, type, url }) {
        const fakeCall = (dispatch) => {
            console.debug('FAKE API REQUEST FOR ' + method + ' ' + url);
            if (type.request) {
                dispatch({
                    type: type.request,
                    context
                });
            }
            const originalBody = body || payload;
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    const httpCode = this.pickOneHttpCode(expectedResponses !== null && expectedResponses !== void 0 ? expectedResponses : this.expectedResponses);
                    if (httpCode < 400) {
                        const payload = expectedPayload instanceof Function ? expectedPayload() : expectedPayload;
                        console.log('FAKE API SUCCESS ' + httpCode + ' FOR ' + method + ' ' + url);
                        console.log('payload', payload);
                        resolve({
                            status: httpCode,
                            payload,
                            headers: {}
                        });
                    }
                    else {
                        console.log('FAKE API FAILURE ' + httpCode + ' FOR ' + method + ' ' + url);
                        const apiError = new ApiError('Generated ' + httpCode + ' failure error in fetch-api for ' + url);
                        apiError.response = {
                            status: httpCode
                        };
                        reject(apiError);
                    }
                }, Math.floor(Math.random() * maxDelayTime));
            })
                .then(this.handleSuccessResponse(dispatch, type, originalBody, context))
                .catch(this.handleFailureResponse(dispatch, type, originalBody, context, cascadeFailureError));
        };
        return fakeCall;
    }
    realCall({ body = undefined, context, cascadeFailureError = false, headers, method = 'GET', responseType = 'json', redirect = 'follow', payload, type, url, useTimestamp = false }) {
        const realCall = (dispatch) => {
            var _a, _b, _c, _d;
            const options = {
                method,
                redirect,
                headers: Object.assign(Object.assign(Object.assign({}, this.headers), headers), { Accept: responseType === 'pdf' ? 'application/pdf' : 'application/json; charset=utf-8' })
            };
            const originalBody = body || payload;
            const _body = originalBody ? JSON.stringify(originalBody) : undefined;
            /* istanbul ignore next */
            let _url = url;
            if (useTimestamp) {
                const now = new Date().getTime();
                _url = url.match(/\?./) ? url + '&ts=' + now : url + '?ts=' + now;
            }
            if (method !== 'GET' && method !== 'HEAD') {
                options.headers['Content-Type'] = 'application/json; charset=utf-8';
                options.body = _body;
            }
            if (type === null || type === void 0 ? void 0 : type.request) {
                dispatch({
                    type: type.request,
                    context
                });
            }
            return (_d = (_c = (_b = (_a = fetch(_url, options)) === null || _a === void 0 ? void 0 : _a.catch(
            /* istanbul ignore next */ (error) => {
                console.error(error);
                return Promise.reject(error);
            })) === null || _b === void 0 ? void 0 : _b.then(this.handleResponse(responseType, _url))) === null || _c === void 0 ? void 0 : _c.then(this.handleSuccessResponse(dispatch, type, originalBody, context))) === null || _d === void 0 ? void 0 : _d.catch(this.handleFailureResponse(dispatch, type, originalBody, context, cascadeFailureError));
        };
        return realCall;
    }
    call(props) {
        var _a;
        if ((IS_DEVELOPMENT || (HOST === 'localhost' && RUNNING_IN_BROWSER)) && !props.skipFake) {
            if (!IS_TEST && !IS_PRODUCTION) {
                console.debug('using fakeCall, because ISDEVELOPMENT=' +
                    IS_DEVELOPMENT +
                    ' HOST=' +
                    HOST +
                    ' RUNNING_IN_BROWSER=' +
                    RUNNING_IN_BROWSER +
                    ' skipFake=' +
                    props.skipFake);
            }
            return this.fakeCall(props);
        }
        if (IS_CHAOS) {
            const useRealCall = Math.floor(Math.random() * 1000) % 2 === 0;
            if (!useRealCall) {
                console.debug('using fakeCall, because ISCHAOS=' + IS_CHAOS + ' useRealCall=' + useRealCall);
                return this.fakeCall(Object.assign({ expectedResponses: this.expectedResponses }, props));
            }
        }
        if (!IS_TEST && !IS_PRODUCTION) {
            console.debug('using realCall in a ' + ((_a = env === null || env === void 0 ? void 0 : env.default) !== null && _a !== void 0 ? _a : 'unknown') + ' env');
        }
        return this.realCall(props);
    }
}
