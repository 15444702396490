var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Close } from '@cegal/ds-icons/dist/Close';
import { Search as SearchIcon } from '@cegal/ds-icons/dist/Search';
import cl from 'clsx';
import { BodyShort, ErrorMessage, Label } from 'components/typography';
import React, { forwardRef, useCallback, useState } from 'react';
import { omit, useEventListener } from 'util/index';
import { useFormField } from 'components/form/useFormField';
import SearchButton from './SearchButton';
export const SearchContext = React.createContext(null);
export const Search = forwardRef((props, ref) => {
    const { inputProps, size = 'medium', inputDescriptionId, errorId, showErrorMsg, hasError } = useFormField(props, 'searchfield');
    const { className, hideLabel = true, label, description, value, clearButtonLabel, onClear, onSubmit, clearButton = true, children, variant = 'secondary', defaultValue, onChange } = props, rest = __rest(props, ["className", "hideLabel", "label", "description", "value", "clearButtonLabel", "onClear", "onSubmit", "clearButton", "children", "variant", "defaultValue", "onChange"]);
    const [wrapperRef, setWrapperRef] = useState(null);
    const [internalValue, setInternalValue] = useState(defaultValue !== null && defaultValue !== void 0 ? defaultValue : '');
    const handleChange = useCallback((v) => {
        value === undefined && setInternalValue(v);
        onChange === null || onChange === void 0 ? void 0 : onChange(v);
    }, [onChange, value]);
    const handleClear = useCallback((event) => {
        var _a, _b;
        onClear === null || onClear === void 0 ? void 0 : onClear(event);
        handleChange('');
        if (!!ref && !!ref.current) {
            ;
            (_b = (_a = ref.current).focus) === null || _b === void 0 ? void 0 : _b.call(_a);
        }
    }, [ref, handleChange, onClear]);
    useEventListener('keydown', useCallback((e) => {
        if (e.key === 'Escape') {
            e.preventDefault();
            handleClear({ trigger: 'Escape', event: e });
        }
        if (e.key === 'Enter') {
            e.preventDefault();
            onSubmit === null || onSubmit === void 0 ? void 0 : onSubmit(internalValue.toString());
        }
    }, [handleClear, internalValue, onSubmit]), wrapperRef);
    return (React.createElement("div", { ref: setWrapperRef, className: cl('cds-form-field', `cds-form-field--${size}`, 'cds-search', {
            'cds-search--error': hasError,
            'cds-search--disabled': !!inputProps.disabled
        }, className) },
        React.createElement(Label, { htmlFor: inputProps.id, size: size, as: 'label', className: cl('cds-form-field__label', {
                'cds-sr-only': hideLabel
            }) }, label),
        !!description && (React.createElement(BodyShort, { as: 'div', className: cl('cds-form-field__description', {
                'cds-sr-only': hideLabel
            }), id: inputDescriptionId, size: size }, description)),
        React.createElement("div", { className: 'cds-search__wrapper' },
            React.createElement("div", { className: 'cds-search__wrapper-inner' },
                variant === 'simple' && (React.createElement(SearchIcon, { size: '1.3rem', "aria-hidden": true, className: 'cds-search__search-icon' })),
                React.createElement("input", Object.assign({ ref: ref }, omit(rest, ['error', 'errorId', 'size']), inputProps, { value: value !== null && value !== void 0 ? value : internalValue, onChange: (e) => handleChange(e.target.value), type: 'search', role: 'searchbox', className: cl('cds-search__input', `cds-search__input--${variant}`, 'cds-text-field__input', 'cds-body-short', `cds-body-${size}`, className) })),
                (value !== null && value !== void 0 ? value : internalValue) && clearButton && (React.createElement("button", { type: 'button', onClick: (e) => handleClear({ trigger: 'Click', event: e }), className: 'cds-search__button-clear' },
                    React.createElement("span", { className: 'cds-sr-only' }, clearButtonLabel || 'Tøm'),
                    React.createElement(Close, { "aria-hidden": true })))),
            React.createElement(SearchContext.Provider, { value: {
                    onSubmit: () => onSubmit === null || onSubmit === void 0 ? void 0 : onSubmit(internalValue.toString()),
                    size,
                    disabled: inputProps.disabled,
                    variant
                } }, (children || variant !== 'simple') && React.createElement(SearchButton, null))),
        React.createElement("div", { className: 'cds-form-field__error', id: errorId, "aria-relevant": 'additions removals', "aria-live": 'polite' }, showErrorMsg && React.createElement(ErrorMessage, { size: size }, props.error))));
});
Search.Button = SearchButton;
Search.displayName = 'Search';
export default Search;
