import * as types from 'constants/actionTypes'
import { EngagementResponse } from 'declarations/api'
import { EngagementVacancy, EngagementVacancyConsultant } from 'declarations/models'
import { AnyAction } from 'redux'

export interface EngagementState {
  /** result of POST engagements/new */
  created: EngagementResponse | null | undefined
  /** POST engagements/new loader */
  creating: boolean
  /** result of DELETE engagements/{id}/delete  */
  deleted: EngagementResponse | null | undefined
  /** DELETE engagements/{id}/delete loader */
  deleting: boolean
  /** result for GET /engagement/{id}/edit, also temp for PUT /engagement/{id}/delete, DELETE /engagement/{id}/delete */
  get: EngagementResponse | null | undefined
  /** GET /engagement/{id}/edit loader */
  getting: boolean
  /** result of GET /engagements */
  list: Array<EngagementResponse> | null | undefined
  /** GET /engagements loader */
  listing: boolean
  /** result of PUT engagements/{id}/edit */
  saved: EngagementResponse | null | undefined
  /** PUT engagements/{id}/edit loader */
  saving: boolean

  /** result of POST engagements/{id}/vacancies/new */
  createdVacancy: EngagementVacancy | null | undefined
  /** POST engagements/{id}/vacancies/new loader */
  creatingVacancy: boolean
  /** result of DELETE engagements/{id}/vacancies/{id}/delete */
  deletedVacancy: EngagementVacancy | null | undefined
  /** DELETE engagements/{id}/vacancies/{id}/delete loader */
  deletingVacancy: boolean
  /** result of PUT engagements/{id}/vacancies/{id}/edit */
  savedVacancy: EngagementVacancy | null | undefined
  /** PUT engagements/{id}/vacancies/{id}/edit loader */
  savingVacancy: boolean
  /** vacancy ID being edited or deleted */
  currentVacancyId: number | undefined

  /** result of POST engagements/vacancies/{id}/consultants/new */
  createdVacancyConsultant: (EngagementVacancyConsultant & { vacancy_id: number }) | null | undefined
  /** POST engagements/vacancies/{id}/consultants/new loader */
  creatingVacancyConsultant: boolean
  /** result of DELETE engagements/vacancies/{id}/consultants/{id}/delete */
  deletedVacancyConsultant: (EngagementVacancyConsultant & { vacancy_id: number }) | null | undefined
  /** DELETE engagements/vacancies/{id}/consultants/{id}/delete loader */
  deletingVacancyConsultant: boolean
  /** result of PUT engagements/vacancies/{id}/consultants/{id}/edit */
  savedVacancyConsultant: (EngagementVacancyConsultant & { vacancy_id: number }) | null | undefined
  /** PUT engagements/vacancies/{id}/consultants/{id}/edit loader */
  savingVacancyConsultant: boolean
  /** vacancy consultant ID being edited or deleted */
  currentVacancyConsultantId:
    | {
        vacancy_id: number
        id: number
      }
    | undefined
}

export const initialEngagementsState: EngagementState = {
  created: undefined,
  createdVacancy: undefined,
  createdVacancyConsultant: undefined,
  creating: false,
  creatingVacancy: false,
  creatingVacancyConsultant: false,
  currentVacancyId: undefined,
  currentVacancyConsultantId: undefined,
  get: undefined,
  deleting: false,
  deletingVacancy: false,
  deletingVacancyConsultant: false,
  deleted: undefined,
  deletedVacancy: undefined,
  deletedVacancyConsultant: undefined,
  list: undefined,
  getting: false,
  listing: false,
  saved: undefined,
  savedVacancy: undefined,
  savedVacancyConsultant: undefined,
  saving: false,
  savingVacancy: false,
  savingVacancyConsultant: false
}

const engagementsReducer = (
  /* istanbul ignore next */
  state: EngagementState = initialEngagementsState,
  action: AnyAction
): EngagementState => {
  switch (action.type) {
    case types.ENGAGEMENTS_CREATED_MOVE:
      return {
        ...state,
        created: undefined,
        get: state.created
      }

    case types.ENGAGEMENTS_SAVED_MOVE:
      return {
        ...state,
        saved: undefined,
        get: state.saved
      }

    case types.ENGAGEMENTS_DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
        deleted: undefined
      }

    case types.ENGAGEMENTS_DELETE_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleted: action.context.engagement,
        get: undefined,
        list: /* istanbul ignore next */ (state.list ?? []).filter(
          (engagement) => engagement.id !== action.context.engagement.id
        )
      }

    case types.ENGAGEMENTS_DELETE_FAILURE:
      return {
        ...state,
        deleting: false,
        deleted: null
      }

    case types.ENGAGEMENTS_EDIT_REQUEST:
      return {
        ...state,
        saving: true,
        saved: undefined
      }

    case types.ENGAGEMENTS_EDIT_SUCCESS: {
      const savedEngagement: EngagementResponse = action.payload
      return {
        ...state,
        saving: false,
        list: /* istanbul ignore next */ (state.list ?? []).map((engagement) =>
          /* istanbul ignore next */
          savedEngagement.id === engagement.id ? savedEngagement : engagement
        ),
        saved: savedEngagement
      }
    }

    case types.ENGAGEMENTS_EDIT_FAILURE:
      return {
        ...state,
        saving: false,
        saved: null
      }

    case types.ENGAGEMENTS_ENGAGEMENT_SET:
      return {
        ...state,
        get: action.payload
      }

    case types.ENGAGEMENTS_GET_REQUEST:
      return {
        ...state,
        getting: true,
        get: undefined
      }

    case types.ENGAGEMENTS_GET_SUCCESS:
      return {
        ...state,
        getting: false,
        get: action.payload
      }

    case types.ENGAGEMENTS_GET_FAILURE:
      return {
        ...state,
        getting: false,
        get: null
      }

    case types.ENGAGEMENTS_LIST_REQUEST:
      return {
        ...state,
        listing: true,
        list: undefined
      }

    case types.ENGAGEMENTS_LIST_SUCCESS:
      return {
        ...state,
        listing: false,
        // TODO: Remove when endpoint starts supporting certificates
        list: action.payload
      }

    case types.ENGAGEMENTS_LIST_FAILURE:
      return {
        ...state,
        listing: false,
        list: null
      }

    case types.ENGAGEMENTS_NEW_REQUEST:
      return {
        ...state,
        creating: true,
        created: undefined
      }

    case types.ENGAGEMENTS_NEW_SUCCESS: {
      const createdEngagement: EngagementResponse = action.payload
      return {
        ...state,
        creating: false,
        created: createdEngagement,
        list: /* istanbul ignore next */ (state.list ?? []).concat(createdEngagement)
      }
    }

    case types.ENGAGEMENTS_NEW_FAILURE:
      return {
        ...state,
        creating: false,
        created: null
      }

    case types.ENGAGEMENTS_VACANCIES_DELETE_REQUEST:
      return {
        ...state,
        deletingVacancy: true,
        deletedVacancy: undefined,
        currentVacancyId: action.context.vacancy.id
      }

    case types.ENGAGEMENTS_VACANCIES_DELETE_SUCCESS:
      return {
        ...state,
        deletingVacancy: false,
        deletedVacancy: action.context.vacancy,
        currentVacancyId: undefined,
        get: /* istanbul ignore next */ state.get
          ? {
              ...state.get,
              vacancies: state.get.vacancies.filter((vacancy) => vacancy.id !== action.context.vacancy.id)
            }
          : undefined,
        list: /* istanbul ignore next */ (state.list ?? []).map((engagement) =>
          /* istanbul ignore next */
          action.context.engagement_id === engagement.id
            ? {
                ...engagement,
                vacancies: engagement.vacancies.filter((vacancy) => vacancy.id !== action.context.vacancy.id)
              }
            : engagement
        )
      }

    case types.ENGAGEMENTS_VACANCIES_DELETE_FAILURE:
      return {
        ...state,
        currentVacancyId: undefined,
        deletingVacancy: false,
        deletedVacancy: null
      }

    case types.ENGAGEMENTS_VACANCIES_EDIT_REQUEST:
      return {
        ...state,
        savingVacancy: true,
        savedVacancy: undefined,
        currentVacancyId: action.context.vacancy.id
      }

    case types.ENGAGEMENTS_VACANCIES_EDIT_SUCCESS: {
      const savedVacancy: EngagementVacancy = action.payload
      return {
        ...state,
        savingVacancy: false,
        savedVacancy,
        currentVacancyId: undefined,
        get: /* istanbul ignore next */ state.get
          ? {
              ...state.get,
              vacancies: state.get.vacancies?.map((vacancy) =>
                vacancy.id === savedVacancy.id ? savedVacancy : vacancy
              )
            }
          : undefined,
        list: /* istanbul ignore next */ (state.list ?? []).map((engagement) =>
          /* istanbul ignore next */
          action.context.engagement_id === engagement.id
            ? {
                ...engagement,
                vacancies: engagement.vacancies?.map((vacancy) =>
                  vacancy.id === savedVacancy.id ? savedVacancy : vacancy
                )
              }
            : engagement
        )
      }
    }

    case types.ENGAGEMENTS_VACANCIES_EDIT_FAILURE:
      return {
        ...state,
        currentVacancyId: undefined,
        savingVacancy: false,
        savedVacancy: null
      }

    case types.ENGAGEMENTS_VACANCIES_NEW_REQUEST:
      return {
        ...state,
        creatingVacancy: true,
        createdVacancy: undefined
      }

    case types.ENGAGEMENTS_VACANCIES_NEW_SUCCESS: {
      const createdVacancy: EngagementVacancy = action.payload

      return {
        ...state,
        creatingVacancy: false,
        createdVacancy,
        get: /* istanbul ignore next */ state.get
          ? {
              ...state.get,
              vacancies: /* istanbul ignore next */ (state.get.vacancies ?? []).concat(createdVacancy)
            }
          : undefined,
        list: /* istanbul ignore next */ (state.list ?? []).map((engagement) =>
          /* istanbul ignore next */
          action.context.engagement_id === engagement.id
            ? {
                ...engagement,
                vacancies: /* istanbul ignore next */ (engagement.vacancies ?? []).concat(createdVacancy)
              }
            : engagement
        )
      }
    }

    case types.ENGAGEMENTS_VACANCIES_NEW_FAILURE:
      return {
        ...state,
        creatingVacancy: false,
        createdVacancy: null
      }

    case types.ENGAGEMENTS_VACANCIES_CONSULTANTS_DELETE_REQUEST:
      return {
        ...state,
        currentVacancyConsultantId: {
          vacancy_id: action.context.vacancy_id,
          id: action.context.evc.consultant.id
        },
        deletingVacancyConsultant: true,
        deletedVacancyConsultant: undefined
      }

    case types.ENGAGEMENTS_VACANCIES_CONSULTANTS_DELETE_SUCCESS:
      return {
        ...state,
        deletingVacancyConsultant: false,
        deletedVacancyConsultant: {
          ...action.context.evc,
          vacancy_id: action.context.vacancy_id
        },
        currentVacancyConsultantId: undefined,
        get: /* istanbul ignore next */ state.get
          ? {
              ...state.get,
              vacancies: state.get.vacancies?.map((vacancy) =>
                vacancy.id === action.context.vacancy_id
                  ? {
                      ...vacancy,
                      consultants: vacancy.consultants!.filter(
                        (evc) => evc.consultant?.id !== action.context.evc.consultant.id
                      )
                    }
                  : vacancy
              )
            }
          : undefined,
        list: /* istanbul ignore next */ (state.list ?? []).map((engagement) => ({
          ...engagement,
          vacancies: engagement.vacancies?.map((vacancy) =>
            vacancy.id === action.context.vacancy_id
              ? {
                  ...vacancy,
                  consultants: vacancy.consultants!.filter(
                    (evc) => evc.consultant?.id !== action.context.evc.consultant.id
                  )
                }
              : vacancy
          )
        }))
      }

    case types.ENGAGEMENTS_VACANCIES_CONSULTANTS_DELETE_FAILURE:
      return {
        ...state,
        currentVacancyConsultantId: undefined,
        deletingVacancyConsultant: false,
        deletedVacancyConsultant: null
      }

    case types.ENGAGEMENTS_VACANCIES_CONSULTANTS_EDIT_REQUEST:
      return {
        ...state,
        currentVacancyConsultantId: action.context,
        savingVacancyConsultant: true,
        savedVacancyConsultant: undefined
      }

    case types.ENGAGEMENTS_VACANCIES_CONSULTANTS_EDIT_SUCCESS: {
      const savedVacancyConsultant: EngagementVacancyConsultant = action.payload
      return {
        ...state,
        savingVacancyConsultant: false,
        savedVacancyConsultant: {
          ...savedVacancyConsultant,
          vacancy_id: action.context.vacancy_id
        },
        currentVacancyConsultantId: undefined,
        get: /* istanbul ignore next */ state.get
          ? {
              ...state.get,
              vacancies: state.get.vacancies?.map((vacancy) =>
                vacancy.id === action.context.vacancy_id
                  ? {
                      ...vacancy,
                      consultants: vacancy.consultants?.map((evc) =>
                        evc.consultant?.id === action.context.id ? savedVacancyConsultant : evc
                      )
                    }
                  : vacancy
              )
            }
          : undefined,
        list: /* istanbul ignore next */ (state.list ?? []).map((engagement) => ({
          ...engagement,
          vacancies: engagement.vacancies?.map((vacancy) =>
            vacancy.id === action.context.vacancy_id
              ? {
                  ...vacancy,
                  consultants: vacancy.consultants?.map((evc) =>
                    evc.consultant?.id === action.context.id ? savedVacancyConsultant : evc
                  )
                }
              : vacancy
          )
        }))
      }
    }

    case types.ENGAGEMENTS_VACANCIES_CONSULTANTS_EDIT_FAILURE:
      return {
        ...state,
        currentVacancyConsultantId: undefined,
        savingVacancyConsultant: false,
        savedVacancyConsultant: null
      }

    case types.ENGAGEMENTS_VACANCIES_CONSULTANTS_NEW_REQUEST:
      return {
        ...state,
        creatingVacancyConsultant: true,
        createdVacancyConsultant: undefined
      }

    case types.ENGAGEMENTS_VACANCIES_CONSULTANTS_NEW_SUCCESS: {
      const createdVacancyConsultant: EngagementVacancyConsultant = action.payload
      return {
        ...state,
        creatingVacancyConsultant: false,
        createdVacancyConsultant: {
          ...createdVacancyConsultant,
          vacancy_id: action.context.vacancy_id
        },
        get: /* istanbul ignore next */ state.get
          ? {
              ...state.get,
              vacancies: state.get.vacancies?.map((vacancy) =>
                vacancy.id === action.context.vacancy_id
                  ? {
                      ...vacancy,
                      consultants: vacancy.consultants?.concat(createdVacancyConsultant)
                    }
                  : vacancy
              )
            }
          : undefined,
        list: /* istanbul ignore next */ (state.list ?? []).map((engagement) => ({
          ...engagement,
          vacancies: engagement.vacancies?.map((vacancy) =>
            vacancy.id === action.context.vacancy_id
              ? {
                  ...vacancy,
                  consultants: vacancy.consultants?.concat(createdVacancyConsultant)
                }
              : vacancy
          )
        }))
      }
    }
    case types.ENGAGEMENTS_VACANCIES_CONSULTANTS_NEW_FAILURE:
      return {
        ...state,
        creatingVacancyConsultant: false,
        createdVacancyConsultant: null
      }

    case types.ENGAGEMENTS_RESET:
      return {
        ...state,
        created: undefined,
        saved: undefined,
        deleted: undefined,
        get: undefined
      }

    case types.ENGAGEMENTS_VACANCIES_RESET:
      return {
        ...state,
        createdVacancy: undefined,
        savedVacancy: undefined,
        deletedVacancy: undefined,
        currentVacancyId: undefined
      }

    case types.ENGAGEMENTS_VACANCIES_CONSULTANTS_RESET:
      return {
        ...state,
        createdVacancyConsultant: undefined,
        savedVacancyConsultant: undefined,
        deletedVacancyConsultant: undefined,
        currentVacancyConsultantId: undefined
      }

    case types.MATCHES_NEW_SUCCESS: {
      return {
        ...state,
        get: /* istanbul ignore next */ state.get
          ? {
              ...state.get,
              vacancies: state.get!.vacancies?.map((vacancy) =>
                vacancy.id === action.payload.engagement_vacancy_id
                  ? {
                      ...vacancy,
                      consultants: vacancy.consultants?.concat(action.payload)
                    }
                  : vacancy
              )
            }
          : undefined,
        list: state.list?.map((engagement) => ({
          ...engagement,
          vacancies: engagement.vacancies?.map((vacancy) =>
            vacancy.id === action.payload.engagement_vacancy_id
              ? {
                  ...vacancy,
                  consultants: vacancy.consultants?.concat(action.payload)
                }
              : vacancy
          )
        }))
      }
    }

    /* we do not have a payload, so we have to get info from context */
    case types.MATCHES_DELETE_SUCCESS:
      return {
        ...state,
        get: /* istanbul ignore next */ state.get
          ? {
              ...state.get,
              vacancies: state.get.vacancies?.map((vacancy) =>
                vacancy.id === action.context.engagement_vacancy.id
                  ? {
                      ...vacancy,
                      consultants: vacancy.consultants?.filter(
                        (evc) => evc.consultant?.id !== action.context.consultant.id
                      )
                    }
                  : vacancy
              )
            }
          : undefined,
        list: state.list?.map((engagement) => ({
          ...engagement,
          vacancies: engagement.vacancies?.map((vacancy) =>
            vacancy.id === action.context.engagement_vacancy.id
              ? {
                  ...vacancy,
                  consultants: vacancy.consultants?.filter(
                    /* istanbul ignore next */
                    (evc) => evc.consultant?.id !== action.context.consultant.id
                  )
                }
              : vacancy
          )
        }))
      }
    default:
      return state
  }
}

export default engagementsReducer
