import { Checkbox, CheckboxGroup, CheckboxGroupProps, ReadMore } from '@cegal/ds-components'
import styled from 'styled-components'

import { useTranslation } from 'react-i18next'

import { Option } from 'utils/fieldUtils'

export type CheckboxGroupFieldProps<T extends Record<string, any>> = Omit<CheckboxGroupProps, 'children'> & {
  options: Array<Option<T>>
  onChange: (value: Array<Option<T>>) => void
  value?: Array<Option<T>>
  showMoreConfig?: CheckboxGroupShowMoreConfig
}

type CheckboxGroupShowMoreConfig = {
  visibleOptionsCount: number
  showMoreText?: string
  initiallyOpen?: boolean
}

const StyledCheckboxGroup = styled(CheckboxGroup)`
  width: 100%;
  legend {
    width: 100%;
  }
`

const CheckboxGroupField = <T extends Record<string, any>>({
  options,
  showMoreConfig,
  value,
  onChange
}: CheckboxGroupFieldProps<T>) => {
  const { t } = useTranslation()

  const renderOption = (option: T) => (
    <Checkbox value={option} key={option.value}>
      {option.label}
    </Checkbox>
  )

  return (
    <StyledCheckboxGroup legend={null} value={value} onChange={onChange}>
      {showMoreConfig ? (
        <>
          {options.slice(0, showMoreConfig.visibleOptionsCount).map(renderOption)}
          {options.length > showMoreConfig.visibleOptionsCount && (
            <ReadMore
              size='small'
              header={showMoreConfig.showMoreText ?? t('buttons:show-more')}
              defaultOpen={showMoreConfig.initiallyOpen}
            >
              {options.slice(showMoreConfig.visibleOptionsCount).map(renderOption)}
            </ReadMore>
          )}
        </>
      ) : (
        options.map(renderOption)
      )}
    </StyledCheckboxGroup>
  )
}

export default CheckboxGroupField
