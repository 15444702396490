var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from 'react';
import cl from 'clsx';
export const Panel = forwardRef((_a, ref) => {
    var { className, border = false, noPadding = false, background = false, as: Component = 'div' } = _a, rest = __rest(_a, ["className", "border", "noPadding", "background", "as"]);
    return (React.createElement(Component, Object.assign({}, rest, { ref: ref, className: cl('cds-panel', {
            'cds-panel--border': border,
            'cds-panel--no-padding': noPadding,
            'cds-panel--background': background
        }, className) })));
});
Panel.displayName = 'Panel';
export default Panel;
