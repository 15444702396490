import {
  Alert,
  Button,
  Column,
  FlexCenterDiv,
  FlexStartDiv,
  Heading,
  HorizontalSpace,
  Row,
  Tabs,
  VerticalSpace
} from '@cegal/ds-components'
import { Delete } from '@cegal/ds-icons'
import { ArrowBack } from '@cegal/ds-icons/dist/ArrowBack'
import { Assignment } from '@cegal/ds-icons/dist/Assignment'
import { BarChart } from '@cegal/ds-icons/dist/BarChart'
import { Comment } from '@cegal/ds-icons/dist/Comment'
import { Wc } from '@cegal/ds-icons/dist/Wc'
import {
  clearAlertBody,
  deleteClient,
  deleteFollowedClient,
  getClient,
  newFollowedClient,
  setClient
} from 'actions'
import { CLIENTS_DELETE_FAILURE, CLIENTS_GET_FAILURE, CLIENTS_NEW_SUCCESS } from 'constants/actionTypes'
import { AlertElement } from 'declarations/app'
import { Client } from 'declarations/models'
import { eventLogger, standardLogger } from 'metrics/loggers'
import { AlertsState } from 'reducers/alerts'
import { useAppDispatch, useAppSelector } from 'store'

import { FullScreenLoader } from 'components'
import AlertPanel from 'components/AlertPanel/AlertPanel'
import ClientCommentsView from 'components/Clients/Client/ClientCommentsView'
import ClientConsultantsView from 'components/Clients/Client/ClientConsultantsView'
import ClientDetail from 'components/Clients/Client/ClientDetail'
import ClientEngagementsView from 'components/Clients/Client/ClientEngagementsView'
import ClientStatisticsView from 'components/Clients/Client/ClientStatisticsView'
import FollowButton from 'components/Forms/Buttons/FollowButton'
import PageContent from 'components/PageContent/PageContent'
import { TabPanel } from 'components/styled'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import useUnmount from 'hooks/useUnmount'

import _ from 'lodash'

enum ClientTab {
  ENGAGEMENTS = 'engagements',
  CONSULTANTS = 'consultants',
  STATISTICS = 'statistics',
  COMMENTS = 'comments'
}

const ClientView = () => {
  const params = useParams()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [requestedDelete, setRequestedDelete] = useState<boolean>(false)

  const client = useAppSelector((state) => state.clients.get)
  const clients = useAppSelector((state) => state.clients.list)
  const alertBody = useAppSelector((state) => (state.alerts as AlertsState).body)
  const apiReady = useAppSelector((state) => state.app.apiReady)
  const deleting = useAppSelector((state) => state.clients.deleting)
  const creatingFollow = useAppSelector((state) => state.followedClients.creating)
  const deletingFollow = useAppSelector((state) => state.followedClients.deleting)
  const followedClients = useAppSelector((state) => state.followedClients.list)

  const handleClientFollow = (client: Client) => {
    standardLogger('clients.view.follow')
    dispatch(newFollowedClient(client))
  }

  const handleClientUnfollow = (client: Client) => {
    standardLogger('clients.view.unfollow')
    dispatch(deleteFollowedClient(client))
  }

  const loadPageData = () => {
    if (params.id) {
      const parsedId: number = parseInt(params.id!)
      if (_.isNumber(parsedId)) {
        if (!_.isNil(clients)) {
          const found = clients?.find((obj) => obj.id === parsedId)
          if (found) {
            dispatch(setClient(found))
            return
          }
        }
        dispatch(getClient(parsedId))
      }
    }
  }

  // when unmounting, clean up any alerts that are shown here
  useUnmount(() => {
    if (showAlert) {
      dispatch(clearAlertBody([CLIENTS_NEW_SUCCESS, CLIENTS_DELETE_FAILURE]))
    }
  })

  useEffect(() => {
    if (requestedDelete && !deleting) {
      setRequestedDelete(false)
      // return to clients only if successfully deleted
      if (client === undefined) {
        navigateBack()
      }
    }
  }, [client, requestedDelete, deleting])

  useEffect(() => {
    if (apiReady) {
      loadPageData()
    }
  }, [apiReady])

  const navigateBack = (e?: any) => {
    if (e) {
      eventLogger(e)
    }
    dispatch(setClient(undefined))
    navigate(-1)
  }

  const handleDeleteClient = () => {
    if (!!client && window.confirm(t('messages:confirm-delete-client')!)) {
      standardLogger('clients.view.delete')
      dispatch(deleteClient(client))
      setRequestedDelete(true)
    }
  }

  const handleClientViewTabChange = (tab: string) => {
    standardLogger('clients.view.tab.' + tab)
  }

  const showAlert: AlertElement | undefined = _.find(
    alertBody,
    (alert) => [CLIENTS_NEW_SUCCESS, CLIENTS_DELETE_FAILURE].indexOf(alert.type) >= 0
  )

  const _creating: boolean = _.find(creatingFollow, { id: client?.id }) !== undefined
  const _deleting: boolean = _.find(deletingFollow, { id: client?.id }) !== undefined

  return (
    <PageContent maxWidth='1600px'>
      <VerticalSpace />
      <Row>
        <Column>
          <FlexCenterDiv>
            <Button spacing variant='secondary' icon={<ArrowBack size='1.5rem' />} onClick={navigateBack}>
              {t('buttons:back')}
            </Button>
            <HorizontalSpace />
            <Heading>{client?.name}</Heading>
          </FlexCenterDiv>
          <VerticalSpace />
        </Column>
        <Column>
          <FlexStartDiv style={{ justifyContent: 'flex-end' }}>
            {client && (
              <>
                <FollowButton<Client>
                  size='medium'
                  item={client}
                  followedItems={followedClients}
                  onFollow={handleClientFollow}
                  onUnfollow={handleClientUnfollow}
                  loading={_creating || _deleting}
                  disabled={_creating || _deleting}
                />
                <HorizontalSpace />
              </>
            )}
            <Button
              spacing
              variant='danger'
              disabled
              icon={<Delete size='1.5rem' />}
              loading={deleting}
              onClick={handleDeleteClient}
            >
              {t(deleting ? 'buttons:deleting-x' : 'buttons:delete-x', {
                x: t('label:client').toLowerCase()
              })}
            </Button>
          </FlexStartDiv>
        </Column>
      </Row>
      <VerticalSpace size='2' />
      {showAlert && (
        <>
          <Alert variant={showAlert.variant}>{showAlert.message}</Alert>
          <VerticalSpace />
        </>
      )}
      {client === undefined && <FullScreenLoader />}
      {client === null && (
        <AlertPanel
          doNotRenderIfHeadIsActiveWithError
          watchFor={[CLIENTS_GET_FAILURE]}
          buttonText={t('buttons:click-to-reload-the-page')!}
          reloadPage={loadPageData}
        />
      )}
      {!_.isNil(client) && (
        <Row>
          <Column style={{ maxWidth: '100%', minWidth: '400px' }}>
            <ClientDetail client={client as Client} />
            <VerticalSpace />
          </Column>
          <Column flex='3'>
            <Tabs defaultValue={ClientTab.ENGAGEMENTS} onChange={handleClientViewTabChange}>
              <Tabs.List loop={false}>
                <Tabs.Tab
                  value={ClientTab.ENGAGEMENTS}
                  label={
                    <>
                      <Assignment size='1.5rem' />
                      <HorizontalSpace size='0.3' />
                      {t('label:engagements')}
                    </>
                  }
                />
                <Tabs.Tab
                  value={ClientTab.CONSULTANTS}
                  label={
                    <>
                      <Wc size='1.5rem' />
                      <HorizontalSpace size='0.3' />
                      {t('label:consultants')}
                    </>
                  }
                />
                <Tabs.Tab
                  value={ClientTab.STATISTICS}
                  label={
                    <>
                      <BarChart size='1.5rem' />
                      <HorizontalSpace size='0.3' />
                      {t('label:statistics')}
                    </>
                  }
                />
                <Tabs.Tab
                  value={ClientTab.COMMENTS}
                  label={
                    <>
                      <Comment size='1.5rem' />
                      <HorizontalSpace size='0.3' />
                      {t('label:comments')}
                    </>
                  }
                />
              </Tabs.List>
              <TabPanel value={ClientTab.ENGAGEMENTS}>
                <ClientEngagementsView client={client} />
              </TabPanel>

              <TabPanel value={ClientTab.CONSULTANTS}>
                <ClientConsultantsView client={client} />
              </TabPanel>

              <TabPanel value={ClientTab.STATISTICS}>
                <ClientStatisticsView client={client} />
              </TabPanel>

              <TabPanel value={ClientTab.COMMENTS}>
                <ClientCommentsView client={client} />
              </TabPanel>
            </Tabs>
          </Column>
        </Row>
      )}
    </PageContent>
  )
}

export default ClientView
