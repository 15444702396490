var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import cl from 'clsx';
import React, { createContext, forwardRef, useContext, useState } from 'react';
import { Fieldset, FieldsetContext } from '..';
export const CheckboxGroupContext = createContext(null);
export const CheckboxGroup = forwardRef((_a, ref) => {
    var _b, _c;
    var { value, defaultValue, onChange = () => { }, children, className } = _a, rest = __rest(_a, ["value", "defaultValue", "onChange", "children", "className"]);
    const fieldset = useContext(FieldsetContext);
    const [state, setState] = useState(defaultValue !== null && defaultValue !== void 0 ? defaultValue : []);
    const toggleValue = (v) => {
        const newValue = value !== null && value !== void 0 ? value : state;
        const newState = newValue.includes(v) ? newValue.filter((x) => x !== v) : [...newValue, v];
        value === undefined && setState(newState);
        onChange(newState);
    };
    return (React.createElement(Fieldset, Object.assign({}, rest, { ref: ref, className: cl('cds-checkbox-group', `cds-checkbox-group--${(_c = (_b = rest.size) !== null && _b !== void 0 ? _b : fieldset === null || fieldset === void 0 ? void 0 : fieldset.size) !== null && _c !== void 0 ? _c : 'medium'}`, className) }),
        React.createElement(CheckboxGroupContext.Provider, { value: {
                value,
                defaultValue,
                toggleValue
            } },
            React.createElement("div", { className: 'cds-checkboxes' }, children))));
});
CheckboxGroup.displayName = 'CheckboxGroup';
export default CheckboxGroup;
