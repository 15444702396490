import { Button, Table } from '@cegal/ds-components/dist'
import { Settings } from '@cegal/ds-icons/dist/Settings'
import {
  deleteFollowedConsultant,
  deleteMatch,
  listMatches,
  newFollowedConsultant,
  newMatch,
  setTableHeaders
} from 'actions'
import { TableHeader, ViewMode } from 'declarations/app'
import {
  Consultant,
  ConsultantsQuery,
  Engagement,
  EngagementVacancy,
  EngagementVacancyConsultant
} from 'declarations/models'
import { standardLogger } from 'metrics/loggers'
import { useAppDispatch, useAppSelector } from 'store'

import { ConsultantCard, ConsultantPreviewModal, ConsultantRow } from 'components/Consultants/Consultant'
import FindButton from 'components/Forms/Buttons/FindButton'
import FollowButton from 'components/Forms/Buttons/FollowButton'
import MatchButton from 'components/Forms/Buttons/MatchButton'
import TableColumnManagerModal from 'components/TableColumnManagerModal/TableColumnManagerModal'
import { CardContainer } from 'components/styled'

import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { consultantHeaders, getConsultantHeader, initialConsultantsTable } from 'utils/tableColumns'

import _ from 'lodash'

export interface ConsultantsViewProps {
  buttons?: Array<string>
  consultants: Array<Consultant>
  viewMode: ViewMode
  onSortChange?: (sortKey?: string | undefined) => void
  query?: Partial<ConsultantsQuery>

  // these are for match context
  previewInModal?: boolean
  engagement?: Engagement | undefined
  engagementVacancy?: EngagementVacancy | undefined
}

const ConsultantsView = ({
  buttons,
  consultants,
  engagement,
  engagementVacancy,
  viewMode,
  query,
  onSortChange,
  previewInModal = false
}: ConsultantsViewProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const headerKeys: Array<string> = useAppSelector((state) => state.app.tables.consultants)
  const headers: Array<TableHeader> = useMemo(
    () => headerKeys.map(getConsultantHeader) as Array<TableHeader>,
    [headerKeys]
  )

  const [consultantToPreview, setConsultantToPreview] = useState<Consultant>()
  const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false)
  const [openTableModal, setOpenTableModal] = useState<boolean>(false)

  const apiReady = useAppSelector((state) => state.app.apiReady)
  const creating = useAppSelector((state) => state.matches.creating)
  const deleting = useAppSelector((state) => state.matches.deleting)
  const currentMatch = useAppSelector((state) => state.matches.currentMatch)
  const matches = useAppSelector((state) => state.matches.list)
  const creatingFollow = useAppSelector((state) => state.followedConsultants.creating)
  const deletingFollow = useAppSelector((state) => state.followedConsultants.deleting)
  const followedConsultants = useAppSelector((state) => state.followedConsultants.list)

  const onCloseModal = () => setOpenPreviewModal(!openPreviewModal)

  const setHeaderKeys = (newHeaders: Array<string>) => {
    dispatch(setTableHeaders('consultants', newHeaders))
  }

  const handleConsultantMatched = (evc: Partial<EngagementVacancyConsultant>) => {
    standardLogger('consultant.' + viewMode + '.match')
    dispatch(newMatch(evc))
  }

  const handleConsultantUnmatched = (evc: Partial<EngagementVacancyConsultant>) => {
    standardLogger('consultant.' + viewMode + '.unmatch')
    dispatch(deleteMatch(evc))
  }

  const handleConsultantFollow = (consultant: Consultant) => {
    standardLogger('consultant.' + viewMode + '.follow')
    dispatch(newFollowedConsultant(consultant))
  }

  const handleConsultantUnfollow = (consultant: Consultant) => {
    standardLogger('consultant.' + viewMode + '.unfollow')
    dispatch(deleteFollowedConsultant(consultant))
  }

  const handleConsultantPreview = (consultant: Consultant) => {
    standardLogger('consultant.' + viewMode + '.view')
    if (!previewInModal) {
      navigate(generatePath('/consultants/:id', { id: consultant?.id.toString() }))
      return
    }
    setConsultantToPreview(consultant)
    setOpenPreviewModal(true)
  }

  const getButtons = (consultant: Consultant): Array<React.ReactNode> | undefined => {
    return buttons?.map((button) => {
      switch (button) {
        case 'match':
          return (
            <MatchButton
              key={'evc-' + consultant?.id + '-' + (engagementVacancy?.id ?? '') + 'match-button'}
              item={
                {
                  consultant,
                  engagement_vacancy: engagementVacancy,
                  start_date: engagement?.start_date,
                  end_date: engagement?.end_date,
                  notes: '',
                  status: 'matched',
                  hourly_rate: engagementVacancy?.hourly_rate
                } as EngagementVacancyConsultant
              }
              context={consultant}
              contextType='consultant'
              alreadyMatched={
                _.find(
                  matches,
                  (m: EngagementVacancyConsultant) =>
                    m.engagement_vacancy?.id === engagementVacancy?.id && m.consultant?.id === consultant.id
                ) !== undefined
              }
              onMatch={handleConsultantMatched}
              onUnmatch={handleConsultantUnmatched}
              loading={(creating || deleting) && currentMatch?.consultant?.id === consultant.id}
              disabled={(creating || deleting) && currentMatch?.consultant?.id === consultant.id}
            />
          )
        case 'find':
          return (
            <FindButton
              key={'consultant-' + consultant.id + '-find-button'}
              forItem='engagement'
              text={t('buttons:find-x', { x: t('label:engagements-title').toLowerCase() })}
              onClick={() => navigate('/consultants/' + consultant.id + '/find')}
            />
          )
        case 'follow': {
          const _creatingFollow = _.find(creatingFollow, { id: consultant.id }) !== undefined
          const _deletingFollow = _.find(deletingFollow, { id: consultant.id }) !== undefined
          return (
            <FollowButton<Consultant>
              key={'consultant-' + consultant.id + '-follow-button'}
              item={consultant}
              followedItems={followedConsultants}
              onFollow={() => handleConsultantFollow(consultant)}
              onUnfollow={() => handleConsultantUnfollow(consultant)}
              loading={_creatingFollow || _deletingFollow}
              disabled={_creatingFollow || _deletingFollow}
            />
          )
        }
        default:
          return null
      }
    })
  }

  useEffect(() => {
    if (apiReady && _.isUndefined(matches)) {
      dispatch(listMatches())
    }
  }, [apiReady, matches])

  return (
    <>
      <ConsultantPreviewModal
        open={openPreviewModal}
        onClose={onCloseModal}
        consultant={consultantToPreview}
      />
      {viewMode === 'row' && (
        <>
          <TableColumnManagerModal
            headerKeys={headerKeys}
            setHeaderKeys={setHeaderKeys}
            modalOpen={openTableModal}
            onClose={() => setOpenTableModal(false)}
            headers={consultantHeaders}
            initialHeaders={initialConsultantsTable}
          />
          <Table
            style={{ width: '100%' }}
            data-cy='consultantTable'
            sort={query?.sort}
            onSortChange={onSortChange}
          >
            <Table.Header>
              <Table.Row>
                {headers?.map((header) => (
                  <Table.ColumnHeader key={header.value} sortable={!!header.sortKey} sortKey={header.sortKey}>
                    {t(header.label)}
                  </Table.ColumnHeader>
                ))}
                <Table.ColumnHeader style={{ textAlign: 'end' }}>
                  <Button
                    variant='primary'
                    icon={<Settings size='1.5rem' />}
                    onClick={() => setOpenTableModal(true)}
                  />
                </Table.ColumnHeader>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {consultants?.map((consultant) => (
                <ConsultantRow
                  order={headerKeys}
                  key={`${consultant.id}-consultant-row`}
                  item={consultant}
                  buttons={getButtons(consultant)}
                  onView={() => handleConsultantPreview(consultant)}
                />
              ))}
            </Table.Body>
          </Table>
        </>
      )}
      {viewMode === 'card' && (
        <CardContainer>
          {consultants?.map((consultant: Consultant) => (
            <ConsultantCard
              key={consultant.id}
              item={consultant}
              buttons={getButtons(consultant)}
              context={{
                consultant,
                engagement,
                engagementVacancy
              }}
              spacing
              onView={handleConsultantPreview}
            />
          ))}
        </CardContainer>
      )}
    </>
  )
}

export default ConsultantsView
