import { Button, ButtonSize } from '@cegal/ds-components'
import { ManageSearch as EngagementOrClientFindIcon } from '@cegal/ds-icons/dist/ManageSearch'
import { PersonSearch as ConsultantFindIcon } from '@cegal/ds-icons/dist/PersonSearch'

interface FindButtonProps {
  size?: ButtonSize
  text?: string
  forItem: 'consultant' | 'engagement' | 'client'
  loading?: boolean
  disabled?: boolean
  onClick: (e: any) => void
}

const FindButton = ({ forItem, text, size = 'small', ...rest }: FindButtonProps) => {
  return (
    <Button
      spacing
      variant='secondary'
      title={text}
      size={size}
      icon={
        forItem === 'consultant' ? (
          <ConsultantFindIcon size='1.25rem' />
        ) : (
          <EngagementOrClientFindIcon size='1.25rem' />
        )
      }
      {...rest}
    >
      {text}
    </Button>
  )
}

export default FindButton
