var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import cl from 'clsx';
import { BodyShort, Detail, ErrorMessage, Label } from 'components/typography';
import React, { forwardRef } from 'react';
import OriginalRangeSlider from 'react-range-slider-input';
import { useFormField } from 'components/form/useFormField';
export const RangeSlider = forwardRef((props, ref) => {
    const { inputProps, errorId, showErrorMsg, hasError, size, inputDescriptionId } = useFormField(props, 'textField');
    const { className, description, hideLabel = false, disabled = false, label, single = false, onChange, style = {} } = props, rest = __rest(props, ["className", "description", "hideLabel", "disabled", "label", "single", "onChange", "style"]);
    return (React.createElement("div", { className: cl('cds-form-field', `cds-form-field--${size}`, {
            'cds-rangeslider--single': single,
            'cds-rangeslider--error': hasError,
            'cds-rangeslider--disabled': !!inputProps.disabled,
            'cds-form-field--disabled': !!inputProps.disabled
        }, className) },
        React.createElement(Label, { htmlFor: inputProps.id, size: size, as: 'label', className: cl('cds-form-field__label', {
                'cds-sr-only': hideLabel
            }) }, label),
        !!description && (React.createElement(React.Fragment, null, size === 'medium' ? (React.createElement(BodyShort, { className: cl('cds-form-field__description', {
                'cds-sr-only': hideLabel
            }), id: inputDescriptionId, size: 'small', as: 'div' }, description)) : (React.createElement(Detail, { className: cl('cds-form-field__description', {
                'cds-sr-only': hideLabel
            }), id: inputDescriptionId, size: 'small', as: 'div' }, description)))),
        React.createElement("div", { className: 'cds-rangeslider__wrap', style: style },
            React.createElement(OriginalRangeSlider, Object.assign({ thumbsDisabled: disabled, onInput: onChange }, rest))),
        React.createElement("div", { className: 'cds-form-field__error', id: errorId, "aria-relevant": 'additions removals', "aria-live": 'polite' }, showErrorMsg && React.createElement(ErrorMessage, { size: size }, props.error))));
});
RangeSlider.displayName = 'Slider';
export default RangeSlider;
