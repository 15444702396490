import {
  Alert,
  AlignEndColumn,
  Button,
  Dropzone,
  FlexBaseDiv,
  FlexDiv,
  Heading,
  HorizontalSpace,
  Modal,
  Row,
  VerticalSpace
} from '@cegal/ds-components'
import { File } from 'declarations/models'
import 'index.css'

import FileRow from 'components/File/FileRow'

import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'

export interface UploadFileModalProps {
  open: boolean
  onClose: () => void
  onAddFiles: (cleanFiles: Array<File>) => void
  onPreviewFile: (file: File) => void
  newFiles: Array<File>
  anchorEl?: Element | null
}

const FileUploadModal = ({
  onClose,
  open,
  onAddFiles,
  onPreviewFile,
  newFiles,
  anchorEl = null
}: UploadFileModalProps) => {
  const { t } = useTranslation()

  const maxFileSize = 5000000 // 5MB
  const maxFiles = 6
  const modalContainerRef = useRef(null)

  const [alert, setAlert] = useState<string | undefined>(undefined)
  const [cleanFiles, setCleanFiles] = useState<Array<File>>(newFiles)

  useEffect(() => {
    newFiles?.length > 0 ? setCleanFiles(newFiles) : setCleanFiles([])
  }, [newFiles])

  const processFiles = (newFiles: Array<File>, rejectedFiles: any) => {
    if (!_.isEmpty(rejectedFiles)) {
      return setAlert(t('messages:there-were-rejected-files'))
    }

    if (_.size(cleanFiles) >= maxFiles) {
      return setAlert(`${maxFiles} ${t('messages:files-are-allowed-to-add')}`)
    }

    const hasDuplicateFile = cleanFiles?.some((file: File) => file.name === newFiles[0].name)

    if (hasDuplicateFile) {
      return setAlert(t('messages:this-file-already-added'))
    }

    setCleanFiles([...cleanFiles, ...newFiles])
  }

  const onDeleteFile = (_file: File) => {
    const files = (cleanFiles as Array<File>).filter((file: File) => file.name !== _file.name)
    setCleanFiles(files)
    onAddFiles(files)
  }

  const onAdd = () => {
    onAddFiles(cleanFiles)
    onClose()
  }

  const onCloseAlert = () => setAlert(undefined)

  const handleClose = () => onClose()

  return (
    <>
      <FlexBaseDiv ref={modalContainerRef}>
        {modalContainerRef?.current && (
          <Modal
            // @ts-ignore
            parentSelector={() => anchorEl ?? modalContainerRef.current!}
            open={open}
            onClose={handleClose}
            aria-label='Upload File'
            closeButton={false}
            shouldCloseOnOverlayClick={false}
          >
            <Modal.Content style={{ minWidth: '400px' }}>
              <Modal.Content.Header>
                <Heading spacing level='2' size='medium'>
                  {t('label:upload-file')}
                </Heading>
              </Modal.Content.Header>
              <Modal.Content.Body>
                <VerticalSpace />
                {!_.isEmpty(alert) && (
                  <>
                    <Alert variant='error' size='small' onClose={onCloseAlert}>
                      {alert}
                    </Alert>
                    <VerticalSpace />
                  </>
                )}
                <Dropzone
                  multiple
                  messages
                  maxFiles={maxFiles}
                  maxFileSize={maxFileSize}
                  labels={{
                    dropFilesHere: t('messages:drag-and-drop-file')
                  }}
                  onDrop={(newFiles, acceptedFiles, rejectedFiles) => processFiles(newFiles, rejectedFiles)}
                />

                <VerticalSpace />
                {!_.isEmpty(cleanFiles) && (
                  <Heading spacing level='3' size='small'>
                    {t('label:added-files')}
                  </Heading>
                )}
                {(cleanFiles as Array<File>).map((file: File) => (
                  <FileRow
                    key={file.name}
                    file={file}
                    separator
                    onDelete={() => onDeleteFile(file)}
                    onPreview={() => onPreviewFile(file)}
                  />
                ))}
              </Modal.Content.Body>
              <Modal.Content.Footer>
                <Row>
                  <AlignEndColumn style={{ alignItems: 'flex-end' }}>
                    <FlexDiv>
                      <Button
                        variant='secondary'
                        onClick={() => {
                          if (_.isEmpty(cleanFiles)) {
                            handleClose()
                          } else {
                            if (window.confirm(t('messages:confirm-close-note')!)) {
                              handleClose()
                            }
                          }
                        }}
                      >
                        {t('buttons:close')}
                      </Button>
                      <HorizontalSpace />
                      <Button disabled={_.isEmpty(cleanFiles)} onClick={onAdd}>
                        {t('buttons:add')}
                      </Button>
                    </FlexDiv>
                  </AlignEndColumn>
                </Row>
              </Modal.Content.Footer>
            </Modal.Content>
          </Modal>
        )}
      </FlexBaseDiv>
    </>
  )
}

export default FileUploadModal
