import { FlexStartDiv, HorizontalSpace, Select } from '@cegal/ds-components'
import { setHomeMode, setItemsPerPage, setViewMode } from 'actions'
import { HomeMode, ViewMode } from 'declarations/app'
import { standardLogger } from 'metrics/loggers'
import { useAppDispatch, useAppSelector } from 'store'

import ToggleViewButtons from 'components/Forms/ToggleViewButtons'

export interface ToggleViewProps {
  context: string
  viewToggleModeHome?: boolean
  viewToggleMode?: boolean
  viewItemsPerPage?: boolean
}

const ToggleView = ({
  context,
  viewToggleMode = true,
  viewToggleModeHome = false,
  viewItemsPerPage = false
}: ToggleViewProps) => {
  const dispatch = useAppDispatch()
  const itemsPerPage = useAppSelector((state) => state.app.settings.itemsPerPage)
  const viewMode = useAppSelector((state) => state.app.settings.viewMode)
  const homeMode = useAppSelector((state) => state.app.settings.homeMode)

  const onViewModeChange = (mode: string) => {
    standardLogger(`${context}.${mode}.view`)

    if (mode === 'row' || mode === 'card') {
      dispatch(setViewMode(mode as ViewMode))
    }
    if (mode === 'widget' || mode === 'page' || mode === 'tab') {
      dispatch(setHomeMode(mode as HomeMode))
    }
  }

  const onItemsPerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newItems = parseInt(e.target.value)
    standardLogger(`${context}.itemsperpage`, { items: newItems })
    dispatch(setItemsPerPage(newItems))
  }

  return (
    <FlexStartDiv style={{ flexWrap: 'nowrap', justifyContent: 'flex-end' }}>
      {viewToggleMode && (
        <ToggleViewButtons
          homeView={viewToggleModeHome}
          onChange={onViewModeChange}
          value={viewToggleModeHome ? homeMode : viewMode}
        />
      )}
      {viewItemsPerPage && (
        <>
          <HorizontalSpace />
          <Select hideLabel label='items per page' onChange={onItemsPerPageChange} value={itemsPerPage}>
            <option>10</option>
            <option>20</option>
            <option>50</option>
          </Select>
        </>
      )}
    </FlexStartDiv>
  )
}

export default ToggleView
