var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import cl from 'clsx';
import { BoxContext } from 'components/box/Box';
import React, { forwardRef, useContext } from 'react';
const BoxBody = forwardRef((_a, ref) => {
    var { className, background } = _a, rest = __rest(_a, ["className", "background"]);
    const context = useContext(BoxContext);
    return (React.createElement("div", Object.assign({}, rest, { ref: ref, className: cl('cds-box-body', `cds-box-body--${context === null || context === void 0 ? void 0 : context.size}`, {
            'cds-box-body--background': background !== null && background !== void 0 ? background : context === null || context === void 0 ? void 0 : context.background
        }, className) })));
});
BoxBody.displayName = 'Box.Body';
export default BoxBody;
