export type Option<D extends Record<string, any>, V extends string = 'id'> = D & {
  value: D[V]
  label: string
}

type GroupedOptionValue<D extends Record<string, any>, V extends string> = {
  occurrence: number
  label: string
  option: Option<D, V>
}
type GroupedOption<D extends Record<string, any>, V extends string> = Record<string, GroupedOptionValue<D, V>>

export enum OptionsSortBy {
  ALPHABET_DESC = 'alphabet-desc',
  OCCURRENCE_DESC = 'occurrence-desc'
}

type DataToOptionsSortedByOccurrenceProps<
  D extends Record<string, any>,
  V extends string,
  L extends string
> = {
  data: D[]
  showOccurrence?: boolean
  valueSelector: V
  labelSelector: L
  sortBy?: OptionsSortBy
}

const getOptionsSortFunction = <D extends Record<string, any>, V extends string>(sortBy?: OptionsSortBy) => {
  if (sortBy === OptionsSortBy.ALPHABET_DESC) {
    return (a: [string, GroupedOptionValue<D, V>], b: [string, GroupedOptionValue<D, V>]) => {
      if (a[1].label < b[1].label) {
        return -1
      }
      if (a[1].label > b[1].label) {
        return 1
      }
      /* istanbul ignore next */
      return 0
    }
  }
  return (a: [string, GroupedOptionValue<D, V>], b: [string, GroupedOptionValue<D, V>]) =>
    b[1].occurrence - a[1].occurrence
}

export const dataToOptions = <D extends Record<string, any>, V extends string, L extends string>(
  data: D[],
  valueSelector: V,
  labelSelector: L
): Option<D, V>[] =>
  data.map((item: D) => {
    const value = item[valueSelector]
    const label = item[labelSelector]

    return {
      ...item,
      value,
      label
    }
  })

export const dataToOptionsSortedByOccurrence = <
  D extends Record<string, any>,
  V extends string,
  L extends string
>({
  data,
  showOccurrence = true,
  valueSelector,
  labelSelector,
  sortBy
}: DataToOptionsSortedByOccurrenceProps<D, V, L>): Option<D, V>[] => {
  const optionsMap = dataToOptions<D, V, L>(data, valueSelector, labelSelector) || []

  const optionsGroupedByName = optionsMap.reduce<GroupedOption<D, V>>((group, option) => {
    const updatedGroup = group

    if (group?.[option.label]) {
      updatedGroup[option.label].occurrence = group[option.label].occurrence + 1
      return updatedGroup
    }

    updatedGroup[option.label] = { occurrence: 1, label: option.label, option }

    return updatedGroup
  }, {})

  const sortFunction = getOptionsSortFunction(sortBy)

  const optionsSorted = Object.entries(optionsGroupedByName)

    .sort(sortFunction)
    .map((optionsEntry) => {
      const occurrenceString = showOccurrence ? ` (${optionsEntry[1].occurrence})` : ''

      return {
        ...optionsEntry[1].option,
        label: `${optionsEntry[1].option.label}${occurrenceString}`
      }
    })

  return optionsSorted
}
