import {
  Avatar,
  Chip,
  FlexCenterDiv,
  FlexDiv,
  HorizontalSpace,
  Link,
  Loader,
  Table
} from '@cegal/ds-components'
import { Launch } from '@cegal/ds-icons'
import UnknownAvatar from 'assets/images/unknownAvatar.png'
import { Consultant, ConsultantsQuery, SimpleEntry } from 'declarations/models'
import { eventLogger } from 'metrics/loggers'

import ConsultantAllocationChart from 'components/Consultants/Consultant/Allocation/ConsultantAllocationChart'
import { DescriptionListItem, DescriptionSpan } from 'components/DescriptionList/DescriptionList'
import Row, { RowProps } from 'components/Row/Row'
import CertificatesGroup from 'components/TagGroup/CertificatesGroup'
import RolesGroup from 'components/TagGroup/RolesGroup'
import SkillsGroup from 'components/TagGroup/SkillsGroup'
import TagGroup from 'components/TagGroup/TagGroup'

import Highlighter from 'react-highlight-words'
import { useTranslation } from 'react-i18next'
import { NavLink, generatePath } from 'react-router-dom'

import useConsultantAllocation from 'hooks/useConsultantAllocation'
import useConsultantAvatar from 'hooks/useConsultantAvatar'
import useQuery from 'hooks/useQuery'

import { tokenize } from 'utils/tokenizer'

import _ from 'lodash'

export type ConsultantRowProps = RowProps<Consultant, ConsultantsQuery> & {
  order: Array<string>
  onView?: (consultant: Consultant) => void
}

const ConsultantRow = ({ item, order, onView, ...rest }: ConsultantRowProps) => {
  const { t } = useTranslation()
  const { avatar, avatarLoading, ref: avatarRef } = useConsultantAvatar({ id: item?.id })

  const { allocation, allocationLoading, ref: allocationRef } = useConsultantAllocation({ consultant: item })

  const { query, setQuery } = useQuery<Partial<ConsultantsQuery>>({})
  const searchWords = tokenize(query.search)

  return (
    <Row data-testId='ConsultantRow' item={item} {...rest}>
      {order.map((key) => {
        switch (key) {
          case 'name':
            return (
              <Table.DataCell key={key}>
                <FlexCenterDiv style={{ flexWrap: 'nowrap' }} ref={allocationRef}>
                  <Avatar
                    style={{ minWidth: 'var(--cds-size-12)' }}
                    variant='square-round'
                    size='small'
                    loading={avatarLoading}
                    src={avatar}
                    ref={avatarRef}
                    defaultSrc={UnknownAvatar}
                  />
                  <HorizontalSpace size='0.5' />
                  <Link
                    style={{ cursor: 'pointer' }}
                    onClick={(e: any) => {
                      eventLogger(e)
                      onView?.(item)
                    }}
                    data-testid='ConsultantRow-Name'
                    data-cy='ConsultantRowName'
                  >
                    <Highlighter
                      caseSensitive={false}
                      searchWords={searchWords}
                      textToHighlight={item?.name ?? ''}
                    />
                  </Link>
                </FlexCenterDiv>
              </Table.DataCell>
            )
          case 'email':
            return (
              <Table.DataCell key={key}>
                <Link target='_blank' href={'mailto:' + item.email} rel='noreferrer'>
                  <Highlighter
                    caseSensitive={false}
                    searchWords={searchWords}
                    textToHighlight={item.email ?? ''}
                  />
                </Link>
              </Table.DataCell>
            )
          case 'allocation':
            return (
              <Table.DataCell key={key}>
                {allocationLoading ? (
                  <Loader size='small' />
                ) : _.isEmpty(allocation) ? (
                  <DescriptionListItem
                    title={t('label:allocation-title')}
                    $alignItems='baseline'
                    details={<DescriptionSpan>{t('messages:warning-no-allocation-info')}</DescriptionSpan>}
                  />
                ) : (
                  <ConsultantAllocationChart
                    chartLabel={t('label:allocation-chart')}
                    consultant={item}
                    allocation={allocation}
                    chartStyleOptions={{
                      height: '8rem',
                      width: '16rem'
                    }}
                    showOverview={false}
                    showChartLabel={false}
                    showYAxisLabel={false}
                    showXAxisLabel
                  />
                )}
              </Table.DataCell>
            )
          case 'engagements':
            return (
              <Table.DataCell key={key}>
                <TagGroup
                  size='small'
                  visibleRows={1}
                  interactive
                  tags={item.engagements.map(
                    (engagement) =>
                      ({
                        name: engagement.title
                      }) as SimpleEntry
                  )}
                  renderElement={({ tag }) => (
                    <>
                      {item.engagements?.map((engagement) => (
                        <Chip size='small' key={engagement?.id?.toString()}>
                          <Link
                            as={NavLink}
                            to={generatePath('/engagements/:id', {
                              id: engagement?.id?.toString()
                            })}
                          >
                            {tag.name}
                            <Launch size='1rem' />
                          </Link>
                        </Chip>
                      ))}
                    </>
                  )}
                />
              </Table.DataCell>
            )
          case 'roles':
            return (
              <Table.DataCell key={key}>
                <RolesGroup<Partial<ConsultantsQuery>>
                  query={query}
                  roles={(item as Consultant).roles}
                  setQuery={setQuery}
                  interactive={!_.isNil(query)}
                />
              </Table.DataCell>
            )
          case 'department':
            return (
              <Table.DataCell key={key}>
                <Highlighter
                  caseSensitive={false}
                  searchWords={searchWords}
                  textToHighlight={item?.department?.name ?? ''}
                />
              </Table.DataCell>
            )
          case 'location':
            return (
              <Table.DataCell key={key}>
                <Highlighter
                  caseSensitive={false}
                  searchWords={searchWords}
                  textToHighlight={item?.location?.name ?? ''}
                />
              </Table.DataCell>
            )
          case 'skills':
            return (
              <Table.DataCell key={key}>
                <SkillsGroup<Partial<ConsultantsQuery>>
                  mode='popover'
                  size='small'
                  visibleRows={2}
                  query={query}
                  setQuery={setQuery}
                  interactive
                  skills={item.skills}
                />
              </Table.DataCell>
            )
          case 'certificates':
            return (
              <Table.DataCell key={key}>
                <FlexDiv style={{ minWidth: '200px' }}>
                  <CertificatesGroup<Partial<ConsultantsQuery>>
                    query={query}
                    setQuery={setQuery}
                    interactive={!_.isNil(query)}
                    certificates={item.certificates}
                  />
                </FlexDiv>
              </Table.DataCell>
            )
        }
        return null
      })}
    </Row>
  )
}

export default ConsultantRow
