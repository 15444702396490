import * as types from 'constants/actionTypes'
import * as urls from 'constants/urls'
import api from 'api'
import { NoteRequest } from 'declarations/api'
import { Note } from 'declarations/models'
import { sprintf } from 'sprintf-js'

export const deleteNote = (note: Note) => {
  return api.call({
    url: sprintf(urls.NOTES_DELETE_URL, { id: note.id }),
    method: 'DELETE',
    body: note,
    skipFake: true,
    cascadeFailureError: true,
    type: {
      request: types.NOTES_DELETE_REQUEST,
      success: types.NOTES_DELETE_SUCCESS,
      failure: types.NOTES_DELETE_FAILURE
    }
  })
}

export const editNote = (note: Note) => {
  const payload: NoteRequest = {
    title: note.title,
    body: note.body
  }

  return api.call({
    url: sprintf(urls.NOTES_EDIT_URL, { id: note.id }),
    method: 'PUT',
    skipFake: true,
    body: payload,
    cascadeFailureError: true,
    type: {
      request: types.NOTES_EDIT_REQUEST,
      success: types.NOTES_EDIT_SUCCESS,
      failure: types.NOTES_EDIT_FAILURE
    }
  })
}

export const listNotes = () => {
  return api.call({
    url: sprintf(urls.NOTES_LIST_URL),
    skipFake: true,
    cascadeFailureError: true,
    type: {
      request: types.NOTES_LIST_REQUEST,
      success: types.NOTES_LIST_SUCCESS,
      failure: types.NOTES_LIST_FAILURE
    }
  })
}

export const newNote = (note: Note) => {
  return api.call({
    url: urls.NOTES_NEW_URL,
    method: 'POST',
    body: note,
    skipFake: true,
    cascadeFailureError: true,
    type: {
      request: types.NOTES_NEW_REQUEST,
      success: types.NOTES_NEW_SUCCESS,
      failure: types.NOTES_NEW_FAILURE
    }
  })
}

export const resetNote = () => ({
  type: types.NOTES_RESET
})
