var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import cl from 'clsx';
import Panel from 'components/panel/Panel';
import Loader from 'components/loader/Loader';
import React, { createContext, forwardRef } from 'react';
import BoxBody from './Body';
import BoxFooter from './Footer';
import BoxHeader from './Header';
export const BoxContext = createContext(null);
const Box = forwardRef((_a, ref) => {
    var { background = false, border = false, children, className, flex = true, loading = false, size = 'medium', spacing = false, variant = 'normal' } = _a, rest = __rest(_a, ["background", "border", "children", "className", "flex", "loading", "size", "spacing", "variant"]);
    return (React.createElement(BoxContext.Provider, { value: { size, background } },
        React.createElement(Panel, Object.assign({}, rest, { ref: ref, className: cl('cds-box', `cds-box--${size}`, `cds-box--${variant}`, {
                'cds-box--flex': flex,
                'cds-box--spacing': spacing
            }, className), as: 'div', border: border, background: background, noPadding: true }),
            loading && (React.createElement("div", { className: 'cds-box--loading' },
                React.createElement(Loader, { size: 'xlarge' }))),
            children)));
});
Box.Header = BoxHeader;
Box.Body = BoxBody;
Box.Footer = BoxFooter;
Box.displayName = 'Box';
export default Box;
