import cl from 'clsx';
import { BodyShort, Detail } from 'components/typography';
import React, { forwardRef } from 'react';
import { omit } from 'util/index';
import { useRadio } from './useRadio';
export const Radio = forwardRef((props, ref) => {
    const { inputProps, size, hasError } = useRadio(props);
    const Description = size === 'medium' ? BodyShort : Detail;
    return (React.createElement("div", { className: cl('cds-radio', `cds-radio--${size}`, {
            'cds-radio--error': hasError,
            'cds-radio--disabled': inputProps.disabled
        }, props.className) },
        React.createElement("input", Object.assign({}, omit(props, ['children', 'size', 'description']), inputProps, { ref: ref, className: 'cds-radio__input' })),
        React.createElement("label", { htmlFor: inputProps.id, className: 'cds-radio__label' },
            React.createElement("div", { className: 'cds-radio__content' },
                React.createElement(BodyShort, { as: 'div', size: size }, props.children),
                props.description && (React.createElement(Description, { as: 'div', size: 'small', className: 'cds-radio__description' }, props.description))))));
});
Radio.displayName = 'Radio';
export default Radio;
