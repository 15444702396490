import * as types from 'constants/actionTypes'
import * as urls from 'constants/urls'
import api from 'api'
import { Consultant } from 'declarations/models'
import { sprintf } from 'sprintf-js'

import _ from 'lodash'

export const deleteFollowedConsultant = (followedConsultant: Consultant) => {
  if (_.isNumber(followedConsultant?.id)) {
    return api.call({
      url: sprintf(urls.FOLLOWED_CONSULTANTS_DELETE_URL, {
        id: followedConsultant!.id
      }),
      method: 'DELETE',
      skipFake: true,
      context: followedConsultant,
      type: {
        request: types.FOLLOWED_CONSULTANTS_DELETE_REQUEST,
        success: types.FOLLOWED_CONSULTANTS_DELETE_SUCCESS,
        failure: types.FOLLOWED_CONSULTANTS_DELETE_FAILURE
      }
    })
  }
}

export const listFollowedConsultants = () => {
  return api.call({
    url: urls.FOLLOWED_CONSULTANTS_LIST_URL,
    skipFake: true,
    cascadeFailureError: true,
    type: {
      request: types.FOLLOWED_CONSULTANTS_LIST_REQUEST,
      success: types.FOLLOWED_CONSULTANTS_LIST_SUCCESS,
      failure: types.FOLLOWED_CONSULTANTS_LIST_FAILURE
    }
  })
}

export const newFollowedConsultant = (consultant: Consultant) => {
  return api.call({
    url: sprintf(urls.FOLLOWED_CONSULTANTS_NEW_URL, {
      consultant_id: consultant!.id
    }),
    method: 'POST',
    skipFake: true,
    cascadeFailureError: true,
    context: consultant,
    type: {
      request: types.FOLLOWED_CONSULTANTS_NEW_REQUEST,
      success: types.FOLLOWED_CONSULTANTS_NEW_SUCCESS,
      failure: types.FOLLOWED_CONSULTANTS_NEW_FAILURE
    }
  })
}
