var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useContext } from 'react';
import { omit } from 'util/index';
import { useFormField } from 'components/form/useFormField';
import { RadioGroupContext } from './RadioGroup';
export const useRadio = (props) => {
    const radioGroup = useContext(RadioGroupContext);
    const _a = useFormField(omit(props, ['description']), 'radio'), { inputProps } = _a, rest = __rest(_a, ["inputProps"]);
    if (!radioGroup) {
        console.warn('<Radio> must be used inside <RadioGroup>.');
    }
    if ((props === null || props === void 0 ? void 0 : props.required) !== undefined) {
        console.warn('required is only supported on <RadioGroup>.');
    }
    return Object.assign(Object.assign({}, rest), { inputProps: Object.assign(Object.assign({}, inputProps), { name: radioGroup === null || radioGroup === void 0 ? void 0 : radioGroup.name, defaultChecked: (radioGroup === null || radioGroup === void 0 ? void 0 : radioGroup.defaultValue) === undefined ? undefined : (radioGroup === null || radioGroup === void 0 ? void 0 : radioGroup.defaultValue) === props.value, checked: (radioGroup === null || radioGroup === void 0 ? void 0 : radioGroup.value) === undefined ? undefined : (radioGroup === null || radioGroup === void 0 ? void 0 : radioGroup.value) === props.value, onChange: (e) => {
                props.onChange && props.onChange(e);
                (radioGroup === null || radioGroup === void 0 ? void 0 : radioGroup.onChange) && radioGroup.onChange(props.value);
            }, required: radioGroup === null || radioGroup === void 0 ? void 0 : radioGroup.required, type: 'radio' }) });
};
