import * as types from 'constants/actionTypes'
import * as urls from 'constants/urls'
import api from 'api'
import { EngagementResponse } from 'declarations/api'
import { sprintf } from 'sprintf-js'

import _ from 'lodash'

export const deleteFollowedEngagement = (followedEngagement: EngagementResponse) => {
  if (_.isNumber(followedEngagement?.id)) {
    return api.call({
      url: sprintf(urls.FOLLOWED_ENGAGEMENTS_DELETE_URL, {
        id: followedEngagement!.id
      }),
      skipFake: true,
      method: 'DELETE',
      context: followedEngagement,
      type: {
        request: types.FOLLOWED_ENGAGEMENTS_DELETE_REQUEST,
        success: types.FOLLOWED_ENGAGEMENTS_DELETE_SUCCESS,
        failure: types.FOLLOWED_ENGAGEMENTS_DELETE_FAILURE
      }
    })
  }
}

export const listFollowedEngagements = () => {
  return api.call({
    url: urls.FOLLOWED_ENGAGEMENTS_LIST_URL,
    skipFake: true,
    cascadeFailureError: true,
    type: {
      request: types.FOLLOWED_ENGAGEMENTS_LIST_REQUEST,
      success: types.FOLLOWED_ENGAGEMENTS_LIST_SUCCESS,
      failure: types.FOLLOWED_ENGAGEMENTS_LIST_FAILURE
    }
  })
}

export const newFollowedEngagement = (engagement: EngagementResponse) => {
  return api.call({
    url: sprintf(urls.FOLLOWED_ENGAGEMENTS_NEW_URL, {
      engagement_id: engagement!.id
    }),
    method: 'POST',
    skipFake: true,
    cascadeFailureError: true,
    context: engagement,
    type: {
      request: types.FOLLOWED_ENGAGEMENTS_NEW_REQUEST,
      success: types.FOLLOWED_ENGAGEMENTS_NEW_SUCCESS,
      failure: types.FOLLOWED_ENGAGEMENTS_NEW_FAILURE
    }
  })
}
