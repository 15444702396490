import { Certificate, ConsultantCertificate, Query } from 'declarations/models'

import TagGroup, { TagGroupMode } from 'components/TagGroup/TagGroup'

import _ from 'lodash'

interface CertificatesGroupProps<Q> {
  query?: Q
  certificates: Array<Certificate> | null | undefined
  setQuery?: (q: Q) => void
  interactive?: boolean
  mode?: TagGroupMode
  visibleRows?: number
}

const CertificatesGroup = <Q extends Partial<Query>>({
  query,
  certificates,
  mode,
  setQuery,
  interactive,
  visibleRows = 1
}: CertificatesGroupProps<Q>) => {
  const handleTagsChanged = (certificateIds: Array<ConsultantCertificate['id']>) => {
    setQuery?.({ certificates: certificateIds } as any)
  }

  return (
    <TagGroup
      size='small'
      tags={certificates}
      mode={mode}
      queryTagIds={
        _.isArray(query?.certificates)
          ? query!.certificates
          : _.isNumber(query?.certificates)
            ? [query!.certificates]
            : []
      }
      interactive={interactive}
      searchWords={query?.search}
      visibleRows={visibleRows}
      setTags={handleTagsChanged}
    />
  )
}

export default CertificatesGroup
