import {
  Button,
  FlexBaseDiv,
  FlexDiv,
  Heading,
  HorizontalSpace,
  Modal,
  Panel,
  PileEndDiv,
  TextField,
  Textarea,
  VerticalSpace
} from '@cegal/ds-components'
import { Note } from 'declarations/models'
import { useAppSelector } from 'store'

import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'

export interface EditNoteModalProps {
  note: Partial<Note> | undefined
  open: boolean
  onClose: () => void
  onNewNote?: (note: Note) => void
  onSaveNote?: (note: Note) => void
}

const NoteModal = ({ note, open, onClose, onNewNote, onSaveNote }: EditNoteModalProps) => {
  const { t } = useTranslation()
  const [editMode, setEditMode] = useState<boolean | undefined>(undefined)
  useEffect(() => {
    reset(note)
    setEditMode(_.isNumber(note?.id))
  }, [note])

  const {
    handleSubmit,
    register,
    reset,
    formState: { isDirty, errors }
  } = useForm<Note>()

  const creating = useAppSelector((state) => state.notes.creating)
  const created = useAppSelector((state) => state.notes.created)
  const saving = useAppSelector((state) => state.notes.saving)
  const saved = useAppSelector((state) => state.notes.saved)

  const onSubmit = (note: Note) => {
    if (editMode && onSaveNote) {
      onSaveNote(note)
    }
    if (!editMode && onNewNote) {
      onNewNote(note)
    }
  }

  useEffect(() => {
    if (editMode && saved !== undefined) {
      onClose()
    }
    if (!editMode && created !== undefined) {
      onClose()
    }
  }, [saved, created, editMode])

  const modalContainerRef = useRef(null)

  return (
    <FlexBaseDiv ref={modalContainerRef}>
      {modalContainerRef?.current && (
        <Modal
          parentSelector={() => modalContainerRef.current!}
          open={open}
          aria-label={t('home:edit-note')}
          closeButton={false}
          onClose={onClose}
          shouldCloseOnOverlayClick={false}
        >
          <Modal.Content>
            <Panel style={{ minWidth: '600px' }}>
              <Heading spacing level='2' size='medium'>
                {t(!editMode ? 'buttons:add-new-x' : 'buttons:edit-x', {
                  x: t('label:match-note').toLowerCase()
                })}
              </Heading>
              <TextField
                data-cy='noteTextArea'
                {...register('title')}
                label={t('label:note-title')}
                error={errors?.title?.message}
                hideLabel
              />
              <VerticalSpace size='2' />
              <Textarea
                data-cy='noteTextArea'
                resize
                rows={6}
                {...register('body', {
                  required: t('validation:note-no-text-details').toString()
                })}
                label={t('label:notes-description')}
                hideLabel
                error={errors?.body?.message}
              />
              <VerticalSpace size='2' />
              <PileEndDiv>
                <FlexDiv>
                  <Button
                    variant='secondary'
                    onClick={() => {
                      if (!isDirty) {
                        onClose()
                      } else {
                        if (window.confirm(t('messages:confirm-close-note')!)) {
                          onClose()
                        }
                      }
                    }}
                  >
                    {t('buttons:close')}
                  </Button>
                  <HorizontalSpace />
                  <Button
                    loading={saving || creating}
                    disabled={saving || creating}
                    onClick={handleSubmit(onSubmit)}
                  >
                    {editMode
                      ? saving
                        ? t('buttons:saving')
                        : t('buttons:save')
                      : creating
                        ? t('buttons:adding')
                        : t('buttons:add')}
                  </Button>
                </FlexDiv>
              </PileEndDiv>
            </Panel>
          </Modal.Content>
        </Modal>
      )}
    </FlexBaseDiv>
  )
}

export default NoteModal
