import { nb } from 'date-fns/locale'

import { useEffect, useMemo, useState } from 'react'

// const delayed: boolean = false

const useConsultantAllocationChartConfig = ({
  type,
  showChartLabel,
  showXAxisLabel,
  showYAxisLabel,
  chartLabel,
  dataset
}: any) => {
  const [_type, setType] = useState(type)
  const [_dataset, setDataset] = useState(dataset)

  useEffect(() => {
    if (type !== _type) {
      setType(type)
    }
  }, [type])

  useEffect(() => {
    setDataset(dataset)
  }, [dataset])

  const plugins: any = [
    {
      id: 'backgrounds',
      beforeDraw: (chart: any, args: any, options: any) => {
        const {
          ctx,
          chartArea,
          scales: { y }
        } = chart
        options.hbars.forEach((hBar: any) => {
          ctx.save()
          ctx.fillStyle = hBar.color
          ctx.fillRect(
            chartArea.left,
            y.getPixelForValue(hBar.from),
            chartArea.right - chartArea.left,
            y.getPixelForValue(hBar.to) - y.getPixelForValue(hBar.from)
          )
          ctx.restore()
        })
      }
    }
  ]

  const options: any = useMemo(
    () => ({
      type: _type,
      responsive: true,
      animation: false,
      plugins: {
        tooltip: {
          callbacks: {
            label: (value: any): string => {
              return value.raw.tooltip ?? `${value.formattedValue}%`
            }
          }
        },
        legend: {
          position: 'top' as const,
          display: showChartLabel
        },
        title: {
          display: false,
          text: ''
        },
        backgrounds: {
          hbars: [
            {
              from: 50,
              to: 100,
              color: 'rgba(230, 195, 195, 0.3)'
            },
            {
              from: 25,
              to: 50,
              color: 'rgba(230, 220, 195, 0.3)'
            },
            {
              from: 0,
              to: 25,
              color: 'rgba(195, 230, 195,0.3)'
            }
          ]
        }
      },
      scales: {
        y: {
          border: {
            display: false
          },
          grid: {
            lineWidth: 0,
            drawBorder: false,
            display: false,
            drawOnChartArea: false
          },

          suggestedMin: 0,
          suggestedMax: 100,
          ticks: {
            font: {
              size: 10
            },
            display: showYAxisLabel,
            callback: (value: string | number): string => {
              return `${value}%`
            }
          }
        },
        x: {
          adapters: {
            date: { locale: nb },
            type: 'time',
            distribution: 'linear',
            time: {
              parser: 'MM-dd',
              unit: 'month'
            },
            title: {
              display: true,
              text: 'Date'
            }
          },
          grid: {
            lineWidth: 0,
            drawBorder: false,
            display: false,
            drawOnChartArea: false
          },
          ticks: {
            font: {
              size: 10
            },
            display: showXAxisLabel
          }
        }
      }
    }),
    [showChartLabel, showXAxisLabel, showYAxisLabel]
  )

  // @ts-ignore
  const data: any = useMemo(() => {
    if (_type === 'line') {
      return {
        datasets: [
          {
            label: `${chartLabel}`,
            data: _dataset,
            fill: true,
            showLine: true,
            borderColor: 'blue',
            backgroundColor: 'lightblue',
            strokeColor: 'blue',
            cubicInterpolationMode: 'monotone',
            tension: 0.4
          }
        ]
      }
    }
    return {
      datasets: [
        {
          label: `${chartLabel}`,
          data: _dataset,
          backgroundColor: (data: any): any => {
            const perc = data?.raw?.y
            if (perc <= 25) return 'green'
            if (perc <= 70) return 'yellow'
            return 'red'
          },
          cubicInterpolationMode: 'monotone',
          tension: 0.4
        }
      ]
    }
  }, [chartLabel, _dataset, _type])

  return { plugins, data, options }
}

export default useConsultantAllocationChartConfig
