import { BodyShort, Button, Dropdown, HorizontalSpace, Switch } from '@cegal/ds-components'
import { Settings } from '@cegal/ds-icons'

import React from 'react'

import { t } from 'i18next'

export type FacetedFilterDropdownProps = {
  onChange: any
  checked: boolean | undefined
  switchText: string | undefined
}

const FacetedFilterDropdown: React.FC<FacetedFilterDropdownProps> = ({ onChange, checked, switchText }) => {
  return (
    <Dropdown closeOnSelect={false}>
      <Button as={Dropdown.Toggle} size='small' variant='tertiary' style={{ padding: '3px' }}>
        <Settings size='1.5rem' />
      </Button>
      <Dropdown.Menu>
        <Dropdown.Menu.List>
          <Dropdown.Menu.List.Item>
            <BodyShort>{t('label:filter-some')}</BodyShort>
            <Switch hideLabel position='left' size='small' onChange={onChange} checked={checked}>
              {switchText}
            </Switch>
            <HorizontalSpace size='2.5' />
            <BodyShort>{t('label:filter-all')}</BodyShort>
          </Dropdown.Menu.List.Item>
        </Dropdown.Menu.List>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default FacetedFilterDropdown
