import { BodyShort, Button, FlexCenterSpacedDiv } from '@cegal/ds-components'
import { ChevronLeft } from '@cegal/ds-icons/dist/ChevronLeft'
import { ChevronRight } from '@cegal/ds-icons/dist/ChevronRight'
import { TableHeader } from 'declarations/app'

import { Draggable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'

interface DraggableHeaderProps {
  header: TableHeader
  index: number

  role?: 'left' | 'right'

  onClick?: (index: number) => void
}

const DraggableHeader = ({ header, index, role, onClick }: DraggableHeaderProps) => {
  const { t } = useTranslation()

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    userSelect: 'none',
    padding: '0.7rem',
    margin: '0 0 0.35rem 0',
    border: '1px solid var(--cds-border-color)',
    background: isDragging
      ? 'var(--cds-background-color-alternative-medium-opaque)'
      : 'var(--cds-background-color)',
    ...draggableStyle
  })

  return (
    <Draggable key={header.value} draggableId={header.value} index={index}>
      {(provided: any, snapshot: any) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
          <FlexCenterSpacedDiv>
            {role === 'left' && (
              <Button size='small' icon={<ChevronLeft size='1.2rem' />} onClick={() => onClick?.(index)} />
            )}
            <BodyShort>{t(header.label)}</BodyShort>
            {role === 'right' && (
              <Button size='small' icon={<ChevronRight size='1.2rem' />} onClick={() => onClick?.(index)} />
            )}
          </FlexCenterSpacedDiv>
        </div>
      )}
    </Draggable>
  )
}

export default DraggableHeader
