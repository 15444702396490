import * as types from 'constants/actionTypes'
import * as urls from 'constants/urls'
import api from 'api'
import { CacheDomain } from 'reducers/cache'
import { sprintf } from 'sprintf-js'

export const setCache = (domain: CacheDomain, key: string, value: string) => ({
  type: types.CACHE_SET,
  payload: {
    domain,
    key,
    value
  }
})

export const getClientAvatar = (id: number) => {
  return api.call({
    url: sprintf(urls.CLIENTS_AVATAR_URL, { id: id.toString() }),
    skipFake: true,
    cascadeFailureError: true,
    context: {
      key: id.toString()
    },
    type: {
      request: types.CLIENTS_AVATAR_REQUEST,
      success: types.CLIENTS_AVATAR_SUCCESS,
      failure: types.CLIENTS_AVATAR_FAILURE
    }
  })
}

export const getConsultantAvatar = (id: number) => {
  return api.call({
    url: sprintf(urls.CONSULTANTS_AVATAR_URL, { id: id.toString() }),
    skipFake: true,
    cascadeFailureError: true,
    context: {
      key: id.toString()
    },
    type: {
      request: types.CONSULTANTS_AVATAR_REQUEST,
      success: types.CONSULTANTS_AVATAR_SUCCESS,
      failure: types.CONSULTANTS_AVATAR_FAILURE
    }
  })
}
