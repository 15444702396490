import * as types from 'constants/actionTypes'
import * as urls from 'constants/urls'
import api from 'api'
import {
  EngagementRequest,
  EngagementVacancyConsultantRequest,
  EngagementVacancyRequest
} from 'declarations/api'
import {
  Certificate,
  Engagement,
  EngagementVacancy,
  EngagementVacancyConsultant,
  ObjectWithId,
  SimpleClient,
  Skill
} from 'declarations/models'
import { sprintf } from 'sprintf-js'

import _ from 'lodash'

export const deleteEngagement = (engagement: Engagement) => {
  return api.call({
    url: sprintf(urls.ENGAGEMENTS_DELETE_URL, { engagement_id: engagement.id }),
    method: 'DELETE',
    cascadeFailureError: true,
    skipFake: true,
    context: {
      engagement
    },
    type: {
      request: types.ENGAGEMENTS_DELETE_REQUEST,
      success: types.ENGAGEMENTS_DELETE_SUCCESS,
      failure: types.ENGAGEMENTS_DELETE_FAILURE
    }
  })
}

export const deleteEngagementVacancy = (vacancy: EngagementVacancy, engagement_id: number) => {
  return api.call({
    url: sprintf(urls.ENGAGEMENTS_VACANCIES_DELETE_URL, { vacancy_id: vacancy.id }),
    method: 'DELETE',
    cascadeFailureError: true,
    skipFake: true,
    context: {
      vacancy,
      engagement_id
    },
    type: {
      request: types.ENGAGEMENTS_VACANCIES_DELETE_REQUEST,
      success: types.ENGAGEMENTS_VACANCIES_DELETE_SUCCESS,
      failure: types.ENGAGEMENTS_VACANCIES_DELETE_FAILURE
    }
  })
}

export const deleteEngagementVacancyConsultant = (evc: EngagementVacancyConsultant, vacancy_id: number) => {
  return api.call({
    url: sprintf(urls.ENGAGEMENTS_VACANCIES_CONSULTANTS_DELETE_URL, {
      vacancy_id,
      id: evc.consultant!.id
    }),
    method: 'DELETE',
    cascadeFailureError: true,
    skipFake: true,
    context: {
      evc,
      vacancy_id
    },
    type: {
      request: types.ENGAGEMENTS_VACANCIES_CONSULTANTS_DELETE_REQUEST,
      success: types.ENGAGEMENTS_VACANCIES_CONSULTANTS_DELETE_SUCCESS,
      failure: types.ENGAGEMENTS_VACANCIES_CONSULTANTS_DELETE_FAILURE
    }
  })
}

export const editEngagement = (engagement: Engagement) => {
  const payload: any = {
    ..._.omit(engagement, [
      'id',
      'source',
      'last_modified_date',
      'last_modifier',
      'posted_date',
      'creation_date',
      'creator',
      'sync_date'
    ]),
    clients: engagement.clients?.map((c: SimpleClient) => ({ id: c.id })) as Array<ObjectWithId>,
    source_id: engagement.source?.id as number,
    vacancies: engagement.vacancies?.map((v: EngagementVacancy) => ({
      allocation: v.allocation,
      consultants: v.consultants?.map((c: EngagementVacancyConsultant) => ({
        ..._.omit(c, 'consultant', 'last_modifier', 'last_modified_date'),
        id: c.consultant?.id
      })),
      hourly_rate: v.hourly_rate,
      skills: v.skills.map((s: Skill) => ({
        id: s.id
      })) as Array<ObjectWithId>,
      title: v.title
    }))
  }
  return api.call({
    url: sprintf(urls.ENGAGEMENTS_EDIT_URL, { engagement_id: engagement.id }),
    method: 'PUT',
    skipFake: true,
    body: payload,
    cascadeFailureError: true,
    type: {
      request: types.ENGAGEMENTS_EDIT_REQUEST,
      success: types.ENGAGEMENTS_EDIT_SUCCESS,
      failure: types.ENGAGEMENTS_EDIT_FAILURE
    }
  })
}

export const editEngagementVacancy = (vacancy: EngagementVacancy, engagement_id: number) => {
  const payload: EngagementVacancyRequest = {
    allocation: vacancy.allocation,
    consultants:
      /* istanbul ignore next */
      vacancy.consultants?.map((c: EngagementVacancyConsultant) => ({
        ..._.omit(c, 'consultant'),
        id: c.consultant!.id!
      })) ?? [],
    hourly_rate: vacancy.hourly_rate,
    certificates: vacancy?.certificates?.map((c) => ({
      id: c.id
    })) as Array<ObjectWithId>,
    skills: vacancy.skills.map((s: Skill) => ({
      id: s.id
    })) as Array<ObjectWithId>,
    title: vacancy.title,
    id: vacancy?.id
  }

  return api.call({
    url: sprintf(urls.ENGAGEMENTS_VACANCIES_EDIT_URL, { vacancy_id: vacancy.id }),
    method: 'PUT',
    skipFake: true,
    body: payload,
    cascadeFailureError: true,
    context: {
      engagement_id,
      vacancy
    },
    type: {
      request: types.ENGAGEMENTS_VACANCIES_EDIT_REQUEST,
      success: types.ENGAGEMENTS_VACANCIES_EDIT_SUCCESS,
      failure: types.ENGAGEMENTS_VACANCIES_EDIT_FAILURE
    }
  })
}

export const editEngagementVacancyConsultant = (
  evc: EngagementVacancyConsultant,
  id: number, // use this in url, as evc.consultant.id may have changed
  vacancy_id: number
) => {
  const payload: EngagementVacancyConsultantRequest = {
    ..._.omit(evc, 'consultant', 'last_modifier', 'last_modified_date'),
    id: evc.consultant!.id!
  }
  return api.call({
    url: sprintf(urls.ENGAGEMENTS_VACANCIES_CONSULTANTS_EDIT_URL, { vacancy_id, id }),
    method: 'PUT',
    skipFake: true,
    body: payload,
    cascadeFailureError: true,
    context: {
      vacancy_id,
      id
    },
    type: {
      request: types.ENGAGEMENTS_VACANCIES_CONSULTANTS_EDIT_REQUEST,
      success: types.ENGAGEMENTS_VACANCIES_CONSULTANTS_EDIT_SUCCESS,
      failure: types.ENGAGEMENTS_VACANCIES_CONSULTANTS_EDIT_FAILURE
    }
  })
}

export const getEngagement = (engagement_id: number) => {
  return api.call({
    url: sprintf(urls.ENGAGEMENTS_GET_URL, { engagement_id }),
    skipFake: true,
    cascadeFailureError: true,
    type: {
      request: types.ENGAGEMENTS_GET_REQUEST,
      success: types.ENGAGEMENTS_GET_SUCCESS,
      failure: types.ENGAGEMENTS_GET_FAILURE
    }
  })
}

export const getEngagementVacancy = (vacancy_id: number) => {
  return api.call({
    url: sprintf(urls.ENGAGEMENTS_VACANCIES_GET_URL, { vacancy_id }),
    skipFake: true,
    cascadeFailureError: true,
    type: {
      request: types.ENGAGEMENTS_VACANCIES_GET_REQUEST,
      success: types.ENGAGEMENTS_VACANCIES_GET_SUCCESS,
      failure: types.ENGAGEMENTS_VACANCIES_GET_FAILURE
    }
  })
}

export const getEngagementVacancyConsultant = (vacancy_id: number, evc_consultant_id: number) => {
  return api.call({
    url: sprintf(urls.ENGAGEMENTS_VACANCIES_CONSULTANTS_GET_URL, {
      vacancy_id,
      id: evc_consultant_id
    }),
    skipFake: true,
    cascadeFailureError: true,
    type: {
      request: types.ENGAGEMENTS_VACANCIES_CONSULTANTS_GET_REQUEST,
      success: types.ENGAGEMENTS_VACANCIES_CONSULTANTS_GET_SUCCESS,
      failure: types.ENGAGEMENTS_VACANCIES_CONSULTANTS_GET_FAILURE
    }
  })
}

export const listEngagements = () => {
  return api.call({
    url: urls.ENGAGEMENTS_LIST_URL,
    skipFake: true,
    cascadeFailureError: true,
    type: {
      request: types.ENGAGEMENTS_LIST_REQUEST,
      success: types.ENGAGEMENTS_LIST_SUCCESS,
      failure: types.ENGAGEMENTS_LIST_FAILURE
    }
  })
}

export const listEngagementVacancies = (engagement_id: number) => {
  return api.call({
    url: sprintf(urls.ENGAGEMENTS_VACANCIES_LIST_URL, { engagement_id }),
    skipFake: true,
    cascadeFailureError: true,
    type: {
      request: types.ENGAGEMENTS_VACANCIES_LIST_REQUEST,
      success: types.ENGAGEMENTS_VACANCIES_LIST_SUCCESS,
      failure: types.ENGAGEMENTS_VACANCIES_LIST_FAILURE
    }
  })
}

export const listEngagementVacancyConsultants = (vacancy_id: number) => {
  return api.call({
    url: sprintf(urls.ENGAGEMENTS_VACANCIES_CONSULTANTS_LIST_URL, { vacancy_id }),
    skipFake: true,
    cascadeFailureError: true,
    type: {
      request: types.ENGAGEMENTS_VACANCIES_CONSULTANTS_LIST_REQUEST,
      success: types.ENGAGEMENTS_VACANCIES_CONSULTANTS_LIST_SUCCESS,
      failure: types.ENGAGEMENTS_VACANCIES_CONSULTANTS_LIST_FAILURE
    }
  })
}

export const newEngagement = (engagement: Omit<Engagement, 'id'>) => {
  const payload: EngagementRequest = {
    ..._.omit(engagement, ['source', 'creator']),
    clients: engagement.clients?.map((c: SimpleClient) => ({ id: c.id })) as Array<ObjectWithId>,
    source_id: engagement.source?.id,
    vacancies: engagement.vacancies?.map((v: EngagementVacancy) => ({
      allocation: v.allocation,
      consultants:
        /* istanbul ignore next */
        v.consultants?.map((c: EngagementVacancyConsultant) => ({
          ..._.omit(c, 'consultant'),
          id: c.consultant!.id!
        })) ?? [],
      hourly_rate: v.hourly_rate,
      certificates: v?.certificates?.map((c: Certificate) => ({
        id: c.id
      })) as Array<ObjectWithId>,
      skills: v.skills.map((s: Skill) => ({
        id: s.id
      })) as Array<ObjectWithId>,
      title: v.title
    }))
  }

  return api.call({
    url: urls.ENGAGEMENTS_NEW_URL,
    method: 'POST',
    skipFake: true,
    body: payload,
    cascadeFailureError: true,
    type: {
      request: types.ENGAGEMENTS_NEW_REQUEST,
      success: types.ENGAGEMENTS_NEW_SUCCESS,
      failure: types.ENGAGEMENTS_NEW_FAILURE
    }
  })
}

export const newEngagementVacancy = (vacancy: EngagementVacancy, engagement_id: number) => {
  const payload: EngagementVacancyRequest = {
    allocation: vacancy.allocation,
    consultants:
      /* istanbul ignore next */
      vacancy.consultants?.map((c: EngagementVacancyConsultant) => ({
        ..._.omit(c, 'consultant'),
        id: c.consultant!.id!
      })) ?? [],
    hourly_rate: vacancy.hourly_rate,
    certificates: vacancy?.certificates?.map((c) => ({
      id: c.id
    })) as Array<ObjectWithId>,
    skills: vacancy.skills.map((s: Skill) => ({
      id: s.id
    })) as Array<ObjectWithId>,
    title: vacancy.title
  }
  return api.call({
    url: sprintf(urls.ENGAGEMENTS_VACANCIES_NEW_URL, { engagement_id }),
    method: 'POST',
    skipFake: true,
    body: payload,
    cascadeFailureError: true,
    context: {
      engagement_id
    },
    type: {
      request: types.ENGAGEMENTS_VACANCIES_NEW_REQUEST,
      success: types.ENGAGEMENTS_VACANCIES_NEW_SUCCESS,
      failure: types.ENGAGEMENTS_VACANCIES_NEW_FAILURE
    }
  })
}

export const newEngagementVacancyConsultant = (
  evc: Partial<EngagementVacancyConsultantRequest>,
  vacancy_id: number
) => {
  // remove empty fields, the consultant object
  const sanitizedNewConsultant: Partial<EngagementVacancyConsultantRequest> = _.pickBy(evc, (value) => {
    if (_.isString(value)) {
      return value.length > 0
    }
    if (_.isNil(value)) {
      return false
    }
    if (_.isObject(value)) {
      return false
    }
    return true
  }) as EngagementVacancyConsultant

  return api.call({
    url: sprintf(urls.ENGAGEMENTS_VACANCIES_CONSULTANTS_NEW_URL, { vacancy_id }),
    method: 'POST',
    skipFake: true,
    body: sanitizedNewConsultant,
    cascadeFailureError: true,
    context: {
      vacancy_id
    },
    type: {
      request: types.ENGAGEMENTS_VACANCIES_CONSULTANTS_NEW_REQUEST,
      success: types.ENGAGEMENTS_VACANCIES_CONSULTANTS_NEW_SUCCESS,
      failure: types.ENGAGEMENTS_VACANCIES_CONSULTANTS_NEW_FAILURE
    }
  })
}

export const resetEngagement = () => ({
  type: types.ENGAGEMENTS_RESET
})

export const resetEngagementVacancy = () => ({
  type: types.ENGAGEMENTS_VACANCIES_RESET
})

export const resetEngagementVacancyConsultant = () => ({
  type: types.ENGAGEMENTS_VACANCIES_CONSULTANTS_RESET
})

export const setEngagement = (engagement: Engagement | undefined) => {
  return {
    type: types.ENGAGEMENTS_ENGAGEMENT_SET,
    payload: engagement
  }
}

export const moveCreatedEngagementToCurrent = () => ({
  type: types.ENGAGEMENTS_CREATED_MOVE
})

export const moveSavedEngagementToCurrent = () => ({
  type: types.ENGAGEMENTS_SAVED_MOVE
})
