import * as types from 'constants/actionTypes'
import * as urls from 'constants/urls'
import api from 'api'
import { EngagementVacancyConsultantRequest } from 'declarations/api'
import { EngagementVacancyConsultant } from 'declarations/models'
import { sprintf } from 'sprintf-js'

import _ from 'lodash'

export const deleteMatch = (evc: Partial<EngagementVacancyConsultant>) => {
  return api.call({
    url: sprintf(urls.MATCHES_DELETE_URL, {
      id: evc.consultant!.id!,
      vacancy_id: evc.engagement_vacancy?.id
    }),
    skipFake: true,
    method: 'DELETE',
    cascadeFailureError: true,
    context: evc,
    type: {
      request: types.MATCHES_DELETE_REQUEST,
      success: types.MATCHES_DELETE_SUCCESS,
      failure: types.MATCHES_DELETE_FAILURE
    }
  })
}

export const editMatch = (
  match: EngagementVacancyConsultant,
  id: number, // use this in url, as match.consultant.id may have changed
  vacancy_id: number
) => {
  const payload: Partial<EngagementVacancyConsultantRequest> = {
    ..._.omit(match, 'consultant', 'last_modifier', 'last_modified_date'),
    id: match.consultant?.id as number
  }

  return api.call({
    url: sprintf(urls.MATCHES_EDIT_URL, { id, vacancy_id }),
    method: 'PUT',
    cascadeFailureError: true,
    body: payload,
    skipFake: true,
    context: match,
    type: {
      request: types.MATCHES_EDIT_REQUEST,
      success: types.MATCHES_EDIT_SUCCESS,
      failure: types.MATCHES_EDIT_FAILURE
    }
  })
}

export const listMatches = () => {
  return api.call({
    url: urls.MATCHES_LIST_URL,
    cascadeFailureError: true,
    skipFake: true,
    type: {
      request: types.MATCHES_LIST_REQUEST,
      success: types.MATCHES_LIST_SUCCESS,
      failure: types.MATCHES_LIST_FAILURE
    }
  })
}

export const newMatch = (match: Partial<EngagementVacancyConsultantRequest>) => {
  const sanitizedMatch: EngagementVacancyConsultantRequest = _.pickBy(match, (value) => {
    if (_.isString(value)) {
      return value.length > 0
    }
    if (_.isNil(value)) {
      return false
    }
    if (_.isObject(value)) {
      return false
    }
    return true
  }) as EngagementVacancyConsultantRequest
  delete sanitizedMatch.consultant
  sanitizedMatch.id = match.consultant!.id!

  return api.call({
    url: sprintf(urls.MATCHES_NEW_URL, { vacancy_id: match.engagement_vacancy!.id }),
    method: 'POST',
    body: sanitizedMatch,
    skipFake: true,
    cascadeFailureError: true,
    context: match,
    type: {
      request: types.MATCHES_NEW_REQUEST,
      success: types.MATCHES_NEW_SUCCESS,
      failure: types.MATCHES_NEW_FAILURE
    }
  })
}

export const resetMatch = () => ({
  type: types.MATCHES_RESET
})
