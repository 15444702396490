import { Engagement } from 'declarations/models'

export default {
  id: 1,
  description: 'We need a resource management tool',
  clients: [
    {
      id: 1,
      name: 'Cegal AS'
    },
    {
      id: 2,
      name: 'Other clients'
    },
    {
      id: 3,
      name: 'CIP project'
    }
  ],
  title: 'Resource Management tool',
  posted_date: '2020-08-01',
  creator: {
    id: 1,
    name: 'Ola Nordmann',
    email: 'ola.nordmann@cegal.com',
    image_href: 'https://picsum.photos/100'
  },
  start_date: '2002-08-01',
  end_date: '2022-12-31',
  deadline: '2023-08-08',
  optional_extension_period: 6,
  location: 'Oslo',
  status: 'In progress',
  file: `[{"size":441002,"name":"Doc1.pdf","mimetype":"application/pdf","lastModified":1677756714000,"content":"data:application/pdf;base64" }]`,
  notes: 'This is an internal app extended from a summer project',
  last_modified_date: '2022-09-09',
  last_modifier: {
    id: 1,
    name: 'Ola Nordmann',
    email: 'ola.nordmann@cegal.com',
    image_href: 'https://picsum.photos/100'
  },
  energy_domain: false,
  integration: true,
  relevant_technologies: false,
  mentoring: true,
  reusable_database: false,
  financially_smart: false,
  vacancies: [
    {
      id: 1,
      hourly_rate: 1000,
      allocation: 'full-time',
      certificates: [
        {
          name: '70-778: Analyzing and Visualizing Data with Power BI',
          certificate: 'Microsoft NoName Cert',
          category: 'Microsoft Certified Systems Engineer',
          cloud: false,

          id: 1
        },
        {
          name: 'Administering Office 365 for Small Business Specialist',
          certificate: 'Microsoft NoName Cert',
          category: 'Microsoft Certified Systems Engineer',
          cloud: false,

          id: 2
        }
      ],
      title: 'Frontend development',
      last_modifier: {
        id: 1,
        name: 'Ola Nordmann',
        email: 'ola.nordmann@cegal.com',
        image_href: 'https://picsum.photos/100'
      },
      last_modified_date: '2022-01-01',
      skills: [
        {
          id: 1,
          name: 'React'
        },
        {
          id: 2,
          name: 'Node'
        },
        {
          id: 3,
          name: 'TypeScript'
        },
        {
          id: 4,
          name: 'Git'
        },
        {
          id: 5,
          name: 'Google Cloud'
        },
        {
          id: 6,
          name: 'Github actions'
        }
      ],
      consultants: [
        {
          start_date: '2020-08-01',
          end_date: '',
          hourly_rate: 1000,
          status: 'assigned',
          notes: '',
          consultant: {
            id: 1,
            name: 'Nuno Cardoso',
            email: 'nuno.cardoso@cegal.com',
            image_href: 'https://picsum.photos/100'
          }
        },
        {
          start_date: '2020-08-01',
          end_date: '',
          hourly_rate: 1000,
          status: 'assigned',
          notes: '',
          consultant: {
            id: 2,
            name: 'Faisal Ahmed ',
            email: 'faisal.ahmed@cegal.com',
            image_href: 'https://picsum.photos/100'
          }
        },
        {
          start_date: '2020-08-01',
          end_date: '',
          hourly_rate: 1000,
          status: 'assigned',
          notes: '',
          consultant: {
            id: 3,
            name: 'Soroush Ghodrat',
            email: 'soroush.godrat@cegal.com',
            image_href: 'https://picsum.photos/100'
          }
        },
        {
          start_date: '2020-08-01',
          end_date: '',
          hourly_rate: 1000,
          status: 'assigned',
          notes: '',
          consultant: {
            id: 4,
            name: 'Vitalijus Zudenkovas',
            email: 'vitalijus.zudenkovas@cegal.com',
            image_href: 'https://picsum.photos/100'
          }
        },
        {
          start_date: '2020-08-01',
          end_date: '',
          hourly_rate: 1000,
          status: 'assigned',
          notes: '',
          consultant: {
            id: 5,
            name: 'Sarah Reyfuse',
            email: 'sarah.reyfuse@cegal.com',
            image_href: 'https://picsum.photos/100'
          }
        }
      ]
    },
    {
      id: 2,
      hourly_rate: 1000,
      allocation: 'full-time',
      certificates: [
        {
          name: '70-778: Analyzing and Visualizing Data with Power BI',
          certificate: 'Microsoft NoName Cert',
          category: 'Microsoft Certified Systems Engineer',
          cloud: false,

          id: 1
        },
        {
          name: 'Administering Office 365 for Small Business Specialist',
          certificate: 'Microsoft NoName Cert',
          category: 'Microsoft Certified Systems Engineer',
          cloud: false,

          id: 2
        }
      ],
      title: 'Backend development',
      skills: [
        {
          id: 10,
          name: 'GoLang'
        },
        {
          id: 11,
          name: 'SQL'
        },
        {
          id: 12,
          name: 'REST'
        },
        {
          id: 13,
          name: 'Google Cloud'
        },
        {
          id: 14,
          name: 'Github actions'
        },
        {
          id: 15,
          name: 'PostgreSQL'
        },
        {
          id: 16,
          name: 'bash'
        }
      ],
      last_modifier: {
        id: 1,
        name: 'Ola Nordmann',
        email: 'ola.nordmann@cegal.com',
        image_href: 'https://picsum.photos/100'
      },
      last_modified_date: '2022-01-01',
      consultants: [
        {
          start_date: '2020-08-01',
          end_date: '',
          hourly_rate: 1000,
          status: 'assigned',
          notes: '',
          consultant: {
            id: 10,
            name: 'Agnija Bako',
            email: 'agnija.bako@cegal.com',
            image_href: 'https://picsum.photos/100'
          }
        },
        {
          start_date: '2020-08-01',
          end_date: '',
          hourly_rate: 100,
          status: 'assigned',
          notes: '',
          consultant: {
            id: 11,
            name: 'Nikola Jokanovic',
            email: 'nikola.jokanovic@cegal.com',
            image_href: 'https://picsum.photos/100'
          }
        },
        {
          start_date: '2020-08-01',
          end_date: '',
          hourly_rate: 100,
          status: 'assigned',
          notes: '',
          consultant: {
            id: 12,
            name: 'Magnus Andreas Holmen',
            email: 'magnus.andreas.holmen@cegal.com',
            image_href: 'https://picsum.photos/100'
          }
        },
        {
          start_date: '2020-08-01',
          end_date: '',
          hourly_rate: 100,
          status: 'assigned',
          notes: '',
          consultant: {
            id: 12,
            name: 'Martin Thomas Candor',
            email: 'martin.thomas.candor@cegal.com',
            image_href: 'https://picsum.photos/100'
          }
        },
        {
          start_date: '2020-08-01',
          end_date: '',
          hourly_rate: 100,
          status: 'assigned',
          notes: '',
          consultant: {
            id: 12,
            name: 'Johanne Holst Klaæboe',
            email: 'johanne.klaeboe@cegal.com',
            image_href: 'https://picsum.photos/100'
          }
        },
        {
          start_date: '2020-08-01',
          end_date: '',
          hourly_rate: 100,
          status: 'assigned',
          notes: '',
          consultant: {
            id: 12,
            name: 'Melanie Østbøll',
            email: 'melanie.østbøll@cegal.com',
            image_href: 'https://picsum.photos/100'
          }
        },
        {
          start_date: '2020-08-01',
          end_date: '',
          hourly_rate: 100,
          status: 'assigned',
          notes: '',
          consultant: {
            id: 12,
            name: 'Ganesh Gurudu',
            email: 'ganesh.gurudu@cegal.com',
            image_href: 'https://picsum.photos/100'
          }
        },
        {
          start_date: '2020-08-01',
          end_date: '',
          hourly_rate: 100,
          status: 'assigned',
          notes: '',
          consultant: {
            id: 12,
            name: 'Ioan-Vlad Dragomir',
            email: 'ioan-vlad.dragomir@cegal.com',
            image_href: 'https://picsum.photos/100'
          }
        }
      ]
    },
    {
      id: 3,
      hourly_rate: 1000,
      allocation: 'full-time',
      certificates: [
        {
          name: '70-778: Analyzing and Visualizing Data with Power BI',
          certificate: 'Microsoft NoName Cert',
          category: 'Microsoft Certified Systems Engineer',
          cloud: false,

          id: 1
        },
        {
          name: 'Administering Office 365 for Small Business Specialist',
          certificate: 'Microsoft NoName Cert',
          category: 'Microsoft Certified Systems Engineer',
          cloud: false,

          id: 2
        }
      ],
      title: 'UX/UI design',
      last_modifier: {
        id: 1,
        name: 'Ola Nordmann',
        email: 'ola.nordmann@cegal.com',
        image_href: 'https://picsum.photos/100'
      },
      last_modified_date: '2022-01-01',
      skills: [
        {
          id: 21,
          name: 'Figma'
        },
        {
          id: 22,
          name: 'React'
        },
        {
          id: 23,
          name: 'CSS'
        }
      ],
      consultants: [
        {
          start_date: '2020-08-01',
          end_date: '',
          hourly_rate: 1000,
          status: 'assigned',
          notes: '',
          consultant: {
            id: 1,
            name: 'Nuno Cardoso',
            email: 'nuno.cardoso@cegal.com',
            image_href: 'https://picsum.photos/100'
          }
        },
        {
          start_date: '2020-08-01',
          end_date: '',
          hourly_rate: 1000,
          status: 'assigned',
          notes: '',
          consultant: {
            id: 1,
            name: 'Sarah Reyfuse',
            email: 'sarah.reyfuse@cegal.com',
            image_href: 'https://picsum.photos/100'
          }
        }
      ]
    },
    {
      id: 4,
      hourly_rate: 1000,
      allocation: 'full-time',
      certificates: [
        {
          name: '70-778: Analyzing and Visualizing Data with Power BI',
          certificate: 'Microsoft NoName Cert',
          category: 'Microsoft Certified Systems Engineer',
          cloud: false,

          id: 1
        },
        {
          name: 'Administering Office 365 for Small Business Specialist',
          certificate: 'Microsoft NoName Cert',
          category: 'Microsoft Certified Systems Engineer',
          cloud: false,

          id: 2
        }
      ],
      title: 'Project manager',
      last_modifier: {
        id: 1,
        name: 'Ola Nordmann',
        email: 'ola.nordmann@cegal.com',
        image_href: 'https://picsum.photos/100'
      },
      last_modified_date: '2022-01-01',
      skills: [
        {
          id: 31,
          name: 'Project management'
        }
      ],
      consultants: [
        {
          start_date: '2020-08-01',
          end_date: '',
          hourly_rate: 1000,
          status: 'assigned',
          notes: '',
          consultant: {
            id: 1,
            name: 'Nuno Cardoso',
            email: 'nuno.cardoso@cegal.com',
            image_href: 'https://picsum.photos/100'
          }
        }
      ]
    }
  ]
} as Engagement
