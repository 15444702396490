var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef, useContext } from 'react';
import cl from 'clsx';
import { Popover } from 'components/popover';
import List from './List';
import GroupedList from './GroupedList';
import Divider from './Divider';
import { DropdownContext } from 'components/dropdown';
export const Menu = forwardRef((_a, ref) => {
    var { className, onClose, placement = 'bottom-end' } = _a, rest = __rest(_a, ["className", "onClose", "placement"]);
    const context = useContext(DropdownContext);
    if (!context) {
        console.warn('Dropdown.Menu has to be wrapped in <Dropdown />');
        return null;
    }
    const { isOpen, anchorEl, setIsOpen } = context;
    return (React.createElement(Popover, Object.assign({}, rest, { ref: ref, placement: placement, arrow: false, variant: 'secondary', className: cl(className, 'cds-dropdown__menu'), offset: -4, anchorEl: anchorEl, open: isOpen, onClose: () => {
            setIsOpen(false);
            onClose && onClose();
        } })));
});
Menu.List = List;
Menu.GroupedList = GroupedList;
Menu.Divider = Divider;
Menu.displayName = 'Dropdown.Menu';
export default Menu;
