import * as types from 'constants/actionTypes'
import * as urls from 'constants/urls'
import api from 'api'
import { DepartmentRequest, DepartmentResponse } from 'declarations/api'
import { sprintf } from 'sprintf-js'

import _ from 'lodash'

export const deleteDepartment = (department: DepartmentResponse) => {
  return api.call({
    url: sprintf(urls.DEPARTMENTS_DELETE_URL, { id: department.id }),
    method: 'DELETE',
    body: department,
    cascadeFailureError: true,
    type: {
      request: types.DEPARTMENTS_DELETE_REQUEST,
      success: types.DEPARTMENTS_DELETE_SUCCESS,
      failure: types.DEPARTMENTS_DELETE_FAILURE
    }
  })
}

export const editDepartment = (department: DepartmentResponse) => {
  const payload: DepartmentRequest = {
    ..._.omit(department, ['id']),
    name: department.name
  }
  return api.call({
    url: sprintf(urls.DEPARTMENTS_EDIT_URL, { id: department.id }),
    method: 'PUT',
    body: payload,
    cascadeFailureError: true,
    type: {
      request: types.DEPARTMENTS_EDIT_REQUEST,
      success: types.DEPARTMENTS_EDIT_SUCCESS,
      failure: types.DEPARTMENTS_EDIT_FAILURE
    }
  })
}

export const getDepartment = (id: number) => {
  return api.call({
    url: sprintf(urls.DEPARTMENTS_GET_URL, { id }),
    cascadeFailureError: true,
    type: {
      request: types.DEPARTMENTS_GET_REQUEST,
      success: types.DEPARTMENTS_GET_SUCCESS,
      failure: types.DEPARTMENTS_GET_FAILURE
    }
  })
}

export const listDepartments = () => {
  return api.call({
    url: urls.DEPARTMENTS_LIST_URL,
    cascadeFailureError: true,
    type: {
      request: types.DEPARTMENTS_LIST_REQUEST,
      success: types.DEPARTMENTS_LIST_SUCCESS,
      failure: types.DEPARTMENTS_LIST_FAILURE
    }
  })
}

export const newDepartment = (department: DepartmentRequest) => {
  return api.call({
    url: urls.DEPARTMENTS_NEW_URL,
    method: 'POST',
    body: department,
    cascadeFailureError: true,
    type: {
      request: types.DEPARTMENTS_NEW_REQUEST,
      success: types.DEPARTMENTS_NEW_SUCCESS,
      failure: types.DEPARTMENTS_NEW_FAILURE
    }
  })
}
