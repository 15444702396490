import React, { useContext } from 'react';
import { CarouselContext } from './Carousel';
const CarouselIndicators = ({ children }) => {
    const carouselContext = useContext(CarouselContext);
    if (!carouselContext) {
        return null;
    }
    const { activeIndex, onValueChange } = carouselContext;
    return (React.createElement("div", { className: 'cds-carousel--indicators' }, React.Children.map(children, (child, index) => (React.createElement("span", { key: index, className: `cds-carousel-indicator ${activeIndex === index ? 'active' : ''}`, onClick: () => onValueChange(index) })))));
};
export default CarouselIndicators;
