import { FlexDiv, Heading, HorizontalSpace, VerticalSpace } from '@cegal/ds-components'
import { Assignment } from '@cegal/ds-icons/dist/Assignment'
import { deleteFollowedEngagement, listFollowedEngagements } from 'actions'
import { FOLLOWED_ENGAGEMENTS_LIST_FAILURE } from 'constants/actionTypes'
import { Engagement } from 'declarations/models'
import { standardLogger } from 'metrics/loggers'
import { useAppDispatch, useAppSelector } from 'store'

import AlertPanel from 'components/AlertPanel/AlertPanel'
import EngagementCard from 'components/Engagements/Engagement/EngagementCard'
import FollowButton from 'components/Forms/Buttons/FollowButton'
import Widget from 'components/Home/Widget/Widget'
import { StretchLoader } from 'components/Loader/CustomLoader'
import { CardContainer } from 'components/styled'

import { HomePanelProps } from 'pages/Home/Home'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import _ from 'lodash'

const FollowedEngagements = ({ mode }: HomePanelProps) => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const apiReady = useAppSelector((state) => state.app.apiReady)
  const followedEngagements = useAppSelector((state) => state.followedEngagements.list)
  const deletingFollow = useAppSelector((state) => state.followedEngagements.deleting)

  const loadPageData = () => {
    dispatch(listFollowedEngagements())
  }

  const handleEngagementUnfollow = (engagement: Engagement) => {
    standardLogger('followedengagements.card.unfollow')
    dispatch(deleteFollowedEngagement(engagement))
  }

  const renderTitle = () => (
    <FlexDiv>
      <Assignment size='2rem' />
      <HorizontalSpace />
      <Heading>{t('home:followed-engagements')}</Heading>
    </FlexDiv>
  )

  const renderBody = () => (
    <>
      {_.isUndefined(followedEngagements) && <StretchLoader />}
      {_.isNull(followedEngagements) && (
        <AlertPanel
          doNotRenderIfHeadIsActiveWithError
          watchFor={[FOLLOWED_ENGAGEMENTS_LIST_FAILURE]}
          buttonText={t('buttons:click-to-reload-my-follows')!}
          reloadPage={loadPageData}
        />
      )}
      {!_.isEmpty(followedEngagements)
        ? followedEngagements!.map((followedEngagement: Engagement) => {
            const _deleting = _.find(deletingFollow, { id: followedEngagement.id }) !== undefined
            return (
              <EngagementCard
                spacing
                fullWidth
                key={followedEngagement.id}
                item={followedEngagement}
                onView={(followedEngagement) =>
                  navigate(generatePath('/engagements/:id', { id: followedEngagement!.id.toString() }))
                }
                buttons={[
                  <FollowButton<Engagement>
                    text={false}
                    key={'followedengagement-' + followedEngagement.id + '-follow-button'}
                    item={followedEngagement}
                    followedItems={followedEngagements}
                    onUnfollow={handleEngagementUnfollow}
                    loading={_deleting}
                    disabled={_deleting}
                  />
                ]}
                size='small'
              />
            )
          })
        : t('messages:no-followed-engagements')}
    </>
  )

  useEffect(() => {
    if (followedEngagements === undefined && apiReady) {
      loadPageData()
    }
  }, [followedEngagements, apiReady])

  if (mode === 'widget') {
    return <Widget header={renderTitle()}>{renderBody()}</Widget>
  }

  return (
    <>
      {renderTitle()}
      <VerticalSpace />
      <CardContainer>{renderBody()}</CardContainer>
      <VerticalSpace size='2' />
    </>
  )
}

export default FollowedEngagements
