import { SWAGGER_URL } from 'constants/urls'
import SwaggerUI from 'swagger-ui-react'
import 'swagger-ui-react/swagger-ui.css'

import PageContent from 'components/PageContent/PageContent'

import { IS_PRODUCTION } from 'utils/environment'

const Swagger = () => {
  const url = !IS_PRODUCTION
    ? 'http://localhost:8080/swagger/doc.json'
    : window.location.protocol + '//' + window.location.host + SWAGGER_URL
  return (
    <PageContent maxWidth='1600px' padding='0px'>
      <SwaggerUI url={url} />
    </PageContent>
  )
}

export default Swagger
