var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import cl from 'clsx';
import { BodyShort, Detail, ErrorMessage, Label } from 'components/typography';
import React, { forwardRef, useRef } from 'react';
import { omit } from 'util/index';
import { useFormField } from 'components/form/useFormField';
export const Slider = forwardRef((props, ref) => {
    const { inputProps, errorId, showErrorMsg, hasError, size, inputDescriptionId } = useFormField(props, 'textField');
    const { active = 'both', className, description, fullWidth = false, htmlSize, hideLabel = false, max, min, label, leftLabel, rightLabel, onChange, value } = props, rest = __rest(props, ["active", "className", "description", "fullWidth", "htmlSize", "hideLabel", "max", "min", "label", "leftLabel", "rightLabel", "onChange", "value"]);
    const thumbRef = useRef(null);
    const trackInnerRef = useRef(null);
    const handleOnChange = (e) => {
        onChange === null || onChange === void 0 ? void 0 : onChange(e);
        updateSlider(parseInt(e.target.value));
    };
    const updateSlider = (value) => {
        let perc;
        if (max !== undefined && min !== undefined) {
            const range = parseInt(max) - parseInt(min);
            perc = ((value !== null && value !== void 0 ? value : 0) / range) * 100;
        }
        else {
            perc = value;
        }
        if (thumbRef.current) {
            thumbRef.current.style.left = `${perc}%`;
            thumbRef.current.style.transform = `translate(-${perc}%, -50%)`;
        }
        if (trackInnerRef.current) {
            trackInnerRef.current.style.width = `${perc}%`;
        }
    };
    updateSlider(value !== null && value !== void 0 ? value : 0);
    return (React.createElement("div", { className: cl('cds-form-field', `cds-form-field--${size}`, {
            'cds-slider--error': hasError,
            'cds-slider--disabled': !!inputProps.disabled,
            'cds-form-field--disabled': !!inputProps.disabled
        }, className) },
        React.createElement(Label, { htmlFor: inputProps.id, size: size, as: 'label', className: cl('cds-form-field__label', {
                'cds-sr-only': hideLabel
            }) }, label),
        !!description && (React.createElement(React.Fragment, null, size === 'medium' ? (React.createElement(BodyShort, { className: cl('cds-form-field__description', {
                'cds-sr-only': hideLabel
            }), id: inputDescriptionId, size: 'small', as: 'div' }, description)) : (React.createElement(Detail, { className: cl('cds-form-field__description', {
                'cds-sr-only': hideLabel
            }), id: inputDescriptionId, size: 'small', as: 'div' }, description)))),
        React.createElement("div", { className: cl('cds-slider__container', { 'cds-slider__container-fullWidth': fullWidth }) },
            leftLabel && React.createElement("div", { className: 'cds-slider__left-label' }, leftLabel),
            React.createElement("div", { className: 'cds-slider__wrap' },
                React.createElement("input", Object.assign({}, omit(rest, ['error', 'errorId', 'size']), inputProps, { ref: ref, className: cl('cds-slider__input', 'cds-body-short', `cds-body-${size !== null && size !== void 0 ? size : 'medium'}`), onChange: handleOnChange, min: min, max: max, value: value, type: 'range', size: htmlSize })),
                React.createElement("div", { className: cl('cds-slider__track', `cds-slider__active-${active}`) },
                    React.createElement("div", { ref: trackInnerRef, className: 'cds-slider__track-inner' })),
                React.createElement("div", { ref: thumbRef, className: 'cds-slider__thumb' })),
            rightLabel && React.createElement("div", { className: 'cds-slider__right-label' }, rightLabel)),
        React.createElement("div", { className: 'cds-form-field__error', id: errorId, "aria-relevant": 'additions removals', "aria-live": 'polite' }, showErrorMsg && React.createElement(ErrorMessage, { size: size }, props.error))));
});
Slider.displayName = 'Slider';
export default Slider;
