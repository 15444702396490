import * as types from 'constants/actionTypes'
import { ConsultantCV } from 'declarations/cv'
import { Consultant } from 'declarations/models'
import { AnyAction } from 'redux'

export interface ConsultantState {
  list: Array<Consultant> | null | undefined
  listing: boolean
  get: Consultant | null | undefined
  getting: boolean
  cv: ConsultantCV | null | undefined
  cvLoading: boolean
}

export const initialConsultantsState: ConsultantState = {
  list: undefined,
  listing: false,
  get: undefined,
  getting: false,
  cv: undefined,
  cvLoading: false
}

const consultantsReducer = (
  state: ConsultantState = initialConsultantsState,
  action: AnyAction
): ConsultantState => {
  switch (action.type) {
    case types.CONSULTANTS_CONSULTANT_SET:
      return {
        ...state,
        get: action.payload
      }

    case types.CONSULTANTS_CV_REQUEST:
      return {
        ...state,
        cvLoading: true,
        cv: undefined
      }

    case types.CONSULTANTS_CV_SUCCESS:
      return {
        ...state,
        cvLoading: false,
        cv: action.payload
      }

    case types.CONSULTANTS_CV_FAILURE:
      return {
        ...state,
        cvLoading: false,
        cv: null
      }

    case types.CONSULTANTS_GET_REQUEST:
      return {
        ...state,
        getting: true,
        get: undefined
      }

    case types.CONSULTANTS_GET_SUCCESS:
      return {
        ...state,
        getting: false,
        get: action.payload
      }

    case types.CONSULTANTS_GET_FAILURE:
      return {
        ...state,
        getting: false,
        get: null
      }

    case types.CONSULTANTS_LIST_REQUEST:
      return {
        ...state,
        listing: true,
        list: undefined
      }

    case types.CONSULTANTS_LIST_SUCCESS:
      return {
        ...state,
        listing: false,
        // TODO fix when backend is ready
        list: action.payload.map((consultant: Consultant) => ({
          ...consultant,
          // filter out all roles that name is empty string
          roles: consultant.roles.filter((role) => role.name !== '')
        }))
      }

    case types.CONSULTANTS_LIST_FAILURE:
      return {
        ...state,
        listing: false,
        list: null
      }

    case types.CONSULTANTS_LIST_SET:
      return {
        ...state,
        listing: false,
        list: action.payload
      }

    default:
      return state
  }
}

export default consultantsReducer
