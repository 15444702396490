import * as types from 'constants/actionTypes'
import * as urls from 'constants/urls'
import api from 'api'
import { Client } from 'declarations/models'
import { sprintf } from 'sprintf-js'

import _ from 'lodash'

export const deleteFollowedClient = (followedClient: Client) => {
  if (_.isNumber(followedClient?.id)) {
    return api.call({
      url: sprintf(urls.FOLLOWED_CLIENTS_DELETE_URL, {
        id: followedClient!.id
      }),
      method: 'DELETE',
      skipFake: true,
      context: followedClient,
      type: {
        request: types.FOLLOWED_CLIENTS_DELETE_REQUEST,
        success: types.FOLLOWED_CLIENTS_DELETE_SUCCESS,
        failure: types.FOLLOWED_CLIENTS_DELETE_FAILURE
      }
    })
  }
}

export const listFollowedClients = () => {
  return api.call({
    url: urls.FOLLOWED_CLIENTS_LIST_URL,
    skipFake: true,
    cascadeFailureError: true,
    type: {
      request: types.FOLLOWED_CLIENTS_LIST_REQUEST,
      success: types.FOLLOWED_CLIENTS_LIST_SUCCESS,
      failure: types.FOLLOWED_CLIENTS_LIST_FAILURE
    }
  })
}

export const newFollowedClient = (client: Client) => {
  return api.call({
    url: sprintf(urls.FOLLOWED_CLIENTS_NEW_URL, { client_id: client.id }),
    method: 'POST',
    skipFake: true,
    cascadeFailureError: true,
    context: client,
    type: {
      request: types.FOLLOWED_CLIENTS_NEW_REQUEST,
      success: types.FOLLOWED_CLIENTS_NEW_SUCCESS,
      failure: types.FOLLOWED_CLIENTS_NEW_FAILURE
    }
  })
}
