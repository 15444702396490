var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { CheckCircle as Success } from '@cegal/ds-icons/dist/CheckCircle';
import { Error } from '@cegal/ds-icons/dist/Error';
import { Info } from '@cegal/ds-icons/dist/Info';
import { Warning } from '@cegal/ds-icons/dist/Warning';
import cl from 'clsx';
import Button from 'components/button/Button';
import { PileDiv } from 'layout/grid';
import { BodyLong, Label } from 'components/typography';
import React, { forwardRef } from 'react';
const Icon = (_a) => {
    var { variant } = _a, props = __rest(_a, ["variant"]);
    switch (variant) {
        case 'error':
            return React.createElement(Error, Object.assign({ title: 'Error' }, props));
        case 'warning':
            return React.createElement(Warning, Object.assign({ title: 'Warning' }, props));
        case 'info':
            return React.createElement(Info, Object.assign({ title: 'Information' }, props));
        case 'success':
            return React.createElement(Success, Object.assign({ title: 'Success' }, props));
        default:
            return null;
    }
};
const Alert = forwardRef((_a, ref) => {
    var { children, className, variant = 'info', fullWidth = false, header = undefined, inline = false, onClose, onCloseLabel = 'OK', size = 'medium', spacing = false } = _a, rest = __rest(_a, ["children", "className", "variant", "fullWidth", "header", "inline", "onClose", "onCloseLabel", "size", "spacing"]);
    return (React.createElement("div", Object.assign({}, rest, { ref: ref, className: cl('cds-alert', `cds-alert--${variant}`, `cds-alert--${size}`, {
            'cds-alert--full-width': fullWidth,
            'cds-alert--inline': inline,
            'cds-alert--spacing': spacing
        }, className) }),
        React.createElement(Icon, { variant: variant, className: 'cds-alert__icon' }),
        React.createElement(PileDiv, null,
            header && (React.createElement(Label, { as: 'div', className: 'cds-alert__header' }, header)),
            React.createElement(BodyLong, { as: 'div', size: size, className: 'cds-alert__wrapper' }, children)),
        !!onClose && (React.createElement(Button, { size: 'small', onClick: onClose }, onCloseLabel))));
});
Alert.displayName = 'Alert';
export default Alert;
