import * as types from 'constants/actionTypes'
import { ClientCommentModel } from 'declarations/models'
import { AnyAction } from 'redux'

import _ from 'lodash'

export interface ClientCommentsState {
  created: ClientCommentModel | null | undefined
  creating: boolean
  deleted: ClientCommentModel | null | undefined
  deleting: boolean
  get: ClientCommentModel | null | undefined
  list: Array<ClientCommentModel> | null | undefined
  listing: boolean
  saving: boolean
  saved: ClientCommentModel | null | undefined
}

export const initialClientCommentsState: ClientCommentsState = {
  created: undefined,
  creating: false,
  deleted: undefined,
  deleting: false,
  get: undefined,
  list: undefined,
  listing: false,
  saved: undefined,
  saving: false
}

const clientCommentsReducer = (
  /* istanbul ignore next */
  state: ClientCommentsState = initialClientCommentsState,
  action: AnyAction
): ClientCommentsState => {
  switch (action.type) {
    case types.CLIENT_COMMENTS_DELETE_REQUEST:
      return {
        ...state,
        deleted: undefined,
        deleting: true
      }

    case types.CLIENT_COMMENTS_DELETE_SUCCESS: {
      const deletedClientComment = action.originalPayload
      return {
        ...state,
        deleted: deletedClientComment,
        deleting: false,
        list: _.reject(state.list, { id: deletedClientComment.id })
      }
    }

    case types.CLIENT_COMMENTS_DELETE_FAILURE:
      return {
        ...state,
        deleted: null,
        deleting: false
      }

    case types.CLIENT_COMMENTS_EDIT_REQUEST:
      return {
        ...state,
        saved: undefined,
        saving: true
      }

    case types.CLIENT_COMMENTS_EDIT_SUCCESS: {
      const editedClientComment = action.payload
      return {
        ...state,
        saved: editedClientComment,
        saving: false,
        list: _.map(state.list, (ClientComment) =>
          /* istanbul ignore next */
          editedClientComment.id === ClientComment.id ? editedClientComment : ClientComment
        )
      }
    }

    case types.CLIENT_COMMENTS_EDIT_FAILURE:
      return {
        ...state,
        saved: null,
        saving: false
      }

    case types.CLIENT_COMMENTS_LIST_REQUEST:
      return {
        ...state,
        list: undefined,
        listing: true
      }

    case types.CLIENT_COMMENTS_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload,
        listing: false
      }

    case types.CLIENT_COMMENTS_LIST_FAILURE:
      return {
        ...state,
        list: null,
        listing: false
      }

    case types.CLIENT_COMMENTS_NEW_REQUEST:
      return {
        ...state,
        creating: true,
        created: undefined
      }

    case types.CLIENT_COMMENTS_NEW_SUCCESS: {
      const newClientComment = action.payload
      return {
        ...state,
        created: newClientComment,
        creating: false,
        list: [.../* istanbul ignore next */ (state.list ?? [])].concat(newClientComment)
      }
    }

    case types.CLIENT_COMMENTS_NEW_FAILURE:
      return {
        ...state,
        created: null,
        creating: false
      }

    case types.CLIENT_COMMENTS_RESET:
      return {
        ...state,
        created: undefined,
        saved: undefined,
        deleted: undefined,
        get: undefined
      }

    default:
      return state
  }
}

export default clientCommentsReducer
