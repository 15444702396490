import * as types from 'constants/actionTypes'
import { AdminSyncing } from 'declarations/models'
import { AnyAction } from 'redux'

export interface AdminSyncingsState {
  posted: string | null | undefined
  posting: boolean
  get: AdminSyncing | null | undefined
  getting: boolean
  list: Array<AdminSyncing> | null | undefined
  listing: boolean
}

export const initialAdminSyncingsState: AdminSyncingsState = {
  posted: undefined,
  posting: false,
  get: undefined,
  getting: false,
  list: undefined,
  listing: false
}

const AdminSyncingsReducer = (
  /* istanbul ignore next */
  state: AdminSyncingsState = initialAdminSyncingsState,
  action: AnyAction
): AdminSyncingsState => {
  switch (action.type) {
    case types.ADMIN_SYNCING_GET_REQUEST:
      return {
        ...state,
        getting: true,
        get: undefined
      }

    case types.ADMIN_SYNCING_GET_SUCCESS:
      return {
        ...state,
        getting: false,
        get: action.payload
      }

    case types.ADMIN_SYNCING_GET_FAILURE:
      return {
        ...state,
        getting: false,
        get: null
      }

    case types.ADMIN_SYNCING_LIST_REQUEST:
      return {
        ...state,
        list: undefined,
        listing: true
      }

    case types.ADMIN_SYNCING_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload,
        listing: false
      }

    case types.ADMIN_SYNCING_LIST_FAILURE:
      return {
        ...state,
        list: null,
        listing: false
      }

    case types.ADMIN_SYNCING_POST_REQUEST:
      return {
        ...state,
        posting: true,
        posted: undefined
      }

    case types.ADMIN_SYNCING_POST_SUCCESS: {
      return {
        ...state,
        posted: 'success',
        posting: false
      }
    }

    case types.ADMIN_SYNCING_POST_FAILURE:
      return {
        ...state,
        posted: null,
        posting: false
      }

    default:
      return state
  }
}

export default AdminSyncingsReducer
