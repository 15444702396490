import { Consultant } from 'declarations/models'

import { Attachment } from 'hooks/useAiMessages'

export type MockResponseFunction = (nextWord: string, attachments?: Attachment[]) => string

export interface MockResponse {
  triggers: string[]
  response: MockResponseFunction
  contexts?: MockResponse[]
}

// NOTE! If top-level trigger has no meaning on it's own, return an empty string as the response. That way the fallback message is shown.

export const responses: MockResponse[] = [
  {
    triggers: ['called', 'call', 'titled'],
    response: () => '',
    contexts: [
      {
        triggers: ['vacancy'],
        response: (name) => `Sure, creating a vacancy called ${name}`
      },
      {
        triggers: ['it'],
        response: (name) => `Sure, creating and calling it ${name}. Give me a few seconds...`
      }
    ]
  },
  {
    triggers: ['add', 'create'],
    response: () => 'Please specify what you would like me to add',
    contexts: [
      {
        triggers: ['vacancy'],
        response: () => 'What should the vacancy be called?',
        contexts: [
          {
            triggers: ['called'],
            response: (name) =>
              name
                ? `No problem! Creating a vacancy called ${name}`
                : "It seems like you didn't specify a name for the new vacancy. Please try again."
          }
        ]
      },
      {
        triggers: ['engagement'],
        response: () => 'What should the engagement be called?',
        contexts: [
          {
            triggers: ['called'],
            response: (name) =>
              name
                ? `No problem! Creating an engagement called ${name}`
                : "It seems like you didn't specify a name for the new engagement. Please try again."
          }
        ]
      }
    ]
  },
  {
    triggers: ['find'],
    response: (something) => `If you want me to find ${something} you're going to have to be more specific.`,
    contexts: [
      {
        triggers: ['consultant', 'person', 'developer'],
        response: () => '',
        contexts: [
          {
            triggers: ['called', 'named'],
            response: (name) =>
              name
                ? `Are you looking for ${name}?`
                : "It seems like you didn't specify a name for me to search. Please try again."
          },
          {
            triggers: ['by'],
            response: () => 'Hmm... can you be more specific what about what you want me to find?',
            contexts: [
              {
                triggers: ['skills'],
                response: () => 'Here are the consultants with the desired skills.'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    triggers: ['summarize'],
    response: (something) =>
      `If you want me to summarize ${something} you're going to have to be more specific.`,
    contexts: [
      {
        triggers: ['engagement', 'description'],
        response: (name, attachments) => {
          const engagement = attachments && attachments[0]?.type === 'EngagementCard' && attachments[0]
          return `Here is a short summary ${
            engagement ? 'of ' + engagement.title : name ? 'of ' + name : ''
          }: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`
        }
      },
      {
        triggers: ['consultant', 'person'],
        response: (name, attachments) => {
          const consultant = attachments && attachments[0]?.type === 'ConsultantCard' && attachments[0]
          return `Here is a short summary ${
            consultant ? 'of ' + consultant.title : name ? 'of ' + name : ''
          }: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`
        }
      },
      {
        triggers: ['this'],
        response: () =>
          `This is Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`
      }
    ]
  },
  {
    triggers: ['filter'],
    response: () => `Sure, what would you like to filter by?`,
    contexts: [
      {
        triggers: ['by'],
        response: (filter) => `Sure, filtering current results by ${filter}`,
        contexts: [
          {
            triggers: ['consultant', 'skills'],
            response: (_, attachments) => {
              const consultant = attachments && attachments[0]?.type === 'ConsultantCard' && attachments[0]
              return consultant
                ? `Filtering by all of ${consultant.title}'s ${
                    (consultant.data as Consultant).skills.length
                  } skills`
                : 'Sure, filtering by consultant skills.'
            }
          }
        ]
      }
    ]
  }
]
