import {
  BodyShort,
  Button,
  Chip,
  FlexBaseDiv,
  FlexBaseSpacedDiv,
  FlexCenterDiv,
  Label,
  Link,
  Loader,
  PileDiv,
  Tooltip
} from '@cegal/ds-components/dist'
import {
  Article,
  AutoFixHigh,
  Clear,
  ContentCopy,
  Create,
  Delete,
  Download,
  FileUpload,
  FormatAlignCenter,
  FormatListBulleted,
  InsertDriveFile,
  Person,
  Summarize
} from '@cegal/ds-icons'
import { styled } from 'styled-components'

import { useState } from 'react'
import { useDrop } from 'react-dnd'
import { useNavigate } from 'react-router-dom'

import { Attachment } from 'hooks/useAiMessages'

import { t } from 'i18next'

import { generateDocument } from './Document'

const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 0.5rem;
  position: relative;
`

const TabField = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--cat-homepanel-element-background-color);
  flex-grow: 1;
  overflow-y: auto;
  padding: 0.6rem;
`
const SectionContainer = styled.div`
  border: 1px solid var(--cat-homepanel-element-border-color);
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
`
const InputContainer = styled.div`
  width: 100%;
  background-color: var(--cat-summarize-background);
  border: 1px solid var(--cat-homepanel-element-border-color);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  position: relative;
`

const InputField = styled.textarea`
  border-radius: 0;
  background-color: transparent;
  border: none;
  height: 100px;
  resize: none;
  padding: 1rem;
  width: 100%;
  color: var(--cds-text-color);
  font-size: var(--cds-font-body-size-large);
  border-radius: 0.5rem;
  scrollbar-color: var(--cat-homepanel-element-border-color) transparent;
  &:focus {
    outline: none;
  }
`
const ExtraContainer = styled.div`
  width: 100%;
  border-top: 1px solid var(--cat-homepanel-element-border-color);
  padding: 1rem;
`

const StyledButton = styled(Button)`
  background-color: var(--cat-summarize-background);
  border-radius: 0.5rem;
  margin-top: 1rem;
  padding: 1rem;
  width: 100%;
`

const StyledLoader = styled(Loader)`
  position: absolute;
  right: 1rem;
  top: 1rem;
`

const AttachmentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`

const AttachmentItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  align-items: center;
  gap: 1rem;
  background-color: var(--cat-drawer-background-color);
  padding: 0.3rem;
  border-radius: 0.4rem;
`

const Overlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
`

const OverlayStripes = styled.div`
  position: absolute;
  inset: 1.6rem;
  border: 2px dashed rgba(255, 255, 255, 0.5);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
`

const OverlayText = styled.div`
  position: absolute;
  color: white;
  font-size: 24px;
`

type Message = {
  text: string | undefined
  length: string | undefined
  format: string | undefined
}

const AiSummarize = () => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [attachments, setAttachments] = useState<Attachment[]>([])
  const [inputValue, setInputValue] = useState('')
  const [outputMessage, setOutputMessage] = useState('')
  // eslint-disable-next-line
  const [message, setMessage] = useState<Message>({
    text: '',
    length: '',
    format: ''
  })

  const [lengthChips, setLengthChips] = useState([
    { value: 'short', label: 'Short', selected: true },
    { value: 'medium', label: 'Medium', selected: false },
    { value: 'long', label: 'Long', selected: false }
  ])

  const [formatChips, setFormatChips] = useState([
    { value: 'paragraph', label: 'Paragraph', icon: <FormatAlignCenter size='2rem' />, selected: true },
    { value: 'summary', label: 'Summary', icon: <Summarize size='2rem' />, selected: false },
    {
      value: 'bullet-points',
      label: 'Bullet Points',
      icon: <FormatListBulleted size='2rem' />,
      selected: false
    }
  ])

  const iconMap = [
    { type: 'Document', icon: <InsertDriveFile size='1.5rem' /> },
    { type: 'ConsultantCard', icon: <Person size='1.5rem' /> }
  ]

  const handleSubmit = (e: any) => {
    e?.preventDefault()
    const newMessage = {
      text: inputValue,
      length: lengthChips.find((c) => c.selected)?.value,
      format: formatChips.find((c) => c.selected)?.value
    }

    setMessage(newMessage)

    const output = `Text : "${newMessage.text}"\r\nLength : "${newMessage.length}"\r\nFormat : "${newMessage.format}"`
    setIsLoading(true)

    setTimeout(() => {
      setIsLoading(false)
      setOutputMessage(output)
    }, 2000)
  }

  const handleClear = () => {
    setOutputMessage('')
    setMessage({ text: '', length: '', format: '' })
  }

  const handleExportDocument = () => {
    generateDocument(outputMessage)
  }

  const addAttachment = (item: Attachment) => {
    setAttachments((curr) => [...curr, item])
  }
  const removeAttachment = (index: number) => {
    const copy = [...attachments]
    copy.splice(index, 1)
    setAttachments(copy)
  }

  const handleFileInput = (e: any) => {
    const attachment: Attachment = {
      type: 'Document',
      title: e.target.files[0].name,
      data: e.target.files[0]
    }
    addAttachment(attachment)
  }

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: ['EngagementCard', 'ConsultantCard', 'ClientCard'],
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    }),
    drop: (item, monitor) => {
      addAttachment(item as Attachment)
    }
  }))

  const styles = {
    backgroundColor: isOver ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.5)',
    transition: 'background-color 0.2s ease-in-out'
  }
  const stripeStyles = {
    inset: isOver ? '2rem' : '1.4rem',
    transition: 'inset 0.2s ease-in-out'
  }

  const isActive = canDrop

  return (
    <TabContainer style={{ paddingTop: '0' }}>
      <TabField>
        {isActive && (
          <Overlay ref={drop} style={{ ...styles }}>
            <OverlayStripes style={{ ...stripeStyles }}>
              <OverlayText>{t('label:drop-files-here')}</OverlayText>
            </OverlayStripes>
          </Overlay>
        )}
        <SectionContainer>
          <Label>
            <Create size='1.5rem' style={{ marginRight: '.6rem' }} />
            Write about
          </Label>
          <InputContainer>
            <InputField
              onChange={(e) => setInputValue(e.target.value)}
              value={inputValue}
              maxLength={1000}
              placeholder='Start typing or paste your content here...'
            />
            <AttachmentContainer style={{ marginLeft: '1rem' }}>
              {attachments.map((item: Attachment, index) => (
                <AttachmentItem key={index}>
                  {iconMap.find((x) => x.type === item.type)?.icon || <Article size='1.5rem' />}
                  <PileDiv>
                    {item.label && <BodyShort style={{ opacity: '0.7' }}>{item.label}</BodyShort>}
                    <BodyShort style={{ paddingBottom: '.4rem' }}>
                      <Link style={{ cursor: 'pointer' }} onClick={() => navigate(item.link || '')}>
                        {item.title}
                      </Link>
                    </BodyShort>
                  </PileDiv>
                  <Button
                    onClick={() => removeAttachment(index)}
                    icon={<Clear size='1.5rem' />}
                    variant='tertiary'
                    size='small'
                    style={{ marginLeft: '-.5rem' }}
                  />
                </AttachmentItem>
              ))}
            </AttachmentContainer>
            <ExtraContainer>
              <FlexBaseSpacedDiv style={{ alignItems: 'center' }}>
                <div>
                  <label htmlFor='fileInput'>
                    <FlexCenterDiv style={{ gap: '.6rem', cursor: 'pointer', width: 'fit-content' }}>
                      {' '}
                      <FileUpload size='1.5rem' />
                      <Label>Browse files</Label>
                    </FlexCenterDiv>
                  </label>
                  <input
                    onChange={handleFileInput}
                    type='file'
                    accept='.doc,.docx'
                    id='fileInput'
                    style={{ display: 'none' }}
                  />
                </div>
                <BodyShort style={{ opacity: '0.7' }}>{`${inputValue.length} / 1000`}</BodyShort>
              </FlexBaseSpacedDiv>
            </ExtraContainer>
          </InputContainer>
          <InputContainer style={{ padding: '1rem' }}>
            <Label>Length</Label>
            <FlexBaseDiv style={{ gap: '0.5rem' }}>
              {lengthChips.map((chip) => (
                <Chip
                  style={{ margin: '0', cursor: 'pointer', borderRadius: '.8rem' }}
                  key={chip.value}
                  selected={chip.selected}
                  onClick={() => {
                    const newChips = lengthChips.map((c) => ({ ...c, selected: c.value === chip.value }))
                    setLengthChips(newChips)
                  }}
                >
                  {chip.label}
                </Chip>
              ))}
            </FlexBaseDiv>
          </InputContainer>
          <InputContainer style={{ padding: '1rem' }}>
            <Label>Format</Label>
            <FlexCenterDiv style={{ gap: '1.6rem' }}>
              {formatChips.map((chip) => (
                <FlexCenterDiv key={chip.value} style={{ flexDirection: 'column', gap: '.2rem' }}>
                  <Chip
                    style={{ margin: '0', cursor: 'pointer', padding: '1.2rem', borderRadius: '0.8rem' }}
                    selected={chip.selected}
                    onClick={() => {
                      const newChips = formatChips.map((c) => ({ ...c, selected: c.value === chip.value }))
                      setFormatChips(newChips)
                    }}
                  >
                    {chip.icon}
                  </Chip>
                  <BodyShort style={{ opacity: '0.7' }}>{chip.label}</BodyShort>
                </FlexCenterDiv>
              ))}
            </FlexCenterDiv>
          </InputContainer>
          <StyledButton variant='secondary' loading={isLoading} disabled={!inputValue} onClick={handleSubmit}>
            {isLoading ? 'Generating...' : 'Generate text'}
          </StyledButton>
        </SectionContainer>
        <SectionContainer style={{ marginBottom: '0' }}>
          <Label>
            {' '}
            <AutoFixHigh size='1.5rem' style={{ marginRight: '.6rem' }} />
            Summarized text
          </Label>
          <InputContainer>
            <InputField
              disabled={outputMessage === ''}
              onChange={(e) => setOutputMessage(e.target.value)}
              value={outputMessage}
              placeholder={!isLoading ? 'Your AI generated text will appear here...' : ''}
            />
            {isLoading && <StyledLoader size='small' />}
            <ExtraContainer>
              <FlexBaseSpacedDiv style={{ alignItems: 'center' }}>
                <BodyShort>{`${outputMessage ? outputMessage.split(' ').length : '0'} words`}</BodyShort>
                <FlexCenterDiv style={{ gap: '1rem' }}>
                  <Tooltip content='Export'>
                    <Button
                      variant='tertiary'
                      size='small'
                      disabled={!outputMessage}
                      onClick={handleExportDocument}
                      icon={<Download size='1.5rem' />}
                    />
                  </Tooltip>
                  <Tooltip content='Copy full text'>
                    <Button
                      variant='tertiary'
                      size='small'
                      disabled={!outputMessage}
                      onClick={() => navigator.clipboard.writeText(outputMessage)}
                      icon={<ContentCopy size='1.5rem' />}
                    />
                  </Tooltip>
                  <Tooltip content='Clear output'>
                    <Button
                      variant='tertiary'
                      size='small'
                      disabled={!outputMessage}
                      onClick={handleClear}
                      icon={<Delete size='1.5rem' />}
                    />
                  </Tooltip>
                </FlexCenterDiv>
              </FlexBaseSpacedDiv>
            </ExtraContainer>
          </InputContainer>
        </SectionContainer>
      </TabField>
    </TabContainer>
  )
}

export default AiSummarize
