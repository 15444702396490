var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { KeyboardArrowDown } from '@cegal/ds-icons/dist/KeyboardArrowDown';
import cl from 'clsx';
import React, { forwardRef, useState } from 'react';
import { UnmountClosed } from 'react-collapse';
import { useId } from 'util/index';
import DataCell from './DataCell';
import Row from './Row';
export const ExpandableRow = forwardRef((_a, ref) => {
    var { className, children, content, togglePlacement = 'left', defaultOpen = false, open, onOpenChange, expansionDisabled = false } = _a, rest = __rest(_a, ["className", "children", "content", "togglePlacement", "defaultOpen", "open", "onOpenChange", "expansionDisabled"]);
    const [internalOpen, setInternalOpen] = useState(defaultOpen);
    const id = `expandable-${useId()}`;
    const isOpen = open !== null && open !== void 0 ? open : internalOpen;
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, Object.assign({}, rest, { ref: ref, className: cl('cds-table__expandable-row', {
                'cds-table__expandable-row--open': isOpen
            }, className) }),
            togglePlacement === 'right' && children,
            React.createElement(DataCell, { className: cl('cds-table__toggle-expand-cell', {
                    'cds-table__toggle-expand-cell--open': isOpen
                }) }, !expansionDisabled && (React.createElement("button", { className: 'cds-table__toggle-expand-button', "aria-controls": id, "aria-expanded": isOpen, onClick: () => {
                    onOpenChange === null || onOpenChange === void 0 ? void 0 : onOpenChange(!isOpen);
                    if (open === undefined) {
                        setInternalOpen((open) => !open);
                    }
                } },
                React.createElement(KeyboardArrowDown, { size: '1.5rem', className: 'cds-table__expandable-icon' })))),
            togglePlacement === 'left' && children),
        React.createElement("tr", { className: 'cds-table__expanded-row', "aria-hidden": !isOpen, id: id },
            React.createElement("td", { colSpan: 999, className: 'cds-table__expanded-row-cell' },
                React.createElement(UnmountClosed, { isOpened: isOpen, theme: {
                        collapse: 'cds-table__expanded-row-collapse',
                        content: 'cds-table__expanded-row-content'
                    } }, content)))));
});
ExpandableRow.displayName = 'Table.ExpandableRow';
export default ExpandableRow;
