import {
  Certificate,
  Client,
  Engagement,
  EngagementVacancy,
  EngagementVacancyConsultant,
  Skill,
  Source,
  StringEntry
} from 'declarations/models'

import _, { uniqBy } from 'lodash'

export const getCertificatesFromEngagement = (engagement: Engagement): Array<Certificate> =>
  engagement?.vacancies?.flatMap((vacancy: EngagementVacancy) => vacancy.certificates) ?? []

export const getCertificatesFromEngagements = (engagements: Array<Engagement>): Array<Certificate> =>
  engagements?.flatMap(getCertificatesFromEngagement)

export const getConsultantsFromEngagement = (engagement: Engagement): Array<EngagementVacancyConsultant> =>
  engagement?.vacancies?.flatMap((vacancy: EngagementVacancy) => _.flatMap(vacancy.consultants))

export const getClientsFromEngagements = (engagements: Array<Engagement>): Array<Client> =>
  engagements?.flatMap((engagement) => engagement.clients).filter((c) => !!c) as Array<Client>

export const getSourcesFromEngagements = (engagements: Array<Engagement>): Array<Source> =>
  engagements?.flatMap((engagement) => engagement.source).filter((c) => !!c) as Array<Source>

export const getSkillsFromEngagement = (engagement: Engagement): Array<Skill> =>
  engagement.vacancies?.flatMap((vacancy: EngagementVacancy) => vacancy.skills) ?? []

export const getSkillsFromEngagements = (engagements: Array<Engagement>): Array<Skill> =>
  engagements?.flatMap(getSkillsFromEngagement)

export const getUniqueSkillsFromEngagement = (engagement: Engagement): Array<Skill> =>
  uniqBy(getSkillsFromEngagement(engagement), 'id')

export const getUniqueSkillIdsFromEngagement = (engagement: Engagement): Array<Skill['id']> =>
  getUniqueSkillsFromEngagement(engagement)
    .map((s) => s.id)
    .sort()

export const getSortedSkillsFromEngagementVacancy = (
  engagement: Engagement,
  vacancy_id: number
): Array<Skill['id']> =>
  _.find(engagement?.vacancies, (v: EngagementVacancy) => v.id === vacancy_id)
    ?.skills.map((s) => s.id)
    .sort() ?? []

export const getLocationsFromEngagements = (engagements: Array<Engagement>): Array<StringEntry> =>
  engagements?.flatMap((engagement) => ({
    id: engagement.location,
    name: engagement.location
  })) as Array<StringEntry>

export const isEditable = (engagement: Engagement | null | undefined, userId: number) =>
  engagement?.creator?.id === userId
