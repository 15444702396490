var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { arrow as flArrow, autoUpdate, flip, hide, shift, useFloating } from '@floating-ui/react-dom';
import cl from 'clsx';
import React, { cloneElement, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { mergeRefs } from 'react-merge-refs';
import { composeEventHandlers, useEventListener, useId } from 'util/index';
import { Detail } from 'components/typography';
import Portal from './portal';
export const Tooltip = forwardRef((_a, ref) => {
    var { children, className, arrow: _arrow = true, placement: _placement = 'top', open, defaultOpen = false, offset: _offset, content, delay = 150, id, keys, variant = 'primary' } = _a, rest = __rest(_a, ["children", "className", "arrow", "placement", "open", "defaultOpen", "offset", "content", "delay", "id", "keys", "variant"]);
    const arrowRef = useRef(null);
    const mainRef = useRef(null);
    const [isOpen, setIsOpen] = useState(defaultOpen);
    const openTimerRef = useRef(0);
    const leaveTimerRef = useRef(0);
    const isMouseDownRef = useRef(false);
    const ariaId = useId(id);
    const { x, y, update, placement, refs, middlewareData: { arrow: { x: arrowX, y: arrowY } = {}, hide: { referenceHidden } = {} } } = useFloating({
        placement: _placement,
        middleware: [
            shift(),
            flip({ padding: 5, fallbackPlacements: ['bottom', 'top'] }),
            flArrow({ element: arrowRef, padding: 5 }),
            hide()
        ]
    });
    useEffect(() => {
        if (!refs.reference.current || !refs.floating.current) {
            return;
        }
        return autoUpdate(refs.reference.current, refs.floating.current, update);
    }, [refs.reference, refs.floating, update, open, isOpen]);
    const handleOpen = useCallback(() => {
        window.clearTimeout(openTimerRef.current);
        window.clearTimeout(leaveTimerRef.current);
        setIsOpen(true);
    }, [setIsOpen]);
    const handleDelayedOpen = useCallback(() => {
        window.clearTimeout(openTimerRef.current);
        window.clearTimeout(leaveTimerRef.current);
        openTimerRef.current = window.setTimeout(() => {
            setIsOpen(true);
        }, delay);
    }, [delay, setIsOpen]);
    const handleClose = useCallback(() => {
        window.clearTimeout(openTimerRef.current);
        leaveTimerRef.current = window.setTimeout(() => {
            setIsOpen(false);
        }, 50);
    }, [setIsOpen]);
    const handleMouseUp = useCallback(() => (isMouseDownRef.current = false), []);
    useEffect(() => {
        return () => window.clearTimeout(openTimerRef.current);
    }, []);
    useEffect(() => {
        return () => document.removeEventListener('mouseup', handleMouseUp);
    }, [handleMouseUp]);
    useEventListener('keydown', useCallback((e) => e.key === 'Escape' && handleClose(), [handleClose]));
    const stableRef = useMemo(() => mergeRefs([ref, refs.floating]), [ref, refs.floating]);
    if (!children || (children === null || children === void 0 ? void 0 : children.type) === React.Fragment || children === React.Fragment) {
        console.error('<Tooltip> children needs to be a single ReactElement and not <React.Fragment/>/<></>');
        return null;
    }
    return (React.createElement("div", { ref: mainRef },
        cloneElement(children, Object.assign(Object.assign({}, children.props), { 'aria-describedby': open || isOpen
                ? cl(ariaId, children === null || children === void 0 ? void 0 : children.props['aria-describedby'])
                : children === null || children === void 0 ? void 0 : children.props['aria-describedby'], ref: mergeRefs([children.ref, refs.reference]), onMouseEnter: composeEventHandlers(children.props.onMouseEnter, handleDelayedOpen), onMouseLeave: composeEventHandlers(children.props.onMouseLeave, handleClose), onMouseDown: composeEventHandlers(children.props.onMouseDown, () => {
                isMouseDownRef.current = true;
                document &&
                    document.addEventListener('mouseup', handleMouseUp, {
                        once: true
                    });
            }), onFocus: composeEventHandlers(children.props.onFocus, () => !isMouseDownRef.current && handleOpen()), onBlur: composeEventHandlers(children.props.onBlur, handleClose) })),
        (open !== null && open !== void 0 ? open : isOpen) && (React.createElement(Portal, { anchorEl: mainRef.current },
            React.createElement("div", Object.assign({ ref: stableRef }, rest, { className: cl('cds-tooltip', 'cds-detail cds-detail--small', `cds-tooltip__${variant}`, className), onMouseEnter: handleOpen, onMouseLeave: handleClose, role: 'tooltip', id: ariaId, style: {
                    position: 'absolute',
                    top: y !== null && y !== void 0 ? y : '',
                    left: x !== null && x !== void 0 ? x : '',
                    visibility: referenceHidden ? 'hidden' : 'visible'
                }, "data-side": placement }),
                React.createElement("div", { className: 'cds-tooltip__inner', style: {
                        [{
                            top: 'marginBottom',
                            right: 'marginLeft',
                            bottom: 'marginTop',
                            left: 'marginRight'
                        }[placement]]: _offset || (_arrow ? 10 : 2)
                    } },
                    content,
                    keys && (React.createElement("span", { className: 'cds-tooltip__keys' }, keys.map((key) => (React.createElement(Detail, { size: 'small', as: 'kbd', key: key, className: 'cds-tooltip__key' }, key))))),
                    _arrow && (React.createElement("div", { ref: (node) => {
                            arrowRef.current = node;
                        }, className: 'cds-tooltip__arrow', style: {
                            left: arrowX != null ? `${arrowX}px` : '',
                            top: arrowY != null ? `${arrowY}px` : '',
                            right: '',
                            bottom: '',
                            [{
                                top: 'bottom',
                                right: 'left',
                                bottom: 'top',
                                left: 'right'
                            }[placement]]: '-3.5px'
                        } }))))))));
});
Tooltip.displayName = 'Tooltip';
export default Tooltip;
