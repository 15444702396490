import { Chip, FlexCenterDiv, Tooltip } from '@cegal/ds-components'
import { Skill } from 'declarations/models'
import styled from 'styled-components'

import { RenderedElementProps } from 'components/TagGroup/TagGroup'

const color: Array<string> = ['red', 'orangered', 'orange', 'yellowgreen', 'lightgreen', 'green', 'darkgreen']

const Proficiency = styled.div<{ $proficiency: number }>`
  line-height: 20px;
  width: 20px;
  height: 20px;
  text-align: center;
  background-color: transparent;
  margin-right: -5px;
  margin-left: 5px;
  border-radius: 50%;
  background-image: radial-gradient(var(--cds-background-color) 50%, transparent 51%),
    conic-gradient(
      ${(props) => color[props.$proficiency]} 0% ${(props) => props.$proficiency * 20}%,
      var(--cds-border-color) ${(props) => props.$proficiency * 20}% 100%
    );
`

const Years = styled.div`
  min-width: 1rem;
  text-align: center;
`

const SkillChip = ({
  tag: skill,
  size,
  interactive,
  matched,
  onClick,
  highlightName
}: RenderedElementProps<Skill>) => {
  const proficiencyTitle = [
    'User has not defined skill level in ',
    'Basic awareness of ',
    'Some experience with ',
    'Experienced with ',
    'Advanced in ',
    'Expert in '
  ]

  return (
    <Chip
      size={size}
      button={interactive}
      selected={matched}
      onClick={onClick}
      rightElement={
        <Tooltip
          variant='primary'
          arrow
          content={
            <>
              <span>
                {proficiencyTitle[skill.proficiency!]}
                {skill.name}
              </span>
              <span>{`Worked with ${skill.name} for ${skill.years} year${
                skill.years === 1 ? '' : 's'
              }`}</span>
            </>
          }
        >
          <FlexCenterDiv>
            <Proficiency $proficiency={skill.proficiency ?? 0}>
              <Years>{skill.years ?? 0}</Years>
            </Proficiency>
          </FlexCenterDiv>
        </Tooltip>
      }
    >
      {highlightName(skill.name)}
    </Chip>
  )
}

export default SkillChip
