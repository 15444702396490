var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import cl from 'clsx';
import React, { createContext, forwardRef } from 'react';
import Step from './Step';
export const StepperContext = createContext(null);
export const Stepper = forwardRef((_a, ref) => {
    var { children, className, activeStep, orientation = 'vertical', onStepChange = () => { }, interactive = true } = _a, rest = __rest(_a, ["children", "className", "activeStep", "orientation", "onStepChange", "interactive"]);
    activeStep = activeStep - 1;
    return (React.createElement("ol", Object.assign({}, rest, { ref: ref, className: cl('cds-stepper', orientation === 'horizontal' ? 'cds-stepper--horizontal' : '', className) }),
        React.createElement(StepperContext.Provider, { value: {
                activeStep,
                onStepChange,
                lastIndex: React.Children.count(children),
                orientation,
                interactive
            } }, React.Children.map(children, (step, index) => {
            var _a, _b;
            return (React.createElement("li", { className: cl('cds-stepper__item'), key: index + ((_b = (_a = children === null || children === void 0 ? void 0 : children.toString) === null || _a === void 0 ? void 0 : _a.call(children)) !== null && _b !== void 0 ? _b : '') },
                React.createElement("span", { className: 'cds-stepper__line cds-stepper__line--1' }),
                React.isValidElement(step)
                    ? React.cloneElement(step, Object.assign(Object.assign({}, step.props), { unsafeIndex: index }))
                    : step,
                React.createElement("span", { className: 'cds-stepper__line cds-stepper__line--2' })));
        }))));
});
Stepper.Step = Step;
Stepper.displayName = 'Stepper';
export default Stepper;
