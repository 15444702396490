import { ConsultantsSortOptions } from 'declarations/enum'
import { AllocationSearchResult, Consultant, ConsultantsQuery } from 'declarations/models'

import { SearchableConsultant } from 'hooks/useConsultantFilter'

import { scoreMatch } from 'utils/rank'
import { tableSort } from 'utils/sort'
import { tokenize } from 'utils/tokenizer'

import _ from 'lodash'

interface FilterConsultantsProperties {
  searchableConsultants: Array<SearchableConsultant>
  query: Partial<ConsultantsQuery>
  followedConsultants: Array<Consultant> | null | undefined
  getSubTreeIds?: (id: string) => Array<number>
  allocations: Array<AllocationSearchResult> | undefined | null
}

export const getSearchableConsultants = (consultants: Array<Consultant>) =>
  consultants?.map((consultant) => {
    const textSearch: Record<string, string[]> = {
      name: tokenize(consultant.name),
      department: tokenize(consultant.department?.name),
      // location: tokenize(consultant.location?.name),
      skills: _.flatten(consultant.skills?.map(({ name }) => tokenize(name))),
      roles: _.flatten(consultant.roles?.map(({ name }) => tokenize(name))),
      certificates: _.flatten(consultant.certificates?.map(({ name }) => tokenize(name)))
    }
    return { textSearch, consultant }
  }) ?? []

export const filterConsultants = ({
  searchableConsultants,
  query,
  followedConsultants,
  getSubTreeIds,
  allocations
}: FilterConsultantsProperties) => {
  return searchableConsultants?.filter(
    (consultantItem: SearchableConsultant): boolean => {
      if (query.followed) {
        // show followed consultants
        const needleIds = Array.isArray(followedConsultants)
          ? followedConsultants.map((c) => c.id).sort()
          : []
        const haystackIds = consultantItem.consultant ? [consultantItem.consultant.id] : []
        const intersection = _.intersection(needleIds, haystackIds)
        if (intersection.length === 0) {
          return false
        }
      }

      // show manager
      if (_.isNumber(query.manager) && _.isFunction(getSubTreeIds)) {
        const peopleUnderManager = getSubTreeIds(query.manager.toString())
        if (!peopleUnderManager.includes(consultantItem.consultant.id)) {
          return false
        }
      }

      // departments
      if (!_.isEmpty(query.departments?.toString())) {
        const needleIds = Array.isArray(query.departments) ? query.departments.sort() : [query.departments]
        const haystackIds = consultantItem.consultant.department
          ? [consultantItem.consultant.department?.id]
          : []
        const intersection = _.intersection(needleIds, haystackIds)
        if (intersection.length === 0) {
          return false
        }
      }

      // locations
      if (!_.isEmpty(query.locations?.toString())) {
        const matchAllLocations: boolean = query.allLocations === true
        const needleIds = Array.isArray(query.locations) ? query.locations.sort() : [query.locations]
        const haystackIds = consultantItem.consultant.location ? [consultantItem.consultant.location?.id] : []
        const intersection = _.intersection(needleIds, haystackIds)
        const approved = matchAllLocations
          ? intersection.length === needleIds.length
          : intersection.length > 0
        if (!approved) {
          return false
        }
      }

      // roles
      if (!_.isEmpty(query.roles?.toString())) {
        const matchAllRoles: boolean = query.allRoles === true
        const needleIds = Array.isArray(query.roles) ? query.roles.sort() : [query.roles]
        const haystackIds = consultantItem.consultant.roles
          ? consultantItem.consultant.roles.map((c) => c.id)
          : []
        const intersection = _.intersection(needleIds, haystackIds)
        const approved = matchAllRoles ? intersection.length === needleIds.length : intersection.length > 0
        if (!approved) {
          return false
        }
      }

      // skills
      if (!_.isEmpty(query.skills?.toString())) {
        const matchAllSkills: boolean = query.allSkills === true
        const needleSkillIds = Array.isArray(query.skills) ? query.skills.sort() : [query.skills]
        const haystackSkillIds = consultantItem.consultant.skills.map((s) => s.id as number).sort()
        const intersection = _.intersection(needleSkillIds, haystackSkillIds)
        const approved = matchAllSkills
          ? intersection.length === needleSkillIds.length
          : intersection.length > 0
        if (!approved) {
          return false
        }
      }

      // Certificates
      if (!_.isEmpty(query.certificates?.toString())) {
        const matchAllCertificates: boolean = query.allCertificates === true
        const needleCertificateIds = Array.isArray(query.certificates)
          ? query.certificates.sort()
          : [query.certificates]
        const haystackCertificateIds = consultantItem.consultant.certificates
          .map((c) => c.id as number)
          .sort()
        const intersection = _.intersection(needleCertificateIds, haystackCertificateIds)
        const approved = matchAllCertificates
          ? intersection.length === needleCertificateIds.length
          : intersection.length > 0
        if (!approved) {
          return false
        }
      }

      if (query.search && query.search.length > 1) {
        // if free text search terms: reject those who score 0
        consultantItem.score = rankConsultant(consultantItem, query)
        if (consultantItem.score === 0) {
          return false
        }
      }

      if (!_.isEmpty(query.allocation) && !_.isEmpty(allocations)) {
        const match = allocations?.find(
          (allocation: AllocationSearchResult): boolean =>
            allocation.consultant_id === consultantItem.consultant.id
        )
        if (!match) {
          return false
        }
      }

      return true
    }
    // then sort the remaining elements
  )
}

export const sortConsultants = (query: Partial<ConsultantsQuery>) => {
  return (a: SearchableConsultant, b: SearchableConsultant) => {
    // first, table sort
    if (query.sort) {
      return tableSort(query.sort, a, b, 'consultant', (a, b) =>
        b.score - a.score > 0 ? 1 : b.score - a.score < 0 ? -1 : 0
      )
    }

    if (query.metaSort) {
      return metaSort(query, a, b, (a, b) => (b.score - a.score > 0 ? 1 : b.score - a.score < 0 ? -1 : 0))
    }
    return b.score! - a.score! > 0 ? 1 : b.score! - a.score! < 0 ? -1 : 0
  }
}

export const metaSort = (
  query: Partial<ConsultantsQuery>,
  a: any,
  b: any,
  otherComparator: (a: any, b: any) => number
) => {
  if (query.metaSort === ConsultantsSortOptions.SKILL && !_.isEmpty(query.skills)) {
    const _skills = Array.isArray(query.skills)
      ? query.skills
      : _.isNumber(query.skills)
        ? [query.skills]
        : []
    const consultantAScore = _.reduce(
      a.consultant.skills,
      (val, skill) => {
        if (_skills?.indexOf(skill.id as number) >= 0) {
          return val + (skill.years ?? 1)
        }
        return val
      },
      0
    )
    const consultantBScore = _.reduce(
      b.consultant.skills,
      (val, skill) => {
        if (_skills?.indexOf(skill.id as number) >= 0) {
          return val + (skill.years ?? 1)
        }
        return val
      },
      0
    )
    const value = consultantBScore - consultantAScore
    if (value !== 0) {
      return value
    }
  }
  /*
'sort-client-first'
'sort-followed-first'
*/
  return otherComparator(a, b)
}

export const rankConsultant = (c: SearchableConsultant, query: Partial<ConsultantsQuery>): number => {
  let score = 0
  if (_.isNil(query?.search) || query?.search.length === 0) {
    return score
  }

  let textScore = 0.0
  let maxTextScore = 0.0

  // text match
  tokenize(query.search).forEach((token) => {
    if (token.length > 1) {
      maxTextScore += 2 * token.length
      c.textSearch.name?.forEach((name) => {
        textScore += scoreMatch(name, token)
      })

      maxTextScore += 2 * token.length
      c.textSearch.roles?.forEach((name) => {
        textScore += scoreMatch(name, token)
      })

      maxTextScore += 2 * token.length
      c.textSearch.certificates?.forEach((name) => {
        textScore += scoreMatch(name, token)
      })

      maxTextScore += token.length
      c.textSearch.department?.forEach((name) => {
        textScore += 0.5 * scoreMatch(name, token)
      })
    }
  })
  const normalisedTextScore = textScore / maxTextScore

  // normalised => value between 0 and 1
  let normalisedSkillScore

  if (!_.isEmpty(query.skills)) {
    const needleSkillIds = Array.isArray(query.skills) ? query.skills.sort() : [query.skills]
    const haystackSkillIds = c.consultant.skills.map((s) => s.id as number).sort()
    const intersectionSkills = _.intersection(needleSkillIds, haystackSkillIds)
    normalisedSkillScore = intersectionSkills.length / haystackSkillIds.length
  } else {
    normalisedSkillScore = 0
  }

  // final weight: 2 text match to 1 skill match
  score = 2 * normalisedTextScore + normalisedSkillScore
  return score
}
