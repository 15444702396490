import {
  Button,
  Datepicker,
  FlexCenterDiv,
  FlexEndDiv,
  HorizontalSpace,
  PaddedDiv,
  Switch,
  TextField,
  VerticalSpace
} from '@cegal/ds-components'
import { Certificate, Client, Skill, StringEntry } from 'declarations/models'
import { eventLogger, standardLogger } from 'metrics/loggers'
import { useAppSelector } from 'store'

import FacetedFilterSection from 'components/Forms/FacetedFilterSection/FacetedFilterSection'
import FacetedFilterSectionMeta from 'components/Forms/FacetedFilterSection/FacetedFilterSectionMeta'
import FacetedFilterSectionWrapper from 'components/Forms/FacetedFilterSection/FacetedFilterSectionWrapper'
import { DrawerButtonsDiv } from 'components/styled'

import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useQuery from 'hooks/useQuery'

import {
  getCertificatesFromEngagements,
  getClientsFromEngagements,
  getLocationsFromEngagements,
  getSkillsFromEngagements
} from 'utils/engagements'
import { Option, OptionsSortBy, dataToOptionsSortedByOccurrence } from 'utils/fieldUtils'
import { initialEngagementsQuery } from 'utils/query'

import _ from 'lodash'

export type EngagementFilterContext = 'engagements' | 'matches'

type EngagementsFilterProps = {
  context: EngagementFilterContext
  onClose: () => void
}

const EngagementsFilter = ({ context, onClose }: EngagementsFilterProps) => {
  const { t } = useTranslation()
  const language = useAppSelector((state) => state.app.settings.language)
  const [skillsOptions, setSkillsOptions] = useState<Array<Option<Skill>> | undefined>(undefined)
  const [clientsOptions, setClientsOptions] = useState<Array<Option<Client>> | undefined>(undefined)
  // const [sourcesOptions, setSourcesOptions] = useState<Array<Option<Source>> | undefined>(undefined)
  const [locationsOptions, setLocationsOptions] = useState<Array<Option<StringEntry>> | undefined>(undefined)
  const [postedDateDays, setPostedDateDays] = useState<number>(1)
  const [startDatePicker, setStartDateDatepicker] = useState<string>('')
  const [endDatePicker, setEndDateDatepicker] = useState<string>('')
  const [deadlineMonths, setDeadlineMonths] = useState<number>(1)
  const [certificatesOptions, setCertificatesOptions] = useState<Array<Option<Certificate>> | undefined>(
    undefined
  )
  const { query, setQuery } = useQuery({})
  const [showDateFilter, setShowDateFilter] = useState<boolean>(true)
  const [showOptionsFilter, setShowOptionsFilter] = useState<boolean>(true)
  const [collapsed, setCollapsed] = useState<boolean>(false)

  const engagements = useAppSelector((state) => state.engagements.list)

  const selectedSkills: Array<Option<Skill>> = useMemo(
    () =>
      skillsOptions?.filter((s: Skill) =>
        Array.isArray(query?.skills) ? query?.skills?.includes(s.id as number) : query?.skills === s.id
      ) ?? [],
    [skillsOptions, query?.skills]
  )

  const selectedClients: Array<Option<Client>> = useMemo(
    () =>
      clientsOptions?.filter((c: Client) =>
        Array.isArray(query?.clients) ? query?.clients?.includes(c.id as number) : query?.clients === c.id
      ) ?? [],
    [clientsOptions, query?.clients]
  )

  const selectedCertificates: Array<Option<Certificate>> = useMemo(
    () =>
      certificatesOptions?.filter((c: Certificate) =>
        Array.isArray(query?.certificates)
          ? query?.certificates?.includes(c.id as number)
          : query?.certificates === c.id
      ) ?? [],
    [certificatesOptions, query?.certificates]
  )

  /* const selectedSources: Array<Option<Source>> = useMemo(
    () =>
      sourcesOptions?.filter((s: Source) =>
        Array.isArray(query?.sources) ? query?.sources?.includes(s.id) : query?.sources === s.id
      ) ?? [],
    [sourcesOptions, query?.sources]
  ) */

  const selectedLocations: Array<Option<StringEntry>> = useMemo(
    () =>
      locationsOptions?.filter((l: StringEntry) =>
        Array.isArray(query?.locations) ? query?.locations?.includes(l.id) : query?.locations === l.id
      ) ?? [],
    [locationsOptions, query?.locations]
  )

  useEffect(() => {
    if (!_.isEmpty(engagements)) {
      const _skillsOptions = dataToOptionsSortedByOccurrence<Skill, 'id', 'name'>({
        data: getSkillsFromEngagements(engagements!) || [],
        valueSelector: 'id',
        labelSelector: 'name',
        showOccurrence: true,
        sortBy: OptionsSortBy.OCCURRENCE_DESC
      })
      setSkillsOptions(_skillsOptions)

      const _certificatesOptions = dataToOptionsSortedByOccurrence<Certificate, 'id', 'name'>({
        data: getCertificatesFromEngagements(engagements!) || [],
        valueSelector: 'id',
        labelSelector: 'name',
        showOccurrence: true,
        sortBy: OptionsSortBy.OCCURRENCE_DESC
      })
      setCertificatesOptions(_certificatesOptions)

      const _clientsOptions = dataToOptionsSortedByOccurrence<Client, 'id', 'name'>({
        data: getClientsFromEngagements(engagements!) || [],
        valueSelector: 'id',
        labelSelector: 'name',
        showOccurrence: true,
        sortBy: OptionsSortBy.OCCURRENCE_DESC
      })
      setClientsOptions(_clientsOptions)

      /* const _sourcesOptions = dataToOptionsSortedByOccurrence<Source, 'id', 'name'>({
        data: getSourcesFromEngagements(engagements!) || [],
        valueSelector: 'id',
        labelSelector: 'name',
        showOccurrence: true,
        sortBy: OptionsSortBy.OCCURRENCE_DESC
      })
      setSourcesOptions(_sourcesOptions) */

      const _locationsOptions = dataToOptionsSortedByOccurrence<StringEntry, 'id', 'name'>({
        data: getLocationsFromEngagements(engagements!) || [],
        valueSelector: 'id',
        labelSelector: 'name',
        showOccurrence: true,
        sortBy: OptionsSortBy.OCCURRENCE_DESC
      })
      setLocationsOptions(_locationsOptions)
    }
  }, [engagements])

  const handleFollowed = (e: React.ChangeEvent<HTMLInputElement>) => {
    standardLogger('engagements.filters.followed')
    setQuery({ followed: e.target.checked })
  }

  const handleMine = (e: React.ChangeEvent<HTMLInputElement>) => {
    standardLogger('engagements.filters.mine')
    setQuery({ mine: e.target.checked })
  }

  const handlePostedDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    standardLogger('engagements.filters.posteddate')
    setQuery({ posteddate: e.target.checked ? postedDateDays : undefined })
  }

  const handlePostedDateDays = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPostedDateDays(parseInt(e.target.value))
    setQuery({ posteddate: parseInt(e.target.value) })
  }

  const handleStartDateMonth = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDateDatepicker(e.target.value)
    standardLogger('engagements.filters.startdate')
    setQuery({ startdate: e.target.value })
  }

  const handleEndDateMonth = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDateDatepicker(e.target.value)
    standardLogger('engagements.filters.enddate')
    setQuery({ enddate: e.target.value })
  }

  const handleDeadline = (e: React.ChangeEvent<HTMLInputElement>) => {
    standardLogger('engagements.filters.deadline')
    setQuery({ deadline: e.target.checked ? deadlineMonths : undefined })
  }

  const handleDeadlineMonth = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDeadlineMonths(parseInt(e.target.value))
    setQuery({ deadline: parseInt(e.target.value) })
  }

  const handleHideEndedEngagements = (e: React.ChangeEvent<HTMLInputElement>) => {
    standardLogger('engagements.filters.hideendedengagements')
    setQuery({ hideEndedEngagements: e.target.checked })
  }

  const handleAllClients = (e: React.ChangeEvent<HTMLInputElement>) => {
    standardLogger('engagements.filters.clients.mode')
    setQuery({ allClients: e.target.checked })
  }

  const handleClients = (clientOptions: Array<Option<Client>>) => {
    standardLogger('engagements.filters.clients')
    setQuery({ clients: clientOptions.map((option) => option.id as number) })
  }

  /* const handleSources = (sourceOptions: Array<Option<Source>>) => {
    standardLogger('engagements.filters.sources')
    setQuery({ sources: sourceOptions.map((option) => option.id as number) })
  } */

  const handleAllLocations = (e: React.ChangeEvent<HTMLInputElement>) => {
    standardLogger('engagements.filters.locations.mode')
    setQuery({ allLocations: e.target.checked })
  }

  const handleLocations = (locationOptions: Array<Option<StringEntry>>) => {
    standardLogger('engagements.filters.locations')
    setQuery({ locations: locationOptions.map((option) => option.id as string) })
  }

  const handleUnfilledVacancies = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newHideFilledVacancies: boolean | undefined =
      e.target.checked && query?.hideFilledVacancies ? false : query?.hideFilledVacancies
    standardLogger('engagements.filters.vacations.unfilled')
    setQuery({
      hideUnfilledVacancies: e.target.checked,
      hideFilledVacancies: newHideFilledVacancies
    })
  }

  const handleFilledVacancies = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newHideUnfilledVacancies: boolean | undefined =
      e.target.checked && query?.hideUnfilledVacancies ? false : query?.hideUnfilledVacancies
    standardLogger('engagements.filters.vacations.filled')
    setQuery({
      hideFilledVacancies: e.target.checked,
      hideUnfilledVacancies: newHideUnfilledVacancies
    })
  }

  const handleAllSkills = (e: React.ChangeEvent<HTMLInputElement>) => {
    standardLogger('engagements.filters.skills.mode')
    setQuery({ allSkills: e.target.checked })
  }

  const handleSkills = (skillOptions: Array<Option<Skill>>) => {
    standardLogger('engagements.filters.skills')
    setQuery({ skills: skillOptions.map((option) => option.id as number) })
  }

  const handleCertificates = (certificateOptions: Array<Option<Certificate>>) => {
    standardLogger('engagements.filters.certificates')
    setQuery({ certificates: certificateOptions.map((option) => option.id as number) })
  }

  const handleAllCertificates = (e: React.ChangeEvent<HTMLInputElement>) => {
    standardLogger(context + '.filters.certificates.mode')
    setQuery({ allCertificates: e.target.checked })
  }

  const handleClear = (e: any) => {
    eventLogger(e)
    setQuery(initialEngagementsQuery)
  }

  const handleClose = (e: any) => {
    eventLogger(e)
    onClose()
  }

  const handleCollapse = () => {
    if (showDateFilter && showOptionsFilter) {
      setShowDateFilter(false)
      setShowOptionsFilter(false)
      setCollapsed(true)
    } else {
      setShowDateFilter(true)
      setShowOptionsFilter(true)
      setCollapsed(false)
    }
  }

  return (
    <PaddedDiv>
      <FacetedFilterSectionMeta
        header={t('header:filter-options')}
        collapsed={collapsed}
        onCollapse={handleCollapse}
      />
      <VerticalSpace />

      <FacetedFilterSectionWrapper label={t('buttons:options')} collapsed={collapsed}>
        <Switch size='small' position='left' onChange={handleFollowed} checked={!!query?.followed}>
          {t('label:filter-show-only-followed-engagements')}
        </Switch>
        <Switch size='small' position='left' onChange={handleMine} checked={!!query?.mine}>
          {t('label:filter-show-only-my-engagements')}
        </Switch>
        <Switch
          size='small'
          data-cy='endedVacanciesToggle'
          position='left'
          onChange={handleHideEndedEngagements}
          checked={!!query?.hideEndedEngagements}
        >
          {t('label:filter-hide-ended-engagements')}
        </Switch>
        <Switch
          size='small'
          position='left'
          onChange={handleUnfilledVacancies}
          checked={!!query?.hideUnfilledVacancies}
        >
          {t('label:filter-hide-unfilled-vacancies')}
        </Switch>
        <Switch
          size='small'
          position='left'
          onChange={handleFilledVacancies}
          checked={!!query?.hideFilledVacancies}
        >
          {t('label:filter-hide-filled-vacancies')}
        </Switch>
      </FacetedFilterSectionWrapper>

      <FacetedFilterSectionWrapper label={t('label:dates-title')} collapsed={collapsed}>
        <Datepicker
          size='small'
          lang={language}
          label={t('label:start-date-title')}
          value={startDatePicker}
          type='date'
          onChange={handleStartDateMonth}
        />
        <VerticalSpace />
        <Datepicker
          size='small'
          label={t('label:end-date-title')}
          value={endDatePicker}
          type='date'
          onChange={handleEndDateMonth}
        />
        <VerticalSpace />
        <Switch
          size='small'
          position='left'
          onChange={handlePostedDate}
          checked={_.isNumber(query?.posteddate)}
        >
          <FlexCenterDiv>
            {t('label:filter-created-in-the-past')}
            <HorizontalSpace size='0.5' />
            <TextField
              value={postedDateDays.toString()}
              type='number'
              min={1}
              size='small'
              hideLabel
              label=''
              onChange={handlePostedDateDays}
              style={{ width: '4rem' }}
            />
            <HorizontalSpace size='0.5' />
            {postedDateDays === 1 ? t('label:day').toLowerCase() : t('label:days').toLowerCase()}
          </FlexCenterDiv>
        </Switch>
        <Switch size='small' position='left' onChange={handleDeadline} checked={_.isNumber(query?.deadline)}>
          <FlexCenterDiv>
            {t('label:filter-deadline-in')}
            <HorizontalSpace size='0.5' />
            <TextField
              value={deadlineMonths.toString()}
              type='number'
              min={1}
              size='small'
              hideLabel
              label=''
              onChange={handleDeadlineMonth}
              style={{ width: '4rem' }}
            />
            <HorizontalSpace size='0.5' />
            {deadlineMonths === 1 ? t('label:month').toLowerCase() : t('label:months').toLowerCase()}
          </FlexCenterDiv>
        </Switch>
      </FacetedFilterSectionWrapper>
      <VerticalSpace />
      <FacetedFilterSection
        onChange={handleClients}
        options={clientsOptions}
        label={t('label:clients')}
        value={selectedClients}
        collapsed={collapsed}
        showSearchButton
        showSettingsButton
        showClearButton
        settingsProps={{
          onChange: handleAllClients,
          checked: query?.allClients,
          switchText: query?.allClients ? t('label:filter-all-clients') : t('label:filter-some-clients')
        }}
      />
      <VerticalSpace />
      {/* <FacetedFilterSection
        onChange={handleSources}
        options={sourcesOptions}
        label={t('label:sources')}
        value={selectedSources}
        collapsed={collapsed}
        showSearchButton
        showSettingsButton
        showClearButton
        settingsProps={{
          onChange: handleAllSources,
          checked: query?.allSources,
          switchText: query?.allSources ? t('label:filter-all-sources') : t('label:filter-some-sources')
        }}
      />
      <VerticalSpace />
      */}
      <FacetedFilterSection
        onChange={handleLocations}
        options={locationsOptions}
        label={t('label:locations-title')}
        value={selectedLocations}
        collapsed={collapsed}
        showSearchButton
        showSettingsButton
        showClearButton
        settingsProps={{
          onChange: handleAllLocations,
          checked: query?.allLocations,
          switchText: query?.allLocations ? t('label:filter-all-locations') : t('label:filter-some-locations')
        }}
      />
      <VerticalSpace />
      <FacetedFilterSection
        onChange={handleCertificates}
        options={certificatesOptions}
        label={t('label:certificates')}
        value={selectedCertificates}
        collapsed={collapsed}
        showSearchButton
        showSettingsButton
        showClearButton
        settingsProps={{
          onChange: handleAllCertificates,
          checked: query?.allCertificates,
          switchText: query?.allCertificates
            ? t('label:filter-all-certificates')
            : t('label:filter-some-certificates')
        }}
      />
      <VerticalSpace />
      <FacetedFilterSection
        onChange={handleSkills}
        options={skillsOptions}
        label={t('label:skills')}
        value={selectedSkills}
        collapsed={collapsed}
        showSearchButton
        showSettingsButton
        showClearButton
        settingsProps={{
          onChange: handleAllSkills,
          checked: query?.allSkills,
          switchText: query?.allSkills ? t('label:filter-all-skills') : t('label:filter-some-skills')
        }}
      />
      <VerticalSpace size='4' />
      <DrawerButtonsDiv>
        <FlexEndDiv>
          <Button data-amplitude='engagements.filters.clear' variant='secondary' onClick={handleClear}>
            {t('buttons:reset')}
          </Button>
          <HorizontalSpace />
          <Button data-amplitude='engagements.filters.close' onClick={handleClose}>
            {t('buttons:close')}
          </Button>
        </FlexEndDiv>
      </DrawerButtonsDiv>
    </PaddedDiv>
  )
}

export default EngagementsFilter
