import { FlexDiv, Heading, HorizontalSpace, Label, PileDiv, VerticalSpace } from '@cegal/ds-components'
import { Client } from 'declarations/models'
import mockClients from 'mocks/client'
import mockConsultant from 'mocks/consultant'
import mockEngagement from 'mocks/engagement'
import mockMatches from 'mocks/matches'

import { ClientCard } from 'components/Clients/Client'
import { ConsultantCard, ConsultantDetail, ConsultantSignature } from 'components/Consultants/Consultant'
import { EngagementCard, EngagementDetail } from 'components/Engagements/Engagement'
import EngagementVacancyConsultantCard from 'components/Engagements/Engagement/Vacancy/Consultant/EngagementVacancyConsultantCard'
import EngagementVacancyCard from 'components/Engagements/Engagement/Vacancy/EngagementVacancyCard'
import { FollowButton, MatchButton } from 'components/Forms/Buttons'
import PageContent from 'components/PageContent/PageContent'
import ToC, { ToCDiv } from 'components/ToC/ToC'

import { useRef } from 'react'

import useHeadings from 'hooks/useHeadings'
import useScrollSpy from 'hooks/useScrollSpy'

const Cards = () => {
  const mockButtonsMedium = [
    <MatchButton item={{} as any} key='match-button' onMatch={() => {}} />,
    <FollowButton followedItems={[]} key='follow-button' onUnfollow={() => {}} />
  ]

  const mockButtonsSmall = [
    <MatchButton text={false} item={{} as any} key='match-button' onMatch={() => {}} />,
    <FollowButton text={false} followedItems={[]} key='follow-button' onUnfollow={() => {}} />
  ]

  const contentRef = useRef(null)
  const headings = useHeadings(contentRef)
  const activeId = useScrollSpy(
    headings.map(({ id }) => id),
    {
      root: null, // default, use viewport
      rootMargin: '0px 0px -50% 0px',
      threshold: 0.5 // half of item height
    }
  )

  return (
    <PageContent maxWidth='1600px'>
      <FlexDiv style={{ flexWrap: 'nowrap' }}>
        <PileDiv>
          <FlexDiv flex='1'>
            <ToCDiv>
              <ToC headings={headings} activeId={activeId} />
            </ToCDiv>
          </FlexDiv>
        </PileDiv>
        <HorizontalSpace />
        <div ref={contentRef}>
          <Heading className='toc' level='1' id='card'>
            1. Cards
          </Heading>
          <VerticalSpace />
          <Heading className='toc' level='2' id='card.consultants'>
            1.1 Consultants
          </Heading>
          <VerticalSpace />
          <Heading className='toc' level='3' id='card.consultants.xsmall' size='small'>
            1.1.1 xsmall
          </Heading>
          <hr />
          <VerticalSpace />
          <PileDiv>
            <Label spacing>Default</Label>
            <FlexDiv>
              <ConsultantCard size='xsmall' spacing item={mockConsultant} />
            </FlexDiv>
          </PileDiv>
          <VerticalSpace />
          <Heading className='toc' level='3' id='cards.consultants.small' size='small'>
            1.1.2 small
          </Heading>
          <hr />
          <VerticalSpace />
          <FlexDiv style={{ flexWrap: 'wrap' }}>
            <PileDiv style={{ maxWidth: '400px', breakInside: 'avoid' }}>
              <Label spacing>Default</Label>
              <FlexDiv>
                <ConsultantCard size='small' spacing item={mockConsultant} />
              </FlexDiv>
            </PileDiv>
            <HorizontalSpace />
            <PileDiv style={{ maxWidth: '400px', breakInside: 'avoid' }}>
              <Label spacing>With onView</Label>
              <FlexDiv>
                <ConsultantCard size='small' spacing item={mockConsultant} onView={() => {}} />
              </FlexDiv>
            </PileDiv>
            <HorizontalSpace />
            <PileDiv style={{ maxWidth: '400px', breakInside: 'avoid' }}>
              <Label spacing>With custom buttons</Label>
              <FlexDiv>
                <ConsultantCard
                  size='small'
                  spacing
                  item={mockConsultant}
                  onView={() => {}}
                  buttons={mockButtonsSmall}
                />
              </FlexDiv>
            </PileDiv>
            <HorizontalSpace />
            <PileDiv style={{ maxWidth: '400px', breakInside: 'avoid' }}>
              <Label spacing>With custom buttons and onEdit / onDelete</Label>
              <FlexDiv>
                <ConsultantCard
                  size='small'
                  spacing
                  item={mockConsultant}
                  onView={() => {}}
                  onEdit={() => {}}
                  onDelete={() => {}}
                  buttons={mockButtonsSmall}
                />
              </FlexDiv>
            </PileDiv>
          </FlexDiv>
          <VerticalSpace />
          <Heading className='toc' level='3' id='cards.consultants.medium' size='small'>
            1.1.3 medium
          </Heading>
          <hr />
          <VerticalSpace />
          <FlexDiv style={{ flexWrap: 'wrap' }}>
            <PileDiv style={{ maxWidth: '400px', breakInside: 'avoid' }}>
              <Label spacing>Default</Label>
              <FlexDiv>
                <ConsultantCard size='medium' spacing item={mockConsultant} />
              </FlexDiv>
            </PileDiv>
            <HorizontalSpace />
            <PileDiv style={{ maxWidth: '400px', breakInside: 'avoid' }}>
              <Label spacing>With onView, onEdit, onDelete, custom buttons</Label>
              <FlexDiv>
                <ConsultantCard
                  size='medium'
                  spacing
                  item={mockConsultant}
                  onView={() => {}}
                  onEdit={() => {}}
                  onDelete={() => {}}
                  buttons={mockButtonsMedium}
                />
              </FlexDiv>
            </PileDiv>
          </FlexDiv>
          <Heading className='toc' level='3' id='cards.consultants.detail' size='small'>
            1.1.4 detail
          </Heading>
          <hr />
          <VerticalSpace />
          <FlexDiv style={{ flexWrap: 'wrap' }}>
            <PileDiv style={{ maxWidth: '400px', breakInside: 'avoid' }}>
              <Label spacing>Default</Label>
              <FlexDiv>
                <ConsultantDetail consultant={mockConsultant} />
              </FlexDiv>
            </PileDiv>
            <HorizontalSpace />
            <PileDiv style={{ maxWidth: '400px', breakInside: 'avoid' }}>
              <Label spacing>With no engagements</Label>
              <FlexDiv>
                <ConsultantDetail showEngagements={false} consultant={mockConsultant} />
              </FlexDiv>
            </PileDiv>
          </FlexDiv>
          <VerticalSpace size='3' />
          <Heading className='toc' level='2' id='cards.engagement'>
            1.2. Engagements
          </Heading>

          <VerticalSpace />
          <Heading className='toc' level='3' id='cards.engagement.small' size='small'>
            1.2.1 small
          </Heading>
          <hr />
          <VerticalSpace />
          <FlexDiv style={{ flexWrap: 'wrap' }}>
            <PileDiv>
              <Label spacing>Default</Label>
              <FlexDiv>
                <EngagementCard size='small' spacing item={mockEngagement} />
              </FlexDiv>
            </PileDiv>
            <HorizontalSpace />
            <PileDiv>
              <Label spacing>With custom buttons</Label>
              <FlexDiv>
                <EngagementCard size='small' spacing item={mockEngagement} buttons={mockButtonsSmall} />
              </FlexDiv>
            </PileDiv>
          </FlexDiv>
          <VerticalSpace />
          <Heading className='toc' level='3' id='cards.engagement.medium' size='small'>
            1.2.2 medium
          </Heading>
          <hr />
          <VerticalSpace />
          <FlexDiv style={{ flexWrap: 'wrap' }}>
            <PileDiv style={{ maxWidth: '400px', breakInside: 'avoid' }}>
              <EngagementCard size='medium' spacing item={mockEngagement} buttons={mockButtonsMedium} />
            </PileDiv>
          </FlexDiv>
          <VerticalSpace />
          <Heading className='toc' level='3' id='cards.engagement.detail' size='small'>
            1.2.3 detail
          </Heading>
          <hr />
          <VerticalSpace />
          <FlexDiv style={{ flexWrap: 'wrap' }}>
            <PileDiv style={{ maxWidth: '400px', breakInside: 'avoid' }}>
              <Label spacing>Default</Label>

              <EngagementDetail engagement={mockEngagement} />
            </PileDiv>
            <HorizontalSpace />
            <PileDiv style={{ maxWidth: '400px', breakInside: 'avoid' }}>
              <Label spacing>With no vacancies</Label>

              <EngagementDetail showVacancies={false} engagement={mockEngagement} />
            </PileDiv>
          </FlexDiv>
          <VerticalSpace size='3' />
          <Heading className='toc' level='2' id='cards.engagementvacancy'>
            1.3. Engagement vacancy
          </Heading>
          <VerticalSpace />
          <Heading className='toc' level='3' id='cards.engagementvacancy.small' size='small'>
            1.3.1 small
          </Heading>
          <hr />
          <VerticalSpace />
          <FlexDiv style={{ flexWrap: 'wrap' }}>
            <PileDiv style={{ maxWidth: '400px', breakInside: 'avoid' }}>
              <Label spacing>Default</Label>
              <FlexDiv>
                <EngagementVacancyCard size='small' item={mockEngagement.vacancies[0]} spacing />
              </FlexDiv>
            </PileDiv>
            <HorizontalSpace />
            <PileDiv style={{ maxWidth: '400px', breakInside: 'avoid' }}>
              <Label spacing>With engagement title, edit and delete</Label>
              <FlexDiv>
                <EngagementVacancyCard
                  size='small'
                  item={{
                    ...mockEngagement.vacancies[0],
                    engagement: {
                      id: 1,
                      title: 'Engagement title'
                    }
                  }}
                  onEdit={() => {}}
                  onDelete={() => {}}
                  spacing
                />
              </FlexDiv>
            </PileDiv>
            <HorizontalSpace />
            <PileDiv style={{ maxWidth: '400px', breakInside: 'avoid' }}>
              <Label spacing>With loading and edited</Label>
              <FlexDiv>
                <EngagementVacancyCard
                  size='small'
                  loading
                  edited
                  item={mockEngagement.vacancies[0]}
                  spacing
                />
              </FlexDiv>
            </PileDiv>
            <HorizontalSpace />
            <PileDiv style={{ maxWidth: '400px', breakInside: 'avoid' }}>
              <Label spacing>With added</Label>
              <FlexDiv>
                <EngagementVacancyCard size='small' added item={mockEngagement.vacancies[0]} spacing />
              </FlexDiv>
            </PileDiv>
          </FlexDiv>
          <VerticalSpace />
          <Heading className='toc' level='3' id='cards.engagementvacancy.medium' size='small'>
            1.3.2 medium
          </Heading>
          <hr />
          <VerticalSpace />
          <FlexDiv style={{ flexWrap: 'wrap' }}>
            <PileDiv style={{ maxWidth: '400px', breakInside: 'avoid' }}>
              <Label spacing>default</Label>
              <EngagementVacancyCard size='medium' item={mockEngagement.vacancies[0]} spacing />
            </PileDiv>
            <HorizontalSpace />
            <PileDiv style={{ maxWidth: '400px', breakInside: 'avoid' }}>
              <Label spacing>with edit and delete</Label>
              <EngagementVacancyCard
                size='medium'
                item={mockEngagement.vacancies[0]}
                spacing
                onEdit={() => {}}
                onDelete={() => {}}
              />
            </PileDiv>
          </FlexDiv>
          <VerticalSpace size='3' />
          <Heading className='toc' level='2' id='cards.engagementvacancyconsultant'>
            1.4. Engagement vacancy consultant
          </Heading>
          <VerticalSpace />
          <Heading className='toc' level='3' id='cards.engagementvacancyconsultant.small' size='small'>
            1.4.1 small
          </Heading>
          <hr />
          <VerticalSpace />
          <FlexDiv style={{ flexWrap: 'wrap' }}>
            <PileDiv style={{ maxWidth: '400px', breakInside: 'avoid' }}>
              <Label spacing>Default</Label>
              <FlexDiv>
                <EngagementVacancyConsultantCard size='small' spacing item={mockMatches[0]} />
              </FlexDiv>
            </PileDiv>
            <HorizontalSpace />
            <PileDiv style={{ maxWidth: '400px', breakInside: 'avoid' }}>
              <Label spacing>With edit and delete</Label>
              <FlexDiv>
                <EngagementVacancyConsultantCard
                  size='small'
                  spacing
                  item={mockMatches[0]}
                  onEdit={() => {}}
                  onDelete={() => {}}
                />
              </FlexDiv>
            </PileDiv>
            <HorizontalSpace />
            <PileDiv style={{ maxWidth: '400px', breakInside: 'avoid' }}>
              <Label spacing>With added</Label>
              <FlexDiv>
                <EngagementVacancyConsultantCard size='small' spacing item={mockMatches[0]} added />
              </FlexDiv>
            </PileDiv>
            <HorizontalSpace />
            <PileDiv style={{ maxWidth: '400px', breakInside: 'avoid' }}>
              <Label spacing>With loading and edited</Label>
              <FlexDiv>
                <EngagementVacancyConsultantCard size='small' spacing item={mockMatches[0]} edited loading />
              </FlexDiv>
            </PileDiv>
          </FlexDiv>
          <VerticalSpace />
          <Heading className='toc' level='3' id='cards.engagementvacancyconsultant.medium' size='small'>
            1.4.2 medium
          </Heading>
          <hr />
          <VerticalSpace />
          <FlexDiv style={{ flexWrap: 'wrap' }}>
            <PileDiv style={{ maxWidth: '400px', breakInside: 'avoid' }}>
              <Label spacing>Default</Label>
              <FlexDiv>
                <EngagementVacancyConsultantCard size='medium' spacing item={mockMatches[0]} />
              </FlexDiv>
            </PileDiv>
            <HorizontalSpace />
            <PileDiv style={{ maxWidth: '400px', breakInside: 'avoid' }}>
              <Label spacing>With onEdit and onDelete</Label>
              <FlexDiv>
                <EngagementVacancyConsultantCard
                  size='medium'
                  spacing
                  item={mockMatches[0]}
                  onEdit={() => {}}
                  onDelete={() => {}}
                />
              </FlexDiv>
            </PileDiv>
            <HorizontalSpace />
            <PileDiv style={{ maxWidth: '400px', breakInside: 'avoid' }}>
              <Label spacing>With added</Label>
              <FlexDiv>
                <EngagementVacancyConsultantCard size='medium' spacing item={mockMatches[0]} added />
              </FlexDiv>
            </PileDiv>
            <HorizontalSpace />
            <PileDiv style={{ maxWidth: '400px', breakInside: 'avoid' }}>
              <Label spacing>With edited and loading</Label>
              <FlexDiv>
                <EngagementVacancyConsultantCard size='medium' spacing item={mockMatches[0]} edited loading />
              </FlexDiv>
            </PileDiv>
          </FlexDiv>
          <VerticalSpace size='3' />
          <Heading className='toc' level='2' id='cards.clients'>
            1.6. Clients
          </Heading>
          <VerticalSpace />
          <Heading className='toc' level='3' id='cards.clients.small' size='small'>
            1.6.1 small
          </Heading>
          <hr />
          <VerticalSpace />
          <FlexDiv style={{ flexWrap: 'wrap' }}>
            <PileDiv style={{ maxWidth: '400px', breakInside: 'avoid' }}>
              <Label>Default</Label>
              <ClientCard size='small' spacing item={mockClients as Client} />
            </PileDiv>
          </FlexDiv>
          <VerticalSpace />
          <Heading className='toc' level='3' id='cards.clients.medium' size='medium'>
            1.6.2 medium
          </Heading>
          <hr />
          <VerticalSpace />
          <FlexDiv style={{ flexWrap: 'wrap' }}>
            <PileDiv style={{ maxWidth: '400px', breakInside: 'avoid' }}>
              <Label>Default</Label>
              <ClientCard size='medium' spacing item={mockClients as Client} />
            </PileDiv>
          </FlexDiv>
          <VerticalSpace />
          <Heading className='toc' level='1' id='note'>
            2. Note
          </Heading>
          <VerticalSpace />
          <Heading className='toc' level='1' id='comment'>
            3. Client comments
          </Heading>
          <VerticalSpace />
          <Heading className='toc' level='1' id='sync'>
            4. Syncing
          </Heading>
          <VerticalSpace />
          <Heading className='toc' level='1' id='signature'>
            5 Signature
          </Heading>
          <VerticalSpace />
          <PileDiv>
            <FlexDiv>
              <ConsultantSignature consultant={mockConsultant} />
            </FlexDiv>
          </PileDiv>
        </div>
      </FlexDiv>
    </PageContent>
  )
}

export default Cards
