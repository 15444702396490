var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import cl from 'clsx';
import React, { forwardRef, useContext } from 'react';
import { DropdownContext } from 'components/dropdown';
export const GroupedItem = forwardRef((_a, ref) => {
    var { as: Component = 'button', className } = _a, rest = __rest(_a, ["as", "className"]);
    const context = useContext(DropdownContext);
    return (React.createElement("dd", { className: 'cds-dropdown__list-item' },
        React.createElement(Component, Object.assign({}, rest, { ref: ref, value: rest.children, onClick: (event) => {
                var _a, _b;
                (_a = context === null || context === void 0 ? void 0 : context.onSelect) === null || _a === void 0 ? void 0 : _a.call(context, event);
                (_b = rest === null || rest === void 0 ? void 0 : rest.onClick) === null || _b === void 0 ? void 0 : _b.call(rest, event);
            }, className: cl('cds-dropdown__item', 'cds-body-short', 'cds-body-short--small', className) }))));
});
GroupedItem.displayName = 'Dropdown.Menu.DescriptionList.GroupedItem';
export default GroupedItem;
