import * as types from 'constants/actionTypes'
import * as urls from 'constants/urls'
import api from 'api'
import { ClientCommentModel } from 'declarations/models'
import { sprintf } from 'sprintf-js'

export const deleteClientComment = (comment: ClientCommentModel) => {
  return api.call({
    url: sprintf(urls.CLIENT_COMMENTS_DELETE_URL, { id: comment.client_id, comment_id: comment.id }),
    method: 'DELETE',
    body: comment,
    skipFake: true,
    cascadeFailureError: true,
    type: {
      request: types.CLIENT_COMMENTS_DELETE_REQUEST,
      success: types.CLIENT_COMMENTS_DELETE_SUCCESS,
      failure: types.CLIENT_COMMENTS_DELETE_FAILURE
    }
  })
}

export const editClientComment = (comment: ClientCommentModel) => {
  return api.call({
    url: sprintf(urls.CLIENT_COMMENTS_EDIT_URL, { id: comment.client_id, comment_id: comment.id }),
    method: 'PUT',
    body: comment,
    skipFake: true,
    cascadeFailureError: true,
    type: {
      request: types.CLIENT_COMMENTS_EDIT_REQUEST,
      success: types.CLIENT_COMMENTS_EDIT_SUCCESS,
      failure: types.CLIENT_COMMENTS_EDIT_FAILURE
    }
  })
}

export const listClientComments = (client_id: number) => {
  return api.call({
    url: sprintf(urls.CLIENT_COMMENTS_LIST_URL, { id: client_id }),
    skipFake: true,
    cascadeFailureError: true,
    type: {
      request: types.CLIENT_COMMENTS_LIST_REQUEST,
      success: types.CLIENT_COMMENTS_LIST_SUCCESS,
      failure: types.CLIENT_COMMENTS_LIST_FAILURE
    }
  })
}

export const newClientComment = (comment: ClientCommentModel) => {
  return api.call({
    url: sprintf(urls.CLIENT_COMMENTS_NEW_URL, { id: comment.client_id }),
    method: 'POST',
    body: comment,
    skipFake: true,
    cascadeFailureError: true,
    type: {
      request: types.CLIENT_COMMENTS_NEW_REQUEST,
      success: types.CLIENT_COMMENTS_NEW_SUCCESS,
      failure: types.CLIENT_COMMENTS_NEW_FAILURE
    }
  })
}

export const resetClientComment = () => ({
  type: types.CLIENT_COMMENTS_RESET
})
