import { Client, ClientsQuery, Engagement, EngagementVacancyConsultant } from 'declarations/models'

import { SearchableClient } from 'hooks/useClientFilter'

import { getConsultantsFromEngagement } from 'utils/engagements'
import { scoreMatch } from 'utils/rank'
import { tableSort } from 'utils/sort'
import { tokenize } from 'utils/tokenizer'

import _ from 'lodash'

interface FilterClientProps {
  query: Partial<ClientsQuery>
  searchableClients: Array<SearchableClient>
  followedClients: Array<Client> | null | undefined
}

export const getSearchableClients = (clients?: Array<Client>): SearchableClient[] =>
  clients?.map((client) => {
    const textSearch: Record<string, string[]> = {
      name: tokenize(client.name),
      engagements: tokenize(client.engagements?.map((e) => e.title)?.join(' ')),
      location: tokenize(client.location)
    }
    return { textSearch, client }
  }) || []

export const getConsultantsFromClient = (client: Client): Array<EngagementVacancyConsultant> =>
  _.flatMap(client?.engagements?.map((engagement: Engagement) => getConsultantsFromEngagement(engagement)))

const rankClient = (c: SearchableClient, query: Partial<ClientsQuery>): number => {
  let score = 0
  if (_.isNil(query?.search) || query?.search.length === 0) {
    return score
  }

  let textScore = 0.0
  let maxTextScore = 0.0

  tokenize(query.search).forEach((token) => {
    if (token.length > 1) {
      maxTextScore += 2 * token.length
      c.textSearch.name?.forEach((name) => {
        textScore += scoreMatch(name, token)
      })
      maxTextScore += 2 * token.length
      c.textSearch.engagements?.forEach((name) => {
        textScore += scoreMatch(name, token)
      })
      maxTextScore += token.length
      c.textSearch.location?.forEach((name) => {
        textScore += scoreMatch(name, token)
      })
    }
  })

  // normalised => value between 0 and 1
  score = textScore / maxTextScore
  return score
}

export const filterClients = ({
  query,
  searchableClients,
  followedClients
}: FilterClientProps): Array<SearchableClient> => {
  return searchableClients?.filter((clientItem: SearchableClient) => {
    // search text
    if (query.search && query.search.length > 1) {
      // if free text search terms: reject those who score 0
      clientItem.score = rankClient(clientItem, query)
      if (clientItem.score === 0) {
        return false
      }
    }

    if (!_.isEmpty(query.hasEngagement?.toString())) {
      if ((clientItem.client as Client).engagements?.length === 0) {
        return false
      }
    }

    if (!_.isEmpty(query.hasNoEngagement?.toString())) {
      if ((clientItem.client as Client).engagements?.length !== 0) {
        return false
      }
    }

    return true
  })
}

export const sortClients = (query: Partial<ClientsQuery>) => {
  return (a: SearchableClient, b: SearchableClient) => {
    if (query.sort) {
      return tableSort(query.sort, a, b, 'client', (a, b) =>
        b.score - a.score > 0 ? 1 : b.score - a.score < 0 ? -1 : 0
      )
    }
    return b.score! - a.score! > 0 ? 1 : b.score! - a.score! < 0 ? -1 : 0
  }
}
