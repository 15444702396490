import {
  Alert,
  AlignEndColumn,
  BodyShort,
  Box,
  Button,
  Column,
  ErrorSummary,
  FlexEndDiv,
  Heading,
  HorizontalSpace,
  Panel,
  Row,
  Select,
  Table,
  Tabs,
  TextField,
  VerticalSpace
} from '@cegal/ds-components'
import { AddCircle } from '@cegal/ds-icons'
import {
  deleteEngagementVacancyConsultant,
  editEngagementVacancyConsultant,
  listCertificates,
  listSkills,
  newEngagementVacancyConsultant,
  resetEngagementVacancyConsultant
} from 'actions'
import { EngagementVacancy, EngagementVacancyConsultant } from 'declarations/models'
import { useAppDispatch, useAppSelector } from 'store'
import { v4 as uuidv4 } from 'uuid'

import EngagementVacancyConsultantForm from 'components/Engagements/Engagement/Vacancy/Consultant/EngagementVacancyConsultantForm'
import EngagementVacancyConsultantRow from 'components/Engagements/Engagement/Vacancy/Consultant/EngagementVacancyConsultantRow'
import EngagementVacancyConsultantRowHeader from 'components/Engagements/Engagement/Vacancy/Consultant/EngagementVacancyConsultantRowHeader'
import CertificateSelect from 'components/Forms/CertificateSelect/CertificateSelect'
import SkillSelect from 'components/Forms/SkillSelect/SkillSelect'

import { RefObject, useEffect, useMemo, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'

interface EngagementVacancyFormProps {
  /* show loading animation for adding this vacancy */
  isAdding?: boolean
  /* show loading animation for deleting this vacancy */
  isEditing?: boolean
  // if this form is used for a new vacancy, here is a temp ID so we can identify it
  tempId?: string
  // form ref, useful to attach modals and other menus so they behave properly
  formRef?: RefObject<HTMLDivElement>
  /* callback function when form submitted */
  onNewVacancy: (newVacancy: EngagementVacancy) => void
  /* callback function when form canceled */
  onClose: () => void
  engagementTitle?: string
  vacancy?: EngagementVacancy
  spacing?: boolean
}

// Partial, because we are missing an ID
const initialNewVacancyValue: Partial<EngagementVacancy> = {
  title: undefined,
  allocation: 'full-time',
  hourly_rate: 0,
  skills: [],
  consultants: []
}

enum EngagementVacancyFormTabs {
  VACANCY = 'vacancy',
  CONSULTANTS = 'consultants'
}

const EngagementVacancyForm: React.FC<EngagementVacancyFormProps> = ({
  isAdding = false,
  isEditing = false,
  tempId,
  formRef,
  onNewVacancy,
  engagementTitle,
  onClose,
  vacancy = initialNewVacancyValue,
  spacing = true
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [consultantsBeingCreated, setConsultantsBeingCreated] = useState<{ [k in string]: boolean }>({})
  const [consultantsBeingEdited, setConsultantsBeingEdited] = useState<{ [k in string]: boolean }>({})

  const certificates = useAppSelector((state) => state.certificates.list)
  const certificatesLoading = useAppSelector((state) => state.certificates.listing)
  const createdVacancyConsultant = useAppSelector((state) => state.engagements.createdVacancyConsultant)
  const creatingVacancyConsultant = useAppSelector((state) => state.engagements.creatingVacancyConsultant)
  const currentVacancyConsultantId = useAppSelector((state) => state.engagements.currentVacancyConsultantId)
  const deletedVacancyConsultant = useAppSelector((state) => state.engagements.deletedVacancyConsultant)
  const deletingVacancyConsultant = useAppSelector((state) => state.engagements.deletingVacancyConsultant)
  const savedVacancyConsultant = useAppSelector((state) => state.engagements.savedVacancyConsultant)
  const savingVacancyConsultant = useAppSelector((state) => state.engagements.savingVacancyConsultant)
  const skillsLoading = useAppSelector((state) => state.skills.listing)
  const skills = useAppSelector((state) => state.skills.list)
  const apiReady = useAppSelector((state) => state.app.apiReady)

  const sortedSkills = useMemo(() => {
    return _.cloneDeep(skills)?.sort((a, b) => a.name.localeCompare(b.name)) ?? null
  }, [skills])

  const sortedCertificates = useMemo(() => {
    if (_.isArray(certificates)) {
      return _.cloneDeep(certificates)?.sort((a, b) => a.name.localeCompare(b.name)) ?? null
    }
    return null
  }, [certificates])

  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset,
    getValues
  } = useForm({
    defaultValues: vacancy
  })

  const editView = _.isNumber(vacancy?.id) && tempId === undefined

  const vacancyTitle = watch('title', vacancy.title)

  const { fields: consultants } = useFieldArray({
    control,
    name: 'consultants',
    keyName: 'consultantFieldArrayId' // prevent useFieldArray from overriding id
  })

  /**
   * Called when we are submitting a new/existing engagement vacancy to backend
   * @param newVacancy Engagement vacancy we want to create/save
   */
  const onSubmit = (newVacancy: Partial<EngagementVacancy>) => {
    if (!_.isNumber(newVacancy.id)) {
      newVacancy.temp_id = tempId
    }
    onNewVacancy(newVacancy as EngagementVacancy)
    reset(initialNewVacancyValue)
    onClose()
  }

  /**
   * Called when we are adding a new evc to a new/existing vacancy
   * If we are adding to a draft vacancy, evc will be added in memory only
   * If we are adding to an existing vacancy, then we use the endpoints to store it permanently.
   * @param newConsultant New EVC we want to add to new/existing vacancy
   */
  const onNewConsultant = (newConsultant: Partial<EngagementVacancyConsultant>) => {
    const vacancy: EngagementVacancy = getValues() as EngagementVacancy

    if (editView) {
      dispatch(
        newEngagementVacancyConsultant(
          {
            ...newConsultant,
            id: newConsultant.consultant?.id
          },
          vacancy.id
        )
      )
      return
    }

    const newConsultants = vacancy.consultants!.concat(newConsultant as EngagementVacancyConsultant)
    setValue('consultants', newConsultants)
  }

  /**
   * Called when we are saving an existing evc to an existing vacancy
   * @param editConsultant  EVC we are editing from existing vacancy
   * @param index index of EVC in the vacancy.consultants array, so we can easily update the EVC here
   */
  const onEditConsultant = (editConsultant: EngagementVacancyConsultant, index: number) => {
    const vacancy: EngagementVacancy = getValues() as EngagementVacancy
    const newConsultants = _.cloneDeep(vacancy.consultants) as Array<EngagementVacancyConsultant>

    // use the ID of the previous consultant, as the new one may have changed (swapping consultants in the EVC)
    const consultantId: number = newConsultants[index].consultant!.id as number
    newConsultants[index] = editConsultant

    if (editView) {
      dispatch(editEngagementVacancyConsultant(editConsultant, consultantId, vacancy.id))
      return
    }
    setValue('consultants', newConsultants)
  }

  /**
   * Called when we are deleting a EVC from a new/existing vacancy
   * @param index index of EVC in the vacancy.consultants array we are deleting
   */
  const onRemoveConsultant = (index: number) => {
    const vacancy: EngagementVacancy = getValues() as EngagementVacancy
    const newConsultants: Array<EngagementVacancyConsultant> = _.cloneDeep(
      vacancy.consultants
    ) as Array<EngagementVacancyConsultant>

    if (editView) {
      dispatch(deleteEngagementVacancyConsultant(newConsultants[index], vacancy.id))
      return
    }

    newConsultants.splice(index, 1)
    setValue('consultants', newConsultants)
  }

  useEffect(() => {
    if (certificates === undefined && apiReady && !certificatesLoading) {
      dispatch(listCertificates())
    }
  }, [apiReady])

  useEffect(() => {
    if (skills === undefined && apiReady && !skillsLoading) {
      dispatch(listSkills())
    }
  }, [apiReady])

  /* if vacancy changes in store, because we are using backend to save changes, update form values */
  useEffect(() => {
    reset(vacancy)
  }, [vacancy])

  return (
    <>
      <Box border style={{ position: 'relative' }}>
        <Box.Header background>
          <Heading size='medium'>
            {editView
              ? t('header:vacancy-edit-x', {
                  x: vacancyTitle
                })
              : vacancyTitle
                ? t('header:vacancy-add-x-to-y', {
                    x: vacancyTitle,
                    y: engagementTitle
                  })
                : t('header:vacancy-add')}
          </Heading>
        </Box.Header>
        <Box.Body style={{ padding: '1rem' }}>
          <Tabs border defaultValue={EngagementVacancyFormTabs.VACANCY}>
            <Tabs.List>
              <Tabs.Tab value={EngagementVacancyFormTabs.VACANCY} label={t('label:vacancy-title')} />
              <Tabs.Tab value={EngagementVacancyFormTabs.CONSULTANTS} label={t('label:consultants-title')} />
            </Tabs.List>
            <Tabs.Panel noPadding value={EngagementVacancyFormTabs.VACANCY}>
              <Panel style={{ minHeight: '15rem' }}>
                <Row>
                  <Column flex='2'>
                    <TextField
                      label={t('label:vacancy-title-title') + '*'}
                      description={t('label:vacancy-title-description')}
                      {...register('title', {
                        required: t('validation:engagement-no-vacancy-title').toString()
                      })}
                      error={errors?.title?.message}
                    />
                    <VerticalSpace />
                  </Column>
                  <Column>
                    <Select
                      label={t('label:allocation-title')}
                      description={t('label:allocation-description')}
                      error={errors.allocation?.message}
                      {...register('allocation')}
                    >
                      <option value='no-time'>{t('label:no-time')}</option>
                      <option value='part-time'>{t('label:part-time')}</option>
                      <option value='full-time'>{t('label:full-time')}</option>
                    </Select>
                    <VerticalSpace />
                  </Column>
                </Row>
                <Row>
                  <Column flex='2'>
                    <Controller
                      control={control}
                      name='skills'
                      rules={{
                        required: t('validation:engagement-no-skills').toString()
                      }}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <SkillSelect
                            skills={sortedSkills}
                            closeMenuOnSelect={false}
                            menuPortalTarget={formRef?.current}
                            menuPosition='fixed'
                            loading={skillsLoading}
                            label={t('label:skills-title') + ' *'}
                            description={t('label:skills-description')}
                            defaultValue={value}
                            onChange={onChange}
                            error={errors?.skills?.message}
                          />
                        )
                      }}
                    />
                    <VerticalSpace />
                  </Column>
                  <Column flex='2'>
                    <TextField
                      type='number'
                      label={t('label:hourly-rate-title')}
                      description={t('label:hourly-rate-description')}
                      error={errors.hourly_rate?.message}
                      {...register('hourly_rate', {
                        valueAsNumber: true
                      })}
                    />
                    <VerticalSpace />
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <Controller
                      control={control}
                      name='certificates'
                      render={({ field: { onChange, value } }) => {
                        return (
                          <CertificateSelect
                            certificates={sortedCertificates}
                            closeMenuOnSelect={false}
                            menuPortalTarget={formRef?.current}
                            menuPosition='fixed'
                            loading={certificatesLoading}
                            label={t('label:certificates')}
                            description={t('label:certificates-description')}
                            defaultValue={value}
                            onChange={onChange}
                            error={errors?.certificates?.message}
                          />
                        )
                      }}
                    />
                    <VerticalSpace />
                  </Column>
                </Row>
                <VerticalSpace />
              </Panel>
            </Tabs.Panel>
            <Tabs.Panel value={EngagementVacancyFormTabs.CONSULTANTS}>
              <Panel noPadding style={{ minHeight: '15rem' }}>
                <BodyShort>
                  {_.isEmpty(consultants)
                    ? t('messages:vacancy-has-no-consultants')
                    : t(
                        consultants.length > 1
                          ? 'messages:vacancy-has-x-consultants'
                          : 'messages:vacancy-has-x-consultant',
                        { x: consultants.length }
                      )}
                </BodyShort>
                {deletedVacancyConsultant !== undefined && (
                  <>
                    <VerticalSpace />
                    {deletedVacancyConsultant === null ? (
                      <Alert variant='error' onClose={() => dispatch(resetEngagementVacancyConsultant())}>
                        {t('messages:error-delete-consultant-from-vacancy-x', { x: vacancyTitle })}
                      </Alert>
                    ) : (
                      <Alert variant='success' onClose={() => dispatch(resetEngagementVacancyConsultant())}>
                        {t('messages:success-delete-consultant-from-vacancy-x', { x: vacancyTitle })}
                      </Alert>
                    )}
                  </>
                )}
                {savedVacancyConsultant !== undefined && (
                  <>
                    <VerticalSpace />
                    {savedVacancyConsultant === null ? (
                      <Alert variant='error' onClose={() => dispatch(resetEngagementVacancyConsultant())}>
                        {t('messages:error-edit-consultant-from-vacancy-x', { x: vacancyTitle })}
                      </Alert>
                    ) : (
                      <Alert variant='success' onClose={() => dispatch(resetEngagementVacancyConsultant())}>
                        {t('messages:success-edit-consultant-from-vacancy-x', { x: vacancyTitle })}
                      </Alert>
                    )}
                  </>
                )}
                <VerticalSpace />
                <Table>
                  {!_.isEmpty(consultants) && <EngagementVacancyConsultantRowHeader />}
                  {consultants?.map((consultant: EngagementVacancyConsultant, index: number) => {
                    let editModeId: string | undefined
                    let editMode: boolean = false
                    if (_.isNumber(consultant?.consultant?.id)) {
                      editModeId = vacancy.id + '-' + consultant.consultant!!.id
                      editMode = consultantsBeingEdited[editModeId]
                    }

                    // isDeleting and isEditing only for existing EVCs, with valid ids
                    const isDeleting =
                      deletingVacancyConsultant &&
                      currentVacancyConsultantId?.id === consultant.consultant?.id &&
                      currentVacancyConsultantId?.vacancy_id === vacancy.id
                    const isEditing =
                      savingVacancyConsultant &&
                      currentVacancyConsultantId?.id === consultant.consultant?.id &&
                      currentVacancyConsultantId?.vacancy_id === vacancy.id
                    const edited =
                      !_.isNil(savedVacancyConsultant) &&
                      savedVacancyConsultant.consultant?.id === consultant.consultant?.id &&
                      savedVacancyConsultant.vacancy_id === vacancy.id

                    const added =
                      !_.isNil(createdVacancyConsultant) &&
                      createdVacancyConsultant.consultant?.id === consultant.consultant?.id &&
                      createdVacancyConsultant.vacancy_id === vacancy.id

                    if (editMode) {
                      return (
                        <Table.Row
                          key={
                            'engagement-vacancy-' + vacancy.id + '-consultant-' + consultant.consultant?.id
                          }
                        >
                          <Table.DataCell colSpan={999} style={{ padding: '1rem' }}>
                            <EngagementVacancyConsultantForm
                              evc={consultant}
                              vacancyTitle={vacancyTitle}
                              isEditing={isEditing}
                              formRef={formRef}
                              spacing
                              onEvcChanged={(evc: Partial<EngagementVacancyConsultant>) =>
                                onEditConsultant(evc as EngagementVacancyConsultant, index)
                              }
                              onClose={() => {
                                setConsultantsBeingEdited({
                                  ...consultantsBeingEdited,
                                  [editModeId!!]: false
                                })
                              }}
                            />
                          </Table.DataCell>
                        </Table.Row>
                      )
                    }
                    return (
                      <EngagementVacancyConsultantRow
                        key={'engagement-vacancy-' + vacancy.id + '-consultant-' + consultant.consultant?.id}
                        edited={edited}
                        added={added}
                        loading={isEditing || isDeleting}
                        item={consultant}
                        onEdit={() => {
                          setConsultantsBeingEdited({
                            ...consultantsBeingEdited,
                            [editModeId!!]: true
                          })
                        }}
                        onDelete={() => onRemoveConsultant(index)}
                      />
                    )
                  })}
                </Table>
                <VerticalSpace />

                {Object.keys(consultantsBeingCreated)
                  ?.filter((key) => consultantsBeingCreated[key])
                  ?.map((key) => (
                    <EngagementVacancyConsultantForm
                      isAdding={creatingVacancyConsultant}
                      formRef={formRef}
                      tempId={key}
                      vacancyTitle={vacancyTitle}
                      key={key}
                      onEvcChanged={onNewConsultant}
                      onClose={() =>
                        setConsultantsBeingCreated({
                          ...consultantsBeingCreated,
                          [key]: false
                        })
                      }
                    />
                  ))}
                <VerticalSpace />

                <Button
                  loading={creatingVacancyConsultant}
                  variant='secondary'
                  icon={<AddCircle size='1.3rem' />}
                  onClick={() => {
                    const newKey = uuidv4()
                    setConsultantsBeingCreated({
                      ...consultantsBeingCreated,
                      [newKey]: true
                    })
                  }}
                >
                  {creatingVacancyConsultant
                    ? t('buttons:adding')
                    : t('buttons:add-new-x', { x: t('label:consultant-title').toLowerCase() })}
                </Button>
                <VerticalSpace />
              </Panel>
            </Tabs.Panel>
          </Tabs>
          <VerticalSpace />
        </Box.Body>
        <Box.Footer style={{ padding: '1rem' }}>
          <Row>
            <AlignEndColumn style={{ alignItems: 'flex-end' }}>
              <FlexEndDiv>
                <Button variant='primary' loading={isEditing || isAdding} onClick={handleSubmit(onSubmit)}>
                  {isEditing || isAdding ? t('buttons:saving') : t('buttons:save')}
                </Button>
                <HorizontalSpace />
                <Button variant='secondary' onClick={onClose}>
                  {editView ? t('buttons:discard') : t('buttons:cancel')}
                </Button>
              </FlexEndDiv>
            </AlignEndColumn>
          </Row>
          {!_.isEmpty(errors) && (
            <>
              <VerticalSpace />
              <ErrorSummary size='small' heading={t('validation:engagement-vacancy-error-summary-title')}>
                {Object.keys(errors).map((errorKey) => (
                  <ErrorSummary.Item key={errorKey}>{_.get(errors, errorKey + '.message')}</ErrorSummary.Item>
                ))}
              </ErrorSummary>
            </>
          )}
        </Box.Footer>
      </Box>
      {spacing && <VerticalSpace />}
    </>
  )
}

export default EngagementVacancyForm
