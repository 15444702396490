import { Avatar, FlexCenterDiv, HorizontalSpace, PileDiv, Table, VerticalSpace } from '@cegal/ds-components'
import UnknownAvatar from 'assets/images/unknownAvatar.png'
import { EngagementVacancyConsultant } from 'declarations/models'

import { ConsultantSignature } from 'components/Consultants/Consultant'
import Row, { RowProps } from 'components/Row/Row'
import { NoWrapSpan } from 'components/styled'

import { useTranslation } from 'react-i18next'

import useConsultantAvatar from 'hooks/useConsultantAvatar'

import { toLocalDateFormat } from 'utils'

import _ from 'lodash'

const EngagementVacancyRow = ({
  item,
  added = false,
  edited = false,
  loading = false,
  ...rest
}: RowProps<EngagementVacancyConsultant>) => {
  const { t } = useTranslation()
  const { avatar, avatarLoading, ref: avatarRef } = useConsultantAvatar({ id: item?.consultant?.id })

  return (
    <Row<EngagementVacancyConsultant>
      item={item}
      data-testid='EngagementVacancyConsultantRow'
      edited={edited}
      added={added}
      loading={loading}
      {...rest}
    >
      <Table.DataCell>
        <PileDiv>
          <Avatar
            variant='square-round'
            size='small'
            loading={avatarLoading}
            src={avatar}
            ref={avatarRef}
            defaultSrc={UnknownAvatar}
          />
          <VerticalSpace size='0.5' />
          {item?.consultant?.name ?? '-'}
        </PileDiv>
      </Table.DataCell>
      <Table.DataCell>{item.engagement_vacancy?.title ?? '-'}</Table.DataCell>
      <Table.DataCell>{item.hourly_rate + ' kr'}</Table.DataCell>
      <Table.DataCell>{item.status}</Table.DataCell>
      <Table.DataCell>
        <>
          <NoWrapSpan>{toLocalDateFormat(item?.start_date)}</NoWrapSpan>
          {' - '}
          <NoWrapSpan>{toLocalDateFormat(item?.end_date)}</NoWrapSpan>
        </>
      </Table.DataCell>
      <Table.DataCell>
        <FlexCenterDiv>
          <span>{'' + toLocalDateFormat(item.last_modified_date)}</span>
          {!_.isEmpty(item.last_modifier) && (
            <>
              <HorizontalSpace size='0.5' />
              {t('label:by')}
              <HorizontalSpace size='0.5' />
              <ConsultantSignature consultant={item.last_modifier} />
            </>
          )}
        </FlexCenterDiv>
      </Table.DataCell>
    </Row>
  )
}

export default EngagementVacancyRow
