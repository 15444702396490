var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from 'react';
import cl from 'clsx';
import { useId } from 'util/index';
export const Loader = forwardRef((_a, ref) => {
    var { children, className, size = 'medium', title = 'venter...', transparent = false, variant = 'primary', id } = _a, rest = __rest(_a, ["children", "className", "size", "title", "transparent", "variant", "id"]);
    const internalId = useId();
    return (React.createElement("svg", Object.assign({}, rest, { ref: ref, className: cl('cds-loader', className, `cds-loader--${size}`, `cds-loader--${variant}`, {
            'cds-loader--transparent': transparent
        }), "aria-labelledby": id !== null && id !== void 0 ? id : `loader-${internalId}`, focusable: 'false', viewBox: '0 0 50 50', preserveAspectRatio: 'xMidYMid' }),
        React.createElement("title", { id: id !== null && id !== void 0 ? id : `loader-${internalId}` }, title),
        React.createElement("circle", { className: 'cds-loader__background', xmlns: 'http://www.w3.org/2000/svg', cx: '25', cy: '25', r: '20', fill: 'none' }),
        React.createElement("circle", { className: 'cds-loader__foreground', cx: '25', cy: '25', r: '20', fill: 'none', strokeDasharray: '50 155' })));
});
Loader.displayName = 'Loader';
export default Loader;
