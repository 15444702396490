import * as types from 'constants/actionTypes'
import * as urls from 'constants/urls'
import api from 'api'
import { Client } from 'declarations/models'
import { sprintf } from 'sprintf-js'

export const deleteClient = (client: Client) => {
  return api.call({
    url: sprintf(urls.CLIENTS_DELETE_URL, { id: client.id }),
    method: 'DELETE',
    body: client,
    skipFake: true,
    cascadeFailureError: true,
    context: {
      client
    },
    type: {
      request: types.CLIENTS_DELETE_REQUEST,
      success: types.CLIENTS_DELETE_SUCCESS,
      failure: types.CLIENTS_DELETE_FAILURE
    }
  })
}

export const editClient = (client: Client) => {
  return api.call({
    url: sprintf(urls.CLIENTS_EDIT_URL, { id: client.id }),
    method: 'PUT',
    body: client,
    skipFake: true,
    cascadeFailureError: true,
    type: {
      request: types.CLIENTS_EDIT_REQUEST,
      success: types.CLIENTS_EDIT_SUCCESS,
      failure: types.CLIENTS_EDIT_FAILURE
    }
  })
}

export const getClient = (id: number) => {
  return api.call({
    url: sprintf(urls.CLIENTS_GET_URL, { id }),
    skipFake: true,
    cascadeFailureError: true,
    type: {
      request: types.CLIENTS_GET_REQUEST,
      success: types.CLIENTS_GET_SUCCESS,
      failure: types.CLIENTS_GET_FAILURE
    }
  })
}

export const listClients = () => {
  return api.call({
    url: urls.CLIENTS_LIST_URL,
    skipFake: true,
    cascadeFailureError: true,
    type: {
      request: types.CLIENTS_LIST_REQUEST,
      success: types.CLIENTS_LIST_SUCCESS,
      failure: types.CLIENTS_LIST_FAILURE
    }
  })
}

export const newClient = (client: Partial<Client>) => {
  return api.call({
    url: urls.CLIENTS_NEW_URL,
    method: 'POST',
    body: client,
    skipFake: true,
    cascadeFailureError: true,
    type: {
      request: types.CLIENTS_NEW_REQUEST,
      success: types.CLIENTS_NEW_SUCCESS,
      failure: types.CLIENTS_NEW_FAILURE
    }
  })
}

export const resetClient = () => ({
  type: types.CLIENTS_RESET
})

export const setClient = (client: Client | undefined) => ({
  type: types.CLIENTS_CLIENT_SET,
  payload: client
})
