import styled from 'styled-components';
const Container = styled.div `
  display: flex;
  flex: 1;
  flex-flow: column;
  min-height: inherit;
  color: var(--cds-container-text-color);
  background-color: var(--cds-container-background-color);
  font-family: var(--cds-container-font-family);
  line-height: var(--cds-container-line-height);
  font-size: var(--cds-container-font-size);
`;
Container.displayName = 'Container';
const Content = styled.div `
  flex: ${(props) => props.flex || 6};
  padding: ${(props) => (props.padding ? props.padding + 'rem' : 'var(--cds-size-5)')};
`;
Container.displayName = 'Content';
const Nav = styled.nav `
  svg.logo * {
    fill: var(--cds-container-text-color);
  }
`;
Nav.displayName = 'Nav';
const Header = styled.header `
  flex: 0 1 auto;
  background-color: var(--cds-header-background-color);
  color: var(--cds-header-text-color);
  svg.logo * {
    fill: var(--cds-header-text-color);
  }
`;
Header.displayName = 'Header';
const Margin = styled.div `
  flex: 1;
`;
Margin.displayName = 'Margin';
export { Container, Content, Nav, Header, Margin };
