import { FlexCenterDiv, Heading, HorizontalSpace, VerticalSpace } from '@cegal/ds-components'
import { Consultant, EngagementVacancy } from 'declarations/models'

import Card, { CardProps } from 'components/Card/Card'
import { ConsultantSignature } from 'components/Consultants/Consultant'
import { DescriptionListItem, DescriptionSpan } from 'components/DescriptionList/DescriptionList'
import CertificatesGroup from 'components/TagGroup/CertificatesGroup'
import ConsultantsGroup from 'components/TagGroup/ConsultantsGroup'
import SkillsGroup from 'components/TagGroup/SkillsGroup'

import Highlighter from 'react-highlight-words'
import { useTranslation } from 'react-i18next'

import useQuery from 'hooks/useQuery'

import { toLocalDateFormat } from 'utils'
import { tokenize } from 'utils/tokenizer'

import _ from 'lodash'

interface AdditionalCardProps {
  increaseCutoffPoint?: boolean
}

const EngagementVacancyCard = ({
  item,
  size = 'medium',
  spacing = false,
  added = false,
  edited = false,
  loading = false,
  increaseCutoffPoint,
  context,
  ...rest
}: CardProps<EngagementVacancy> & AdditionalCardProps) => {
  const { t } = useTranslation()
  const { query } = useQuery()
  const searchWords = tokenize(query.search)

  return (
    <Card<EngagementVacancy>
      item={item}
      data-cy='EngagementVacancyCard'
      data-testid='EngagementVacancyCard'
      size={size}
      spacing={spacing}
      edited={edited}
      added={added}
      loading={loading}
      border
      {...rest}
    >
      <Heading level='3' size='small'>
        <Highlighter caseSensitive={false} searchWords={searchWords} textToHighlight={item?.title ?? ''} />
      </Heading>
      <VerticalSpace size='0.3' />
      {item.engagement?.title && (
        <Heading level='4' size='xsmall' spacing>
          <Highlighter
            caseSensitive={false}
            searchWords={searchWords}
            textToHighlight={item?.engagement?.title ?? ''}
          />
        </Heading>
      )}

      <DescriptionListItem
        title={t('label:consultants')}
        $alignItems='baseline'
        details={
          !_.isEmpty(item.consultants) ? (
            <ConsultantsGroup
              size='small'
              consultants={item.consultants?.map((c) => c.consultant) as Array<Consultant>}
              interactive
            />
          ) : (
            <DescriptionSpan>{t('messages:vacancy-has-no-consultants')}</DescriptionSpan>
          )
        }
      />

      <DescriptionListItem title={t('label:allocation')} details={t('label:' + item.allocation)} />

      <DescriptionListItem title={t('label:hourly-rate')} details={item.hourly_rate + ' kr'} />

      {size === 'medium' && (
        <>
          <DescriptionListItem
            $alignItems='baseline'
            title={t('label:skills')}
            details={
              !_.isEmpty(item.skills) ? (
                <SkillsGroup size='small' skills={item.skills} />
              ) : (
                <DescriptionSpan>{t('messages:no-skills')}</DescriptionSpan>
              )
            }
          />
          <DescriptionListItem
            $alignItems='baseline'
            title={t('label:certificates')}
            details={
              !_.isEmpty(item.certificates) ? (
                <CertificatesGroup certificates={item.certificates} />
              ) : (
                <DescriptionSpan>{t('messages:no-certificates')}</DescriptionSpan>
              )
            }
          />
          <DescriptionListItem
            title={t('label:last-modified')}
            details={
              <FlexCenterDiv>
                <span>{'' + toLocalDateFormat(item.last_modified_date)}</span>
                {!_.isEmpty(item.last_modifier) && (
                  <>
                    <HorizontalSpace size='0.5' />
                    {t('label:by')}
                    <HorizontalSpace size='0.5' />
                    <ConsultantSignature
                      consultant={item.last_modifier}
                      popover={['view', 'email']}
                      context={context}
                    />
                  </>
                )}
              </FlexCenterDiv>
            }
          />
        </>
      )}
    </Card>
  )
}

export default EngagementVacancyCard
