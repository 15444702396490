import { BodyShort, Button, Dropdown, FlexBaseSpacedDiv, FlexDiv, Link } from '@cegal/ds-components/dist'
import { InsertDriveFile, MoreVert } from '@cegal/ds-icons'
import { styled } from 'styled-components'

import { t } from 'i18next'

type customFile = {
  name: string
  size: number
  uploaded: string
}

type AiFileProps = {
  file: customFile
}

const FileContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  position: relative;

  &:after {
    content: '';
    display: block;
    border-bottom: 1px solid var(--cat-homepanel-element-border-color);
    position: absolute;
    bottom: 0;
    left: 14%;
    width: 86%;
  }

  &:first-child:after {
    width: 100%;
  }

  &:hover {
    background-color: var(--cds-table-row-background-color-hover);
  }
`

const FileDetailText = styled(BodyShort)`
  font-size: var(--cds-container-font-size);
`

const FilePreview = styled.div``

const AiFile = ({ file }: AiFileProps) => {
  const today = new Date().setHours(0, 0, 0, 0)
  const msInDay = 24 * 60 * 60 * 1000

  const formatTimestamp = (date: string): string => {
    const timestamp = new Date(date)
    const sentDate = new Date(timestamp)

    sentDate.setHours(0, 0, 0, 0)

    const daysAgo = (+today - +sentDate) / msInDay

    if (daysAgo === 0) {
      return timestamp.toLocaleTimeString().slice(0, -3)
    } else if (daysAgo === 1) {
      return `${t('messages:yesterday')}, ${timestamp.toLocaleTimeString().slice(0, -3)}`
    } else {
      return timestamp.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })
    }
  }
  return (
    <>
      <FileContainer>
        <FlexDiv style={{ alignItems: 'center', flexFlow: 'nowrap' }}>
          <FilePreview>
            <InsertDriveFile size='2.5rem' />
          </FilePreview>
          <FlexBaseSpacedDiv
            style={{ marginLeft: '1rem', alignItems: 'center', flexFlow: 'nowrap', width: '100%' }}
          >
            <div style={{ marginRight: '2rem' }}>
              <Link href='#' target='_blank'>
                <BodyShort>{file?.name}</BodyShort>
              </Link>
              <FileDetailText>{`${file.size} kB, ${formatTimestamp(file.uploaded)}`}</FileDetailText>
            </div>
            <Dropdown closeOnSelect>
              <Button
                as={Dropdown.Toggle}
                variant='tertiary'
                size='medium'
                icon={<MoreVert size='1.5rem' />}
              />
              <Dropdown.Menu>
                <Dropdown.Menu.List>
                  <Dropdown.Menu.List.Item>Open file location</Dropdown.Menu.List.Item>
                </Dropdown.Menu.List>
              </Dropdown.Menu>
            </Dropdown>
          </FlexBaseSpacedDiv>
        </FlexDiv>
      </FileContainer>
    </>
  )
}

export default AiFile
