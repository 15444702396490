import { Column, Row, SelectMultiple, TextField, VerticalSpace } from '@cegal/ds-components'
import { Location } from 'declarations/models'
import { useAppSelector } from 'store'

import { EngagementStepFormProps } from 'components/Engagements/Engagement/Form/EngagementForm'

import { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import useOrgChart from 'hooks/useOrgChart'

import { Option } from 'utils/fieldUtils'

import _ from 'lodash'

const Step1 = (props: EngagementStepFormProps) => {
  const {
    control,
    register,
    watch,
    formState: { errors }
  } = useFormContext()
  const { t } = useTranslation()
  const _location: string | undefined = watch('location')
  const locations = useAppSelector((state) => state.locations.list)
  const { loading: locationsLoading } = useOrgChart()

  const locationOptions: Array<Option<Location>> = useMemo(() => {
    if (!_.isEmpty(locations)) {
      return (
        locations?.map((l) => ({
          ...l,
          label: l.name,
          value: l.id
        })) ?? []
      )
    }
    return []
  }, [locations])

  const selectedLocation = useMemo(
    () => _.find(locationOptions, { id: parseInt(_location ?? '-1') }) ?? null,
    [_location, locationOptions]
  )

  return (
    <>
      <Row>
        <Column flex='3'>
          <TextField
            label={t('label:engagement-title-title') + '*'}
            {...register('title', {
              required: t('validation:engagement-no-title')
            })}
            error={errors?.title?.message?.toString()}
          />
          <VerticalSpace />
        </Column>
        <Column />
      </Row>
      <Row>
        <Column flex='2'>
          <Controller
            control={control}
            name='location'
            rules={
              {
                // required: t('validation:engagement-no-location')
              }
            }
            render={({ field: { onChange } }) => (
              <SelectMultiple
                key={'location-' + selectedLocation?.id}
                multiple={false}
                menuPosition='fixed'
                menuPortalTarget={props.formRef?.current}
                loading={locationsLoading}
                label={t('label:engagement-location-title')}
                description={t('label:engagement-location-description')}
                defaultValue={!_.isEmpty(selectedLocation) ? selectedLocation : null}
                error={errors?.location?.message}
                onChange={(location: Option<Location>) => onChange(location?.value?.toString())}
                options={locationOptions}
                getOptionValue={(location: Location) => location.name}
              />
            )}
          />
          <VerticalSpace />
        </Column>
        <Column flex='2' />
      </Row>
    </>
  )
}

export default Step1
