import { BodyShort, Button, FlexBaseSpacedDiv, FlexCenterDiv, Link, PileDiv } from '@cegal/ds-components/dist'
import { Article, AttachFile, Clear, InsertDriveFile, Person, Send } from '@cegal/ds-icons'
import styled from 'styled-components'

import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import type { Attachment } from 'hooks/useAiMessages'
import useAutosizeTextArea from 'hooks/useAutosizeTextArea'

const TextFieldContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 1rem;
`

const SendButton = styled.button`
  border-radius: 100px;
  width: 2.4rem;
  height: 2.4rem;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
`

const InputContainer = styled.div`
  width: 100%;
  background-color: var(--cat-homepanel-element-background-color);
  padding: 1rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const InputField = styled.textarea`
  background: var(--cat-homepanel-element-background-color);
  border-radius: 0;
  border: none;
  max-height: 100px;
  resize: none;
  width: 100%;
  color: var(--cds-text-color);
  font-size: var(--cds-font-body-size-large);
  padding-right: 1rem;

  &:focus {
    outline: none;
  }
`

const AttachmentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`

const AttachmentItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  align-items: center;
  gap: 1rem;
  background-color: var(--cat-drawer-background-color);
  padding: 0.3rem;
  border-radius: 0.4rem;
`

type AiTextFieldProps = {
  disabled?: boolean
  onInput: (e: any) => void
  onSubmit: (e: any) => void
  value: any
  attachments: Attachment[]
  addAttachment: (item: Attachment) => void
  removeAttachment: (index: number) => void
}

const AiTextField = ({
  disabled,
  onInput,
  onSubmit,
  value,
  attachments,
  addAttachment,
  removeAttachment
}: AiTextFieldProps) => {
  const navigate = useNavigate()
  const textAreaRef = useRef(null)
  const fileInputRef = useRef(null)

  useAutosizeTextArea(textAreaRef.current, value)

  const iconMap = [
    { type: 'Document', icon: <InsertDriveFile size='1.5rem' /> },
    { type: 'ConsultantCard', icon: <Person size='1.5rem' /> }
  ]

  const handleFileInput = (e: any) => {
    const attachment: Attachment = {
      type: 'Document',
      title: e.target.files[0].name,
      data: e.target.files[0]
    }
    addAttachment(attachment)
  }

  return (
    <TextFieldContainer id='textarea'>
      <InputContainer>
        <InputField
          id='review-text'
          onChange={onInput}
          placeholder='Ask me anything...'
          ref={textAreaRef}
          rows={1}
          style={{ height: '20px' }}
          value={value}
          maxLength={1000}
          onKeyDown={(e) => e.key === 'Enter' && (disabled ? e.preventDefault() : onSubmit(e))}
        />
        <AttachmentContainer>
          {attachments.map((item: Attachment, index) => (
            <AttachmentItem key={index}>
              {iconMap.find((x) => x.type === item.type)?.icon || <Article size='1.5rem' />}
              <PileDiv>
                {item.label && <BodyShort style={{ opacity: '0.7' }}>{item.label}</BodyShort>}
                <BodyShort style={{ paddingBottom: '.4rem' }}>
                  <Link style={{ cursor: 'pointer' }} onClick={() => navigate(item.link || '')}>
                    {item.title}
                  </Link>
                </BodyShort>
              </PileDiv>
              <Button
                onClick={() => removeAttachment(index)}
                icon={<Clear size='1.5rem' />}
                variant='tertiary'
                size='small'
                style={{ marginLeft: '-.5rem' }}
              />
            </AttachmentItem>
          ))}
        </AttachmentContainer>

        <FlexBaseSpacedDiv style={{ alignItems: 'center' }}>
          <label htmlFor='fileInput' style={{ cursor: 'pointer' }}>
            <AttachFile size='1.5rem' />
          </label>
          <input
            type='file'
            id='fileInput'
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileInput}
          />
          <FlexCenterDiv style={{ gap: '1rem' }}>
            <BodyShort>{`${value.length} / 1000`}</BodyShort>
            <SendButton disabled={disabled} onClick={onSubmit}>
              <Send size='1.8rem' />
            </SendButton>
          </FlexCenterDiv>
        </FlexBaseSpacedDiv>
      </InputContainer>
    </TextFieldContainer>
  )
}

export default AiTextField
