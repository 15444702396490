var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { KeyboardArrowDown } from '@cegal/ds-icons/dist/KeyboardArrowDown';
import cl from 'clsx';
import { Loader } from 'components/loader';
import { BodyLong, Detail, ErrorMessage, Label } from 'components/typography';
import React, { forwardRef } from 'react';
import { omit } from 'util/index';
import { useFormField } from './useFormField';
export const Select = forwardRef((props, ref) => {
    const { inputProps, errorId, showErrorMsg, hasError, size, inputDescriptionId } = useFormField(props, 'textField');
    const { children, label, loading = false, className, description, htmlSize, hideLabel = false, style } = props, rest = __rest(props, ["children", "label", "loading", "className", "description", "htmlSize", "hideLabel", "style"]);
    return (React.createElement("div", { className: cl('cds-form-field', `cds-form-field--${size}`, {
            'cds-form-field--disabled': !!inputProps.disabled,
            'cds-select--error': hasError
        }, className) },
        React.createElement(Label, { as: 'label', htmlFor: inputProps.id, size: size, className: cl('cds-form-field__label', {
                'cds-sr-only': hideLabel
            }) }, label),
        !!description && (React.createElement(React.Fragment, null, size === 'medium' ? (React.createElement(BodyLong, { className: cl('cds-form-field__description', {
                'cds-sr-only': hideLabel
            }), id: inputDescriptionId, size: 'small', as: 'div' }, description)) : (React.createElement(Detail, { className: cl('cds-form-field__description', {
                'cds-sr-only': hideLabel
            }), id: inputDescriptionId, size: 'small', as: 'div' }, description)))),
        React.createElement("div", { className: 'cds-select__container', style: style },
            React.createElement("select", Object.assign({}, omit(rest, ['error', 'errorId', 'size']), { ref: ref }, inputProps, { className: cl('cds-select__input', 'cds-body-short', `cds-body--${size !== null && size !== void 0 ? size : 'medium'}`), size: props.htmlSize }), children),
            loading ? (React.createElement(Loader, { className: 'cds-select__loading' })) : (React.createElement(KeyboardArrowDown, { size: '1.5rem', className: 'cds-select__chevron', "aria-hidden": true }))),
        React.createElement("div", { className: 'cds-form-field__error', id: errorId, "aria-relevant": 'additions removals', "aria-live": 'polite' }, showErrorMsg && React.createElement(ErrorMessage, { size: size }, props.error))));
});
Select.displayName = 'Select';
export default Select;
