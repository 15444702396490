var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { CheckCircle } from '@cegal/ds-icons/dist/CheckCircle';
import cl from 'clsx';
import { Label } from 'components/typography';
import React, { forwardRef, useContext } from 'react';
import { StepperContext } from './Stepper';
export const Step = forwardRef((_a, ref) => {
    var { className, children, as: Component = 'a', unsafeIndex = 0, completed = false, interactive, error = false } = _a, rest = __rest(_a, ["className", "children", "as", "unsafeIndex", "completed", "interactive", "error"]);
    const context = useContext(StepperContext);
    if (context === null) {
        console.error('<Stepper.Step> has to be used within <Stepper>');
        return null;
    }
    const { activeStep } = context;
    const isInteractive = interactive !== null && interactive !== void 0 ? interactive : context === null || context === void 0 ? void 0 : context.interactive;
    const Comp = isInteractive ? Component : 'div';
    return (React.createElement(Comp, Object.assign({}, rest, { ref: ref, className: cl('cds-stepper__step', {
            'cds-stepper__step--active': activeStep === unsafeIndex,
            'cds-stepper__step--behind': activeStep > unsafeIndex,
            'cds-stepper__step--non-interactive': !isInteractive,
            'cds-stepper__step--completed': completed,
            'cds-stepper__step--error': error
        }, className), "aria-current": activeStep === unsafeIndex, onClick: (e) => {
            var _a;
            isInteractive && context.onStepChange(unsafeIndex + 1);
            (_a = rest === null || rest === void 0 ? void 0 : rest.onClick) === null || _a === void 0 ? void 0 : _a.call(rest, e);
        } }),
        completed ? (React.createElement(CheckCircle, { "aria-hidden": true, className: 'cds-stepper__circle cds-stepper__circle--success' })) : (React.createElement(Label, { className: 'cds-stepper__circle', as: 'span', "aria-hidden": 'true' }, unsafeIndex + 1)),
        React.createElement(Label, { as: 'span', className: 'cds-stepper__content' }, children)));
});
Step.displayName = 'Stepper.Step';
export default Step;
