import { Consultant, Query } from 'declarations/models'

import { ConsultantSignature } from 'components/Consultants'
import TagGroup, { RenderedElementProps, TagGroupMode } from 'components/TagGroup/TagGroup'

interface ConsultantGroupProps<Q> {
  query?: Q
  consultants: Array<Consultant> | null | undefined
  setQuery?: (q: Q) => void
  interactive?: boolean
  mode?: TagGroupMode
  size?: 'medium' | 'small'
  visibleRows?: number
}

const ConsultantsGroup = <Q extends Partial<Query>>({
  consultants,
  size,
  mode,
  interactive,
  visibleRows
}: ConsultantGroupProps<Q>) => {
  return (
    <TagGroup
      size={size}
      tags={consultants}
      mode={mode}
      interactive={interactive}
      visibleRows={visibleRows}
      renderElement={(props: RenderedElementProps<Consultant>) => (
        <ConsultantSignature showName consultant={props.tag} popover={['view']} />
      )}
    />
  )
}

export default ConsultantsGroup
