import { Column, Datepicker, Row, TextField, VerticalSpace } from '@cegal/ds-components'
import { useAppSelector } from 'store'

import { EngagementStepFormProps } from 'components/Engagements/Engagement/Form/EngagementForm'
import FileUpload from 'components/File/FileUpload'

import { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const Step3 = (props: EngagementStepFormProps) => {
  const { t } = useTranslation()

  const {
    register,
    control,
    formState: { errors }
  } = useFormContext()
  const language = useAppSelector((state) => state.app.settings.language)
  const [dates, setDates] = useState({ startDate: '', endDate: '' })

  return (
    <>
      <Row>
        <Column>
          <Controller
            control={control}
            name='start_date'
            rules={{
              validate: (value: string | number) =>
                Date.parse(dates.endDate) < Date.parse(value as string)
                  ? t('validation:engagement-start-date-conflict').toString()
                  : undefined
            }}
            render={({ field: { onChange, value } }) => (
              <Datepicker
                lang={language}
                label={t('label:start-date-title')}
                value={value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const _dates = { ...dates, startDate: event.target.value }
                  setDates(_dates)
                  onChange(event.target.value)
                }}
                error={errors?.start_date?.message?.toString()}
              />
            )}
          />
          <VerticalSpace />
        </Column>

        <Column>
          <Controller
            control={control}
            name='end_date'
            rules={{
              validate: (value: string | number) =>
                Date.parse(dates.startDate) > Date.parse(value as string)
                  ? t('validation:engagement-end-date-conflict').toString()
                  : undefined
            }}
            render={({ field: { onChange, value } }) => (
              <Datepicker
                lang={language}
                label={t('label:end-date-title')}
                value={value}
                defaultValue={dates.startDate}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const _dates = { ...dates, endDate: event.target.value }
                  setDates(_dates)
                  onChange(event.target.value)
                }}
                error={errors?.end_date?.message?.toString()}
              />
            )}
          />
          <VerticalSpace />
        </Column>
        <Column flex='2' />
      </Row>
      <VerticalSpace />
      <Row>
        <Column>
          <TextField
            type='number'
            label={t('label:extension-title') + ' (' + t('label:extension-description') + ')'}
            {...register('optional_extension_period', {
              value: 0,
              valueAsNumber: true,
              validate: {
                positive: (value: string) => parseInt(value) >= 0 || 'Extension should be 0 or greater than 0'
              }
            })}
            error={errors?.optional_extension_period?.message?.toString()}
          />
          <VerticalSpace />
        </Column>

        <Column>
          <Controller
            control={control}
            name='deadline'
            render={({ field: { onChange, value } }) => (
              <Datepicker
                lang={language}
                label={t('label:deadline-title')}
                value={value}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  onChange(event.target.value)
                }}
                error={errors?.deadline?.message?.toString()}
              />
            )}
          />
          <VerticalSpace />
        </Column>
        <Column flex='2' />
      </Row>
      <Row>
        <Column>
          <Controller
            control={control}
            name='file'
            render={({ field: { onChange, value } }) => (
              <FileUpload anchorEl={props.formRef?.current} value={value} onChange={onChange} />
            )}
          />
          <VerticalSpace />
        </Column>
        <Column />
      </Row>
    </>
  )
}

export default Step3
