import { FlexCenterDiv, HorizontalSpace, PileDiv, VerticalSpace } from '@cegal/ds-components'
import { Chart } from '@cegal/ds-graph'
import { BarChart } from '@cegal/ds-icons/dist/BarChart'
import { ChevronLeft } from '@cegal/ds-icons/dist/ChevronLeft'
import { ChevronRight } from '@cegal/ds-icons/dist/ChevronRight'
import { FilterCenterFocus } from '@cegal/ds-icons/dist/FilterCenterFocus'
import { KeyboardArrowDown } from '@cegal/ds-icons/dist/KeyboardArrowDown'
import { KeyboardArrowUp } from '@cegal/ds-icons/dist/KeyboardArrowUp'
import { ShowChart as LineChart } from '@cegal/ds-icons/dist/ShowChart'
import 'chartjs-adapter-date-fns'
import { Allocation, Consultant } from 'declarations/models'
import { DateTime } from 'luxon'

import useConsultantAllocationChartConfig from 'components/Consultants/Consultant/Allocation/useConsultantAllocationChartConfig'
import { MicroButton } from 'components/styled'

import React, { CSSProperties, useMemo, useState } from 'react'

import BEMHelper from 'utils/bem'

import { generateDatasetFromAllocation } from './ConsultantAllocationChartUtils'
import ConsultantOverview from './ConsultantOverview'
import './consultantAllocationChart.scss'

export enum ConsultantAllocationChartType {
  Line = 'line',
  Bar = 'bar'
}

export enum ConsultantAllocationChartUnit {
  Day = 'day',
  Week = 'week',
  Month = 'month'
}

interface ConsultantAllocationChartProps {
  /* line or bar */
  type?: ConsultantAllocationChartType
  unit?: ConsultantAllocationChartUnit
  offsetInUnits?: number

  chartLabel: string
  consultant: Consultant | null | undefined
  showOverview?: boolean
  allocation: Array<Allocation> | null | undefined
  controls?: boolean
  chartStyleOptions?: CSSProperties
  showChartLabel?: boolean
  showYAxisLabel?: boolean
  showXAxisLabel?: boolean
}

export interface ChartDataset {
  x: string
  y: number
  tooltip?: any
}

const ConsultantAllocationChart: React.FunctionComponent<ConsultantAllocationChartProps> = ({
  type = ConsultantAllocationChartType.Line,
  unit = ConsultantAllocationChartUnit.Week,
  offsetInUnits = 10,
  chartLabel,
  consultant,
  allocation,
  showOverview = false,
  controls = false,
  chartStyleOptions = {},
  showChartLabel = false,
  showYAxisLabel = false,
  showXAxisLabel = false
}: ConsultantAllocationChartProps) => {
  const cls = BEMHelper('consultantChart')

  const [hover, setHover] = useState<boolean>(false)
  const [_type, setType] = useState(type)
  const [_date, setDate] = useState(DateTime.now())
  const [_unit, setUnit] = useState(unit)
  const [_offsetInUnits, setOffsetInUnits] = useState<number>(offsetInUnits)

  const MIN_OFFSET_IN_UNITS = 5
  const MAX_OFFSET_IN_UNITS = 50

  const dataset: ChartDataset[] = useMemo(
    () => generateDatasetFromAllocation(consultant, allocation, _date, _unit, _offsetInUnits),
    [allocation, _date, _unit, _offsetInUnits]
  )

  const { plugins, data, options } = useConsultantAllocationChartConfig({
    type: _type,
    showChartLabel,
    showXAxisLabel,
    showYAxisLabel,
    chartLabel,
    dataset
  })

  const handleZoomIn = () => {
    setOffsetInUnits(_offsetInUnits - 1)
  }

  const handleZoomOut = () => {
    setOffsetInUnits(_offsetInUnits + 1)
  }

  const canZoomIn = (): boolean => {
    return dataset?.length >= MIN_OFFSET_IN_UNITS
  }

  const canPanLeft = (): boolean => {
    if (dataset?.length >= MIN_OFFSET_IN_UNITS) {
      return true
    }
    return _date > DateTime.now()
  }

  const canPanRight = (): boolean => {
    if (dataset?.length >= MIN_OFFSET_IN_UNITS) {
      return true
    }
    return _date < DateTime.now()
  }

  const canZoomOut = (): boolean => {
    return dataset?.length <= MAX_OFFSET_IN_UNITS
  }

  const Component = _type === ConsultantAllocationChartType.Line ? Chart.Line : Chart.Bar
  if (dataset.length <= 1) {
    return null
  }
  return (
    <FlexCenterDiv onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <PileDiv flex='3' className={cls.className}>
        <div className={cls.element('wrapper')} style={chartStyleOptions}>
          <Component
            className={cls.element('consultant-chart')}
            data={data}
            options={options}
            plugins={plugins}
          />
        </div>
        {showOverview && <ConsultantOverview consultant={consultant!} allocation={allocation} />}
      </PileDiv>
      <HorizontalSpace size='0.3' />
      <PileDiv>
        {controls && (
          <div style={{ visibility: hover ? 'visible' : 'hidden' }}>
            <table cellPadding={0}>
              <tr>
                <td>
                  <MicroButton
                    icon={<LineChart size='0.85rem' />}
                    title='See chart as a line graph'
                    variant={_type === ConsultantAllocationChartType.Line ? 'primary' : 'secondary'}
                    onClick={() => setType(ConsultantAllocationChartType.Line)}
                  />
                </td>
                <td>
                  <MicroButton
                    icon={<BarChart size='0.85rem' />}
                    title='See chart as a bar graph'
                    variant={_type === ConsultantAllocationChartType.Bar ? 'primary' : 'secondary'}
                    onClick={() => setType(ConsultantAllocationChartType.Bar)}
                  />
                </td>
              </tr>
              <VerticalSpace size='0.2' />
              <tr>
                <td>
                  <MicroButton
                    style={{ minWidth: '1.5rem' }}
                    title='See chart values as days'
                    variant={_unit === ConsultantAllocationChartUnit.Day ? 'primary' : 'secondary'}
                    onClick={() => setUnit(ConsultantAllocationChartUnit.Day)}
                  >
                    D
                  </MicroButton>
                </td>
                <td>
                  <MicroButton
                    style={{ minWidth: '1.5rem' }}
                    title='See chart values as weeks'
                    variant={_unit === ConsultantAllocationChartUnit.Week ? 'primary' : 'secondary'}
                    onClick={() => setUnit(ConsultantAllocationChartUnit.Week)}
                  >
                    W
                  </MicroButton>
                </td>
                <td>
                  <MicroButton
                    style={{ minWidth: '1.5rem' }}
                    title='See chart values as months'
                    variant={_unit === ConsultantAllocationChartUnit.Month ? 'primary' : 'secondary'}
                    onClick={() => setUnit(ConsultantAllocationChartUnit.Month)}
                  >
                    M
                  </MicroButton>
                </td>
              </tr>
              <VerticalSpace size='0.2' />
              <tr>
                <td />
                <td>
                  <MicroButton
                    variant='secondary'
                    title='Zoom in'
                    disabled={!canZoomIn()}
                    icon={<KeyboardArrowUp size='0.85rem' />}
                    onClick={handleZoomIn}
                  />
                </td>
                <td />
              </tr>
              <tr>
                <td>
                  <MicroButton
                    variant='secondary'
                    disabled={!canPanLeft()}
                    title={'Move data one ' + _unit + ' in the past'}
                    icon={<ChevronLeft size='0.85rem' />}
                    onClick={() => setDate(_date.minus({ [_unit]: 1 }))}
                  />
                </td>
                <td>
                  <MicroButton
                    variant='secondary'
                    title={'Recenter to date ' + DateTime.now().toFormat('yyyy-MM-dd')}
                    icon={<FilterCenterFocus size='0.85rem' />}
                    onClick={() => setDate(DateTime.now())}
                  />
                </td>
                <td>
                  {' '}
                  <MicroButton
                    variant='secondary'
                    disabled={!canPanRight()}
                    title={'Move data one ' + _unit + ' in the future'}
                    icon={<ChevronRight size='0.85rem' />}
                    onClick={() => setDate(_date.plus({ [_unit]: 1 }))}
                  />
                </td>
              </tr>
              <tr>
                <td />
                <td>
                  <MicroButton
                    variant='secondary'
                    title='Zoom out'
                    disabled={!canZoomOut()}
                    icon={<KeyboardArrowDown size='0.85rem' />}
                    onClick={handleZoomOut}
                  />
                </td>
                <td />
              </tr>
            </table>
          </div>
        )}
      </PileDiv>
    </FlexCenterDiv>
  )
}
export default ConsultantAllocationChart
