import { listEngagements } from 'actions'
import { Consultant } from 'declarations/models'
import { useAppDispatch, useAppSelector } from 'store'

import Section from 'components/Forms/Section'
import { FullScreenLoader } from 'components/Loader/CustomLoader'

import { useEffect } from 'react'

import { t } from 'i18next'

export type ConsultantEngagementsProps = {
  consultant?: Consultant
}

const ConsultantEngagements = ({ consultant }: ConsultantEngagementsProps) => {
  const dispatch = useAppDispatch()
  // const consultantEngagements = useAppSelector((state) => state.consultants.get?.engagements)
  const engagements = useAppSelector((state) => state.engagements.list)
  const engagementsLoading = useAppSelector((state) => state.engagements.listing)
  // const savingEngagementVacancy = useAppSelector((state) => state.engagements.savingVacancy)
  // const [editingVacancy, setEditingVacancy] = useState<number>()

  useEffect(() => {
    if (consultant && !engagementsLoading && engagements === undefined) {
      dispatch(listEngagements())
    }
  }, [consultant, engagementsLoading, engagements])

  if (engagementsLoading) {
    return <FullScreenLoader />
  }

  /* const activeEngagements = consultantEngagements?.filter((engagement) => {
    const today = new Date()
    const startDate = new Date(engagement.start_date)
    const endDate = new Date(engagement.end_date)
    return startDate < today && today < endDate
  })

  const matchedEngagements = engagements?.filter((engagement) => {
    return engagement.vacancies.some((vacancy) => {
      return vacancy.consultants?.some((consultant) => {
        return consultant.status === 'matched'
      })
    })
  })

  const onEditVacancy = (newVacancy: EngagementVacancy) => {
    if (engagements && engagements[0]) {
      dispatch(editEngagementVacancy(newVacancy, engagements[0].id))
    }
  } */

  return (
    <div>
      <Section heading={t('label:engagements')!} />
      {/* TODO render matched engagements. Doesnt work right now need backend to fix */}
      {/* <Section heading={t('label:matched-engagements')!}>
        
        {matchedEngagements?.map((engagement: Engagement) => {
          return editingVacancy === engagement?.vacancies?.[0]?.id ? (
            <EngagementVacancyForm
              isEditing={savingEngagementVacancy}
              vacancy={engagement?.vacancies?.[0]}
              onNewVacancy={onEditVacancy}
              onClose={() => setEditingVacancy(undefined)}
            />
          ) : (
            <EngagementVacancyCard
              key={engagement?.vacancies?.[0]?.id}
              item={{ ...engagement?.vacancies?.[0] }}
              spacing
              onEdit={(engagement) => setEditingVacancy(engagement.id)}
            />
          )
        })}
      </Section> */}
    </div>
  )
}

export default ConsultantEngagements
