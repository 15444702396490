import {
  BodyShort,
  Button,
  Header as CegalHeader,
  Dropdown,
  FlexCenterDiv,
  FlexDiv,
  FlexEndDiv,
  HorizontalSpace,
  Link,
  NavBar,
  PileDiv,
  Radio,
  RadioGroup,
  VerticalSpace
} from '@cegal/ds-components'
import { Close, Logout, Menu, Person, Settings } from '@cegal/ds-icons'
import { ReactComponent as CegalWhiteLogo } from '@cegal/ds-logos/logos/svg/CegalWhite.svg'
import { logout, setAiDrawerOpen, setLanguage, setTheme } from 'actions'
import catHeadphones from 'assets/images/catHeadphones.svg'
import kittyHead from 'assets/images/kittyHead.svg'
import { SHOW_AI_DRAWER } from 'config'
import { AllowedLanguages, AllowedThemes } from 'declarations/app'
import { eventLogger, standardLogger } from 'metrics/loggers'
import { useAppDispatch, useAppSelector } from 'store'
import styled from 'styled-components'

import { NoWrapSpan } from 'components/styled'

import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, generatePath, useNavigate } from 'react-router-dom'

import _ from 'lodash'

const StickyCegalHeader = styled(CegalHeader)`
  position: fixed;
  width: 100vw;
  top: 0;
  min-height: var(--cat-header-height);
  z-index: var(--cds-z-index-popover);
`

const ResponsiveHeaderContainer = styled(FlexDiv)`
  align-items: flex-end;
  flex-wrap: nowrap;
  justify-content: space-between;
  @media (max-width: 992px) {
    flex-direction: column;
    align-items: center;
  }
`
const ResponsiveHeaderLogoAndMenu = styled(FlexEndDiv)`
  flex-wrap: nowrap;
  @media (max-width: 992px) {
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
  }
`
const ResponsiveUserAndTheme = styled(FlexCenterDiv)<{ $viewMode: string }>`
  flex-wrap: nowrap;
  margin-right: 1rem;
  margin-top: 0.5rem;
  display: ${({ $viewMode }) => ($viewMode === 'collapsed' ? 'none' : 'flex')};
`

const BurgerMenu = styled(FlexDiv)`
  display: none;
  @media (max-width: 992px) {
    display: flex;
    right: 0.5rem;
    top: 0.5rem;
    position: absolute;
  }
`

const CatNavBar = styled(NavBar)<{ $viewMode: string }>`
  display: ${({ $viewMode }) => ($viewMode === 'collapsed' ? 'none' : 'flex')};
  margin-top: 0.5rem;
  margin-bottom: -3px;
  .cds-navbar-link {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`
const LogoImage = styled.img`
  height: 2.5rem;
  margin-right: -0.5rem;
`

const AiButton = styled(Button)`
  padding: 0 0.5rem 0 0.3rem;
  background-color: var(--cds-color-cegallime);
  color: var(--cds-color-black);
  &:hover {
    background-color: var(--cds-color-cegallime-darker10);
    color: var(--cds-color-black);
  }
`

export type ViewMode = 'expanded' | 'collapsed'

const Header = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const ref = useRef(null)
  const [viewMode, setViewMode] = useState<ViewMode>('expanded')

  const aiDrawerOpen = useAppSelector((state) => state.app.settings.aiDrawerOpen)
  const language = useAppSelector((state) => state.app.settings.language)
  const theme = useAppSelector((state) => state.app.settings.theme)
  const displaySize = useAppSelector((state) => state.app.displaySize)
  const userRole = useAppSelector((state) => state.app.userRole)
  const userProfile = useAppSelector((state) => state.app.userProfile)

  const defaultNavbarLink = useMemo(() => {
    let haystack = ['home', 'engagements', 'consultants', 'clients', 'admin', 'about', 'orgchart', 'swagger']
    if (userRole !== 'admin') {
      haystack = _.reject(haystack, (value) => value === 'admin')
    }
    return haystack.indexOf(window.location.pathname.split('/')[1])
  }, [userRole, window.location.pathname])

  useEffect(() => {
    if (displaySize === 'sm' || displaySize === 'md' || displaySize === 'lg') {
      setViewMode('collapsed')
    } else {
      setViewMode('expanded')
    }
  }, [displaySize])

  const handleNavbarViewMode = () => {
    setViewMode(viewMode === 'expanded' ? 'collapsed' : 'expanded')
  }

  const handleLogout = () => {
    standardLogger('header.user.logout')
    dispatch(logout())
  }

  const handleLanguageChange = () => {
    const newLanguage: AllowedLanguages = language === 'en' ? 'no' : 'en'
    standardLogger('header.language.' + newLanguage)
    dispatch(setLanguage(newLanguage))
  }

  const toggleTheme = () => {
    const newTheme: AllowedThemes = theme === 'cegal-light' ? 'cegal-dark' : 'cegal-light'
    standardLogger('header.theme.' + newTheme)
    dispatch(setTheme(newTheme))
  }

  return (
    <StickyCegalHeader ref={ref} data-testid='Header'>
      <ResponsiveHeaderContainer>
        <ResponsiveHeaderLogoAndMenu>
          <Link
            as={NavLink}
            data-testid='Header-Logo'
            to={generatePath('/home')}
            data-amplitude='header.nav.logo'
            onClick={(e: any) => eventLogger(e)}
          >
            <img height='50px' src={kittyHead} alt='Cat logo' />
            {!(displaySize === 'sm' || displaySize === 'md') && (
              <CegalWhiteLogo className='logo' style={{ padding: '1rem 0.5rem', width: '170px' }} />
            )}
          </Link>
          <HorizontalSpace size='0.5' />
          {userProfile && (
            <CatNavBar
              style={{ flexWrap: displaySize === 'sm' || displaySize === 'md' ? 'wrap' : 'nowrap' }}
              orientation='horizontal'
              value={defaultNavbarLink}
              data-cy='appBarItemsButton'
              $viewMode={viewMode}
            >
              <NavBar.Link
                data-cy='homeNav'
                data-testid='Header-Home'
                data-amplitude='header.nav.home'
                onClick={(e) => {
                  eventLogger(e)
                  navigate('/home')
                }}
              >
                {t('header:home')}
              </NavBar.Link>

              <NavBar.Link
                data-cy='EngagementsNav'
                data-testid='Header-Engagements'
                data-amplitude='header.nav.engagements'
                onClick={(e) => {
                  eventLogger(e)
                  navigate('/engagements')
                }}
              >
                {t('header:engagements')}
              </NavBar.Link>

              <NavBar.Link
                data-cy='ConsultantsNav'
                data-testid='Header-Consultants'
                data-amplitude='header.nav.consultants'
                onClick={(e) => {
                  eventLogger(e)
                  navigate('/consultants')
                }}
              >
                {t('header:consultants')}
              </NavBar.Link>

              <NavBar.Link
                data-cy='ClientsNav'
                data-testid='Header-Clients'
                data-amplitude='header.nav.clients'
                onClick={(e) => {
                  eventLogger(e)
                  navigate('/clients')
                }}
              >
                {t('header:clients')}
              </NavBar.Link>

              {userRole === 'admin' && (
                <NavBar.Link
                  data-cy='AdminNav'
                  data-testid='Header-Admin'
                  data-amplitude='header.nav.admin'
                  onClick={(e) => {
                    eventLogger(e)
                    navigate('/admin')
                  }}
                >
                  {t('header:admin')}
                </NavBar.Link>
              )}

              <NavBar.Link
                data-cy='AboutNav'
                data-testid='Header-About'
                data-amplitude='header.nav.about'
                onClick={(e) => {
                  eventLogger(e)
                  navigate('/about')
                }}
              >
                <NoWrapSpan>{t('header:about-us')}</NoWrapSpan>
              </NavBar.Link>

              <NavBar.Link
                data-cy='OrgChartNav'
                data-testid='Header-OrgChart'
                data-amplitude='header.nav.orgchart'
                onClick={(e) => {
                  eventLogger(e)
                  navigate('/orgchart')
                }}
              >
                {t('header:org-chart')}
              </NavBar.Link>

              <NavBar.Link
                data-cy='SwaggerNav'
                data-testid='Header-Swagger'
                data-amplitude='header.nav.api'
                onClick={(e) => {
                  eventLogger(e)
                  navigate('/swagger')
                }}
              >
                {t('header:API')}
              </NavBar.Link>
            </CatNavBar>
          )}
        </ResponsiveHeaderLogoAndMenu>
        <ResponsiveUserAndTheme $viewMode={viewMode}>
          <PileDiv>
            <Dropdown>
              <Button
                style={{ padding: 'var(--cds-size-2)' }}
                icon={<Settings size='1.5rem' />}
                variant='secondary'
                as={Dropdown.Toggle}
              />
              <Dropdown.Menu>
                <Dropdown.Menu.List>
                  <PileDiv style={{ padding: '0 1rem' }}>
                    <BodyShort>{t('label:theme')}</BodyShort>
                    <RadioGroup value={theme} onChange={toggleTheme} data-testid='Header-ThemeButton'>
                      <Radio value='cegal-light'>Light</Radio>
                      <Radio value='cegal-dark'>Dark</Radio>
                    </RadioGroup>{' '}
                  </PileDiv>
                  <Dropdown.Menu.Divider />
                  <PileDiv style={{ padding: '0 1rem' }}>
                    <BodyShort>{t('label:language')}</BodyShort>
                    <RadioGroup value={language} onChange={handleLanguageChange}>
                      <Radio value='en'>English</Radio>
                      <Radio value='no'>Norsk</Radio>
                    </RadioGroup>
                  </PileDiv>
                </Dropdown.Menu.List>
              </Dropdown.Menu>
            </Dropdown>
            <VerticalSpace size='0.5' />
          </PileDiv>
          <HorizontalSpace />
          {userProfile && (
            <>
              <PileDiv>
                <Dropdown>
                  <Button
                    style={{ padding: 'var(--cds-size-2)' }}
                    icon={<Person size='1.5rem' />}
                    variant='secondary'
                    as={Dropdown.Toggle}
                  />
                  <Dropdown.Menu>
                    <Dropdown.Menu.List>
                      <PileDiv style={{ padding: '0 1rem' }}>
                        <BodyShort>Logged in as</BodyShort>
                        <BodyShort style={{ fontStyle: userRole === 'admin' ? 'italic' : 'normal' }}>
                          {userProfile?.name}
                        </BodyShort>
                      </PileDiv>
                      <Dropdown.Menu.List.Item style={{ marginTop: '1rem' }} onClick={handleLogout}>
                        <Logout size='1.5rem' /> Log out
                      </Dropdown.Menu.List.Item>
                    </Dropdown.Menu.List>
                  </Dropdown.Menu>
                </Dropdown>
                <VerticalSpace size='0.5' />
              </PileDiv>

              <HorizontalSpace />
              {SHOW_AI_DRAWER && (
                <PileDiv style={{ marginRight: '1rem' }}>
                  <AiButton
                    variant='secondary'
                    icon={<LogoImage src={catHeadphones} alt='Cat logo' />}
                    onClick={() => dispatch(setAiDrawerOpen(!aiDrawerOpen))}
                  >
                    <BodyShort>AI tools</BodyShort>
                  </AiButton>
                  <VerticalSpace size='0.5' />
                </PileDiv>
              )}
            </>
          )}
        </ResponsiveUserAndTheme>

        <BurgerMenu>
          <Button onClick={handleNavbarViewMode}>
            {viewMode === 'collapsed' ? <Menu size='2rem' /> : <Close size='2rem' />}
          </Button>
        </BurgerMenu>
      </ResponsiveHeaderContainer>
    </StickyCegalHeader>
  )
}

export default Header
