import { Button, Chip, Panel, PileEndDiv, Tabs } from '@cegal/ds-components'
import styled from 'styled-components'

export const NoWrapSpan = styled.span`
  white-space: nowrap;
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`

export const CardContainer = styled.div`
  column-count: 2;
  column-gap: 12px;
  @media (max-width: 768px) {
    column-count: 1;
  }
`

export const InnerCardContainer = styled.div`
  column-gap: 12px;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
`

export const HomeElementPanel = styled(Panel)<{ spacing?: boolean }>`
  width: ${({ width }) => width || '100%'};
  height: auto;
  break-inside: avoid;
  background-color: var(--cat-homepanel-element-background-color);
  margin-bottom: ${(props) => (props.spacing ? '1rem' : '0rem')};
`

export const DrawerButtonsDiv = styled(PileEndDiv)`
  position: sticky;
  bottom: 0;
  padding: 1rem;
  margin: -1rem;
  background-color: var(--cat-drawer-background-color);
`

export const OverlayDiv = styled.div`
  background-color: var(--cds-overlay-color);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`

export const InsetDiv = styled.div`
  display: flex;
  background-color: var(--cds-background-color);
  padding: 0.5rem;
  flex-direction: column;
  align-items: center;
  box-shadow: var(--cds-shadow-focus);
`

export const MicroButton = styled(Button)`
  padding: 4px;
  min-height: auto;
  .cds-label {
    line-height: 0.85rem;
    font-size: 0.85rem;
  }
`

export const TabPanel = styled(Tabs.Panel)`
  padding: 0px;
  padding-top: 1rem;
`

export const Hr = styled.hr`
  border: 0;
  height: 1px;
  background-image: linear-gradient(90deg, var(--cds-text-color), transparent);
`

export const MarginChip = styled(Chip)`
  margin: 0rem 0.3rem;
`

export const MarginChipMarquee = styled(Chip)`
  .rfm-marquee-container {
    width: 200px;
  }

  .rfm-marquee {
    animation: none;
  }

  &:hover {
    .rfm-marquee {
      animation: scroll var(--duration) linear var(--delay) var(--iteration-count);
    }
  }

  .rfm-child {
    padding-right: 1rem;
  }
`
