var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { TabsContent } from '@radix-ui/react-tabs';
import cl from 'clsx';
import React, { forwardRef } from 'react';
const TabPanel = forwardRef((_a, ref) => {
    var { className, noPadding = false } = _a, rest = __rest(_a, ["className", "noPadding"]);
    return (React.createElement(TabsContent, Object.assign({}, rest, { ref: ref, className: cl('cds-tabs__tabpanel', { 'cds-tabs__tabpanel-nopadding': noPadding }, className) })));
});
TabPanel.displayName = 'Tab.Panel';
export default TabPanel;
