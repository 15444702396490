import { Button, Heading } from '@cegal/ds-components'
import { UnfoldLess, UnfoldMore } from '@cegal/ds-icons'
import styled from 'styled-components'

import { StyledFlexBaseSpacedDiv } from 'components/Forms/FacetedFilterSection/FacetedFilterSectionWrapper'

const CollapseButton = styled(Button)`
  padding: 0.4rem;
`

const FacetedFilterSectionMeta = ({ header, collapsed, onCollapse }: any) => {
  return (
    <StyledFlexBaseSpacedDiv>
      <Heading>{header}</Heading>
      <CollapseButton size='small' variant='tertiary' onClick={onCollapse}>
        {!collapsed ? <UnfoldLess size='2rem' /> : <UnfoldMore size='2rem' />}
      </CollapseButton>
    </StyledFlexBaseSpacedDiv>
  )
}

export default FacetedFilterSectionMeta
