import { FlexBaseDiv, HorizontalSpace, Modal, Panel } from '@cegal/ds-components'
import { Consultant } from 'declarations/models'

import ConsultantViewContent from 'components/Consultants/Consultant/ConsultantViewContent'

import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

export interface OpenConsultantPreviewModalProps {
  open: boolean
  consultant?: Consultant
  onClose: () => void
}

const ConsultantPreviewModal = ({ open, consultant, onClose }: OpenConsultantPreviewModalProps) => {
  const { t } = useTranslation()
  const [modalContainer, setModalContainer] = useState<HTMLElement | null>(null)

  useEffect(() => {
    if (modalContainerRef.current) {
      setModalContainer(modalContainerRef.current)
    }
  }, [])

  const modalContainerRef = useRef<HTMLDivElement>(null)

  return (
    <FlexBaseDiv ref={modalContainerRef}>
      {modalContainer && (
        <Modal
          aria-label={t('header:consultant-preview')}
          parentSelector={() => modalContainer}
          open={open}
          onClose={onClose}
        >
          <HorizontalSpace />
          <Modal.Content>
            <Panel style={{ width: '80vw', height: '80vh' }}>
              <ConsultantViewContent consultant={consultant} />
            </Panel>
          </Modal.Content>
        </Modal>
      )}
    </FlexBaseDiv>
  )
}

export default ConsultantPreviewModal
