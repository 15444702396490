import styled, { css } from 'styled-components'

import { PropsWithChildren } from 'react'

export type FluidDrawerProps = PropsWithChildren<{
  open?: boolean
  width?: number | string
}>

const DrawerWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['open', 'width'].includes(prop as string)
})<{ width?: number | string; open?: boolean }>`
  background-color: var(--cat-drawer-background-color);
  height: 100%;
  overflow: visible;
  position: relative;
  transition: all 0.3s;
  width: ${({ width }) => width};
  ${({ width, open }) =>
    !open &&
    css`
      margin-left: -${width};
    `};
`

const ContentWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
`

const FixedContainer = styled.div`
  position: fixed;
  top: var(--cat-header-height);
  height: calc(100vh - var(--cat-header-height));
  z-index: var(--cds-z-index-focus);
`

const FluidDrawer = ({ children, open, width = '20rem' }: FluidDrawerProps) => {
  return (
    <FixedContainer>
      <DrawerWrapper open={open} width={width}>
        <ContentWrapper>{children}</ContentWrapper>
      </DrawerWrapper>
    </FixedContainer>
  )
}

export default FluidDrawer
