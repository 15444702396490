import * as Amplitude from 'metrics/amplitude'
import '@cegal/ds-css'
import 'index.css'
import reportWebVitals from 'reportWebVitals'
import store from 'store'

import MetaContainer from 'components/MetaContainer/MetaContainer'
import PrivateRoute from 'components/PrivateRoute/PrivateRoute'

import * as Pages from 'pages'

import React, { Suspense } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import ReactDOM from 'react-dom/client'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { IS_PRODUCTION } from 'utils/environment'

import i18n from 'i18n'

if (!IS_PRODUCTION) {
  const axe = require('@axe-core/react')
  axe(React, ReactDOM, 1000)
}
Amplitude.init()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <I18nextProvider i18n={i18n}>
    <DndProvider backend={HTML5Backend}>
      <Provider store={store}>
        <Suspense fallback={<div>"Loading default language..."</div>}>
          <BrowserRouter>
            <MetaContainer>
              <Routes>
                <Route path='/' element={<PrivateRoute />}>
                  <Route path='engagements' element={<Pages.Engagements.Route />}>
                    <Route index element={<Pages.Engagements.EngagementsList />} />
                    <Route path=':id/edit' element={<Pages.Engagements.EngagementEdit />} />
                    <Route path=':id/find' element={<Pages.Engagements.EngagementFind />} />
                    <Route path=':id' element={<Pages.Engagements.EngagementView />} />
                    <Route path='new' element={<Pages.Engagements.EngagementNew />} />
                  </Route>
                  <Route path='consultants' element={<Pages.Consultants.Route />}>
                    <Route path=':id/find' element={<Pages.Consultants.ConsultantFind />} />
                    <Route path=':id' element={<Pages.Consultants.ConsultantView />} />
                    <Route index element={<Pages.Consultants.ConsultantsList />} />
                  </Route>
                  <Route path='clients' element={<Pages.Clients.Route />}>
                    <Route index element={<Pages.Clients.ClientsList />} />
                    <Route path=':id' element={<Pages.Clients.ClientView />} />
                  </Route>
                  <Route path='admin' element={<Pages.Admin.Route />}>
                    <Route index element={<Pages.Admin.Home />} />
                    <Route path='categories' element={<Pages.Admin.Categories />} />
                    <Route path='roles' element={<Pages.Admin.Roles />} />
                    <Route path='skills' element={<Pages.Admin.Skills />} />
                    <Route path='departments' element={<Pages.Admin.Departments />} />
                    <Route path='locations' element={<Pages.Admin.Locations />} />
                    <Route path='sources' element={<Pages.Admin.Sources />} />
                    <Route path='clients' element={<Pages.Admin.Clients />} />
                    <Route path='permissions' element={<Pages.Admin.Permissions />} />
                    <Route path='news' element={<Pages.Admin.News />} />
                    <Route path='syncing' element={<Pages.Admin.Syncing />} />
                    <Route path='subscriptions' element={<Pages.Admin.Subscriptions />} />
                  </Route>
                  <Route path='home' element={<Pages.Home />} />
                  <Route path='/about' element={<Pages.About />} />
                  <Route path='swagger' element={<Pages.Swagger />} />
                  <Route path='/orgchart' element={<Pages.OrgChart />} />
                </Route>
                <Route path='/cards' element={<Pages.Cards />} />
                <Route path='/signup' element={<Pages.SignUp />} />
                <Route path='/login' element={<Pages.Login />} />
                <Route path='/add-to-cetegra' element={<Pages.AddToCetegra />} />
                <Route path='*' element={<Pages.Error />} />
              </Routes>
            </MetaContainer>
          </BrowserRouter>
        </Suspense>
      </Provider>
    </DndProvider>
  </I18nextProvider>
)

reportWebVitals()
