import { FlexBaseDiv, Modal } from '@cegal/ds-components'
import { EngagementVacancyConsultant } from 'declarations/models'

import EngagementVacancyConsultantForm from 'components/Engagements/Engagement/Vacancy/Consultant/EngagementVacancyConsultantForm'

import { useRef } from 'react'
import { useTranslation } from 'react-i18next'

export interface EditMatchModalProps {
  match: EngagementVacancyConsultant | undefined
  open: boolean
  onClose: () => void
  onSaveMatch: (workingCopyMatch: EngagementVacancyConsultant) => void
}

const MyMatchesModal = ({ match, open, onClose, onSaveMatch }: EditMatchModalProps) => {
  const { t } = useTranslation()

  const saveEvcChanges = (updatedMatch: Partial<EngagementVacancyConsultant>) => {
    onSaveMatch(updatedMatch as EngagementVacancyConsultant)
  }

  const modalContainerRef = useRef(null)

  return (
    <FlexBaseDiv ref={modalContainerRef}>
      {modalContainerRef?.current && (
        <Modal
          parentSelector={() => modalContainerRef.current!}
          open={open}
          aria-label={t('home:edit-match')}
          closeButton={false}
          onClose={onClose}
          shouldCloseOnOverlayClick={false}
        >
          <Modal.Content style={{ minWidth: '500px' }}>
            <EngagementVacancyConsultantForm
              formRef={modalContainerRef?.current}
              spacing={false}
              evc={match}
              showConsultant={false}
              onEvcChanged={saveEvcChanges}
              onClose={onClose}
            />
          </Modal.Content>
        </Modal>
      )}
    </FlexBaseDiv>
  )
}

export default MyMatchesModal
