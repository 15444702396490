import { BodyShort, Chip, Loader, VerticalSpace } from '@cegal/ds-components'
import { Delete } from '@cegal/ds-icons/dist/Delete'
import { listCertificates, listDepartments, listRoles, listSkills } from 'actions'
import { ConsultantsQuery, Department, Location, Role, Skill } from 'declarations/models'
import { useAppDispatch, useAppSelector } from 'store'

import { Hr, MarginChip, MarginChipMarquee } from 'components/styled'

import { useEffect } from 'react'
import Marquee from 'react-fast-marquee'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'

export interface ConsultantsDescriptionProps {
  total: number
  query: Partial<ConsultantsQuery>
  itemsPerPage: number
  setQuery?: (query: Partial<ConsultantsQuery>) => void
}

const ConsultantsDescription = ({ total, query, setQuery, itemsPerPage }: ConsultantsDescriptionProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const apiReady = useAppSelector((state) => state.app.apiReady)
  const skillsLoading = useAppSelector((state) => state.skills.listing)
  const skillsList = useAppSelector((state) => state.skills.list)
  const departmentsList = useAppSelector((state) => state.departments.list)
  const departmentsLoading = useAppSelector((state) => state.departments.listing)
  const locationsList = useAppSelector((state) => state.locations.list)
  const locationsLoading = useAppSelector((state) => state.locations.listing)
  const rolesList = useAppSelector((state) => state.roles.list)
  const rolesLoading = useAppSelector((state) => state.roles.listing)
  const certificatesList = useAppSelector((state) => state.certificates.list)
  const certificatesLoading = useAppSelector((state) => state.certificates.listing)

  useEffect(() => {
    if (apiReady && rolesList === undefined && !rolesLoading) {
      dispatch(listRoles())
    }
  }, [apiReady, rolesList, rolesLoading])

  useEffect(() => {
    if (apiReady && skillsList === undefined && !skillsLoading) {
      dispatch(listSkills())
    }
  }, [apiReady, skillsList, skillsLoading])

  useEffect(() => {
    if (apiReady && departmentsList === undefined && !departmentsLoading) {
      dispatch(listDepartments())
    }
  }, [apiReady, departmentsList, departmentsLoading])

  useEffect(() => {
    if (apiReady && certificatesList === undefined && !certificatesLoading) {
      dispatch(listCertificates())
    }
  }, [apiReady, certificatesList, certificatesLoading])

  const {
    search,
    followed,
    allocation,
    allocationFrom,
    allocationTo,
    roles,
    skills,
    allSkills,
    allRoles,
    page,
    departments,
    locations,
    certificates,
    allCertificates
  } = query

  const resetAllocation = () => {
    setQuery?.({
      allocation: [] as Array<number>,
      allocationFrom: '',
      allocationTo: ''
    })
  }

  const initialValue = page ? (+page - 1) * itemsPerPage + 1 : 1
  let finalValue = initialValue + itemsPerPage - 1
  if (finalValue > total) {
    finalValue = total
  }

  const sentence: Array<React.ReactNode> = []

  if (total > 0) {
    sentence.push(
      t('messages:description-found-x', {
        number: total,
        item:
          total === 1 ? t('label:consultant-title').toLowerCase() : t('label:consultants-title').toLowerCase()
      })
    )
    if (total > itemsPerPage) {
      sentence.push('. ')
      sentence.push(
        t('messages:description-showing-x', {
          range: '' + initialValue + '-' + finalValue,
          item: t('label:consultants-title').toLowerCase()
        })
      )
    }
  } else {
    sentence.push(t('messages:description-no-x-found', { x: t('label:consultants-title').toLowerCase() }))
  }

  if (!_.isEmpty(followed)) {
    sentence.push(', ')
    sentence.push(t('messages:description-i-follow'))
  }

  if (!_.isEmpty(search)) {
    sentence.push(', ')
    sentence.push(t('messages:description-matching-x', { text: search }))
  }

  if (!_.isEmpty(allocation) || !_.isEmpty(allocationFrom) || !_.isEmpty(allocationTo)) {
    sentence.push(', ')
    sentence.push(
      <MarginChip
        size='small'
        rightElement={<Chip.Button icon={<Delete size='1rem' />} onClick={resetAllocation} />}
      >
        {t('messages:description-allocated-at-x-y', {
          x: (allocation?.[0] ?? 0) + ' - ' + (allocation?.[1] ?? 100) + '%',
          y: allocationFrom + ' - ' + allocationTo
        })}
      </MarginChip>
    )
  }
  if (!_.isEmpty(departments) || _.isNumber(departments)) {
    sentence.push(', ')
    const _departments = (!Array.isArray(departments) ? [departments] : departments) as Array<number>
    sentence.push(
      t('messages:description-in-item', {
        item:
          _departments.length === 1
            ? t('label:department-title').toLowerCase()
            : t('label:departments-title').toLowerCase()
      })
    )
    // this does a "flat push" of an array into current sentences array
    sentence.push.apply(
      sentence,
      departmentsList
        ?.filter((d: Department) => _departments.includes(d.id))
        .map((d: Department) => (
          <MarginChip
            key={'department-' + d.id}
            size='small'
            selected
            rightElement={
              <Chip.Button
                icon={<Delete size='1rem' />}
                onClick={() =>
                  setQuery?.({
                    departments: _departments.filter((_d) => _d !== d.id)
                  })
                }
              />
            }
          >
            {d.name}
          </MarginChip>
        )) ?? []
    )
  }

  if (!_.isEmpty(locations) || _.isNumber(locations)) {
    sentence.push(', ')
    const _locations = (!Array.isArray(locations) ? [locations] : locations) as Array<number>
    sentence.push(
      t('messages:description-in-item', {
        item:
          _locations.length === 1
            ? t('label:location-title').toLowerCase()
            : t('label:locations-title').toLowerCase()
      })
    )
    // this does a "flat push" of an array into current sentences array
    sentence.push.apply(
      sentence,
      locationsList
        ?.filter((l: Location) => _locations.includes(l.id))
        .map((l: Location) => (
          <MarginChip
            key={'location-' + l.id}
            size='small'
            selected
            rightElement={
              <Chip.Button
                icon={<Delete size='1rem' />}
                onClick={() =>
                  setQuery?.({
                    locations: _locations.filter((_l) => _l !== l.id)
                  })
                }
              />
            }
          >
            {l.name}
          </MarginChip>
        )) ?? []
    )
  }

  if (!_.isEmpty(roles) || _.isNumber(roles)) {
    sentence.push(', ')
    const _roles = (!Array.isArray(roles) ? [roles] : roles) as Array<number>
    if (_roles.length > 1) {
      sentence.push(
        allRoles
          ? ' ' + t('messages:description-operator-in-all')
          : ' ' + t('messages:description-operator-in-some')
      )
    } else {
      sentence.push(' ' + t('messages:description-operator-in-none'))
    }

    sentence.push(
      ' ' + (_roles.length === 1 ? t('label:role-title').toLowerCase() : t('label:roles-title').toLowerCase())
    )

    // this does a "flat push" of an array into current sentences array
    sentence.push.apply(
      sentence,
      rolesList
        ?.filter((r: Role) => _roles.includes(r.id))
        .map((r: Role) => (
          <MarginChip
            key={'role-' + r.id}
            size='small'
            selected
            rightElement={
              <Chip.Button
                icon={<Delete size='1rem' />}
                onClick={() =>
                  setQuery?.({
                    roles: _roles.filter((_r) => _r !== r.id)
                  })
                }
              />
            }
          >
            {r.name}
          </MarginChip>
        )) ?? []
    )
  }

  if (!_.isEmpty(skills) || _.isNumber(skills)) {
    sentence.push(', ')
    const _skills = (!Array.isArray(skills) ? [skills] : skills) as Array<number>

    if (_skills.length > 1) {
      sentence.push(
        allSkills
          ? ' ' + t('messages:description-operator-all')
          : ' ' + t('messages:description-operator-some')
      )
    } else {
      sentence.push(' ' + t('messages:description-operator-none'))
    }
    sentence.push(
      ' ' +
        (_skills.length === 1 ? t('label:skill-title').toLowerCase() : t('label:skills-title').toLowerCase())
    )
    // this does a "flat push" of an array into current sentences array
    sentence.push.apply(
      sentence,
      skillsList
        ?.filter((s: Skill) => _skills.includes(s.id))
        .map((s: Skill) => (
          <MarginChip
            key={'skill-' + s.id}
            size='small'
            selected
            rightElement={
              <Chip.Button
                icon={<Delete size='1rem' />}
                onClick={() =>
                  setQuery?.({
                    skills: _skills.filter((_s) => _s !== s.id)
                  })
                }
              />
            }
          >
            {s.name}
          </MarginChip>
        )) ?? []
    )
  }

  if (!_.isEmpty(certificates) || _.isNumber(certificates)) {
    sentence.push(', ')
    const _certificates = (!Array.isArray(certificates) ? [certificates] : certificates) as Array<number>

    if (_certificates.length > 1) {
      sentence.push(
        allCertificates
          ? ' ' + t('messages:description-operator-all')
          : ' ' + t('messages:description-operator-some')
      )
    } else {
      sentence.push(' ' + t('messages:description-operator-none'))
    }

    sentence.push(
      ' ' +
        (_certificates.length === 1
          ? t('label:certificate').toLowerCase()
          : t('label:certificates').toLowerCase())
    )
    // this does a "flat push" of an array into current sentences array
    sentence.push.apply(
      sentence,
      certificatesList
        ?.filter((c: any) => _certificates.includes(c.id))
        .map((c: any) => (
          <MarginChipMarquee
            key={'certificate-' + c.id}
            size='small'
            selected
            rightElement={
              <Chip.Button
                icon={<Delete size='1rem' />}
                onClick={() =>
                  setQuery?.({
                    certificates: _certificates.filter((_c) => _c !== c.id)
                  })
                }
              />
            }
          >
            {c.name.length > 40 ? <Marquee>{c.name}</Marquee> : <>{c.name}</>}
          </MarginChipMarquee>
        )) ?? []
    )
  }

  return (
    <>
      <BodyShort data-cy='consultantResultsText' spacing>
        {skillsLoading || departmentsLoading || locationsLoading || rolesLoading || certificatesLoading ? (
          <Loader size='small' />
        ) : (
          sentence
        )}
      </BodyShort>
      <Hr style={{ width: '70%', marginLeft: '0' }} />
      <VerticalSpace />
    </>
  )
}

export default ConsultantsDescription
