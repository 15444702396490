import { Panel, Table, Tag } from '@cegal/ds-components'
import { Consultant } from 'declarations/models'
import { useAppSelector } from 'store'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export interface ConsultantCertificatesProps {
  consultant?: Consultant
}

interface CertificatesTableHeader {
  label: string
  sortable?: boolean
  sortKey?: string
  key: string
}

const ConsultantCertificates = ({ consultant }: ConsultantCertificatesProps) => {
  const [sort, setSort] = useState<any>()
  const { t } = useTranslation()

  const certificates = useAppSelector((state) => state.consultants.get?.certificates)

  const headers: Array<CertificatesTableHeader> = [
    { label: t('label:name'), key: 'name', sortKey: 'name' },
    { label: t('label:certificate'), key: 'certificate', sortKey: 'certificate' },
    { label: t('label:category'), key: 'category', sortKey: 'category' },
    { label: t('label:cloud'), key: 'cloud', sortKey: 'cloud' },
    { label: t('label:certificate-expired'), key: 'expired', sortKey: 'expired' }
  ]

  const handleSort = (sortKey: string | undefined) => {
    setSort(
      sort && sortKey === sort.orderBy && sort.direction === 'descending'
        ? undefined
        : {
            orderBy: sortKey,
            direction:
              sort && sortKey === sort.orderBy && sort.direction === 'ascending' ? 'descending' : 'ascending'
          }
    )
  }

  const sortData = certificates?.slice().sort((a, b) => {
    if (sort) {
      const comparator = (a: any, b: any, orderBy: any) => {
        if (b[orderBy] < a[orderBy] || b[orderBy] === undefined) {
          return -1
        }
        if (b[orderBy] > a[orderBy]) {
          return 1
        }
        return 0
      }

      return sort.direction === 'ascending' ? comparator(b, a, sort.orderBy) : comparator(a, b, sort.orderBy)
    }
    return 1
  })

  return (
    <Panel
      data-cy='consultantCertificate'
      data-testid='ConsultantCertificate'
      border
      style={{ overflowX: 'auto' }}
    >
      <Table zebraStripes sort={sort} onSortChange={handleSort}>
        <Table.Header>
          <Table.Row>
            {headers.map((header) => (
              <Table.ColumnHeader
                key={header.key + '-certificates-table-header'}
                sortKey={header.sortKey}
                sortable={!!header.sortKey}
              >
                {t(header.label)}
              </Table.ColumnHeader>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sortData?.map((certificate) => (
            <Table.Row key={certificate.id}>
              <Table.DataCell scope='row'>{certificate.name}</Table.DataCell>
              <Table.DataCell scope='row'>{certificate.certificate}</Table.DataCell>
              <Table.DataCell scope='row'>{certificate.category}</Table.DataCell>
              <Table.DataCell scope='row'>
                <Tag variant={certificate.cloud ? 'success' : 'error'}>
                  {certificate.cloud ? t('label:yes') : t('label:no')}
                </Tag>
              </Table.DataCell>
              <Table.DataCell scope='row'>
                <Tag variant={certificate.expired ? 'success' : 'error'}>
                  {certificate.expired ? t('label:yes') : t('label:no')}
                </Tag>
              </Table.DataCell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Panel>
  )
}

export default ConsultantCertificates
