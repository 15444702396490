// AiChatBubbles.js
import { BodyShort, Loader, PileEndDiv, VerticalSpace } from '@cegal/ds-components/dist'
import catHead from 'assets/images/catHeadHeadphones.png'
import styled from 'styled-components'

import React from 'react'

import { t } from 'i18next'

import type { Message } from '../../../hooks/useAiMessages'
import AiAttachment from './AiAttachment'

type AiChatBubblesProps = {
  messages: Message[]
  isLoading?: boolean
  searchValue: string
}

const ResponseContainer = styled.div`
  max-width: 70%;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  gap: 1rem;
`

const LogoImg = styled.img`
  height: 2rem;
  margin-top: 0.4rem;
`

const ResponseMessage = styled(BodyShort)`
  background-color: var(--cat-chat-response-background);
  padding: 0.4rem 0.8rem 0.6rem 0.8rem;
  border-radius: 0.2rem 0.8rem 0.8rem 0.8rem;
`

const SentContainer = styled.div`
  max-width: 70%;
  width: max-content;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  gap: 1rem;
`

const SentMessage = styled(BodyShort)`
  background-color: var(--cat-chat-sent-background);
  color: var(--cat-chat-sent-color-text);
  padding: 0.4rem 0.8rem 0.6rem 0.8rem;
  border-radius: 1rem 1rem 0.2rem 1rem;
`

const Timestamp = styled(PileEndDiv)`
  padding-top: 0.2rem;
  color: var(--cds-text-color-muted);
`

const AttachmentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin-left: auto;
  justify-content: end;
  gap: 0.2rem;
  margin-top: 0.2rem;
`

const AiChatBubbles = ({ messages, isLoading, searchValue }: AiChatBubblesProps) => {
  const today = new Date().setHours(0, 0, 0, 0)
  const msInDay = 24 * 60 * 60 * 1000

  const formatTimestamp = (date: Date): string => {
    const timestamp = new Date(date)
    const sentDate = new Date(timestamp)

    sentDate.setHours(0, 0, 0, 0)

    const daysAgo = (+today - +sentDate) / msInDay

    if (daysAgo === 0) {
      return timestamp.toLocaleTimeString().slice(0, -3)
    } else if (daysAgo === 1) {
      return `${t('messages:yesterday')}, ${timestamp.toLocaleTimeString().slice(0, -3)}`
    } else {
      return timestamp.toLocaleString().slice(0, -3)
    }
  }

  return (
    <>
      {messages?.map((message, index) => {
        if (message.isUser) {
          return (
            <div key={index}>
              <SentContainer>
                <SentMessage>{getHighlightedText(message.message, searchValue)}</SentMessage>
              </SentContainer>
              <AttachmentContainer>
                {message.attachments?.map((attachment) => (
                  <AiAttachment key={attachment.title} item={attachment} />
                ))}
              </AttachmentContainer>
              <Timestamp>{formatTimestamp(message.timestamp)}</Timestamp>
              <VerticalSpace />
            </div>
          )
        } else {
          return (
            <div key={index}>
              <ResponseContainer>
                <LogoImg src={catHead} alt='catHead' />
                <ResponseMessage>
                  {isLoading ? message.message : getHighlightedText(message.message, searchValue)}
                </ResponseMessage>
              </ResponseContainer>
              <VerticalSpace />
            </div>
          )
        }
      })}
      {isLoading && (
        <div>
          <ResponseContainer>
            <LogoImg src={catHead} alt='catHead' />
            <Loader size='small' />
          </ResponseContainer>
          <VerticalSpace size='2' />
        </div>
      )}
    </>
  )
}

function getHighlightedText(text: string, highlight: string) {
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'))
  return parts.map((part, index) => (
    <React.Fragment key={index}>
      {part.toLowerCase() === highlight.toLowerCase() ? (
        <span style={{ color: 'black', backgroundColor: 'rgb(255, 255, 0)' }}>{part}</span>
      ) : (
        part
      )}
    </React.Fragment>
  ))
}

export default AiChatBubbles
