export interface AiAction {
  context: RegExp
  text: string
}

export const anyRegex = /./
export const actions: AiAction[] = [
  {
    context: /\/engagements\/[0-9]+\/find/i,
    text: 'Add vacancy'
  },
  {
    context: /\/consultants\/[0-9]+\/find/i,
    text: 'Filter by consultant skills'
  },
  {
    context: /\/engagements\/[0-9]/i,
    text: 'Summarize engagement'
  },
  {
    context: /\/consultants\/[0-9]/i,
    text: 'Summarize consultant'
  },
  {
    context: anyRegex,
    text: 'Create engagement'
  },
  {
    context: anyRegex,
    text: 'Find consultant by skills'
  }
]
