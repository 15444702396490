import { Client } from 'declarations/models'

const { v4: uuidv4 } = require('uuid')

export default {
  id: 1,
  name: 'Norsk Hydro asa',
  image_href: 'https://picsum.photos/100',
  description:
    'Norsk Hydro is a Norwegian aluminium company. It is the world’s second largest aluminium company and the largest in Europe.',
  location: 'Norway, Oslo',
  engagements: [
    {
      id: 1,
      title: 'Create a new website',
      description: 'We need to form a team to create a new website for this client',
      posted_date: '2021-11-10',
      start_date: '2021-12-12',
      end_date: '2022-02-01',
      deadline: '2023-08-08',
      last_modified_date: '2022-09-09',
      optional_extension_period: 5,
      location: 'Oslo',
      status: 'started',
      file: 'https://picsum.photos/seed/picsum/200/300',
      notes: 'Some notes related to the Norsk Hydro asa',
      energy_domain: true,
      integration: true,
      relevant_technologies: true,
      mentoring: true,
      reusable_database: false,
      financially_smart: false,
      creator: {
        id: 1,
        name: 'Faisal',
        email: 'jaf@jaf.com',
        image_href: 'https://picsum.photos/seed/picsum/200/300'
      },
      clients: [
        {
          id: 2,
          name: 'ABC'
        }
      ],
      source: {
        id: 2,
        name: 'source'
      },
      last_modifier_id: {
        id: 1,
        name: 'Lisa',
        email: 'lisa@lisa.com',
        image_href: 'https://picsum.photos/seed/picsum/200/300'
      },
      vacancies: [
        {
          id: 1,
          hourly_rate: 100,
          allocation: 'full-time',
          title: 'Frontend',
          skills: [
            {
              id: 1,
              name: 'JavaScript'
            },
            {
              id: 2,
              name: 'Vue 2'
            }
          ],
          consultants: [
            {
              consultant: {
                id: uuidv4(),
                name: 'Marianne Hjørnevik Andersen',
                email: 'marianne.hjornevik.andersen@cegal.com',
                image_href: 'https://picsum.photos/id/237/200/300'
              },
              last_modifier_id: 1,
              hourly_rate: 5,
              status: 'done',
              start_date: '2022-11-12',
              end_date: '2022-11-12',
              notes: 'Test2'
            },
            {
              consultant: {
                id: uuidv4(),
                name: 'Thomas Løkling Pedersen',
                email: 'thomas.lokling.pedersen@cegal.com',
                image_href: 'https://picsum.photos/id/237/200/300'
              },
              last_modifier_id: 1,
              last_modified_date: '2022-11-12',
              hourly_rate: 5,
              status: 'done',
              start_date: '2022-11-12',
              end_date: '2022-11-12',
              notes: 'Test2'
            },
            {
              consultant: {
                id: uuidv4(),
                name: 'Rune Egeland',
                email: 'rune.egeland@cegal.com',
                image_href: 'https://picsum.photos/id/237/200/300'
              },
              last_modifier_id: 1,
              last_modified_date: '2022-11-12',
              hourly_rate: 5,
              status: 'done',
              start_date: '2022-11-12',
              end_date: '2022-11-12',
              notes: 'Test2'
            },
            {
              consultant: {
                id: uuidv4(),
                name: 'Lars Nypan',
                email: 'lars.nypan@cegal.com',
                image_href: 'https://picsum.photos/id/237/200/300'
              },
              last_modifier_id: 1,
              last_modified_date: '2022-11-12',
              hourly_rate: 5,
              status: 'done',
              start_date: '2022-11-12',
              end_date: '2022-11-12',
              notes: 'Test2'
            },
            {
              consultant: {
                id: uuidv4(),
                name: 'Mock Consultant',
                email: 'mock.consultant@cegal.com',
                image_href: 'https://picsum.photos/id/237/200/300'
              },
              last_modifier_id: 1,
              last_modified_date: '2022-11-12',
              hourly_rate: 5,
              status: 'done',
              start_date: '2022-11-12',
              end_date: '2022-11-12',
              notes: 'Test2'
            }
          ]
        },
        {
          id: 2,
          hourly_rate: 100,
          allocation: 'full-time',
          title: 'Backend',
          skills: [
            {
              id: 1,
              name: 'SQL'
            },
            {
              id: 2,
              name: 'GO'
            }
          ],
          consultants: [
            {
              consultant: {
                id: uuidv4(),
                name: 'Faisal Ahmed',
                email: 'faisal@faisal.com',
                image_href: 'https://picsum.photos/id/237/200/300'
              },
              last_modifier_id: 1,
              last_modified_date: '2022-11-12',
              hourly_rate: 5,
              status: 'inProgress',
              start_date: '2022-11-12',
              end_date: '2029-11-12',
              notes: 'Test2'
            }
          ]
        },
        {
          id: 3,
          hourly_rate: 200,
          allocation: 'full-time',
          title: 'UX/UI Design',
          skills: [
            {
              id: 1,
              name: 'Figma'
            },
            {
              id: 2,
              name: 'Sketch'
            }
          ],
          consultants: [
            {
              consultant: {
                id: uuidv4(),
                name: 'Faisal Ahmed 2',
                email: 'faisal@faisal.com',
                image_href: 'https://picsum.photos/id/237/200/300'
              },
              last_modifier_id: 1,
              last_modified_date: '2022-11-12',
              hourly_rate: 5,
              status: 'inProgress',
              start_date: '2022-11-12',
              end_date: '2029-11-12',
              notes: 'Test2'
            }
          ]
        },
        {
          id: 4,
          hourly_rate: 200,
          allocation: 'full-time',
          title: 'Project Manager',
          skills: [
            {
              id: 1,
              name: 'Policy knowledge'
            },
            {
              id: 2,
              name: 'Conflict management'
            }
          ],
          consultants: [
            {
              consultant: {
                id: uuidv4(),
                name: 'Faisal Ahmed 3',
                email: 'faisal@faisal.com',
                image_href: 'https://picsum.photos/id/237/200/300'
              },
              last_modifier_id: 1,
              last_modified_date: '2022-11-12',
              hourly_rate: 5,
              status: 'inProgress',
              start_date: '2022-11-12',
              end_date: '2029-11-12',
              notes: 'Test2'
            }
          ]
        }
      ]
    },
    {
      id: 2,
      title: 'Install a new server room with all the equipment',
      description:
        'We need to form a team to install a new server room with all the equipment includee cables, switches, routers, servers, etc.',
      posted_date: '2021-11-10',
      start_date: '2021-12-12',
      end_date: '2022-02-01',
      deadline: '2023-08-08',
      last_modified_date: '2022-09-09',
      optional_extension_period: 5,
      location: 'Oslo',
      status: 'started',
      file: 'https://picsum.photos/seed/picsum/200/300',
      notes: 'Some notes related to the Norsk Hydro asa',
      energy_domain: true,
      integration: true,
      relevant_technologies: true,
      mentoring: true,
      reusable_database: false,
      financially_smart: false,
      creator: {
        id: 1,
        name: 'Faisal',
        email: 'jaf@jaf.com',
        image_href: 'https://picsum.photos/seed/picsum/200/300'
      },
      clients: [
        {
          id: 2,
          name: 'ABC'
        }
      ],
      source: {
        id: 2,
        name: 'source'
      },
      last_modifier: {
        id: 1,
        name: 'Lisa',
        email: 'lisa@lisa.com',
        image_href: 'https://picsum.photos/seed/picsum/200/300'
      },
      vacancies: [
        {
          id: 1,
          hourly_rate: 100,
          allocation: 'full-time',
          title: 'Network Technician',
          skills: [
            {
              id: 1,
              name: 'TCP/IP protocols'
            },
            {
              id: 2,
              name: 'LAN/WAN network'
            }
          ],
          consultants: [
            {
              consultant: {
                id: uuidv4(),
                name: 'Lars Nypan',
                email: 'lars.nypan@cegal.com',
                image_href: 'https://picsum.photos/id/237/200/300'
              },
              last_modifier_id: 1,
              hourly_rate: 5,
              status: 'inProgress',
              start_date: '2022-11-12',
              end_date: '2022-11-12',
              notes: 'Test2'
            },
            {
              consultant: {
                id: uuidv4(),
                name: 'Mock Consultant',
                email: 'mock.consultant@cegal.com',
                image_href: 'https://picsum.photos/id/237/200/300'
              },
              last_modified_date: '2022-11-12',
              hourly_rate: 5,
              status: 'done',
              start_date: '2022-11-12',
              end_date: '2022-11-12',
              notes: 'Test2'
            }
          ]
        },
        {
          id: 2,
          hourly_rate: 100,
          allocation: 'full-time',
          title: 'Frontend',
          skills: [
            {
              id: 1,
              name: 'React'
            },
            {
              id: 2,
              name: 'Vue'
            },
            {
              id: 4,
              name: 'Angular'
            }
          ],
          consultants: [
            {
              consultant: {
                id: uuidv4(),
                name: 'Faisal Ahmed',
                email: 'faisal@faisal.com',
                image_href: 'https://picsum.photos/id/237/200/300'
              },
              last_modifier_id: 1,
              last_modified_date: '2022-11-12',
              hourly_rate: 5,
              status: 'inProgress',
              start_date: '2022-11-12',
              end_date: '2029-11-12',
              notes: 'Test2'
            }
          ]
        },
        {
          id: 3,
          hourly_rate: 200,
          allocation: 'full-time',
          title: 'Backend',
          skills: [
            {
              id: 1,
              name: 'SQL'
            },
            {
              id: 2,
              name: '.NET'
            }
          ],
          consultants: [
            {
              consultant: {
                id: uuidv4(),
                name: 'Faisal Ahmed',
                email: 'faisal@faisal.com',
                image_href: 'https://picsum.photos/id/237/200/300'
              },
              last_modifier_id: 1,
              last_modified_date: '2022-11-12',
              hourly_rate: 5,
              status: 'inProgress',
              start_date: '2022-11-12',
              end_date: '2029-11-12',
              notes: 'Test2'
            }
          ]
        },
        {
          id: 4,
          hourly_rate: 200,
          allocation: 'full-time',
          title: 'Project Manager',
          skills: [
            {
              id: 1,
              name: 'Policy knowledge'
            },
            {
              id: 2,
              name: 'Conflict management'
            }
          ],
          consultants: [
            {
              consultant: {
                id: uuidv4(),
                name: 'Faisal Ahmed',
                email: 'faisal@faisal.com',
                image_href: 'https://picsum.photos/id/237/200/300'
              },
              last_modifier_id: 1,
              last_modified_date: '2022-11-12',
              hourly_rate: 5,
              status: 'inProgress',
              start_date: '2022-11-12',
              end_date: '2029-11-12',
              notes: 'Test2'
            }
          ]
        }
      ]
    },
    {
      id: 3,
      title: 'Install a new physical firewall server',
      description:
        'We need to install a new firewall server in our datacenter. The server is already ordered and will be delivered next week. We need to install the server and configure it to work with our existing network.',
      posted_date: '2021-11-10',
      start_date: '2021-12-12',
      end_date: '2022-02-01',
      deadline: '2023-08-08',
      last_modified_date: '2022-09-09',
      optional_extension_period: 5,
      location: 'Oslo',
      status: 'finished',
      file: 'https://picsum.photos/seed/picsum/200/300',
      notes: 'Some notes related to the Norsk Hydro asa',
      energy_domain: true,
      integration: true,
      relevant_technologies: true,
      mentoring: true,
      reusable_database: false,
      financially_smart: false,
      creator: {
        id: 1,
        name: 'Faisal',
        email: 'jaf@jaf.com',
        image_href: 'https://picsum.photos/seed/picsum/200/300'
      },
      clients: [
        {
          id: 2,
          name: 'ABC'
        }
      ],
      source: {
        id: 2,
        name: 'source'
      },
      last_modifier: {
        id: 1,
        name: 'Lisa',
        email: 'lisa@lisa.com',
        image_href: 'https://picsum.photos/seed/picsum/200/300'
      },
      vacancies: [
        {
          id: 1,
          hourly_rate: 100,
          allocation: 'full-time',
          title: 'Network Technician',
          skills: [
            {
              id: 1,
              name: 'TCP/IP protocols'
            },
            {
              id: 2,
              name: 'LAN/WAN network'
            }
          ],
          consultants: [
            {
              consultant: {
                id: uuidv4(),
                name: 'Rune Egeland',
                email: 'rune.egeland@cegal.com',
                image_href: 'https://picsum.photos/id/237/200/300'
              },
              last_modifier_id: 1,
              last_modified_date: '2022-11-12',
              hourly_rate: 5,
              status: 'inProgress',
              start_date: '2022-11-12',
              end_date: '2022-11-12',
              notes: 'Test2'
            },
            {
              consultant: {
                id: uuidv4(),
                name: 'Lars Nypan',
                email: 'lars.nypan@cegal.com',
                image_href: 'https://picsum.photos/id/237/200/300'
              },
              last_modifier_id: 1,
              last_modified_date: '2022-11-12',
              hourly_rate: 5,
              status: 'done',
              start_date: '2022-11-12',
              end_date: '2022-11-12',
              notes: 'Test2'
            },
            {
              consultant: {
                id: uuidv4(),
                name: 'Mock Consultant',
                email: 'mock.consultant@cegal.com',
                image_href: 'https://picsum.photos/id/237/200/300'
              },
              last_modifier_id: 1,
              last_modified_date: '2022-11-12',
              hourly_rate: 5,
              status: 'done',
              start_date: '2022-11-12',
              end_date: '2022-11-12',
              notes: 'Test2'
            }
          ]
        },
        {
          id: 2,
          hourly_rate: 100,
          allocation: 'full-time',
          title: 'Frontend',
          skills: [
            {
              id: 1,
              name: 'React'
            },
            {
              id: 2,
              name: 'Vue'
            },
            {
              id: 4,
              name: 'Angular'
            }
          ],
          consultants: [
            {
              consultant: {
                id: uuidv4(),
                name: 'Faisal Ahmed',
                email: 'faisal@faisal.com',
                image_href: 'https://picsum.photos/id/237/200/300'
              },
              last_modifier_id: 1,
              last_modified_date: '2022-11-12',
              hourly_rate: 5,
              status: 'inProgress',
              start_date: '2022-11-12',
              end_date: '2029-11-12',
              notes: 'Test2'
            }
          ]
        },
        {
          id: 3,
          hourly_rate: 200,
          allocation: 'full-time',
          title: 'Backend',
          skills: [
            {
              id: 1,
              name: 'SQL'
            },
            {
              id: 2,
              name: '.NET'
            }
          ],
          consultants: [
            {
              consultant: {
                id: uuidv4(),
                name: 'Faisal Ahmed',
                email: 'faisal@faisal.com',
                image_href: 'https://picsum.photos/id/237/200/300'
              },
              last_modifier_id: 1,
              last_modified_date: '2022-11-12',
              hourly_rate: 5,
              status: 'inProgress',
              start_date: '2022-11-12',
              end_date: '2029-11-12',
              notes: 'Test2'
            }
          ]
        },
        {
          id: 4,
          hourly_rate: 200,
          allocation: 'full-time',
          title: 'Project Manager',
          skills: [
            {
              id: 1,
              name: 'Policy knowledge'
            },
            {
              id: 2,
              name: 'Conflict management'
            }
          ],
          consultants: [
            {
              consultant: {
                id: uuidv4(),
                name: 'Viktor Lander',
                email: 'viktor.lander@cegal.com',
                image_href: 'https://picsum.photos/id/237/200/300'
              },
              last_modifier_id: 1,
              last_modified_date: '2022-11-12',
              hourly_rate: 5,
              status: 'inProgress',
              start_date: '2022-11-12',
              end_date: '2022-11-12',
              notes: 'Test2'
            }
          ]
        }
      ]
    }
  ]
} as Client
