import { Box, Button, FlexDiv, HorizontalSpace } from '@cegal/ds-components'
import { CloseFullscreen } from '@cegal/ds-icons/dist/CloseFullscreen'
import { OpenInFull } from '@cegal/ds-icons/dist/OpenInFull'
import clsx from 'clsx'
import { standardLogger } from 'metrics/loggers'
import styled from 'styled-components'

import { PropsWithChildren, useEffect, useRef, useState } from 'react'
import AnimateHeight from 'react-animate-height'

export interface WidgetProps extends PropsWithChildren {
  header: React.ReactNode
  buttons?: Array<React.ReactNode>
  minWidth?: number
  spacing?: boolean
}

export const WidgetBox = styled(Box)<{
  spacing?: boolean
  display?: string
  flexDirection?: string
}>`
  margin-bottom: ${({ spacing }) => (spacing ? '32px' : '0px')};
  display: ${({ display }) => display || undefined};
  flex-direction: ${({ flexDirection }) => flexDirection || undefined};
  .overflow {
    overflow-y: scroll !important;
  }
`

export const WidgetBoxHeader = styled(Box.Header)`
  padding: 0.5rem 1rem;
  background-color: var(--cat-homepanel-background-color);
`

export const WidgetBoxBody = styled(Box.Body)`
  padding: 1rem 1rem 0 1rem;
  overflow-y: auto;
`

const Widget = ({ header, buttons = [], children, minWidth = 350, spacing = true }: WidgetProps) => {
  const ref: any = useRef(null)
  const [requestFull, setRequestFull] = useState<boolean>(false)
  const [isFull, setIsFull] = useState<boolean>(false)
  const [contentOverflowing, setContentOverflowing] = useState<boolean>(false)
  const duration: number = 250
  const collapsedWidth: number = minWidth

  useEffect(() => {
    if (ref.current) {
      const resizeObserver = new ResizeObserver(() =>
        setContentOverflowing(ref.current?.scrollHeight > collapsedWidth)
      )
      resizeObserver.observe(ref.current)
      return () => resizeObserver.disconnect()
    }
  }, [ref])

  return (
    <WidgetBox border size='small' spacing={spacing}>
      <WidgetBoxHeader>
        <FlexDiv style={{ justifyContent: 'space-between' }}>
          {header}
          <FlexDiv>
            {buttons}
            <HorizontalSpace size='0.5' />
            <Button
              disabled={requestFull ? false : !contentOverflowing}
              size='small'
              variant='secondary'
              icon={requestFull ? <CloseFullscreen size='1rem' /> : <OpenInFull size='1rem' />}
              onClick={() => {
                standardLogger(requestFull ? 'home.widget.expand' : 'home.widget.collapse')
                setRequestFull(!requestFull)
              }}
            />
          </FlexDiv>
        </FlexDiv>
      </WidgetBoxHeader>
      <AnimateHeight
        duration={duration}
        height={requestFull ? 'auto' : collapsedWidth}
        className={clsx({ overflow: contentOverflowing || (!isFull && !requestFull) })}
        onHeightAnimationEnd={() => setIsFull(requestFull)}
      >
        <WidgetBoxBody ref={ref}>{children}</WidgetBoxBody>
      </AnimateHeight>
    </WidgetBox>
  )
}
export default Widget
