var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import cl from 'clsx';
import React, { forwardRef, useContext } from 'react';
import { Label } from 'components/typography';
import { TableContext } from './Table';
export const HeaderCell = forwardRef((_a, ref) => {
    var { className, children, align } = _a, rest = __rest(_a, ["className", "children", "align"]);
    const context = useContext(TableContext);
    return (React.createElement(Label, Object.assign({}, rest, { ref: ref, className: cl('cds-table__header-cell', {
            [`cds-table__header-cell--align-${align}`]: align
        }, className), as: 'th', size: context === null || context === void 0 ? void 0 : context.size }), children));
});
HeaderCell.displayName = 'HeaderCell';
export default HeaderCell;
