import {
  BodyLong,
  BodyShort,
  Column,
  FlexBaseDiv,
  Heading,
  HorizontalSpace,
  Label,
  Panel,
  Row
} from '@cegal/ds-components'
import { getConsultantCV } from 'actions'
import { Consultant } from 'declarations/models'
import { useAppDispatch, useAppSelector } from 'store'

import { FullScreenLoader } from 'components/Loader/CustomLoader'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export interface ConsultantCVProps {
  consultant?: Consultant
}

const ConsultantCV = ({ consultant }: ConsultantCVProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const cv = useAppSelector((state) => state.consultants.cv)
  const cvLoading = useAppSelector((state) => state.consultants.cvLoading)

  useEffect(() => {
    if (consultant?.id) {
      dispatch(getConsultantCV(consultant.id as number))
    }
  }, [consultant])

  if (cvLoading) {
    return <FullScreenLoader />
  }

  return (
    <Panel data-cy='consultantCV' data-testid='ConsultantCV' border>
      <Heading size='medium' spacing>
        {cv?.title.int}
      </Heading>
      {cv?.telefon && (
        <Row>
          <Column>
            <FlexBaseDiv>
              <Label spacing>{t('label:telephone-title')}:</Label>
              <HorizontalSpace />
              <BodyShort spacing>{cv?.telefon}</BodyShort>
            </FlexBaseDiv>
          </Column>
        </Row>
      )}
      {cv?.key_qualifications?.map((qualification) => (
        <Row key={qualification._id}>
          <Column>
            <Label>{qualification.label.int}</Label>
            <BodyLong spacing>{qualification.long_description.int}</BodyLong>
          </Column>
        </Row>
      ))}
      <Heading size='medium' spacing>
        {t('label:courses')}
      </Heading>
      {cv?.courses?.map((course) => {
        return (
          <Row key={course._id}>
            <Column>
              <Label spacing>{course.name.int}</Label>
              <BodyLong spacing>{course.long_description.int}</BodyLong>
            </Column>
          </Row>
        )
      })}
      <Heading size='medium' spacing>
        {t('label:project-experiences')}
      </Heading>
      {cv?.project_experiences?.map((project_experience) => {
        return project_experience?.roles?.map((role) => {
          return (
            <Row key={role._id}>
              <Column>
                <Label spacing>{role.name.int}</Label>
                <Label>{project_experience.customer?.int}</Label>
                <BodyLong spacing>{project_experience.long_description.int}</BodyLong>
              </Column>
            </Row>
          )
        })
      })}
    </Panel>
  )
}

export default ConsultantCV
