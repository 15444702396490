import cl from 'clsx';
import { BodyShort, ErrorMessage, Label } from 'components/typography';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { useFormField } from 'components/form';
import { Button } from 'components/button';
export const Counter = forwardRef((props, ref) => {
    var _a;
    const { inputProps, size = 'medium', inputDescriptionId, errorId, showErrorMsg, hasError } = useFormField(props, 'counter');
    const { className, hideLabel = false, label, description, disabled = false, value, min = 0, max, variant = 'primary', defaultValue, onChange } = props;
    const [internalValue, setInternalValue] = useState((_a = defaultValue !== null && defaultValue !== void 0 ? defaultValue : value) !== null && _a !== void 0 ? _a : min);
    useEffect(() => {
        value !== undefined && setInternalValue(value);
    }, [value]);
    const handleChange = useCallback((v) => {
        setInternalValue(v);
        onChange === null || onChange === void 0 ? void 0 : onChange(v);
    }, [onChange]);
    return (React.createElement("div", { ref: ref, className: cl('cds-form-field', `cds-form-field--${size}`, 'cds-counter', {
            'cds-counter--error': hasError,
            'cds-counter--disabled': !!inputProps.disabled
        }, className) },
        React.createElement(Label, { htmlFor: inputProps.id, size: size, as: 'label', className: cl('cds-form-field__label', {
                'cds-sr-only': hideLabel
            }) }, label),
        !!description && (React.createElement(BodyShort, { as: 'div', className: cl('cds-form-field__description', {
                'cds-sr-only': hideLabel
            }), id: inputDescriptionId, size: size }, description)),
        React.createElement("div", { className: 'cds-counter__wrapper' },
            React.createElement(Button, { className: cl('cds-counter__button', className), onClick: () => handleChange(internalValue - 1), size: 'xsmall', variant: variant, disabled: disabled || min === internalValue }, "-"),
            React.createElement(Label, { className: cl('cds-counter__label', 'cds-body-short', `cds-body-${size}`, className) }, value !== null && value !== void 0 ? value : internalValue),
            React.createElement(Button, { className: cl('cds-counter__button', className), onClick: () => handleChange(internalValue + 1), size: 'xsmall', variant: variant, disabled: disabled || max === internalValue }, "+")),
        React.createElement("div", { className: 'cds-form-field__error', id: errorId, "aria-relevant": 'additions removals', "aria-live": 'polite' }, showErrorMsg && React.createElement(ErrorMessage, { size: size }, props.error))));
});
Counter.displayName = 'Counter';
export default Counter;
