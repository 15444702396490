import {
  FlexEndDiv,
  HorizontalSpace,
  PileCenterDiv,
  SelectMultiple,
  Switch,
  VerticalSpace
} from '@cegal/ds-components'
import { Location, OrgChartNode } from 'declarations/models'
import { useAppSelector } from 'store'

import { FullScreenLoader } from 'components'
import { ConsultantOrgChartNode } from 'components/Consultants/Consultant'
import PageContent from 'components/PageContent/PageContent'

import { useEffect, useMemo, useState } from 'react'
import OrgTree from 'react-org-tree'

import useOrgChart from 'hooks/useOrgChart'

import { Option } from 'utils/fieldUtils'

import './OrgChart.css'

import _ from 'lodash'

const OrgChart = () => {
  const [data, setData] = useState<OrgChartNode | null | undefined>(undefined)
  const { orgChart, loading, getSubTreeIds, getPathFromTopToX } = useOrgChart()
  const [_location, setLocation] = useState<string | undefined>(undefined)
  const [_findMe, setFindMe] = useState<Array<number> | undefined>(undefined)
  const locations = useAppSelector((state) => state.locations.list)
  const userId = useAppSelector((state) => state.app.userId)

  const locationOptions: Array<Option<Location>> = useMemo(() => {
    if (!_.isEmpty(locations)) {
      return (
        locations?.map((l) => ({
          ...l,
          label: l.name,
          value: l.id
        })) ?? []
      )
    }
    return []
  }, [locations])

  const selectedLocation = useMemo(
    () => _.find(locationOptions, { name: _location }) ?? null,
    [_location, locationOptions]
  )

  useEffect(() => setData(orgChart), [orgChart])

  const findMe = (checked: boolean) => {
    if (!checked) {
      setFindMe(undefined)
      return
    }
    const _tempFindMe: Array<number> = []
    const path: Array<number> | undefined = getPathFromTopToX(userId)
    if (!_.isEmpty(path) && !_.isNil(data)) {
      const _data: OrgChartNode = _.cloneDeep(data)
      for (let i = 0; i < path!.length; i++) {
        const clonedPath = path!.slice(0, i)
        const _path = clonedPath?.map((i) => 'children[' + i + ']').join('.')
        if (_.isEmpty(_path)) {
          _data.expand = true
          if (_.isNumber(_data.catId)) {
            _tempFindMe.push(_data.catId)
          }
        }
        const node: OrgChartNode = _.get(_data, _path)
        if (_.isNumber(node?.catId)) {
          _tempFindMe.push(node.catId)
        }
        _.set(_data, _path, {
          ...node,
          expand: true
        })
      }
      _tempFindMe.push(userId)
      setFindMe(_tempFindMe)
      setData(_data)
    }
    setTimeout(() => {
      const node = document.getElementById('node-' + userId)
      if (node) {
        node.scrollIntoView()
      }
    }, 500)
  }

  return (
    <PageContent maxWidth='1600px'>
      <VerticalSpace />
      <PileCenterDiv>
        <FlexEndDiv>
          <SelectMultiple
            multiple={false}
            menuPosition='fixed'
            loading={loading}
            label='Highlight location'
            defaultValue={!_.isEmpty(selectedLocation) ? selectedLocation : null}
            onChange={(location: Option<Location>) => setLocation(location?.name?.toString())}
            options={locationOptions}
            getOptionValue={(location: Location) => location.name}
          />
          <HorizontalSpace />
          <Switch onChange={(e) => findMe(e.target.checked)}>Find me</Switch>
        </FlexEndDiv>
      </PileCenterDiv>
      <VerticalSpace />
      {(data === undefined || loading) && <FullScreenLoader />}
      {!!data && (
        <OrgTree
          key={JSON.stringify(data)}
          data={data}
          horizontal
          collapsable
          expandAll={false}
          labelClassName='cat-org'
          renderContent={(data: OrgChartNode) => (
            <ConsultantOrgChartNode
              id={'node-' + (data?.catId ?? data.id)}
              data={data}
              highlight={data.officeLocation === _location || _findMe?.includes(data?.catId ?? -1)}
            />
          )}
          onClick={(e: any, data: any) => {
            console.log(getSubTreeIds(data.id))
          }}
        />
      )}
    </PageContent>
  )
}

export default OrgChart
