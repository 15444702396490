import {
  Alert,
  Button,
  FlexBaseDiv,
  FlexBaseSpacedDiv,
  FlexCenterDiv,
  FlexStartDiv,
  Heading,
  HorizontalSpace,
  Loader,
  Pagination,
  PileDiv,
  PileEndDiv,
  TextField,
  VerticalSpace
} from '@cegal/ds-components'
import { FilterAlt as FilterIcon } from '@cegal/ds-icons/dist/FilterAlt'
import { clearAlertBodyAll, deleteClient, editClient, newClient, resetClient } from 'actions'
import {
  CLIENTS_DELETE_FAILURE,
  CLIENTS_DELETE_SUCCESS,
  CLIENTS_EDIT_FAILURE,
  CLIENTS_EDIT_SUCCESS,
  CLIENTS_LIST_FAILURE,
  CLIENTS_NEW_FAILURE,
  CLIENTS_NEW_SUCCESS
} from 'constants/actionTypes'
import { AlertElement, DisplaySize, ViewMode } from 'declarations/app'
import { Client, ClientsQuery } from 'declarations/models'
import { standardLogger } from 'metrics/loggers'
import { AlertsState } from 'reducers/alerts'
import { useAppSelector } from 'store'

import { FullScreenLoader } from 'components'
import AlertPanel from 'components/AlertPanel/AlertPanel'
import ClientModal from 'components/Clients/Client/ClientModal'
import ClientsView from 'components/Clients/Client/ClientsView'
import ClientsDescription from 'components/Clients/ClientsDescription'
import ClientsFilter from 'components/Clients/ClientsFilter'
import ToggleView from 'components/Forms/ToggleView'
import PageContent from 'components/PageContent/PageContent'

import { useDeferredValue, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import useClientFilter from 'hooks/useClientFilter'
import useQuery from 'hooks/useQuery'

import { initialClientsQuery } from 'utils/query'
import { getNextSort } from 'utils/sort'

import _ from 'lodash'

const ClientsList = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const viewMode = useAppSelector((state) => state.app.settings.viewMode)
  const itemsPerPage = useAppSelector((state) => state.app.settings.itemsPerPage)
  const displaySize = useAppSelector((state) => state.app.displaySize)
  const alertBody = useAppSelector((state) => (state.alerts as AlertsState).body)

  const [workingCopyClient, setWorkingCopyClient] = useState<Partial<Client> | undefined>(undefined)
  const [isDrawerOpen, setIsDrawerOpen] = useState(true)
  const [_viewMode, setViewMode] = useState<ViewMode>(viewMode)
  const { query, setQuery } = useQuery<ClientsQuery>(initialClientsQuery)

  const [inputValue, setInputValue] = useState(query?.search ?? '')
  const debouncedValue = useDeferredValue(inputValue)

  const initialClient: Partial<Client> = {
    name: '',
    image_href: '',
    location: '',
    description: ''
  }

  const onAddClient = () => {
    dispatch(resetClient())
    setWorkingCopyClient(initialClient)
  }

  const onEditClient = (client: Client) => {
    dispatch(resetClient())
    setWorkingCopyClient(client)
  }

  const onCloseNewClient = () => setWorkingCopyClient(undefined)

  const onNewClient = (client: Client) => {
    standardLogger('clients.new.created')
    dispatch(newClient(client))
  }

  const onSaveClient = (client: Client) => {
    standardLogger('clients.edit.saved')
    dispatch(editClient(client!))
  }

  const onDeleteClient = (client: Client) => {
    standardLogger('clients.deleted')
    dispatch(deleteClient(client!))
  }

  const handlePageChange = (page: number) => {
    window.scrollTo(0, 0)
    standardLogger('clients.list.page', { page })
    setQuery({ page: page === 1 ? undefined : page?.toString() })
  }

  const {
    pages,
    loadPageData,
    items: clients,
    loaded,
    total
  } = useClientFilter(initialClientsQuery, handlePageChange)

  const handleSort = (sortKey?: string | undefined) => setQuery({ sort: getNextSort(query?.sort, sortKey) })

  const toggleDrawer = () => {
    standardLogger('clients.filters.' + (isDrawerOpen ? 'hide' : 'show'))
    setIsDrawerOpen(!isDrawerOpen)
  }

  const setOrientationAndViewMode = (displaySize: DisplaySize) => {
    if (displaySize === 'sm' || displaySize === 'md') {
      setViewMode('card')
    } else {
      setViewMode(viewMode)
    }
  }

  const showAlert: AlertElement | undefined = _.find(
    alertBody,
    (alert) =>
      [
        CLIENTS_NEW_SUCCESS,
        CLIENTS_NEW_FAILURE,
        CLIENTS_DELETE_SUCCESS,
        CLIENTS_DELETE_FAILURE,
        CLIENTS_EDIT_FAILURE,
        CLIENTS_EDIT_SUCCESS
      ].indexOf(alert.type) >= 0
  )

  useEffect(() => setViewMode(viewMode), [viewMode])

  useEffect(() => setOrientationAndViewMode(displaySize), [displaySize])

  useEffect(() => setInputValue(query.search!), [query?.search])

  const updateSearchQuery = (searchPhrase: string) =>
    setQuery((query: Partial<ClientsQuery> | undefined) => ({ ...query, search: searchPhrase }))

  const debouncedChangeHandler = useMemo(() => _.debounce(updateSearchQuery, 1000), [])

  const UpdateSearch = (searchPhrase: string) => {
    setInputValue(searchPhrase)
    debouncedChangeHandler(searchPhrase)
  }

  return (
    <PageContent
      maxWidth={viewMode === 'row' ? '100%' : '1600px'}
      isDrawerOpen={isDrawerOpen}
      drawer={!_.isNil(clients) && <ClientsFilter onClose={toggleDrawer} />}
    >
      <VerticalSpace />
      <ClientModal
        open={workingCopyClient !== undefined}
        client={workingCopyClient}
        onClose={onCloseNewClient}
        onNewClient={onNewClient}
        onSaveClient={onSaveClient}
      />
      <FlexBaseSpacedDiv>
        <FlexBaseDiv>
          <PileDiv>
            <Heading level='2' size='large'>
              {t('header:clients')}
            </Heading>
          </PileDiv>
          <HorizontalSpace />
          <PileDiv>
            <FlexCenterDiv style={{ flexWrap: 'nowrap' }}>
              <Button
                data-cy='clientFilterButton'
                variant='primary'
                icon={<FilterIcon size='1.5rem' />}
                onClick={toggleDrawer}
              >
                <span style={{ whiteSpace: 'nowrap' }}>
                  {t(isDrawerOpen ? 'buttons:close-filter-options' : 'buttons:open-filter-options')}
                </span>
              </Button>
              <HorizontalSpace />
              <TextField
                hideLabel
                description={t('label:search-description')}
                label={t('label:filter')}
                placeholder={t('label:search-title')}
                value={inputValue}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => UpdateSearch(e.target.value)}
              />
              <HorizontalSpace size='0.5' />
              {debouncedValue !== inputValue && <Loader size='small' />}
            </FlexCenterDiv>
            <VerticalSpace />
          </PileDiv>
        </FlexBaseDiv>
        <PileEndDiv flex='1'>
          <FlexStartDiv style={{ flexWrap: 'nowrap', justifyContent: 'flex-end' }}>
            <Button variant='secondary' onClick={onAddClient}>
              <span style={{ whiteSpace: 'nowrap' }}>
                {t('buttons:create-x', {
                  x: t('label:client-title').toLowerCase()
                })}
              </span>
            </Button>
            <HorizontalSpace />
            <ToggleView
              context='clients.list'
              viewItemsPerPage
              viewToggleMode={!(displaySize === 'sm' || displaySize === 'md')}
            />
          </FlexStartDiv>
          <VerticalSpace />
        </PileEndDiv>
      </FlexBaseSpacedDiv>
      <ClientsDescription total={total} query={query} itemsPerPage={itemsPerPage} />
      <VerticalSpace size='2' />
      {showAlert && (
        <>
          <Alert onClose={() => dispatch(clearAlertBodyAll())} variant={showAlert.variant}>
            {showAlert.message}
          </Alert>
          <VerticalSpace />
        </>
      )}
      {!loaded && <FullScreenLoader />}
      {clients === null && (
        <AlertPanel
          doNotRenderIfHeadIsActiveWithError
          watchFor={[CLIENTS_LIST_FAILURE]}
          buttonText={t('buttons:click-to-reload-the-page')!}
          reloadPage={loadPageData}
        />
      )}

      {!_.isNil(clients) && (
        <>
          <ClientsView
            clients={clients}
            query={query}
            onSortChange={handleSort}
            viewMode={_viewMode}
            buttons={['newengagement', 'edit', 'follow']}
            onEditClient={onEditClient}
            onDeleteClient={onDeleteClient}
          />
          <VerticalSpace size='2' />
          {pages > 1 && (
            <>
              <VerticalSpace size='2' />
              <Pagination
                style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
                size={displaySize === 'sm' ? 'small' : 'medium'}
                count={pages}
                page={query?.page ? +query?.page : 1}
                onPageChange={handlePageChange}
              />
            </>
          )}
        </>
      )}
    </PageContent>
  )
}

export default ClientsList
