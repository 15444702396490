import { Column, FlexCenterDiv, Heading, Row, VerticalSpace } from '@cegal/ds-components'
import { listAdminSyncings } from 'actions'
import { CLIENTS_LIST_FAILURE } from 'constants/actionTypes'
import { useAppSelector } from 'store'

import { FullScreenLoader } from 'components'
import AdminSyncingCardView from 'components/Admin/Syncing/SyncingCardView'
import AlertPanel from 'components/AlertPanel/AlertPanel'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import _ from 'lodash'

const AdminSyncing = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const apiReady = useAppSelector((state) => state.app.apiReady)
  const syncings = useAppSelector((state) => state.adminSyncing.list)
  const syncingsLoading = useAppSelector((state) => state.adminSyncing.listing)

  useEffect(() => {
    if (syncings === undefined && apiReady && !syncingsLoading) {
      loadPageData()
    }
  }, [apiReady])

  const loadPageData = () => {
    dispatch(listAdminSyncings())
  }

  return (
    <>
      {syncingsLoading && <FullScreenLoader />}
      {syncings === null && (
        <AlertPanel
          doNotRenderIfHeadIsActiveWithError
          watchFor={[CLIENTS_LIST_FAILURE]}
          buttonText={t('buttons:click-to-reload-the-page')!}
          reloadPage={loadPageData}
        />
      )}

      {!_.isNil(syncings) && (
        <>
          <VerticalSpace size='2' />
          <Row>
            <Column flex='3'>
              <FlexCenterDiv>
                <Heading size='large'>{t('header:syncings')}</Heading>
              </FlexCenterDiv>
              <VerticalSpace />
            </Column>
          </Row>
          <VerticalSpace />

          <VerticalSpace size='2' />
          <AdminSyncingCardView syncings={syncings} />
        </>
      )}
    </>
  )
}

export default AdminSyncing
