import { FlexDiv, Heading, HorizontalSpace, VerticalSpace } from '@cegal/ds-components'
import { Wc } from '@cegal/ds-icons/dist/Wc'
import { deleteFollowedClient, listFollowedClients } from 'actions'
import { FOLLOWED_CLIENTS_LIST_FAILURE } from 'constants/actionTypes'
import { Client } from 'declarations/models'
import { standardLogger } from 'metrics/loggers'
import { useAppDispatch, useAppSelector } from 'store'

import AlertPanel from 'components/AlertPanel/AlertPanel'
import ClientCard from 'components/Clients/Client/ClientCard'
import FollowButton from 'components/Forms/Buttons/FollowButton'
import Widget from 'components/Home/Widget/Widget'
import { StretchLoader } from 'components/Loader/CustomLoader'
import { CardContainer } from 'components/styled'

import { HomePanelProps } from 'pages/Home/Home'

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import _ from 'lodash'

const FollowedClients = ({ mode }: HomePanelProps) => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const apiReady = useAppSelector((state) => state.app.apiReady)
  const followedClients = useAppSelector((state) => state.followedClients.list)
  const followedDeleting = useAppSelector((state) => state.followedClients.deleting)

  const loadPageData = () => {
    if (apiReady) {
      dispatch(listFollowedClients())
    }
  }

  const handleClientUnfollow = (client: Client) => {
    standardLogger('followedclients.card.unfollow')
    dispatch(deleteFollowedClient(client))
  }

  const renderTitle = () => (
    <FlexDiv>
      <Wc size='2rem' />
      <HorizontalSpace />
      <Heading>{t('home:followed-clients')}</Heading>
    </FlexDiv>
  )

  const renderBody = () => (
    <>
      {_.isUndefined(followedClients) && <StretchLoader />}
      {_.isNull(followedClients === null) && (
        <AlertPanel
          doNotRenderIfHeadIsActiveWithError
          watchFor={[FOLLOWED_CLIENTS_LIST_FAILURE]}
          buttonText={t('buttons:click-to-reload-my-follows')!}
          reloadPage={loadPageData}
        />
      )}
      {!_.isNil(followedClients) &&
        (!_.isEmpty(followedClients)
          ? followedClients!.map((followedClient: Client) => {
              const deleting: boolean = _.find(followedDeleting, { id: followedClient.id }) !== undefined
              return (
                <ClientCard
                  spacing
                  fullWidth
                  key={followedClient?.id}
                  item={followedClient}
                  onView={(followedClient) =>
                    navigate(generatePath('/clients/:id', { id: followedClient!.id.toString() }))
                  }
                  buttons={[
                    <FollowButton<Client>
                      key={'followedclient-' + followedClient.id + '-follow-button'}
                      text={false}
                      item={followedClient}
                      followedItems={followedClients}
                      onUnfollow={handleClientUnfollow}
                      loading={deleting}
                      disabled={deleting}
                    />
                  ]}
                  size='small'
                />
              )
            })
          : t('messages:no-followed-clients'))}
    </>
  )
  useEffect(() => {
    if (followedClients === undefined && apiReady) {
      loadPageData()
    }
  }, [followedClients, apiReady])

  if (mode === 'widget') {
    return <Widget header={renderTitle()}>{renderBody()}</Widget>
  }

  return (
    <>
      {renderTitle()}
      <VerticalSpace />
      <CardContainer>{renderBody()}</CardContainer>
      <VerticalSpace size='2' />
    </>
  )
}

export default FollowedClients
