import { Avatar, BodyShort, FlexCenterDiv, Heading, HorizontalSpace, PileDiv } from '@cegal/ds-components'
import UnknownAvatar from 'assets/images/unknownAvatar.png'
import { ClientCommentModel } from 'declarations/models'

import Card, { CardProps } from 'components/Card/Card'

import { useTranslation } from 'react-i18next'

import useConsultantAvatar from 'hooks/useConsultantAvatar'

import { toLocalDateFormat } from 'utils'

const ClientCommentsCard = ({
  item,
  size = 'medium',
  spacing = false,
  ...rest
}: CardProps<ClientCommentModel>) => {
  const { t } = useTranslation()
  const { avatar, avatarLoading, ref } = useConsultantAvatar({ id: item.commenter?.id })

  return (
    <Card<ClientCommentModel>
      data-cy='consultantCard'
      data-testid='ConsultantCard'
      border
      item={item}
      size={size}
      spacing={spacing}
      editTooltip={t('messages:edit-this-comment').toString()}
      deleteTooltip={t('messages:delete-this-comment').toString()}
      deleteMessage={t('messages:confirm-delete-comment').toString()}
      {...rest}
    >
      <FlexCenterDiv>
        <Avatar
          variant='circle'
          border
          size='small'
          src={avatar}
          ref={ref}
          loading={avatarLoading}
          defaultSrc={UnknownAvatar}
        />
        <HorizontalSpace />
        <PileDiv>
          <Heading size='xsmall' as='span'>
            {item.commenter?.name}
          </Heading>
          <BodyShort>{toLocalDateFormat(item.comment_date)}</BodyShort>
        </PileDiv>
      </FlexCenterDiv>
      <HorizontalSpace />
      <BodyShort>{item.body}</BodyShort>
    </Card>
  )
}

export default ClientCommentsCard
