function debounce(func, wait = 166) {
    let timeout;
    function debounced(...args) {
        const later = () => {
            func.apply(this, args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    }
    debounced.clear = () => {
        clearTimeout(timeout);
    };
    return debounced;
}
export { debounce };
