import { Chip } from '@cegal/ds-components'
import { actions, anyRegex } from 'ai/actions'
import { styled } from 'styled-components'

import { useLocation } from 'react-router-dom'

const ActionsContainer = styled.div`
  display: flex;
  margin-top: 0.5rem;
  padding-bottom: 0.5rem;
  overflow-y: auto;
  flex-shrink: 0;
`

interface Props {
  onAction: (action: string) => void
  disabled: boolean
}

const AiQuickActions = ({ onAction, disabled }: Props) => {
  const { pathname } = useLocation()

  return (
    <ActionsContainer>
      {actions
        .filter((action) => action.context === anyRegex || action.context.test(pathname))
        .map((action) => (
          <Chip
            disabled={disabled}
            key={action.text}
            button
            size='small'
            style={{ whiteSpace: 'nowrap' }}
            onClick={() => onAction(action.text)}
          >
            {action.text}
          </Chip>
        ))}
    </ActionsContainer>
  )
}

export default AiQuickActions
