var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { NavigateNext as Next } from '@cegal/ds-icons/dist/NavigateNext';
import cl from 'clsx';
import { Panel } from 'components/panel';
import React, { forwardRef } from 'react';
import LinkPanelDescription from './LinkPanelDescription';
import LinkPanelTitle from './LinkPanelTitle';
const LinkPanel = forwardRef((_a, ref) => {
    var { children, as = 'a', variant = 'primary', spacing = false, className } = _a, rest = __rest(_a, ["children", "as", "variant", "spacing", "className"]);
    return (React.createElement(Panel, Object.assign({}, rest, { ref: ref, as: as, className: cl('cds-link-panel', `cds-link-panel--${variant}`, {
            'cds-link-panel--spacing': spacing
        }, className) }),
        React.createElement("div", { className: 'cds-link-panel__content' }, children),
        React.createElement(Next, { size: '2rem', className: 'cds-link-panel__chevron', "aria-hidden": true })));
});
LinkPanel.Title = LinkPanelTitle;
LinkPanel.Description = LinkPanelDescription;
LinkPanel.displayName = 'LinkPanel';
export default LinkPanel;
