import * as types from 'constants/actionTypes'
import { Category } from 'declarations/models'
import { AnyAction } from 'redux'

import _ from 'lodash'

export interface CategoriesState {
  created: Category | null | undefined
  creating: boolean
  deleted: Category | null | undefined
  deleting: boolean
  get: Category | null | undefined
  getting: boolean
  list: Array<Category> | null | undefined
  listing: boolean
  saved: Category | null | undefined
  saving: boolean
}

export const initialCategoriesState: CategoriesState = {
  created: undefined,
  creating: false,
  deleted: undefined,
  deleting: false,
  get: undefined,
  getting: false,
  list: undefined,
  listing: false,
  saved: undefined,
  saving: false
}

const categoriesReducer = (/* istanbul ignore next */ state = initialCategoriesState, action: AnyAction) => {
  switch (action.type) {
    case types.CATEGORIES_DELETE_REQUEST:
      return {
        ...state,
        deleted: undefined,
        deleting: true
      }

    case types.CATEGORIES_DELETE_SUCCESS: {
      const deletedCategory = action.originalPayload
      return {
        ...state,
        deleted: deletedCategory,
        deleting: false,
        list: _.reject(state.list, { id: deletedCategory.id })
      }
    }

    case types.CATEGORIES_DELETE_FAILURE:
      return {
        ...state,
        deleted: null,
        deleting: false
      }

    case types.CATEGORIES_EDIT_REQUEST:
      return {
        ...state,
        saved: undefined,
        saving: true
      }

    case types.CATEGORIES_EDIT_SUCCESS: {
      const editedCategory = action.payload
      return {
        ...state,
        saved: editedCategory,
        saving: false,
        list: _.map(state.list, (category) =>
          /* istanbul ignore next */
          editedCategory.id === category.id ? editedCategory : category
        )
      }
    }

    case types.CATEGORIES_EDIT_FAILURE:
      return {
        ...state,
        saved: null,
        saving: false
      }

    case types.CATEGORIES_LIST_REQUEST:
      return {
        ...state,
        list: undefined,
        listing: true
      }

    case types.CATEGORIES_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload,
        listing: false
      }

    case types.CATEGORIES_LIST_FAILURE:
      return {
        ...state,
        list: null,
        listing: false
      }

    case types.CATEGORIES_NEW_REQUEST:
      return {
        ...state,
        creating: true,
        created: undefined
      }

    case types.CATEGORIES_NEW_SUCCESS: {
      const newCategory = action.payload
      return {
        ...state,
        created: newCategory,
        creating: false,
        list: [.../* istanbul ignore next */ (state.list ?? [])].concat(newCategory)
      }
    }

    case types.CATEGORIES_NEW_FAILURE:
      return {
        ...state,
        created: null,
        creating: false
      }
    default:
      return state
  }
}

export default categoriesReducer
