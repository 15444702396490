import * as types from 'constants/actionTypes'
import * as urls from 'constants/urls'
import api from 'api'
import { NewsRequest, NewsResponse } from 'declarations/api'
import { sprintf } from 'sprintf-js'

export const deleteNews = (news: NewsResponse) => {
  return api.call({
    method: 'DELETE',
    url: sprintf(urls.NEWS_DELETE_URL, { id: news.id }),
    context: news,
    skipFake: true,
    cascadeFailureError: true,
    type: {
      request: types.NEWS_DELETE_REQUEST,
      success: types.NEWS_DELETE_SUCCESS,
      failure: types.NEWS_DELETE_FAILURE
    }
  })
}

export const editNews = (news: NewsResponse) => {
  const payload: NewsRequest = {
    name: news.name,
    important: news.important
  }
  return api.call({
    method: 'PUT',
    url: sprintf(urls.NEWS_EDIT_URL, { id: news.id }),
    body: payload,
    cascadeFailureError: true,
    skipFake: true,
    type: {
      request: types.NEWS_EDIT_REQUEST,
      success: types.NEWS_EDIT_SUCCESS,
      failure: types.NEWS_EDIT_FAILURE
    }
  })
}

export const listNews = () => {
  return api.call({
    url: urls.NEWS_LIST_URL,
    skipFake: true,
    cascadeFailureError: true,
    type: {
      request: types.NEWS_LIST_REQUEST,
      success: types.NEWS_LIST_SUCCESS,
      failure: types.NEWS_LIST_FAILURE
    }
  })
}

export const newNews = (news: NewsRequest) => {
  return api.call({
    method: 'POST',
    skipFake: true,
    url: urls.NEWS_NEW_URL,
    body: news,
    cascadeFailureError: true,
    type: {
      request: types.NEWS_NEW_REQUEST,
      success: types.NEWS_NEW_SUCCESS,
      failure: types.NEWS_NEW_FAILURE
    }
  })
}
