import { getClientAvatar } from 'actions'
import { useAppDispatch, useAppSelector } from 'store'

import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import _ from 'lodash'

export interface UseAvatarProps {
  id?: number
  initialLoading?: boolean // for test purposes
}

const useClientAvatar = ({ id, initialLoading = false }: UseAvatarProps) => {
  const [avatarLoading, setAvatarLoading] = useState<boolean>(initialLoading)
  const { ref, inView } = useInView()

  /* istanbul ignore next */
  const avatar = useAppSelector((state) => (id ? state.cache.clientAvatars[id] : ''))

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (inView && _.isNumber(id)) {
      if (avatar !== undefined) {
        setAvatarLoading(false)
      } else {
        if (!avatarLoading) {
          setAvatarLoading(true)
          dispatch(getClientAvatar(id))
        }
      }
    }
  }, [avatar, avatarLoading, inView, id])

  return { avatar, avatarLoading, ref }
}

export default useClientAvatar
