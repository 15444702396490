var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import cl from 'clsx';
import React, { forwardRef } from 'react';
import ListItem from './Item';
export const List = forwardRef((_a, ref) => {
    var { className, children } = _a, rest = __rest(_a, ["className", "children"]);
    return (React.createElement("ul", Object.assign({}, rest, { ref: ref, className: cl('cds-dropdown__list', className) }), children));
});
List.Item = ListItem;
List.displayName = 'Dropdown.Menu.List';
export default List;
