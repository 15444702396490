import {
  BodyShort,
  Box,
  BoxSize,
  Button,
  FlexDiv,
  FlexEndDiv,
  HorizontalSpace,
  PileDiv,
  VerticalSpace
} from '@cegal/ds-components'
import { Delete, DragIndicator } from '@cegal/ds-icons'
import styled from 'styled-components'

import DeleteButton from 'components/Forms/Buttons/DeleteButton'
import EditButton from 'components/Forms/Buttons/EditButton'
import { InsetDiv, OverlayDiv } from 'components/styled'

import { PropsWithChildren, Ref, forwardRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'

export interface CardProps<T, Q = any> extends PropsWithChildren {
  item: T
  border?: boolean
  size?: CardSize
  width?: string
  fullWidth?: boolean
  spacing?: boolean
  // style for newly edited card
  edited?: boolean
  // style for newly added card
  added?: boolean
  // animation for loading
  loading?: boolean
  // query string for highlight
  query?: Q

  buttons?: Array<React.ReactNode> | null

  onView?: (item: T) => void
  onEdit?: (item: T) => void
  editTooltip?: string
  onDelete?: (item: T) => void
  deleteMessage?: string
  deleteTooltip?: string

  'data-cy'?: string
  'data-testid'?: string

  style?: any
  context?: any

  dropItem?: any

  draggable?: boolean
}

export type CardSize = 'medium' | 'small' | 'xsmall'

export const CardBox = styled(Box)<{
  width?: string
  fullWidth?: boolean
  size?: BoxSize
  edited?: boolean
  added?: boolean
}>`
  @media (max-width: 768px) {
    width: ${(props) => props.width ?? '100%'};
  }
  break-inside: avoid;
  display: block;
  flex-direction: column;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  padding: var(--cds-size-3);
  background-color: ${(props) =>
    props.edited
      ? 'var(--cat-card-background-edited)'
      : props.added
        ? 'var(--cat-card-background-added)'
        : 'var(--cat-card-background)'};
  position: relative;
`
export const CardBody = styled(Box.Body)`
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0px;
  flex: 10;
`

export const CardFooter = styled(Box.Footer)`
  flex: 1;
  margin-top: 0.5rem;
  padding: 0.6rem 0px 0px 0px;
`

export const CardButtonDiv = styled.div<{ size: CardSize }>`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 10px;
  padding: 0.2rem;
  margin-bottom: ${({ size }) => (size !== 'medium' ? '0rem' : '-0.25rem')};
`

const Card = forwardRef(<T extends any>(props: CardProps<T, any>, ref: Ref<HTMLDivElement>) => {
  const [deleteMode, setDeleteMode] = useState<boolean>(false)

  const { t } = useTranslation()

  const confirmDelete = () => {
    props.onDelete?.(props.item)
    setDeleteMode(false)
  }

  const cancelDelete = () => setDeleteMode(false)

  const renderButtons = () => {
    return (
      <CardButtonDiv size={props.size ?? 'medium'}>
        <>
          {props.buttons}
          {_.isFunction(props.onDelete) && (
            <DeleteButton
              data-testid='Card-deleteButton'
              title={props.deleteTooltip}
              text={props.size === 'medium' ? t('buttons:delete') : undefined}
              showText={props.size === 'medium'}
              onDelete={() => setDeleteMode(true)}
            />
          )}
          {_.isFunction(props.onEdit) && (
            <EditButton
              data-testid='Card-editButton'
              title={props.editTooltip}
              text={props.size === 'medium' ? t('buttons:edit') : undefined}
              showText={props.size === 'medium'}
              onEdit={() => props.onEdit?.(props.item)}
            />
          )}
        </>
      </CardButtonDiv>
    )
  }

  return (
    <CardBox
      ref={ref}
      border={props.border}
      size={props.size === 'medium' ? 'medium' : 'small'}
      fullWidth={props.fullWidth}
      width={props.width}
      data-cy={props['data-cy'] ?? 'Card'}
      data-testid={props['data-testid'] ?? 'Card'}
      added={props.added}
      edited={props.edited}
      loading={props.loading}
      style={props.style}
      spacing={props.spacing}
    >
      <CardBody>
        {deleteMode && (
          <OverlayDiv data-testid='Card-DeleteOverlayDiv'>
            <InsetDiv>
              <BodyShort>{props.deleteMessage ?? t('messages:are-you-sure')}</BodyShort>
              <VerticalSpace size='0.5' />
              <FlexEndDiv>
                <Button
                  data-testid='Card-ConfirmDeleteButton'
                  variant='danger'
                  size='small'
                  icon={<Delete size='1.2rem' />}
                  onClick={confirmDelete}
                >
                  {t('buttons:yes-delete')}
                </Button>
                <HorizontalSpace />
                <Button variant='secondary' size='small' onClick={cancelDelete}>
                  {t('buttons:no-cancel')}
                </Button>
              </FlexEndDiv>
            </InsetDiv>
          </OverlayDiv>
        )}
        <FlexDiv style={{ flexWrap: 'nowrap', justifyContent: 'space-between' }}>
          <PileDiv style={{ width: '100%', alignItems: 'flex-start' }} flex='1'>
            {props.children}
          </PileDiv>
          {props.size === 'small' && <HorizontalSpace />}
          {props.size === 'small' && (
            <FlexDiv style={{ alignItems: 'flex-start' }}>{renderButtons()}</FlexDiv>
          )}
          {props.draggable && (
            <FlexDiv>
              <DragIndicator size='2rem' style={{ marginTop: '.2rem' }} />
            </FlexDiv>
          )}
        </FlexDiv>
      </CardBody>
      {props.size === 'medium' &&
        (!_.isEmpty(props.buttons) || _.isFunction(props.onEdit) || _.isFunction(props.onDelete)) && (
          <CardFooter>
            <FlexEndDiv style={{ justifyContent: 'flex-end' }}>{renderButtons()} </FlexEndDiv>
          </CardFooter>
        )}
    </CardBox>
  )
})

export default Card as <T extends any, Q extends any = any>(
  props: CardProps<T, Q> & { ref?: Ref<HTMLFormElement> }
) => JSX.Element
