import {
  BodyShort,
  Button,
  FlexCenterDiv,
  FlexDiv,
  Heading,
  HorizontalSpace,
  Loader,
  Pagination,
  Table,
  TextField,
  VerticalSpace
} from '@cegal/ds-components'
import { deleteNews, editNews, listNews, newNews } from 'actions'
import { News } from 'declarations/models'
import { useAppDispatch, useAppSelector } from 'store'

import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useQuery from 'hooks/useQuery'

import BasicRowView from '../../components/Admin/BasicRowView'

const AdminNews = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [isNewMode, setIsNewMode] = useState<boolean>(false)

  const news = useAppSelector((state) => state.news.list)
  const loadingNews = useAppSelector((state) => state.news.listing)
  const itemsPerPage = useAppSelector((state) => state.app.settings.itemsPerPage)

  const { query, setQuery } = useQuery<{
    page: string
    search: string
  }>({ page: '1', search: '' })
  const page = query?.page ? +query?.page : 1

  useEffect(() => {
    if (!loadingNews && news === undefined) {
      dispatch(listNews())
    }
  }, [])

  const refreshNews = () => {
    dispatch(listNews())
  }

  const handlePageChange = (page: number) => {
    window.scrollTo(0, 0)
    setQuery({ page: page?.toString() })
  }

  const filteredNews = useMemo(
    () =>
      query.search
        ? news?.filter((news: News) => news.name?.toLowerCase().includes(query.search!!.toLowerCase())) ??
          null
        : news ?? null,
    [news, query.search]
  )

  const newsPage = useMemo(() => {
    const firstPageIndex = (page - 1) * itemsPerPage
    const lastPageIndex = firstPageIndex + itemsPerPage
    return filteredNews?.slice(firstPageIndex, lastPageIndex) ?? null
  }, [page, filteredNews])

  const paginationRange = useMemo(() => {
    const numberOfPages = Math.ceil((filteredNews?.length ?? 0) / itemsPerPage)
    return numberOfPages
  }, [itemsPerPage, filteredNews])

  const headers: { label: string }[] = [{ label: t('label:id') }, { label: t('label:name') }, { label: '' }]

  const handleFilter = (value: { search: string }) => {
    setQuery(value)
  }

  return (
    <>
      <Heading level='2' size='medium' spacing>
        {t('label:news-title')}
      </Heading>
      <FlexDiv style={{ justifyContent: 'space-between' }}>
        <FlexDiv>
          {loadingNews ? (
            <FlexCenterDiv>
              <Loader />
              <HorizontalSpace />
              <BodyShort>{t('messages:loading-news')}...</BodyShort>
            </FlexCenterDiv>
          ) : (
            <BodyShort>
              {t('messages:news-list-result', {
                x: filteredNews?.length
              })}
            </BodyShort>
          )}
        </FlexDiv>
        <TextField
          hideLabel
          description={t('label:search-description')}
          label={t('label:filter')}
          placeholder={t('label:search-title')}
          value={query?.search || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleFilter({ search: e.target.value || '' })
          }
        />
        <FlexDiv>
          <Button onClick={() => setIsNewMode(true)}>
            {t('buttons:add-new-x', { x: t('label:news-title') })}
          </Button>
          <HorizontalSpace />
          <Button onClick={refreshNews}>{t('buttons:refresh-x', { x: t('label:news-title') })}</Button>
        </FlexDiv>
      </FlexDiv>
      <VerticalSpace />
      <Table style={{ tableLayout: 'fixed', width: '100%' }}>
        <Table.Header>
          <Table.Row>
            {headers.map((row) => (
              <Table.ColumnHeader key={`row-label-${row.label}`}>{row.label}</Table.ColumnHeader>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {isNewMode && (
            <BasicRowView<News>
              onCancel={() => setIsNewMode(false)}
              isNewMode
              onNew={(item) => dispatch(newNews(item))}
              isCheckbox
            />
          )}
          {newsPage?.map((news: News) => (
            <BasicRowView
              key={news.id}
              item={news}
              onEdit={(item) => dispatch(editNews(item))}
              onDelete={(item) => dispatch(deleteNews(item))}
              isCheckbox
            />
          ))}
        </Table.Body>
      </Table>

      {paginationRange > 1 && (
        <>
          <VerticalSpace size='2' />
          <Pagination
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
            size='medium'
            count={paginationRange}
            page={page}
            onPageChange={handlePageChange}
          />
        </>
      )}
    </>
  )
}

export default AdminNews
