import {
  BodyShort,
  SelectMultiple,
  SelectMultipleComponents,
  SelectMultipleProps
} from '@cegal/ds-components'
import { Consultant } from 'declarations/models'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OptionProps } from 'react-select'

import _ from 'lodash'

const ConsultantOption = (props: OptionProps<Consultant>) => {
  const { data } = props
  return (
    <SelectMultipleComponents.Option {...props}>
      <BodyShort>{data.name}</BodyShort>
    </SelectMultipleComponents.Option>
  )
}

const ConsultantSingleValue = (props: OptionProps<Consultant>) => {
  const { data } = props
  return (
    <SelectMultipleComponents.SingleValue {...props}>
      <BodyShort>{data.name}</BodyShort>
    </SelectMultipleComponents.SingleValue>
  )
}

export interface ThisConsultantSelectProps {
  consultants: Array<Consultant>
}

export type ConsultantSelectProps = ThisConsultantSelectProps & SelectMultipleProps<Consultant>

const filterOptions = (candidate: { label: string; value: string; data: any }, input: string) => {
  if (input) {
    return candidate.data.name.toLowerCase().match(input.toLowerCase())
  }
  return false
}

const ConsultantSelect = (props: ConsultantSelectProps) => {
  const [_options, setOptions] = useState<Array<Consultant>>(props.consultants)

  const { t } = useTranslation()

  useEffect(() => {
    setOptions(props.consultants)
  }, [props.consultants])

  const handleInputChange = (inputValue: string) => {
    const filteredOptions = _.filter(props.consultants, (c: Consultant) =>
      c.name.toLowerCase().match(inputValue.toLowerCase())
    ) as Array<Consultant>
    setOptions(filteredOptions)
  }

  return (
    <div>
      <SelectMultiple
        fullWidth
        noOptionsMessage={(data: any) =>
          !_.isEmpty(data.inputValue)
            ? t('messages:warning-no-consultants-found-with-text-x', { x: data.inputValue })
            : t('messages:info-type-to-search-consultants')
        }
        multiple={false}
        // @ts-ignore
        onInputChange={handleInputChange}
        filterOption={filterOptions}
        components={{
          SingleValue: (optionProps: OptionProps<Consultant>) => <ConsultantSingleValue {...optionProps} />,
          Option: (optionProps: OptionProps<Consultant>) => <ConsultantOption {...optionProps} />
        }}
        // @ts-ignore
        options={_options}
        {...props}
      />
    </div>
  )
}

export default ConsultantSelect
