import * as types from 'constants/actionTypes'
import { AnyAction } from '@reduxjs/toolkit'
import { Certificate } from 'declarations/models'

export interface CertificatesState {
  created: Certificate | null | undefined
  creating: boolean
  deleted: Certificate | null | undefined
  deleting: boolean
  get: Certificate | null | undefined
  getting: boolean
  list: Array<Certificate> | null | undefined
  listing: boolean
  saving: boolean
  saved: Certificate | null | undefined
}

export const initialCertificatesState: CertificatesState = {
  created: undefined,
  creating: false,
  deleted: undefined,
  deleting: false,
  get: undefined,
  getting: false,
  list: undefined,
  listing: false,
  saved: undefined,
  saving: false
}

const certificatesReducer = (
  state: CertificatesState = initialCertificatesState,
  action: AnyAction
): CertificatesState => {
  switch (action.type) {
    case types.CERTIFICATES_GET_REQUEST:
      return {
        ...state,
        getting: true,
        get: undefined
      }

    case types.CERTIFICATES_GET_SUCCESS:
      return {
        ...state,
        getting: false,
        get: action.payload
      }

    case types.CERTIFICATES_GET_FAILURE:
      return {
        ...state,
        getting: false,
        get: null
      }
    case types.CERTIFICATES_LIST_REQUEST:
      return {
        ...state,
        listing: true,
        list: undefined
      }
    case types.CERTIFICATES_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload,
        listing: false
      }
    case types.CERTIFICATES_LIST_FAILURE:
      return {
        ...state,
        list: null,
        listing: false
      }

    default:
      return state
  }
}

export default certificatesReducer
