import {
  Button,
  Column,
  FlexBaseDiv,
  FlexBaseSpacedDiv,
  FlexCenterDiv,
  FlexCenterSpacedDiv,
  FlexDiv,
  FlexStartDiv,
  Heading,
  HorizontalSpace,
  Loader,
  Pagination,
  PileDiv,
  PileEndDiv,
  TextField,
  VerticalSpace
} from '@cegal/ds-components'
import { ChevronLeft } from '@cegal/ds-icons/dist/ChevronLeft'
import { FilterAlt as FilterIcon } from '@cegal/ds-icons/dist/FilterAlt'
import { getConsultant, setConsultant } from 'actions'
import { ENGAGEMENTS_LIST_FAILURE } from 'constants/actionTypes'
import { DisplaySize, ViewMode } from 'declarations/app'
import { ConsultantsFilterOptions } from 'declarations/enum'
import { EngagementsQuery } from 'declarations/models'
import { standardLogger } from 'metrics/loggers'
import { useAppDispatch, useAppSelector } from 'store'
import styled from 'styled-components'

import { FullScreenLoader } from 'components'
import AlertPanel from 'components/AlertPanel/AlertPanel'
import { ConsultantCard } from 'components/Consultants'
import { EngagementsFilter } from 'components/Engagements'
import EngagementVacanciesView from 'components/Engagements/Engagement/EngagementVacanciesView'
import EngagementsDescription from 'components/Engagements/EngagementsDescription'
import ToggleView from 'components/Forms/ToggleView'
import PageContent from 'components/PageContent/PageContent'

import { useDeferredValue, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import useEngagementFilter from 'hooks/useEngagementFilter'
import useQuery from 'hooks/useQuery'

import { initialEngagementsQuery, isEmptyQuery, isSkillQuery } from 'utils/query'
import { getNextSort } from 'utils/sort'

import _ from 'lodash'

const StickyDiv = styled(PileDiv)`
  position: sticky;
  top: 60px;
  background-color: var(--cds-background-color);
  z-index: 3;
`
const ConsultantFind = () => {
  const { id: consultantId } = useParams()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const consultant = useAppSelector((state) => state.consultants.get)
  const consultants = useAppSelector((state) => state.consultants.list)
  const viewMode = useAppSelector((state) => state.app.settings.viewMode)
  const itemsPerPage = useAppSelector((state) => state.app.settings.itemsPerPage)
  const displaySize = useAppSelector((state) => state.app.displaySize)

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(true)
  const [_viewMode, setViewMode] = useState<ViewMode>(viewMode)

  const { query, setQuery } = useQuery<EngagementsQuery>(initialEngagementsQuery)

  const [inputValue, setInputValue] = useState(query?.search)
  const debouncedValue = useDeferredValue(inputValue)

  const handlePageChange = (page: number) => {
    window.scrollTo(0, 0)
    standardLogger('consultant.match.page', { page })
    setQuery({ page: page === 1 ? undefined : page?.toString() })
  }

  const {
    pages,
    items: engagements,
    loaded,
    total
  } = useEngagementFilter(initialEngagementsQuery, handlePageChange, {
    asEngagementVacancy: true
  })

  const handleSort = (sortKey?: string | undefined) => setQuery({ sort: getNextSort(query?.sort, sortKey) })

  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen)

  const setOrientationAndViewMode = (displaySize: DisplaySize) => {
    if (displaySize === 'sm' || displaySize === 'md') {
      setViewMode('card')
    } else {
      setViewMode(viewMode)
    }
  }

  const loadPageData = () => {
    if (consultantId) {
      const parsedId = parseInt(consultantId)
      if (_.isNumber(parsedId)) {
        if (!_.isNil(consultants)) {
          const found = consultants?.find((obj) => obj.id === parsedId)
          if (found) {
            dispatch(setConsultant(found))
            return
          }
        }
        dispatch(getConsultant(parsedId))
      }
    }
  }

  useEffect(() => setViewMode(viewMode), [viewMode])

  useEffect(() => setOrientationAndViewMode(displaySize), [displaySize])

  useEffect(() => {
    if (consultantId && !consultant) {
      dispatch(getConsultant(parseInt(consultantId)))
    }
  }, [consultantId])

  useEffect(() => {
    if (inputValue !== undefined) {
      setQuery((query: Partial<EngagementsQuery> | undefined) => ({ ...query, search: debouncedValue }))
    }
  }, [debouncedValue])

  useEffect(() => {
    let newFilter: ConsultantsFilterOptions | undefined
    if (!_.isEmpty(query.metaFilter)) {
      if (isEmptyQuery('engagement', query)) {
        if (query.metaFilter !== ConsultantsFilterOptions.NONE) {
          newFilter = ConsultantsFilterOptions.NONE
        }
      } else {
        if (isSkillQuery('engagement', consultant, query)) {
          if (query.metaFilter !== ConsultantsFilterOptions.SKILL) {
            newFilter = ConsultantsFilterOptions.SKILL
          }
        } else {
          if (query.metaFilter !== ConsultantsFilterOptions.CUSTOM) {
            newFilter = ConsultantsFilterOptions.CUSTOM
          }
        }
      }
    }

    if (newFilter) {
      setQuery((query: any) => ({ ...query, metaFilter: newFilter }))
    }
  }, [query])

  return (
    <PageContent
      maxWidth={viewMode === 'row' ? '100%' : '1600px'}
      isDrawerOpen={isDrawerOpen}
      drawer={!_.isNil(engagements) && <EngagementsFilter context='matches' onClose={() => toggleDrawer()} />}
    >
      <StickyDiv>
        <VerticalSpace />
        <FlexBaseSpacedDiv>
          <FlexBaseDiv>
            <div>
              <Button variant='secondary' icon={<ChevronLeft size='1.5rem' />} onClick={() => navigate(-1)}>
                {t('buttons:back')}
              </Button>
            </div>
            <HorizontalSpace />
            <PileDiv>
              <FlexCenterDiv style={{ flexWrap: 'nowrap' }}>
                <Button
                  variant='secondary'
                  icon={<FilterIcon size='1.5rem' />}
                  onClick={() => setIsDrawerOpen(!isDrawerOpen)}
                >
                  <span style={{ whiteSpace: 'nowrap' }}>
                    {isDrawerOpen ? t('buttons:close-filter-options') : t('buttons:open-filter-options')}
                  </span>
                </Button>
                <HorizontalSpace />
                <TextField
                  hideLabel
                  description={t('label:search-description')}
                  label={t('label:filter')}
                  placeholder={t('label:search-title')}
                  value={inputValue}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value)}
                />
                <HorizontalSpace size='0.5' />
                {debouncedValue !== inputValue && <Loader size='small' />}
              </FlexCenterDiv>
              <VerticalSpace />
            </PileDiv>
          </FlexBaseDiv>
          <PileEndDiv flex='1'>
            <FlexStartDiv style={{ flexWrap: 'nowrap', justifyContent: 'flex-end' }}>
              <ToggleView
                context='matches.list'
                viewItemsPerPage
                viewToggleMode={!(displaySize === 'sm' || displaySize === 'md')}
              />
            </FlexStartDiv>
            <VerticalSpace />
          </PileEndDiv>
        </FlexBaseSpacedDiv>
      </StickyDiv>
      <Heading level='2' size='large'>
        {t('header:consultant-match-x', { x: consultant?.name })}
      </Heading>
      <VerticalSpace />
      <FlexCenterSpacedDiv>
        <Column>
          <FlexDiv style={{ maxWidth: '400px' }}>
            {consultant && <ConsultantCard item={consultant} />}
          </FlexDiv>
        </Column>
        <HorizontalSpace />
      </FlexCenterSpacedDiv>
      <VerticalSpace size='0.5' />
      <EngagementsDescription total={total} query={query} itemsPerPage={itemsPerPage} />

      {!loaded && <FullScreenLoader />}
      {engagements === null && (
        <AlertPanel
          doNotRenderIfHeadIsActiveWithError
          watchFor={[ENGAGEMENTS_LIST_FAILURE]}
          buttonText={t('buttons:click-to-reload-the-page')!}
          reloadPage={loadPageData}
        />
      )}
      {!_.isNil(engagements) && !_.isNil(consultant) && (
        <>
          <EngagementVacanciesView
            query={query}
            viewMode={_viewMode}
            engagements={engagements}
            consultant={consultant!}
            previewInModal
            buttons={['match', 'follow']}
            onSortChange={handleSort}
          />
          {pages > 1 && (
            <>
              <VerticalSpace size='2' />
              <Pagination
                style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
                size={displaySize === 'sm' ? 'small' : 'medium'}
                count={pages}
                page={query?.page ? +query?.page : 1}
                onPageChange={handlePageChange}
              />
            </>
          )}
        </>
      )}
    </PageContent>
  )
}

export default ConsultantFind
