import cl from 'clsx';
import { BodyShort, Detail } from 'components/typography';
import React, { forwardRef } from 'react';
import { omit } from 'util/index';
import useCheckbox from './useCheckbox';
export const Checkbox = forwardRef((props, ref) => {
    const { inputProps, hasError, size } = useCheckbox(props);
    const Description = size === 'medium' ? BodyShort : Detail;
    return (React.createElement("div", { className: cl(props.className, 'cds-checkbox', `cds-checkbox--${size}`, {
            'cds-checkbox--error': hasError,
            'cds-checkbox--disabled': inputProps.disabled
        }) },
        React.createElement("input", Object.assign({}, omit(props, [
            'children',
            'size',
            'error',
            'description',
            'hideLabel',
            'indeterminate',
            'errorId'
        ]), inputProps, { ref: (el) => {
                var _a;
                if (el) {
                    el.indeterminate = (_a = props.indeterminate) !== null && _a !== void 0 ? _a : false;
                }
                if (typeof ref === 'function') {
                    ref(el);
                }
                else if (ref != null) {
                    ref.current = el;
                }
            }, type: 'checkbox', className: 'cds-checkbox__input', "aria-checked": props.indeterminate ? 'mixed' : inputProps.checked })),
        React.createElement("label", { htmlFor: inputProps.id, className: 'cds-checkbox__label' },
            React.createElement("div", { className: cl('cds-checkbox__content', {
                    'cds-sr-only': props.hideLabel
                }) },
                React.createElement(BodyShort, { as: 'div', size: size }, props.children),
                props.description && (React.createElement(Description, { as: 'div', size: 'small', className: 'cds-checkbox__description' }, props.description))))));
});
Checkbox.displayName = 'Checkbox';
export default Checkbox;
