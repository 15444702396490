import { ConsultantsFilterOptions, ConsultantsSortOptions } from 'declarations/enum'
import {
  ClientsQuery,
  Consultant,
  ConsultantsQuery,
  Engagement,
  EngagementsQuery,
  Skill
} from 'declarations/models'

import { getSortedSkillsFromEngagementVacancy } from 'utils/engagements'

import _ from 'lodash'

export const initialConsultantsQuery: ConsultantsQuery = {
  followed: false,
  allocation: [],
  roles: [],
  allRoles: false,
  skills: [],
  allSkills: false,
  locations: [],
  allLocations: false,
  departments: [],
  allCertificates: false,
  certificates: []
}

export const initialConsultantFindQuery: ConsultantsQuery = {
  ...initialConsultantsQuery,
  metaFilter: ConsultantsFilterOptions.SKILL,
  metaSort: ConsultantsSortOptions.NONE
}

export const initialEngagementsQuery: EngagementsQuery = {
  followed: false,
  mine: false,
  posteddate: undefined,
  deadline: undefined,
  startdate: undefined,
  enddate: undefined,
  hideEndedEngagements: false,
  allClients: false,
  clients: [],
  sources: [],
  locations: [],
  hideUnfilledVacancies: false,
  hideFilledVacancies: false,
  allSkills: false,
  skills: [],
  allCertificates: false,
  certificates: []
}

export const initialEngagementFindQuery: EngagementsQuery = {
  ...initialEngagementsQuery,
  metaFilter: ConsultantsFilterOptions.SKILL,
  metaSort: ConsultantsSortOptions.NONE
}

export const initialClientsQuery: ClientsQuery = {
  hasEngagement: false,
  hasNoEngagement: false
}

const whatThisQueryHasThatInitialQueryDoesNotHave = (
  what: 'engagement' | 'consultant',
  query: Partial<ConsultantsQuery | EngagementsQuery>
): Array<string> =>
  _.reduce(
    _.omit(query, 'metaFilter', 'metaSort', 'sort', 'page', 'vacancy'),
    (result, value, key: string) =>
      _.isEqual(value, initialConsultantsQuery[key as keyof ConsultantsQuery]) ? result : result.concat(key),
    [] as Array<string>
  )

export const isEmptyQuery = (
  what: 'engagement' | 'consultant',
  query: Partial<ConsultantsQuery | EngagementsQuery>
) => _.isEmpty(whatThisQueryHasThatInitialQueryDoesNotHave(what, query))

export const isSkillQuery = (
  what: 'engagement' | 'consultant',
  item: Engagement | Consultant | null | undefined,
  query: Partial<ConsultantsQuery | EngagementsQuery>
) => {
  const diffs = whatThisQueryHasThatInitialQueryDoesNotHave(what, query)

  if (!item || (what === 'engagement' && !_.isNumber((query as ConsultantsQuery)?.vacancy))) {
    return false
  }
  if (diffs.length === 1 && diffs[0] === 'skills') {
    let skillIds: Array<Skill['id']>
    if (what === 'engagement') {
      skillIds = getSortedSkillsFromEngagementVacancy(
        item as Engagement,
        (query as ConsultantsQuery).vacancy!!
      )
    } else {
      skillIds = (item as Consultant).skills?.map((s) => s.id)?.sort() ?? []
    }
    const querySkillIds = (Array.isArray(query.skills) ? query.skills : [query.skills]) as Array<Skill['id']>
    return _.intersection(skillIds, querySkillIds).length === skillIds.length
  }
  return false
}
