import ReactDOM from 'react-dom';
const Portal = ({ children, anchorEl }) => {
    var _a;
    const hostElement = anchorEl !== null && anchorEl !== void 0 ? anchorEl : (_a = globalThis === null || globalThis === void 0 ? void 0 : globalThis.document) === null || _a === void 0 ? void 0 : _a.body;
    if (hostElement) {
        return ReactDOM.createPortal(children, hostElement);
    }
    return null;
};
export default Portal;
