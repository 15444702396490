import { BodyShort } from '@cegal/ds-components'
import { Widget } from 'declarations/app'

import { Draggable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'

interface DraggableWidgetProps {
  widget: Widget
  index: number
}

const DraggableWidget = ({ widget, index }: DraggableWidgetProps) => {
  const { t } = useTranslation()

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    userSelect: 'none',
    padding: '0.7rem',
    margin: '0 0 0.35rem 0',
    border: '1px solid var(--cds-border-color)',
    background: isDragging
      ? 'var(--cds-background-color-alternative-medium-opaque)'
      : 'var(--cds-background-color)',
    ...draggableStyle
  })

  return (
    <Draggable key={widget.id} draggableId={widget.id} index={index}>
      {(provided: any, snapshot: any) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
          <BodyShort>{t(widget.translation)}</BodyShort>
        </div>
      )}
    </Draggable>
  )
}

export default DraggableWidget
