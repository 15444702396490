import * as amplitude from '@amplitude/analytics-browser'

import { IS_PRODUCTION } from 'utils/environment'

const isLocalhost = () => {
  const host = window?.location?.hostname || ''
  return host === 'localhost'
}

// const mainApiKey = 'QBH8BwqgWy0e_l33KZuZMdd9-MB3ZTQR'
const apiKey = 'ed477a12729d66f5ac94'

export const init = () => {
  if (!isLocalhost()) {
    amplitude.init(apiKey, undefined, {
      defaultTracking: {
        sessions: true,
        pageViews: true,
        formInteractions: true,
        fileDownloads: true
      }
    })
  }
}

export const amplitudeLogger = (name: string, values?: object) => {
  const data = values || {}
  const key = `cat.${name}`
  if (IS_PRODUCTION && !isLocalhost()) {
    amplitude.logEvent(key, data)
  }
}
