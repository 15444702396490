import { Heading, HeadingProps, VerticalSpace } from '@cegal/ds-components'

import { PropsWithChildren } from 'react'

type SectionProps = PropsWithChildren<{
  heading?: string
  headingLevel?: HeadingProps['level']
  headingSize?: HeadingProps['size']
  addTrailingSpace?: boolean
}>

const Section = ({
  heading,
  children,
  headingLevel = '3',
  headingSize = 'xsmall',
  addTrailingSpace = true
}: SectionProps) => (
  <>
    {heading && (
      <>
        <Heading level={headingLevel} size={headingSize}>
          {heading}
        </Heading>
        <VerticalSpace size='0.5' />
      </>
    )}
    {children || '-'}
    {addTrailingSpace && <VerticalSpace />}
  </>
)

export default Section
