import { Button, Table } from '@cegal/ds-components'
import { Settings } from '@cegal/ds-icons/dist/Settings'
import {
  deleteFollowedEngagement, // deleteMatch,
  listMatches,
  newFollowedEngagement, // newMatch,
  setTableHeaders
} from 'actions'
import { TableHeader, ViewMode } from 'declarations/app'
import { Consultant, Engagement, EngagementsQuery } from 'declarations/models'
import { standardLogger } from 'metrics/loggers'
import { useAppDispatch, useAppSelector } from 'store'

import EngagementCard from 'components/Engagements/Engagement/EngagementCard'
import EngagementPreviewModal, {
  EngagementToPreview
} from 'components/Engagements/Engagement/EngagementPreviewModal'
import EditButton from 'components/Forms/Buttons/EditButton'
import FindButton from 'components/Forms/Buttons/FindButton'
import FollowButton from 'components/Forms/Buttons/FollowButton'
import TableColumnManagerModal from 'components/TableColumnManagerModal/TableColumnManagerModal'
import { CardContainer } from 'components/styled'

import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'

import { isEditable } from 'utils/engagements'
import { engagementHeaders, getEngagementHeader, initialEngagementsTable } from 'utils/tableColumns'

import EngagementRow from './Engagement/EngagementRow'

import _ from 'lodash'

export interface EngagementsViewProps {
  buttons?: Array<string>
  engagements?: Array<Engagement>
  viewMode: ViewMode
  onSortChange?: (sortKey?: string | undefined) => void
  query?: Partial<EngagementsQuery>

  // these are for match context
  previewInModal?: boolean
  consultant?: Consultant | null
}

const EngagementsView = ({
  buttons,
  engagements,
  viewMode,
  query,
  onSortChange,
  previewInModal = false,
  consultant
}: EngagementsViewProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const headerKeys: Array<string> = useAppSelector((state) => state.app.tables.engagements)
  const headers: Array<TableHeader> = useMemo(
    () => headerKeys.map(getEngagementHeader) as Array<TableHeader>,
    [headerKeys]
  )

  const [engagementToPreview, setEngagementToPreview] = useState<EngagementToPreview>()
  const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false)
  const [openTableModal, setOpenTableModal] = useState<boolean>(false)

  const matches = useAppSelector((state) => state.matches.list)
  const apiReady = useAppSelector((state) => state.app.apiReady)
  const creatingFollow = useAppSelector((state) => state.followedEngagements.creating)
  const deletingFollow = useAppSelector((state) => state.followedEngagements.deleting)
  const followedEngagements = useAppSelector((state) => state.followedEngagements.list)
  const userId = useAppSelector((state) => state.app.userId)

  const onCloseModal = () => setOpenPreviewModal(!openPreviewModal)

  const setHeaderKeys = (newHeaders: Array<string>) => {
    dispatch(setTableHeaders('engagements', newHeaders))
  }

  const handleEngagementFollow = (engagement: Engagement) => {
    standardLogger('engagement.' + viewMode + '.follow')
    dispatch(newFollowedEngagement(engagement))
  }

  const handleEngagementUnfollow = (engagement: Engagement) => {
    standardLogger('engagement.' + viewMode + '.unfollow')
    dispatch(deleteFollowedEngagement(engagement))
  }

  const handleEngagementPreview = (engagement: Engagement) => {
    standardLogger('engagement.' + viewMode + '.view')
    if (!previewInModal) {
      navigate(generatePath('/engagements/:id', { id: engagement?.id.toString() }))
      return
    }
    setEngagementToPreview(engagement)
    setOpenPreviewModal(true)
  }

  const handleEngagementFind = (engagement: Engagement) => {
    standardLogger('engagement.' + viewMode + '.find')
    navigate(generatePath('/engagements/:id/find', { id: '' + engagement.id }))
  }

  const handleEngagementEdit = (engagement: Engagement) => {
    standardLogger('engagement.' + viewMode + '.edit')
    navigate(generatePath('/engagements/:id/edit', { id: '' + engagement.id }))
  }

  const getButtons = (engagement: Engagement): Array<React.ReactNode> | undefined => {
    return buttons?.map((button) => {
      switch (button) {
        case 'edit': {
          if (isEditable(engagement, userId)) {
            return (
              <EditButton
                key={'engagement-' + engagement.id + '-edit-button'}
                text={t('buttons:edit')}
                title={t('messages:warning-edit-your-engagement')}
                onEdit={() => handleEngagementEdit(engagement)}
              />
            )
          }
          return null
        }

        case 'find':
          return (
            <FindButton
              key={'engagement-' + engagement.id + '-find-button'}
              disabled={_.isEmpty(engagement.vacancies)}
              forItem='engagement'
              text={t('buttons:find-x', { x: t('label:consultants-title').toLowerCase() })}
              onClick={() => handleEngagementFind(engagement)}
            />
          )
        case 'follow': {
          const _creating = _.find(creatingFollow, { id: engagement.id }) !== undefined
          const _deleting = _.find(deletingFollow, { id: engagement.id }) !== undefined
          return (
            <FollowButton<Engagement>
              key={'engagement-' + engagement.id + '-follow-button'}
              item={engagement}
              followedItems={followedEngagements}
              onFollow={() => handleEngagementFollow(engagement)}
              onUnfollow={() => handleEngagementUnfollow(engagement)}
              loading={_creating || _deleting}
              disabled={_creating || _deleting}
            />
          )
        }
        default:
          return null
      }
    })
  }
  useEffect(() => {
    if (apiReady && _.isUndefined(matches)) {
      dispatch(listMatches())
    }
  }, [apiReady, matches])

  return (
    <>
      <EngagementPreviewModal
        open={openPreviewModal}
        onClose={onCloseModal}
        engagement={engagementToPreview}
      />
      {viewMode === 'row' && (
        <>
          <TableColumnManagerModal
            headerKeys={headerKeys}
            setHeaderKeys={setHeaderKeys}
            modalOpen={openTableModal}
            onClose={() => setOpenTableModal(false)}
            headers={engagementHeaders}
            initialHeaders={initialEngagementsTable}
          />
          <Table
            data-cy='engagementTable'
            style={{ width: '100%' }}
            sort={query?.sort}
            onSortChange={onSortChange}
          >
            <Table.Header>
              <Table.Row>
                {headers.map((header) => (
                  <Table.ColumnHeader key={header.value} sortable={!!header.sortKey} sortKey={header.sortKey}>
                    {t(header.label)}
                  </Table.ColumnHeader>
                ))}
                <Table.ColumnHeader style={{ textAlign: 'end' }}>
                  <Button
                    variant='primary'
                    icon={<Settings size='1.5rem' />}
                    onClick={() => setOpenTableModal(true)}
                  />
                </Table.ColumnHeader>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {engagements?.map((engagement) => (
                <EngagementRow
                  order={headerKeys}
                  key={`${engagement.id}-engagement-table-row`}
                  item={engagement}
                  buttons={getButtons(engagement)}
                  onView={() => handleEngagementPreview(engagement)}
                />
              ))}
            </Table.Body>
          </Table>
        </>
      )}
      {viewMode === 'card' && (
        <CardContainer>
          {engagements?.map((engagement: Engagement) => (
            <EngagementCard
              key={engagement.id}
              item={engagement}
              buttons={getButtons(engagement)}
              spacing
              onView={() => handleEngagementPreview(engagement)}
            />
          ))}
        </CardContainer>
      )}
    </>
  )
}

export default EngagementsView
