import { AlignEndColumn, Button, Checkbox, HorizontalSpace, Table, TextField } from '@cegal/ds-components'
import { SimpleEntry } from 'declarations/models'
import { useAppSelector } from 'store'

import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface BasicRowViewProps<T> {
  isCheckbox?: boolean
  isNewMode?: boolean
  item?: T
  onCancel?: () => void
  onEdit?: (item: T) => void
  onDelete?: (item: T) => void
  onNew?: (item: T) => void
}

const BasicRowView = <T extends SimpleEntry>({
  isCheckbox = false,
  isNewMode = false,
  item,
  onCancel,
  onEdit,
  onDelete,
  onNew
}: BasicRowViewProps<T>) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const { t } = useTranslation()
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors }
  } = useForm<T>({
    defaultValues: item as any
  })

  const saving = useAppSelector((state) => state.notes.saving)

  if (isEditMode || isNewMode) {
    return (
      <Table.Row key={item?.id}>
        <Table.DataCell>{isEditMode && item!.id}</Table.DataCell>
        <Table.DataCell>
          <TextField
            label=''
            hideLabel
            {...register('name' as any, {
              required: t('validation:category-no-name').toString()
            })}
            error={errors?.name?.message?.toString()}
          />
        </Table.DataCell>
        {isCheckbox && (
          <Table.DataCell>
            <Checkbox {...register('important' as any)}>Important</Checkbox>
          </Table.DataCell>
        )}
        <Table.DataCell>
          <Button
            loading={saving}
            disabled={saving}
            onClick={handleSubmit((item) => {
              if (isEditMode) {
                onEdit!(item)
                setIsEditMode(false)
              }
              if (isNewMode) {
                onNew!(item)
                onCancel?.()
              }
            })}
          >
            {t(isEditMode ? 'buttons:save' : 'buttons:add')}
          </Button>
          <HorizontalSpace />
          <Button
            variant='secondary'
            onClick={() => {
              if (isEditMode) {
                reset()
                setIsEditMode(false)
              }
              if (isNewMode) {
                onCancel?.()
              }
            }}
          >
            {t('buttons:cancel')}
          </Button>
        </Table.DataCell>
      </Table.Row>
    )
  }

  return (
    <Table.Row key={item!.id}>
      <Table.DataCell>{item!.id}</Table.DataCell>
      <Table.DataCell>{item!.name}</Table.DataCell>
      <Table.DataCell>
        <AlignEndColumn>
          <Button onClick={() => setIsEditMode(true)}>{t('buttons:edit')}</Button>
          <HorizontalSpace />
          <Button
            onClick={() => {
              if (window.confirm(t('messages:confirm-generic-delete')!)) {
                onDelete!(item!)
              }
            }}
          >
            {t('buttons:delete')}
          </Button>
        </AlignEndColumn>
      </Table.DataCell>
    </Table.Row>
  )
}

export default BasicRowView
