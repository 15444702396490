var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef, useContext } from 'react';
import cl from 'clsx';
import { DropdownContext } from './Dropdown';
export const Toggle = forwardRef((_a, ref) => {
    var { className, onClick } = _a, rest = __rest(_a, ["className", "onClick"]);
    const context = useContext(DropdownContext);
    if (!context) {
        console.warn('Dropdown.Toggle has to be wrapped in <Dropdown />');
        return null;
    }
    const { setAnchorEl, setIsOpen, isOpen } = context;
    return (React.createElement("button", Object.assign({}, rest, { ref: ref, onClick: (e) => {
            setAnchorEl(e.currentTarget);
            setIsOpen((isOpen) => !isOpen);
            onClick && onClick(e);
        }, "aria-expanded": isOpen, className: cl(className, 'cds-dropdown__toggle') })));
});
Toggle.displayName = 'Dropdown.Toggle';
export default Toggle;
